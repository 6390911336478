import { ClientHeader } from "modules/client_header/ClientHeader";
import { PortfolioDepositModal } from "modules/client_portfolios/PortfolioDepositModal";
import { PortfolioResultsCard } from "modules/client_portfolios/PortfolioResultsCard";
import { PortfolioSubscriptionStats } from "modules/client_portfolios/PortfolioSubscriptionStats";
import { PortfolioWithdrawModal } from "modules/client_portfolios/PortfolioWithdrawModal";
import { Position } from "modules/client_positions/Position";
import { PortfolioNews } from "modules/news/PortfolioNews";
import { PortfolioBacktestingChart } from "modules/portfolio_backtesting_chart/PortfolioBacktestingChart";
import { PortfolioComposition } from "modules/portfolio_composition/PortfolioComposition";
import { RecentActivities } from "modules/recent_activities/RecentActivities";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { AvatarImage } from "shared/components/common/AvatarImage";
import { BackButton } from "shared/components/common/BackButton";
import { BasePage, RightBar } from "shared/components/common/BasePage";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import { selectPortfolioByClientIdAndPortfolioId } from "shared/store/client_details/ClientDetailsReducer";
import { ClientDetailsThunks } from "shared/store/client_details/ClientDetailsThunks";
import { selectClientById } from "shared/store/clients/ClientsReducer";
import { ClientsThunks } from "shared/store/clients/ClientsThunks";
import { selectPortfolioById } from "shared/store/portfolios/PortfoliosReducer";
import { Layouts } from "shared/components/hoc/Layouts";
import { LatestUpdates } from "modules/latest_updates/LatestUpdates";

export const ClientPortfolioPage: React.FC = () => {
  const { portfolioId, clientId } = useParams();

  const clientPortfolio = useSelector(selectPortfolioById(portfolioId));
  const { portfolio: faClientPortfolio } = useSelector(
    selectPortfolioByClientIdAndPortfolioId(clientId, portfolioId),
  );
  const client = useSelector(selectClientById(clientId));
  const portfolio = clientId ? faClientPortfolio : clientPortfolio;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showDepositModal, setShowDepositModal] = useState(false);
  const [showWithdrawalModal, setShowWithdrawalModal] = useState(false);

  useEffect(() => {
    if (!portfolioId) {
      return;
    }
    if (clientId) {
      if (!faClientPortfolio) {
        dispatch(
          ClientDetailsThunks.fetchClientPortfolio({ clientId, portfolioId }),
        );
      }
      dispatch(ClientsThunks.fetchClientById(clientId));
    }
  }, [dispatch, portfolioId, clientId, faClientPortfolio]);

  return (
    <BasePage globalSearch>
      {portfolioId && (
        <>
          <PortfolioDepositModal
            open={showDepositModal}
            setOpen={setShowDepositModal}
            clientId={clientId}
            portfolioId={portfolioId}
          />
          <PortfolioWithdrawModal
            open={showWithdrawalModal}
            setOpen={setShowWithdrawalModal}
            clientId={clientId}
            portfolioId={portfolioId}
            portfolioEquity={portfolio?.subscription?.equity || 0}
          />
        </>
      )}
      <RightBar>
        <Layouts.Spaced>
          <Button
            label="clientCash.deposit"
            className="w-full"
            onClick={() => setShowDepositModal(true)}
          />
          <Button
            label="clientCash.withdrawal"
            className="w-full"
            onClick={() => setShowWithdrawalModal(true)}
          />
          <RecentActivities
            clientId={clientId}
            portfolioId={portfolioId}
            ungroup
          />
        </Layouts.Spaced>
      </RightBar>
      <div className="flex items-center">
        <AvatarImage
          className="mr-3"
          imageSrc={portfolio?.cover?.uri}
          width={42}
          height={42}
        />
        <Labels.H1>{portfolio?.title}</Labels.H1>
      </div>
      <BackButton />
      <Layouts.Spaced>
        {client && (
          <Panel>
            <ClientHeader client={client} />
          </Panel>
        )}
        {/* STATS */}
        <PortfolioSubscriptionStats
          clientId={clientId}
          portfolioId={portfolioId}
        />
        {/* RESULTS */}
        <PortfolioResultsCard clientId={clientId} portfolio={portfolio} />
        {/* INVESTING */}
        <Panel>
          <Labels.H3>{t("clientPortfolio.investing")}</Labels.H3>
          <div className="mt-4 md:grid md:grid-cols-3">
            {portfolio?.subscription?.positions.map((item, idx) => {
              return (
                <Position
                  key={"investing_position_" + idx}
                  position={item}
                  onClick={() => {}}
                />
              );
            })}
            {!portfolio?.subscription?.positions.length && (
              <Labels.G1>{t("positions.noPositions")}</Labels.G1>
            )}
          </div>
        </Panel>
        {/* DESCRIPTION */}
        <Panel>
          <Labels.H3>{t("global.description")}</Labels.H3>
          <div className="text-base">{portfolio?.description}</div>
        </Panel>
        {/* COMPOSITION */}
        <Panel className="mt-8">
          <div className="mb-8 flex w-full items-center justify-between font-semibold">
            <Labels.H3>{t("global.allocations")}</Labels.H3>
          </div>
          <PortfolioComposition portfolio={portfolio} />
        </Panel>
        {/* BACKTESTING */}
        <PortfolioBacktestingChart portfolio={portfolio} />
        {clientId && portfolio ? (
          <LatestUpdates portfolioId={portfolioId} clientId={clientId} />
        ) : null}
        {/* news about portfolio */}
        <PortfolioNews portfolioId={portfolioId} />
        {/* latest portfolio updates */}
      </Layouts.Spaced>
    </BasePage>
  );
};
