import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowBackIcon } from "shared/icons/ArrowBackIcon";

interface Props {
  className?: string;
  noText?: boolean;
  onClick?: () => void;
}

export const BackButton: React.FC<Props> = ({ className, noText, onClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();

  const navigateBack = () => {
    if (onClick) {
      onClick();
      return;
    }
    if (location.key === "default") {
      navigate("/dashboard", { replace: true });
      return;
    }
    navigate(-1);
  };

  return (
    <div
      onClick={navigateBack}
      className={`flex cursor-pointer flex-row items-center ${className} w-fit`}
    >
      <div className="mr-2 flex h-[26px] w-[26px] flex-row items-center justify-center rounded-3xl bg-sb-gray-200">
        <ArrowBackIcon className="h-[12px] w-[12px]" />
      </div>
      {!noText && <div className="text-base uppercase">{t("global.back")}</div>}
    </div>
  );
};
