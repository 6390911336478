import { SimpleRow } from "modules/brokerage_account/rows/SimpleRow";
import { useTranslation } from "react-i18next";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import { AlpacaTradingAccount } from "shared/models/brokerage/TradingAccountModel";
import { CurrencyFormat } from "shared/utils/currency";

type Props = {
  tradingAccount: AlpacaTradingAccount;
};

export const LastTrading: React.FC<Props> = ({ tradingAccount }) => {
  const { t } = useTranslation();

  return (
    <Panel>
      <Labels.H3 className="mb-4">{t("tradingAccount.lastTrading")}</Labels.H3>
      <div className="grid grid-cols-3 gap-y-8">
        <SimpleRow
          title={t("tradingAccount.lastEquity")}
          value={CurrencyFormat.format(tradingAccount.last_equity)}
        />
        <SimpleRow
          title={t("tradingAccount.lastBuyingPower")}
          value={CurrencyFormat.format(tradingAccount.last_buying_power)}
        />
        <SimpleRow
          title={t("tradingAccount.lastCash")}
          value={CurrencyFormat.format(tradingAccount.last_cash)}
        />
        <SimpleRow
          title={t("tradingAccount.lastLongMarketValue")}
          value={CurrencyFormat.format(tradingAccount.last_long_market_value)}
        />
      </div>
    </Panel>
  );
};
