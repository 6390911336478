import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  className?: string;
  href?: string;
};

export const EvivaLogo: React.FC<Props> = ({ className, href }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToLink = () => {
    if (!href || href === location.pathname) return;
    navigate(href);
  };

  return (
    <div onClick={navigateToLink}>
      <EvivaLogoContent className={className} />
    </div>
  );
};

interface EvivaLogoContentProps {
  className?: string;
}

export const EvivaLogoContent: React.FC<EvivaLogoContentProps> = ({
  className,
}) => {
  return (
    <svg
      viewBox="0 0 111 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M35.6873 18.2756C34.4122 18.2756 33.3111 18.0106 32.3837 17.4807C31.462 16.9453 30.7527 16.1891 30.2559 15.2121C29.7591 14.2296 29.5107 13.0732 29.5107 11.743C29.5107 10.4348 29.7591 9.28673 30.2559 8.29871C30.7582 7.30516 31.4592 6.5324 32.3589 5.98043C33.2586 5.42295 34.3156 5.1442 35.53 5.1442C36.3138 5.1442 37.0534 5.27115 37.7489 5.52506C38.4499 5.77345 39.0681 6.15982 39.6035 6.6842C40.1444 7.20857 40.5694 7.87645 40.8786 8.68784C41.1877 9.49372 41.3422 10.4541 41.3422 11.5691V12.4882H30.9183V10.4679H38.4692C38.4637 9.8939 38.3395 9.38332 38.0966 8.93623C37.8538 8.48361 37.5143 8.12759 37.0782 7.86817C36.6477 7.60874 36.1454 7.47903 35.5714 7.47903C34.9587 7.47903 34.4205 7.62806 33.9569 7.92613C33.4932 8.21867 33.1317 8.60505 32.8722 9.08526C32.6183 9.55995 32.4886 10.0816 32.4831 10.6501V12.4136C32.4831 13.1533 32.6183 13.788 32.8888 14.3179C33.1593 14.8423 33.5374 15.2452 34.0231 15.5267C34.5088 15.8027 35.0774 15.9407 35.7287 15.9407C36.1647 15.9407 36.5594 15.88 36.9127 15.7586C37.2659 15.6316 37.5723 15.4467 37.8317 15.2038C38.0911 14.961 38.2871 14.6602 38.4195 14.3014L41.218 14.616C41.0414 15.3556 40.7047 16.0014 40.2079 16.5534C39.7167 17.0999 39.0874 17.5249 38.3202 17.8285C37.5529 18.1265 36.6753 18.2756 35.6873 18.2756Z"
        fill="currentColor"
      />
      <path
        d="M54.873 5.30979L50.3441 18.0272H47.0323L42.5034 5.30979H45.6993L48.622 14.7567H48.7545L51.6854 5.30979H54.873Z"
        fill="currentColor"
      />
      <path
        d="M56.9471 18.0272V5.30979H59.9443V18.0272H56.9471ZM58.4539 3.50485C57.9793 3.50485 57.5708 3.34754 57.2286 3.03292C56.8864 2.71277 56.7152 2.32916 56.7152 1.88206C56.7152 1.42945 56.8864 1.04583 57.2286 0.731205C57.5708 0.411063 57.9793 0.250992 58.4539 0.250992C58.9342 0.250992 59.3426 0.411063 59.6793 0.731205C60.0215 1.04583 60.1926 1.42945 60.1926 1.88206C60.1926 2.32916 60.0215 2.71277 59.6793 3.03292C59.3426 3.34754 58.9342 3.50485 58.4539 3.50485Z"
        fill="currentColor"
      />
      <path
        d="M74.3858 5.30979L69.8569 18.0272H66.5451L62.0162 5.30979H65.2121L68.1348 14.7567H68.2673L71.1982 5.30979H74.3858Z"
        fill="currentColor"
      />
      <path
        d="M79.7551 18.2838C78.9493 18.2838 78.2234 18.1403 77.5776 17.8533C76.9373 17.5608 76.4295 17.1302 76.0542 16.5617C75.6844 15.9932 75.4994 15.2922 75.4994 14.4587C75.4994 13.7411 75.6319 13.1478 75.8969 12.6786C76.1618 12.2094 76.5233 11.8341 76.9815 11.5526C77.4396 11.2711 77.9557 11.0586 78.5298 10.915C79.1093 10.766 79.7082 10.6584 80.3264 10.5921C81.0716 10.5149 81.676 10.4459 82.1396 10.3851C82.6033 10.3189 82.94 10.2196 83.1497 10.0871C83.365 9.94909 83.4726 9.73658 83.4726 9.44956V9.39988C83.4726 8.77616 83.2877 8.29319 82.9179 7.95096C82.5481 7.60874 82.0154 7.43763 81.32 7.43763C80.5858 7.43763 80.0035 7.5977 79.573 7.91785C79.148 8.23799 78.8609 8.61609 78.7119 9.05214L75.9134 8.65473C76.1342 7.88197 76.4985 7.23616 77.0063 6.71731C77.5141 6.19294 78.1351 5.80104 78.8692 5.54162C79.6033 5.27667 80.4147 5.1442 81.3034 5.1442C81.9161 5.1442 82.526 5.21596 83.1332 5.35947C83.7404 5.50298 84.2951 5.74033 84.7974 6.07151C85.2997 6.39717 85.7026 6.84151 86.0062 7.40451C86.3153 7.96752 86.4698 8.67128 86.4698 9.5158V18.0272H83.5886V16.2802H83.4892C83.3071 16.6334 83.0504 16.9646 82.7192 17.2737C82.3935 17.5773 81.9823 17.8229 81.4856 18.0106C80.9943 18.1928 80.4175 18.2838 79.7551 18.2838ZM80.5334 16.0815C81.1351 16.0815 81.6567 15.9628 82.0982 15.7255C82.5398 15.4826 82.8793 15.1624 83.1166 14.765C83.3595 14.3676 83.4809 13.9343 83.4809 13.4651V11.9665C83.3871 12.0438 83.227 12.1156 83.0007 12.1818C82.7799 12.248 82.5315 12.306 82.2556 12.3557C81.9796 12.4054 81.7063 12.4495 81.4359 12.4882C81.1654 12.5268 80.9308 12.5599 80.7321 12.5875C80.285 12.6482 79.8848 12.7476 79.5316 12.8856C79.1783 13.0236 78.8996 13.2168 78.6954 13.4651C78.4911 13.708 78.389 14.0226 78.389 14.409C78.389 14.961 78.5905 15.3777 78.9934 15.6592C79.3964 15.9407 79.9097 16.0815 80.5334 16.0815Z"
        fill="currentColor"
      />
      <path
        d="M91.065 18.1514C90.7393 18.1514 90.4578 18.0354 90.2205 17.8036C89.9886 17.5663 89.8727 17.2848 89.8727 16.9591C89.8727 16.6279 89.9886 16.3464 90.2205 16.1146C90.4578 15.8828 90.7393 15.7669 91.065 15.7669C91.3962 15.7669 91.6777 15.8828 91.9095 16.1146C92.1413 16.3464 92.2572 16.6279 92.2572 16.9591C92.2572 17.1744 92.202 17.3731 92.0916 17.5552C91.9868 17.7374 91.8433 17.8837 91.6611 17.994C91.4845 18.0989 91.2858 18.1514 91.065 18.1514Z"
        fill="currentColor"
      />
      <path
        d="M99.7026 18.3169C98.9354 18.3169 98.2344 18.1679 97.5996 17.8699C96.9649 17.5663 96.4598 17.1302 96.0845 16.5617C95.7091 15.9876 95.5214 15.2922 95.5214 14.4752C95.5214 13.846 95.6401 13.3161 95.8775 12.8856C96.1148 12.455 96.4515 12.1018 96.8876 11.8258C97.3236 11.5498 97.8397 11.3318 98.4358 11.1717C99.032 11.0116 99.6888 10.8874 100.406 10.7991C101.118 10.7108 101.72 10.6335 102.211 10.5673C102.708 10.5011 103.086 10.3962 103.346 10.2527C103.605 10.1092 103.735 9.87734 103.735 9.5572V9.25913C103.735 8.39254 103.475 7.71086 102.956 7.21409C102.443 6.71179 101.704 6.46065 100.738 6.46065C99.8213 6.46065 99.0734 6.66212 98.4938 7.06505C97.9198 7.46799 97.5168 7.94268 97.285 8.48913L95.8857 7.98408C96.1728 7.2886 96.5702 6.73387 97.078 6.3199C97.5858 5.9004 98.1543 5.59958 98.7836 5.41743C99.4128 5.22976 100.05 5.13592 100.696 5.13592C101.182 5.13592 101.687 5.1994 102.211 5.32635C102.741 5.4533 103.232 5.67409 103.685 5.98871C104.138 6.29782 104.505 6.73111 104.786 7.2886C105.068 7.84057 105.209 8.54157 105.209 9.3916V18.0272H103.735V16.0152H103.644C103.467 16.3906 103.205 16.7549 102.857 17.1081C102.509 17.4614 102.073 17.7512 101.549 17.9775C101.025 18.2038 100.409 18.3169 99.7026 18.3169ZM99.9013 16.9674C100.685 16.9674 101.364 16.7935 101.938 16.4458C102.512 16.098 102.954 15.6371 103.263 15.0631C103.577 14.4835 103.735 13.846 103.735 13.1505V11.3125C103.624 11.4173 103.439 11.5112 103.18 11.594C102.926 11.6768 102.631 11.7513 102.294 11.8175C101.963 11.8782 101.632 11.9307 101.301 11.9748C100.969 12.019 100.671 12.0576 100.406 12.0907C99.6888 12.179 99.0761 12.317 98.5683 12.5047C98.0605 12.6924 97.6714 12.9518 97.4009 13.283C97.1304 13.6087 96.9952 14.0281 96.9952 14.5415C96.9952 15.3142 97.2712 15.9131 97.8232 16.3381C98.3751 16.7576 99.0678 16.9674 99.9013 16.9674Z"
        fill="currentColor"
      />
      <path
        d="M109.145 18.0272V5.30979H110.627V18.0272H109.145ZM109.899 3.12399C109.595 3.12399 109.336 3.02188 109.121 2.81765C108.905 2.6079 108.798 2.35676 108.798 2.06421C108.798 1.77167 108.905 1.52328 109.121 1.31905C109.336 1.1093 109.595 1.00443 109.899 1.00443C110.202 1.00443 110.462 1.1093 110.677 1.31905C110.892 1.52328 111 1.77167 111 2.06421C111 2.35676 110.892 2.6079 110.677 2.81765C110.462 3.02188 110.202 3.12399 109.899 3.12399Z"
        fill="currentColor"
      />
      <rect width="22" height="22" rx="11" fill="#00CC4F" />
      <path
        d="M12.3123 14.5928C12.3128 13.28 11.8513 12.0073 11.0058 10.99H5.12672C4.44238 10.99 4.28685 11.5701 4.69123 11.9976L10.4459 17.646C10.8192 17.9818 11.2236 17.9513 11.4724 17.5239C12.0206 16.6408 12.3111 15.6269 12.3123 14.5928ZM9.66825 7.4178C9.66825 8.79173 10.166 10.0435 11.0058 10.99H16.8849C17.5693 10.99 17.7248 10.4099 17.2893 10.013L11.5346 4.36461C11.1925 3.99823 10.7881 4.05929 10.5392 4.48674C10.0104 5.37216 9.66825 6.34918 9.66825 7.4178Z"
        fill="#F7F7F7"
      />
    </svg>
  );
};
