import { SerializedError } from "@reduxjs/toolkit";
import RestService, { ApiResponseError } from "shared/api/RestService";
import {
  AccountModel,
  CustomerAgreement,
  EvivaAccountStatus,
} from "shared/models/account/AccountModel";
import { AumChart } from "shared/models/account/AumChart";
import { AumChartParams } from "shared/models/aum/AumChartParams";
import {
  AdvisorPendingTask,
  PendingTasks,
} from "shared/models/pending_tasks/PendingTasksModel";
import { API_EXCEPTIONS } from "shared/utils/common";
import { FaApiRoutes, INTERCOM_VERIFICATION_V1 } from "shared/utils/routes";

interface AccountError extends SerializedError {
  code: EvivaAccountStatus;
}

interface IntercomTokenResponse {
  intercom_verification_token: string;
}

export const AccountService = {
  async postCustomAgreements(custom_agreements: CustomerAgreement[]) {
    await RestService.post<AccountModel>(
      FaApiRoutes.Users.Me.CustomAgreements,
      {
        custom_agreements,
      },
    );
  },

  async getMe(): Promise<AccountModel> {
    const MOCK = false;
    if (MOCK) {
      return await AccountService.mockGet();
    }
    try {
      return await RestService.get<AccountModel>(FaApiRoutes.Users.Me.Path);
    } catch (err) {
      const error = err as ApiResponseError;
      const headers = error.request.getAllResponseHeaders();
      if (headers && headers.includes("x-sb-reason", "allowed-countries")) {
        const decoratedError: AccountError = {
          message: "Sorry, your country not supported yet.",
          code: "blocked_country",
        };
        throw decoratedError;
      }
      if (error.status === API_EXCEPTIONS.FORBIDDEN) {
        const forbiddenError = error.data?.error || {
          streetbeat_account_reason: undefined,
          streetbeat_account_status: undefined,
        };
        const {
          streetbeat_account_reason = error.message,
          streetbeat_account_status = "deleted",
        } = forbiddenError;
        const decoratedError: AccountError = {
          message: streetbeat_account_reason,
          code: streetbeat_account_status,
        };
        throw decoratedError;
      }
      if (error.status === API_EXCEPTIONS.MAINTENANCE) {
        const decoratedError: AccountError = {
          message: "We are currently under maintenance and will be back soon.",
          code: "maintenance",
        };
        throw decoratedError;
      }
      if (error.status === API_EXCEPTIONS.OUT_OF_SERVICE) {
        const decoratedError: AccountError = {
          message:
            "We are experiencing downtime, our services will be restarted as soon as possible.",
          code: "out_of_service",
        };
        throw decoratedError;
      }
      throw error;
    }
  },

  // use this to test different action required states
  async mockGet(): Promise<AccountModel> {
    const r = await RestService.get<AccountModel>(FaApiRoutes.Users.Me.Path);
    if (r.client.summary.alpaca_details?.brokerage_account) {
      r.client.summary.kyc_status = "IN_PROGRESS";
      r.client.summary.alpaca_details.brokerage_account.status =
        "ACTION_REQUIRED";
      r.client.summary.alpaca_details.brokerage_account.last_event = {
        account_id: "",
        account_number: "",
        at: new Date().toISOString(),
        event_id: 1,
        kyc_results: {
          additional_information: "additional_information",
          indeterminate: {
            // IDENTITY_VERIFICATION: {},
            // TAX_IDENTIFICATION: {},
            // ADDRESS_VERIFICATION: {},
            // DATE_OF_BIRTH: {},
            // DATE_OF_BIRTH_INTERNATIONAL: {},
            // PEP: {},
            // FAMILY_MEMBER_PEP: {},
            // CONTROL_PERSON: {},
            // AFFILIATED: {},
            // OTHER: {},
            // COUNTRY_NOT_SUPPORTED: {},
            // INVALID_IDENTITY_PASSPORT: {},
            // WATCHLIST_HIT: {},
            // W8BEN_CORRECTION: {},
            // SELFIE_VERIFICATION: {},
            // OTHER_PARTNER: {},
          },
        },
        status_from: "SUBMITTED",
        status_to: "ACTION_REQUIRED",
        reason: "Testing reason",
      };
    }
    return r;
  },

  async getAumChart(params?: AumChartParams): Promise<AumChart> {
    return RestService.get(FaApiRoutes.Advisor.AumChart, params);
  },

  async getPendingTasks(): Promise<PendingTasks[]> {
    const response: PendingTasks[] = await RestService.get(
      FaApiRoutes.Advisor.PendingTasks,
    );
    return response;
  },

  async getIntercomVerificationToken(): Promise<IntercomTokenResponse> {
    return RestService.get<IntercomTokenResponse>(
      `${INTERCOM_VERIFICATION_V1}`,
    );
  },
};
