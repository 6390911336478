import { Menu } from "@headlessui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "shared/components/controls/Button";
import { OptionsMenu } from "shared/components/controls/OptionsMenu";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import CheckIcon from "shared/icons/CheckIcon";
import { StarIcon } from "shared/icons/StarIcon";
import { selectWatchlists } from "shared/store/watchlist/WatchlistReducer";
import { WatchlistThunks } from "shared/store/watchlist/WatchlistThunks";
import { CreateWatchlistModal } from "./CreateWatchlistModal";

type Props = {
  symbol: string;
};

export const AddToWatchlistButton: React.FC<Props> = ({ symbol }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { watchlists } = useSelector(selectWatchlists());
  const [showCreateWatchlistModal, setShowCreateWatchlistModal] =
    useState(false);

  const symbolWatchlists = watchlists.filter((w) => w.assets.includes(symbol));

  const isInWatchlist = (watchlistId: string) => {
    if (symbol && watchlists) {
      const filtered = watchlists.filter((el) => el.id === watchlistId);
      if (filtered && filtered.length > 0) {
        return filtered[0].assets.indexOf(symbol) !== -1;
      }
    }
    return false;
  };

  const onWatchlistSave = async (watchilstId: string) => {
    if (watchlists && symbol) {
      const isIn = isInWatchlist(watchilstId);
      const name = watchlists.find((w) => w.id === watchilstId)?.name;
      if (!name) {
        return;
      }
      if (isIn) {
        dispatch(
          WatchlistThunks.removeSymbol({
            id: watchilstId,
            symbol,
          }),
        );
      } else {
        await dispatch(
          WatchlistThunks.addSymbol({
            id: watchilstId,
            watchlistData: { name, symbol },
          }),
        );
      }
    }
  };

  return (
    <div>
      <CreateWatchlistModal
        symbol={symbol}
        open={showCreateWatchlistModal}
        setOpen={setShowCreateWatchlistModal}
      />
      <OptionsMenu
        button={
          <Button
            label="watchlists.add"
            leftIcon={
              <div className="pb-0.5">
                <StarIcon className="aspect-square w-5 text-sb-green-700" />
              </div>
            }
          />
        }
      >
        <div className="w-56 select-none space-y-1 p-2">
          {watchlists.map((w) => (
            <div
              key={w.id}
              className="flex cursor-pointer items-center justify-between gap-2 rounded-xl p-2 font-semibold duration-150 hover:bg-sb-gray-100"
              onClick={() => onWatchlistSave(w.id)}
            >
              <div>{w.name}</div>
              {symbolWatchlists.includes(w) && (
                <CheckIcon className="aspect-square w-4 text-sb-green-600" />
              )}
            </div>
          ))}
          <Menu.Item>
            {({ close }) => {
              return (
                <div
                  className="cursor-pointer pt-2 text-center text-sb-green-700"
                  onClick={() => {
                    close();
                    setShowCreateWatchlistModal(true);
                  }}
                >
                  {t("watchlist.create")}
                </div>
              );
            }}
          </Menu.Item>
        </div>
      </OptionsMenu>
    </div>
  );
};
