import React, { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DropdownSelect from "shared/components/controls/DropdownSelect";
import { Inputs } from "shared/components/hoc/Inputs";
import { Labels } from "shared/components/hoc/Labels";
import { Layouts } from "shared/components/hoc/Layouts";
import { useAvailableCountries } from "shared/hooks/useAvailableCountries";
import { Onboarding } from "shared/models/onboarding/OnboardingModel";
import { OnboardingThunks } from "shared/store/onboarding/OnboardingThunks";
import { ISOCountryCode } from "shared/types/Countries";
import { BirthdayInput } from "./BirthdayInput";
import { useAccount } from "shared/hooks/useAccount";
import { useDispatch } from "shared/hooks/useDispatch";
import { Button } from "shared/components/controls/Button";

interface W8BenFormValues extends Onboarding {
  firstName?: string;
  lastName?: string;
  nationality?: ISOCountryCode | undefined;
  street?: string;
  city?: string;
  postalCode?: string;
  state?: string;
  taxCountry?: ISOCountryCode;
  taxId?: string;
  birthday?: string;
}

export const UpdateW8BenForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const countries = useAvailableCountries();
  const { account } = useAccount();
  const { identity, contact } =
    account?.client?.summary?.alpaca_details?.brokerage_account || {};

  const { city, postal_code, street_address, state } = contact || {};
  const {
    family_name,
    middle_name,
    given_name,
    country_of_citizenship,
    country_of_tax_residence,
    date_of_birth,
  } = identity || {};
  const fetchCountries = useCallback(() => {
    dispatch(OnboardingThunks.fetchAvailableCountries());
  }, [dispatch]);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      firstName: given_name,
      lastName: family_name,
      nationality: country_of_citizenship,
      street: street_address?.join(" "),
      city,
      postalCode: postal_code,
      state,
      taxCountry: country_of_tax_residence,
      taxId: "",
      birthday: date_of_birth,
      middleName: middle_name,
    },
  });

  if (!contact || !identity) {
    return null;
  }

  const countriesOptions = countries.map(({ t, code }) => ({
    label: t,
    value: code,
  }));

  const confirmUpdatedValue = (values: W8BenFormValues) => {
    navigate(`/settings/action_required/w8ben`); //TODO: { w8Ben: values }
  };

  return (
    <Layouts.Grid>
      <Labels.H2>{t("settings.updateApplication.w8Ben.title")}</Labels.H2>
      <Controller
        name="firstName"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Inputs.Text
            value={value}
            label="settings.updateApplication.w8Ben.firstName"
            error={errors.firstName?.message}
            autoCapitalize="none"
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="lastName"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Inputs.Text
            value={value}
            label="settings.updateApplication.w8Ben.lastName"
            error={errors.lastName?.message}
            autoCapitalize="none"
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="nationality"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <DropdownSelect
            title={t("settings.updateApplication.w8Ben.nationality")}
            selected={value || ""}
            options={countriesOptions}
            onSelect={onChange}
          />
        )}
      />
      <Controller
        name="middleName"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Inputs.Text
            value={value}
            label="settings.updateApplication.w8Ben.middleName"
            error={errors.middleName?.message}
            autoCapitalize="none"
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="street"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Inputs.Text
            value={value}
            label="settings.updateApplication.w8Ben.fullStreetName"
            error={errors.street?.message}
            autoCapitalize="none"
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="city"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Inputs.Text
            value={value}
            label="settings.updateApplication.w8Ben.city"
            error={errors.city?.message}
            autoCapitalize="none"
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="postalCode"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Inputs.Text
            value={value}
            label="settings.updateApplication.w8Ben.postalCode"
            error={errors.postalCode?.message}
            autoCapitalize="none"
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="state"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Inputs.Text
            value={value}
            label="settings.updateApplication.w8Ben.state"
            error={errors.state?.message}
            autoCapitalize="none"
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="taxCountry"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <DropdownSelect
            title={t("settings.updateApplication.w8Ben.taxCountry")}
            selected={value || ""}
            options={countriesOptions}
            onSelect={onChange}
          />
        )}
      />
      <Controller
        name="taxId"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Inputs.Text
            value={value}
            label="settings.updateApplication.w8Ben.taxId"
            error={errors.taxId?.message}
            autoCapitalize="none"
            onChange={onChange}
          />
        )}
      />
      {/* add label about invalid and special rules */}
      <Controller
        name="birthday"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <BirthdayInput value={value || ""} onChange={onChange} />
        )}
      />
      <Button
        label="settings.updateApplication.updateAndConfirm"
        onClick={handleSubmit(confirmUpdatedValue)}
      />
    </Layouts.Grid>
  );
};
