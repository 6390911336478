import { DateTime } from "luxon";
import {
  BrokerageAccountContact,
  PatchableBrokerageAccountIdentity,
  BrokerageAccountIdentity,
  BrokerageAccountDisclosures,
  BrokerageAccountDocumentUpload,
  BrokerageAccountW8BEN,
} from "shared/models/brokerage_account/BrokerageAccountModel";
import { Onboarding } from "shared/models/onboarding/OnboardingModel";
import { NET_VALUES } from "shared/utils/common";
import { DATE_FORMAT } from "shared/utils/consts";
import { convertFullName } from "shared/utils/converts";
import { formatStreetAddress, KYCRequirements } from "shared/utils/kyc_utils";

class ValidateError extends Error {}

class Validator<T> {
  require<V>(key: keyof T, value: V | undefined): V {
    if (value === undefined) throw Error(`${key.toString()} is required`);
    return value;
  }

  if<V>(required: boolean, key: keyof T, value: V | undefined): V | undefined {
    if (required && value === undefined)
      throw Error(`${key.toString()} is required`);
    return value;
  }
}

const validateContact = (input: Onboarding): BrokerageAccountContact => {
  const validator = new Validator<BrokerageAccountContact>();

  const street = validator.require("street_address", input.street);

  const street_address = formatStreetAddress(street, input.addressUnit);
  const city = validator.require("city", input.city);
  const state = validator.if(input.taxCountry === "USA", "state", input.state);
  // Alpaca requires alpha-numeric postal codes
  const postal_code = (input.postalCode || "").replace(/[^0-9a-z]/gi, "");
  const phone_number = input.phone_number;

  return {
    street_address,
    city,
    state,
    postal_code,
    phone_number,
  };
};

const validateIncome = (
  input: Onboarding,
): PatchableBrokerageAccountIdentity => {
  const annual_income = NET_VALUES.find(
    (nv) => nv.value === input.annualIncome,
  );
  const liquid_assets = NET_VALUES.find(
    (nv) => nv.value === input.liquidNetWorth,
  );

  if (!annual_income)
    throw new ValidateError("errors.kyc.required_fields.annual_income");
  if (!liquid_assets)
    throw new ValidateError("errors.kyc.required_fields.liquid_assets");

  return {
    annual_income_min: annual_income.min,
    annual_income_max: annual_income.max,
    liquid_net_worth_min: liquid_assets.min,
    liquid_net_worth_max: liquid_assets.max,
  };
};

const validateIdentity = (input: Onboarding): BrokerageAccountIdentity => {
  const validator = new Validator<BrokerageAccountIdentity>();
  const isoBirthday = validator.require("date_of_birth", input.birthday);
  const date_of_birth = DateTime.fromISO(isoBirthday).toFormat(DATE_FORMAT);

  const country_of_tax_residence = validator.require(
    "country_of_tax_residence",
    input.taxCountry,
  );
  const tax_id_type = input.taxIdType || "NOT_SPECIFIED";
  const tax_id =
    input.taxIdNotLegallyRequired === true
      ? "Not legally required"
      : input.taxId;

  const funding_source = input.fundingSource;

  if (!funding_source?.length)
    throw new ValidateError("errors.kyc.required_fields.funding_source");

  const {
    annual_income_min,
    annual_income_max,
    total_net_worth_min,
    total_net_worth_max,
    liquid_net_worth_min,
    liquid_net_worth_max,
  } = validateIncome(input);

  return {
    given_name: validator.require("given_name", input.firstName),
    family_name: validator.require("family_name", input.lastName),
    ...(input.middleName && { middle_name: input.middleName }),
    date_of_birth,
    tax_id,
    tax_id_type,
    country_of_citizenship: input.nationality,
    country_of_tax_residence,
    funding_source,
    annual_income_min,
    annual_income_max,
    total_net_worth_min,
    total_net_worth_max,
    liquid_net_worth_min,
    liquid_net_worth_max,
  };
};

const validateDisclosures = (
  input: Onboarding,
): BrokerageAccountDisclosures => {
  const validator = new Validator<BrokerageAccountDisclosures>();
  const employed = input.employmentStatus === "employed";
  return {
    is_control_person: !!input.isControlPerson,
    is_affiliated_exchange_or_finra: !!input.isAffiliatedExchangeOrFinra,
    is_politically_exposed: !!input.isPoliticallyExposed,
    immediate_family_exposed: !!input.isPoliticallyExposed,
    employment_status: input.employmentStatus,
    employer_name: validator.if(employed, "employer_name", input.employerName),
    employer_address: validator.if(
      employed,
      "employer_address",
      input.employerAddress,
    ),
    employment_position: validator.if(
      employed,
      "employment_position",
      input.employmentPosition,
    ),
  };
};

const validateDocuments = (
  input: Onboarding,
  requirements: KYCRequirements,
): BrokerageAccountDocumentUpload[] => {
  const documents: BrokerageAccountDocumentUpload[] = [];

  if (requirements.photoId) {
    if (!input.nationalIdPhoto)
      throw new ValidateError(
        "errors.kyc.required_fields.government_issue_photo",
      );
    documents.push({
      document_type: "identity_verification",
      content: input.nationalIdPhoto,
      mime_type: "image/jpeg",
    });
  }

  if (requirements.visaOrResidenceCardRequired) {
    if (!input.visaOrResidenceCardProofPhoto) {
      throw new ValidateError(
        "errors.kyc.required_fields.visa_or_residence_card_photo",
      );
    }

    documents.push(input.visaOrResidenceCardProofPhoto);
  }

  if (input.addressProof) {
    documents.push(input.addressProof);
  }

  if (input.approvalLetter) {
    documents.push(input.approvalLetter);
  }

  return documents;
};

const validateW8BEN = (
  identity: BrokerageAccountIdentity,
  contact: BrokerageAccountContact,
  signature: string,
): BrokerageAccountDocumentUpload => {
  const validator = new Validator<BrokerageAccountW8BEN>();

  const now = DateTime.now();
  const date = now.toFormat(DATE_FORMAT);
  const timestamp = now.toISO();

  const city_state = [contact.city, contact.state].filter((i) => !!i).join(" ");

  const address = contact.street_address.filter((i) => !!i).join(", ");

  const full_name = convertFullName([
    identity.given_name,
    identity.middle_name,
    identity.family_name,
  ]);

  const w8ben: BrokerageAccountW8BEN = {
    full_name,
    country_citizen: validator.require(
      "country_citizen",
      identity.country_of_citizenship,
    ),
    permanent_address_street: validator.require(
      "permanent_address_street",
      address,
    ),
    permanent_address_city_state: city_state,
    permanent_address_country: identity.country_of_tax_residence,
    foreign_tax_id: identity.tax_id,
    residency: identity.country_of_tax_residence,
    date_of_birth: validator.require("date_of_birth", identity.date_of_birth),
    signer_full_name: validator.require("signer_full_name", signature),
    revision: "10-2021",
    ip_address: "[filled_by_backend]",
    date,
    timestamp,
  };

  // https://alpaca.markets/docs/api-references/broker-api/accounts/accounts/#parameters-6
  if (!identity.tax_id) w8ben.ftin_not_required = true;

  return {
    document_type: "w8ben",
    mime_type: "application/json",
    content_data: w8ben,
  };
};

const validateFaIdentity = (input: Onboarding): BrokerageAccountIdentity => {
  const validator = new Validator<BrokerageAccountIdentity>();
  const isoBirthday = validator.require("date_of_birth", input.birthday);
  const date_of_birth = DateTime.fromISO(isoBirthday).toFormat(DATE_FORMAT);

  const country_of_tax_residence = validator.require(
    "country_of_tax_residence",
    input.taxCountry,
  );
  const tax_id_type = input.taxIdType || "NOT_SPECIFIED";
  const tax_id =
    input.taxIdNotLegallyRequired === true
      ? "Not legally required"
      : input.taxId;

  return {
    given_name: validator.require("given_name", input.firstName),
    family_name: validator.require("family_name", input.lastName),
    ...(input.middleName && { middle_name: input.middleName }),
    date_of_birth,
    tax_id,
    tax_id_type,
    country_of_citizenship: input.nationality,
    country_of_tax_residence,
  };
};

const accountValidation = {
  validateContact,
  validateIdentity,
  validateIncome,
  validateDisclosures,
  validateDocuments,
  validateW8BEN,
};

export const faAccountValidation = {
  validateContact,
  validateFaIdentity,
  validateDisclosures,
  validateDocuments,
  validateW8BEN,
};

export default accountValidation;
