import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "shared/components/controls/Button";
import { Inputs } from "shared/components/hoc/Inputs";
import { Labels } from "shared/components/hoc/Labels";
import { Layouts } from "shared/components/hoc/Layouts";
import { BirthdayInput } from "../components/BirthdayInput";

export const UpdateBirthdayForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [taxId, setTaxId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const navigateAndPassValidDateOfBirth = () => {
    navigate(`/settings/action_required/w8ben`); //TODO: { dateOfBirth, taxId }
  };

  const updateKyc = async () => {
    if (dateOfBirth === null) {
      setErrorMessage(t("settings.updateApplication.invalidBirthDate"));
      return;
    }

    if (!dateOfBirth) {
      setErrorMessage(t("settings.updateApplication.emptyBirthDate"));
      return;
    }

    if (!taxId || !taxId.trim()) {
      setErrorMessage(t("settings.updateApplication.taxIdRequired"));
      return;
    }

    navigateAndPassValidDateOfBirth();
  };

  return (
    <Layouts.Grid>
      <Labels.H2>{t("settings.updateApplication.toUpdateDOP")}</Labels.H2>
      <BirthdayInput value={dateOfBirth} onChange={setDateOfBirth} />
      <Inputs.Text
        value={taxId}
        label="settings.updateApplication.taxId"
        autoCapitalize="characters"
        onChange={setTaxId}
      />
      {errorMessage ? <div>{errorMessage}</div> : null}
      <Button
        label="settings.updateApplication.updateAndConfirm"
        onClick={updateKyc}
      />
    </Layouts.Grid>
  );
};
