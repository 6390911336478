import { useTranslation } from "react-i18next";
import useAssetsExtras from "shared/hooks/useAssetsExtras";
import useRealtimeTicker from "shared/hooks/useRealtimeTicker";
import { CurrencyFormat, pctFormat } from "shared/utils/currency";
import { CompactFormat } from "shared/utils/utils";
import { Stat } from "./Stat";

type Props = {
  assetId?: string;
};

export const CompactAssetStats: React.FC<Props> = ({ assetId }) => {
  const { t } = useTranslation();
  const { stats } = useAssetsExtras(assetId ? assetId : "");
  const { bidPrice, askPrice } = useRealtimeTicker(assetId ? assetId : "");

  return (
    <>
      <hr className="border-sb-gray-200" />
      <div className="mt-4 grid grid-cols-2">
        <Stat
          name={t("assetStats.bidPrice")}
          value={CurrencyFormat.format(bidPrice)}
        />
        <Stat
          name={t("assetStats.askPrice")}
          value={CurrencyFormat.format(askPrice)}
        />
        <Stat
          name={t("assetStats.open")}
          value={
            stats?.open
              ? CurrencyFormat.format(stats.open)
              : t("assetStats.noValue")
          }
        />
        <Stat
          name={t("assetStats.volume")}
          value={
            stats?.volume
              ? CompactFormat.format(stats.volume)
              : t("assetStats.noValue")
          }
        />
        <Stat
          name={t("assetStats.high")}
          value={
            stats?.high
              ? CurrencyFormat.format(stats.high)
              : t("assetStats.noValue")
          }
        />
        <Stat
          name={t("assetStats.avgVolume")}
          value={
            stats?.avg30Volume
              ? CurrencyFormat.format(stats.avg30Volume)
              : t("assetStats.noValue")
          }
        />
        <Stat
          name={t("assetStats.low")}
          value={
            stats?.low
              ? CurrencyFormat.format(stats.low)
              : t("assetStats.noValue")
          }
        />
        <Stat
          name={t("assetStats.marketCap")}
          value={
            stats?.marketcap
              ? CompactFormat.format(stats.marketcap)
              : t("assetStats.noValue")
          }
        />
        <Stat
          name={t("assetStats.weekHigh")}
          value={
            stats?.week52high
              ? CurrencyFormat.format(stats.week52high)
              : t("assetStats.noValue")
          }
        />
        <Stat
          name={t("assetStats.weekLow")}
          value={
            stats?.week52low
              ? CurrencyFormat.format(stats.week52low)
              : t("assetStats.noValue")
          }
        />
        <Stat
          name={t("assetStats.peRatio")}
          value={
            stats?.peRatio ? stats.peRatio.toFixed(2) : t("assetStats.noValue")
          }
        />
        <Stat
          name={t("assetStats.divYield")}
          value={
            stats?.dividendYield
              ? pctFormat.format(stats.dividendYield)
              : t("assetStats.noValue")
          }
        />
      </div>
    </>
  );
};
