import React, { useState } from "react";
import ReactCountryFlag from "react-country-flag";

import { OnboardingFooter } from "./components/OnboardingFooter";
import { OnboardingPartProps } from "./OnboardingFlow";
import { LetUsKnowYouColumn } from "./components/LetUsKnowYouColumn";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import {
  OnboardingActions,
  selectOnboarding,
} from "shared/store/onboarding/OnboardingReducer";
import { useAccount } from "shared/hooks/useAccount";
import { phoneCountryCodes } from "shared/utils/phoneCountryCodes";
import PhoneVerificationService from "shared/services/phone_verification/PhoneVerificationService";
import { OnboardingContainer } from "shared/components/common/OnboardingContainer";
import DropdownSelect from "shared/components/controls/DropdownSelect";
import { useTranslation } from "react-i18next";

export const LegalNamePhoneVerification: React.FC<OnboardingPartProps> = ({
  onNext,
  onPrevious,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [phoneCallingCode, setPhoneCallingCode] = useState("1");
  const [phoneNumber, setPhoneNumber] = useState("");
  const { firstName, middleName, lastName } = useSelector(selectOnboarding);
  const { phoneIsVerified } = useAccount();

  const phoneCallingCodeOptions = phoneCountryCodes.map((pcc) => ({
    label: `+${pcc.callingCode}`,
    value: pcc.callingCode,
    icon: <ReactCountryFlag countryCode={pcc.symbol} />,
  }));

  const handleOnValueChange = (
    value: string,
    type: "firstName" | "lastName" | "middleName",
  ) => {
    dispatch(OnboardingActions.update({ [type]: value }));
  };

  const nextHandler = async () => {
    if (phoneIsVerified) {
      onNext && onNext("address");
      return;
    }

    const fullPhoneNumber = `+${phoneCallingCode}${phoneNumber}`;
    setSubmitting(true);
    try {
      await PhoneVerificationService.sendSmsVerification(fullPhoneNumber);
      dispatch(OnboardingActions.updatePhoneNumber(fullPhoneNumber));
      // save phone in the async
      onNext && onNext();
    } catch (error) {
      throw error;
    } finally {
      setSubmitting(false);
    }
  };

  const prevHandler = () => {
    onPrevious && onPrevious();
  };

  const disabledContinueButton =
    !firstName || !lastName || !phoneCallingCode || !phoneNumber;

  const inputClassName =
    "peer block w-full appearance-none border-0 bg-sb-gray-100 px-4 py-2.5 text-base text-gray-900 focus:border-sb-green-700 focus:outline-none focus:ring-0 disabled:bg-sb-gray-100";

  return (
    <OnboardingContainer
      leftColumnContent={<LetUsKnowYouColumn />}
      rightColumnContent={
        <div className="mb-14 mt-20 flex flex-col">
          <div className="flex-1 items-stretch space-y-2">
            {!phoneIsVerified ? (
              <div className="mb-2 flex flex-row">
                <DropdownSelect
                  className="max-w-[120px] !rounded-r-none rounded-l-xl bg-sb-gray-100"
                  inputClassName="rounded-l-xl !rounded-r-none bg-sb-gray-100 !pb-5 !pt-5 px-5"
                  selected={phoneCallingCode}
                  options={phoneCallingCodeOptions}
                  onSelect={(o) => setPhoneCallingCode(`${o.value}`)}
                />
                <input
                  type="number"
                  inputMode="numeric"
                  className={inputClassName + " rounded-r-xl"}
                  placeholder={t("kyc.phoneNumber")}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
            ) : null}
            <input
              type="text"
              className={inputClassName + " + h-[52.5px] rounded-xl"}
              placeholder={t("kyc.firstName")}
              value={firstName}
              onChange={(e) => {
                handleOnValueChange(e.target.value, "firstName");
              }}
            />
            <input
              type="text"
              className={inputClassName + " + h-[52.5px] rounded-xl"}
              placeholder={t("kyc.middleName")}
              value={middleName}
              onChange={(e) => {
                handleOnValueChange(e.target.value, "middleName");
              }}
            />
            <input
              type="text"
              className={inputClassName + " + h-[52.5px] rounded-xl"}
              placeholder={t("kyc.lastName")}
              value={lastName}
              onChange={(e) => {
                handleOnValueChange(e.target.value, "lastName");
              }}
            />
          </div>
          <OnboardingFooter
            loading={submitting}
            onNext={nextHandler}
            onPrev={prevHandler}
            nextDisabled={disabledContinueButton}
          />
        </div>
      }
    />
  );
};
