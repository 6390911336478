import ClientsReducer from "shared/store/clients/ClientsReducer";

import {
  AnyAction,
  combineReducers,
  configureStore,
  Reducer,
} from "@reduxjs/toolkit";

import { persistReducer, persistStore } from "redux-persist";
import AccountReducer from "./account/AccountReducer";
import AggregatedAumReducer from "./aggregated_aum/AggregatedAumReducer";
import AppReducer from "./app/AppReducer";
import FeeTemplatesReducer from "./fee_templates/FeeTemplatesReducer";
import PortfoliosReducer from "./portfolios/PortfoliosReducer";
import MarketInfoReducer from "./market_info/MarketInfoReducer";
import RealtimeSubscriptionsReducer from "./realtime_prices/RealtimeSubscriptionsReducer";
import RealtimePricesReducer from "./realtime_prices/RealtimePricesReducer";
import AssetStatsReducer from "./assets/asset_stats/AssetStatsReducer";
import AssetInfoReducer from "./assets/asset_info/AssetInfoReducer";
import AssetChartReducer from "./assets/asset_chart/AssetChartReducer";
import BacktestingPortfolioChartsReducer from "./backtesting_portfolio_charts/BacktestingPortfolioChartsReducer";
import ProposalsReducer from "./proposals/ProposalsReducer";
import CreateOrderReducer from "./create_order/CreateOrderReducer";
import ClientDetailsReducer from "./client_details/ClientDetailsReducer";
import AssetsReducer from "./assets/AssetsReducer";
import TopPerformancesReducer from "./top_performances/TopPerformancesReducer";
import MacroeconomicConditionsReducer from "./macroeconomic_conditions/MacroeconomicConditionsReducer";
import FeePayoutTermsReducer from "./fee_payout_term/FeePayoutTermsReducer";
import { ManageDuplicateActions } from "./middleware";
import RecentOrdersReducer from "./recent_orders/RecentOrdersReducer";
import OnboardingReducer from "./onboarding/OnboardingReducer";
import AsyncStorage from "@react-native-async-storage/async-storage";
import WatchlistReducer from "./watchlist/WatchlistReducer";
import PortfolioAssistantReducer from "./portfolio_assistant/PortfolioAssistantReducer";
import ContactsReducer from "./contacts/ContactsReducer";

const rootPersistConfig = {
  key: "root",
  storage: AsyncStorage,
  version: 1,
  whitelist: ["onboarding"],
};

const assetReducer = combineReducers({
  chart: AssetChartReducer,
  stats: AssetStatsReducer,
  info: AssetInfoReducer,
});

const reducers = {
  account: AccountReducer,
  aggregatedAum: AggregatedAumReducer,
  app: AppReducer,
  asset: assetReducer,
  assets: AssetsReducer,
  backtestingPortfolioCharts: BacktestingPortfolioChartsReducer,
  clientDetails: ClientDetailsReducer,
  clients: ClientsReducer,
  contacts: ContactsReducer,
  createOrder: CreateOrderReducer,
  feeTemplates: FeeTemplatesReducer,
  macroeconomicConditions: MacroeconomicConditionsReducer,
  marketInfo: MarketInfoReducer,
  onboarding: OnboardingReducer,
  payoutTerms: FeePayoutTermsReducer,
  portfolioAssistant: PortfolioAssistantReducer,
  portfolios: PortfoliosReducer,
  proposals: ProposalsReducer,
  realtimePrices: RealtimePricesReducer,
  realtimeSubscriptions: RealtimeSubscriptionsReducer,
  recentOrders: RecentOrdersReducer,
  topPerformances: TopPerformancesReducer,
  watchlist: WatchlistReducer,
};

const combinedReducers = combineReducers(reducers);

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "[APP]/clearStoreRequest") {
    // we want to keep the user settings even after logging out
    return combinedReducers(undefined, action);
  }

  return combinedReducers(state, action);
};

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer as any),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(ManageDuplicateActions),
});

export default store;
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof combinedReducers>;
