import { Triangle } from "shared/components/common/datatable/svg/Triangle";

export type SortDirection = "asc" | "desc";

type Props = {
  active?: SortDirection;
  onClick?: (direction: SortDirection) => void;
};

export const SortingArrows: React.FC<Props> = ({ active, onClick }) => {
  return (
    <div className="text-sb-gray-400">
      <div onClick={() => onClick && onClick("asc")}>
        <Triangle
          className={
            "h-3 w-3 cursor-pointer duration-150 hover:text-sb-gray-700 " +
            (active === "asc" ? "text-black" : "")
          }
        />
      </div>
      <div onClick={() => onClick && onClick("desc")}>
        <Triangle
          className={
            "h-3 w-3 rotate-180 cursor-pointer duration-150 hover:text-sb-gray-700 " +
            (active === "desc" ? "text-black" : "")
          }
        />
      </div>
    </div>
  );
};
