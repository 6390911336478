import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "shared/components/controls/Button";
import { VerifyEmailImage } from "shared/images/VerifyEmailImage";
import AuthService from "shared/services/auth/AuthService";
import emailVerificationService from "shared/services/email_verification/EmailVerificationService";
import { auth } from "shared/utils/firebase";

export const VerifyEmail: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const email = auth.currentUser?.email;

  const resendEmail = () => {
    emailVerificationService
      .sendEmailVerification("https://eviva.streetbeat.com")
      .then(() => {
        toast.success(t("verifyEmail.emailResent"));
      });
  };

  useEffect(() => {
    let refreshAuthTimeout: NodeJS.Timeout | null = null;

    const fetchUserStatus = async () => {
      await auth.currentUser?.reload();
      const user = auth.currentUser;
      if (!user?.emailVerified) {
        auth.currentUser?.reload();
        refreshAuthTimeout = setTimeout(fetchUserStatus, 2000);
      } else {
        navigate("/");
      }
    };

    fetchUserStatus();

    return () => {
      if (refreshAuthTimeout) {
        clearTimeout(refreshAuthTimeout);
      }
    };
  }, []);

  auth.currentUser?.reload();

  return (
    <div className="grid h-full place-items-center">
      <div className="max-w-2xl">
        <div className="mb-12 flex justify-center">
          <VerifyEmailImage className="w-[192px]" />
        </div>
        <div className="mb-2 text-xl font-semibold">
          {t("verifyEmail.title")}
        </div>
        <Trans i18nKey="verifyEmail.emailSent" values={{ email }}>
          <span className="ml-1 font-semibold"></span>
        </Trans>
        <div className="mt-4">
          <Trans i18nKey="verifyEmail.resend">
            <span
              className="ml-1 cursor-pointer font-semibold underline"
              onClick={resendEmail}
            ></span>
          </Trans>
        </div>
        <div className="mt-10 flex justify-between">
          <Button
            btnStyle="blank"
            label="avatar.menu.signOut"
            onClick={() => AuthService.signOut()}
          />
          <Button
            btnStyle="black"
            label="global.next"
            disabled={!auth.currentUser?.emailVerified}
            onClick={() => navigate("/")}
          />
        </div>
      </div>
    </div>
  );
};
