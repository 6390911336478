export const colorsConfig = {
  primary: "#61FF9B",
  secondary: "#50FAFA",
  neutral: "#A1A1A1",
  white: "#FFFFFF",
  black: "#111112",
  "gradient-start": "#329DFF",
  "gradient-end": "#5D05F9",
  "sb-blue": {
    100: "#D5EBFF",
    200: "#ACD8FF",
    300: "#84C4FF",
    400: "#5BB1FF",
    500: "#329DFF",
    600: "#0082F9",
    700: "#0065C1",
    800: "#004789",
    900: "#002A51",
    950: "#001B35",
  },
  "sb-green": {
    100: "#E0FFEB",
    200: "#C7FFDA",
    300: "#ADFFC9",
    400: "#94FFB9",
    500: "#61FF9B",
    600: "#2BF076",
    700: "#00AD3A",
    800: "#009431",
    900: "#007A29",
    light: "#00CC4F",
    dark: "#00FD86",
  },
  "sb-red": {
    100: "#FFDED6",
    200: "#FFBCAD",
    300: "#FF9B85",
    400: "#FF795C",
    500: "#FF5833",
    600: "#FA2D00",
    700: "#C22300",
    800: "#8A1900",
    900: "#520F00",
  },
  "sb-cyan": {
    100: "#E8FCFC",
    200: "#CFFCFC",
    300: "#B6FCFC",
    400: "#82FAFA",
    500: "#50FAFA",
    600: "#02EDED",
    700: "#04B2AF",
    800: "#009996",
    900: "#008387",
  },
  "sb-gray": {
    50: "#F7F7F7",
    90: "#202124",
    100: "#F5F5F6",
    200: "#E0E1E3",
    300: "#C7C9CC",
    400: "#B5B8BF",
    500: "#9B9FA7",
    600: "#4E5157",
    700: "#34363A",
    800: "#1E1F21",
    900: "#111112",
  },
  "sb-pink": {
    50: "#FFFDFE",
    100: "#FFE9EE",
    200: "#FFC0CF",
    300: "#FF88A4",
    400: "#FF507A",
    500: "#FF184F",
    600: "#DF0035",
    700: "#A60028",
    800: "#6E001A",
    900: "#36000D",
    950: "#1A0006",
  },
  "sb-magenta": {
    50: "#FFFFFF",
    100: "#FFF0F4",
    200: "#FFC7D4",
    300: "#FF9FB5",
    400: "#FF7695",
    500: "#FF4D76",
    600: "#FF154B",
    700: "#DC0033",
    800: "#A40026",
    900: "#6C0019",
    950: "#500012",
  },
  "sb-violet": {
    50: "#F3EEFB",
    100: "#E8DDF8",
    200: "#D1BBF1",
    300: "#BA9AEA",
    400: "#A378E3",
    500: "#8C56DC",
    600: "#6D2BCF",
    700: "#5521A1",
    800: "#3C1872",
    900: "#240E44",
    950: "#17092C",
  },
  olive: {
    50: "#F6F6D0",
    100: "#F3F3BF",
    200: "#ECEC9D",
    300: "#E6E67B",
    400: "#DFDF58",
    500: "#D9D936",
    600: "#B5B522",
    700: "#868619",
    800: "#565610",
    900: "#272707",
    950: "#101003",
  },
};
