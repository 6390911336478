import { useTranslation } from "react-i18next";
import { PillSelector } from "shared/components/controls/PillSelector";
import { FinancialAdvisorFeeStatus } from "shared/models/fees/FeesModel";

type Props = {
  feeStatuses: FinancialAdvisorFeeStatus[];
  setFeeStatuses: (feeStatuses: FinancialAdvisorFeeStatus[]) => void;
};

export const FeeStatusPicker: React.FC<Props> = ({
  feeStatuses,
  setFeeStatuses,
}) => {
  const { t } = useTranslation();

  const statusArray: FinancialAdvisorFeeStatus[] = [
    "calculation",
    "confirmed",
    "collected",
  ];

  const onFeeStatusSelected = (feeStatus: FinancialAdvisorFeeStatus) => {
    if (feeStatuses.includes(feeStatus)) {
      setFeeStatuses(feeStatuses.filter((f) => f !== feeStatus));
    } else {
      setFeeStatuses([...feeStatuses, feeStatus]);
    }
  };

  return (
    <PillSelector
      selectedValues={feeStatuses.map((f) => {
        return { label: t(`fees.statuses.${f}`), value: f };
      })}
      onPillRemoved={onFeeStatusSelected}
      placeholder={t("fees.statusPlaceholder")}
    >
      <div>
        {statusArray.map((s) => {
          return (
            <div
              key={"status_" + s}
              className="flex cursor-pointer items-center gap-4 px-4 py-2.5 duration-150 hover:bg-sb-gray-100"
              onClick={() => onFeeStatusSelected(s)}
            >
              <div className="pt-1">
                <input
                  type="checkbox"
                  checked={feeStatuses.includes(s)}
                  readOnly
                />
              </div>
              <div className="flex items-baseline gap-2">
                <div className="text-lg font-medium">
                  {t(`fees.statuses.${s}`)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </PillSelector>
  );
};
