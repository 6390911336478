import { useState } from "react";
import { PortfolioAssistantResponse } from "./responses/PortfolioAssistantResponse";
import { mapResponseContents } from "./utils";
import { AIAssistantMessageContent } from "shared/models/assistant/AIAssistantModel";
import { MinusIcon } from "shared/icons/MinusIcon";
import { PlusIcon } from "shared/icons/PlusIcon";

type Props = {
  content: AIAssistantMessageContent[];
  onPortfolioClicked?: (portfolioId: string) => Promise<any> | undefined;
  activePortfolioId?: string;
};

export const SystemResponse: React.FC<Props> = ({
  content,
  activePortfolioId,
  onPortfolioClicked,
}) => {
  const mappedItems = mapResponseContents(content);

  let components = [];
  let i = 0;
  for (let items of mappedItems) {
    if (items[0].type === "text") {
      components.push(
        <ExpandableSystemResponse
          items={items}
          key={"expandable_text_response_" + i}
        />,
      );
    } else if (items[0].type === "portfolio") {
      for (let item of items) {
        components.push(
          <PortfolioAssistantResponse
            key={"portfolio_response_" + i}
            content={item}
            active={item.portfolio?.id === activePortfolioId}
            onPortfolioClicked={onPortfolioClicked}
          />,
        );
      }
    }
    i++;
  }
  return <div>{components}</div>;
};

type ExpandableSystemResponseProps = {
  items: AIAssistantMessageContent[];
};

const ExpandableSystemResponse: React.FC<ExpandableSystemResponseProps> = ({
  items,
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="mb-2 text-sb-gray-500">
      <div className="flex items-start gap-2">
        {items.length > 1 && (
          <div
            className="cursor-pointer rounded-sm border-2 border-sb-gray-500"
            onClick={() => setExpanded((prev) => !prev)}
          >
            <div className="grid place-items-center">
              {expanded ? (
                <MinusIcon className="aspect-square w-4 stroke-sb-gray-600" />
              ) : (
                <PlusIcon className="aspect-square w-4 stroke-sb-gray-600" />
              )}
            </div>
          </div>
        )}
        <div className="space-y-2 italic">
          {expanded ? (
            items.map((item, idx) => {
              return (
                <div key={"expanded_system_" + idx}>{item.text?.value}</div>
              );
            })
          ) : (
            <div>{items[items.length - 1].text?.value}</div>
          )}
        </div>
      </div>
    </div>
  );
};
