import { useTranslation } from "react-i18next";
import { Panel } from "shared/components/hoc/Panels";
import useAssetsExtras from "shared/hooks/useAssetsExtras";
import { EarningsChart } from "./EarningsChart";

type Props = {
  assetId?: string;
};

export const AssetEarnings: React.FC<Props> = ({ assetId }) => {
  const { t } = useTranslation();
  const { earnings } = useAssetsExtras(assetId ? assetId : "");

  if (!earnings?.length) {
    return null;
  }

  return (
    <Panel>
      <div className="mb-8 text-xl font-semibold">
        {t("assetEarnings.title")}
      </div>
      <div>
        <EarningsChart assetId={assetId} />
      </div>
    </Panel>
  );
};
