import { useTranslation } from "react-i18next";
import { Modal } from "shared/components/common/Modal";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";
import { Layouts } from "shared/components/hoc/Layouts";
import { CheckCircleOutlined } from "shared/icons/CheckCircleOutlined";
import { CreateTradingOrder } from "shared/models/trading/TradingOrderModel";
import { getFormattedAmountValue } from "shared/utils/orders";
import { OrderSummaryRow } from "./OrderSummaryRow";

type Props = {
  order?: CreateTradingOrder;
  setOrder: (order?: CreateTradingOrder) => void;
};

export const OrderSuccessModal: React.FC<Props> = ({ order, setOrder }) => {
  const { t } = useTranslation();

  return (
    <Modal open={order !== undefined} setOpen={() => setOrder(undefined)}>
      <div className="flex justify-center">
        <CheckCircleOutlined className="aspect-square w-28 text-sb-green-500" />
      </div>
      <Labels.H2 className="mb-8 text-center">
        {t("orders.orderCreated")}
      </Labels.H2>
      {order && (
        <Layouts.Spaced className="divide-y divide-sb-gray-100">
          <OrderSummaryRow title="Symbol" value={order.symbol} />
          <OrderSummaryRow title="Side" value={t(`trade.${order.side}`)} />
          <OrderSummaryRow
            title="Order type"
            value={t(`trade.orderTypes.options.${order.type}.label`)}
          />
          <OrderSummaryRow
            title="Amount"
            value={
              getFormattedAmountValue(order, 0) +
              " " +
              (order.qty !== undefined
                ? t("trade.summary.amountType.qty")
                : t("trade.summary.amountType.cash"))
            }
          />
        </Layouts.Spaced>
      )}
      <Button
        className="mt-12 w-full"
        label="general.ok"
        onClick={() => setOrder(undefined)}
      />
    </Modal>
  );
};
