import { Message } from "modules/chat/Message";
import { AIAssistantMessageContent } from "shared/models/assistant/AIAssistantModel";

type Props = {
  content: AIAssistantMessageContent[];
};

export const ComposedUserMessage: React.FC<Props> = ({ content }) => {
  return (
    <>
      {content.map((c, idx) => {
        return (
          <Message key={"user_message_" + idx} text={c.text?.value || ""} />
        );
      })}
    </>
  );
};
