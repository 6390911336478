import "./styles.css";

import { CreatePortfolioForm } from "modules/create_portfolio_form/CreatePortfolioForm";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BasePage } from "shared/components/common/BasePage";
import { Panel } from "shared/components/hoc/Panels";

export const CreatePortfolioPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const portfolioCreatedHandler = async () => {
    navigate("/portfolios");
  };

  return (
    <BasePage>
      <Panel>
        <div className="mb-10 flex items-center justify-between">
          <div>
            <h1 className="mb-1 text-xl font-medium">
              {t("portfolios.create")}
            </h1>
          </div>
        </div>
        <CreatePortfolioForm onPortfolioCreated={portfolioCreatedHandler} />
      </Panel>
    </BasePage>
  );
};
