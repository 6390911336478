import { useTranslation } from "react-i18next";
import { Labels } from "shared/components/hoc/Labels";
import { AccountWidget } from "shared/icons/AccountWidget";
import { EvivaLogoBlack } from "shared/icons/EvivaLogoBlack";

export const LetUsKnowYouColumn = () => {
  const { t } = useTranslation();

  return (
    <div className="rounded-3xl bg-sb-green-dark p-10">
      <EvivaLogoBlack className="mb-20 w-[150px]" />
      <Labels.H1 className="mb-4 text-4xl">
        {t("webOnboarding.letsKnowYouBetter")}
      </Labels.H1>
      <Labels.B1 className="mb-12">
        {t("webOnboarding.weNeedToVerify")}
      </Labels.B1>
      <div className="grid aspect-square w-[300px] place-items-center">
        <AccountWidget className="w-[220px]" />
      </div>
    </div>
  );
};
