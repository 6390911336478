import { PropsWithChildren } from "react";

type Props = PropsWithChildren & {
  className?: string;
};

const H1: React.FC<Props> = ({ children, className }) => {
  return (
    <div className={`text-3xl font-semibold ${className} mb-2`}>{children}</div>
  );
};

const H2: React.FC<Props> = ({ children, className }) => {
  return (
    <div className={`text-2xl font-semibold ${className} mb-2`}>{children}</div>
  );
};

const H3: React.FC<Props> = ({ children, className }) => {
  return (
    <div className={`text-xl font-semibold ${className} mb-2`}>{children}</div>
  );
};

const G1: React.FC<Props> = ({ children, className }) => {
  return <div className={`${className} text-sb-gray-500`}>{children}</div>;
};

const B1: React.FC<Props> = ({ className = "", children }) => {
  return <div className={"text-base " + className}>{children}</div>;
};

export const Labels = { H1, H2, H3, G1, B1 };
