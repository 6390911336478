import { useTranslation } from "react-i18next";
import { PillSelector } from "shared/components/controls/PillSelector";
import { PayoutStatus } from "shared/models/fees/FeesModel";

type Props = {
  payoutStatuses: PayoutStatus[];
  setPayoutStatuses: (payoutStatuses: PayoutStatus[]) => void;
};

export const PayoutStatusPicker: React.FC<Props> = ({
  payoutStatuses,
  setPayoutStatuses,
}) => {
  const { t } = useTranslation();

  const statusArray: PayoutStatus[] = [
    "new",
    "accepted",
    "pending",
    "successful",
  ];

  const onPayoutStatusSelected = (payoutStatus: PayoutStatus) => {
    if (payoutStatuses.includes(payoutStatus)) {
      setPayoutStatuses(payoutStatuses.filter((f) => f !== payoutStatus));
    } else {
      setPayoutStatuses([...payoutStatuses, payoutStatus]);
    }
  };

  return (
    <PillSelector
      selectedValues={payoutStatuses.map((f) => {
        return { label: t(`payoutsChart.status.${f}`), value: f };
      })}
      onPillRemoved={onPayoutStatusSelected}
      placeholder={t("payouts.statusPlaceholder")}
    >
      <div>
        {statusArray.map((s) => {
          return (
            <div
              key={"status_" + s}
              className="flex cursor-pointer items-center gap-4 px-4 py-2.5 duration-150 hover:bg-sb-gray-100"
              onClick={() => onPayoutStatusSelected(s)}
            >
              <div className="pt-1">
                <input
                  type="checkbox"
                  checked={payoutStatuses.includes(s)}
                  readOnly
                />
              </div>
              <div className="flex items-baseline gap-2">
                <div className="text-lg font-medium">
                  {t(`payoutsChart.status.${s}`)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </PillSelector>
  );
};
