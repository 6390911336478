import RestService from "shared/api/RestService";
import { FaApiRoutes } from "shared/utils/routes";

class PhoneVerificationService {
  async sendSmsVerification(phone_number: string) {
    const payload = { phone_number };
    return RestService.post(FaApiRoutes.Users.PhoneStartVerify, payload);
  }

  async checkVerification(phone_number: string, verification_code: string) {
    const payload = { phone_number, verification_code };
    return RestService.post(FaApiRoutes.Users.PhoneCheckVerify, payload);
  }
}

const phoneVerificationService = new PhoneVerificationService();
export default phoneVerificationService;
