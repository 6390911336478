import { createAsyncThunk } from "@reduxjs/toolkit";
import { AssetChartQueryParams } from "shared/models/asset/AssetModel";
import { AssetsService } from "shared/services/assets/AssetsService";

export const fetchChartBySymbol = createAsyncThunk(
  "[ASSETS_CHART] Fetch asset chart",
  async (
    {
      symbol,
      params,
      isOpen,
    }: { symbol: string; params: AssetChartQueryParams; isOpen: boolean },
    { dispatch },
  ) => {
    const chart = await AssetsService.getChart(symbol, params, isOpen);
    return chart;
  },
);

export const AssetChartThunks = {
  fetchChartBySymbol,
};
