import RestService from "shared/api/RestService";
import {
  FeeTemplate,
  FeeTemplateEntry,
  FeeTemplateRequest,
} from "shared/types/FeeTemplateType";
import { FaApiRoutes } from "shared/utils/routes";

export const FeeTemplatesService = {
  async getById(templateId: string): Promise<FeeTemplate | undefined> {
    return RestService.get(
      FaApiRoutes.Advisor.FeeTemplates.Details(templateId),
    );
  },

  async getMany(): Promise<FeeTemplateEntry[]> {
    return RestService.get(FaApiRoutes.Advisor.FeeTemplates.Path);
  },

  async post(body: FeeTemplateRequest): Promise<void> {
    return RestService.post(FaApiRoutes.Advisor.FeeTemplates.Path, body);
  },

  async remove(templateId: string): Promise<void> {
    return RestService.delete(
      FaApiRoutes.Advisor.FeeTemplates.Details(templateId),
    );
  },
};
