import { init } from "onfido-sdk-ui";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PageContainer } from "shared/components/common/PageContainer";
import { PageWidthConstraint } from "shared/components/common/PageWidthConstraint";
import { useDispatch } from "shared/hooks/useDispatch";
import OnfidoService from "shared/services/fa_onboarding/OnfidoService";
import { AccountThunks } from "shared/store/account/AccountThunks";

type OnFidoOutcomeStatus = "USER_COMPLETED" | "USER_EXITED" | "SDK_ERROR";

export const OnfidoPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sendOutcome = useCallback(
    async (token: string, outcome: OnFidoOutcomeStatus) => {
      try {
        const onFidoOutcome = {
          token: token,
          outcome: outcome,
        };
        const result = await OnfidoService.patchOutcome(onFidoOutcome);
        await dispatch(AccountThunks.fetch()).unwrap();
        navigate("/");
        console.info("OnFido outcome result", result);
        return result;
      } catch (err) {
        throw err;
      }
    },
    [dispatch, navigate],
  );

  const initOnfido = useCallback(async () => {
    let token: string;

    try {
      const tokenData = await OnfidoService.getUsersToken();
      token = tokenData.token;
    } catch (error) {
      throw error;
    }

    init({
      token: token,
      containerId: "onfido-mount",
      onComplete: function () {
        sendOutcome(token, "USER_COMPLETED");
        console.log("everything is complete");
      },
      steps: ["welcome", "document", "face", "complete"],
    });
  }, [sendOutcome]);

  useEffect(() => {
    initOnfido();
  }, [initOnfido]);

  return (
    <PageContainer className="min-w-full">
      <PageWidthConstraint className="mb-12">
        <div id="onfido-mount"></div>
      </PageWidthConstraint>
    </PageContainer>
  );
};
