import { PortfolioTitle } from "modules/portfolio_composition/PortfolioTitle";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Panel } from "shared/components/hoc/Panels";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import { PortfolioActivity } from "shared/models/portfolio/PortfolioModel";
import { selectPortfolioById } from "shared/store/portfolios/PortfoliosReducer";
import { PortfoliosThunks } from "shared/store/portfolios/PortfoliosThunks";
import AllocationChange from "./AllocationChange";
import CompositionUpdateChart from "./CompositionUpdateChart";

type Props = {
  latestActivities: PortfolioActivity[];
  portfolioId: string;
};

export default function CompositionUpdate({
  latestActivities,
  portfolioId,
}: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const portfolio = useSelector(selectPortfolioById(portfolioId));

  const composition = latestActivities.find(
    (a) => a.entry_type === "composition",
  );

  useEffect(() => {
    dispatch(PortfoliosThunks.fetchOne(portfolioId));
  }, [dispatch, portfolioId]);

  if (!composition) return null;

  return (
    <div
      className="cursor-pointer bg-white duration-150"
      onClick={() => navigate(`/portfolios/${portfolioId}`)}
    >
      {portfolio && (
        <Panel className="!bg-sb-gray-100">
          <PortfolioTitle portfolio={portfolio} />
          {composition.composition && (
            <div className="mt-4 flex items-center gap-8">
              <CompositionUpdateChart composition={composition.composition} />
            </div>
          )}
          <div className="mt-4">
            <div className="space-y-4">
              {composition.composition?.changes.map((item, idx) => {
                return (
                  <AllocationChange
                    showChangeLabel={true}
                    key={"allocation_change_" + idx}
                    change={item}
                  />
                );
              })}
            </div>
          </div>
        </Panel>
      )}
    </div>
  );
}
