import { DateTime } from "luxon";
import TrendsChart from "modules/asset_chart/TrendsChart";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Labels } from "shared/components/hoc/Labels";
import { useAssetPrice } from "shared/hooks/useAssetPrice";
import useAssets from "shared/hooks/useAssets";
import { ArrowUp } from "shared/icons/ArrowUp";
import { AssetWrapper } from "shared/models/asset/AssetModel";
import { Loading } from "shared/types/enums";
import { CurrencyFormat, pctFormat } from "shared/utils/currency";
import { formatDateTime } from "shared/utils/date";

type Props = {
  asset: AssetWrapper;
  companyClassName?: string;
  bidAskClassname?: string;
};

export const CompactAssetInfo: React.FC<Props> = ({
  asset,
  companyClassName,
  bidAskClassname,
}) => {
  const { t } = useTranslation();
  const { assets_map, loading } = useAssets();

  const { latestPrice, pnlPct, positive, askPrice, bidPrice } = useAssetPrice(
    asset.symbol,
    "date",
  );

  const assetInfo = assets_map[asset.symbol];

  if (!assetInfo && loading === Loading.Finished) {
    return <Labels.G1>{t("globalSearch.assetNotFound")}</Labels.G1>;
  }

  const name = assetInfo?.name.length > 60 ? `${assetInfo?.name.slice(0, 60)} \u2026` : assetInfo?.name;

  return (
    <div className="flex items-center gap-8">
      <div>
        <div className="text-xl font-medium cursor-pointer">
          <Link to={`/dashboard/assets/${assetInfo?.symbol}`} className={'hover:p-1 hover:rounded-lg hover:bg-black hover:text-white'}>
            {assetInfo?.symbol}
          </Link>
        </div>
        <div className="text-lg text-sb-gray-600 cursor-help" title={assetInfo?.name}>
          {name}
        </div>
        <div
          className={
            "flex items-center gap-4 " +
            (companyClassName ? companyClassName : "")
          }
        >
          <div className="text-3xl font-medium">
            {CurrencyFormat.format(latestPrice)}
          </div>
          <div
            className={
              "flex items-center gap-2 text-xl font-bold " +
              (positive ? "text-sb-green-light" : "text-sb-red-600")
            }
          >
            <ArrowUp className={(positive ? "" : "rotate-180") + " h-6 w-6"} />
            <div>{pctFormat.format(pnlPct / 100)}</div>
          </div>
        </div>
        <div className="text-sb-gray-500">
          {formatDateTime(DateTime.now().toString())}
        </div>
      </div>
      <div className={"space-y-4 " + (bidAskClassname ? bidAskClassname : "")}>
        <div className="flex items-center justify-between gap-10 whitespace-nowrap rounded-xl bg-sb-gray-100 px-4 py-2 text-lg font-medium">
          <div>{t("assetStats.askPrice")}</div>
          <div className="text-sb-magenta-500">
            {CurrencyFormat.format(askPrice)}
          </div>
        </div>
        <div className="flex items-center justify-between gap-10 whitespace-nowrap rounded-xl bg-sb-gray-100 px-4 py-2 text-lg font-medium">
          <div>{t("assetStats.bidPrice")}</div>
          <div className="text-sb-blue-500">
            {CurrencyFormat.format(bidPrice)}
          </div>
        </div>
      </div>
      <div className="w-full">
        <TrendsChart height={120} symbol={asset.symbol} />
      </div>
    </div>
  );
};
