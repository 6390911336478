import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyLogo } from "shared/components/common/CompanyLogo";
import { Labels } from "shared/components/hoc/Labels";
import useAssets from "shared/hooks/useAssets";
import { ArrowUp } from "shared/icons/ArrowUp";
import ChevronRight from "shared/icons/ChevronRight";
import { PortfolioCompositionActivityChange } from "shared/models/portfolio/PortfolioModel";

type Props = {
  change: PortfolioCompositionActivityChange;
  showChangeLabel?: boolean;
};

export default function AllocationChange({
  change,
  showChangeLabel = true,
}: Props) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const { assets_map } = useAssets();

  const newAllocation = change.new_allocation && !change.old_allocation;
  const removedAllocation = change.old_allocation && !change.new_allocation;

  return (
    <div
      className="cursor-pointer rounded-xl bg-white p-4"
      onClick={(e) => {
        e.stopPropagation();
        setExpanded((prev) => !prev);
      }}
    >
      <div className="grid grid-cols-3">
        <div className="flex items-center gap-4">
          <CompanyLogo
            symbol={change.symbol}
            className="h-8 w-8 rounded-full"
          />
          <div className="">{change.symbol}</div>
        </div>
        {showChangeLabel ? (
          <>
            {change.new_allocation && change.old_allocation ? (
              <div className="text-center text-sm">
                {change.new_allocation > change.old_allocation &&
                  t("latestUpdates.increasedAllocation").toUpperCase()}
                {change.new_allocation < change.old_allocation &&
                  t("latestUpdates.decreasedAllocation").toUpperCase()}
              </div>
            ) : (
              <div className="text-center text-sm">
                {newAllocation
                  ? t("latestUpdates.newAllocation").toUpperCase()
                  : t("latestUpdates.removedAllocation").toUpperCase()}
              </div>
            )}
          </>
        ) : (
          <div />
        )}
        <div className="flex flex-row-reverse items-center gap-4 text-lg font-semibold">
          <div>
            <ChevronRight
              className={
                "h-4 w-4 text-sb-gray-500 duration-150 " +
                (expanded ? "-rotate-90" : "rotate-90")
              }
            />
          </div>
          {change.new_allocation || removedAllocation ? (
            <div>{(change.new_allocation * 100).toFixed(0)}%</div>
          ) : (
            ""
          )}
          {change.old_allocation && change.new_allocation ? (
            <div>
              <ArrowUp
                className={
                  "h-4 w-4 rotate-90 " +
                  (change.new_allocation > change.old_allocation
                    ? "text-sb-cyan-600"
                    : "text-sb-blue-600")
                }
              />
            </div>
          ) : (
            ""
          )}
          {change.old_allocation && !newAllocation && !removedAllocation ? (
            <div>{(change.old_allocation * 100).toFixed(0)}% </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {expanded && assets_map ? (
        <div className="mt-8">
          {assets_map[change.symbol] && (
            <div className="text-xl font-semibold">
              {assets_map[change.symbol].name}
            </div>
          )}
          <div className="text-base">{change.reasoning}</div>
          {change.sources && change.sources.length > 0 ? (
            <div className="mt-4">
              <hr className="my-4 border-sb-gray-200" />
              <div className="mb-4 font-semibold">
                {t("latestUpdates.sources")}
              </div>
              <div className="space-y-4">
                {change.sources.map((item, idx) => {
                  return (
                    <div className="cursor-pointer rounded-xl border border-sb-gray-200 p-4">
                      <a href={item.url} target="_blank" rel="noreferrer">
                        <div key={"source_" + item.id + "_" + idx}>
                          <div className="text-lg font-semibold">
                            {item.headline}
                          </div>
                          <Labels.G1>{item.source}</Labels.G1>
                        </div>
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
