import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { OptionsMenu } from "shared/components/controls/OptionsMenu";
import ChevronRight from "shared/icons/ChevronRight";
import { XMarkIcon } from "shared/icons/XMarkIcon";

type PillValue = {
  label?: string;
  value: any;
};

type Props = PropsWithChildren & {
  selectedValues: PillValue[];
  placeholder: string;
  onPillRemoved: (value: any) => void;
};

export const PillSelector: React.FC<Props> = ({
  children,
  selectedValues,
  placeholder,
  onPillRemoved,
}) => {
  return (
    <OptionsMenu
      button={
        <div className="relative min-h-[37px] w-full cursor-pointer rounded-xl border border-sb-gray-200 px-4 py-2">
          <div className="mr-8">
            {selectedValues.length ? (
              <div className="flex flex-wrap items-center gap-4">
                {selectedValues.map((item, idx) => {
                  return (
                    <div key={"selected_pill_" + idx}>
                      <SelectedPill value={item} onRemoved={onPillRemoved} />
                    </div>
                  );
                })}
              </div>
            ) : (
              <span className="select-none text-sb-gray-500">
                {placeholder}
              </span>
            )}
          </div>
          <div className="absolute right-4 top-1/2 -translate-y-1/2">
            <ChevronRight className="aspect-square w-4 duration-150" />
          </div>
        </div>
      }
      position="left-0"
    >
      {children}
    </OptionsMenu>
  );
};

type PillProps = {
  value: PillValue;
  onRemoved: (value: any) => void;
};

const SelectedPill: React.FC<PillProps> = ({ value, onRemoved }) => {
  const { t } = useTranslation();

  return (
    <div className="sb-gray-100 box-border flex items-center gap-2 rounded-lg border px-2">
      {value.label || t("global.unknown")}
      <div
        className="cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          onRemoved(value.value);
        }}
      >
        <XMarkIcon className="aspect-square w-3" />
      </div>
    </div>
  );
};
