import { Dialog, Transition } from "@headlessui/react";
import { Fragment, PropsWithChildren } from "react";

type Props = PropsWithChildren & {
  open: boolean;
  setOpen: (open: boolean) => void;
  wFit?: boolean;
};

export const Modal: React.FC<Props> = ({ open, setOpen, wFit, children }) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10 overflow-visible"
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-sb-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div
          className="fixed inset-0 z-10 overflow-y-auto"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-fit sm:max-w-lg">
                <div className="rounded-lg bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

type ModalScrollProps = PropsWithChildren & {
  className?: string;
};

export const ModalScroll: React.FC<ModalScrollProps> = ({
  children,
  className,
}) => {
  return (
    <div className={(className || "") + " mt-8 max-h-[300px] overflow-y-auto"}>
      {children}
    </div>
  );
};
