import { useTranslation } from "react-i18next";
import { FlatFeeType, NetLiquidationAppliedType } from "../types";

export const useFlatFee = () => {
  const { t } = useTranslation();

  const appliedChoices: { label: string; value: FlatFeeType}[] =
    [
      {
        label: t("feeTemplateForm.flatFee.daily"),
        value: "daily",
      },
      {
        label: t("feeTemplateForm.flatFee.monthly"),
        value: "monthly",
      },
      {
        label: t("feeTemplateForm.flatFee.quarterly"),
        value: "quarterly",
      },
    ];

  return { appliedChoices };
};
