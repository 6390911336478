type Props = {
  className?: string;
};

export const ArrowUp: React.FC<Props> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5.00345 10.9977L4.36786 10.3371C4.09873 10.0573 4.09873 9.605 4.36786 9.32824L9.93073 3.54317C10.1999 3.26344 10.635 3.26344 10.9013 3.54317L16.4642 9.32526C16.7333 9.605 16.7333 10.0573 16.4642 10.3341L15.8286 10.9947C15.5566 11.2774 15.1128 11.2715 14.8466 10.9828L11.5627 7.39988V15.9525C11.5627 16.3483 11.2563 16.6667 10.8755 16.6667H9.95936C9.57858 16.6667 9.27223 16.3483 9.27223 15.9525V7.39988L5.98547 10.9858C5.71921 11.2774 5.27544 11.2834 5.00345 10.9977Z"
        fill="currentColor"
      />
    </svg>
  );
};
