export type ISODate = string;
export type ISOTimestamp = string;

export type UUID = string;
export type Float = string;
export type Int = string;
export type PositionSide = "long" | "short";
export type AmountType = "cash" | "qty";

export type ChartRange =
  | "max"
  | "5y"
  | "2y"
  | "1y"
  | "6m"
  | "3m"
  | "1m"
  | "5dm"
  | "5d"
  | "date";
export type Environment = "production" | "staging";

export const AllowedFileMimeTypes = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "application/pdf",
] as const;
export type AllowedFileMimeType = (typeof AllowedFileMimeTypes)[number];

export type TradingInvestorType = "conservative" | "moderate" | "aggressive";
