import { createAsyncThunk } from "@reduxjs/toolkit";
import { AssetsService } from "shared/services/assets/AssetsService";

export const fetchAssets = createAsyncThunk(
  "[ASSETS] Fetch assets",
  async (_, { dispatch }) => {
    const account = await AssetsService.getAssets();
    return account;
  },
);

export const AssetThunks = {
  fetchAssets,
};
