import { createAsyncThunk } from "@reduxjs/toolkit";
import RestService from "shared/api/RestService";
import { TICKERS_SUBSCRIBE_V1 } from "shared/utils/routes";
import { RealTimeTickers } from "./RealtimePricesReducer";

export const subscribe = createAsyncThunk(
  "[REALTIME_SUBSCRIPTION] Subscribe symbols",
  async (symbols: string[]) =>
    RestService.post<RealTimeTickers>(TICKERS_SUBSCRIBE_V1, {
      tickers: symbols,
    }),
);

export const RealtimeSubscriptionsThunks = { subscribe };
