type Props = {
  className?: string;
};

export default function TrailingStopOrder({ className }: Props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5.99957 5L20.9996 16"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="2 3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.92632 10.1937C3.394 10.153 3.80612 10.4991 3.84681 10.9668C3.88811 11.4414 3.92637 11.8933 3.96277 12.3234C4.1435 14.4584 4.27864 16.0549 4.51462 17.2146C4.80911 18.6618 5.17365 18.9623 5.49041 19.0417C5.71332 19.0976 6.0772 19.095 6.67576 18.9276C7.26687 18.7622 8.01048 18.4586 8.95456 18.0003C10.6965 17.1547 12.9982 15.8446 16.0949 14.0819C16.3599 13.9311 16.6307 13.777 16.9074 13.6196C17.3154 13.3875 17.8344 13.5301 18.0665 13.9381C18.2986 14.3462 18.156 14.8651 17.748 15.0972C17.4635 15.2591 17.1848 15.4177 16.9118 15.5731C13.8438 17.3195 11.492 18.6583 9.69693 19.5297C8.71811 20.0048 7.8688 20.3591 7.13366 20.5647C6.40597 20.7683 5.71026 20.8495 5.07692 20.6907C3.68119 20.3407 3.14661 19.0173 2.84875 17.5535C2.59114 16.2875 2.4457 14.5647 2.26579 12.4336C2.23001 12.0098 2.19287 11.5699 2.15321 11.1142C2.11252 10.6465 2.45865 10.2344 2.92632 10.1937Z"
        fill="currentColor"
      />
      <path d="M18.6813 14.7975C19.1238 14.1974 18.9962 13.3523 18.3961 12.9097C17.7961 12.4671 16.9509 12.5948 16.5084 13.1948C16.0658 13.7948 16.1934 14.64 16.7935 15.0826C17.3935 15.5252 18.2387 15.3975 18.6813 14.7975Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0903 14.6806C16.7125 14.4019 16.6321 13.8698 16.9108 13.492C17.1894 13.1142 17.7216 13.0338 18.0994 13.3124C18.4772 13.5911 18.5575 14.1233 18.2789 14.501C18.0002 14.8788 17.4681 14.9592 17.0903 14.6806ZM16.106 12.8984C15.4995 13.7206 15.6744 14.8789 16.4967 15.4853C17.319 16.0918 18.4772 15.9169 19.0837 15.0946C19.6901 14.2724 19.5152 13.1141 18.693 12.5077C17.8707 11.9012 16.7125 12.0761 16.106 12.8984Z"
        fill="currentColor"
      />
    </svg>
  );
}
