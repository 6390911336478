import { useState } from "react";

type Props = {
  symbol?: string;
  className?: string;
};

export const CompanyLogo: React.FC<Props> = ({ symbol, className }) => {
  const [show, setShow] = useState(true);
  return show ? (
    <div
      className={
        "flex flex-col justify-center overflow-hidden text-center " + className
      }
    >
      <img
        className="max-h-full max-w-full"
        src={`https://storage.googleapis.com/iex/api/logos/${symbol}.png`}
        alt=""
        onError={() => setShow(false)}
      />
    </div>
  ) : (
    <div className={className + " bg-sb-gray-300"}></div>
  );
};
