import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  title: string;
  className?: string;
}

export const KycFormSegment: React.FC<PropsWithChildren<Props>> = ({
  title,
  className,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <div className={`mb-10 ${className} w-full`}>
      <h2 className="mb-2 ml-1 text-base font-medium">{t(title)}</h2>
      {children}
    </div>
  );
};
