import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./styles.css";
import {
  FeedbackType,
  PortfolioAssistantService,
} from "shared/services/portfolio_assistant/PortfolioAssistantService";
import { Modal } from "shared/components/common/Modal";
import { Labels } from "shared/components/hoc/Labels";
import { Button } from "shared/components/controls/Button";
import { ThumbsUpSolid } from "shared/icons/ThumbsUpSolid";
import { ThumbsUp } from "shared/icons/ThumbsUp";

type Props = {
  sessionId: string;
  messageId: string;
};

export const FeedbackButtons: React.FC<Props> = ({ sessionId, messageId }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<FeedbackType>();
  const [showModal, setShowModal] = useState(false);
  const feedbackRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (selected) {
      setShowModal(true);
    }
  }, [selected]);

  const sendFeedback = () => {
    if (selected) {
      PortfolioAssistantService.sendFeedback(sessionId, {
        type: selected,
        message_id: messageId,
        explanation: feedbackRef.current?.value,
      });
      setShowModal(false);
    }
  };

  return (
    <div className="mt-2 flex items-center justify-end gap-2">
      <Modal open={showModal} setOpen={setShowModal}>
        <Labels.H2 className="mb-2 text-center">
          {t("aiAssistant.feedbackTitle")}
        </Labels.H2>
        <textarea
          ref={feedbackRef}
          className="h-[200px] w-full rounded-xl border border-sb-gray-200 p-2"
        />
        <div className="mt-4 flex justify-center gap-4">
          <Button
            btnStyle="blank"
            label="global.cancel"
            onClick={() => {
              setShowModal(false);
              setSelected(undefined);
            }}
          />
          <Button label="global.send" onClick={sendFeedback} />
        </div>
      </Modal>
      <ThumbsButton
        btnType="negative"
        selected={selected}
        setSelected={setSelected}
      />
      <ThumbsButton
        btnType="positive"
        selected={selected}
        setSelected={setSelected}
      />
    </div>
  );
};

type ThumbsButtonProps = {
  btnType: FeedbackType;
  selected?: FeedbackType;
  setSelected: (type: FeedbackType) => void;
};

const ThumbsButton: React.FC<ThumbsButtonProps> = ({
  btnType,
  selected,
  setSelected,
}) => {
  return (
    <div
      className={
        "animated duration-150 " +
        (!selected ? "cursor-pointer hover:text-sb-gray-500 " : "") +
        (selected === btnType ? "tada" : "")
      }
      onClick={() => !selected && setSelected(btnType)}
    >
      {selected === btnType ? (
        <ThumbsUpSolid
          className={
            "aspect-square w-5 fill-sb-green-light " +
            (btnType === "negative" ? "rotate-180" : "")
          }
        />
      ) : (
        <ThumbsUp
          className={
            "aspect-square w-5 " + (btnType === "negative" ? "rotate-180" : "")
          }
        />
      )}
    </div>
  );
};
