import { useCallback, useEffect, useState } from "react";
import useRealtimeTicker from "shared/hooks/useRealtimeTicker";
import { Side } from "shared/models/trading/TradingOrderModel";
import useSupportLevelPrices from "./useSupportLevelPrices";

const FIVE_PERCENT = 5;

interface ProfitLossPrices {
  stopLoss: number | undefined;
  takeProfit: number | undefined;
}

interface Result extends ProfitLossPrices {
  getProfitLossPrices: (side: Side) => ProfitLossPrices;
}

const useProfitLossPrices = (symbol: string): Result => {
  const stockPrice = useRealtimeTicker(symbol);
  const supportLevelPrices = useSupportLevelPrices(symbol);

  const [{ stopLoss, takeProfit }, setStopLossTakeProfit] =
    useState<ProfitLossPrices>({
      takeProfit: undefined,
      stopLoss: undefined,
    });

  const calculateProfitLoss = useCallback(
    (prices: number[], latestPrice: number) => {
      const result = prices.reduce(
        (acc: ProfitLossPrices, curr: number) => {
          const takeProfitCalc =
            curr > latestPrice
              ? acc.takeProfit === undefined ||
                (acc.takeProfit !== undefined && curr < acc.takeProfit)
                ? curr
                : acc.takeProfit
              : acc.takeProfit;

          const stopLossCalc =
            curr < latestPrice
              ? acc.stopLoss === undefined ||
                (acc.stopLoss !== undefined && curr > acc.stopLoss)
                ? curr
                : acc.stopLoss
              : acc.stopLoss;

          return {
            takeProfit: takeProfitCalc,
            stopLoss: stopLossCalc,
          };
        },
        { takeProfit: undefined, stopLoss: undefined },
      );

      const stopLossValue =
        result.stopLoss || latestPrice - (FIVE_PERCENT / 100) * latestPrice;
      const takeProfitValue =
        result.takeProfit || latestPrice + (FIVE_PERCENT / 100) * latestPrice;

      return {
        takeProfit: +takeProfitValue.toFixed(2),
        stopLoss: +stopLossValue.toFixed(2),
      };
    },
    [],
  );

  useEffect(() => {
    if (!stockPrice.latestPrice || !supportLevelPrices?.length) return;

    const { takeProfit: takeProfitValue, stopLoss: stopLossValue } =
      calculateProfitLoss(supportLevelPrices, stockPrice.latestPrice);

    setStopLossTakeProfit({
      takeProfit: takeProfitValue,
      stopLoss: stopLossValue,
    });
  }, [stockPrice.latestPrice, supportLevelPrices, calculateProfitLoss]);

  const getProfitLossPrices = useCallback(
    (side: Side) => ({
      takeProfit: side === "buy" ? takeProfit : stopLoss,
      stopLoss: side === "buy" ? stopLoss : takeProfit,
    }),
    [takeProfit, stopLoss],
  );

  return { stopLoss, takeProfit, getProfitLossPrices };
};

export default useProfitLossPrices;
