type SectionType = "info" | "warning";

type Props = {
  text: string;
  type?: SectionType;
};

export const InfoSection: React.FC<Props> = ({ text, type }) => {
  const sectionType = type ?? "info";

  const typeClasses: Record<SectionType, string> = {
    info: "border-l-sb-blue-300 bg-sb-blue-100",
    warning: "border-l-sb-red-300 bg-sb-red-100",
  };

  return (
    <div
      className={
        "rounded-l-lg border-l-4 px-8 py-4 " + typeClasses[sectionType]
      }
    >
      {text}
    </div>
  );
};
