import React from "react";
import { Controller } from "react-hook-form";
import { KycControllerProps } from "./KycForm";
import { TextInput } from "shared/components/controls/TextInput";

export const KycTextInput: React.FC<KycControllerProps> = ({
  name,
  label,
  control,
  rules,
  error,
  disabled,
  className,
  defaultValue,
}) => {
  return (
    <div className="relative mb-4 min-w-[250px]">
      <Controller
        name={name}
        control={control}
        rules={rules}
        disabled={disabled}
        defaultValue={defaultValue}
        render={({ field }) => (
          <TextInput
            {...field}
            name={name}
            label={label || ""}
            error={error}
            className={className}
          />
        )}
      />
    </div>
  );
};
