import React from "react";
import {
  Button,
  DateRange,
  DateRangePickerStateContext,
} from "react-aria-components";
import { CustomDateRangePicker } from "shared/components/common/daterangepicker/CustomDateRangePicker";
import { ArrowUp } from "shared/icons/ArrowUp";
import { CalendarIcon } from "shared/icons/Calendar";
import { XMarkIcon } from "shared/icons/XMarkIcon";
import { formatDate } from "shared/utils/date";

type Props = {
  dateRange: DateRange | undefined;
  setDateRange: (dateRange: DateRange) => void;
  placeholder: string;
  clearable?: boolean;
};

export const DateRangeSelector: React.FC<Props> = ({
  dateRange,
  setDateRange,
  placeholder,
  clearable,
}) => {
  return (
    <CustomDateRangePicker value={dateRange} onChange={setDateRange}>
      <div className="flex cursor-pointer items-center gap-1 rounded-xl border border-sb-gray-200 px-4 py-2">
        {dateRange ? (
          <div className="flex items-center gap-2">
            <div>{formatDate(dateRange.start.toString())}</div>
            <ArrowUp className="aspect-square h-4 rotate-90" />
            <div>{formatDate(dateRange.end.toString())}</div>
          </div>
        ) : (
          placeholder
        )}
        {clearable && dateRange && <DateRangePickerClearButton />}
        <CalendarIcon className="ml-1 aspect-square h-5 fill-sb-gray-400" />
      </div>
    </CustomDateRangePicker>
  );
};

function DateRangePickerClearButton() {
  let state = React.useContext(DateRangePickerStateContext)!;
  return (
    <Button
      slot={null}
      className="outline-none"
      aria-label="Clear"
      onPress={() => state.setValue(null)}
    >
      <XMarkIcon
        className="aspect-square w-5 fill-sb-gray-400"
        strokeWidth={1}
      />
    </Button>
  );
}
