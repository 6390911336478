import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "shared/components/controls/Button";
import { FeeTemplatesService } from "shared/services/fee_templates/FeeTemplatesService";
import { FeeStrategyDetails } from "./components/FeeStrategyDetails";
import { SectionHeader } from "./components/SectionHeader";
import { YesNoSwitch } from "./components/YesNoSwitch";
import { useFeeStrategies } from "./hooks/useFeeStrategies";
import "./styles.css";
import { FeeStrategyType } from "./types";

export const FeeTemplateForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const feeStrategies = useFeeStrategies();
  const [selectedStrategy, setSelectedStrategy] = useState<FeeStrategyType>();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event: any) => {
    setLoading(true);
    const formData = new FormData(event.target);

    const formDataObject = Object.fromEntries(formData.entries());
    const parsedForm: Record<string, string | any> = {};
    for (let key of Object.keys(formDataObject)) {
      if (formDataObject[key] === "on") {
        parsedForm[key] = true;
      } else {
        parsedForm[key] = formDataObject[key];
      }
    }

    const isDefault = parsedForm.makeDefault;
    const templateName = parsedForm.templateName;
    delete parsedForm.makeDefault;
    delete parsedForm.templateName;
    const body = {
      label: templateName,
      is_default: isDefault,
      request: parsedForm,
    };

    FeeTemplatesService.post(body)
      .then(() => {
        toast.success(t("feeTemplateForm.success"));
        navigate("/fee-templates");
      })
      .finally(() => setLoading(false));

    event.preventDefault();
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <SectionHeader
          title={t("feeTemplateForm.title")}
          description={t("feeTemplateForm.description")}
        />
        <div className="fee-template-form-section">
          <div className="fee-template-form">
            <div>{t("feeTemplateForm.makeDefault")}</div>
            <YesNoSwitch name="makeDefault" />
            <label>{t("feeTemplateForm.templateName")}</label>
            <input name="templateName" type="text" required />
          </div>
        </div>
        <SectionHeader
          title={t("feeTemplateForm.configureFees")}
          description={t("feeTemplateForm.configureFeesDescription")}
        />
        <div className="fee-template-form-section">
          <div className="fee-template-form">
            <div>{t("feeTemplateForm.feeStrategy")}</div>
            <select
              name="feeStrategy"
              onChange={(e) =>
                setSelectedStrategy(e.target.value as FeeStrategyType)
              }
            >
              {feeStrategies.map((item, idx) => (
                <option key={idx} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
          {selectedStrategy === "feeStrategy_3" && (
            <div className="mt-8">
              <FeeStrategyDetails />
            </div>
          )}
        </div>
        <SectionHeader
          title={t("feeTemplateForm.otherExpenses")}
          description={t("feeTemplateForm.otherExpensesDescription")}
        />
        <div className="fee-template-form-section">
          <div className="fee-template-form">
            <div>{t("feeTemplateForm.chargeCommisions")}</div>
            <YesNoSwitch name="chargeCommisions" />
            <div>{t("feeTemplateForm.chargeOtherFees")}</div>
            <YesNoSwitch name="chargeOtherFees" />
          </div>
          <div className="mt-8 whitespace-pre-wrap rounded-xl bg-sb-red-100 p-8 text-red-900">
            {t("feeTemplateForm.feesDisclaimer")}
          </div>
        </div>
        <div className="my-10 flex justify-center">
          <Button label="Submit" type="submit" loading={loading} />
        </div>
      </form>
    </div>
  );
};
