type Props = {
  className?: string;
};

export const Person: React.FC<Props> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.75 5.53014C13.75 7.66397 12.0711 9.39378 10 9.39378C7.92893 9.39378 6.25 7.66397 6.25 5.53014C6.25 3.39631 7.92893 1.6665 10 1.6665C12.0711 1.6665 13.75 3.39631 13.75 5.53014ZM12.0833 5.53014C12.0833 6.7156 11.1506 7.67661 10 7.67661C8.84941 7.67661 7.91667 6.7156 7.91667 5.53014C7.91667 4.34468 8.84941 3.38368 10 3.38368C11.1506 3.38368 12.0833 4.34468 12.0833 5.53014Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3333 10.6817L6.66665 10.6817C4.36547 10.6817 2.5 12.6037 2.5 14.9746V16.6918C2.5 17.6401 3.24619 18.4089 4.16667 18.4089H15.8333C16.7538 18.4089 17.5 17.6401 17.5 16.6918V14.9746C17.5 12.6037 15.6345 10.6817 13.3333 10.6817ZM6.66666 12.3989L13.3333 12.3988C14.714 12.3988 15.8333 13.552 15.8333 14.9746V16.6918H4.16667V14.9746C4.16667 13.5521 5.28595 12.3989 6.66666 12.3989Z"
        fill="currentColor"
      />
    </svg>
  );
};
