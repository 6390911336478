import {
  createSlice,
  createSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Contact } from "shared/models/contact/AddContactModel";
import { fetchContactById, fetchContacts } from "./ContactsThunks";
import { Loading } from "shared/types/enums";

const contactsAdapter = createEntityAdapter<Contact>({
  selectId: (contact) => contact.contact_id,
});

const { selectAll, selectById } = contactsAdapter.getSelectors(
  (state: RootState) => state.contacts,
);

interface ContactsState {
  loading: Loading;
}

const initialState = contactsAdapter.getInitialState<ContactsState>({
  loading: Loading.Idle,
});

const contactsSlice = createSlice({
  name: "[CONTACTS]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchContacts.pending, (state, action) => {
      state.loading = Loading.InProgress;
      return state;
    });
    builder.addCase(fetchContacts.fulfilled, (state, action) => {
      state.loading = Loading.Finished;
      contactsAdapter.setAll(state, action.payload);
      return state;
    });
    builder.addCase(fetchContactById.fulfilled, (state, action) => {
      state.loading = Loading.Finished;
      if (action.payload) {
        contactsAdapter.upsertOne(state, action.payload);
      }
    });
  },
});

export const selectContactById = (id?: string) =>
  createSelector(
    (state: RootState) => state,
    (state) => (id ? selectById(state, id) : undefined),
  );

export const selectContacts = createSelector(
  (state: RootState) => state.clients,
  selectAll,
  (state, contacts) => ({
    loading: state.loading,
    fetched: state.fetched,
    contacts: contacts,
  }),
);

const ContactsActions = { ...contactsSlice.actions };

export default contactsSlice.reducer;
