import { PaperAirplane } from "modules/proposals/svg/PaperAirplane";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "shared/components/common/Modal";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";
import { Layouts } from "shared/components/hoc/Layouts";
import ChevronRight from "shared/icons/ChevronRight";
import { ToolsService } from "shared/services/tools/ToolsService";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onProposalSend: (message?: string) => void;
  sendLoading: boolean;
};

export const SendProposalModal: React.FC<Props> = ({
  open,
  setOpen,
  onProposalSend,
  sendLoading,
}) => {
  const { t } = useTranslation();
  const [text, setText] = useState("");
  const [command, setCommand] = useState("");
  const [generating, setGenerating] = useState(false);
  const [history, setHistory] = useState<string[]>([]);
  const [currentHistoryIndex, setCurrentHistoryIndex] = useState(0);

  const onSendClicked = () => {
    onProposalSend(text);
  };

  const addToHistory = (msg: string) => {
    setHistory((prev) => [...prev, msg]);
  };

  const onImproveClicked = () => {
    setGenerating(true);
    if (!history.length) {
      addToHistory(text);
    }
    ToolsService.fetchTextAssistant(
      {
        text,
        command,
      },
      (response, finished) => {
        setText(response);
        if (finished) {
          addToHistory(response);
        }
      },
    ).finally(() => {
      setGenerating(false);
    });
  };

  useEffect(() => {
    if (history.length) {
      setCurrentHistoryIndex(history.length - 1);
    }
  }, [history]);

  const loadHistory = (index: number) => {
    if (index >= 0 && index < history.length) {
      setCurrentHistoryIndex(index);
      setText(history[index]);
    }
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="flex items-center justify-between">
        <Labels.H3>{t("proposals.send.message")}</Labels.H3>
        {history.length > 1 && (
          <div className="flex items-center gap-4">
            <div
              className={
                currentHistoryIndex > 0 ? "cursor-pointer" : "text-sb-gray-200"
              }
              onClick={() => loadHistory(currentHistoryIndex - 1)}
            >
              <ChevronRight className="aspect-square w-4 rotate-180" />
            </div>
            <div>
              {currentHistoryIndex + 1}/{history.length}
            </div>
            <div
              className={
                currentHistoryIndex < history.length - 1
                  ? "cursor-pointer"
                  : "text-sb-gray-200"
              }
              onClick={() => loadHistory(currentHistoryIndex + 1)}
            >
              <ChevronRight className="aspect-square w-4" />
            </div>
          </div>
        )}
      </div>
      <Layouts.Spaced>
        <div>
          <textarea
            disabled={generating}
            placeholder={t("proposals.send.placeholder")}
            value={text}
            onChange={(e) => setText(e.target.value)}
            className="min-h-32 h-32 w-full overflow-hidden overflow-y-scroll rounded-xl border border-sb-gray-200 px-4 py-2 focus:border-sb-green-700 focus:outline-none disabled:bg-white"
          />
        </div>
        <div>
          <textarea
            disabled={generating}
            placeholder={t("proposals.send.improvePlaceholder")}
            value={command}
            onChange={(e) => setCommand(e.target.value)}
            className="min-h-20 h-20 w-full overflow-hidden rounded-xl border border-sb-gray-200 px-4 py-2 focus:border-sb-green-700 focus:outline-none"
          />
        </div>
        <div className="flex w-full justify-between gap-4">
          <Button
            disabled={generating || sendLoading}
            label="proposals.send.improveMessage"
            onClick={onImproveClicked}
          />
          <Button
            loading={sendLoading}
            onClick={onSendClicked}
            disabled={generating}
            label="proposals.send.sendShort"
            rightIcon={
              <div className="grid aspect-square h-6 place-items-center rounded-full bg-black">
                <PaperAirplane className="mr-0.5 mt-0.5 aspect-square h-3 text-white" />
              </div>
            }
          />
        </div>
      </Layouts.Spaced>
    </Modal>
  );
};
