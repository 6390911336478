import React from "react";

import { OnboardingFooter } from "./components/OnboardingFooter";
import { OnboardingPartProps } from "./OnboardingFlow";
import { LetUsKnowYouColumn } from "./components/LetUsKnowYouColumn";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import {
  OnboardingActions,
  selectOnboarding,
} from "shared/store/onboarding/OnboardingReducer";
import { Onboarding } from "shared/models/onboarding/OnboardingModel";
import { OnboardingContainer } from "shared/components/common/OnboardingContainer";
import { useTranslation } from "react-i18next";
import { Labels } from "shared/components/hoc/Labels";
import { Inputs } from "shared/components/hoc/Inputs";

export const EmployerDetails: React.FC<OnboardingPartProps> = ({
  onNext,
  onPrevious,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { employerName, employmentPosition, employerAddress } =
    useSelector(selectOnboarding);

  const handleOnValueChange = (value: string, type: keyof Onboarding) => {
    dispatch(OnboardingActions.update({ [type]: value }));
  };

  const disabledContinueButton =
    !employerName || !employmentPosition || !employerAddress;

  return (
    <OnboardingContainer
      leftColumnContent={<LetUsKnowYouColumn />}
      rightColumnContent={
        <div className="mb-14 mt-20 flex flex-col">
          <div className="flex-1">
            <Labels.H2 className="mb-2">
              {t("forms.kyc.screen_titles.employment_info.title")}
            </Labels.H2>
            <Labels.B1>
              {t("forms.kyc.screen_titles.employment_info.sub_title")}
            </Labels.B1>
            <Labels.B1 className="mb-6">
              {t("webOnboarding.neverContact")}
            </Labels.B1>

            <Inputs.Text
              value={employerName}
              className="mb-2"
              label="forms.employer.name.label"
              autoCapitalize="none"
              onChange={(value: string) => {
                handleOnValueChange(value, "employerName");
              }}
            />
            <Inputs.Text
              value={employmentPosition}
              className="mb-2"
              label="forms.employer.jobPosition.label"
              autoCapitalize="none"
              onChange={(value: string) => {
                handleOnValueChange(value, "employmentPosition");
              }}
            />
            <Inputs.Text
              value={employerAddress}
              className="mb-2"
              label="forms.employer.address.placeholder"
              autoCapitalize="none"
              onChange={(value: string) => {
                handleOnValueChange(value, "employerAddress");
              }}
            />
          </div>
          <OnboardingFooter
            onNext={() => onNext && onNext()}
            onPrev={() => onPrevious && onPrevious()}
            nextDisabled={disabledContinueButton}
          />
        </div>
      }
    />
  );
};
