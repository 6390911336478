import React, { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  className?: string;
}
export const ScrollOverviewBox: React.FC<Props> = ({ children, className }) => {
  return (
    <div
      className={`min-h-[200px] overflow-y-auto rounded-lg border border-black p-8 ${className}`}
    >
      {children}
    </div>
  );
};
