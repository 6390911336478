import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectAvailableCountries } from "shared/store/onboarding/OnboardingReducer";
import { AllCountries } from "shared/types/Countries";

export function useAvailableCountries() {
  const storedAvailableCountries = useSelector(selectAvailableCountries);
  return useMemo(() => {
    return AllCountries.filter(
      (e) => storedAvailableCountries.indexOf(e.code) > -1,
    );
  }, [storedAvailableCountries]);
}
