import { Avatar } from "modules/avatar/Avatar";
import { GlobalSearch } from "modules/global_search/GlobalSearch";
import { NavigationBar } from "modules/navigation/NavigationBar";
import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { PageWidthConstraint } from "shared/components/common/PageWidthConstraint";
import { Footer } from "./Footer";

type RightBarProps = PropsWithChildren & {
  __TYPE?: "RightBar";
};

export const RightBar: React.FC<RightBarProps> = ({ children }) => {
  return <div>{children}</div>;
};
RightBar.defaultProps = { __TYPE: "RightBar" };

type Props = PropsWithChildren & {
  globalSearch?: boolean;
  wideRightBar?: boolean;
};

export const BasePage: React.FC<Props> = ({
  globalSearch,
  children,
  wideRightBar,
}) => {
  const navigate = useNavigate();
  let rightBarComponent = undefined;
  let mainPageComponents: React.ReactNode[] = [];

  React.Children.forEach(children, (child) => {
    if (
      React.isValidElement(child) &&
      typeof child.type !== "string" &&
      child.props.__TYPE === "RightBar"
    ) {
      rightBarComponent = child;
    } else {
      mainPageComponents.push(child);
    }
  });

  return (
    <div className="min-h-dvh flex flex-col">
      <PageWidthConstraint className="flex-grow">
        <div className="fixed -left-[200px] right-0 top-0 z-[999] h-[4px] bg-sb-green-600" />
        <div className="flex h-full w-full items-stretch justify-between gap-8 xl:gap-12">
          <div className="h-full w-fit pt-8">
            <NavigationBar />
          </div>
          <div className="w-full pt-8">
            <div className="flex w-full items-start gap-8">
              <div className="flex grow flex-col gap-12">
                {globalSearch && (
                  <GlobalSearch
                    onClicked={(asset) =>
                      navigate("/dashboard/assets/" + asset.symbol)
                    }
                  />
                )}
                {rightBarComponent ? mainPageComponents : ""}
              </div>
              <div
                className={
                  (wideRightBar
                    ? "min-w-[350px] xl:min-w-[420px]"
                    : "min-w-[250px]") + " flex flex-[0] flex-col"
                }
              >
                <Avatar />
                {rightBarComponent ? (
                  <div className="">{rightBarComponent}</div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className={wideRightBar ? "min-w-[420px]" : "min-w-[250px]"}>
              {!rightBarComponent ? mainPageComponents : ""}
            </div>
          </div>
        </div>
      </PageWidthConstraint>
      <div className="mt-10">
        <Footer />
      </div>
    </div>
  );
};
