type Props = {
  className?: string;
};

export const ArrowsUpDown: React.FC<Props> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.652 13.9648C14.059 13.9648 13.5777 13.4836 13.5777 12.8906V6.99102L12.6238 7.94492C12.2027 8.36602 11.5238 8.36602 11.1027 7.94492C10.6816 7.52383 10.6816 6.84492 11.1027 6.42383L13.8914 3.63516C14.3125 3.21406 14.9914 3.21406 15.4125 3.63516L18.1969 6.42383C18.618 6.84492 18.618 7.52383 18.1969 7.94492C17.7758 8.36602 17.0969 8.36602 16.6758 7.94492L15.7262 6.99102V12.8906C15.7262 13.4836 15.2449 13.9648 14.652 13.9648ZM6.27305 9.10938V15.0047L5.31914 14.0508C4.89805 13.6297 4.21914 13.6297 3.79805 14.0508C3.37695 14.4719 3.37695 15.1508 3.79805 15.5719L6.58672 18.3605C7.00781 18.7816 7.68672 18.7816 8.10781 18.3605L10.8922 15.5719C11.3133 15.1508 11.3133 14.4719 10.8922 14.0508C10.4711 13.6297 9.79219 13.6297 9.37109 14.0508L8.41719 15.0047V9.10938C8.41719 8.51641 7.93594 8.03516 7.34297 8.03516C6.7543 8.03516 6.27305 8.51641 6.27305 9.10938Z"
        fill="black"
      />
    </svg>
  );
};
