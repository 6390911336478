import { t } from "i18next";
import { useState, useCallback } from "react";
import { ValueChart } from "shared/components/common/chart/ValueChart";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import {
  Portfolio,
  PortfolioResults,
} from "shared/models/portfolio/PortfolioModel";
import { ClientsService } from "shared/services/clients/ClientsService";
import { CurrencyFormat } from "shared/utils/currency";
import { ISOTimestamp } from "shared/utils/types";

type Props = {
  clientId?: string;
  portfolio?: Portfolio;
};

export const PortfolioResultsCard: React.FC<Props> = ({
  clientId,
  portfolio,
}) => {
  const [data, setData] = useState<PortfolioResults>();

  const portfolioId = portfolio?.id;

  const onPeriodChange = useCallback(
    (startDate: ISOTimestamp | null, endDate: ISOTimestamp | null) => {
      if (clientId && portfolioId) {
        ClientsService.getPortfolioResults(clientId, portfolioId, {
          startDate,
          endDate,
        }).then(setData);
      }
    },
    [clientId, portfolioId],
  );

  return (
    <Panel>
      <Labels.H3>{t("clientPortfolio.results")}</Labels.H3>
      <ValueChart
        domain={[
          (dataMin: number) => (dataMin ? dataMin - 0.03 * dataMin : dataMin),
          (dataMax: number) => dataMax + 0.05 * dataMax,
        ]}
        data={data?.points}
        xKey="date"
        yKey="end_equity"
        onPeriodChange={onPeriodChange}
        yValueFormatter={(value) => CurrencyFormat.format(value)}
        zoomable
      />
    </Panel>
  );
};
