import { useState } from "react";
import { TextAssistantResponse } from "./TextAssistantResponse";
import { AiAssistantInitialChatOption } from "shared/models/assistant/AIAssistantModel";
import { Message } from "modules/chat/Message";
import { ActionButton } from "./ActionButton";

type Props = {
  sessionConfig?: AiAssistantInitialChatOption;
  hide?: boolean;
};

export const InitialAssistantResponse: React.FC<Props> = ({
  sessionConfig,
  hide,
}) => {
  const [selected, setSelected] = useState<number | undefined>();

  if (!sessionConfig) {
    return null;
  }

  return (
    <>
      <TextAssistantResponse
        text={sessionConfig.welcome_message}
        skipFeedback
      />
      {!hide && selected === undefined && (
        <div className="mt-4 grid grid-cols-2 gap-4">
          {sessionConfig.initial_chat_options?.map((action, index) => (
            <ActionButton
              key={index}
              onClick={() => setSelected(index)}
              title={action.title}
              desc={action.description}
            />
          ))}
        </div>
      )}
      {selected !== undefined && (
        <div className="mb-2 space-y-2">
          <Message
            text={sessionConfig.initial_chat_options?.[selected].title}
          />
          <TextAssistantResponse
            text={sessionConfig.initial_chat_options?.[selected].reply}
            skipFeedback
          />
        </div>
      )}
    </>
  );
};
