import { auth, environment } from "shared/utils/firebase";

const STAGING_WSS_URL = "wss://api.staging.streetbeat.com";
const PROD_WSS_URL = "wss://api.streetbeat.com";

class WebsocketService {
  async createWebsocketInstance(url: string) {
    try {
      const env = await environment();
      const baseUrl = env === "staging" ? STAGING_WSS_URL : PROD_WSS_URL;
      await auth.authStateReady();
      const token = await auth.currentUser?.getIdToken();
      if (token) {
        const websocket = new WebSocket(`${baseUrl}${url}`);
        websocket.onopen = () => {
          websocket.send(
            JSON.stringify({
              type: "authenticate",
              payload: {
                token: token,
              },
            }),
          );
        };
        return websocket;
      }
    } catch (error) {
      console.warn("[WebsocketService] request", JSON.stringify(error));
    }
  }
}

const websocketService = new WebsocketService();
export default websocketService;
