import { useInvoicing } from "modules/fee_template_form/hooks/useInvoicing";
import { useTranslation } from "react-i18next";

export const InvoicingForm: React.FC = () => {
  const { t } = useTranslation();
  const { appliedChoices } = useInvoicing();

  return (
    <>
      <div>
        <div className="fee-template-form-section">
          <div className="fee-template-form">
            <div>{t("feeTemplateForm.applied")}</div>
            <select name="invoicing_applied">
              {appliedChoices.map((item, idx) => (
                <option key={idx} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            <label>{t("feeTemplateForm.invoicing.maxFee")}</label>
            <input type="number" name="invoicing_maxFee" />
          </div>
        </div>
      </div>
    </>
  );
};
