type DataTuple = [any, number | null | undefined];

export const MathUtils = {
  asc: (arr: number[]) => arr.sort((a, b) => a - b),
  sum: (arr: number[]) => arr.reduce((a, b) => a + b, 0),
  mean: (arr: number[]) =>
    arr.length !== 0 ? MathUtils.sum(arr) / arr.length : 0,
  std: (arr: number[]) => {
    const mu = MathUtils.mean(arr);
    const diffArr = arr.map((a) => (a - mu) ** 2);
    return Math.sqrt(MathUtils.sum(diffArr) / (arr.length - 1));
  },
  quantile: (arr: number[], q: number) => {
    const sorted = MathUtils.asc(arr);
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
      return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
      return sorted[base];
    }
  },

  median: (arr: number[]) => MathUtils.quantile(arr, 0.5),

  range: (arr: number[]): [number, number] => {
    if (arr.length === 0) return [0, 0];
    const sorted = MathUtils.asc(arr);
    const low = sorted[0];
    const high = sorted[sorted.length - 1];
    return [low, high];
  },

  round: (
    range: [number, number],
    step: number = 1,
    precision: number = 10,
  ): [number, number] => {
    return [
      MathUtils.floor(range[0], step, precision),
      MathUtils.ceil(range[1], step, precision),
    ];
  },

  ceil: (a: number, step: number = 1, precision: number = 10) =>
    Math.ceil((a + step) / precision) * precision,

  floor: (a: number, step: number = 1, precision: number = 10) =>
    Math.floor((a - step) / precision) * precision,

  concat: (...tuples: DataTuple[][]): number[] => {
    const all: [any, number | null | undefined][] = [];
    const filtered = all
      .concat(...tuples)
      .filter((dp) => dp[1] != null)
      .map((dp) => dp[1]) as number[];
    return filtered;
  },

  roundCurrency: (num: number, precision: number = 100) =>
    Math.floor((num + Number.EPSILON) * precision) / precision,

  roundConvertedToCurrency: (num: number) =>
    Math.round((num + Number.EPSILON) * 100) / 100,

  capWithinThreshold: (
    input: number,
    max: number,
    threshold: number = 0.01,
  ) => {
    const delta = Math.abs(input - max);
    return delta < threshold ? max : input;
  },

  isNumeric: (n: number | undefined | null) => {
    return n !== undefined && n !== null && !isNaN(n) && isFinite(n);
  },

  percentageChange: (initial: number, final: number) =>
    initial > 0 ? (final - initial) / initial : 0,

  clamp: (value: number, min: number, max: number): number => {
    return Math.min(Math.max(value, min), max);
  },

  roundNumber: (num: number) => {
    return Math.round(+num * 100) / 100;
  },
};
