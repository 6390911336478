import { Client } from "shared/models/client/ClientModel";

type Props = {
  checked: boolean;
  client: Client;
  onSelected: (clientId: string) => void;
};

export const ClientRow: React.FC<Props> = ({ checked, client, onSelected }) => {
  return (
    <div
      className="flex cursor-pointer items-center gap-4 px-4 py-2.5 duration-150 hover:bg-sb-gray-100"
      onClick={() => onSelected(client.user_id)}
    >
      <div className="pt-1">
        <input type="checkbox" checked={checked} readOnly />
      </div>
      <div className="flex items-baseline gap-2">
        <div className="text-lg font-medium">{client.summary.client_name}</div>
        <div className="ml-4 text-sb-gray-500">{client.summary.email}</div>
      </div>
    </div>
  );
};
