import { useEffect } from "react";
import { useIntercom } from "react-use-intercom";
import { AccountService } from "shared/services/account/AccountService";
import { auth } from "shared/utils/firebase";

export default function IntercomListener() {
  const { boot, shutdown } = useIntercom();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const email = user.email;
        if (email) {
          const { intercom_verification_token } =
            (await AccountService.getIntercomVerificationToken()) || {};

          boot({
            email: email,
            userHash: intercom_verification_token,
            userId: user.uid,
          });
        }
      } else {
        shutdown();
      }
    });
    return () => unsubscribe();
  }, []);

  return <></>;
}
