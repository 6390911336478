import { useState } from "react";
import { CompanyLogo } from "shared/components/common/CompanyLogo";
import { AssetWrapper } from "shared/models/asset/AssetModel";
import "./styles.css";
import { XIcon } from "pages/fa/clients/svg/XIcon";

type Props = {
  asset: AssetWrapper;
  onRemove?: () => void;
  onAllocationChange: (value: number) => void;
};

export const Asset: React.FC<Props> = ({
  asset,
  onRemove,
  onAllocationChange,
}) => {
  const [value, setValue] = useState(0);

  return (
    <div className="relative rounded-xl bg-sb-gray-100 p-4">
      <div
        className="absolute right-2.5 top-2.5 cursor-pointer"
        onClick={() => onRemove && onRemove()}
      >
        <XIcon className="h-3 w-3" />
      </div>
      <div className="flex items-center justify-between gap-4">
        <div className="flex items-center gap-4">
          <CompanyLogo
            symbol={asset.symbol}
            className="h-10 w-10 rounded-full"
          />
          <div>
            <div className="text-lg font-semibold">{asset.symbol}</div>
            <div className="text-base">{asset.name}</div>
          </div>
        </div>
        <div className="stock-pct-form flex items-center gap-2 pr-8">
          <input
            type="number"
            min={0}
            max={100}
            step={1}
            value={value}
            onChange={(e) => {
              onAllocationChange(parseInt(e.target.value));
              setValue(parseInt(e.target.value));
            }}
          />
          <div className="text-2xl">%</div>
        </div>
      </div>
    </div>
  );
};
