import { PortfolioTitle } from "./PortfolioTitle";
import { Skeleton } from "shared/components/common/Skeleton";
import { useNavigate } from "react-router-dom";
import {
  MetaPortfolio,
  Portfolio,
} from "shared/models/portfolio/PortfolioModel";
import { MetaPortfolioChart } from "./MetaPortfolioChart";

type Props = {
  portfolio?: Portfolio;
  includeTitle?: boolean;
  className?: string;
  clientId?: string;
  editing?: boolean;
  onChange?: (metaPortfolio: MetaPortfolio[]) => void;
  totalInvalid?: () => boolean;
  wrapped?: boolean;
};

export const PortfolioComposition: React.FC<Props> = ({
  portfolio,
  includeTitle,
  className,
  clientId,
  editing,
  onChange,
  totalInvalid,
  wrapped,
}) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (clientId) {
      navigate(`/clients/${clientId}/portfolios/${portfolio?.id}`);
    } else {
      navigate(`/portfolios/${portfolio?.id}`);
    }
  };

  return (
    <div className={className}>
      {includeTitle && (
        <div>
          {portfolio ? (
            <div className="cursor-pointer" onClick={handleNavigate}>
              <PortfolioTitle portfolio={portfolio} />
            </div>
          ) : (
            <>
              <Skeleton className="h-8 w-[200px] bg-sb-gray-200" />
              <Skeleton className="my-2 h-6 w-[150px] bg-sb-gray-200" />
            </>
          )}
        </div>
      )}
      {portfolio?.meta_portfolio && (
        <MetaPortfolioChart
          key={portfolio?.id}
          metaPortfolio={portfolio?.meta_portfolio}
          editing={editing}
          onChange={onChange}
          totalInvalid={totalInvalid}
          wrapped={wrapped}
        />
      )}
    </div>
  );
};
