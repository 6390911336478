import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CreateWatchlistData,
  WatchlistsService,
} from "shared/services/watchlists/WatchlistsService";
import { WatchlistActions } from "./WatchlistReducer";
import { RootState } from "../store";

const fetchWatchlists = createAsyncThunk(
  "[WATCHLISTS] Fetch watchlists",
  async (_, { dispatch }) => {
    const watchlists = await WatchlistsService.fetchAll();
    dispatch(WatchlistActions.loadingFinished());
    return watchlists;
  },
);

const addSymbol = createAsyncThunk(
  "[WATCHLISTS] Add symbol to watchlist",
  async (
    payload: { id: string; watchlistData: CreateWatchlistData },
    { dispatch, getState },
  ) => {
    const { id, watchlistData } = payload;
    const state = getState() as RootState;
    const watchlist = state.watchlist.entities[id];

    dispatch(
      WatchlistActions.updateWatchlist({
        id,
        changes: {
          assets: [...(watchlist?.assets || []), watchlistData.symbol],
        },
      }),
    );
    const data = await WatchlistsService.addSymbol(id, watchlistData);
    return data;
  },
);

const removeSymbol = createAsyncThunk(
  "[WATCHLISTS] Remove symbol from watchlist",
  async (payload: { id: string; symbol: string }, { dispatch, getState }) => {
    const { id, symbol } = payload;
    const state = getState() as RootState;
    const watchlist = state.watchlist.entities[id];

    dispatch(
      WatchlistActions.updateWatchlist({
        id,
        changes: {
          assets: (watchlist?.assets || []).filter((a) => a !== symbol),
        },
      }),
    );
    const data = await WatchlistsService.removeSymbol(id, symbol);
    return data;
  },
);

const rename = createAsyncThunk(
  "[WATCHLISTS] Rename",
  async (payload: { id: string; name: string }, { dispatch, getState }) => {
    const { id, name } = payload;
    const state = getState() as RootState;
    const watchlist = state.watchlist.entities[id];

    dispatch(
      WatchlistActions.updateWatchlist({
        id,
        changes: {
          name: name,
        },
      }),
    );
    const data = await WatchlistsService.put(id, {
      name,
      symbols: watchlist?.assets || [],
    });
    return data;
  },
);

const remove = createAsyncThunk(
  "[WATCHLISTS] Delete Watchlist",
  async (id: string) => {
    await WatchlistsService.delete(id);
    return id;
  },
);

export const WatchlistThunks = {
  fetchWatchlists,
  addSymbol,
  removeSymbol,
  remove,
  rename,
};
