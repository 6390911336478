import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Skeleton } from "shared/components/common/Skeleton";
import { Panel } from "shared/components/hoc/Panels";
import { News } from "shared/models/news/NewsModel";
import { NewsService } from "shared/services/news/NewsService";
import { Article } from "./Article";

type Props = {
  assetId?: string;
};

export const AssetNews: React.FC<Props> = ({ assetId }) => {
  const { t } = useTranslation();
  const [news, setNews] = useState<News[]>();

  useEffect(() => {
    if (assetId) {
      NewsService.fetch(assetId).then(setNews);
    }
  }, [assetId]);

  if (news && news.length === 0) {
    return null;
  }

  return (
    <Panel>
      <div className="mb-8 text-xl font-semibold">{t("assetNews.title")}</div>
      <div className="space-y-8">
        {news &&
          news.map((a, idx) => (
            <Article key={idx} news={a} assetId={assetId} />
          ))}
      </div>
      {!news && (
        <div className="flex gap-2">
          <div className="aspect-square w-[100px] animate-pulse bg-sb-gray-200" />
          <div>
            <Skeleton className="mt-2 h-5 w-[100px] bg-sb-gray-200" />
            <Skeleton className="mt-4 h-4 w-[40px] bg-sb-gray-200" />
            <Skeleton className="mt-4 h-3 w-[25px] bg-sb-gray-200" />
          </div>
        </div>
      )}
    </Panel>
  );
};
