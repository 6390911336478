type Props = {
  className?: string;
};

export const HistoryIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.9375 17.5C7.86806 17.5 6.11111 16.7674 4.66667 15.3021C3.22222 13.8368 2.5 12.0625 2.5 9.97917H3.75C3.75 11.7153 4.34722 13.1944 5.54167 14.4167C6.73611 15.6389 8.20139 16.25 9.9375 16.25C11.7014 16.25 13.1944 15.6319 14.4167 14.3958C15.6389 13.1597 16.25 11.6597 16.25 9.89583C16.25 8.17361 15.6319 6.71875 14.3958 5.53125C13.1597 4.34375 11.6736 3.75 9.9375 3.75C8.99306 3.75 8.10764 3.96528 7.28125 4.39583C6.45486 4.82639 5.73611 5.39583 5.125 6.10417H7.3125V7.35417H2.95833V3.02083H4.20833V5.22917C4.93056 4.38194 5.78819 3.71528 6.78125 3.22917C7.77431 2.74306 8.82639 2.5 9.9375 2.5C10.9792 2.5 11.9583 2.69444 12.875 3.08333C13.7917 3.47222 14.5937 4.00347 15.2812 4.67708C15.9687 5.35069 16.5104 6.13889 16.9062 7.04167C17.3021 7.94444 17.5 8.91667 17.5 9.95833C17.5 11 17.3021 11.9792 16.9062 12.8958C16.5104 13.8125 15.9687 14.6111 15.2812 15.2917C14.5937 15.9722 13.7917 16.5104 12.875 16.9062C11.9583 17.3021 10.9792 17.5 9.9375 17.5ZM12.6042 13.3958L9.39583 10.2292V5.77083H10.6458V9.70833L13.5 12.5L12.6042 13.3958Z"
        fill="currentCOlor"
      />
    </svg>
  );
};
