import React from "react";

type Props = {
  className?: string;
};

export const AttachmentIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      width="16"
      height="24"
      viewBox="0 0 16 24"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 24C3.86401 24 0.5 20.636 0.5 16.5V6C0.5 5.44702 0.948059 5.00006 1.49994 5.00006C2.052 5.00006 2.50006 5.44702 2.50006 6V16.5C2.50006 19.532 4.96704 21.9999 8 21.9999C11.033 21.9999 13.4999 19.532 13.4999 16.5V5.49994C13.4999 3.57001 11.93 2.00006 10.0001 2.00006C8.06995 2.00006 6.5 3.57001 6.5 5.49994V15.5001C6.5 16.327 7.17291 17.0001 8 17.0001C8.82709 17.0001 9.5 16.327 9.5 15.5001V6C9.5 5.44702 9.94806 5.00006 10.4999 5.00006C11.052 5.00006 11.5001 5.44702 11.5001 6V15.5001C11.5001 17.43 9.92993 18.9999 8 18.9999C6.07007 18.9999 4.49994 17.43 4.49994 15.5001V5.49994C4.49994 2.46808 6.96692 0 10.0001 0C13.033 0 15.5 2.46808 15.5 5.49994V16.5C15.5 20.636 12.136 24 8 24Z"
        fill="black"
      />
    </svg>
  );
};
