import { PropsWithChildren, useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useRealtimeTicker from "shared/hooks/useRealtimeTicker";
import { AssetWrapper } from "shared/models/asset/AssetModel";
import { CreateOrderActions } from "shared/store/create_order/CreateOrderReducer";
import { roundCurrency, usdIntlConfig } from "shared/utils/currency";
import useCreateOrder from "../hooks/useCreateOrder";
import GenericBuyOrder from "./GenericBuyOrder";

type Props = PropsWithChildren & {
  asset: AssetWrapper;
  onEstimatedValueChange: (value: number) => void;
};

export default function StopOrder({
  asset,
  onEstimatedValueChange,
  children,
}: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [estimatedValue, setEstimatedValue] = useState(0);
  const { latestPrice, askPrice, bidPrice } = useRealtimeTicker(asset.symbol);

  const [stopPriceValue, setStopPriceValue] = useState<string | undefined>("0");

  const order = useCreateOrder();

  useEffect(() => {
    setEstimatedValue(
      latestPrice && order?.qty ? latestPrice * parseFloat(order.qty) : 0,
    );
  }, [latestPrice, order]);

  useEffect(() => {
    onEstimatedValueChange(estimatedValue);
  }, [estimatedValue, onEstimatedValueChange]);

  useEffect(() => {
    if (!stopPriceValue) {
      const midPriceAvailable = bidPrice && askPrice;
      const buyLimitPrice = midPriceAvailable
        ? (bidPrice + askPrice) / 2
        : latestPrice;
      const midPrice = roundCurrency(buyLimitPrice);
      setStopPriceValue(midPrice.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bidPrice, askPrice, latestPrice]);

  useEffect(() => {
    if (stopPriceValue && order) {
      let newOrder = { ...order };
      newOrder.time_in_force = "gtc";
      newOrder.type = "stop";
      newOrder.stop_price = stopPriceValue;
      dispatch(CreateOrderActions.update({ ...newOrder }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, order.type, stopPriceValue]);

  return (
    <GenericBuyOrder asset={asset} fractional>
      {children}
      <div>
        <div>{t("orders.stopPrice")}</div>
        <div className="rounded-xl border border-sb-gray-200 px-2 py-1 pr-2 font-medium">
          <CurrencyInput
            prefix="$"
            value={stopPriceValue}
            intlConfig={usdIntlConfig}
            decimalsLimit={2}
            decimalScale={2}
            onValueChange={(value) => setStopPriceValue(value)}
            className="py-1 text-right outline-none"
          />
        </div>
      </div>
    </GenericBuyOrder>
  );
}
