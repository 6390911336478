import { createAsyncThunk } from "@reduxjs/toolkit";
import { AssetsService } from "shared/services/assets/AssetsService";

export const fetchAssetStats = createAsyncThunk(
  "[ASSET_STATS] Fetch Asset Stats By Symbol",
  async (symbol: string) => {
    const stats = await AssetsService.getStats(symbol);
    const earnings = await AssetsService.getEarnings(symbol);
    return { symbol, stats, earnings };
  },
);

export const fetchSupportLevelPrices = createAsyncThunk(
  "[ASSET_STATS] Fetch Support Level Prices",
  async (symbol: string) => AssetsService.getSupportLevelPrices(symbol),
);

export const AssetStatsThunks = {
  fetchAssetStats,
  fetchSupportLevelPrices,
};
