import { createAsyncThunk } from "@reduxjs/toolkit";
import { ClientsService } from "shared/services/clients/ClientsService";
import { FeesService } from "shared/services/fees/FeesService";
import { OrdersService } from "shared/services/orders/OrdersService";
import { PositionsService } from "shared/services/positions/PositionsService";

const fetchRecentActivities = createAsyncThunk(
  "[CLIENT_DETAILS_RECENT_ACTIVITIES] Fetch client recent activities",
  async ({
    clientId,
    params,
  }: {
    clientId: string;
    params?: { type: string };
  }) => {
    const recentActivities = await ClientsService.getRecentActivities(
      clientId,
      params,
    );
    return { clientId, recentActivities };
  },
);

const fetchPositions = createAsyncThunk(
  "[CLIENT_DETAILS_POSITIONS] Fetch client positions",
  async ({ clientId }: { clientId: string }) => {
    const positions = await PositionsService.fetch(clientId);
    return { clientId, positions };
  },
);

const fetchOrders = createAsyncThunk(
  "[CLIENT_DETAILS_ORDERS] Fetch client orders",
  async ({ clientId, limit }: { clientId: string; limit: number }) => {
    const orders = await OrdersService.fetch(
      clientId,
      `?status=all&limit=${limit}`,
    );
    return { clientId, orders };
  },
);

const fetchPortfolios = createAsyncThunk(
  "[CLIENT_DETAILS_PORTFOLIOS] Fetch client portfolios",
  async ({ clientId }: { clientId: string }) => {
    const portfolios = await ClientsService.getPortfolios(clientId);
    return { clientId, portfolios };
  },
);

const fetchClientPortfolio = createAsyncThunk(
  "[CLIENT_DETAILS_PORTFOLIO] Fetch client portfolio",
  async ({
    clientId,
    portfolioId,
  }: {
    clientId: string;
    portfolioId: string;
  }) => {
    const portfolio = await ClientsService.getPortfolioById(
      clientId,
      portfolioId,
    );
    return { clientId, portfolio };
  },
);

const fetchWireAccounts = createAsyncThunk(
  "[CLIENT_DETAILS_WIRE_ACCOUNTS] Fetch client wire accounts",
  async ({ clientId }: { clientId: string }) => {
    const wireAccounts = await ClientsService.getWireAccounts(clientId);
    return { clientId, wireAccounts };
  },
);

const fetchOpenFees = createAsyncThunk(
  "[CLIENT_DETAILS_FEES] Fetch client fees",
  async ({ clientId }: { clientId: string }) => {
    const fees = await FeesService.getClientOpenFees(clientId);
    return { clientId, fees };
  },
);

const fetchFeeSchedule = createAsyncThunk(
  "[CLIENT_DETAILS_FEES_SCHEDULE] Fetch client fee schedule",
  async ({ clientId }: { clientId: string }) => {
    const fees = await FeesService.getClientFeeSchedules(clientId);
    return { clientId, fees };
  },
);

export const ClientDetailsThunks = {
  fetchPositions,
  fetchOrders,
  fetchPortfolios,
  fetchClientPortfolio,
  fetchRecentActivities,
  fetchWireAccounts,
  fetchOpenFees,
  fetchFeeSchedule,
};
