import RestService from "shared/api/RestService";
import {
  Watchlist,
  WatchlistResponse,
} from "shared/models/watchlist/WatchlistModel";
import { FaApiRoutes } from "shared/utils/routes";

export interface CreateWatchlistData {
  name: string;
  symbol?: string;
}

interface CreateWatchlistDataMultipleSymbols {
  name: string;
  symbols?: string[];
}

export const WatchlistsService = {
  async delete(id: string) {
    return RestService.delete<void>(
      FaApiRoutes.Advisor.Watchlists.Details(id).Path,
    );
  },

  async put(id: string, watchlistData: CreateWatchlistDataMultipleSymbols) {
    const data = await RestService.put<WatchlistResponse>(
      FaApiRoutes.Advisor.Watchlists.Details(id).Path,
      watchlistData,
    );
    return { ...data, assets: data.assets.map((a) => a.symbol) };
  },

  async post(watchlistData: CreateWatchlistDataMultipleSymbols) {
    const data = await RestService.post<WatchlistResponse>(
      FaApiRoutes.Advisor.Watchlists.Path,
      watchlistData,
    );
    return { ...data, assets: data.assets.map((a) => a.symbol) };
  },

  async fetchOne(id: string): Promise<WatchlistResponse> {
    const watchlist = await RestService.get<WatchlistResponse>(
      FaApiRoutes.Advisor.Watchlists.Details(id).Path,
    );
    return watchlist;
  },

  async fetchAll(): Promise<Watchlist[]> {
    const watchlists = await RestService.get<WatchlistResponse[]>(
      FaApiRoutes.Advisor.Watchlists.Path,
    );

    const promises = watchlists.map((watchlist) =>
      WatchlistsService.fetchOne(watchlist.id),
    );
    const watchlistWithAssets = await Promise.all(promises);
    return watchlistWithAssets
      .flatMap((w) => w)
      .map((w) => ({ ...w, assets: w.assets.map((a) => a.symbol) }));
  },

  async addSymbol(id: string, watchlistData: CreateWatchlistData) {
    const data = await RestService.post<WatchlistResponse>(
      FaApiRoutes.Advisor.Watchlists.Details(id).Path,
      watchlistData,
    );
    return { ...data, assets: data.assets.map((a) => a.symbol) };
  },

  async removeSymbol(id: string, symbol: string) {
    const data = await RestService.delete<WatchlistResponse>(
      FaApiRoutes.Advisor.Watchlists.Details(id).Symbol(symbol),
    );
    return { ...data, assets: data.assets.map((a) => a.symbol) };
  },
};
