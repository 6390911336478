import React from "react";

import { FewMoreQuestionsColumn } from "./components/FewMoreQuestionsColumn";
import { OnboardingFooter } from "./components/OnboardingFooter";
import { OnboardingPartProps } from "./OnboardingFlow";
import { AnswerItem } from "./components/AnswerItem";
import { ScrollOverviewBox } from "./components/ScrollOverviewBox";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import {
  OnboardingActions,
  selectOnboarding,
} from "shared/store/onboarding/OnboardingReducer";
import { Onboarding } from "shared/models/onboarding/OnboardingModel";
import { OnboardingContainer } from "shared/components/common/OnboardingContainer";
import { Labels } from "shared/components/hoc/Labels";

export const Affiliate: React.FC<OnboardingPartProps> = ({
  onNext,
  onPrevious,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isAffiliatedExchangeOrFinra, isControlPerson, isPoliticallyExposed } =
    useSelector(selectOnboarding);

  const accountApprovalLetterRequired =
    !!isAffiliatedExchangeOrFinra || !!isControlPerson;

  const handleTermsCheckboxesChange = (
    value?: boolean,
    type?: keyof Onboarding,
  ) => {
    if (type) dispatch(OnboardingActions.update({ [type]: value }));
  };

  return (
    <OnboardingContainer
      leftColumnContent={<FewMoreQuestionsColumn />}
      rightColumnContent={
        <div className="mb-14 mt-20 flex flex-col">
          <div className="flex-1">
            <Labels.H2 className="mb-2">
              {t("forms.kyc.screen_titles.affiliations.title")}
            </Labels.H2>
            <Labels.B1 className="mb-6">
              {t("forms.kyc.screen_titles.affiliations.sub_title")}
            </Labels.B1>
            <div className="space-y-3">
              <AnswerItem
                label="forms.kyc.terms.affiliationQuestions.affiliatedExchangeOrFinra"
                value={!!isAffiliatedExchangeOrFinra}
                onChange={(v) => {
                  handleTermsCheckboxesChange(
                    !v,
                    "isAffiliatedExchangeOrFinra",
                  );
                }}
                isActive={!!isAffiliatedExchangeOrFinra}
              />
              <AnswerItem
                label="forms.kyc.terms.affiliationQuestions.shareholderTradedCompany"
                value={!!isControlPerson}
                onChange={(v) =>
                  handleTermsCheckboxesChange(!v, "isControlPerson")
                }
                isActive={!!isControlPerson}
              />
              <AnswerItem
                label="forms.kyc.terms.affiliationQuestions.politicallyExposed"
                value={!!isPoliticallyExposed}
                onChange={(v) =>
                  handleTermsCheckboxesChange(!v, "isPoliticallyExposed")
                }
                isActive={!!isPoliticallyExposed}
              />
              <AnswerItem
                label="forms.kyc.terms.noAffiliation"
                value={
                  !isAffiliatedExchangeOrFinra &&
                  !isControlPerson &&
                  !isPoliticallyExposed
                }
                onChange={(v) => {
                  if (v) {
                    handleTermsCheckboxesChange(false, "isPoliticallyExposed");
                    handleTermsCheckboxesChange(
                      false,
                      "isAffiliatedExchangeOrFinra",
                    );
                    handleTermsCheckboxesChange(false, "isControlPerson");
                  }
                }}
                isActive={
                  !isAffiliatedExchangeOrFinra &&
                  !isControlPerson &&
                  !isPoliticallyExposed
                }
              />
            </div>
            {accountApprovalLetterRequired ? (
              <>
                <ScrollOverviewBox className="mt-6 max-h-[250px]">
                  <Labels.B1 className="mb-2">
                    {t("webOnboarding.requiredAWrittenLetter")}
                  </Labels.B1>
                  <Labels.B1 className="mb-2">
                    {t("webOnboarding.includeParty")}
                  </Labels.B1>
                  <ul className="!list ml-2 !list-disc pl-2 text-base">
                    <li>
                      <Labels.B1 className="mb-2">
                        {t("webOnboarding.applicantName")}
                      </Labels.B1>
                      <ul className="mb-2 pl-2">
                        <li>{t("webOnboarding.firstName")}</li>
                        <li>{t("webOnboarding.title")}</li>
                        <li>{t("webOnboarding.emailAddress")}</li>
                        <li>{t("webOnboarding.telephoneNumber")}</li>
                      </ul>
                    </li>
                    <li>{t("webOnboarding.interestedParty")}</li>
                    <li>{t("webOnboarding.companyName")}</li>
                    <li>{t("webOnboarding.firmAddress")}</li>
                    <li>{t("webOnboarding.firmEmail")}</li>
                    <li>{t("webOnboarding.publicCompany")}</li>
                  </ul>
                </ScrollOverviewBox>
                <Labels.B1 className="mb-4 mt-2">
                  {t("forms.kyc.uploadApprovalLetterLater")}
                </Labels.B1>
              </>
            ) : null}
          </div>
          <OnboardingFooter
            onNext={() => onNext && onNext()}
            onPrev={() => onPrevious && onPrevious()}
            nextDisabled={false}
          />
        </div>
      }
    />
  );
};
