import React, { useEffect } from "react";

import { OnboardingPartProps } from "./OnboardingFlow";
import { TermsLink } from "./components/TermsLink";
import { ScrollOverviewBox } from "./components/ScrollOverviewBox";
import { TermsCheckbox } from "./components/TermsCheckbox";
import { OnboardingFooter } from "./components/OnboardingFooter";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import {
  OnboardingActions,
  selectOnboarding,
} from "shared/store/onboarding/OnboardingReducer";
import { useAccount } from "shared/hooks/useAccount";
import { Onboarding } from "shared/models/onboarding/OnboardingModel";
import { AccountThunks } from "shared/store/account/AccountThunks";
import { OnboardingContainer } from "shared/components/common/OnboardingContainer";
import { Labels } from "shared/components/hoc/Labels";
import { useTranslation } from "react-i18next";
import { TermsAndConditionsColumn } from "./components/TermsAndConditionsColumn";

export const TermsAndConditions: React.FC<OnboardingPartProps> = ({
  onPrevious,
  onNext,
}) => {
  const dispatch = useDispatch();
  const {
    taxCountry,
    nonSolicitation,
    boundByTermsAgreement,
    customAgreements,
    cryptoCustomAgreement,
    nationality,
  } = useSelector(selectOnboarding);
  const { account } = useAccount();
  const { t } = useTranslation();

  const isPayingTaxesInUsa = taxCountry === "USA";
  const noneUSNationality = nationality !== "USA";

  const custom_agreements_to_sign = account?.custom_agreements_to_sign || [];

  const handleTermsCheckboxesChange = (
    value: boolean,
    type: keyof Onboarding,
  ) => {
    dispatch(OnboardingActions.update({ [type]: value }));
  };

  useEffect(() => {
    dispatch(AccountThunks.fetch());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nextDisabled =
    (!isPayingTaxesInUsa && !nonSolicitation) ||
    !boundByTermsAgreement ||
    !customAgreements ||
    !cryptoCustomAgreement;

  const handlePrevious = () => {
    if (!onPrevious) return;
    const w8benRequired = !isPayingTaxesInUsa && noneUSNationality;
    if (w8benRequired) {
      onPrevious();
      return;
    }
    onPrevious("affiliate");
  };

  return (
    <OnboardingContainer
      leftColumnContent={<TermsAndConditionsColumn />}
      rightColumnContent={
        <div className="mt-14 flex flex-col">
          <div className="flex-1">
            <Labels.H2 className="mb-8">
              {t("forms.kyc.section.terms")}
            </Labels.H2>
            <ScrollOverviewBox className="max-h-[430px] space-y-5">
              <TermsLink
                className="mb-4 text-lg"
                label="forms.kyc.readDisclosures"
                url="https://streetbeat.com/disclosure-library"
              />
              {!isPayingTaxesInUsa && (
                <TermsCheckbox
                  label="forms.kyc.terms.solicitation"
                  value={!!nonSolicitation}
                  onChange={(v) =>
                    handleTermsCheckboxesChange(!v, "nonSolicitation")
                  }
                />
              )}
              {custom_agreements_to_sign.length ? (
                <TermsCheckbox
                  label="forms.kyc.terms.customAgreements"
                  value={!!customAgreements}
                  onChange={(v) =>
                    handleTermsCheckboxesChange(!v, "customAgreements")
                  }
                >
                  {custom_agreements_to_sign.map((agreement) => (
                    <TermsLink
                      className="mt-4 text-lg"
                      key={agreement.document_type}
                      label={agreement.document_label}
                      url="https://streetbeat.com/disclosure-library"
                    />
                  ))}
                </TermsCheckbox>
              ) : null}

              <TermsCheckbox
                label="forms.kyc.terms.cryptoAgreement"
                value={!!cryptoCustomAgreement}
                onChange={(value) =>
                  handleTermsCheckboxesChange(!value, "cryptoCustomAgreement")
                }
              >
                <TermsLink
                  className="mt-4 text-lg"
                  label="forms.kyc.terms.readCryptoAgreement"
                  url="https://files.alpaca.markets/disclosures/library/Crypto%20Customer%20Agreement.pdf"
                />
              </TermsCheckbox>
              <TermsCheckbox
                label="forms.kyc.terms.boundByTermsAgreement"
                value={boundByTermsAgreement}
                onChange={(v) =>
                  handleTermsCheckboxesChange(!v, "boundByTermsAgreement")
                }
              >
                <TermsLink
                  className="mt-4 text-lg"
                  label="forms.kyc.terms.readCustomerAgreement"
                  url="https://files.alpaca.markets/disclosures/library/AcctAppMarginAndCustAgmt.pdf"
                />
              </TermsCheckbox>
            </ScrollOverviewBox>
          </div>
          <OnboardingFooter
            onNext={() => onNext && onNext()}
            onPrev={handlePrevious}
            nextDisabled={nextDisabled}
          />
        </div>
      }
    />
  );
};
