import React from "react";

type Props = {
  className?: string;
};

export const FilledAddIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      className={`h-[20px] w-[20px] ${className}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6873 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6873 13.9 19.212C12.6833 19.7373 11.3833 20 10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6873 3.825 17.975 2.925 17.075C2.025 16.175 1.31233 15.1167 0.787 13.9C0.262333 12.6833 -3.76646e-07 11.3833 -4.37114e-07 10C-4.97581e-07 8.61667 0.262333 7.31667 0.786999 6.1C1.31233 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.31267 6.1 0.788C7.31667 0.262666 8.61667 -3.76646e-07 10 -4.37114e-07C11.3833 -4.97581e-07 12.6833 0.262666 13.9 0.787999C15.1167 1.31267 16.175 2.025 17.075 2.925C17.975 3.825 18.6873 4.88333 19.212 6.1C19.7373 7.31667 20 8.61667 20 10ZM8.92857 8.92857L8.92857 5.71429C8.92857 5.31987 9.24844 5 9.64286 5L10.3571 5C10.7516 5 11.0714 5.31987 11.0714 5.71429L11.0714 8.92857L14.2857 8.92857C14.6801 8.92857 15 9.24844 15 9.64286L15 10.3571C15 10.7516 14.6801 11.0714 14.2857 11.0714L11.0714 11.0714L11.0714 14.2857C11.0714 14.6801 10.7516 15 10.3571 15L9.64286 15C9.24844 15 8.92857 14.6801 8.92857 14.2857L8.92857 11.0714L5.71429 11.0714C5.31987 11.0714 5 10.7516 5 10.3571L5 9.64286C5 9.24844 5.31987 8.92857 5.71429 8.92857L8.92857 8.92857Z"
      />
    </svg>
  );
};
