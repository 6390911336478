import { DateTime } from "luxon";
import React from "react";
import { useTranslation } from "react-i18next";

import { OnboardingFooter } from "./components/OnboardingFooter";
import { ScrollOverviewBox } from "./components/ScrollOverviewBox";
import { TermsCheckbox } from "./components/TermsCheckbox";
import { TermsLink } from "./components/TermsLink";
import { OnboardingPartProps } from "./OnboardingFlow";
import { OnboardingContainer } from "shared/components/common/OnboardingContainer";
import { Labels } from "shared/components/hoc/Labels";
import { Onboarding } from "shared/models/onboarding/OnboardingModel";
import {
  selectOnboarding,
  OnboardingActions,
} from "shared/store/onboarding/OnboardingReducer";
import { CountryNames } from "shared/types/Countries";
import { convertFullName } from "shared/utils/converts";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import { TermsAndConditionsColumn } from "./components/TermsAndConditionsColumn";

export const W8benForm: React.FC<OnboardingPartProps> = ({
  onPrevious,
  onNext,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    w8ben,
    firstName,
    lastName,
    nationality,
    street,
    city,
    postalCode,
    state,
    taxCountry,
    taxId,
    birthday,
    middleName,
  } = useSelector(selectOnboarding);

  const fullName = convertFullName([firstName, middleName, lastName]);
  const addressAdditional = `${city}, ${state}, ${postalCode}`;

  const handleTermsCheckboxesChange = (
    value: boolean,
    type: keyof Onboarding,
  ) => {
    dispatch(OnboardingActions.update({ [type]: value }));
  };

  const nextDisabled = !w8ben;

  return (
    <OnboardingContainer
      leftColumnContent={<TermsAndConditionsColumn />}
      rightColumnContent={
        <div className="mt-14 flex flex-col">
          <div className="mb-6 flex-1">
            <Labels.H2 className="mb-8">
              {t("forms.kyc.screen_titles.w8ben_form.title")}
            </Labels.H2>
            <Labels.B1 className="mb-6">
              {t("forms.kyc.screen_titles.w8ben_form.sub_title")}
            </Labels.B1>
            <ScrollOverviewBox className="max-h-[250px]">
              <KycW8benInfoRow
                label="kyc.w8ben.identification"
                value={fullName}
              />
              <KycW8benInfoRow
                label="kyc.w8ben.citizenship"
                value={nationality ? CountryNames[nationality] : ""}
              />
              <KycW8benInfoRow
                label="kyc.w8ben.permanent_resident_address"
                value={street}
              />
              <KycW8benInfoRow
                label="kyc.w8ben.address_additional"
                value={addressAdditional}
              />
              <KycW8benInfoRow
                label="kyc.w8ben.taxCountry"
                value={taxCountry ? CountryNames[taxCountry] : ""}
              />
              <KycW8benInfoRow label="kyc.w8ben.taxId" value={taxId} />
              <KycW8benInfoRow
                label="kyc.w8ben.dateOfBirth"
                value={
                  birthday && DateTime.fromISO(birthday).toFormat("LLL dd yyyy")
                }
              />
            </ScrollOverviewBox>
            <div className="peer mb-6 mt-6 block w-full rounded-xl border-0 bg-slate-100 px-2.5 py-2.5">
              <p className="m-0 text-base">
                {t("kyc.w8ben.goTo")}
                <span>
                  <TermsLink
                    className="inline text-base"
                    url="https://www.irs.gov/instructions/iw8ben"
                    text="https://www.irs.gov/instructions/iw8ben"
                  />
                </span>{" "}
                <span>{t("kyc.w8ben.forDetailed")}</span>
              </p>
            </div>
            <TermsCheckbox
              labelClass="!text-base"
              label="forms.kyc.terms.w8ben"
              value={!!w8ben}
              onChange={(v) => handleTermsCheckboxesChange(!v, "w8ben")}
            />
          </div>
          <OnboardingFooter
            onNext={() => onNext && onNext()}
            onPrev={() => onPrevious && onPrevious()}
            nextDisabled={nextDisabled}
          />
        </div>
      }
    />
  );
};

interface KycW8benInfoRowProps {
  label: string;
  value?: string;
}

const KycW8benInfoRow: React.FC<KycW8benInfoRowProps> = ({ label, value }) => {
  const { t } = useTranslation();

  return (
    <div className="mb-2 flex justify-between">
      <p className="mr-2 max-w-[150px] text-sm text-gray-600">{t(label)}</p>
      <p className="max-w-[150px] text-right text-sm font-bold">{value}</p>
    </div>
  );
};
