import LimitOrder from "./svg/LimitOrder";
import MarketOrder from "./svg/MarketOrder";
import StopOrder from "./svg/StopOrder";
import TrailingStopOrder from "./svg/TrailingStopOrder";
import { useTranslation } from "react-i18next";
import {
  Side,
  TradingOrderType,
} from "shared/models/trading/TradingOrderModel";

type Props = {
  type: TradingOrderType;
  side: Side;
  onChange: (t: TradingOrderType) => void;
};

export default function OrderTypeDropdownItem({ type, onChange, side }: Props) {
  const { t } = useTranslation();

  const icons: Record<TradingOrderType, JSX.Element> = {
    limit: <LimitOrder className="h-6 w-6" />,
    market: <MarketOrder className="h-6 w-6" />,
    stop: <StopOrder className="h-6 w-6" />,
    trailing_stop: <TrailingStopOrder className="h-6 w-6" />,
    stop_limit: <MarketOrder className="h-6 w-6" />,
  };

  const direction = side === "buy" ? t("general.lower") : t("general.higher");

  return (
    <div
      className="flex cursor-pointer items-center justify-between gap-4 rounded-lg p-1.5 duration-150 hover:bg-sb-gray-100"
      onClick={() => onChange(type)}
    >
      <div>{icons[type]}</div>
      <div>
        <div className="text-base font-semibold">
          {t(`trade.orderTypes.options.${type}.label`)}
        </div>
        <div className="text-sm text-sb-gray-600">
          {t(`trade.orderTypes.options.${type}.hint`, { direction })}
        </div>
      </div>
    </div>
  );
}
