import React from "react";
import { useTranslation } from "react-i18next";
import { Labels } from "shared/components/hoc/Labels";
import { EvivaLogo } from "shared/icons/EvivaLogo";
import { MessageWidget } from "shared/icons/MessageWidget";

export const FewMoreQuestionsColumn: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="rounded-3xl bg-sb-gray-100 p-10">
      <EvivaLogo className="mb-20 w-[150px]" />
      <Labels.H1 className="mb-4 text-4xl">
        {t("webOnboarding.fewQuestions")}
      </Labels.H1>
      <Labels.B1>{t("webOnboarding.toOpenBrokerage")}</Labels.B1>
      <div className="grid aspect-square w-[300px] place-items-center">
        <MessageWidget className="w-[182px]" />
      </div>
    </div>
  );
};
