enum ActionTypes {
  FULFILLED = "fulfilled",
  REJECTED = "rejected",
  PENDING = "pending",
}

const asyncActionsInProgress = new Map<string, any>();

export const ManageDuplicateActions = () => (next: any) => (action: any) => {
  // Get Label
  const exp = new RegExp(/\/(fulfilled|pending|rejected)$/g);
  const label = action.type.replaceAll(exp, "").concat(action.meta?.arg || "");
  // Get Status
  const statusExp = /\/(fulfilled|pending|rejected)$/;
  const status = action?.type?.match(statusExp)?.[1] || "";

  if (!asyncActionsInProgress.has(label)) {
    asyncActionsInProgress.set(label, status);
    return next(action);
  } else {
    if (status !== ActionTypes.PENDING) {
      asyncActionsInProgress.delete(label);

      return next(action);
    } else {
      return;
    }
  }
};
