import { useTranslation } from "react-i18next";
import CurrencyInput from "react-currency-input-field";
import { FrequencyType } from "shared/types/RecurringInvestmentModel";
import { RecurrentInvestmentIcon } from "./svg/RecurrentInvestmentIcon";
import { convertCurrencyWithDecimalCheck, Number } from "shared/utils/converts";
import { Panel } from "shared/components/hoc/Panels";
import { PropsWithChildren } from "react";
import { RecurringInvestmentFrequency } from "shared/models/client_proposal/ClientProposalModel";

type Props = PropsWithChildren & {
  readOnlyValue?: Number;
  readOnlyFrequencyValue?: string;
  value?: string;
  freqValue?: string;
  onChange?: (value?: string, name?: string) => void;
  onFrequencyChange?: (value: RecurringInvestmentFrequency) => void;
  defaultValue?: string;
  defaultFreqValue?: string;
};

export const RecurrentInvestment: React.FC<Props> = ({
  readOnlyValue,
  readOnlyFrequencyValue,
  freqValue,
  value,
  onChange,
  onFrequencyChange,
  children,
  defaultValue,
  defaultFreqValue,
}) => {
  const { t } = useTranslation();
  const frequencyOptions: { value: FrequencyType; label: string }[] = [
    {
      value: "month",
      label: t("proposals.frequency.month"),
    },
    {
      value: "week",
      label: t("proposals.frequency.week"),
    },
    {
      value: "month_twice",
      label: t("proposals.frequency.month_twice"),
    },
  ];

  return (
    <Panel>
      <div className="relative">
        {children}
        <div className="flex items-center gap-4">
          <div className="grid aspect-square h-10 shrink-0 place-items-center rounded-full bg-sb-gray-200">
            <RecurrentInvestmentIcon className="aspect-square h-6 text-sb-gray-500" />
          </div>
          <div className="text-xl font-medium">
            {t("proposals.recurrentInvestment")}
          </div>
        </div>
        <div className="ml-14 flex items-center justify-between gap-4">
          {readOnlyValue ? (
            <div className="text-2xl">
              {convertCurrencyWithDecimalCheck(readOnlyValue)}
            </div>
          ) : (
            <div className="flex items-center gap-4">
              <div className="text-2xl">$</div>
              <CurrencyInput
                allowNegativeValue={false}
                className="w-full min-w-0 text-3xl caret-sb-green-600 outline-none"
                placeholder="0"
                value={value}
                defaultValue={defaultValue}
                decimalsLimit={2}
                decimalScale={2}
                onValueChange={onChange}
              />
            </div>
          )}
          <div>
            {readOnlyFrequencyValue ? (
              <div className="rounded-full bg-black p-2">
                <div className="text-sm font-semibold text-white">
                  {t(`proposals.frequency.${readOnlyFrequencyValue}`)}
                </div>
              </div>
            ) : (
              <select
                value={freqValue}
                defaultValue={defaultFreqValue}
                onChange={(e) =>
                  onFrequencyChange &&
                  onFrequencyChange(
                    e.target.value as RecurringInvestmentFrequency,
                  )
                }
                className="outline-nonde cursor-pointer rounded-full border border-black px-4 py-1.5"
              >
                {frequencyOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>
      </div>
    </Panel>
  );
};
