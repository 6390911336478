import { PropsWithChildren } from "react";
import { AvatarImage } from "shared/components/common/AvatarImage";
import { Labels } from "shared/components/hoc/Labels";
import { Portfolio } from "shared/models/portfolio/PortfolioModel";

type Props = PropsWithChildren & {
  portfolio: Portfolio;
};

export const PortfolioTitle: React.FC<Props> = ({ portfolio, children }) => {
  return (
    <div className="flex items-center gap-4">
      <AvatarImage
        imageSrc={portfolio?.cover?.uri}
        width={40}
        height={40}
        className="shrink-0"
      />
      <div>
        <Labels.H3>{portfolio.title}</Labels.H3>
        <Labels.G1>{portfolio.symbol}</Labels.G1>
        {children}
      </div>
    </div>
  );
};
