import { TradingPosition } from "shared/models/trading/TradingPositionmodel";

export const calculateWeightedAverage = (data: TradingPosition[]): number => {
  let weightedSum = 0;
  let totalMarketValue = 0;

  data.forEach((asset) => {
    weightedSum += asset.unrealized_plpc * asset.market_value;
    totalMarketValue += asset.market_value;
  });

  if (totalMarketValue === 0) {
    return 0;
  }

  return weightedSum / totalMarketValue;
};
