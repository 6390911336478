import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { Client } from "shared/models/client/ClientModel";
import {
  fetchClientById,
  fetchClients,
} from "shared/store/clients/ClientsThunks";
import { RootState } from "shared/store/store";
import { Loading } from "shared/types/enums";

const clientsAdapter = createEntityAdapter<Client>({
  selectId: (client) => client.user_id,
});

const { selectAll, selectById } = clientsAdapter.getSelectors(
  (state: RootState) => state.clients,
);

interface ClientsState {
  loading: Loading;
  fetched: boolean;
}

const initialState = clientsAdapter.getInitialState<ClientsState>({
  loading: Loading.Idle,
  fetched: false,
});

const clientsSlice = createSlice({
  name: "[CLIENT]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchClients.pending, (state) => {
      state.loading = Loading.InProgress;
    });
    builder.addCase(fetchClients.rejected, (state) => {
      state.loading = Loading.Finished;
      state.fetched = true;
    });
    builder.addCase(
      fetchClients.fulfilled,
      (state, action: { payload: Client[] }) => {
        state.loading = Loading.Finished;
        clientsAdapter.setAll(state, action.payload);
        state.fetched = true;
      },
    );
    // FETCH ONE
    builder.addCase(fetchClientById.pending, (state) => {
      state.loading = Loading.InProgress;
    });
    builder.addCase(fetchClientById.rejected, (state) => {
      state.loading = Loading.Finished;
      state.fetched = true;
    });
    builder.addCase(
      fetchClientById.fulfilled,
      (state, action: { payload?: Client }) => {
        state.loading = Loading.Finished;
        if (action.payload) {
          clientsAdapter.upsertOne(state, action.payload);
        }
      },
    );
  },
});

export const selectClientById = (id?: string) =>
  createSelector(
    (state: RootState) => state,
    (state) => (id ? selectById(state, id) : undefined),
  );

export const selectClients = createSelector(
  (state: RootState) => state.clients,
  selectAll,
  (state, clients) => ({
    loading: state.loading,
    fetched: state.fetched,
    clients: clients,
  }),
);

const ClientsActions = { ...clientsSlice.actions };

export default clientsSlice.reducer;
