import { createAsyncThunk } from "@reduxjs/toolkit";
import { Onboarding } from "shared/models/onboarding/OnboardingModel";
import { BrokerageAccountService } from "shared/services/brokerage_account/BrokerageAccountService";
import CountriesService from "shared/services/fa_onboarding/CountriesService";

interface KYCSubmission {
  KYCData: Onboarding;
  signature: string;
  sensor_data?: string;
}

const submit = createAsyncThunk(
  "[ONBOARDING] Submit KYC",
  async ({ KYCData, signature, sensor_data }: KYCSubmission) => {
    await BrokerageAccountService.post(KYCData, signature, sensor_data);
  },
);

export const fetchAvailableCountries = createAsyncThunk(
  "[ONBOARDING] Fetch available countries",
  async () => await CountriesService.get(),
);

export const OnboardingThunks = { submit, fetchAvailableCountries };
