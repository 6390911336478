import { useState } from "react";
import ChevronRight from "shared/icons/ChevronRight";
import {
  PortfolioActivity,
  ActivityEntryType,
} from "shared/models/portfolio/PortfolioModel";
import { CurrencyFormat } from "shared/utils/currency";
import { formatDate } from "shared/utils/date";

type Props = {
  title: string;
  symbol: string;
  activities: PortfolioActivity[];
  ungroup?: boolean;
};

export const RecentPortfolioActivities: React.FC<Props> = ({
  title,
  symbol,
  activities,
  ungroup,
}) => {
  const [expanded, setExpanded] = useState(false);

  const backgrounds: Record<ActivityEntryType, string> = {
    deposit: "bg-orange-300",
    withdrawal: "bg-orange-300",
    execution: "bg-sb-blue-400",
    dividend: "bg-sb-green-700",
    management_fee: "bg-sb-blue-400",
    product_fee: "bg-sb-blue-400",
    composition: "bg-sb-blue-400",
  };

  const colors: Record<ActivityEntryType, string> = {
    deposit: "text-orange-300",
    withdrawal: "text-orange-300",
    execution: "text-sb-blue-400",
    dividend: "text-sb-green-700",
    management_fee: "text-sb-blue-400",
    product_fee: "text-sb-blue-400",
    composition: "text-sb-blue-400",
  };

  return (
    <>
      {!ungroup && (
        <div
          className="my-2 flex cursor-pointer select-none items-center justify-between rounded-lg p-2"
          onClick={() => !ungroup && setExpanded((prev) => !prev)}
        >
          <div>
            <div className="text-sm text-sb-gray-500">{symbol}</div>
            <div className="font-medium">{title}</div>
          </div>
          <ChevronRight
            className={
              (expanded ? "rotate-90" : "rotate-0") + " h-4 w-4 duration-150"
            }
          />
        </div>
      )}
      {(ungroup || expanded) && (
        <div
          className={
            (!ungroup ? "ml-4" : "") +
            " space-y-2 border-l border-sb-gray-100 pl-2 text-base"
          }
        >
          {activities.map((item, idx) => {
            return (
              <div
                key={"activity_" + idx}
                className="flex items-baseline gap-2"
              >
                <div
                  className={
                    "h-2 w-2 rounded-full " + backgrounds[item.entry_type]
                  }
                ></div>
                <div>
                  <div className={"font-medium " + colors[item.entry_type]}>
                    {item.label}
                    {item.amount && item.entry_type !== "execution" ? (
                      <span className="text-black">
                        <span className="text-sb-gray-200"> | </span>
                        {CurrencyFormat.format(item.amount)}
                      </span>
                    ) : null}
                  </div>
                  <div className="bg-none">{formatDate(item.timestamp)}</div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
