import { useTranslation } from "react-i18next";
import { Modal } from "shared/components/common/Modal";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";
import { Layouts } from "shared/components/hoc/Layouts";
import { CreateTradingOrder } from "shared/models/trading/TradingOrderModel";
import { CurrencyFormat } from "shared/utils/currency";
import { getFormattedAmountValue } from "shared/utils/orders";
import { CreateOrderReviewInfo } from "./hooks/useCreateOrderValidation";
import { OrderSummaryRow } from "./OrderSummaryRow";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  order: CreateTradingOrder;
  reviewedOrder: CreateOrderReviewInfo;
  amountType: string;
  warningsValue?: string[];
  setReviewed: (reviewed: boolean) => void;
  makeOrder: () => void;
};

export const ReviewOrderModal: React.FC<Props> = ({
  open,
  setOpen,
  order,
  reviewedOrder,
  amountType,
  warningsValue,
  setReviewed,
  makeOrder,
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="my-4 text-base">
        <Labels.H3 className="mb-4">{t("trade.summary.title")}</Labels.H3>
        <Layouts.Spaced className="divide-y divide-sb-gray-100">
          <OrderSummaryRow title={t("orders.symbol")} value={order.symbol} />
          <OrderSummaryRow
            title={t("orders.side")}
            value={t(`trade.${order.side}`)}
          />
          <OrderSummaryRow
            title={t("orders.orderType")}
            value={t(`trade.orderTypes.options.${order.type}.label`)}
          />
          <OrderSummaryRow
            title={t("orders.amount")}
            value={getFormattedAmountValue(order, 0) + " " + amountType}
          />
          <OrderSummaryRow
            title={t("orders.tradingAt")}
            value={CurrencyFormat.format(reviewedOrder.tradingAtPrice)}
          />
        </Layouts.Spaced>
      </div>
      {warningsValue && warningsValue.length > 0 ? (
        <div className="mb-4 mt-10 space-y-2 text-base">
          <div className="font-roobert text-sm font-semibold">
            {t("trade.warnings_title").toUpperCase()}
          </div>
          {warningsValue?.map((item, idx) => {
            return <div key={"warning_" + idx}>{item}</div>;
          })}
        </div>
      ) : (
        ""
      )}
      <div className="mt-10 flex items-center justify-center gap-4">
        <Button
          label="global.cancel"
          btnStyle="blank"
          onClick={() => {
            setReviewed(false);
            setOpen(false);
          }}
          className="w-full"
        />
        <Button
          label={`trade.${order.side}`}
          onClick={() => {
            setOpen(false);
            makeOrder();
          }}
          className="w-full"
        />
      </div>
    </Modal>
  );
};
