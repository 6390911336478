import { AIAssistantMessageContent } from "shared/models/assistant/AIAssistantModel";
import { useEffect, useState } from "react";
import { convertPercentage } from "shared/utils/converts";
import { CheckCircle } from "modules/managed_capital/svg/CheckCircleFilled";
import { AvatarImage } from "shared/components/common/AvatarImage";
import { PortfoliosService } from "shared/services/portfolios/PortfoliosService";
import { Portfolio } from "shared/models/portfolio/PortfolioModel";
import { Spinner } from "shared/components/common/Spinner";

type PortfolioResponseProps = {
  content?: AIAssistantMessageContent;
  portfolioObject?: Portfolio;
  active?: boolean;
  onPortfolioClicked?: (portfolioId: string) => Promise<any> | undefined;
};

export const PortfolioAssistantResponse: React.FC<PortfolioResponseProps> = ({
  content,
  active,
  portfolioObject,
  onPortfolioClicked,
}) => {
  const [portfolio, setPortfolio] = useState<Portfolio | undefined>(
    portfolioObject,
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (content?.portfolio?.id) {
      PortfoliosService.getOne(content.portfolio.id).then(setPortfolio);
    }
  }, [content?.portfolio?.id]);

  const cagr = (portfolio?.simulated_metrics.all_time.cagr as number) || 0;

  const handlePortfolioClicked = () => {
    if (portfolio?.id) {
      setLoading(true);
      const promise = onPortfolioClicked?.(portfolio.id);
      if (promise) {
        promise.finally(() => setLoading(false));
      } else {
        setLoading(false);
      }
    }
  };

  return content?.portfolio || portfolio ? (
    <div className="relative">
      {loading && (
        <div className="absolute bottom-0 left-0 right-0 top-0 z-50 bg-white opacity-50">
          <div className="grid h-full w-full place-items-center">
            <Spinner className="aspect-square w-8" />
          </div>
        </div>
      )}
      <div
        className={
          "mx-2 flex items-center justify-between rounded-3xl border px-4 py-2 duration-150 " +
          (active
            ? "border-black bg-black text-white"
            : "cursor-pointer border-sb-gray-200 hover:bg-sb-gray-100")
        }
        onClick={handlePortfolioClicked}
      >
        <div className="w-full">
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center gap-2">
              <AvatarImage
                className="mr-3 shrink-0"
                imageSrc={portfolio?.cover?.uri}
                width={40}
                height={40}
              />
              <div>
                <div className="text-sm font-medium text-sb-gray-500">
                  {content?.portfolio?.symbol || portfolio?.symbol}
                </div>
                <div className="text-base font-semibold">
                  {content?.portfolio?.name || portfolio?.title}
                </div>
                <div className="flex items-center justify-between">
                  <div
                    className={
                      "font-semibold " +
                      (cagr >= 0 ? "text-sb-green-light" : "")
                    }
                  >
                    {convertPercentage(cagr)} CAGR
                  </div>
                </div>
              </div>{" "}
            </div>
            {active && (
              <div>
                <CheckCircle className="aspect-square h-6 text-sb-green-600" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
