import React from "react";

interface Props {
  className?: string;
  leftColumnContent: React.ReactElement;
  rightColumnContent: React.ReactElement;
}

export const OnboardingContainer: React.FC<Props> = ({
  className,
  leftColumnContent,
  rightColumnContent,
}) => {
  return (
    <div
      className={`flex min-h-screen items-center justify-center bg-white ${className}`}
    >
      <div className="relative w-full bg-transparent px-4 py-10 md:max-w-7xl">
        <div className="flex flex-wrap gap-12 md:grid md:grid-cols-2">
          {leftColumnContent}
          {rightColumnContent}
        </div>
      </div>
    </div>
  );
};
