import { Labels } from "shared/components/hoc/Labels";

type Props = {
  title: string;
  description?: string;
};

export const SectionHeader: React.FC<Props> = ({ title, description }) => {
  return (
    <>
      <div className="text-xl font-semibold">{title}</div>
      {description && (
        <Labels.G1 className="text-base">{description}</Labels.G1>
      )}
    </>
  );
};
