import { useEffect } from "react";
import {
  selectRealtimeStockPrice,
  RealtimePricesActions,
} from "shared/store/realtime_prices/RealtimePricesReducer";
import { RealtimeSubscriptionActions } from "shared/store/realtime_prices/RealtimeSubscriptionsReducer";
import { auth } from "shared/utils/firebase";
import { useDispatch, useSelector } from "./useDispatch";

export default function useRealtimeTicker(symbol: string) {
  const dispatch = useDispatch();
  const prices = useSelector(
    selectRealtimeStockPrice(symbol),
    (a, b) =>
      a.latestPrice === b.latestPrice &&
      a.previousClose === b.previousClose &&
      a.subscribed_to_realtime === b.subscribed_to_realtime &&
      a.askPrice === b.askPrice &&
      a.bidPrice === b.bidPrice,
  );

  const { subscribed_to_realtime } = prices;

  useEffect(() => {
    if (!subscribed_to_realtime) {
      auth.onAuthStateChanged((user) => {
        if (user) {
          dispatch(
            RealtimeSubscriptionActions.subscribe({ symbols: [symbol] }),
          );
        }
      });
    }
  }, [dispatch, symbol, subscribed_to_realtime]);

  useEffect(() => {
    return () => {
      auth.onAuthStateChanged((user) => {
        if (user) {
          dispatch(
            RealtimeSubscriptionActions.unsubscribe({ symbols: [symbol] }),
          );
          dispatch(RealtimePricesActions.unsubscribe({ symbol }));
        }
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return prices;
}
