import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import { RootState } from "../store";
import { Loading } from "shared/types/enums";
import { fetchMany, fetchOne } from "../portfolios/PortfoliosThunks";
import { Portfolio } from "shared/models/portfolio/PortfolioModel";

const portfoliosAdapter = createEntityAdapter<Portfolio>({
  selectId: (portfolio) => portfolio.id,
});

const { selectAll, selectById } = portfoliosAdapter.getSelectors(
  (state: RootState) => state.portfolios,
);

interface PortfoliosState {
  loading: Loading;
  fetched: boolean;
}

const initialState = portfoliosAdapter.getInitialState<PortfoliosState>({
  loading: Loading.Idle,
  fetched: false,
});

const PortfoliosSlice = createSlice({
  name: "[PORTFOLIOS]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // FETCH MANY
    builder.addCase(fetchMany.pending, (state) => {
      state.loading = Loading.InProgress;
    });
    builder.addCase(fetchMany.rejected, (state) => {
      state.loading = Loading.Finished;
      state.fetched = true;
    });
    builder.addCase(
      fetchMany.fulfilled,
      (state, action: { payload: Portfolio[] }) => {
        state.loading = Loading.Finished;
        if (state.fetched) {
          portfoliosAdapter.upsertMany(state, action.payload);
        } else {
          portfoliosAdapter.setAll(state, action.payload);
        }
        state.fetched = true;
      },
    );

    // FETCH ONE
    builder.addCase(
      fetchOne.fulfilled,
      (state, action: { payload?: Portfolio }) => {
        if (action.payload) {
          portfoliosAdapter.upsertOne(state, action.payload);
        }
      },
    );
  },
});

export const selectPortfolioById = (id?: string) =>
  createSelector(
    (state: RootState) => state,
    (state) => (id ? selectById(state, id) : undefined),
  );

export const selectPortfolios = createSelector(
  (state: RootState) => state.portfolios,
  selectAll,
  (state, portfolios) => ({
    loading: state.loading,
    fetched: state.fetched,
    portfolios: portfolios,
  }),
);

const PortfoliosActions = { ...PortfoliosSlice.actions };

export default PortfoliosSlice.reducer;
