import { useTranslation } from "react-i18next";
import { Labels } from "shared/components/hoc/Labels";

interface Props<T> {
  value: T;
  label: string;
  isActive: boolean;
  onChange(value: T): void;
}

export function AnswerItem<T>({ value, label, isActive, onChange }: Props<T>) {
  const activeClasses = "bg-sb-green-100 !border-sb-green-700";
  const { t } = useTranslation();
  return (
    <div
      onClick={() => onChange(value)}
      className={`cursor-pointer rounded-lg border border-black p-3 ${
        isActive ? activeClasses : undefined
      }`}
    >
      <Labels.B1>{t(label)}</Labels.B1>
    </div>
  );
}
