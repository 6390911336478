import { CheckCircleSolid } from "modules/fee_templates_list/svg/CheckCircleSolid";
import { FlatFee } from "modules/fee_template_view/FlatFee";
import { Invoicing } from "modules/fee_template_view/Invoicing";
import { PnlPct } from "modules/fee_template_view/PnlPct";
import { useTranslation } from "react-i18next";
import { Labels } from "shared/components/hoc/Labels";
import { FeeTemplate } from "shared/types/FeeTemplateType";
import { formatDateTime } from "shared/utils/date";
import { NetLiquidation } from "./NetLiquidation";

type Props = {
  template: FeeTemplate;
};

export const FeeTemplateView: React.FC<Props> = ({ template }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex w-full items-center justify-between gap-10">
        <div>
          <Labels.H1>{template?.label}</Labels.H1>
          <div className="text-base text-sb-gray-500">
            {t("feeTemplates.createdAt", {
              date: formatDateTime(template.created_at),
            })}
          </div>
        </div>
        <div className="flex items-center gap-4">
          {template.is_default && (
            <div className="flex h-fit w-fit items-center gap-2 rounded-full bg-blue-200 px-4 py-1 text-base font-medium text-blue-500">
              <CheckCircleSolid className="h-6 w-6 fill-blue-500" />
              {t("feeTemplates.default")}
            </div>
          )}
          <div
            className={
              (template.in_ibrk
                ? "bg-sb-green-300 text-sb-green-700"
                : "bg-orange-300 text-orange-700") +
              " flex h-fit w-fit items-center gap-2 rounded-full px-4 py-1 text-base font-medium"
            }
          >
            {template.in_ibrk
              ? t("feeTemplates.ready")
              : t("feeTemplates.processing")}
          </div>
        </div>
      </div>
      <div className="mt-8 grid grid-cols-2 gap-y-2">
        <div className="text-lg font-medium">
          {t("feeTemplates.view.feeStrategy")}
        </div>
        <div>{t(`feeTemplateForm.${template.request["feeStrategy"]}`)}</div>
        <div className="text-lg font-medium">
          {t("feeTemplateForm.chargeCommisions")}
        </div>
        <div>
          {template.request["chargeComissions"]
            ? t("feeTemplates.yes")
            : t("feeTemplates.no")}
        </div>
        <div className="text-lg font-medium">
          {t("feeTemplateForm.chargeOtherFees")}
        </div>
        <div>
          {template.request["chargeOtherFees"]
            ? t("feeTemplates.yes")
            : t("feeTemplates.no")}
        </div>
        {template.request["netLiquidation"] && (
          <NetLiquidation request={template.request} />
        )}
        {template.request["flatFee"] && <FlatFee request={template.request} />}
        {template.request["pnlPctFee"] && <PnlPct request={template.request} />}
        {template.request["invoicing"] && (
          <Invoicing request={template.request} />
        )}
      </div>
    </>
  );
};
