import { usePortfolios } from "shared/hooks/usePortfolios";
import { PortfolioCard } from "modules/portfolio_card/PortfolioCard";
import { PortfolioCardSkel } from "modules/portfolio_card/PortfolioCardSkel";
import { useTranslation } from "react-i18next";
import { BasePage } from "shared/components/common/BasePage";
import { CreatePortfolioButton } from "shared/components/controls/buttons/CreatePortfolioButton";
import { Labels } from "shared/components/hoc/Labels";
import { Loading } from "shared/types/enums";
import { Portfolio } from "shared/models/portfolio/PortfolioModel";
import { useState, useEffect, Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import { Tab } from "@headlessui/react";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import { selectProposals } from "shared/store/proposals/ProposalsReducer";
import { ProposalsThunks } from "shared/store/proposals/ProposalsThunks";
import { Proposal } from "shared/models/proposals/ProposalsModel";
import { selectClients } from "shared/store/clients/ClientsReducer";
import { ClientsThunks } from "shared/store/clients/ClientsThunks";
import { Client } from "shared/models/client/ClientModel";

export const PortfoliosPage = () => {
  const { loading, portfolios } = usePortfolios();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { proposals } = useSelector(selectProposals);
  const { clients } = useSelector(selectClients);

  useEffect(() => {
    dispatch(ProposalsThunks.fetchMany());
    dispatch(ClientsThunks.fetchClients());
  }, [dispatch]);

  useEffect(() => {
    if (searchParams.get("tab")) {
      setSelectedIndex(parseInt(searchParams.get("tab") || "0"));
    }
  }, [searchParams]);

  const tabChanged = (index: number) => {
    setSearchParams({ tab: index.toString() });
    setSelectedIndex(index);
  };

  const customPortfolios = portfolios.filter(
    (item) => item.origin.type === "custom",
  );

  const streetbeatPortfolios = portfolios.filter(
    (item) => item.origin.type === "streetbeat",
  );

  const proposalPortfolios = portfolios.filter(
    (item) => item.origin.type === "proposal",
  );

  return (
    <BasePage>
      <div className="flex items-center justify-between">
        <div>
          <Labels.H1>{t("portfolios.title")}</Labels.H1>
        </div>
        <CreatePortfolioButton />
      </div>

      <div className="mb-4 mt-8">
        <Tab.Group
          defaultIndex={0}
          selectedIndex={selectedIndex}
          onChange={tabChanged}
        >
          <Tab.List className="mb-8 box-border border-b-[3px] border-sb-gray-200 font-medium">
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={(selected ? "active" : "") + " tab !px-10"}>
                  {t("portfolios.streetbeatOrigin")}
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={(selected ? "active" : "") + " tab !px-10"}>
                  {t("portfolios.customOrigin")}
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={(selected ? "active" : "") + " tab !px-10"}>
                  {t("portfolios.proposalOrigin")}
                </button>
              )}
            </Tab>
          </Tab.List>
          {loading === Loading.InProgress && !portfolios.length ? (
            <div className="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
              <PortfolioCardSkel />
              <PortfolioCardSkel />
              <PortfolioCardSkel />
            </div>
          ) : null}
          <Tab.Panels>
            <Tab.Panel>
              {streetbeatPortfolios.length ? (
                <PortfoliosCards portfolios={streetbeatPortfolios} />
              ) : null}
            </Tab.Panel>
            <Tab.Panel>
              {customPortfolios.length ? (
                <PortfoliosCards portfolios={customPortfolios} />
              ) : null}
            </Tab.Panel>
            <Tab.Panel>
              {proposalPortfolios.length ? (
                <ProposalPortfoliosCards
                  portfolios={proposalPortfolios}
                  proposals={proposals}
                  clients={clients}
                />
              ) : null}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>

      <div className="mt-8 space-y-12"></div>
    </BasePage>
  );
};

type PortfoliosProps = {
  portfolios: Portfolio[];
};

const PortfoliosCards: React.FC<PortfoliosProps> = ({ portfolios }) => {
  return (
    <div className="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
      {portfolios.map((item, idx) => {
        return <PortfolioCard key={"portfolio_" + idx} portfolio={item} />;
      })}
    </div>
  );
};

type ProposalPortfoliosProps = PortfoliosProps & {
  proposals: Proposal[];
  clients: Client[];
};

const ProposalPortfoliosCards: React.FC<ProposalPortfoliosProps> = ({
  portfolios,
  proposals,
  clients,
}) => {
  if (!proposals?.length) {
    return null;
  }

  let activePortfolios = [];
  let portfolioClients: Record<string, Client> = {};
  for (let proposal of proposals) {
    const activePortfolio = portfolios.find(
      (p) => p.id === proposal.strategy_type,
    );
    const portfolioClient = clients.find(
      (c) => c.user_id === proposal.client_id,
    );
    if (activePortfolio) {
      activePortfolios.push(activePortfolio);
      if (portfolioClient) {
        portfolioClients[activePortfolio.id] = portfolioClient;
      }
    }
  }

  return (
    <div className="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
      {activePortfolios.map((item, idx) => {
        return (
          <PortfolioCard
            key={"portfolio_" + idx}
            portfolio={item}
            client={portfolioClients[item.id]}
          />
        );
      })}
    </div>
  );
};
