import { useEffect, useState } from "react";

type Props = {
  fake?: boolean;
  fakeStep?: number;
  value?: number;
};

export const ProgressBar: React.FC<Props> = ({
  fake,
  fakeStep = 0.01,
  value,
}) => {
  const [currentValue, setCurrentValue] = useState(0);
  const [_, setCurrentProgress] = useState(0);
  const [step, setStep] = useState(fakeStep);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined = undefined;
    if (fake) {
      interval = setInterval(() => {
        setCurrentProgress((prev) => {
          const v =
            Math.round((Math.atan(prev + step) / (Math.PI / 2)) * 100 * 1000) /
            1000;
          setCurrentValue(v);
          if (v >= 100) {
            clearInterval(interval);
          } else if (v > 70) {
            setStep(0.1);
          }
          return prev + step;
        });
      }, 100);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  return (
    <div className="w-full rounded-full bg-sb-gray-100 p-0.5">
      <div
        className="bg-progress h-2.5 animate-pulse rounded-full"
        style={{ width: `${fake ? currentValue : value}%` }}
      ></div>
    </div>
  );
};
