import { Menu } from "@headlessui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OptionsMenu } from "shared/components/controls/OptionsMenu";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import ChevronRight from "shared/icons/ChevronRight";
import { Client } from "shared/models/client/ClientModel";
import { selectClients } from "shared/store/clients/ClientsReducer";
import { ClientsThunks } from "shared/store/clients/ClientsThunks";

type Props = {
  value?: Client;
  onChange: (client: Client | undefined) => void;
};

export const ClientPicker: React.FC<Props> = ({ onChange, value }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { clients } = useSelector(selectClients);
  const [selectedClient, setSelectedClient] = useState<Client | undefined>(
    value,
  );
  const [filter, setFilter] = useState("");

  const filteredClients = clients.filter(
    (c) =>
      c.summary.client_name.includes(filter) ||
      c.summary.email.includes(filter),
  );

  useEffect(() => {
    if (value && !selectedClient) {
      setSelectedClient(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    dispatch(ClientsThunks.fetchClients());
  }, [dispatch]);

  useEffect(() => {
    onChange(selectedClient);
  }, [onChange, selectedClient]);

  return (
    <div>
      <OptionsMenu
        button={
          <div className="relative w-full min-w-[250px] cursor-pointer rounded-xl border border-sb-gray-200 px-4 py-2">
            <div className="mr-8">
              {!selectedClient ? (
                <span className="select-none text-sb-gray-500">
                  {t("clients.selectAccount")}
                </span>
              ) : (
                <div className="text-ellipsis whitespace-nowrap">
                  <span className="font-medium">
                    {selectedClient.summary.client_name}
                  </span>
                  <span className="ml-2 font-normal text-sb-gray-500">
                    {selectedClient.summary.email}
                  </span>
                </div>
              )}
            </div>
            <div className="absolute right-4 top-1/2 -translate-y-1/2">
              <ChevronRight className="aspect-square w-4 text-sb-green-700 duration-150" />
            </div>
          </div>
        }
        position="left-0"
      >
        <div className="min-w-[250px] px-2 pb-2 pt-4">
          <div className="border-b border-sb-gray-100 pb-2">
            <input
              type="text"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              placeholder={t("global.search")}
              className="w-full rounded-lg border border-sb-gray-200 px-2 py-2 outline-none"
            />
          </div>
          <div className="max-h-[300px] w-fit select-none overflow-y-auto pt-2">
            {filteredClients.map((client) => {
              return (
                <Menu.Item key={"client_" + client.user_id}>
                  <div
                    className="mr-4 cursor-pointer rounded-lg p-2 font-medium duration-150 hover:bg-sb-gray-100"
                    onClick={() => setSelectedClient(client)}
                  >
                    {client.summary.client_name}
                    <div className="text-sm font-normal text-sb-gray-500">
                      {client.summary.email}
                    </div>
                  </div>
                </Menu.Item>
              );
            })}
          </div>
        </div>
      </OptionsMenu>
    </div>
  );
};
