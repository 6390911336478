import { useNavigate, useLocation } from "react-router-dom";

type Props = {
  className?: string;
  href?: string;
};

export const EvivaLogoSmall: React.FC<Props> = ({ className, href }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToLink = () => {
    if (!href || href === location.pathname) return;
    navigate(href);
  };

  return (
    <div onClick={navigateToLink}>
      <EvivaLogoSmallContent className={className} />
    </div>
  );
};

interface EvivaLogoContentProps {
  className?: string;
}

const EvivaLogoSmallContent: React.FC<EvivaLogoContentProps> = ({
  className,
}) => {
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="22" height="22" rx="11" fill="#00CC4F" />
      <path
        d="M12.3103 14.5928C12.3108 13.28 11.8493 12.0073 11.0039 10.99H5.12477C4.44043 10.99 4.2849 11.5701 4.68928 11.9976L10.444 17.646C10.8172 17.9818 11.2216 17.9513 11.4705 17.5238C12.0186 16.6407 12.3091 15.6269 12.3103 14.5928ZM9.6663 7.41778C9.6663 8.79172 10.164 10.0435 11.0039 10.99H16.883C17.5673 10.99 17.7228 10.4099 17.2874 10.013L11.5327 4.3646C11.1905 3.99821 10.7861 4.05928 10.5373 4.48672C10.0085 5.37215 9.6663 6.34917 9.6663 7.41778Z"
        fill="#F7F7F7"
      />
    </svg>
  );
};
