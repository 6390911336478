import { createAsyncThunk } from "@reduxjs/toolkit";
import { FeeTemplatesService } from "shared/services/fee_templates/FeeTemplatesService";

export const fetchFeeTemplates = createAsyncThunk(
  "[FEE_TEMPLATES] Fetch fee templates",
  async () => {
    const templates = await FeeTemplatesService.getMany();
    return templates;
  },
);

export const FeeTemplatesThunks = {
  fetchFeeTemplates,
};
