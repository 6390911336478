import { AIAssistantMessage, AIAssistantMessageContent } from "shared/models/assistant/AIAssistantModel";

export const mapResponseContents = (
  content: AIAssistantMessageContent[],
) => {
  const mappedItems: AIAssistantMessageContent[][] = [];
  let lastType = "";
  for (let item of content) {
    if (lastType === item.type) {
      mappedItems[mappedItems.length - 1].push(item);
    } else {
      mappedItems.push([item]);
    }
    lastType = item.type;
  }
  return mappedItems;
};

export const compactMessages = (messages: AIAssistantMessage[]) => {
  const compactedSystemMessages: AIAssistantMessage[] = [];
  for (let message of JSON.parse(JSON.stringify(messages))) {
    if (compactedSystemMessages.length > 0) {
      const lastMessage =
        compactedSystemMessages[compactedSystemMessages.length - 1];
      if (lastMessage.role === message.role) {
        const poppedMessage = compactedSystemMessages.pop();
        if (poppedMessage) {
          poppedMessage.content.push(...message.content);
          compactedSystemMessages.push(poppedMessage);
        }
      } else {
        compactedSystemMessages.push(message);
      }
    } else {
      compactedSystemMessages.push(message);
    }
  }
  return compactedSystemMessages;
};

