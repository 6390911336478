import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { PortfolioPerformanceChartParams } from "shared/models/portfolio/PortfolioModel";
import { PortfoliosService } from "shared/services/portfolios/PortfoliosService";

export const fetch = createAsyncThunk(
  "[STRATEGIES] Fetch strategy chart",
  async (
    { id, params }: { id: string; params: PortfolioPerformanceChartParams },
    { getState },
  ) => {
    const state = getState() as RootState;
    const period = params?.period || "last_year";
    const cache = state.backtestingPortfolioCharts.cache[id];
    let data = cache && cache[period];
    if (!data) {
      try {
        data = await PortfoliosService.getPortfolioBacktestingChart(id, {
          ...params,
        });
      } catch (err) {
        console.warn(err);
        data = [];
      }
    }
    return { id, period, data };
  },
);

export const BacktestingPortfolioChartsThunks = { fetch };
