import { PlaidLinkOnSuccessMetadata } from "react-plaid-link";
import RestService from "shared/api/RestService";
import { PlaidLinkToken } from "shared/models/plaid/PlaidModel";
import {
  PLAID_ADD_ACCESS_TOKEN,
  PLAID_INVESTMENTS_ACCOUNTS,
  PLAID_INVESTMENTS_TOKEN,
} from "shared/utils/routes";

export interface CreatePlaidACHRelationshipRequest {
  publicToken: string;
  accountId: string;
}

export const PlaidService = {
  async fetchInvestmentsToken(): Promise<PlaidLinkToken> {
    const tokens = await RestService.post<PlaidLinkToken>(
      PLAID_INVESTMENTS_TOKEN,
    );
    return tokens;
  },

  async addAccessToken(
    publicToken: string,
    metadata: PlaidLinkOnSuccessMetadata,
  ) {
    await RestService.post(PLAID_ADD_ACCESS_TOKEN, { publicToken, metadata });
  },

  async getAccounts(): Promise<PlaidLinkOnSuccessMetadata[]> {
    const result = await RestService.get<PlaidLinkOnSuccessMetadata[]>(
      PLAID_INVESTMENTS_ACCOUNTS,
    );
    return result;
  },

  async deleteAccount(institutionId: string) {
    await RestService.delete(`${PLAID_INVESTMENTS_ACCOUNTS}/${institutionId}`);
  },
};
