import { DateTime } from "luxon";

export const TIMEZONE_NEW_YORK = "America/New_York";

const formatDate = (inputDate: string | number): string => {
  if (typeof inputDate == "number") {
    return DateTime.fromMillis(inputDate).toFormat("MMM dd, yyyy");
  }
  return DateTime.fromISO(inputDate).toFormat("MMM dd, yyyy ");
};

const formatDateTime = (inputDate: string | number): string => {
  if (typeof inputDate == "number") {
    return DateTime.fromMillis(inputDate).toFormat("MMM dd, yyyy 'at' hh:mm a");
  }
  return DateTime.fromISO(inputDate).toFormat("MMM dd, yyyy 'at' hh:mm a");
};

const formatTime = (inputDate: string | number): string => {
  if (typeof inputDate == "number") {
    return DateTime.fromMillis(inputDate).toFormat("hh:mm a");
  }
  return DateTime.fromISO(inputDate).toFormat("hh:mm a");
};

const formatRelativeDate = (
  timestamp: number | string,
  locale: string,
): string | null => {
  if (typeof timestamp == "number") {
    return DateTime.fromMillis(timestamp).toRelative({ locale });
  }
  return DateTime.fromISO(timestamp).toRelative({ locale });
};

export { formatDate, formatDateTime, formatRelativeDate, formatTime };
