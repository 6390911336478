import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  OAuthProvider,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import RestService from "shared/api/RestService";
import emailVerificationService from "shared/services/email_verification/EmailVerificationService";
import { auth } from "shared/utils/firebase";
import { FaApiRoutes } from "shared/utils/routes";

class AuthService {
  async sendVerificationEmail() {
    await auth.authStateReady();
    const user = auth.currentUser;
    if (user) {
      sendEmailVerification(user, { url: "https://eviva.streetbeat.com" });
    }
  }

  async signInWithEmailAndPassword(email: string, password: string) {
    const formattedEmail = email.trim().toLowerCase();
    if (!formattedEmail) throw Error("Invalid email");
    if (!password) throw Error("Invalid password");

    const credentials = await signInWithEmailAndPassword(
      auth,
      formattedEmail,
      password,
    );
    return credentials;
  }

  async signInWithGoogle() {
    const googleProvider = new GoogleAuthProvider();
    googleProvider.setCustomParameters({
      prompt: "select_account",
    });
    await signInWithPopup(auth, googleProvider);
  }

  async signInWithApple() {
    const provider = new OAuthProvider("apple.com");
    await signInWithPopup(auth, provider);
  }

  async signUpWithEmailAndPassword(email: string, password: string) {
    await createUserWithEmailAndPassword(auth, email, password);
    await emailVerificationService.sendEmailVerification(
      "https://eviva.streetbeat.com",
    );
  }

  async signOut() {
    return await auth.signOut();
  }

  async sendPasswordResetEmail(url: string, email: string) {
    return RestService.post(FaApiRoutes.ResetPassword, { url, email });
  }
}

const authService = new AuthService();
export default authService;
