import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AssetClass } from "shared/models/asset/AssetModel";
import {
  TradingOrderType,
  CreateTradingOrder,
  TakeProfit,
  StopLoss,
  Side,
} from "shared/models/trading/TradingOrderModel";
import { AmountType } from "shared/utils/types";
import { RootState } from "../store";

interface CreateOrderSettingsPersisted {
  buy?: { orderType: TradingOrderType; amountType: AmountType };
  sell?: { orderType: TradingOrderType; amountType: AmountType };
}

interface CreateOrderSettings {
  buy: { orderType: TradingOrderType; amountType: AmountType };
  sell: { orderType: TradingOrderType; amountType: AmountType };
}

interface CreateOrderState {
  settings?: CreateOrderSettingsPersisted;
  order?: CreateTradingOrder;
}

export interface InitializeOrderPayload {
  symbol: string;
  assetClass: AssetClass;
  side: Side;
  type: TradingOrderType;
  allowNotional: boolean;
  take_profit?: TakeProfit;
  stop_loss?: StopLoss;
}
interface UpdateOrderTypePayload {
  side: Side;
  orderType: TradingOrderType;
}

interface UpdateAmountTypePayload {
  side: Side;
  amountType: AmountType;
}

const initialSettings: CreateOrderSettings = {
  buy: { orderType: "limit", amountType: "qty" },
  sell: { orderType: "limit", amountType: "qty" },
};

const initialState: CreateOrderState = {
  settings: initialSettings,
};

const initializeOrder = (
  state: CreateOrderState,
  {
    symbol,
    side,
    type,
    allowNotional,
    assetClass,
    take_profit,
    stop_loss,
  }: InitializeOrderPayload,
): CreateTradingOrder => {
  const time_in_force = assetClass === "crypto" ? "gtc" : "day";
  switch (type) {
    case "market":
      return {
        symbol,
        side,
        type,
        time_in_force,
        ...(allowNotional
          ? // if notional is allowed then we are populating amount based on settings amount type
            {
              ...(state.settings && state.settings[side]?.amountType === "cash"
                ? { notional: "0" }
                : { qty: "0" }),
            }
          : { qty: "0" }),
      };
    case "limit":
      // here we keep limit_price undefined so we can use realtime mid price from ask/bid spread
      return {
        symbol,
        side,
        type,
        time_in_force: "gtc",
        take_profit,
        stop_loss,
        limit_price: undefined,
        qty: "0",
      };
    case "stop":
      return {
        symbol,
        side,
        type,
        time_in_force: "gtc",
        stop_price: "0",
        qty: "0",
      };
    case "trailing_stop":
      return {
        symbol,
        side,
        type,
        time_in_force: "gtc",
        trail_percent: "0",
        qty: "0",
      };
    default:
      return { symbol, side, type: "market", time_in_force };
  }
};

const CreateOrderSlice = createSlice({
  name: "[CREATE_ORDER]",
  initialState,
  reducers: {
    // We separate updating only the order type for single responsibility reason to avoid updating the whole settings
    updateOrderType: (
      state,
      { payload: { side, orderType } }: PayloadAction<UpdateOrderTypePayload>,
    ) => {
      state.settings = {
        ...state.settings,
        [side]: {
          ...(state.settings &&
            state.settings[side] && { ...state.settings[side] }),
          orderType,
        },
      };
    },
    // We separating to update only the amount type for single responsibility reason to avoid updating the whole settings
    updateAmountType: (
      state,
      { payload: { side, amountType } }: PayloadAction<UpdateAmountTypePayload>,
    ) => {
      state.settings = {
        ...state.settings,
        [side]: {
          ...(state.settings &&
            state.settings[side] && { ...state.settings[side] }),
          amountType,
        },
      };
    },
    initialize: (state, { payload }: PayloadAction<InitializeOrderPayload>) => {
      state.order = initializeOrder(state, payload);
    },
    update: (
      state,
      { payload }: PayloadAction<Partial<CreateTradingOrder>>,
    ) => {
      if (state.order) {
        state.order = { ...state.order, ...payload };
      }
    },
  },
});

export const selectCreateOrder = (state: RootState) => state.createOrder;

const selectCreateOrderSettings = (state: RootState) =>
  state.createOrder.settings;

export const CreateOrderActions = { ...CreateOrderSlice.actions };

export default CreateOrderSlice.reducer;
