import { selectPortfolioById } from "shared/store/portfolios/PortfoliosReducer";
import { PortfolioComposition } from "modules/portfolio_composition/PortfolioComposition";
import { useEffect, useState } from "react";
import { Panel } from "shared/components/hoc/Panels";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import { Proposal } from "shared/models/proposals/ProposalsModel";
import { EditMenu } from "./ProposalEditMenu";
import { useTranslation } from "react-i18next";
import { PortfoliosThunks } from "shared/store/portfolios/PortfoliosThunks";
import { MetaPortfolio } from "shared/models/portfolio/PortfolioModel";
import { ProposalsService } from "shared/services/proposals/ProposalsService";
import { toast } from "react-toastify";
import { ProposalsThunks } from "shared/store/proposals/ProposalsThunks";
import { AccountThunks } from "shared/store/account/AccountThunks";
import { useAccount } from "shared/hooks/useAccount";
import { pctFormatMaxTwo } from "shared/utils/currency";

type Props = {
  proposal: Proposal;
};

export const EditablePortfolioComposition: React.FC<Props> = ({ proposal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { account } = useAccount();
  const [editing, setEditing] = useState(false);
  const [changedMetaPortfolio, setChangedMetaPortfolio] =
    useState<MetaPortfolio[]>();

  const portfolioId = proposal.strategy_type;
  const portfolio = useSelector(selectPortfolioById(portfolioId));

  const [saveLoading, setSaveLoading] = useState(false);
  const canEdit = ["draft", "sent"].includes(proposal.status);

  useEffect(() => {
    if (!portfolio) {
      dispatch(PortfoliosThunks.fetchOne(portfolioId));
    }
  }, [dispatch, portfolioId, portfolio]);

  useEffect(() => {
    if (!account) {
      dispatch(AccountThunks.fetch());
    }
  }, [account, dispatch]);

  const getTotalWeight = () => {
    if (!changedMetaPortfolio) {
      return 0;
    }
    let totalWeight = 0;
    for (let row of changedMetaPortfolio) {
      if (typeof row.weight === "number" && !isNaN(row.weight)) {
        totalWeight += row.weight;
      }
    }
    return totalWeight;
  };

  const isTotalWeightInvalid = () => {
    if (!changedMetaPortfolio) {
      return false;
    }
    const totalWeight = getTotalWeight();
    return totalWeight > 1 || totalWeight <= 0.99;
  };

  const onSaveClicked = () => {
    if (changedMetaPortfolio) {
      setSaveLoading(true);
      ProposalsService.patchPortfolios(proposal.proposal_id, {
        meta_portfolio: changedMetaPortfolio,
      })
        ?.then(() => {
          dispatch(ProposalsThunks.fetchOne(proposal.proposal_id));
          setChangedMetaPortfolio(undefined);
          setEditing(false);
          setSaveLoading(false);
          toast.success(t("faPortfolio.portfolioUpdated"));
        })
        .finally(() => setSaveLoading(false));
    }
  };

  const getError = () => {
    if (isTotalWeightInvalid()) {
      return t("faPortfolio.totalWeightInvalid");
    }
    return undefined;
  };

  return (
    <Panel className="mt-8">
      <div className="relative">
        {saveLoading && (
          <div className="absolute bottom-0 left-0 right-0 top-0 z-50 bg-white opacity-50" />
        )}
        <div className="mb-8 text-xl font-medium">
          {t("global.allocations")}
        </div>
        {canEdit && !saveLoading && (
          <EditMenu
            editing={editing}
            setEditing={setEditing}
            onSaveClicked={onSaveClicked}
            error={getError}
          />
        )}
        {editing && (
          <div className="w-full text-right font-semibold">
            {t("faPortfolio.totalWeight", {
              weight: pctFormatMaxTwo.format(getTotalWeight()),
            })}
          </div>
        )}
        <PortfolioComposition
          portfolio={portfolio}
          editing={editing}
          onChange={setChangedMetaPortfolio}
          totalInvalid={isTotalWeightInvalid}
        />
      </div>
    </Panel>
  );
};
