import { Onboarding } from "shared/models/onboarding/OnboardingModel";

export interface KYCRequirements {
  employmentInfo: boolean;
  photoId: boolean;
  visaOrResidenceCardRequired: boolean;
  addressProof: boolean;
  w8ben: boolean;
}

export const getKYCRequirements = (data: Onboarding): KYCRequirements => {
  const noneUSNationality = data.nationality !== "USA";
  const payingTaxesInUSA = data.taxCountry === "USA";

  const employmentInfo = data.employmentStatus === "employed";
  const visaOrResidenceCardRequired = noneUSNationality && payingTaxesInUSA;
  const addressProof = false; // no longer required
  const photoId = false; // no longer required

  // W8BEN is required for customers who has different nationality from US and is not paying taxes in USA
  const w8ben = noneUSNationality && !payingTaxesInUSA;

  return {
    employmentInfo,
    photoId,
    visaOrResidenceCardRequired,
    addressProof,
    w8ben,
  };
};

export const formatStreetAddress = (
  street: string,
  unit: string | undefined,
): string[] => {
  return unit ? [street, unit] : [street];
};
