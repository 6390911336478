import { usePnlPct } from "modules/fee_template_form/hooks/usePnlPct";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { InfoSection } from "../InfoSection";
import { YesNoSwitch } from "../YesNoSwitch";
import { HighWaterMark } from "./HighWaterMark";
import { PerformanceThreshold } from "./PerformanceThreshold";

export const PnlPctForm: React.FC = () => {
  const { t } = useTranslation();
  const { appliedChoices } = usePnlPct();

  const [performanceThreshold, setPerformanceThreshold] = useState(false);
  const [highWaterMark, setHighWaterMark] = useState(false);

  return (
    <>
      <div>
        <InfoSection text={t("feeTemplateForm.pnlPct.infoText")} />
        <div className="fee-template-form-section">
          <div className="fee-template-form">
            <div>{t("feeTemplateForm.applied")}</div>
            <select name="pnlPct_applied">
              {appliedChoices.map((item, idx) => (
                <option key={idx} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            <label>{t("feeTemplateForm.percentage")}</label>
            <input type="number" name="pnlPct_percentage" required />
            <div>{t("feeTemplateForm.pnlPct.performanceThreshold")}</div>
            <YesNoSwitch
              name="pnlPct_performanceThreshold"
              onChange={setPerformanceThreshold}
            />
            {performanceThreshold && <PerformanceThreshold />}
            <div>{t("feeTemplateForm.pnlPct.highWaterMark")}</div>
            <YesNoSwitch
              name="pnlPct_highWaterMark"
              onChange={setHighWaterMark}
            />
            {highWaterMark && <HighWaterMark />}
          </div>
        </div>
      </div>
    </>
  );
};
