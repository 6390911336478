import React from "react";
import { useTranslation } from "react-i18next";

export const AddContactFeeHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h1 className="mb-1 text-xl font-medium">
        {t("addContactForm.feeTitle")}
      </h1>
      <h3 className="text-md mb-6 text-gray-400">
        {t("addContactForm.feeSubtitle")}
      </h3>
    </div>
  );
};
