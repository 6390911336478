import React from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "../common/Spinner";

interface Props {
  className?: string;
  btnStyle?: "green" | "blank" | "black";
  disabled?: boolean;
  labelClassName?: string;
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  label: string;
  type?: "button" | "submit" | "reset";
  loading?: boolean;
  onClick?: () => void;
  onKeyDown?: (e: React.KeyboardEvent) => void; // Define the onKeyDown prop
}

export const Button: React.FC<Props> = ({
  className,
  label,
  btnStyle,
  labelClassName,
  disabled,
  type = "button",
  leftIcon,
  rightIcon,
  loading,
  onClick,
  onKeyDown,
}) => {
  const { t } = useTranslation();

  const btnClassNames = {
    green: "green-btn",
    blank: "blank-btn",
    black: "black-btn",
  };

  return (
    <button
      disabled={disabled}
      className={`${
        btnStyle ? btnClassNames[btnStyle] : btnClassNames.green
      } relative ${className}`}
      type={type}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      <div
        className={`flex flex-row items-center justify-center ${
          loading ? "opacity-0" : ""
        }`}
      >
        {leftIcon && leftIcon}
        <div className={`px-2 ${labelClassName}`}>{t(label)}</div>
        {rightIcon && rightIcon}
      </div>
      {loading ? (
        <Spinner className="absolute bottom-0 left-0 right-0 top-0 m-auto h-[20px] w-[20px] text-white" />
      ) : null}
    </button>
  );
};
