import { PropsWithChildren } from "react";
import {
  Button,
  Calendar,
  CalendarCell,
  CalendarGrid,
  DatePicker,
  DatePickerProps,
  DateRangePicker,
  DateRangePickerProps,
  DateValue,
  Dialog,
  Group,
  Heading,
  Popover,
  RangeCalendar,
} from "react-aria-components";
import ChevronRight from "shared/icons/ChevronRight";
import "./styles.css";

interface CustomDateRangePickerProps<T extends DateValue>
  extends DateRangePickerProps<T> {}

export function CustomDateRangePicker<T extends DateValue>({
  children,
  ...props
}: PropsWithChildren<CustomDateRangePickerProps<T>>) {
  return (
    <DateRangePicker {...props} aria-label="date-range-picker">
      <Group>
        <Button className="outline-none">{children}</Button>
      </Group>
      <Popover className={"min-h-[294px] rounded-lg bg-white p-4 shadow-md"}>
        <Dialog>
          <RangeCalendar>
            <div className="mb-4 flex items-center justify-between">
              <Button slot="previous">
                <ChevronRight className="h-4 w-4 rotate-180" />
              </Button>
              <Heading />
              <Button slot="next">
                <ChevronRight className="h-4 w-4" />
              </Button>
            </div>
            <CalendarGrid>
              {(date) => <CalendarCell date={date} />}
            </CalendarGrid>
          </RangeCalendar>
        </Dialog>
      </Popover>
    </DateRangePicker>
  );
}

interface CustomDatePickerProps<T extends DateValue>
  extends DatePickerProps<T> {}

export function CustomDatePicker<T extends DateValue>({
  children,
  ...props
}: PropsWithChildren<CustomDatePickerProps<T>>) {
  return (
    <DatePicker {...props}>
      <Group>
        <Button className="w-full outline-none">{children}</Button>
      </Group>
      <Popover className="min-h-[294px] rounded-lg bg-white p-4 shadow-md">
        <Dialog>
          <Calendar className="react-aria-RangeCalendar">
            <div className="mb-4 flex items-center justify-between">
              <Button slot="previous">
                <ChevronRight className="h-4 w-4 rotate-180" />
              </Button>
              <Heading />
              <Button slot="next">
                <ChevronRight className="h-4 w-4" />
              </Button>
            </div>
            <CalendarGrid>
              {(date) => <CalendarCell date={date} />}
            </CalendarGrid>
          </Calendar>
        </Dialog>
      </Popover>
    </DatePicker>
  );
}
