type Props = {
  className?: string;
};

export const StarIcon: React.FC<Props> = ({ className }) => {
  return (
    <>
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M10.9271 3.65707L8.48599 8.54102L3.02432 9.32673C2.04488 9.4669 1.65235 10.6584 2.36263 11.3408L6.31403 15.1402L5.37945 20.5074C5.21123 21.4776 6.24674 22.2043 7.11403 21.7505L12 19.2163L16.886 21.7505C17.7533 22.2006 18.7888 21.4776 18.6206 20.5074L17.686 15.1402L21.6374 11.3408C22.3476 10.6584 21.9551 9.4669 20.9757 9.32673L15.514 8.54102L13.0729 3.65707C12.6355 2.78652 11.3682 2.77545 10.9271 3.65707Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};
