import { useTranslation } from "react-i18next";
import { NetLiquidationAppliedType, NetLiquidationType } from "../types";

export const useNetLiquidation = () => {
  const { t } = useTranslation();

  const typeChoices: { label: string; value: NetLiquidationType }[] = [
    {
      label: t("feeTemplateForm.netLiquidation.fixed"),
      value: "fixed",
    },
    {
      label: t("feeTemplateForm.netLiquidation.blended"),
      value: "blended",
    },
  ];

  const appliedChoices: { label: string; value: NetLiquidationAppliedType }[] =
    [
      {
        label: t("feeTemplateForm.netLiquidation.daily"),
        value: "daily",
      },
      {
        label: t("feeTemplateForm.netLiquidation.monthly"),
        value: "monthly",
      },
      {
        label: t("feeTemplateForm.netLiquidation.monthly_end"),
        value: "monthly_end",
      },
      {
        label: t("feeTemplateForm.netLiquidation.quarterly"),
        value: "quarterly",
      },
      {
        label: t("feeTemplateForm.netLiquidation.quarterly_end"),
        value: "quarterly_end",
      },
    ];

  return { typeChoices, appliedChoices };
};
