import { useTranslation } from "react-i18next";
import { Panel } from "shared/components/hoc/Panels";
import { useAccount } from "shared/hooks/useAccount";

export const ActionRequiredApprovedView = () => {
  const { t } = useTranslation();
  const { account } = useAccount();
  const brokerageAccount =
    account?.client?.summary?.alpaca_details?.brokerage_account;

  if (!brokerageAccount) return null;

  const { identity, contact } = brokerageAccount;

  return (
    <Panel>
      <div className="space-y-4">
        <div>
          <div className="font-semibold">{t("settings.traderApp.status")}</div>
          <div className="text-lg">
            {t(`trader.result.${brokerageAccount.status}`)}
          </div>
        </div>
        <div>
          <div className="font-semibold">{t("settings.traderApp.name")}</div>
          <div className="text-lg">
            {`${identity.given_name} ${identity.family_name}`}
          </div>
        </div>
        <div>
          <div className="font-semibold">{t("settings.traderApp.email")}</div>
          <div className="text-lg">{contact.email_address}</div>
        </div>
      </div>
    </Panel>
  );
};
