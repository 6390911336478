import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Labels } from "shared/components/hoc/Labels";
import { Layouts } from "shared/components/hoc/Layouts";
import { Panel } from "shared/components/hoc/Panels";
import { useAccount } from "shared/hooks/useAccount";
import { Person } from "shared/icons/Person";
import { DocumentType } from "shared/models/brokerage_account/BrokerageAccountModel";

export const RecentUploadedDocuments: React.FC = () => {
  const { t } = useTranslation();

  const { account } = useAccount();
  const { last_event, documents } =
    account?.client?.summary?.alpaca_details?.brokerage_account || {};

  if (!last_event) return null;

  const userUploadedDocumentTypes: DocumentType[] = [
    "identity_verification",
    "address_verification",
    "account_approval_letter",
    "date_of_birth_verification",
    "tax_id_verification",
    "other",
  ];

  const recentUploadedDocuments = documents?.filter(
    (d) =>
      d.created_at &&
      userUploadedDocumentTypes.includes(d.document_type) &&
      DateTime.fromISO(d.created_at) > DateTime.fromISO(last_event.at),
  );

  if (!recentUploadedDocuments?.length) return null;

  return (
    <Layouts.Grid>
      <Labels.H2>{t("documents.documents_uploaded")}</Labels.H2>

      {recentUploadedDocuments.map((document) => (
        <Panel key={document.created_at}>
          <Layouts.Row>
            <Person />
            <Layouts.Grid>
              <Labels.B1>
                {document.created_at &&
                  DateTime.fromISO(document.created_at).toFormat(
                    "MMM dd, hh:mm",
                  )}
              </Labels.B1>

              {document.content !== undefined ? (
                <Link to={document.content}>
                  <Labels.B1>
                    {t(`documents.${document.document_type}`)}
                  </Labels.B1>
                </Link>
              ) : null}
            </Layouts.Grid>
          </Layouts.Row>
        </Panel>
      ))}
    </Layouts.Grid>
  );
};
