import { SerializedError, createSlice } from "@reduxjs/toolkit";
import { RootState } from "shared/store/store";
import { Loading } from "shared/types/enums";
import { FeeTemplateEntry } from "shared/types/FeeTemplateType";
import { fetchFeeTemplates } from "./FeeTemplatesThunks";

interface FeeTemplatesReducer {
  feeTemplates: FeeTemplateEntry[];
  loading: Loading;
  error?: SerializedError;
}

const initialState: FeeTemplatesReducer = {
  feeTemplates: [],
  loading: Loading.Idle,
};

const feeTemplatesSlice = createSlice({
  name: "[FEE_TEMPLATES]",
  initialState,
  reducers: {
    reset(state) {
      state.feeTemplates = initialState.feeTemplates;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFeeTemplates.pending, (state) => {
      state.loading = Loading.InProgress;
    });
    builder.addCase(fetchFeeTemplates.rejected, (state, action) => {
      state.loading = Loading.Finished;
      state.error = action.error;
      return state;
    });
    builder.addCase(fetchFeeTemplates.fulfilled, (state, action) => {
      state.loading = Loading.Finished;
      state.feeTemplates = action.payload;
      delete state.error;
      return state;
    });
  },
});

export const selectFeeTemplates = () => (state: RootState) =>
  state.feeTemplates;

const FeeTemplatesActions = { ...feeTemplatesSlice.actions };

export default feeTemplatesSlice.reducer;
