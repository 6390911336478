import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ChevronRight from "shared/icons/ChevronRight";
import { Link } from "react-router-dom";
import { useChartColors } from "./hooks/useChartColors";
import {
  MetaPortfolio,
  PortfolioAssetAllocation,
} from "shared/models/portfolio/PortfolioModel";
import { pctFormatInt, pctFormatMaxTwo } from "shared/utils/currency";
import { TrashIcon } from "shared/icons/TrashIcon";
import { GlobalSearch } from "modules/global_search/GlobalSearch";
import { AssetWrapper } from "shared/models/asset/AssetModel";
import { toast } from "react-toastify";

type Props = {
  metaPortfolio: MetaPortfolio;
  color: string;
  editing?: boolean;
  onChange?: (newMeta: MetaPortfolio) => void;
  onRemove?: (assetClass: string) => void;
  totalInvalid?: () => boolean;
};

export const MetaPortfolioRow: React.FC<Props> = ({
  metaPortfolio,
  color,
  editing,
  onChange,
  onRemove,
  totalInvalid,
}) => {
  const { t } = useTranslation();
  const { getOuterColor } = useChartColors();
  const [open, setOpen] = useState(editing);
  const [newMetaPortfolio, setNewMetaPortfolio] = useState(metaPortfolio);

  useEffect(() => {
    setOpen((prev) => editing || prev);
    if (!editing) {
      setNewMetaPortfolio(metaPortfolio);
    }
  }, [editing, metaPortfolio]);

  const setNewTotalWeight = (weight: number) => {
    const newMeta = {
      ...newMetaPortfolio,
      weight,
    };
    setNewMetaPortfolio(newMeta);
    onChange?.(newMeta);
  };

  const onAllocationChanged = (ticker: string, weight: number) => {
    const newMeta = JSON.parse(JSON.stringify(newMetaPortfolio));
    for (let allocation of newMeta.allocations) {
      if (allocation.ticker === ticker) {
        allocation.weight = weight;
      }
    }
    setNewMetaPortfolio(newMeta);
    onChange?.(newMeta);
  };

  const onAllocationRemoved = (ticker: string) => {
    const newMeta = { ...newMetaPortfolio };
    newMeta.allocations = newMeta.allocations.filter(
      (allocation) => allocation.ticker !== ticker,
    );
    setNewMetaPortfolio(newMeta);
    onChange?.(newMeta);
  };

  const onAssetAdded = (asset: AssetWrapper) => {
    const newMeta = JSON.parse(JSON.stringify(newMetaPortfolio));
    if (
      newMeta.allocations.find(
        (a: PortfolioAssetAllocation) => a.ticker === asset.symbol,
      )
    ) {
      toast.warning(t("faPortfolio.assetExists"));
      return;
    }
    newMeta.allocations.push({
      name: asset.name,
      symbol: asset.symbol,
      ticker: asset.symbol,
      asset_id: asset.asset_id,
      weight: "0",
    });
    setNewMetaPortfolio(newMeta);
    onChange?.(newMeta);
  };

  const getAllocation = () => {
    return newMetaPortfolio.allocations
      .map((a) => a.weight)
      .filter((a) => typeof a === "number" && !isNaN(a))
      .reduce((a, b) => a + parseFloat(b), 0);
  };

  const isAllocationValid = () => {
    if (!editing) {
      return true;
    }
    const allocationSum = getAllocation();
    return allocationSum <= 1 && allocationSum > 0.99;
  };

  return (
    <>
      <div
        className="relative cursor-pointer rounded-md bg-sb-gray-100 px-4 py-2"
        onClick={() => !editing && setOpen((prev) => !prev)}
      >
        {editing && (
          <div
            className="absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer text-sb-red-700"
            onClick={() => onRemove?.(metaPortfolio.asset_class)}
          >
            <TrashIcon className="aspect-square w-3.5" />
          </div>
        )}
        <div className="mr-4 grid grid-cols-2 gap-4">
          <div
            className="flex flex-1 select-none items-center gap-2 text-base font-semibold"
            style={{ color: color }}
          >
            <ChevronRight
              className={
                (open ? "-rotate-90" : "rotate-90") +
                " h-4 w-4 shrink-0 text-black duration-150"
              }
            />
            {t(`portfolioCard.assetClasses.${metaPortfolio.asset_class}`)}
          </div>
          <div
            className="flex-1 select-none text-right font-semibold"
            style={{ color: color }}
          >
            {editing ? (
              <input
                type="number"
                name={`${metaPortfolio.asset_class}_weight`}
                className={
                  (totalInvalid?.()
                    ? "border-sb-red-500"
                    : "border-sb-gray-100") +
                  " w-[80px] rounded-lg border px-2 text-right outline-none"
                }
                onChange={(e) =>
                  setNewTotalWeight(parseFloat(e.target.value) / 100)
                }
                value={newMetaPortfolio.weight * 100}
              />
            ) : (
              pctFormatInt.format(metaPortfolio.weight)
            )}
          </div>
        </div>
      </div>
      {open && (
        <div className="mr-4 py-1 text-base">
          {!isAllocationValid() && (
            <div className="text-center text-sb-red-700">
              {t("faPortfolio.allocationsInvalid")}
            </div>
          )}
          {editing && (
            <div className="text-center font-semibold">
              {t("faPortfolio.allocation", {
                weight: pctFormatMaxTwo.format(getAllocation()),
              })}
            </div>
          )}
          {metaPortfolio.allocations.map((allocation, idx) => {
            return (
              <div
                key={"allocation_" + idx}
                className="my-2 flex justify-between"
              >
                <div className="flex items-center gap-1">
                  <div
                    className="ml-10 h-2 w-2 shrink-0 rounded-full"
                    style={{
                      backgroundColor: getOuterColor(
                        color,
                        idx,
                        metaPortfolio.allocations.length,
                      ),
                    }}
                  ></div>
                  <Link
                    to={`/dashboard/assets/${allocation.ticker}`}
                    className={
                      "hover:rounded-lg hover:bg-black hover:px-1 hover:text-white"
                    }
                  >
                    {allocation.name} ({allocation.ticker})
                  </Link>
                </div>
                {editing ? (
                  <div className="flex items-center justify-end gap-4">
                    <input
                      className={
                        (isAllocationValid()
                          ? "border-sb-gray-100"
                          : "border-sb-red-500") +
                        " ml-8 w-[80px] rounded-lg border px-2 text-right outline-none"
                      }
                      type="number"
                      onChange={(e) =>
                        onAllocationChanged(
                          allocation.ticker,
                          parseFloat(e.target.value) / 100,
                        )
                      }
                      value={+allocation.weight * 100}
                    />
                    <div
                      className="cursor-pointer"
                      onClick={() => onAllocationRemoved(allocation.ticker)}
                    >
                      <TrashIcon className="aspect-square w-3.5 cursor-pointer text-sb-red-700" />
                    </div>
                  </div>
                ) : (
                  <div className="text-right font-light">
                    {+(+allocation.weight * 100).toFixed(2)}%
                  </div>
                )}
              </div>
            );
          })}
          {editing && (
            <div className="ml-4 mt-4 text-sm">
              <div className="text-center font-semibold">
                {t("faPortfolio.addAsset")}
              </div>
              <GlobalSearch onClicked={onAssetAdded} wFull />
            </div>
          )}
        </div>
      )}
    </>
  );
};
