import { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  className?: string;
  onClick?(): void;
}>;

const Grid: React.FC<Props> = ({ className, onClick, children }) => {
  return (
    <div className={["grid gap-4", className].join(" ")} onClick={onClick}>
      {children}
    </div>
  );
};

const Row: React.FC<Props> = ({ className, onClick, children }) => {
  return (
    <div
      className={["relative flex gap-4", className].join(" ")}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

const Spaced: React.FC<Props> = ({ className, children }) => {
  return <div className={["space-y-4", className].join(" ")}>{children}</div>;
};

export const Layouts = {
  Grid,
  Row,
  Spaced,
};
