import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { BackButton } from "shared/components/common/BackButton";
import { Button } from "shared/components/controls/Button";
import AuthService from "shared/services/auth/AuthService";

type Props = {
  onBackClicked: () => void;
};

export const ForgotPassword: React.FC<Props> = ({ onBackClicked }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const handlePasswordReset = (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formDataObject = Object.fromEntries(formData.entries());
    const email = formDataObject?.email.toString();
    setLoading(true);
    AuthService.sendPasswordResetEmail("https://eviva.streetbeat.com", email)
      .then(() => {
        toast.success(t("auth.forgotPasswordSuccess"));
      })
      .catch((error) => {
        const code = error.code;
        let message = error.message;
        if (code === "auth/user-not-found") {
          message = t("auth.userNotFound");
        }
        toast.error(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <form onSubmit={handlePasswordReset} className="space-y-4">
        <div>
          <div className="text-center text-lg font-semibold">
            {t("auth.forgotPasswordTitle")}
          </div>
          <div className="mb-4 text-base">
            {t("auth.forgotPasswordDescription")}
          </div>
        </div>
        <input
          type="text"
          name="email"
          className="w-full rounded-lg bg-sb-gray-100 p-4 text-lg"
          placeholder={t("auth.emailAddress")}
        ></input>
        <div className="flex items-center justify-between">
          <BackButton onClick={onBackClicked} />
          <Button
            btnStyle="black"
            loading={loading}
            className="w-fit"
            label="auth.resetPassword"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};
