import { useState } from "react";

import { AddContactFormHeader } from "./components/AddContactFormHeader";
import { ContactDetailsInputs } from "./components/ContactDetailsInputs";
import { ContactAdded } from "./components/ContactAdded";
import { Panel } from "shared/components/hoc/Panels";
import { Button } from "shared/components/controls/Button";
import { ContactFeesInputs } from "./components/ContactFeesInput";
import { AddContactFeeHeader } from "./components/AddContactFeeHeader";

type Props = {
  handleSubmit: (event: any) => void;
  submitting: boolean;
  onEffectiveDateChange: (d: string) => void;
  clientAdded: boolean;
  setClientAdded: (b: boolean) => void;
};

export const AddContactForm: React.FC<Props> = ({
  handleSubmit,
  submitting,
  onEffectiveDateChange,
  clientAdded,
  setClientAdded,
}) => {
  const resetHandler = () => {
    setClientAdded(false);
  };

  if (clientAdded) {
    return <ContactAdded addNewContactHandler={resetHandler} />;
  }

  return (
    <div className="space-y-4">
      <Panel>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <AddContactFormHeader />
              <ContactDetailsInputs submitting={submitting} />
              <div className="mt-6 flex items-center gap-4">
                <Button
                  loading={submitting}
                  type="submit"
                  label="addContactForm.title"
                />
                <div className="flex cursor-pointer select-none items-center gap-2 accent-sb-green-600">
                  <input id="invite" name="invite" type="checkbox" />
                  <label htmlFor="invite">Send invite</label>
                </div>
              </div>
            </div>
            <div>
              <AddContactFeeHeader />
              <ContactFeesInputs
                submitting={submitting}
                onEffectiveDateChange={onEffectiveDateChange}
              />
            </div>
          </div>
        </form>
      </Panel>
    </div>
  );
};
