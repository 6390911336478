import { useNetLiquidation } from "modules/fee_template_form/hooks/useNetLiquidation";
import { NetLiquidationType } from "modules/fee_template_form/types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { InfoSection } from "../InfoSection";
import { NetLiquidationBlendedForm } from "./NetLiquidationBlendedForm";
import { NetLiquidationFixedForm } from "./NetLiquidationFixedForm";

export const NetLiquidationForm: React.FC = () => {
  const { t } = useTranslation();
  const { typeChoices, appliedChoices } = useNetLiquidation();
  const [type, setType] = useState<NetLiquidationType>(typeChoices[0].value);

  return (
    <div>
      <InfoSection text={t("feeTemplateForm.netLiquidation.infoText")} />
      <div className="fee-template-form-section">
        <div className="fee-template-form">
          <div>{t("feeTemplateForm.netLiquidation.type")}</div>
          <select
            name="netLiquidation_type"
            onChange={(e) => setType(e.target.value as NetLiquidationType)}
          >
            {typeChoices.map((item, idx) => (
              <option key={idx} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          <div>{t("feeTemplateForm.applied")}</div>
          <select name="netLiquidation_applied">
            {appliedChoices.map((item, idx) => (
              <option key={idx} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          {type === "fixed" ? (
            <NetLiquidationFixedForm />
          ) : (
            <NetLiquidationBlendedForm />
          )}
        </div>
      </div>
    </div>
  );
};
