import { useEffect } from "react";
import { BasePage } from "shared/components/common/BasePage";
import { useAccount } from "shared/hooks/useAccount";
import { useDispatch } from "shared/hooks/useDispatch";
import { AccountThunks } from "shared/store/account/AccountThunks";
import { ActionRequiredApprovedView } from "./views/ActionRequiredApprovedView";
import { ActionRequiredDefaultView } from "./views/ActionRequiredDefaultView";
import { ActionRequiredRejectedView } from "./views/ActionRequiredRejectedView";
import { ActionRequiredReviewPendingView } from "./views/ActionRequiredReviewPendingView";
import { ActionRequiredSubmittedView } from "./views/ActionRequiredSubmittedView";

export const ActionRequiredPage: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AccountThunks.fetch());
  }, []);

  return (
    <BasePage>
      <ActionRequiredContainer />
    </BasePage>
  );
};

const ActionRequiredContainer: React.FC = () => {
  const { account } = useAccount();
  const status =
    account?.client?.summary?.alpaca_details?.brokerage_account?.status ||
    "INCOMPLETE";

  switch (status) {
    case "SUBMITTED":
    case "APPROVAL_PENDING":
      return <ActionRequiredSubmittedView />;
    case "ACTIVE":
    case "APPROVED":
      return <ActionRequiredApprovedView />;
    case "REJECTED":
      return <ActionRequiredRejectedView />;
    case "REVIEW_PENDING":
      return <ActionRequiredReviewPendingView />;
    case "ACTION_REQUIRED":
      return <ActionRequiredDefaultView />;

    default:
      return <ActionRequiredDefaultView />;
  }
};
