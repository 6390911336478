import { createAsyncThunk } from "@reduxjs/toolkit";
import { PortfolioAssistantService } from "shared/services/portfolio_assistant/PortfolioAssistantService";

export const fetchSessions = createAsyncThunk(
  "[PORTFOLIO_ASSISTANT] Fetch sessions",
  async () => {
    const sessions = await PortfolioAssistantService.fetchSessions();
    return sessions;
  },
);

export const PortfolioAssistantThunks = {
  fetchSessions,
};
