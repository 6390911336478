import { useTranslation } from "react-i18next";

type Props = {
  request: Record<string, any>;
};

export const FlatFee: React.FC<Props> = ({ request }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="col-span-2 mt-8 border-b pb-2 text-xl font-semibold">
        {t("feeTemplateForm.flatFee.title")}
      </div>
      <div className="text-lg font-medium">{t("feeTemplateForm.applied")}</div>
      <div>{t(`feeTemplateForm.flatFee.${request["flatFee_applied"]}`)}</div>
      <div className="text-lg font-medium">{t("feeTemplateForm.amount")}</div>
      <div>{request["flatFee_amount"]}</div>
    </>
  );
};
