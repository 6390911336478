import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { colorsConfig } from "colors.config";
import { CurrencyFormat } from "shared/utils/currency";

import { ISODate } from "shared/utils/types";
import { useEffect, useState } from "react";
import { FeesService } from "shared/services/fees/FeesService";
import { PayoutsByDay } from "shared/models/fees/FeesModel";
import { BarChartIcon } from "shared/icons/BarChartIcon";
import { useTranslation } from "react-i18next";

interface Props {
  updatedAtFrom?: ISODate;
  updatedAtTo?: ISODate;
  height?: number;
}

export const PayoutsChart: React.FC<Props> = ({
  updatedAtFrom,
  updatedAtTo,
  height = 200,
}) => {
  const { t } = useTranslation();
  const [payouts, setPayouts] = useState<PayoutsByDay | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const payoutsByDay = await FeesService.getPayoutsByDay(
          updatedAtFrom,
          updatedAtTo,
        );
        const length = payoutsByDay.length;
        if (length < 6) {
          const lastDate =
            length > 0 ? new Date(payoutsByDay[length - 1].date) : new Date();
          for (let i = 0; i < 6 - length; i++) {
            const nextDay = new Date(lastDate);
            nextDay.setDate(nextDay.getDate() + i + 1);
            payoutsByDay.push({
              date: nextDay.toISOString().split("T")[0],
              accepted: 0,
              failed: 0,
              new: 0,
              pending: 0,
              successful: 0,
            });
          }
        }

        setPayouts(payoutsByDay);
      } catch {
        setPayouts(null);
      }
    })();
  }, [updatedAtFrom, updatedAtTo]);

  const placeholderStyle = { height: `${height}px` };

  const tooltipContent = (tooltipProps: any) => {
    return (
      <div className="space-y-2 rounded-md bg-sb-gray-100 px-2.5 py-1.5 text-base">
        {tooltipProps.payload.map((item: any) => {
          return (
            <div
              key={item?.dataKey + "_tooltip"}
              className="flex w-full items-center justify-between gap-8"
              style={{ color: item?.color }}
            >
              <div className="font-semibold">
                {t(`payoutsChart.status.${item?.dataKey}`)}
              </div>
              <div className="text-black">
                {CurrencyFormat.format(item?.value || "0")}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const maxBarSize = 60;

  return (
    <div
      className="relative w-full py-4 pr-4"
      style={{ height: height || 300 }}
    >
      <div className="absolute bottom-0 left-0 right-0 top-0">
        {payouts?.length ? (
          <ResponsiveContainer width={"100%"} height={height} minWidth={10}>
            <BarChart data={payouts}>
              <Bar
                dataKey="successful"
                stackId="a"
                fill={colorsConfig["sb-green"][600]}
                stroke={colorsConfig["sb-green"][600]}
                isAnimationActive={false}
                maxBarSize={maxBarSize}
              />
              <Bar
                dataKey="pending"
                stackId="a"
                fill={colorsConfig["olive"][400]}
                stroke={colorsConfig["olive"][400]}
                maxBarSize={maxBarSize}
              />
              <Bar
                dataKey="accepted"
                stackId="a"
                fill={colorsConfig["sb-blue"][400]}
                stroke={colorsConfig["sb-blue"][400]}
                maxBarSize={maxBarSize}
              />
              <Bar
                dataKey="new"
                stackId="a"
                fill={colorsConfig["sb-violet"][400]}
                stroke={colorsConfig["sb-violet"][400]}
                maxBarSize={maxBarSize}
              />
              <XAxis
                tickMargin={5}
                dataKey="date"
                tickLine={false}
                fontSize={12}
                strokeWidth={0.5}
              />
              <YAxis
                padding={{ top: 20, bottom: 0 }}
                type="number"
                domain={["auto", "auto"]}
                allowDataOverflow={true}
                tick={{ fontSize: 12, fill: "#000" }}
                tickFormatter={(value) => CurrencyFormat.format(value)}
                tickLine={false}
                strokeWidth={0.5}
              />
              <Legend
                formatter={(value) => (
                  <span className="text-black">
                    {t(`payoutsChart.status.${value}`)}
                  </span>
                )}
              />
              <Tooltip
                content={tooltipContent}
                cursor={{ strokeDasharray: "3 3", fill: "transparent" }}
              />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <div
            className="grid h-full w-full animate-pulse place-items-center rounded-xl bg-sb-gray-100"
            style={placeholderStyle}
          >
            <BarChartIcon className="h-24 w-24 fill-sb-gray-300 stroke-sb-gray-300" />
          </div>
        )}
      </div>
    </div>
  );
};
