export const MAX_AGE = 120;
export const LEGAL_AGE = 18;

export const API_EXCEPTIONS = {
  NOT_FOUND: 404,
  FORBIDDEN: 403,
  UNAUTHORIZED: 401,
  MAINTENANCE: 503,
  OUT_OF_SERVICE: 502,
};

interface NetValuesOptions {
  label: string;
  value: string;
  min: number;
  max: number;
  defaultDeposit: number;
}

export const NET_VALUES: NetValuesOptions[] = [
  {
    label: "kyc.netValues.one",
    value: "20000",
    min: 0,
    max: 20000,
    defaultDeposit: 50,
  },
  {
    label: "kyc.netValues.two",
    value: "20000 - 49999",
    min: 20000,
    max: 49999,
    defaultDeposit: 100,
  },
  {
    label: "kyc.netValues.three",
    value: "50000 - 99999",
    min: 50000,
    max: 99999,
    defaultDeposit: 2000,
  },
  {
    label: "kyc.netValues.four",
    value: "100000 - 499999",
    min: 100000,
    max: 499999,
    defaultDeposit: 10000,
  },
  {
    label: "kyc.netValues.five",
    value: "500000 - 1000000",
    min: 500000,
    max: 1000000,
    defaultDeposit: 25000,
  },
  {
    label: "kyc.netValues.six",
    value: "1000000 - 10000000",
    min: 1000000,
    max: 10000000,
    defaultDeposit: 50000,
  },
];
