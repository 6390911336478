import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { Database, getDatabase } from "firebase/database";
import { Environment } from "./types";
import { getRemoteConfig, RemoteConfig } from "firebase/remote-config";

const firebaseConfig = {
  apiKey: "AIzaSyBBOh8Iw_spAg7-nuARdMqTkVsLNEeM-xE",
  authDomain: "app.streetbeat.com",
  databaseURL: "https://street-beat-app-default-rtdb.firebaseio.com",
  projectId: "street-beat-app",
  storageBucket: "street-beat-app.appspot.com",
  messagingSenderId: "1017064452947",
  appId: "1:1017064452947:web:cc8f79f612ce6a505d27a6",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const remoteConfig: RemoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

const environment = async (): Promise<Environment> => {
  await auth.authStateReady();
  if (auth.currentUser && auth.currentUser.email) {
    if (auth.currentUser.email.endsWith("+staging@streetbeat.com"))
      return "staging";
    if (auth.currentUser.email.endsWith("+test@streetbeat.com"))
      return "staging";
  }
  return "production";
};

const database = (): Database => {
  return getDatabase(app);
};

export {auth, environment, database,  };
