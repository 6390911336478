import { t } from "i18next";

export const NetLiquidationFixedForm: React.FC = () => {
  return (
    <>
      <label>{t("feeTemplateForm.percentage")}</label>
      <input
        type="number"
        name="netLiquidation_percentage"
        placeholder={t("feeTemplateForm.netLiquidation.required")}
        required
      />
    </>
  );
};
