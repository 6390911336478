import RestService from "shared/api/RestService";
import { AIAssistantSession } from "shared/models/assistant/AIAssistantModel";
import { Portfolio } from "shared/models/portfolio/PortfolioModel";
import { FaApiRoutes } from "shared/utils/routes";

export type FeedbackType = "positive" | "negative";

type Feedback = {
  type: FeedbackType;
  explanation?: string;
  message_id: string;
};

export const PortfolioAssistantService = {
  async fetchSessions(): Promise<AIAssistantSession[]> {
    const sessions = await RestService.get<AIAssistantSession[]>(
      FaApiRoutes.PortfolioAssistant.Sessions.Path,
    );
    return sessions;
  },

  async fetchPortfolios(sessionId: string): Promise<Portfolio[]> {
    const portfolios = await RestService.get<Portfolio[]>(
      FaApiRoutes.PortfolioAssistant.Sessions.Details(sessionId).Portfolios,
    );
    return portfolios;
  },

  async createSession(): Promise<AIAssistantSession> {
    const session = await RestService.post<AIAssistantSession>(
      FaApiRoutes.PortfolioAssistant.Sessions.Path,
    );
    return session;
  },

  async sendFeedback(sessionId: string, feedback: Feedback): Promise<void> {
    await RestService.post(
      FaApiRoutes.PortfolioAssistant.Sessions.Details(sessionId).Feedbacks,
      { ...feedback },
    );
  },
};
