import { selectClientById } from "shared/store/clients/ClientsReducer";
import { auth } from "shared/utils/firebase";
import { useAccount } from "./useAccount";
import { useSelector } from "./useDispatch";

type CardType =
  | "kyc"
  | "finish_kyc"
  | "onfido"
  | "action_required"
  | "risk"
  | "review"
  | "invalid_payment_card"
  | "first_transfer_pending"
  | "invalid_subscription"
  | "frozen"
  | "blocked"
  | "no_subscription_plan"
  | "connection_with_brokerage_expired"
  | "allow_notifications"
  | "promotion_ended"
  | "no_investment_yet";

interface CallToAction {
  cardType?: CardType;
}

export default function useCallToAction(clientId?: string): CallToAction {
  const { account: myAccount } = useAccount();
  const client = useSelector(selectClientById(clientId));
  const summary = clientId ? client?.summary : myAccount?.client.summary;

  const isKycRequired = summary?.kyc_status === "NOT_STARTED";
  const isOnfidoRequired =
    summary?.alpaca_details?.brokerage_account?.status === "ONBOARDING";
  const isAccountStatusActionRequired =
    summary?.alpaca_details?.brokerage_account?.status === "ACTION_REQUIRED";

  const generateCardType = (): CardType | undefined => {
    if (isKycRequired) return "kyc";
    if (isOnfidoRequired) return "onfido";
    if (isAccountStatusActionRequired) return "action_required";
    return undefined;
  };

  return {
    cardType: generateCardType(),
  };
}
