import { SerializedError, createSelector, createSlice } from "@reduxjs/toolkit";
import { AssetWrapper } from "shared/models/asset/AssetModel";
import { Loading } from "shared/types/enums";
import { RootState } from "../store";
import { fetchAssets } from "./AssetsThunks";

interface AssetsState {
  assets: AssetWrapper[] | null | undefined;
  loading: Loading;
  error?: SerializedError;
}

const initialState: AssetsState = {
  assets: undefined,
  loading: Loading.Idle,
};

const assetsSlice = createSlice({
  name: "[ASSETS]",
  initialState,
  reducers: {
    reset(state) {
      state.assets = initialState.assets;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAssets.rejected, (state, action) => {
      state.error = action.error;
      state.loading = Loading.Finished;
      return state;
    });
    builder.addCase(fetchAssets.pending, (state) => {
      state.loading = Loading.InProgress;
      return state;
    });
    builder.addCase(fetchAssets.fulfilled, (state, action) => {
      state.assets = action.payload;
      state.loading = Loading.Finished;
      delete state.error;
      return state;
    });
  },
});

export const selectAssets = () =>
  createSelector(
    (state: RootState) => state.assets,
    ({ assets, loading, error }) => ({
      assets: assets,
      loading: loading,
      error,
    }),
  );

const BrokerageAccountActions = { ...assetsSlice.actions };

export default assetsSlice.reducer;
