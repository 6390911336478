import RestService from "shared/api/RestService";
import { TradingPosition } from "shared/models/trading/TradingPositionmodel";
import { FaApiRoutes } from "shared/utils/routes";

export const PositionsService = {
  async fetch(clientId: string): Promise<TradingPosition[]> {
    return await RestService.get<TradingPosition[]>(
      FaApiRoutes.Advisor.Clients.Details(clientId).Positions.Path,
    );
  },

  async closePosition(symbol: string, clientId: string) {
    return await RestService.delete(
      FaApiRoutes.Advisor.Clients.Details(clientId).Positions.Details(symbol)
        .Path,
    );
  },

  async fetchOne(
    symbol: string,
    clientId: string,
  ): Promise<TradingPosition | undefined> {
    return await RestService.get<TradingPosition>(
      FaApiRoutes.Advisor.Clients.Details(clientId).Positions.Details(symbol)
        .Path,
    );
  },
};
