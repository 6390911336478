import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Labels } from "shared/components/hoc/Labels";
import CheckIcon from "shared/icons/CheckIcon";

interface Props<T> extends PropsWithChildren {
  value: T;
  label: string;
  labelClass?: string;
  onChange(value: T): void;
}

export function TermsCheckbox<T>({
  children,
  value,
  label,
  labelClass,
  onChange,
}: Props<T>) {
  const { t } = useTranslation();

  return (
    <div onClick={() => onChange(value)} className="flex">
      {!!value ? (
        <div className="flex max-h-[24px] min-h-[24px] min-w-[24px] items-center justify-center rounded-[3px] bg-sb-green-600">
          <CheckIcon className="h-4 w-4 text-white" />
        </div>
      ) : (
        <div className="max-h-[24px] min-h-[24px] min-w-[24px] rounded-[3px] border-2 border-black" />
      )}
      <div className="ml-3">
        <Labels.B1 className={`text-lg ${labelClass}`}>{t(label)}</Labels.B1>
        {children}
      </div>
    </div>
  );
}
