import { useTranslation } from "react-i18next";
import { PnlPctAppliedType, PnlPctTypeType } from "../types";

export const usePnlPct = () => {
  const { t } = useTranslation();

  const appliedChoices: { label: string; value: PnlPctAppliedType }[] = [
    {
      label: t("feeTemplateForm.pnlPct.quarterly"),
      value: "quarterly",
    },
    {
      label: t("feeTemplateForm.pnlPct.yearly"),
      value: "yearly",
    },
  ];

  const typeChoices: { label: string; value: PnlPctTypeType }[] = [
    {
      label: t("feeTemplateForm.pnlPct.benchmark"),
      value: "benchmark",
    },
    {
      label: t("feeTemplateForm.pnlPct.fixed"),
      value: "fixed",
    },
  ];

  return { appliedChoices, typeChoices };
};
