import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { AvatarImage } from "shared/components/common/AvatarImage";
import { DataTable } from "shared/components/common/datatable/DataTable";
import { Client } from "shared/models/client/ClientModel";
import { Proposal } from "shared/models/proposals/ProposalsModel";
import { Loading } from "shared/types/enums";
import { formatDateTime } from "shared/utils/date";
import { capitalize } from "shared/utils/utils";
import { useSelector, useDispatch } from "shared/hooks/useDispatch";
import { selectClients } from "shared/store/clients/ClientsReducer";
import { TrashIcon } from "shared/icons/TrashIcon";
import { ProposalsService } from "shared/services/proposals/ProposalsService";
import { ProposalsThunks } from "shared/store/proposals/ProposalsThunks";
import { toast } from "react-toastify";
import { useCallback } from "react";

type Props = {
  proposals: Proposal[];
  loading: Loading;
};

export const ProposalsTable: React.FC<Props> = ({ proposals, loading }) => {
  const navigate = useNavigate();
  const { clients: faClients } = useSelector(selectClients);
  const dispatch = useDispatch();

  const onProposalRemoved = useCallback((proposalId: string) => {
    const removeProposal = async () => {
      try {
        await ProposalsService.deleteProposal(proposalId);
        toast.success(t("proposals.proposalDeletedSuccessfully"));
        await dispatch(ProposalsThunks.fetchMany()).unwrap();
      } catch (error) {
        throw error;
      }
    }
    removeProposal();
  }, [dispatch]);

  return (
    <DataTable
      loading={!proposals.length && loading === Loading.InProgress}
      columns={["portfolio", "client_name", "created_at", "status"]}
      headerLabels={{
        portfolio: t("global.portfolio"),
        client_name: t("proposals.clientName"),
        created_at: t("proposals.createdAt"),
        status: t("proposals.status"),
      }}
      rowClass={(item: Proposal) => {
        if (!item.strategy_type) {
          return "!bg-sb-cyan-100 !cursor-auto animate-pulse";
        }
        if (item.status === "deleted") {
          return "!bg-sb-red-100 !cursor-auto animate-pulse";
        }
        return "";
      }}
      onRowClicked={(item: Proposal) => {
        if (item.strategy_type) {
          navigate(`/proposals/${item.proposal_id}`);
        }
      }}
      data={proposals}
      sortableValue={(key: string, entry: any) => {
        if (key === "client_name") {
          return (
            faClients.find(
              (c: Client) => c.user_id + "" === entry["client_id"] + "",
            )?.summary.client_name || "Unknown"
          );
        }
        if (key === "portfolio") {
          return entry["strategy_title"];
        }
        if (key === "created_at") {
          return entry["created_at"];
        }
        if (key === "status") {
          const strategyType = entry["strategy_type"];
          if (!strategyType) return -1;
          if (entry["status"] === "draft") return 0;
          if (entry["status"] === "sent") return 1;
          if (entry["status"] === "canceled") return 2;
          if (entry["status"] === "rejected") return 3;
          if (entry["status"] === "deleted") return 4;
          if (entry["status"] === "accepted") return 5;
        }
        return undefined;
      }}
      cellFormatter={(key: string, value: string, entry: any) => {
        if (key === "created_at") {
          return formatDateTime(value);
        }
        if (key === "status") {
          const strategyType = entry["strategy_type"];
          if (!strategyType) return t("proposals.generating");
          if (value === "sent") return t("proposals.waitingForApproval");
          let label = capitalize(value)
          if (value === "draft") {
            label = t("proposals.draft");
          }
          return (
            <div>
              {label}
              {
                (value === "draft" || value === "canceled" || value === "rejected") && (
                  <div
                    className="cursor-pointer ml-2 py-1 px-2 hover:bg-sb-gray-200 rounded-full"
                    onClick={(event) => {
                      onProposalRemoved(entry["proposal_id"])
                      // block event from propagating to parent row click
                      event?.stopPropagation();
                    }}
                  >
                    <TrashIcon className="aspect-square w-3.5 cursor-pointer text-sb-red-700" />
                  </div>
                )
              }
            </div>
          )
        }
        if (key === "portfolio") {
          return (
            <div className="flex flex-row items-center">
              <AvatarImage
                className="mr-3"
                imageSrc={entry.strategy_cover?.uri}
                width={30}
                height={30}
              />
              <div>
                <div className="font-bold">{entry.strategy_title}</div>
                <div className="text-sm text-gray-400">
                  {entry.strategy_symbol}
                </div>
              </div>
            </div>
          );
        }

        if (key === "client_name") {
          return (
            faClients.find(
              (c: Client) => c.user_id + "" === entry["client_id"] + "",
            )?.summary.client_name || "Unknown"
          );
        }

        return value;
      }}
    />
  );
};
