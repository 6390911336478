import React, { InputHTMLAttributes } from "react";
import CurrencyInput from "react-currency-input-field";
import { useTranslation } from "react-i18next";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  className?: string;
  error?: string;
}

export const PercentageInput: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { name, label, className, error } = props;

  return (
    <div className="relative">
      <CurrencyInput
        id={name}
        suffix={"%"}
        decimalsLimit={2}
        name={name}
        className={`peer mb-4 block w-full appearance-none rounded-xl border-0 bg-sb-gray-100 px-2.5 pb-2.5 pt-5 text-base text-gray-900 focus:border-sb-green-700 focus:outline-none focus:ring-0 ${className}`}
        placeholder=""
      />
      <label
        htmlFor={name}
        className={`absolute left-2.5 top-4  z-10 origin-[0] -translate-y-4 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-sb-green-700`}
      >
        {label && t(label)}
      </label>
      {error && <p className="mb-8 text-sm text-sb-red-600">{error}</p>}
    </div>
  );
};
