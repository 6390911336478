import { useTranslation } from "react-i18next";
import { Labels } from "shared/components/hoc/Labels";
import { useAccount } from "shared/hooks/useAccount";
import { BrokerageAccountIndeterminate } from "shared/models/brokerage_account/BrokerageAccountEventModel";
import { UpdateAddressForm } from "./UpdateAddressForm";

export const AddressVerificationMessage = () => {
  const { account } = useAccount();
  const { contact } =
    account?.client?.summary?.alpaca_details?.brokerage_account || {};
  const { t } = useTranslation();

  if (!contact) {
    return null;
  }

  const key: keyof BrokerageAccountIndeterminate = "ADDRESS_VERIFICATION";
  const streetAddress = contact.street_address.join(", ").trim();
  const postalCode = contact.postal_code ? contact.postal_code.trim() : "";
  const city = contact.city.trim();

  const address = `${streetAddress}, ${postalCode} ${city}`;

  const message = t(`errors.kyc.${key}`, {
    address,
    interpolation: { escapeValue: false },
  });

  return (
    <div>
      <Labels.B1>{message}</Labels.B1>
      <UpdateAddressForm />
    </div>
  );
};
