import { useEffect } from "react";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import { selectSupportLevelPrices } from "shared/store/assets/asset_stats/AssetStatsReducer";
import { AssetStatsThunks } from "shared/store/assets/asset_stats/AssetStatsThunks";

const useSupportLevelPrices = (symbol: string): number[] | undefined => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AssetStatsThunks.fetchSupportLevelPrices(symbol));
  }, [dispatch, symbol]);

  return useSelector(selectSupportLevelPrices);
};

export default useSupportLevelPrices;
