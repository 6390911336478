import { useTranslation } from "react-i18next";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import { useAccount } from "shared/hooks/useAccount";

export const ActionRequiredRejectedView = () => {
  const { t } = useTranslation();
  const { account } = useAccount();
  const brokerageAccount =
    account?.client?.summary?.alpaca_details?.brokerage_account;
  const { identity, contact } = brokerageAccount || {};

  return (
    <Panel>
      <div className="space-y-4">
        <div>
          <div className="font-semibold">{t("trader.status")}</div>
          <div className="text-lg">{t("trader.rejected")}</div>
        </div>
        <div>
          <div className="font-semibold">{t("trader.name")}</div>
          <div className="text-lg">
            {`${identity?.given_name} ${identity?.family_name}`}
          </div>
        </div>
        <div>
          <div className="font-semibold">{t("trader.email")}</div>
          <div className="text-lg">{contact?.email_address}</div>
        </div>
        <Labels.H3>{t("trader.result.REJECTED")}</Labels.H3>
      </div>
    </Panel>
  );
};
