import { Spinner } from "shared/components/common/Spinner";

type ActionButtonProps = {
  onClick: () => void;
  title: string;
  desc: string;
  loading?: boolean;
};

export const ActionButton: React.FC<ActionButtonProps> = ({
  onClick,
  title,
  desc,
  loading,
}) => {
  return (
    <div
      className={
        "min-w-sm relative w-full rounded-3xl border border-black p-4 duration-150 " +
        (loading ? "" : "cursor-pointer hover:bg-black hover:text-white")
      }
      onClick={() => !loading && onClick()}
    >
      {loading && (
        <div className="absolute -bottom-1 -left-1 -right-1 -top-1 z-50 bg-white/80">
          <div className="grid h-full place-items-center">
            <Spinner className="aspect-square w-6" fill="fill-black" />
          </div>
        </div>
      )}
      <div className="font-semibold">{title}</div>
      <div className="text-base">{desc}</div>
    </div>
  );
};
