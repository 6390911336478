import React from "react";
import { StreetbeatHollowIcon } from "shared/icons/StreetbeatHollowIcon";

interface Props {
  imageSrc?: string;
  width: number;
  height: number;
  className?: string;
}

export const AvatarImage: React.FC<Props> = ({
  imageSrc,
  width,
  height,
  className,
}) => {
  const style = { height, width };
  return (
    <div
      className={`grid shrink-0 place-items-center rounded-full bg-sb-gray-200 ${className}`}
      style={style}
    >
      {imageSrc ? (
        <img
          src={imageSrc}
          className="h-full w-full shrink-0 rounded-full"
          alt="alt"
          width={width}
          height={height}
        />
      ) : (
        <StreetbeatHollowIcon className="aspect-square w-full p-1.5 text-sb-gray-600" />
      )}
    </div>
  );
};
