import { createSelector, createSlice, SerializedError } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { AccountModel } from "shared/models/account/AccountModel";
import { fetch, fetchPendingTasks } from "shared/store/account/AccountThunks";
import { PendingTasks } from "shared/models/pending_tasks/PendingTasksModel";
import { Loading } from "shared/types/enums";

interface AccountState {
  data: AccountModel | null | undefined;
  accountLoading: Loading;
  pendingTasks: {
    tasks: PendingTasks[];
    loading: Loading;
  };
  error?: SerializedError;
}

const initialState: AccountState = {
  data: undefined,
  accountLoading: Loading.Idle,
  pendingTasks: {
    tasks: [],
    loading: Loading.Idle,
  },
};

const AccountStateSlice = createSlice({
  name: "[ACCOUNT]",
  initialState,
  reducers: {
    reset(state) {
      state.data = initialState.data;
      state.pendingTasks = initialState.pendingTasks;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.accountLoading = Loading.InProgress;
      return state;
    });
    builder.addCase(fetch.rejected, (state, action) => {
      state.error = action.error;
      return state;
    });

    builder.addCase(fetch.fulfilled, (state, action) => {
      state.data = action.payload;
      state.accountLoading = Loading.Finished;
      delete state.error;
      return state;
    });
    builder.addCase(fetchPendingTasks.pending, (state) => {
      state.pendingTasks = {
        ...state.pendingTasks,
        loading: Loading.InProgress,
      };
    });
    builder.addCase(fetchPendingTasks.rejected, (state, action) => {
      state.error = action.error;
      state.pendingTasks = {
        loading: Loading.Finished,
        tasks: [],
      };
      return state;
    });
    builder.addCase(fetchPendingTasks.fulfilled, (state, action) => {
      state.pendingTasks = {
        tasks: action.payload,
        loading: Loading.Finished,
      };
      delete state.error;
      return state;
    });
  },
});

export const selectAccount = () =>
  createSelector(
    (state: RootState) => state.account,
    ({ data, accountLoading, error }) => ({
      account: data,
      loading: accountLoading,
      error,
    }),
  );

export const selectPendingTasks = () =>
  createSelector(
    (state: RootState) => state.account,
    ({ pendingTasks, error }) => ({
      tasks: pendingTasks.tasks,
      loading: pendingTasks.loading,
      error,
    }),
  );

const AccountActions = { ...AccountStateSlice.actions };

export default AccountStateSlice.reducer;
