import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  label: string;
  value: string;
  numValue: number;
}

export const BacktestingMetric: React.FC<Props> = ({
  numValue,
  label,
  value,
}) => {
  const { t } = useTranslation();

  const valueClassColor =
    numValue >= 0 ? "text-sb-green-700" : "text-sb-red-700";

  return (
    <div>
      <div className="text-xsm uppercase text-sb-gray-500">{t(label)}</div>
      <div className={`text-lg font-semibold ${valueClassColor}`}>{value}</div>
    </div>
  );
};
