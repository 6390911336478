import { colorsConfig } from "colors.config";
import {
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import useRealtimeTicker from "shared/hooks/useRealtimeTicker";

type Props = {
  height: number;
  symbol: string;
};

export default function TrendsChart({ symbol, height }: Props) {
  const { trends } = useRealtimeTicker(symbol);

  const positive =
    trends && trends.length > 0 && trends[0] <= trends[trends.length - 1];
  let chartPoints = [];
  if (trends) {
    for (let i in trends) {
      chartPoints.push({
        index: i,
        value: trends[i],
      });
    }
  }

  return (
    <div className="relative w-full" style={{ height: height }}>
      <div className="absolute bottom-0 left-0 right-0 top-0">
        {chartPoints && chartPoints.length > 1 ? (
          <ResponsiveContainer width="100%" height={height} minWidth={10}>
            <LineChart
              height={600}
              data={chartPoints}
              margin={{ top: 5, right: 20, bottom: 5, left: 10 }}
            >
              <ReferenceLine
                y={chartPoints[0].value}
                stroke="#C7C9CC"
                strokeDasharray="3 3"
              />
              <YAxis type="number" domain={["dataMin", "dataMax"]} hide />
              <XAxis
                dataKey={"index"}
                type="number"
                domain={[0, Math.max(33, trends.length)]}
                hide
              />
              <Line
                dataKey={"value"}
                dot={false}
                stroke={
                  positive
                    ? colorsConfig["sb-green"]["500"]
                    : colorsConfig["sb-red"]["500"]
                }
                strokeWidth={1}
                fillOpacity={1}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <ResponsiveContainer width="100%" height={height} minWidth={10}>
            <LineChart
              height={600}
              data={[]}
              margin={{ top: 5, right: 20, bottom: 5, left: 10 }}
            >
              <ReferenceLine y={0} stroke="#C7C9CC" strokeDasharray="3 3" />
              <YAxis type="number" domain={[-1, 1]} hide />
              <Line
                dataKey={(v) => v}
                dot={false}
                stroke={
                  positive
                    ? colorsConfig["sb-green"]["500"]
                    : colorsConfig["sb-red"]["500"]
                }
                strokeWidth={1}
                fillOpacity={1}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
}
