import { useSelector } from "react-redux/es/hooks/useSelector";
import { selectAssetChartData } from "shared/store/assets/asset_chart/AssetChartReducer";
import { MarketInfoThunks } from "shared/store/market_info/MarketInfoThunks";
import { ChartRange } from "shared/utils/types";
import { useDispatch } from "./useDispatch";
import useMarketInfo from "./useMarketInfo";
import useRealtimeTicker from "./useRealtimeTicker";

export const useAssetPrice = (assetId: string, range: ChartRange) => {
  const dispatch = useDispatch();
  const { marketInfo } = useMarketInfo();
  const chartData = useSelector(
    selectAssetChartData(assetId ? assetId : "", range),
  );

  const { latestPrice, previousClose, askPrice, bidPrice } = useRealtimeTicker(
    assetId ? assetId : "",
  );

  if (!marketInfo) {
    dispatch(MarketInfoThunks.fetchMarketInfo());
  }

  const firstPoint =
    chartData && chartData.length > 1 ? chartData[0].latestPrice || 0 : 0;
  const pnl =
    range === "date" ? latestPrice - previousClose : latestPrice - firstPoint;
  const pnlPct = firstPoint > 0 ? (pnl * 100) / firstPoint : 0;
  const positive = pnl >= 0;

  return {
    latestPrice,
    previousClose,
    askPrice,
    bidPrice,
    pnl,
    pnlPct,
    positive,
  };
};
