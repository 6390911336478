import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DataTable } from "shared/components/common/datatable/DataTable";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import { selectClientOpenFees } from "shared/store/client_details/ClientDetailsReducer";
import { ClientDetailsThunks } from "shared/store/client_details/ClientDetailsThunks";
import { Loading } from "shared/types/enums";
import { CurrencyFormat } from "shared/utils/currency";
import { formatDateTime } from "shared/utils/date";

type Props = {
  clientId?: string;
};

export const ClientOpenFees: React.FC<Props> = ({ clientId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { fees, loading } = useSelector(selectClientOpenFees(clientId));

  useEffect(() => {
    if (clientId) {
      dispatch(ClientDetailsThunks.fetchOpenFees({ clientId }));
    }
  }, [dispatch, clientId]);

  return (
    <Panel>
      <Labels.H3>{t("fees.openFees")}</Labels.H3>
      <div className="mt-4">
        {!fees.length && loading === Loading.Finished ? (
          <Labels.G1>{t("fees.noOpenFees")}</Labels.G1>
        ) : (
          <DataTable
            data={fees}
            loading={loading === Loading.InProgress}
            columns={[
              "amount",
              "status",
              "scheduled_charge_date",
              "updated_at",
            ]}
            headerLabels={{
              amount: t("fees.headers.amount"),
              status: t("fees.headers.status"),
              scheduled_charge_date: t("fees.headers.scheduledChargeDate"),
              updated_at: t("fees.headers.updatedAt"),
            }}
            cellFormatter={(key: string, value: any) => {
              if (["scheduled_charge_date", "updated_at"].includes(key)) {
                return formatDateTime(value);
              }
              if (key === "amount") {
                return CurrencyFormat.format(value);
              }
              if (key === "status") {
                return t(`fees.statuses.${value}`);
              }
              return value;
            }}
          />
        )}
      </div>
    </Panel>
  );
};
