type Props = {
  className?: string;
};

export const PlusCircle: React.FC<Props> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6873 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6873 15.9 21.212C14.6833 21.7373 13.3833 22 12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6873 5.825 19.975 4.925 19.075C4.025 18.175 3.31233 17.1167 2.787 15.9C2.26233 14.6833 2 13.3833 2 12C2 10.6167 2.26233 9.31667 2.787 8.1C3.31233 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26267 10.6167 2 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31267 18.175 4.025 19.075 4.925C19.975 5.825 20.6873 6.88333 21.212 8.1C21.7373 9.31667 22 10.6167 22 12ZM10.9286 10.9286L10.9286 7.71429C10.9286 7.31987 11.2484 7 11.6429 7L12.3571 7C12.7516 7 13.0714 7.31987 13.0714 7.71429L13.0714 10.9286L16.2857 10.9286C16.6801 10.9286 17 11.2484 17 11.6429L17 12.3571C17 12.7516 16.6801 13.0714 16.2857 13.0714L13.0714 13.0714L13.0714 16.2857C13.0714 16.6801 12.7516 17 12.3571 17L11.6429 17C11.2484 17 10.9286 16.6801 10.9286 16.2857L10.9286 13.0714L7.71429 13.0714C7.31987 13.0714 7 12.7516 7 12.3571L7 11.6429C7 11.2484 7.31987 10.9286 7.71429 10.9286L10.9286 10.9286Z"
        fill="currentColor"
      />
    </svg>
  );
};
