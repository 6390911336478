import { useState } from "react";
import { CompanyLogo } from "shared/components/common/CompanyLogo";
import { ArrowUp } from "shared/icons/ArrowUp";
import { TrashIcon } from "shared/icons/TrashIcon";
import { PortfolioAssetAllocation } from "shared/models/portfolio/PortfolioModel";

type Props = {
  item: PortfolioAssetAllocation;
  portfolioItem?: PortfolioAssetAllocation;
  onAllocationChange: (symbol: string, allocation: number) => void;
  onRemove: (symbol: string) => void;
};

export const Allocation: React.FC<Props> = ({
  item,
  portfolioItem,
  onAllocationChange,
  onRemove,
}) => {
  const [allocation, setAllocation] = useState(parseFloat(item.weight) * 100);

  return (
    <div className="flex items-center justify-between rounded-lg bg-white px-8 py-4 text-lg">
      <div className="flex items-center gap-2">
        <div
          onClick={() => onRemove(item.symbol)}
          className="mr-4 cursor-pointer"
        >
          <TrashIcon className="h-5 w-5 text-sb-red-600" />
        </div>
        <CompanyLogo symbol={item.ticker} className="h-10 w-10 rounded-full" />
        <div>
          <div className="text-base font-semibold">{item.ticker}</div>
          <div>{item.name}</div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <div className="text-2xl font-medium">
          {Math.trunc(parseFloat(portfolioItem?.weight || "0") * 100)}%
        </div>
        <ArrowUp className="h-6 w-6 rotate-90 text-sb-blue-600" />
        <div className="text-2xl">
          <input
            type="number"
            className="rounded-lg border border-sb-gray-500 text-right"
            min={0}
            max={100}
            step={1}
            value={Math.trunc(allocation)}
            onChange={(e) => {
              setAllocation(parseFloat(e.target.value));
              onAllocationChange(item.symbol, parseFloat(e.target.value) / 100);
            }}
          />
          %
        </div>
      </div>
    </div>
  );
};
