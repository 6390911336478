import { PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import { Panel } from "shared/components/hoc/Panels";
import { AssetClass } from "./components/AssetClass";
import { Geography } from "./components/Geography";
import { InvestmentTimeline } from "./components/InvestmentTimeline";
import { InvestmentTypes } from "./components/InvestmentTypes";
import { PortfolioGoal } from "./components/PortfolioGoal";
import { Strategy } from "./components/Strategy";

type Props = PropsWithChildren & {
  onChange?: (specifics: Record<string, string[]>) => void;
};

export const Specifics: React.FC<Props> = ({ onChange, children }) => {
  const { t } = useTranslation();
  const [specifics, setSpecifics] = useState<Record<string, string[]>>({});

  const onAnswerChanged = (field: string, answers: string[]) => {
    let result = { ...specifics };
    result[field] = answers;
    setSpecifics(result);
    onChange?.(result);
  };

  return (
    <Panel>
      <div className="flex items-center gap-4">
        <div className="aspect-square h-10 shrink-0 rounded-full bg-sb-gray-200" />
        <div className="text-xl font-medium">{t("proposals.specifics")}</div>
      </div>
      <div className="flex justify-center">
        <div className="max-w-3xl space-y-12 divide-y divide-sb-gray-200">
          <PortfolioGoal
            onChange={(answers) => onAnswerChanged("portfolioGoal", answers)}
          />
          <AssetClass
            onChange={(answers) => onAnswerChanged("assetClass", answers)}
          />
          <Geography
            onChange={(answers) => onAnswerChanged("geography", answers)}
          />
          <InvestmentTimeline
            onChange={(answers) =>
              onAnswerChanged("investmentTimeline", answers)
            }
          />
          <Strategy
            onChange={(answers) => onAnswerChanged("strategy", answers)}
          />
          <InvestmentTypes
            onChange={(answers) => onAnswerChanged("investmentType", answers)}
          />
          <div>{children}</div>
        </div>
      </div>
    </Panel>
  );
};
