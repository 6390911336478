import { createSelector, createSlice } from "@reduxjs/toolkit";
import { TradingOrder } from "shared/models/trading/TradingOrderModel";
import { RecentOrdersParams } from "shared/services/orders/OrdersService";
import { Loading } from "shared/types/enums";
import { RootState } from "../store";
import { fetchRecentOrders } from "./RecentOrdersThunks";

type RecentOrdersState = {
  orders: TradingOrder[];
  loading: Loading;
  filters: RecentOrdersParams;
};

const initialState: RecentOrdersState = {
  orders: [],
  loading: Loading.Idle,
  filters: {},
};

const recentOrdersSlice = createSlice({
  name: "[RECENT_ORDERS]",
  initialState: initialState,
  reducers: {
    reset: (state) => {
      state = initialState;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRecentOrders.pending, (state, args) => {
      state.filters = args.meta.arg || {};
      state.loading = Loading.InProgress;
      return state;
    });
    builder.addCase(fetchRecentOrders.rejected, (state) => {
      state.loading = Loading.Finished;
      return state;
    });
    builder.addCase(fetchRecentOrders.fulfilled, (state, action) => {
      state.loading = Loading.Finished;
      state.orders = action.payload;
      return state;
    });
  },
});

export const selectRecentOrders = () =>
  createSelector(
    (state: RootState) => state.recentOrders,
    ({ orders, loading, filters }) => ({
      orders,
      loading,
      filters,
    }),
  );

export default recentOrdersSlice.reducer;
