type Props = {
  className?: string;
};

export const AccountWidget: React.FC<Props> = ({ className }) => {
  return (
    <svg
      width="227"
      height="175"
      viewBox="0 0 227 175"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M65.5239 174.259L168.49 174.259C177.335 174.259 184.502 167.083 184.49 158.239L184.309 16.0089C184.298 7.16886 177.12 0.0131194 168.28 0.0293043L65.4946 0.217491C56.6695 0.233648 49.5239 7.39235 49.5239 16.2175L49.5239 158.259C49.5239 167.095 56.6874 174.259 65.5239 174.259Z"
        fill="#E0FFEB"
      />
      <circle cx="117.221" cy="49.4538" r="11.5153" fill="#111112" />
      <path
        d="M143.321 84.0201C143.321 75.1835 136.157 68.02 127.321 68.0201L107.118 68.0201C98.2816 68.0202 91.1182 75.1836 91.1182 84.0201V87.2265H143.321V84.0201Z"
        fill="#111112"
      />
      <path
        d="M14.8102 23.128C16.3723 21.5659 18.905 21.5659 20.4671 23.128L26.4581 29.119L30.8678 33.5287L32.449 35.1099C34.0111 36.672 34.0111 39.2047 32.449 40.7668L30.8678 42.3481L26.4581 46.7578L20.4671 52.7487C18.905 54.3108 16.3723 54.3108 14.8102 52.7487L8.81928 46.7578L4.40958 42.3481L2.82831 40.7668C1.26621 39.2047 1.26621 36.672 2.82831 35.1099L4.40958 33.5287L8.81928 29.119L14.8102 23.128Z"
        stroke="#111112"
        strokeWidth="2"
      />
      <path
        d="M71.8442 135.841C71.8442 124.656 80.911 115.589 92.0954 115.589H184.599V156.092H92.0954C80.911 156.092 71.8442 147.025 71.8442 135.841Z"
        fill="url(#paint0_linear_2211_8003)"
        stroke="#E0FFEB"
        strokeWidth="3"
      />
      <circle cx="92.1609" cy="135.841" r="6.18756" fill="#111112" />
      <circle cx="117.254" cy="135.841" r="6.18756" fill="#111112" />
      <circle cx="142.348" cy="135.841" r="6.18756" fill="#111112" />
      <path
        d="M184.598 116.621L206.778 116.621C217.393 116.621 225.998 125.226 225.998 135.841V135.841C225.998 146.455 217.393 155.06 206.778 155.06H184.598"
        stroke="#111112"
        strokeWidth="2"
      />
      <path
        d="M200.878 127.465L209.254 135.841L200.878 144.217"
        stroke="#111112"
        strokeWidth="2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2211_8003"
          x1="77.1296"
          y1="156.092"
          x2="100.817"
          y2="86.9049"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04B3B0" />
          <stop offset="0.567711" stopColor="#00CC4F" />
          <stop offset="1" stopColor="#46FE8D" />
        </linearGradient>
      </defs>
    </svg>
  );
};
