import { useTranslation } from "react-i18next";
import { ValueSelector } from "./ValueSelector";

type Props = {
  onChange?: (values: string[]) => void;
};

export const InvestmentTimeline: React.FC<Props> = ({ onChange }) => {
  const { t } = useTranslation();

  const preDefinedGoals = ["shortterm", "mediumterm", "longterm"];

  return (
    <ValueSelector
      title={t("proposals.investmentTimeline")}
      translationKey="investmentTimeline_answers"
      preDefinedValues={preDefinedGoals}
      onChange={onChange}
    />
  );
};
