import { ClientOrders } from "modules/client_orders/ClientOrders";
import { ClientPositions } from "modules/client_positions/ClientPositions";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import { selectClientById } from "shared/store/clients/ClientsReducer";
import { ClientsThunks } from "shared/store/clients/ClientsThunks";

type Props = {
  clientId?: string;
};

export const ClientHoldings: React.FC<Props> = ({ clientId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const client = useSelector(selectClientById(clientId));

  useEffect(() => {
    if (!client && clientId) {
      dispatch(ClientsThunks.fetchClientById(clientId));
    }
  }, [client, clientId, dispatch]);

  if (client && !client.summary.account_name) {
    return (
      <div className="text-xl font-semibold">
        {t("clientDetails.noAccount")}
      </div>
    );
  }

  return (
    <div>
      {clientId ? (
        <div className="space-y-8">
          <ClientPositions clientId={clientId} />
          <ClientOrders clientId={clientId} />
        </div>
      ) : (
        <div className="text-xl font-semibold">
          {t("clientDetails.noAccount")}
        </div>
      )}
    </div>
  );
};
