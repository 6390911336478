import CheckIcon from "shared/icons/CheckIcon";
import { EditIcon } from "shared/icons/EditIcon";
import { XMarkIcon } from "shared/icons/XMarkIcon";

type Props = {
  editing: boolean;
  setEditing: (editing: boolean) => void;
  onSaveClicked: () => void;
  error?: () => string | undefined;
  hideErrorMessage?: boolean;
  className?: string;
};

export const EditMenu: React.FC<Props> = ({
  editing,
  setEditing,
  onSaveClicked,
  error,
  className,
  hideErrorMessage,
}) => {
  const errorValue = error?.();

  return (
    <div className={className || "absolute -right-2 -top-2"}>
      {editing ? (
        <div className="flex items-center gap-4">
          {errorValue && !hideErrorMessage ? (
            <div className="text-sb-red-700">{errorValue}</div>
          ) : null}
          <div
            onClick={() => !errorValue && onSaveClicked()}
            className={errorValue ? "opacity-50" : "cursor-pointer"}
          >
            <CheckIcon
              className="aspect-square w-5 text-sb-green-700"
              strokeWidth={2}
            />
          </div>
          <div onClick={() => setEditing(false)} className="cursor-pointer">
            <XMarkIcon
              className="aspect-square w-5 text-sb-green-700"
              strokeWidth={2}
            />
          </div>
        </div>
      ) : (
        <div onClick={() => setEditing(true)} className="cursor-pointer">
          <EditIcon className="aspect-square w-5 text-sb-green-700" />
        </div>
      )}
    </div>
  );
};
