import { FeeTemplateForm } from "modules/fee_template_form/FeeTemplateForm";
import { BasePage } from "shared/components/common/BasePage";

export const AddFeeTemplatePage: React.FC = () => {
  return (
    <BasePage>
      <div>
        <FeeTemplateForm />
      </div>
    </BasePage>
  );
};
