import RestService from "shared/api/RestService";
import { FaApiRoutes } from "shared/utils/routes";

class CountriesService {
  async get(): Promise<string[]> {
    return RestService.get(FaApiRoutes.Alpaca.Countries);
  }
}

const CountriesServiceInstance = new CountriesService();
export default CountriesServiceInstance;
