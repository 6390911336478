import { useTranslation } from "react-i18next";

type Props = {
  title?: string;
  subtitle?: string;
};

export const KycHeader: React.FC<Props> = ({ title, subtitle }) => {
  const { t } = useTranslation();

  return (
    <div className="mb-9">
      <h1 className="mb-1 text-2xl font-bold">{title || t("kyc.title")}</h1>
      <h3 className="text-md mb-3 text-gray-400">
        {subtitle || t("kyc.subtitle")}
      </h3>
    </div>
  );
};
