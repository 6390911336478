import "./styles.css";

import { useTranslation } from "react-i18next";
import ChevronRight from "shared/icons/ChevronRight";
import { auth } from "shared/utils/firebase";

import { Menu } from "@headlessui/react";
import { OptionsMenu } from "shared/components/controls/OptionsMenu";
import { Person } from "shared/icons/Person";
import AuthService from "shared/services/auth/AuthService";
import { useDispatch } from "shared/hooks/useDispatch";
import { AppActions } from "shared/store/app/AppReducer";

export const UserMenu: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const signOutHandler = () => {
    dispatch(AppActions.clearStoreRequest());
    AuthService.signOut();
  };

  return (
    <OptionsMenu
      button={
        <div className="min-h-10 min-w-10 flex items-center gap-4 rounded-full bg-white py-2 pl-2 pr-4">
          {auth.currentUser?.photoURL ? (
            <img
              className="min-h-8 aspect-square h-8 rounded-full"
              src={auth.currentUser?.photoURL}
              alt="Avatar"
            />
          ) : (
            <div className="grid aspect-square h-8 place-items-center rounded-full bg-sb-gray-200">
              <Person className="aspect-square h-6 text-sb-gray-500" />
            </div>
          )}
          <div className="font-bold">
            {auth.currentUser?.displayName ||
              auth.currentUser?.email?.split("@")[0]}
          </div>

          <ChevronRight
            className="min-h-3 min-w-3 h-3 w-3 rotate-90 text-sb-green-600"
            strokeWidth={4}
          />
        </div>
      }
    >
      <div className="w-56 select-none">
        <div className="px-1 py-1">
          <Menu.Item>
            {({ active }) => (
              <button
                className={`${active ? "active" : ""} menu-item group`}
                onClick={signOutHandler}
              >
                {t("avatar.menu.signOut")}
              </button>
            )}
          </Menu.Item>
        </div>
      </div>
    </OptionsMenu>
  );
};
