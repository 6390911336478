import RestService from "shared/api/RestService";
import {
  TopClientPerformers,
  TopPortfolioPerformers,
} from "shared/models/top_performances/TopPerformancesModel";
import { FaApiRoutes } from "shared/utils/routes";

export const TopPerformancesService = {
  async fetchTopClients(): Promise<TopClientPerformers> {
    const r = await RestService.get<TopClientPerformers>(
      FaApiRoutes.Advisor.ClientPerformances,
    );
    return r;
  },

  async fetchTopPortfolios(): Promise<TopPortfolioPerformers> {
    const r = await RestService.get<TopPortfolioPerformers>(
      FaApiRoutes.Advisor.PortfolioPerformances,
    );
    return r;
  },
};
