import { createAsyncThunk } from "@reduxjs/toolkit";
import { Portfolio } from "shared/models/portfolio/PortfolioModel";
import { PortfoliosService } from "shared/services/portfolios/PortfoliosService";

export const fetchMany = createAsyncThunk(
  "[PORTFOLIOS] Fetch portfolios",
  async (): Promise<Portfolio[]> => PortfoliosService.getMany(),
);

export const fetchOne = createAsyncThunk(
  "[PORTFOLIOS] Fetch portfolio",
  async (id: string): Promise<Portfolio> => PortfoliosService.getOne(id),
);

export const PortfoliosThunks = {
  fetchMany,
  fetchOne,
};
