import { UserMenu } from "modules/avatar/UserMenu";

type Props = {
  onClick?: () => void;
};

export const Avatar: React.FC<Props> = ({ onClick }) => {
  return (
    <div className="mb-[39px] flex items-center justify-end gap-12">
      <div className="flex shrink-0 items-center gap-8">
        <div className="cursor-pointer" onClick={() => onClick && onClick()}>
          <UserMenu />
        </div>
      </div>
    </div>
  );
};
