import { DateTime } from "luxon";
import RestService from "shared/api/RestService";
import {
  AssetChartData,
  AssetChartDataPoint,
  AssetChartQueryParams,
  AssetEarnings,
  AssetInfo,
  AssetStats,
  AssetWrapper,
} from "shared/models/asset/AssetModel";
import { TIMEZONE_NEW_YORK } from "shared/utils/date";
import {
  COMPANIES_CHART_V2,
  COMPANIES_EARNINGS,
  COMPANIES_PRICE_LEVELS_V2,
  COMPANIES_STATS_V2,
  FaApiRoutes,
} from "shared/utils/routes";

interface PriceLevelsResponse {
  prices: number[];
  ticker: string;
}

function dateToSeconds(date: string, priceInCertainMinute?: string) {
  const [year, month, day] = date.split("-").map((s) => Number(s));
  const [hour, minute] = priceInCertainMinute
    ? priceInCertainMinute.split(":").map((s) => Number(s))
    : [0, 0];
  return DateTime.fromObject(
    { year, month, day, hour, minute },
    { zone: TIMEZONE_NEW_YORK },
  ).toSeconds();
}

export const AssetsService = {
  async search(symbol_or_name: string): Promise<AssetWrapper[]> {
    return RestService.get(FaApiRoutes.Assets.Search, {
      symbol_or_name,
    });
  },

  async getAssets(): Promise<AssetWrapper[]> {
    const assets = await RestService.get<AssetWrapper[]>(
      FaApiRoutes.Assets.Path + "?extras=info",
    );
    return assets;
  },

  async getInfo(symbol: string): Promise<AssetInfo | undefined> {
    try {
      const asset = await RestService.get<AssetWrapper>(
        FaApiRoutes.Assets.Details(symbol).Path + "?extras=info",
      );
      return asset.alpaca_details?.extras?.info;
    } catch (err) {
      return undefined;
    }
  },

  async getChart(
    symbol: string,
    params: AssetChartQueryParams,
    isOpen: boolean,
  ) {
    const useDailyData = params.range === "date";
    const query: AssetChartQueryParams = useDailyData
      ? {
          range: "date",
          exact_date: isOpen ? DateTime.now().toFormat("yyyyMMdd") : "today",
        }
      : params;

    const response = await RestService.get<AssetChartData[]>(
      `${COMPANIES_CHART_V2(symbol)}`,
      query,
    );
    const getAvailablePrice = (item: AssetChartData) =>
      item.average || item.close || item.marketClose || null;

    const data: AssetChartDataPoint[] = response.map(
      (item: AssetChartData, idx, all) => {
        const previous = all[idx > 0 ? idx - 1 : 0];
        return {
          date: dateToSeconds(item.date, item.minute),
          latestPrice: getAvailablePrice(item),
          previousClose: getAvailablePrice(previous),
        };
      },
    );
    return { data, symbol, params };
  },

  async getStats(symbol: string): Promise<AssetStats | undefined> {
    const url = COMPANIES_STATS_V2(symbol);
    try {
      return await RestService.get<AssetStats>(url);
    } catch (err) {
      return undefined;
    }
  },

  async getEarnings(symbol: string): Promise<AssetEarnings[] | undefined> {
    const url = COMPANIES_EARNINGS(symbol);
    try {
      return await RestService.get<AssetEarnings[]>(url);
    } catch (err) {
      return undefined;
    }
  },

  async getSupportLevelPrices(symbol: string): Promise<number[]> {
    const url = COMPANIES_PRICE_LEVELS_V2(symbol);
    const result = await RestService.get<PriceLevelsResponse>(url);
    return result.prices;
  },
};
