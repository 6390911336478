import { PropsWithChildren, useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useRealtimeTicker from "shared/hooks/useRealtimeTicker";
import { AssetWrapper } from "shared/models/asset/AssetModel";
import { CreateOrderActions } from "shared/store/create_order/CreateOrderReducer";
import { roundCurrency, usdIntlConfig } from "shared/utils/currency";
import useCreateOrder from "../hooks/useCreateOrder";
import GenericBuyOrder from "./GenericBuyOrder";

type Props = PropsWithChildren & {
  asset: AssetWrapper;
  onEstimatedValueChange: (value: number) => void;
};

export default function LimitOrder({
  asset,
  onEstimatedValueChange,
  children,
}: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [estimatedValue, setEstimatedValue] = useState(0);
  const { latestPrice, askPrice, bidPrice } = useRealtimeTicker(asset.symbol);
  const order = useCreateOrder();

  const [limitPriceValue, setLimitPriceValue] = useState<string | undefined>(
    "",
  );

  useEffect(() => {
    setEstimatedValue(
      limitPriceValue && order && order.qty
        ? parseFloat(limitPriceValue) * parseFloat(order.qty)
        : 0,
    );
  }, [limitPriceValue, order]);

  useEffect(() => {
    onEstimatedValueChange(estimatedValue);
  }, [estimatedValue, onEstimatedValueChange]);

  useEffect(() => {
    const midPriceAvailable = bidPrice && askPrice;
    const buyLimitPrice = midPriceAvailable
      ? (bidPrice + askPrice) / 2
      : latestPrice;
    const midPrice = roundCurrency(buyLimitPrice);
    setLimitPriceValue(midPrice.toString());
  }, [bidPrice, askPrice, latestPrice]);

  useEffect(() => {
    if (limitPriceValue && order) {
      let newOrder = { ...order };
      newOrder.time_in_force = "day";
      newOrder.type = "limit";
      newOrder.limit_price = limitPriceValue;
      dispatch(CreateOrderActions.update({ ...newOrder }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, order.type, limitPriceValue]);

  return (
    <GenericBuyOrder asset={asset} fractional={false}>
      {children}
      <div>
        <div>{t("trade.limitPrice.label")}</div>
        <div className="rounded-xl border border-sb-gray-200 px-2 py-1 pr-2 font-medium">
          <CurrencyInput
            prefix="$"
            value={limitPriceValue}
            intlConfig={usdIntlConfig}
            decimalsLimit={2}
            decimalScale={2}
            onValueChange={(value) => setLimitPriceValue(value)}
            className="py-1 text-right outline-none"
          />
        </div>
      </div>
    </GenericBuyOrder>
  );
}
