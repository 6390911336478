import { CreatePortfolioForm } from "modules/create_portfolio_form/CreatePortfolioForm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Panel } from "shared/components/hoc/Panels";
import { ProposalQuestionaire } from "shared/models/proposals/ProposalsModel";
import { AIPortfolioCreator } from "./AIPortfolioCreator";
import { PortfolioList } from "./PortfolioList";

interface Props {
  portfolioId?: string;
  onPortfolioIdChange(id?: string): void;
  onQuestionaireChanged: (questionaire: ProposalQuestionaire) => void;
}

export const ProposalPortfolioPicker: React.FC<Props> = ({
  portfolioId,
  onPortfolioIdChange,
  onQuestionaireChanged,
}) => {
  const { t } = useTranslation();
  const [portfolioSwitch, setPortfolioSwitch] = useState("create_ai");

  const createNewPortfolioFlow = portfolioSwitch === "create_new";
  const cloneExistingFlow = portfolioSwitch === "clone_existing";
  const createWithAiFlow = portfolioSwitch === "create_ai";

  const portfolioCreatedHandler = () => {
    setPortfolioSwitch("clone_existing");
  };

  const switchToCreateNewPortfolio = () => {
    setPortfolioSwitch("create_new");
  };

  useEffect(() => {
    if (portfolioId) {
      setPortfolioSwitch("clone_existing");
    }
  }, [portfolioId]);

  return (
    <Panel>
      <div className="mb-8">
        <div className="flex flex-row items-center justify-between">
          <div className="text-xl font-medium">{t("global.portfolio")}</div>
          <PortfolioSwitcher
            value={portfolioSwitch}
            onChange={setPortfolioSwitch}
          />
        </div>
        {cloneExistingFlow && (
          <h3 className="text-md mb-3 text-gray-400">
            {t("proposals.pickOnePortfolio")}
          </h3>
        )}
      </div>
      {createNewPortfolioFlow && (
        <CreatePortfolioForm onPortfolioCreated={portfolioCreatedHandler} />
      )}
      {cloneExistingFlow && (
        <PortfolioList
          portfolioId={portfolioId}
          onPortfolioIdChange={onPortfolioIdChange}
          onCreateNewPortfolioHandler={switchToCreateNewPortfolio}
        />
      )}
      {createWithAiFlow && (
        <AIPortfolioCreator onQuestionaireChanged={onQuestionaireChanged} />
      )}
    </Panel>
  );
};

interface PortfolioSwitcherProps {
  value: string;
  onChange(value: string): void;
}

const PortfolioSwitcher: React.FC<PortfolioSwitcherProps> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex space-x-2 rounded-full bg-sb-gray-100/50 p-2">
      <Radio
        text={t("proposals.createWithAi")}
        onSelect={() => onChange("create_ai")}
        selected={value === "create_ai"}
      />
      <Radio
        text={t("proposals.chooseExisting")}
        onSelect={() => onChange("clone_existing")}
        selected={value === "clone_existing"}
      />
    </div>
  );
};

type RadioProps = {
  text: string;
  onSelect: () => void;
  selected: boolean;
};

const Radio: React.FC<RadioProps> = ({ text, onSelect, selected }) => {
  const selectedClass = "bg-white shadow text-sb-green-700";
  const unselectedClass =
    "text-sb-gray-500 hover:bg-white/70 duration-150 hover:text-sb-gray-700";

  return (
    <div
      className={
        "w-full cursor-pointer select-none whitespace-nowrap rounded-full px-8 py-2.5 text-lg font-medium leading-5 ring-white/60 ring-offset-2 ring-offset-sb-green-400 focus:outline-none focus:ring-2 " +
        (selected ? selectedClass : unselectedClass)
      }
      onClick={() => onSelect()}
    >
      {text}
    </div>
  );
};
