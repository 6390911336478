import { useTranslation } from "react-i18next";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";

interface Props {
  loading: boolean;
  disableSendingProposal: boolean;
  onClearForm(): void;
  onCreateProposal(): void;
}

export const ProposalHeader: React.FC<Props> = ({
  loading,
  disableSendingProposal,
  onClearForm,
  onCreateProposal,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-between gap-4">
      <Labels.H1>{t("proposals.proposal")}</Labels.H1>
      <div className="flex items-center gap-4">
        {/* <Button label="proposals.clearForm" onClick={onClearForm} className="outline-btn" labelClassName="uppercase"/> */}
        <Button
          disabled={disableSendingProposal}
          onClick={onCreateProposal}
          label="proposals.createProposal"
          loading={loading}
        />
      </div>
    </div>
  );
};
