import { createAsyncThunk } from "@reduxjs/toolkit";
import { ToolsService } from "shared/services/tools/ToolsService";

export const fetchMacroeconomicConditions = createAsyncThunk(
  "[MACROECONOMIC_CONDITIONS] Fetch conditions",
  async () => {
    const conditions = await ToolsService.fetchMacroeconomicConditions();
    return conditions;
  },
);

export const MacroeconomicConditionsThunks = {
  fetchMacroeconomicConditions,
};
