import { FloatingWindow } from "./FloatingWindow";
import { PropsWithChildren, useEffect, useState } from "react";
import { PlusCircle } from "shared/icons/PlusCircle";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import { selectPortfolioAssistantSessions } from "shared/store/portfolio_assistant/PortfolioAssistantReducer";
import { PortfolioAssistantThunks } from "shared/store/portfolio_assistant/PortfolioAssistantThunks";
import { formatDateTime } from "shared/utils/date";
import { AiAssistant } from "modules/ai_assistant/AiAssistant";
import { WebsocketProvider } from "shared/api/WebsocketProvider";
import { PORTFOLIO_ASSISTANT_SESSIONS } from "shared/utils/routes";
import { Labels } from "../hoc/Labels";
import { useTranslation } from "react-i18next";
import AiChat from "shared/icons/AiChat";
import ChevronRight from "shared/icons/ChevronRight";
import { PortfolioAssistantService } from "shared/services/portfolio_assistant/PortfolioAssistantService";
import { Spinner } from "./Spinner";
import { auth } from "shared/utils/firebase";
import {
  AppActions,
  selectAssistantActiveSession,
  selectAssistantOpened,
} from "shared/store/app/AppReducer";

export const AiChatBubble: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const aiChatOpened = useSelector(selectAssistantOpened());

  const { sessions } = useSelector(selectPortfolioAssistantSessions());
  const activeSession = useSelector(selectAssistantActiveSession());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(PortfolioAssistantThunks.fetchSessions());
  }, [dispatch, auth.currentUser]);

  const createNewSession = () => {
    setLoading(true);
    PortfolioAssistantService.createSession()
      .then((s) => dispatch(AppActions.setAssistantActiveSession(s)))
      .finally(() => {
        setLoading(false);
      });
  };

  if (!auth.currentUser) return null;

  return (
    <div className="fixed bottom-[50px] right-[120px] z-50 shrink-0">
      <div className="relative">
        <FloatingWindow open={aiChatOpened} height={700} width={500}>
          {activeSession ? (
            <WebsocketProvider
              url={`/api/${PORTFOLIO_ASSISTANT_SESSIONS}/${activeSession.session_id}`}
              children={(socket) => (
                <AiAssistant
                  socket={socket}
                  compact
                  onBackClicked={() =>
                    dispatch(AppActions.setAssistantActiveSession(undefined))
                  }
                  sessionId={activeSession.session_id}
                />
              )}
            />
          ) : (
            <div className="relative space-y-2 p-4">
              <div className="absolute right-4 top-4 rounded-lg bg-sb-cyan-600 px-4 py-1 font-semibold text-white">
                BETA
              </div>
              <Labels.H3 className="mb-4">{t("aiAssistant.title")}</Labels.H3>
              <Row onClick={createNewSession} loading={loading}>
                <div className="flex items-center gap-2">
                  <div>
                    <PlusCircle className="aspect-square w-5" />
                  </div>
                  <div>{t("aiAssistant.newSession")}</div>
                </div>
              </Row>
              {sessions.map((session) => {
                return (
                  <Row
                    key={session.session_id}
                    onClick={() =>
                      dispatch(AppActions.setAssistantActiveSession(session))
                    }
                  >
                    {session.context.latest_user_message}
                    <div className="text-sm font-normal text-sb-gray-500">
                      {formatDateTime(session.created_at)}
                    </div>
                  </Row>
                );
              })}
            </div>
          )}
        </FloatingWindow>
        <div
          className="grid cursor-pointer place-items-center rounded-full bg-cyan-300 p-2 drop-shadow-sm duration-300 hover:scale-110"
          onClick={() => dispatch(AppActions.toggleAiAssistant())}
        >
          {aiChatOpened ? (
            <div className="grid aspect-square w-10 place-items-center">
              <ChevronRight className="aspect-square w-6 rotate-90 text-white" />
            </div>
          ) : (
            <AiChat className="aspect-square w-10 text-white" />
          )}
        </div>
      </div>
    </div>
  );
};

type RowProps = PropsWithChildren & {
  onClick?: () => void;
  loading?: boolean;
};

const Row: React.FC<RowProps> = ({ onClick, loading, children }) => {
  return (
    <div
      className={
        (loading ? "" : "cursor-pointer hover:bg-sb-gray-200") +
        "relative w-full  rounded-xl bg-sb-gray-100 p-4 font-semibold duration-150"
      }
      onClick={() => !loading && onClick?.()}
    >
      {loading && (
        <div className="absolute bottom-0 left-0 right-0 top-0 z-50 bg-white/80">
          <div className="grid h-full place-items-center">
            <Spinner className="h-5 w-5" fill="fill-sb-gray-700" />
          </div>
        </div>
      )}
      {children}
    </div>
  );
};
