import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DataTable } from "shared/components/common/datatable/DataTable";
import { Panel } from "shared/components/hoc/Panels";
import { useDispatch } from "shared/hooks/useDispatch";
import { useFeeTemplates } from "shared/hooks/useFeeTemplates";
import { FeeTemplatesThunks } from "shared/store/fee_templates/FeeTemplatesThunks";
import { Loading } from "shared/types/enums";
import { FeeTemplateEntry } from "shared/types/FeeTemplateType";
import { formatDateTime } from "shared/utils/date";
import { CheckCircleSolid } from "./svg/CheckCircleSolid";
import { XCircleSolid } from "./svg/XCircleSolid";

export const FeeTemplatesList: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { feeTemplates, loading } = useFeeTemplates();

  useEffect(() => {
    dispatch(FeeTemplatesThunks.fetchFeeTemplates());
  }, [dispatch]);

  return (
    <Panel>
      <DataTable
        loading={!feeTemplates.length && loading === Loading.InProgress}
        data={feeTemplates}
        columns={["is_default", "label", "created_at", "in_ibrk"]}
        rowClass={(row) => {
          return row.is_default ? "!bg-blue-100" : "";
        }}
        headerLabels={{
          label: t("feeTemplatesList.columns.name"),
          created_at: t("feeTemplatesList.columns.created_at"),
          is_default: t("feeTemplatesList.columns.is_default"),
          in_ibrk: t("feeTemplatesList.columns.in_ibrk"),
        }}
        cellFormatter={(key: string, value: string) => {
          if (key === "created_at") {
            return formatDateTime(value);
          }
          if (key === "is_default") {
            return value ? (
              <CheckCircleSolid className="h-6 w-6 fill-sb-green-700" />
            ) : (
             '-'
            );
          }
          if (key === "in_ibrk") {
            return value ? (
              <CheckCircleSolid className="float-right h-6 w-6 fill-sb-green-700" />
            ) : (
              <XCircleSolid className="float-right h-6 w-6 fill-sb-red-700" />
            );
          }
          return value;
        }}
        onRowClicked={(row: FeeTemplateEntry) => {
          navigate(`/fee-templates/${row.name}`);
        }}
      />
      {!feeTemplates.length && loading === Loading.Finished && (
        <div className="rounded-lg bg-sb-gray-200 p-4">
          {t("feeTemplatesList.noFeeTemplates")}
        </div>
      )}
    </Panel>
  );
};
