import { AxiosProgressEvent } from "axios";
import RestService from "shared/api/RestService";
import { MacroeconomicConditions } from "shared/models/tools/MacroeconomicConditionsModel";
import {
  TextAssistantMessage,
  TextAssistantResponse,
} from "shared/models/tools/TextAssistantModel";
import { FaApiRoutes } from "shared/utils/routes";

export const ToolsService = {
  async fetchMacroeconomicConditions(): Promise<MacroeconomicConditions> {
    const r = await RestService.get<MacroeconomicConditions>(
      FaApiRoutes.Tools.MacroeconomicConditions,
    );
    return r;
  },

  async fetchTextAssistant(
    message: TextAssistantMessage,
    onMessage: (message: string, finished: boolean) => void,
  ) {
    const onProgress = (progressEvent: AxiosProgressEvent) => {
      const xhr = progressEvent.event.target;
      const { responseText } = xhr;
      const lines = responseText.split("\n");
      let finished = false;
      let response = "";
      for (let line of lines) {
        const sliced = line.slice("data: ".length).trim();
        if (!sliced) {
          continue;
        }
        const assistantResponse: TextAssistantResponse = JSON.parse(sliced);
        if (assistantResponse.type === "set_text") {
          response = assistantResponse.text;
          if (assistantResponse.text) {
            finished = true;
          }
        } else if (assistantResponse.type === "append_text") {
          response += assistantResponse.text;
        }
      }
      onMessage(response, finished);
    };

    const r = await RestService.postSse(
      FaApiRoutes.Tools.TextAssistant,
      message,
      onProgress,
    );
    return r;
  },
};
