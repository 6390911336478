import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Labels } from "shared/components/hoc/Labels";
import { Layouts } from "shared/components/hoc/Layouts";
import { Panel } from "shared/components/hoc/Panels";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import { PortfolioActivity } from "shared/models/portfolio/PortfolioModel";
import { formatDate } from "shared/utils/date";
import CompositionUpdate from "./CompositionUpdate";
import { ClientDetailsThunks } from "shared/store/client_details/ClientDetailsThunks";
import { selectClientRecentActivities } from "shared/store/client_details/ClientDetailsReducer";
import { Loading } from "shared/types/enums";

type Props = {
  clientId?: string;
  portfolioId?: string;
};

type SortedActivities = {
  date: string;
  entry: {
    portfolioId: string;
    activities: PortfolioActivity[];
  }
};

export const LatestUpdates: React.FC<Props> = ({ portfolioId, clientId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stateClientActivities = useSelector(selectClientRecentActivities(clientId))
  const [sortedActivities, setSortedActivities] = useState<SortedActivities[]>([]);

  

  useEffect(() => {
    if (stateClientActivities.loading === Loading.Finished && sortedActivities.length === 0) {
      const mapAndSortActivities = () => {
        const activities = !portfolioId
          ? stateClientActivities.activities
          : stateClientActivities.activities?.filter((a) => a.portfolio_id === portfolioId);
    
        let dateMappedActivities: Record<
          string,
          { portfolioId: string; activities: PortfolioActivity[] }
        > = {};
    
        if (activities) {
          for (let activity of activities) {
            const portfolioId = activity.portfolio_id;
            for (let a of activity.activities?.filter(
              (a) => a.entry_type === "composition",
            )) {
              const date = formatDate(a.timestamp).toString();
              if (!dateMappedActivities[date]) {
                dateMappedActivities[date] = { portfolioId, activities: [a] };
              } else {
                if (dateMappedActivities[date].portfolioId === portfolioId) {
                  dateMappedActivities[date].activities.push(a);
                } else {
                  dateMappedActivities[date] = { portfolioId, activities: [a] };
                }
              }
            }
          }
        }
        let sortedActivities = [];
        for (let [key, entry] of Object.entries(dateMappedActivities)) {
          sortedActivities.push({ date: key, entry: entry });
        }
        sortedActivities.sort((a, b) => (a.date > b.date ? -1 : 1));
    
        setSortedActivities(sortedActivities);
    
      }
      mapAndSortActivities();
    }
  }, [stateClientActivities, sortedActivities, portfolioId])

  useEffect(() => {
    if (clientId) {
      dispatch(ClientDetailsThunks.fetchRecentActivities({ clientId: clientId, params: { type: 'composition' } }))
    }
  }, [clientId, dispatch]);

  if (!clientId) {
    return null;
  }


  return (
    <Panel>
      <Labels.H3 className="mb-4">{t("latestUpdates.title")}</Labels.H3>
      <Layouts.Spaced>
        {sortedActivities?.map((item: any, idx: number) => {
          return (
            <div key={"latest_updates_" + idx}>
              <div className="text-lg text-sb-green-700">{item.date}</div>
              {portfolioId}
              <div className="mt-4">
                <CompositionUpdate
                  latestActivities={item.entry.activities}
                  portfolioId={item.entry.portfolioId}
                />
              </div>
            </div>
          );
        })}
        {!sortedActivities && (
          <Labels.G1>{t("latestUpdates.noUpdates")}</Labels.G1>
        )}
      </Layouts.Spaced>
    </Panel>
  );
};
