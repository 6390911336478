import { createSlice, createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { subscribe } from "./RealtimeSubscriptionsThunks";

interface RealTimeTickerLatestClose {
  latestPrice: number | null;
  previousClose: number | null;
}

interface RealtimeTicker extends RealTimeTickerLatestClose {
  trends?: number[];
  subscribed_to_realtime?: boolean;
  bidPrice?: number;
  askPrice?: number;
}

export interface RealTimeTickers {
  [symbol: string]: RealtimeTicker | undefined;
}

interface RealtimePricesState {
  tickers: RealTimeTickers;
}

const initialState: RealtimePricesState = {
  tickers: {},
};

const RealtimePricesSlice = createSlice({
  name: "[REALTIME_PRICES]",
  initialState,
  reducers: {
    updateMany(
      state,
      { payload }: { payload: { [key: string]: RealTimeTickerLatestClose } },
    ) {
      Object.entries(payload).forEach(([symbol, ticker]) => {
        state.tickers[symbol] = {
          ...state.tickers[symbol],
          ...ticker,
          subscribed_to_realtime: true,
        };
      });
    },
    update(
      state,
      {
        payload: { symbol, ticker },
      }: { payload: { symbol: string; ticker: RealTimeTickerLatestClose } },
    ) {
      state.tickers[symbol] = {
        ...state.tickers[symbol],
        ...ticker,
        subscribed_to_realtime: true,
      };
    },
    unsubscribe(
      state,
      { payload: { symbol } }: { payload: { symbol: string } },
    ) {
      state.tickers[symbol] = {
        ...state.tickers[symbol],
        latestPrice: state.tickers[symbol]?.latestPrice || null,
        previousClose: state.tickers[symbol]?.previousClose || null,
        subscribed_to_realtime: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      subscribe.fulfilled,
      (state, { payload }: { payload: RealTimeTickers }) => {
        Object.entries(payload).forEach(([key, value]) => {
          if (value)
            state.tickers[key] = {
              ...state.tickers[key],
              ...value,
              subscribed_to_realtime: true,
            };
        });
      },
    );
  },
});

interface RealtimeStockPrice {
  previousClose: number;
  latestPrice: number;
  bidPrice: number;
  askPrice: number;
  trends: any[];
  subscribed_to_realtime: boolean;
}
const selectRealtimePrices = (state: RootState) => state.realtimePrices;
export const selectRealtimeStockPrice = (symbol: string) =>
  createSelector(selectRealtimePrices, (state) => {
    const ticker = state.tickers[symbol];
    if (!ticker) {
      return {
        previousClose: 0,
        latestPrice: 0,
        bidPrice: 0,
        askPrice: 0,
        trends: [],
        subscribed_to_realtime: false,
      } as RealtimeStockPrice;
    }

    const bidPrice = ticker.bidPrice || 0;
    const askPrice = ticker.askPrice || 0;

    const previousClose = ticker.previousClose || 0;
    const latestPrice = ticker.latestPrice || 0;

    // We will show an empty graph until we have enough data to show
    // instead of showing previousClose.
    // To proper fix this we could use IEX extended hours data
    const trends = ticker.trends || [];
    const subscribed_to_realtime = ticker.subscribed_to_realtime;

    return {
      previousClose,
      latestPrice,
      trends,
      subscribed_to_realtime,
      bidPrice,
      askPrice,
    } as RealtimeStockPrice;
  });

export const RealtimePricesActions = { ...RealtimePricesSlice.actions };

export default RealtimePricesSlice.reducer;
