export type FeeStrategyType =
  | "feeStrategy_1"
  | "feeStrategy_2"
  | "feeStrategy_3";

export type NetLiquidationType = "blended" | "fixed";

export type NetLiquidationAppliedType =
  | "daily"
  | "monthly"
  | "monthly_end"
  | "quarterly"
  | "quarterly_end";

export type FlatFeeType = "daily" | "monthly" | "quarterly";

export type PnlPctAppliedType = "quarterly" | "yearly";

export type InvoicingAppliedType = "quarterly" | "monthly";

export type PnlPctTypeType = "benchmark" | "fixed";

type PnlPctBenchmarkType = "Xetra-Gold" | "Apple inc.";

export const financialInstruments = [
  "Xetra-Gold",
  "Apple Inc.",
  "iShares MSCI ACWI Index",
  "iShares MSCI Global Min Vol Factor ETF",
  "iShares MSCI ACWI Ex US ETF",
  "Vaneck Vectors Africa ETF",
  "iShares Barclays Aggregate Bond",
  "iShares Global Aggregate Bond UCITS ETF",
  "iShares SP Asia 50 Index",
  "Alerian MLP ETF",
  "iShares Core Aggressive Allocation",
  "iShares Core Conservative Allocation",
  "iShares Core Moderate Allocation",
  "iShares Core Growth Allocation",
  "MSCI Asia Apex 50 Index",
  "ARK Innovation ETF",
  "NASDAQ BANK INDEX",
  "BEL 20 Index",
  "Barclayhedge Hedge Fund Index",
  "SPDR Bloomberg 1-3 Month T-Bill ETF",
  "VANECK BDC INCOME ETF",
  "iShares MSCI BRIC Index",
  "Vanguard Total Bond Market ETF",
  "Berkshire Hathaway Inc - Class A",
  "Berkshire Hathaway Inc - Class B",
  "SPDR Bloomberg Barclays International Treasury Bond ETF",
  "Buy Write Monthly Index",
  "CAC 40",
  "iShares SP Valmer Mexico CETETRAC",
  "CNX 100",
  "NASDAQ Composite Index",
  "DAX 30 Performance Index",
  "Invesco DB Agriculture Fund",
  "Invesco DB Base Metals Fund",
  "Invesco DB Commodity Index Tra",
  "Xtrackers II Global Inflation-Linked Bond UCITS ETF",
  "Flaherty Crumrine Dynamic Preferred and Income Fund Inc",
  "DFA Global Allocation 60/40 Portfolio",
  "Diamonds Trust Series 1",
  "Amplify CWP Enhanced Dividend",
  "Dow Jones STOXX Mid 200 Index",
  "Dow Jones STOXX Large 200 Index",
  "Dow Jones STOXX Small 200 Index",
  "Dow Jones STOXX 600 Index",
  "Dow Jones Euro STOXX Index",
  "iPath Bloomberg Commodity",
  "Short Dow 30",
  "Dow Jones Transportation Average",
  "Dow Jones Utility Average",
  "iShares Select Dividend ETF",
  "Dow Jones Wilshire 5000 Composite Index",
  "WisdomTree Japan Hedged Equity Fund",
  "Financial Times Eurotop 100 Stock Index",
  "Financial Times Eurotop 300 Stock Index",
  "iShares MSCI Chile Investable Market Index",
  "iShares MSCI Emerging Markets Index",
  "iShares MSCI EAFE Index",
  "FTSEUROFIRST 80",
  "FTSEUROFIRST 100",
  "Short MSCI EAFE Index",
  "Eurekahedge Equity Market Neutral Index",
  "iShares MSCI Israel Cap Investable Market Index",
  "iShares JPMorgan USD Emerging Markets Bond ETF",
  "ISHARES MSCI NORWAY ETF",
  "AMS EOE Index",
  "iShares MSCI Poland Investable Market Index",
  "iShares MSCI Pacific ex-Japan",
  "iShares MSCI All Peru Capped Index",
  "Invesco Russell 1000 Equal Weight ETF",
  "Short MSCI Emerging Markets Index",
  "iShares Core Global Aggregate Bond UCITS ETF",
  "iShares MSCI Australia Index",
  "iShares MSCI Canada Index",
  "iShares MSCI Sweden Index",
  "iShares MSCI Germany Index",
  "iShares MSCI Hong Kong Index",
  "iShares MSCI Italy Index",
  "iShares MSCI Japan Index",
  "iShares MSCI Belgium Investable Market Index",
  "iShares MSCI Switzerland Index",
  "iShares MSCI Malaysia Index",
  "iShares MSCI Netherlands Investable Market Index",
  "iShares MSCI Austria Investable Market Index",
  "iShares MSCI Spain Index",
  "iShares MSCI France Index",
  "iShares MSCI Singapore Index",
  "iShares MSCI Taiwan Index",
  "ISHARES MSCI UNITED KINGDOM",
  "iShares MSCI Mexico Investable Market Index",
  "iShares MSCI South Korea Index",
  "iShares MSCI Brazil Index",
  "iShares MSCI South Africa Index",
  "SPDR DJ Euro STOXX 50",
  "FTSE NAREIT Preferred Stock Index",
  "FINNISH Stock Index",
  "First Trust Long-Short Equity",
  "FTSE MIB Index",
  "Invesco CurrencyShares Euro Cu",
  "iShares FTSE/Xinhua China 25 Index",
  "VanEck Vectors Gold Miners ETF",
  "SPDR Gold Shares",
  "Cambria Global Momentum ETF",
  "iShares US Treasury Bond ETF",
  "iShares SP GSCI Commodity-Indexed Trust",
  "Cambria Global Value ETF",
  "Global X FTSE Nordic 30 ETF",
  "Global X/InterBolsa FTSE Colombia 20 ETF",
  "Credit Suisse Equity Market Neutral Index",
  "WisdomTree Europe Hedged Equity Fund",
  "iShares Currency Hedged MSCI EAFE ETF",
  "Hedge Fund Research HFRI Equity Hedge Total Index",
  "Hang Seng China 50 Index",
  "Hang Seng Composite Index",
  "Hang Seng HK 35 Index",
  "Hang Seng Stock Index",
  "Hungarian Stock Index",
  "iShares High Yield Corporate Bond BuyWrite Strategy ETF",
  "iShares Nasdaq Biotechnology ETF",
  "IBEX 35 Index",
  "Brazil Bovespa Stock Index",
  "Bovespa Brazil 50 Index",
  "iBoxx iShares High Yield Corporate Bond Index TR",
  "iShares Global Clean Energy ETF",
  "Vaneck Vectors Indonesia ETF",
  "iShares Barclays 7-10 Year Treasury",
  "iShares 3-7 Year Treasury Bond ETF",
  "iShares SP Europe 350 Index",
  "ISHARES Intermediate",
  "iShares International Treasury Bond",
  "iShares SP 500 AUD Hedged ETF",
  "iShares Core SP Mid Cap ETF",
  "iShares SP SmallCap 600 Index",
  "iShares SP Latin America 40 Index",
  "Alpha Architect International Quantitative Momentum ETD",
  "Dow Jones Industrial Average",
  "iShares Global 100 ETF",
  "Mexico Bolsa Index",
  "iShares MSCI ACWI UCITS ETF",
  "iShares Core SP Total U.S. Stock Market",
  "iShares Core Total USD Bond Market ETF",
  "iShares SP 500 EUR-H",
  "Alpha Architect International Quantitative Value ETF",
  "iShares SP 500 Value ETF",
  "iShares Core SP 500 ETF",
  "iShares SP 500 Growth ETF",
  "iShares Russell 1000 ETF",
  "iShares Micro-Cap ETF",
  "iShares Russell 1000 Value ETF",
  "iShares Core MSCI World",
  "iShares MSCI World CHF-H",
  "iShares Russell 2000 ETF",
  "iShares Russell 2000 Value ETF",
  "ISHARES RUSSELL 2000 GROWTH",
  "iShares Russell Mid-Cap ETF",
  "iShares Russell 3000 ETF",
  "iShares SP Global Telecommunications",
  "iShares Dow Jones US Basic Materials",
  "iShares Dow Jones US Real Estate",
  "iShares Transportation Average",
  "iShares Dow Jones US Telecom",
  "BNP Paribas Easy JPM ESG EMU Government Bond IG UCITS ETF",
  "JPMorgan Equity Premium Income ETF",
  "SPDR Barclays Capital High Yield Bond",
  "Virtus Terranova U.S. Quality Momentum ETF",
  "Japan Index",
  "Nuveen Preferred Income Securities Fund",
  "KOSPI200 Index",
  "SP Kensho New Economies Composite Index",
  "FT Cboe Vest SP 500 Dividend Aristocrats Target Income ETF",
  "KraneShares CSI China Internet ETF",
  "iShares iBoxx $ Investment Grade Corporate Bond ETF",
  "iShares Investment Grade Corporate Bond BuyWrite Strategy ETF",
  "MSCI EAFE Net Total Return Index",
  "MSCI Emerging Markets Net Total Return Index",
  "Midcap DAX",
  "First Trust Multi-Asset Diversified Income Index Fund",
  "SPDR SP MidCap 400",
  "SP Midcap 400 Index",
  "MSCI ACWI World Islamic Index",
  "Microsoft Corp.",
];
