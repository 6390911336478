import RestService from "shared/api/RestService";
import { MarketInfo } from "shared/models/market/MarketModel";
import { CLOCK_V1 } from "shared/utils/routes";

export const MarketService = {
  async checkMarketOpen(): Promise<MarketInfo> {
    const data = await RestService.get<MarketInfo>(CLOCK_V1);
    return data;
  },
};
