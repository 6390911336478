import { useTranslation } from "react-i18next";
import { PieChart, Pie, ResponsiveContainer, Cell, Tooltip } from "recharts";
import { useChartColors } from "./hooks/useChartColors";
import { MetaPortfolio } from "shared/models/portfolio/PortfolioModel";

type Props = {
  metaPortfolio: MetaPortfolio[] | undefined;
};

export const CompositionChart: React.FC<Props> = ({ metaPortfolio }) => {
  const { t, i18n } = useTranslation();
  const { innerData, outerData, innerColors, outerColors } =
    useChartColors(metaPortfolio);

  if (!metaPortfolio) {
    return <></>;
  }

  const tooltipContent = (tooltipProps: any) => {
    const value =
      tooltipProps.payload && tooltipProps.payload.length > 0
        ? tooltipProps.payload[0]["payload"]["value"]
        : "";
    const name =
      tooltipProps.payload && tooltipProps.payload.length > 0
        ? tooltipProps.payload[0]["payload"]["name"]
        : "";
    const translationKey = `portfolioCard.assetClasses.${name}`;
    return (
      <div className="rounded-sm bg-sb-gray-100 px-2.5 py-1.5 text-base font-medium">
        {i18n.exists(translationKey) ? t(translationKey) : name}{" "}
        {(value * 100).toFixed(0)}%
      </div>
    );
  };

  return (
    <div className="aspect-square h-[200px] max-h-[300px] w-[350px] min-w-[300px]">
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={innerData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={40}
            outerRadius={60}
          >
            {innerData.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={innerColors[index]}
                stroke={innerColors[index]}
              />
            ))}
          </Pie>
          <Pie
            data={outerData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={65}
            outerRadius={95}
          >
            {outerData.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={outerColors[index]}
                stroke={outerColors[index]}
              />
            ))}
          </Pie>
          <Tooltip content={tooltipContent} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};
