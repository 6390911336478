import React from "react";

import { FewMoreQuestionsColumn } from "./components/FewMoreQuestionsColumn";
import { OnboardingFooter } from "./components/OnboardingFooter";
import { OnboardingPartProps } from "./OnboardingFlow";
import { CheckboxAnswers } from "./components/CheckboxAnswers";
import { FundingSourceType } from "shared/types/FundingSourceType";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import {
  OnboardingActions,
  selectOnboarding,
} from "shared/store/onboarding/OnboardingReducer";
import { OnboardingContainer } from "shared/components/common/OnboardingContainer";
import { Labels } from "shared/components/hoc/Labels";
import { useTranslation } from "react-i18next";
import i18n from "shared/i18n/i18n";

interface FundingSourceOption {
  label: string;
  value: FundingSourceType;
}

const options: FundingSourceOption[] = [
  {
    label: i18n.t("forms.fundingSource.employment_income"),
    value: i18n.t("employment_income"),
  },
  { label: i18n.t("forms.fundingSource.investments"), value: "investments" },
  { label: i18n.t("forms.fundingSource.inheritance"), value: "inheritance" },
  { label: i18n.t("forms.fundingSource.savings"), value: "savings" },
  {
    label: i18n.t("forms.fundingSource.business_income"),
    value: "business_income",
  },
  { label: i18n.t("forms.fundingSource.family"), value: "family" },
];

export const FundingSource: React.FC<OnboardingPartProps> = ({
  onNext,
  onPrevious,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { fundingSource = [], employmentStatus } =
    useSelector(selectOnboarding);

  const onSelectFoundingSource = (value: FundingSourceType | undefined) => {
    const fundingIsSelectedAlready = fundingSource?.find((f) => f === value);
    let fundingSourceValue: FundingSourceType[] = [];
    if (!value) return;
    if (fundingIsSelectedAlready) {
      fundingSourceValue = fundingSource?.filter((f) => value !== f) || [];
    } else {
      fundingSourceValue = [value, ...(fundingSource || [])];
    }

    dispatch(OnboardingActions.update({ fundingSource: fundingSourceValue }));
  };

  const handleOnPrevious = () => {
    if (!onPrevious) return;
    if (employmentStatus === "employed") {
      onPrevious();
      return;
    }
    onPrevious("employment_status");
  };

  return (
    <OnboardingContainer
      leftColumnContent={<FewMoreQuestionsColumn />}
      rightColumnContent={
        <div className="mb-14 mt-20 flex flex-col">
          <div className="flex-1">
            <Labels.H2 className="mb-2">
              {t("webOnboarding.howFundAccount")}
            </Labels.H2>
            <Labels.B1 className="mb-6">
              {t("webOnboarding.sbIsRequired")}
            </Labels.B1>
            <CheckboxAnswers
              value={fundingSource}
              options={options}
              onChange={(v) => onSelectFoundingSource(v)}
            />
          </div>
          <OnboardingFooter
            onNext={() => onNext && onNext()}
            onPrev={handleOnPrevious}
            nextDisabled={!fundingSource?.length}
          />
        </div>
      }
    />
  );
};
