import { Person } from "shared/icons/Person";
import { StreetbeatIcon } from "shared/icons/StreetbeatIcon";
import "./styles.css";
import { TypingAnimation } from "./svg/TypingAnimation";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { FeedbackButtons } from "./FeedbackButtons";

type Props = {
  text: string;
  sbIcon?: boolean;
  typing?: boolean;
  combine?: boolean;
  combinedFirst?: boolean;
  combinedLast?: boolean;
  skipFeedback?: boolean;
  messageId?: string;
  sessionId?: string;
};

export const Response: React.FC<Props> = ({
  text,
  sbIcon,
  typing,
  combine,
  combinedFirst,
  combinedLast,
  skipFeedback,
  messageId,
  sessionId,
}) => {
  return (
    <div
      className={
        "flex w-full items-end gap-2 " + (combine && combinedLast ? "mb-4" : "")
      }
    >
      <div
        className={
          "grid aspect-square h-6 place-items-center rounded-full " +
          (sbIcon ? "bg-black" : "bg-sb-gray-200") +
          (combine && !combinedLast ? " opacity-0" : "")
        }
      >
        {sbIcon ? (
          <StreetbeatIcon className="aspect-square h-4" />
        ) : (
          <Person className="aspect-square h-4 text-sb-gray-500" />
        )}
      </div>
      <div
        className={
          (combine ? "px-4 py-2 " : "chat-message-bubble ") +
          "left w-full bg-sb-gray-100 " +
          (combinedFirst ? "rounded-t-2xl" : "") +
          (combinedLast && sbIcon ? "rounded-br-2xl" : "") +
          (combinedLast && !sbIcon ? "rounded-bl-2xl" : "")
        }
      >
        {typing ? (
          <TypingAnimation />
        ) : (
          <ReactMarkdown remarkPlugins={[remarkGfm]} className="reactMarkdown">
            {text}
          </ReactMarkdown>
        )}
        {!skipFeedback && sessionId && messageId && (
          <FeedbackButtons sessionId={sessionId} messageId={messageId} />
        )}
      </div>
    </div>
  );
};
