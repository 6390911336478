import { RecurrentInvestment } from "modules/proposals/RecurrentInvestment";
import { useEffect, useState } from "react";
import { useAccount } from "shared/hooks/useAccount";
import { useDispatch } from "shared/hooks/useDispatch";
import { RecurringInvestmentFrequency } from "shared/models/client_proposal/ClientProposalModel";
import { Proposal } from "shared/models/proposals/ProposalsModel";
import { ProposalsService } from "shared/services/proposals/ProposalsService";
import { AccountThunks } from "shared/store/account/AccountThunks";
import { ProposalsThunks } from "shared/store/proposals/ProposalsThunks";
import { EditMenu } from "./ProposalEditMenu";

type Props = {
  proposal: Proposal;
};

export const EditableRecurrentInvestment: React.FC<Props> = ({ proposal }) => {
  const dispatch = useDispatch();
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newValue, setNewValue] = useState<string>();
  const [newFrequency, setNewFrequency] =
    useState<RecurringInvestmentFrequency>();
  const { account } = useAccount();

  const recurringAmount = `${proposal.recurring_deposit}`;
  const recurringFrequency = proposal.recurring_deposit_frequency;

  const canEdit = ["draft", "sent"].includes(proposal.status);

  useEffect(() => {
    if (!account) {
      dispatch(AccountThunks.fetch());
    }
  }, [account, dispatch]);

  useEffect(() => {
    if (editing) {
      setNewValue(recurringAmount);
      setNewFrequency(recurringFrequency);
    }
  }, [editing, recurringAmount, recurringFrequency]);

  const onSaveClicked = () => {
    if (newValue && newFrequency) {
      setLoading(true);
      ProposalsService.patchProposal(proposal.proposal_id, {
        recurring_deposit: parseFloat(newValue),
        recurring_deposit_frequency: newFrequency,
      })
        ?.then(async () => {
          await dispatch(ProposalsThunks.fetchOne(proposal.proposal_id));
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    setEditing(false);
  };

  return (
    <RecurrentInvestment
      defaultValue={editing ? recurringAmount : undefined}
      defaultFreqValue={editing ? recurringFrequency : undefined}
      onChange={(value) => setNewValue(value)}
      onFrequencyChange={(value) => setNewFrequency(value)}
      readOnlyFrequencyValue={!editing ? recurringFrequency : undefined}
      readOnlyValue={!editing ? recurringAmount : undefined}
    >
      {canEdit && (
        <EditMenu
          editing={editing}
          setEditing={setEditing}
          onSaveClicked={onSaveClicked}
        />
      )}
      {loading && (
        <div className="absolute bottom-0 left-0 right-0 top-0 z-50 bg-white opacity-50"></div>
      )}
    </RecurrentInvestment>
  );
};
