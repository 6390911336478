import { parseDate } from "@internationalized/date";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { DateRange } from "react-aria";
import { useTranslation } from "react-i18next";
import { LineBarChart } from "shared/components/common/chart/LineBarChart";
import { DateRangeSelector } from "shared/components/common/DateRangeSelector";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import { FeeAccruedChartPoint } from "shared/models/fees/FeesModel";
import { FeesService } from "shared/services/fees/FeesService";
import { CurrencyFormat } from "shared/utils/currency";

type Props = {
  clientId?: string;
};

export const FeesAccruedChart: React.FC<Props> = ({ clientId }) => {
  const { t } = useTranslation();
  const [data, setData] = useState<FeeAccruedChartPoint[]>();
  const [dateRange, setDateRange] = useState<DateRange>({
    start: parseDate(
      DateTime.local().minus({ months: 3 }).toFormat("yyyy-MM-dd"),
    ),
    end: parseDate(DateTime.local().toFormat("yyyy-MM-dd")),
  });

  useEffect(() => {
    if (clientId) {
      FeesService.getAccruedChart({
        client_ids: [clientId],
        start: dateRange.start.toString(),
        end: dateRange.end.toString(),
      }).then(setData);
    } else {
      FeesService.getAccruedChart({
        start: dateRange.start.toString(),
        end: dateRange.end.toString(),
      }).then(setData);
    }
  }, [clientId, dateRange.end, dateRange.start]);

  return (
    <Panel>
      <div className="flex items-center justify-between gap-4">
        <Labels.H3>{t("fees.accruedFees")}</Labels.H3>
        <DateRangeSelector
          dateRange={dateRange}
          setDateRange={setDateRange}
          placeholder={t("fees.headers.feeDate")}
        />
      </div>
      <div className="mt-4 w-full select-none py-4 pr-4">
        <LineBarChart
          domain={[0, "auto"]}
          data={data}
          xKey={"date"}
          yKey={"amount"}
          yRightKey={"amount_cumulative"}
          height={400}
          yFormatter={(x) => CurrencyFormat.format(x)}
          yRightFormatter={(x) => CurrencyFormat.format(x)}
          lineName={t("fees.amountCumulative")}
          barName={t("fees.amount")}
        />
      </div>
    </Panel>
  );
};
