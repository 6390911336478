import { parseDate } from "@internationalized/date";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { DateValue } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { CustomDatePicker } from "shared/components/common/daterangepicker/CustomDateRangePicker";
import { Modal } from "shared/components/common/Modal";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";
import { useDispatch } from "shared/hooks/useDispatch";
import { CalendarIcon } from "shared/icons/Calendar";
import {
  FeeFrequency,
  FinancialAdvisorFeeSchedule,
} from "shared/models/fees/FeesModel";
import { FeesService } from "shared/services/fees/FeesService";
import { ClientDetailsThunks } from "shared/store/client_details/ClientDetailsThunks";

type Props = {
  clientId: string;
  open: boolean;
  setOpen: (open: boolean) => void;
};
export const CreateFeeModal: React.FC<Props> = ({
  open,
  setOpen,
  clientId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [schedule, setSchedule] = useState<FinancialAdvisorFeeSchedule>({
    fee_type: "nlv_percentage",
    fee_frequency: "daily",
    effective_from:
      DateTime.now().plus({ days: 1 }).toFormat("yyyy-MM-dd") || "",
    configuration: {
      annual_rate: 0,
    },
  });
  const [error, setError] = useState("");
  const [effectiveDate, setEffectiveDate] = useState<DateValue>(
    parseDate(schedule.effective_from),
  );
  const [loading, setLoading] = useState(false);

  const onCreate = () => {
    setLoading(true);
    FeesService.createFeeSchedule(clientId, schedule)
      .then(() => {
        dispatch(ClientDetailsThunks.fetchOpenFees({ clientId }));
        dispatch(ClientDetailsThunks.fetchFeeSchedule({ clientId }));
        toast.success(t("fees.created"));
        setOpen(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (effectiveDate) {
      setSchedule((prev) => ({
        ...prev,
        effective_from: effectiveDate.toString(),
      }));
    }
  }, [effectiveDate]);

  const setScheduleFrequency = (freq: FeeFrequency) => {
    setSchedule((prev) => ({ ...prev, fee_frequency: freq }));
  };

  const setAnnualRate = (rate: number) => {
    if (rate > 0.1 || rate < 0) {
      setError(t("fees.invalidRate"));
    } else {
      setError("");
    }
    setSchedule((prev) => ({
      ...prev,
      configuration: { annual_rate: rate },
    }));
  };

  const frequencyOptions: FeeFrequency[] = [
    "daily",
    "monthly",
    "quarterly",
    "yearly",
  ];

  return (
    <Modal open={open} setOpen={setOpen}>
      <Labels.H3>{t("fees.create")}</Labels.H3>
      <div className="mt-4">
        <div className="font-semibold">{t("fees.frequencyTitle")}</div>
        <select
          className="cursor-pointer rounded-xl border border-sb-gray-200 px-4 py-2 outline-none"
          onChange={(e) => setScheduleFrequency(e.target.value as FeeFrequency)}
        >
          {frequencyOptions.map((freq) => {
            return (
              <option value={freq} key={"option_" + freq}>
                {t(`fees.frequency.${freq}`)}
              </option>
            );
          })}
        </select>
      </div>
      <div className="mt-2 flex items-end justify-between gap-4">
        <div className="w-full">
          <div className="font-semibold">{t("fees.effectiveFrom")}</div>
          <CustomDatePicker
            minValue={parseDate(
              DateTime.now().plus({ days: 1 }).toFormat("yyyy-MM-dd"),
            )}
            value={effectiveDate}
            onChange={setEffectiveDate}
          >
            <div className="relative w-full rounded-xl border border-sb-gray-200 px-4 py-1.5 text-left">
              {effectiveDate?.toString() || t("fees.selectDate")}
              <div className="absolute right-2 top-1/2 -translate-y-1/2">
                <CalendarIcon className="aspect-square h-4 text-sb-gray-600" />
              </div>
            </div>
          </CustomDatePicker>
        </div>
        <div>
          <div className="font-semibold">{t("fees.annualRate")}</div>
          <input
            type="number"
            className="w-full rounded-xl border border-sb-gray-200 px-4 py-1.5 text-right"
            value={schedule.configuration.annual_rate}
            onChange={(e) => setAnnualRate(Number(e.target.value))}
          />
        </div>
      </div>
      {error && <div className="mt-2 text-center text-sb-red-700">{error}</div>}
      <div className="mt-8 flex gap-4">
        <Button label="fees.create" onClick={onCreate} loading={loading} />
        <Button
          disabled={loading}
          btnStyle="blank"
          label="global.cancel"
          onClick={() => setOpen(false)}
        />
      </div>
    </Modal>
  );
};
