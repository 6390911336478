import { Message } from "modules/chat/Message";
import { Response } from "modules/chat/Response";
import { PaperAirplane } from "modules/proposals/svg/PaperAirplane";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import {
  AIAssistantMessage,
  AIAssistantSocketMessage,
  AiAssistantInitialChatOption,
} from "shared/models/assistant/AIAssistantModel";
import { AssistantResponse } from "./AssistantResponse";
import ChevronRight from "shared/icons/ChevronRight";
import { compactMessages } from "./utils";
import { ComposedUserMessage } from "./ComposedUserMessage";
import { SystemResponse } from "./SystemResponse";
import { ArrowPath } from "shared/icons/ArrowPath";
import { InitialAssistantResponse } from "./responses/InitialAssistantResponse";
import { ProgressBar } from "./components/ProgressBar";
import { useNavigate } from "react-router-dom";

type Props = {
  socket?: WebSocket;
  className?: string;
  onPortfolioClicked?: (portfolioId: string) => Promise<any> | undefined;
  activePortfolioId?: string;
  onAllowInput?: () => void;
  onPostMessage?: () => void;
  compact?: boolean;
  onBackClicked?: () => void;
  sessionId: string;
};

export const AiAssistant: React.FC<Props> = ({
  socket,
  className,
  onPortfolioClicked,
  activePortfolioId,
  onAllowInput,
  onPostMessage,
  compact,
  onBackClicked,
  sessionId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [messages, setMessages] = useState<AIAssistantMessage[]>([]);
  const [compactedSystemMessages, setCompactedSystemMessages] = useState<
    AIAssistantMessage[]
  >([]);
  const [tempUserMessage, setTempUserMessage] = useState("");
  const [blockInput, setBlockInput] = useState(false);
  const [retryButton, setRetryButton] = useState(false);
  const [sessionConfig, setSessionConfig] =
    useState<AiAssistantInitialChatOption>();

  useEffect(() => {
    if (!blockInput) {
      onAllowInput?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockInput]);

  if (socket) {
    socket.onmessage = (event) => {
      const message = JSON.parse(event.data) as AIAssistantSocketMessage;
      const type = message.type;
      if (type === "configure") {
        setSessionConfig(message.payload.config);
      }

      if (type === "set_status") {
        const status = message.payload.status;
        if (status === "in_progress") {
          setBlockInput(true);
        } else if (status === "completed") {
          setBlockInput(false);
        } else if (
          status &&
          ["expired", "failed", "cancelled"].includes(status)
        ) {
          setRetryButton(true);
        }
      }
      if (type === "append_messages") {
        const messageIds = messages.map((m) => m.id);
        const deduplicatedMessages =
          message.payload.messages?.filter((m) => !messageIds.includes(m.id)) ||
          [];
        setMessages((prev) => [...prev, ...deduplicatedMessages]);
      }
    };
  }

  useEffect(() => {
    setTempUserMessage("");
    scrollLastMessageIntoView();
    setCompactedSystemMessages(compactMessages(messages));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  useEffect(() => {
    if (blockInput) {
      scrollLastMessageIntoView();
    }
  }, [blockInput]);

  useEffect(() => {
    if (tempUserMessage) {
      scrollLastMessageIntoView();
    }
  }, [tempUserMessage]);

  const postMessage = (message: string) => {
    scrollLastMessageIntoView();
    setTempUserMessage(message);
    socket?.send(
      JSON.stringify({ type: "add_message", payload: { content: message } }),
    );
    onPostMessage?.();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const input = e.target as HTMLInputElement;
      if (input.value) {
        postMessage(input.value);
        input.value = "";
      }
    }
  };

  const handleOnPortfolioClicked = async (portfolioId: string) => {
    onPortfolioClicked?.(portfolioId);
    socket?.send(
      JSON.stringify({
        type: "set_portfolio",
        payload: { portfolio_id: portfolioId },
      }),
    );
    navigate(`/portfolios/${portfolioId}`);
  };

  const handleClick = () => {
    const input = document.querySelector("#messageInput") as HTMLInputElement;
    if (input.value) {
      postMessage(input.value);
      input.value = "";
    }
  };

  const scrollLastMessageIntoView = () => {
    document.getElementById("lastAdvisorMessage")?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  const messagesComponent = (
    <>
      <div className="mb-2">
        <InitialAssistantResponse
          sessionConfig={sessionConfig}
          hide={messages.length > 0 || blockInput}
        />
      </div>

      {compactedSystemMessages?.map((item, idx) => {
        switch (item.role) {
          case "user":
            return (
              <ComposedUserMessage
                key={"user_msg_" + idx}
                content={item.content}
              />
            );
          case "assistant":
            return (
              <AssistantResponse
                key={"assistant_response_" + idx}
                content={item.content}
                messageId={item.id}
                sessionId={sessionId}
              />
            );
          case "system":
            return (
              <SystemResponse
                key={"assistant_response_" + idx}
                content={item.content}
                activePortfolioId={activePortfolioId}
                onPortfolioClicked={handleOnPortfolioClicked}
              />
            );
          default:
            return null;
        }
      })}

      {tempUserMessage && <Message text={tempUserMessage} />}
      {blockInput && (
        <>
          {messages[messages.length - 1]?.role === "system" &&
            messages[messages.length - 1]?.content?.[0].type === "text" && (
              <div className="my-4">
                <ProgressBar fake />
              </div>
            )}
          <Response text="" typing sbIcon />
        </>
      )}
      <div id="lastAdvisorMessage" />
    </>
  );

  const retry = () => {
    setRetryButton(false);
    socket?.send(JSON.stringify({ type: "retry" }));
  };

  if (compact) {
    return (
      <div className="flex h-full max-h-full flex-col justify-end ">
        <div
          className="relative flex cursor-pointer items-center gap-2 bg-black px-4 py-3 font-semibold text-white shadow-sm"
          onClick={onBackClicked}
        >
          <div className="absolute right-4 top-2 rounded-lg bg-sb-cyan-600 px-4 py-1 font-semibold text-white">
            BETA
          </div>
          <ChevronRight className="aspect-square w-4 rotate-180" />
          {t("global.back")}
        </div>
        <div className="flex flex-grow flex-col space-y-4 overflow-scroll px-4 py-4">
          {messagesComponent}
        </div>
        <div className="relative mx-4 my-2 text-base duration-300">
          <input
            id="messageInput"
            autoComplete="off"
            disabled={blockInput}
            type="text"
            placeholder="Type a message"
            className="w-full overflow-hidden rounded-full border border-sb-gray-200 py-2 pl-4 pr-12 outline-none"
            onKeyDown={handleKeyDown}
          />
          <div onClick={handleClick}>
            <PaperAirplane className="absolute right-4 top-1/2 h-4 w-4 -translate-y-1/2 text-sb-green-600" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <Panel className="!p-2.5">
      <Labels.H3 className="px-2 py-2">{t("aiAssistant.title")}</Labels.H3>
      <div
        className={`mt-4 ${
          className || "max-h-[600px] min-h-[600px]"
        } overflow-y-auto rounded-2xl border border-sb-gray-100 p-2`}
      >
        <div className="flex h-full min-h-[580px] flex-col justify-end space-y-4">
          {messagesComponent}
        </div>
      </div>
      <div className="relative pt-2 text-base duration-300">
        {retryButton && (
          <>
            <div className="my-4 text-center text-sb-red-600">
              {t("aiAssistant.requestFailed")}
            </div>
            <div className="flex justify-center">
              <div
                className="mb-4 flex w-fit cursor-pointer items-center gap-2 rounded-lg bg-sb-gray-100 px-4 py-2 duration-150 hover:bg-sb-gray-200"
                onClick={retry}
              >
                <ArrowPath className="aspect-square w-4" />
                {t("aiAssistant.retry")}
              </div>
            </div>
          </>
        )}

        <input
          id="messageInput"
          disabled={blockInput}
          type="text"
          placeholder="Type a message"
          className="w-full overflow-hidden rounded-full border border-sb-gray-200 py-2 pl-4 pr-12 outline-none"
          onKeyDown={handleKeyDown}
        />
        <div onClick={handleClick}>
          <PaperAirplane className="absolute right-4 top-1/2 mt-1 h-4 w-4 -translate-y-1/2 text-sb-green-600" />
        </div>
      </div>
    </Panel>
  );
};
