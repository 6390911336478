import { createAsyncThunk } from "@reduxjs/toolkit";
import { MarketService } from "shared/services/market/MarketService";

export const fetchMarketInfo = createAsyncThunk(
  "[MARKET_INFO] Fetch market info",
  async () => {
    const marketInfo = await MarketService.checkMarketOpen();
    return marketInfo;
  },
);

export const MarketInfoThunks = {
  fetchMarketInfo,
};
