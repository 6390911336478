type Props = {
  label: string;
  onChange?: (checked: boolean) => void;
  name: string;
};

export const Checkbox: React.FC<Props> = ({ label, onChange, name }) => {
  return (
    <div className="mb-4 flex items-center">
      <input
        id={name}
        type="checkbox"
        name={name}
        value={"on"}
        className="h-4 w-4 !cursor-default rounded border-sb-gray-200 bg-white text-sb-green-600 accent-sb-green-600 focus:ring-0 focus:ring-sb-green-500"
        onChange={(e) => onChange?.(e.target.checked)}
      />
      <label htmlFor={name} className="ml-2 text-base font-medium">
        {label}
      </label>
    </div>
  );
};
