import { colorsConfig } from "colors.config";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  Area,
  AreaChart,
} from "recharts";
import {
  XAxisTickProps,
  YAxisTickProps,
} from "shared/components/common/chart/Chart";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import { useAssetPrice } from "shared/hooks/useAssetPrice";
import { useDispatch } from "shared/hooks/useDispatch";
import useMarketInfo from "shared/hooks/useMarketInfo";
import { ArrowUp } from "shared/icons/ArrowUp";
import { BarChartIcon } from "shared/icons/BarChartIcon";
import { selectAssetChartData } from "shared/store/assets/asset_chart/AssetChartReducer";
import { AssetChartThunks } from "shared/store/assets/asset_chart/AssetChartThunks";
import { MarketInfoThunks } from "shared/store/market_info/MarketInfoThunks";
import { CurrencyFormat, pctFormat } from "shared/utils/currency";
import { formatDate, formatTime } from "shared/utils/date";
import { ChartRange } from "shared/utils/types";
import { CompactFormat } from "shared/utils/utils";
import {
  generateXTicks,
  generateYTicks,
  getDataMinMax,
  getFilledChartData,
} from "./assetChartUtils";
import { CustomCursor } from "./CustomCursor";

type Props = {
  assetId: string;
  height: number;
};

export const AssetChart: React.FC<Props> = ({ assetId, height }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [range, setRange] = useState<ChartRange>("date");
  const [hoverY, setHoverY] = useState<number>();

  const { marketInfo } = useMarketInfo();
  const isOpen = marketInfo?.is_open || false;

  const { latestPrice, previousClose, pnlPct, positive } = useAssetPrice(
    assetId ? assetId : "",
    range,
  );

  const chartData = useSelector(
    selectAssetChartData(assetId ? assetId : "", range),
  );

  const periods: { value: ChartRange; label: string }[] = useMemo(
    () => [
      { value: "max", label: t("assetChart.max") },
      { value: "5y", label: t("assetChart.fiveYears") },
      { value: "2y", label: t("assetChart.twoYears") },
      { value: "1y", label: t("assetChart.oneYear") },
      { value: "6m", label: t("assetChart.sixMonths") },
      { value: "3m", label: t("assetChart.threeMonths") },
      { value: "1m", label: t("assetChart.oneMonth") },
      { value: "5dm", label: t("assetChart.fiveDays") },
      { value: "date", label: t("assetChart.date") },
    ],
    [t],
  );

  const shouldFillChart = range === "date" && isOpen;

  const fetchAllCharts = useCallback(() => {
    if (assetId) {
      for (let range of periods.map((period) => period.value)) {
        dispatch(
          AssetChartThunks.fetchChartBySymbol({
            symbol: assetId,
            params: { range: range as ChartRange },
            isOpen,
          }),
        );
      }
    }
  }, [assetId, dispatch, isOpen, periods]);

  useEffect(() => {
    fetchAllCharts();
  }, [fetchAllCharts]);

  useEffect(() => {
    dispatch(MarketInfoThunks.fetchMarketInfo());
  }, [dispatch]);

  const yFormatter = CurrencyFormat.format;
  const xFormatter = (val: number, isTooltip: boolean) => {
    if (range === "date") {
      const timestampInMilliseconds = val * 1000;
      const dateTime = DateTime.fromMillis(timestampInMilliseconds);
      if (isTooltip) {
        return formatTime(dateTime.toISO() || "");
      }
      const formattedTime = dateTime.toFormat("h a").toLowerCase(); // 'ha' represents 12-hour format
      return formattedTime;
    } else {
      return formatDate(DateTime.fromSeconds(val).toISO() || "");
    }
  };

  return (
    <Panel className="w-full">
      <div className="flex items-center gap-4">
        <Labels.H1>{CurrencyFormat.format(latestPrice)}</Labels.H1>
        <div
          className={
            "flex items-center gap-2 text-xl font-bold " +
            (positive ? "text-sb-green-light" : "text-sb-red-600")
          }
        >
          <ArrowUp className={(positive ? "" : "rotate-180") + " h-6 w-6"} />
          <div>{pctFormat.format(pnlPct / 100)}</div>
        </div>
      </div>
      <div className="w-full py-4">
        <div className="relative w-full" style={{ height: height }}>
          <div className="absolute bottom-0 left-0 right-0 top-0">
            {chartData !== undefined ? (
              chartData.length ? (
                <ResponsiveContainer width="100%" height={height}>
                  <AreaChart
                    height={height}
                    data={
                      shouldFillChart
                        ? getFilledChartData(chartData, marketInfo)
                        : chartData
                    }
                    margin={{
                      top: 10,
                      bottom: 5,
                      left: 40,
                      right: 20,
                    }}
                    onMouseMove={(e) => {
                      const y = e.chartY as number;
                      setHoverY(y);
                    }}
                    onMouseLeave={() => {
                      setHoverY(undefined);
                    }}
                  >
                    <defs>
                      <linearGradient
                        id="chartGreenGradient"
                        x1="0"
                        y1="0"
                        y2="100%"
                        x2="0"
                      >
                        <stop
                          offset="50%"
                          stopColor={colorsConfig["sb-green"]["300"]}
                        />
                        <stop
                          offset="100%"
                          stopColor={colorsConfig["sb-green"]["100"]}
                        />
                      </linearGradient>
                      <linearGradient
                        id="chartRedGradient"
                        x1="0"
                        y1="0"
                        y2="100%"
                        x2="0"
                      >
                        <stop
                          offset="50%"
                          stopColor={colorsConfig["sb-red"]["300"]}
                        />
                        <stop
                          offset="100%"
                          stopColor={colorsConfig["sb-red"]["100"]}
                        />
                      </linearGradient>
                    </defs>
                    <XAxis
                      dataKey={"date"}
                      tickFormatter={(tick: number) => xFormatter(tick, false)}
                      ticks={generateXTicks(chartData, range)}
                      tick={({
                        index,
                        x,
                        y,
                        payload,
                        tickFormatter,
                      }: XAxisTickProps) => {
                        const formattedTick = tickFormatter
                          ? tickFormatter(payload.value, index)
                          : null;
                        return (
                          <g>
                            <text
                              x={x}
                              y={y}
                              textAnchor={index === 0 ? "start" : "middle"}
                              fontSize={12}
                              fontWeight="bold"
                            >
                              {formattedTick ? formattedTick : ""}
                            </text>
                          </g>
                        );
                      }}
                      tickLine={false}
                      tickMargin={25}
                      strokeWidth={0.5}
                    />
                    <YAxis
                      tickFormatter={(tick: number) =>
                        yFormatter
                          ? yFormatter(tick)
                          : CompactFormat.format(tick)
                      }
                      domain={() => {
                        const [min, max] = getDataMinMax(chartData);
                        return [min, max];
                      }}
                      interval={0}
                      ticks={generateYTicks(chartData)}
                      tick={({
                        index,
                        x,
                        y,
                        payload,
                        width,
                        tickFormatter,
                      }: YAxisTickProps) => {
                        const formattedTick = tickFormatter
                          ? tickFormatter(payload.value, index)
                          : null;
                        const isLastPrice =
                          parseFloat(payload.value) ===
                          chartData?.[chartData?.length - 1]?.["latestPrice"];
                        return (
                          <g z={100}>
                            {isLastPrice && (
                              <rect
                                z={100}
                                x={(x as number) - 9}
                                y={(y as number) - 9}
                                fill={
                                  positive
                                    ? colorsConfig["sb-green"]["600"]
                                    : colorsConfig["sb-red"]["600"]
                                }
                                width={(width as number) + 8}
                                height={18}
                              ></rect>
                            )}
                            <text
                              strokeWidth="0.5"
                              fontWeight={isLastPrice ? "bold" : "regular"}
                              orientation="right"
                              width="60"
                              height="260"
                              stroke="none"
                              x={x}
                              y={y}
                              textAnchor="start"
                              fill="#000001"
                            >
                              <tspan x={x} dy=".355em">
                                {formattedTick ? formattedTick : ""}
                              </tspan>
                            </text>
                          </g>
                        );
                      }}
                      tickLine={false}
                      strokeWidth={0.5}
                      orientation={"right"}
                    />
                    <Area
                      activeDot={false}
                      strokeWidth={2}
                      dataKey={"latestPrice"}
                      fill={
                        positive
                          ? "url(#chartGreenGradient)"
                          : "url(#chartRedGradient)"
                      }
                      stroke={
                        positive
                          ? colorsConfig["sb-green"]["600"]
                          : colorsConfig["sb-red"]["600"]
                      }
                      dot={false}
                      isAnimationActive={true}
                    />
                    <ReferenceLine y={previousClose} strokeDasharray="2 2" />
                    <Tooltip
                      isAnimationActive={false}
                      content={() => <></>}
                      cursor={
                        <CustomCursor
                          hoverY={hoverY}
                          chartData={chartData}
                          xFormatter={xFormatter}
                          yFormatter={yFormatter}
                        />
                      }
                    />
                  </AreaChart>
                </ResponsiveContainer>
              ) : (
                <div className="grid h-full place-items-center rounded-lg bg-sb-gray-100">
                  <Labels.G1>{t("assetChart.noData")}</Labels.G1>
                </div>
              )
            ) : (
              <div
                className="grid w-full animate-pulse place-items-center rounded-xl bg-sb-gray-100"
                style={{ height: height }}
              >
                <BarChartIcon className="h-24 w-24 fill-sb-gray-300 stroke-sb-gray-300" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mx-10 mt-8 flex items-center gap-4">
        {periods.map((item, idx) => {
          return (
            <div
              key={"period_" + idx}
              className={
                "rounded-selector cursor-pointer whitespace-nowrap hover:border-sb-green-700 hover:bg-sb-green-400 " +
                (range === item.value
                  ? "border-sb-green-700 bg-sb-green-500"
                  : "border-sb-gray-200 bg-sb-gray-100")
              }
              onClick={() => setRange(item.value)}
            >
              {item.label}
            </div>
          );
        })}
      </div>
    </Panel>
  );
};
