import { EditAllocations } from "modules/portfolio_edit_allocations/EditAlllocations";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BasePage } from "shared/components/common/BasePage";
import { Labels } from "shared/components/hoc/Labels";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import { selectPortfolioById } from "shared/store/portfolios/PortfoliosReducer";
import { PortfoliosThunks } from "shared/store/portfolios/PortfoliosThunks";

// TODO remove this when we have portfolio assistant and new edit
export const EditPortfolioPage = () => {
  const { portfolioId } = useParams();
  const portfolio = useSelector(selectPortfolioById(portfolioId));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (!portfolio && portfolioId) {
      dispatch(PortfoliosThunks.fetchOne(portfolioId));
    }
  }, [dispatch, portfolio, portfolioId]);

  if (!portfolio) {
    return null;
  }

  return (
    <BasePage>
      <Labels.H1>{t("faPortfolio.editAllocation")}</Labels.H1>
      <EditAllocations portfolio={portfolio} />
    </BasePage>
  );
};
