import { useTranslation } from "react-i18next";

type Props = {
  filter: string;
  setFilter: (s: string) => void;
  clearSelectedClients: () => void;
  selectAllClients: () => void;
};
export const SearchClients: React.FC<Props> = ({
  filter,
  setFilter,
  clearSelectedClients,
  selectAllClients,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <input
          type="text"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          placeholder={t("global.search")}
          className="w-full rounded-lg border border-sb-gray-200 px-2 py-2 outline-none"
        />
      </div>
      <div className="mt-2 flex w-full items-center justify-between text-sm text-sb-gray-500">
        <div className="cursor-pointer underline" onClick={selectAllClients}>
          {t("global.selectAll")}
        </div>
        <div
          className="cursor-pointer underline"
          onClick={clearSelectedClients}
        >
          {t("global.clear")}
        </div>
      </div>
    </>
  );
};
