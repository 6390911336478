import { HistoryIcon } from "pages/fa/clients/svg/HistoryIcon";
import { useTranslation } from "react-i18next";
import useAssets from "shared/hooks/useAssets";
import { useSelector } from "shared/hooks/useDispatch";
import { AssetWrapper } from "shared/models/asset/AssetModel";
import { selectSearchHistory } from "shared/store/app/AppReducer";

type Props = {
  onClick?: (result: AssetWrapper) => void;
};

export const RecentSearches: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();
  const searchHistory = useSelector(selectSearchHistory());
  const { assets_map } = useAssets();

  return (
    <div>
      <div className="mb-2 flex items-center gap-2">
        <div className="text-lg font-medium">{t("globalSearch.recent")}</div>
        <HistoryIcon className="aspect-square w-4 text-sb-gray-500" />
      </div>
      {[...searchHistory]
        .sort((a, b) => b.timestamp - a.timestamp)
        .map((item, idx) => {
          return (
            <div
              key={"recent_search_" + idx}
              className="group flex cursor-pointer items-baseline gap-2"
              onClick={() => onClick?.(assets_map[item.symbol])}
            >
              <div className="text-lg font-medium">{item.symbol}</div>
              <div className="text-sb-gray-500 group-hover:underline">
                {item.name}
              </div>
            </div>
          );
        })}
    </div>
  );
};
