import { useEffect } from "react";
import useAssets from "shared/hooks/useAssets";
import { useDispatch } from "shared/hooks/useDispatch";
import { AssetThunks } from "shared/store/assets/AssetsThunks";
import { auth } from "shared/utils/firebase";

export const AssetInfoFetcher: React.FC = () => {
  const dispatch = useDispatch();
  const { assets } = useAssets();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        if (!assets?.length) {
          dispatch(AssetThunks.fetchAssets());
        }
      }
    });

    return () => unsubscribe();
  }, []);

  return null;
};
