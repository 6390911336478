import { XIcon } from "pages/fa/clients/svg/XIcon";
import { PlusCircle } from "shared/icons/PlusCircle";

type Props = {
  checked: boolean;
  text: string;
  onChange: (checked: boolean) => void;
};

export const ValueToggle: React.FC<Props> = ({ text, onChange, checked }) => {
  return (
    <div
      className={
        "flex w-fit cursor-pointer items-center gap-2 rounded-full border border-sb-gray-400 px-3 py-1.5 duration-150 " +
        (checked
          ? "border-black bg-black text-white"
          : "bg-sb-gray-100 hover:bg-sb-gray-800 hover:text-white")
      }
      onClick={() => onChange(!checked)}
    >
      {text}
      {checked ? (
        <XIcon className="h-4 w-4 text-sb-green-600" />
      ) : (
        <PlusCircle className="h-5 w-5 text-sb-gray-500" />
      )}
    </div>
  );
};
