type Props = {
  className?: string;
};

export const SquareWarningIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.6661 15.3328H15.3328V7.33281H12.6661V15.3328ZM13.9995 19.3328C14.3773 19.3328 14.6941 19.2048 14.9501 18.9488C15.2053 18.6937 15.3328 18.3773 15.3328 17.9995C15.3328 17.6217 15.2053 17.3048 14.9501 17.0488C14.6941 16.7937 14.3773 16.6661 13.9995 16.6661C13.6217 16.6661 13.3053 16.7937 13.0501 17.0488C12.7941 17.3048 12.6661 17.6217 12.6661 17.9995C12.6661 18.3773 12.7941 18.6937 13.0501 18.9488C13.3053 19.2048 13.6217 19.3328 13.9995 19.3328ZM15.8995 26.5995C15.3884 27.1106 14.755 27.3661 13.9995 27.3661C13.2439 27.3661 12.6106 27.1106 12.0995 26.5995L1.39948 15.8995C0.888368 15.3884 0.632812 14.755 0.632812 13.9995C0.632812 13.2439 0.888368 12.6106 1.39948 12.0995L12.0995 1.39948C12.6106 0.888368 13.2439 0.632812 13.9995 0.632812C14.755 0.632812 15.3884 0.888368 15.8995 1.39948L26.5995 12.0995C27.1106 12.6106 27.3661 13.2439 27.3661 13.9995C27.3661 14.755 27.1106 15.3884 26.5995 15.8995L15.8995 26.5995Z"
        fill="currentColor"
      />
    </svg>
  );
};
