import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Watchlist } from "shared/models/watchlist/WatchlistModel";
import { WatchlistThunks } from "./WatchlistThunks";
import { Loading } from "shared/types/enums";

interface WatchlistState {
  watchlists: Watchlist[];
  loading: Loading;
}

const watchlistAdapter = createEntityAdapter<Watchlist>({
  selectId: (watchlist) => watchlist.id,
});

const initialState = watchlistAdapter.getInitialState<WatchlistState>({
  watchlists: [],
  loading: Loading.Idle,
});

const WatchlistSlices = createSlice({
  name: "[WATCHLIST]",
  initialState,
  reducers: {
    reset(state) {
      watchlistAdapter.removeAll(state);
    },
    updateWatchlist(state, action) {
      watchlistAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload.changes,
      });
    },
    loadingFinished(state) {
      state.loading = Loading.Finished;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(WatchlistThunks.fetchWatchlists.pending, (state) => {
      state.loading = Loading.InProgress;
    });
    builder.addCase(
      WatchlistThunks.fetchWatchlists.fulfilled,
      (state, action) => {
        watchlistAdapter.setAll(state, action.payload);
      },
    );
    builder.addCase(WatchlistThunks.remove.fulfilled, (state, action) => {
      watchlistAdapter.removeOne(state, action.payload);
    });
  },
});

const { selectAll, selectById } = watchlistAdapter.getSelectors(
  (state: RootState) => state.watchlist,
);

export const selectWatchlists = () =>
  createSelector(
    (state: RootState) => state.watchlist,
    selectAll,
    ({ loading }, watchlists) => ({
      loading,
      watchlists: watchlists.sort(
        (a, b) => Date.parse(b.created_at) - Date.parse(a.created_at),
      ),
    }),
  );

export const WatchlistActions = { ...WatchlistSlices.actions };

export default WatchlistSlices.reducer;
