import { AumChart } from "shared/models/account/AumChart";
import { fetch } from "./AggregatedAumThunks";
import { RootState } from "shared/store/store";
import { Loading } from "shared/types/enums";

import { createSlice, SerializedError } from "@reduxjs/toolkit";

interface AggregatedAumReducer {
  chartData: AumChart | null | undefined;
  loading: Loading;
  error?: SerializedError;
}

const initialState: AggregatedAumReducer = {
  chartData: undefined,
  loading: Loading.Idle,
};

const AggregatedAumSlice = createSlice({
  name: "[AGGREGATED_AUM]",
  initialState,
  reducers: {
    reset(state) {
      state.chartData = initialState.chartData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.loading = Loading.InProgress;
    });
    builder.addCase(fetch.rejected, (state, action) => {
      state.loading = Loading.Finished;
      state.error = action.error;
      return state;
    });
    builder.addCase(fetch.fulfilled, (state, action) => {
      state.loading = Loading.Finished;
      state.chartData = action.payload;
      delete state.error;
      return state;
    });
  },
});

export const selectAggregatedAum = () => (state: RootState) =>
  state.aggregatedAum;

const AggregatedAumActions = { ...AggregatedAumSlice.actions };

export default AggregatedAumSlice.reducer;
