import { t } from "i18next";
import { PropsWithChildren } from "react";
import { PageContainer } from "shared/components/common/PageContainer";
import { EvivaLogoContent } from "shared/icons/EvivaLogo";

export const WelcomeContainer: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <PageContainer className="h-full min-w-full bg-white">
      <div className="grid h-full place-items-center">
        <div className="flex w-full items-center justify-center rounded-xl">
          <div className="text-lg">
            <EvivaLogoContent className="m-auto mb-7 w-44 shrink-0" />
            <div className="mb-8 mt-2 text-center">{t("welcome.title")}</div>
            {children}
          </div>
        </div>
      </div>
    </PageContainer>
  );
};
