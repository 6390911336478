import { useTranslation } from "react-i18next";
import useRealtimeTicker from "shared/hooks/useRealtimeTicker";
import { News } from "shared/models/news/NewsModel";
import { pctFormat } from "shared/utils/currency";
import { formatRelativeDate } from "shared/utils/date";

type Props = {
  news: News;
  assetId?: string;
};

export const Article: React.FC<Props> = ({ news, assetId }) => {
  const { i18n } = useTranslation();
  const { latestPrice, previousClose } = useRealtimeTicker(
    assetId ? assetId : "",
  );

  const pnl = latestPrice - previousClose;
  const pnlPct = pnl / previousClose;
  const positive = pnl >= 0;

  return (
    <div>
      <a href={news.url} target="_blank" rel="noreferrer">
        <div className="flex cursor-pointer gap-4">
          <div className="flex flex-col">
            <div className="text-xl">{news.headline}</div>
            <div className="font-semibold">
              {assetId}{" "}
              <span
                className={positive ? "text-sb-green-700" : "text-sb-red-700"}
              >
                {pctFormat.format(pnlPct)}
              </span>
            </div>
            <div className="text-base text-sb-gray-500">
              {news.author} -{" "}
              {formatRelativeDate(news.created_at, i18n.language)}
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};
