import { parseDate } from "@internationalized/date";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { DateValue } from "react-aria";
import { useTranslation } from "react-i18next";
import { CustomDatePicker } from "shared/components/common/daterangepicker/CustomDateRangePicker";
import { OptionsInput } from "shared/components/controls/OptionsInput";
import { PercentageInput } from "shared/components/controls/PercentageInput";
import { CalendarIcon } from "shared/icons/Calendar";
import { FeeFrequency } from "shared/models/fees/FeesModel";

interface Props {
  submitting: boolean;
  onEffectiveDateChange: (d: string) => void;
}

export const ContactFeesInputs: React.FC<Props> = ({
  submitting,
  onEffectiveDateChange,
}) => {
  const { t } = useTranslation();
  const [effectiveDate, setEffectiveDate] = useState<DateValue>(
    parseDate(DateTime.now().plus({ days: 1 }).toFormat("yyyy-MM-dd")),
  );

  const frequencyOptions: FeeFrequency[] = ["monthly", "quarterly", "yearly"];

  const options = frequencyOptions.map((f) => {
    return {
      value: f,
      label: t(`fees.frequency.${f}`),
    };
  });

  useEffect(() => {
    if (effectiveDate) {
      onEffectiveDateChange?.(
        DateTime.fromISO(effectiveDate.toString()).toFormat("yyyy-MM-dd"),
      );
    }
  }, [effectiveDate]);

  return (
    <div>
      <PercentageInput label="fees.annualRate" name="annual_rate" required />
      <OptionsInput
        label="fees.frequencyTitle"
        name="fee_frequency"
        options={options}
      />
      <CustomDatePicker
        minValue={parseDate(
          DateTime.now().plus({ days: 1 }).toFormat("yyyy-MM-dd"),
        )}
        value={effectiveDate}
        onChange={setEffectiveDate}
      >
        <div className="relative">
          <div className="peer mb-4 block w-full appearance-none rounded-xl border-0 bg-sb-gray-100 px-2.5 pb-2.5 pt-5 text-left text-base text-gray-900 focus:border-sb-green-700 focus:outline-none focus:ring-0">
            <label
              className={`absolute left-2.5 top-4  z-10 origin-[0] -translate-y-4 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-sb-green-700`}
            >
              Effective from
            </label>
            {effectiveDate?.toString() || t("fees.selectDate")}
            <div className="absolute right-2 top-1/2 -translate-y-1/2">
              <CalendarIcon className="aspect-square h-4 text-sb-gray-600" />
            </div>
          </div>
        </div>
      </CustomDatePicker>
    </div>
  );
};
