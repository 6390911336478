import RestService from "shared/api/RestService";
import {
  FeeAccruedChartByDayPoint,
  FeeAccruedChartPoint,
  FeePayoutTerm,
  FinancialAdvisorFee,
  FinancialAdvisorFeeSchedule,
  FinancialAdvisorFeeScheduleDaily,
  FinancialAdvisorFeeStats,
  FinancialAdvisorFeeStatus,
  Payout,
  PayoutsByDay,
  PayoutStatus,
} from "shared/models/fees/FeesModel";
import { FaApiRoutes } from "shared/utils/routes";
import { ISODate, ISOTimestamp } from "shared/utils/types";

export type PayoutQuery = {
  statuses?: PayoutStatus[];
  updated_date_from?: ISOTimestamp;
  updated_date_to?: ISOTimestamp;
  amount_from?: number;
  amount_to?: number;
};

export type FeeQuery = {
  updated_date_from?: ISOTimestamp;
  updated_date_to?: ISOTimestamp;
  client_ids?: string[];
  amount_from?: number;
  amount_to?: number;
  statuses?: FinancialAdvisorFeeStatus[];
  scheduled_charge_date_from?: ISODate;
  scheduled_charge_date_to?: ISODate;
  collected_date_from?: ISODate;
  collected_date_to?: ISODate;
  payout_date_from?: ISODate;
  payout_date_to?: ISODate;
};

type FeeAccruedChartByDayParams = {
  start?: ISOTimestamp;
  end?: ISOTimestamp;
};

type FeAccruedChartParams = FeeAccruedChartByDayParams & {
  client_ids?: string[];
};

export const FeesService = {
  async getClientOpenFees(clientId: string): Promise<FinancialAdvisorFee[]> {
    return RestService.get(FaApiRoutes.Advisor.Fees.Details(clientId).Path);
  },

  async getClientFeeSchedules(
    clientId: string,
  ): Promise<FinancialAdvisorFee[]> {
    return RestService.get(FaApiRoutes.Advisor.Fees.Details(clientId).Schedule);
  },

  async createFeeSchedule(
    clientId: string,
    schedule: FinancialAdvisorFeeSchedule,
  ) {
    return RestService.post(
      FaApiRoutes.Advisor.Fees.Details(clientId).Schedule,
      schedule,
    );
  },

  async getStats(
    start?: ISODate,
    end?: ISODate,
  ): Promise<FinancialAdvisorFeeStats> {
    return RestService.get(FaApiRoutes.Advisor.Fees.Stats, {
      start,
      end,
    });
  },

  async getDailySchedule(
    clientId: string,
    start: ISODate,
    end: ISODate,
  ): Promise<FinancialAdvisorFeeScheduleDaily[]> {
    return RestService.get(
      FaApiRoutes.Advisor.Fees.Details(clientId).ScheduleDaily,
      {
        schedule_on_date_from: start,
        schedule_on_date_to: end,
      },
    );
  },

  async getFees(query?: FeeQuery): Promise<FinancialAdvisorFee[]> {
    return RestService.get(FaApiRoutes.Advisor.Fees.Search, query);
  },

  async getPayoutsByDay(
    updated_date_from?: ISODate,
    updated_date_to?: ISODate,
  ): Promise<PayoutsByDay> {
    return RestService.get(FaApiRoutes.Advisor.Fees.PayoutsByDay, {
      updated_date_from,
      updated_date_to,
    });
  },

  async getAccruedChartByDay(
    params?: FeeAccruedChartByDayParams,
  ): Promise<FeeAccruedChartByDayPoint[]> {
    return RestService.get(FaApiRoutes.Advisor.Fees.Accrued.ByDay, params);
  },

  async getAccruedChart(
    params?: FeAccruedChartParams,
  ): Promise<FeeAccruedChartPoint[]> {
    const result = await RestService.get<{ chart: FeeAccruedChartPoint[] }>(
      FaApiRoutes.Advisor.Fees.Accrued.Chart,
      params,
    );
    return result.chart;
  },

  async getPayoutTerms(): Promise<FeePayoutTerm> {
    return RestService.get(FaApiRoutes.Advisor.Fees.PayoutTerms);
  },

  async getPayouts(params?: PayoutQuery): Promise<Payout[]> {
    return RestService.get(FaApiRoutes.Advisor.Fees.Payouts.Search, params);
  },
};
