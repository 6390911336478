import React from "react";
import { PhoneNumberInput } from "shared/components/controls/PhoneNumberInput";
import { TextInput } from "shared/components/controls/TextInput";

interface Props {
  submitting: boolean;
}

export const ContactDetailsInputs: React.FC<Props> = ({ submitting }) => {
  return (
    <div>
      <TextInput label="global.form.firstName" name="first_name" required />
      <TextInput label="global.form.lastName" name="last_name" required />
      <TextInput label="global.form.email" name="email" type="email" required />
      <PhoneNumberInput required={true} />
    </div>
  );
};
