import { ClientFeeSchedule } from "modules/client_fees/ClientFeeSchedule";
import { ClientFeeScheduleDaily } from "modules/client_fees/ClientFeeScheduleDaily";
import { CreateFeeModal } from "modules/create_fee/CreateFeeModal";
import { useState } from "react";
import { Button } from "shared/components/controls/Button";
import { Layouts } from "shared/components/hoc/Layouts";
import { PlusIcon } from "shared/icons/PlusIcon";

type Props = {
  clientId?: string;
};

export const ClientFeeSchedules: React.FC<Props> = ({ clientId }) => {
  const [openModal, setOpenMopdal] = useState(false);

  return (
    <Layouts.Spaced>
      {clientId && (
        <CreateFeeModal
          open={openModal}
          setOpen={setOpenMopdal}
          clientId={clientId}
        />
      )}
      <div>
        <Button
          label="fees.create"
          leftIcon={<PlusIcon className="aspect-square w-4" />}
          onClick={() => setOpenMopdal(true)}
        />
      </div>
      <ClientFeeSchedule clientId={clientId} />
      <ClientFeeScheduleDaily clientId={clientId} />
    </Layouts.Spaced>
  );
};
