import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AvatarImage } from "shared/components/common/AvatarImage";
import { Modal, ModalScroll } from "shared/components/common/Modal";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import { Proposal } from "shared/models/proposals/ProposalsModel";
import { selectProposals } from "shared/store/proposals/ProposalsReducer";
import { ProposalsThunks } from "shared/store/proposals/ProposalsThunks";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClick?: (proposal: Proposal) => void;
};

export const SelectProposalModal: React.FC<Props> = ({
  open,
  setOpen,
  onClick,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { proposals } = useSelector(selectProposals);
  const [selectedProposal, setSelectedProposal] = useState<Proposal>();

  useEffect(() => {
    if (!proposals) {
      dispatch(ProposalsThunks.fetchMany());
    }
  }, [dispatch, proposals]);

  return (
    <Modal open={open} setOpen={setOpen}>
      <Labels.H3 className="mb-4">{t("proposals.selectProposal")}</Labels.H3>
      <div className="my-8">
        <ModalScroll className="space-y-4 px-2">
          {proposals?.map((proposal, idx) => {
            return (
              <div
                key={"proposal_" + idx}
                className={
                  "w-full cursor-pointer rounded-xl border p-4 duration-150 " +
                  (selectedProposal?.proposal_id === proposal.proposal_id
                    ? "border-sb-green-500 bg-sb-green-100"
                    : "border-sb-gray-300 hover:bg-sb-gray-100")
                }
                onClick={() => setSelectedProposal(proposal)}
              >
                <div className="flex items-center gap-4">
                  <AvatarImage
                    className="shrink-0"
                    imageSrc={proposal.strategy_cover?.uri}
                    width={40}
                    height={40}
                  />
                  <div>
                    <div className="text-sb-gray-500">
                      {proposal.strategy_symbol}
                    </div>
                    <div className="font-semibold">
                      {proposal.strategy_title}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </ModalScroll>
      </div>
      <div className="flex items-center justify-center gap-8">
        <Button
          label="global.select"
          onClick={() => selectedProposal && onClick?.(selectedProposal)}
        />
        <Button
          label="global.cancel"
          btnStyle="blank"
          onClick={() => setOpen(false)}
        />
      </div>
    </Modal>
  );
};
