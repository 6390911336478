import { useEffect } from "react";
import { selectAssetInfo } from "shared/store/assets/asset_info/AssetInfoReducer";
import { AssetInfoThunks } from "shared/store/assets/asset_info/AssetInfoThunks";
import { selectAssetStats } from "shared/store/assets/asset_stats/AssetStatsReducer";
import { AssetStatsThunks } from "shared/store/assets/asset_stats/AssetStatsThunks";
import { useDispatch, useSelector } from "./useDispatch";

export default function useAssetsExtras(symbol: string) {
  const dispatch = useDispatch();

  const extras = useSelector(selectAssetStats(symbol));
  const { info } = useSelector(selectAssetInfo(symbol));

  const hasAnyInfo =
    info?.description ||
    info?.industry ||
    info?.website ||
    info?.CEO ||
    info?.sector ||
    info?.tags?.length;

  useEffect(() => {
    dispatch(AssetStatsThunks.fetchAssetStats(symbol));
    dispatch(AssetInfoThunks.fetchInfoBySymbol(symbol));
  }, [dispatch, symbol]);

  return {
    stats: extras.stats,
    earnings: extras.earnings,
    info: info,
    hasAnyInfo,
  };
}
