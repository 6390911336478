import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "shared/components/controls/Button";
import DropdownSelect from "shared/components/controls/DropdownSelect";
import { Inputs } from "shared/components/hoc/Inputs";
import { Labels } from "shared/components/hoc/Labels";
import { Layouts } from "shared/components/hoc/Layouts";
import { useUploadDocuments } from "shared/hooks/useUploadDocuments";
import CheckIcon from "shared/icons/CheckIcon";
import { BrokerageAccountIndeterminate } from "shared/models/brokerage_account/BrokerageAccountEventModel";
import { BrokerageAccountDocument } from "shared/models/brokerage_account/BrokerageAccountModel";
import { DocumentType } from "shared/models/brokerage_account/BrokerageAccountModel";

interface Props {
  indeterminateKey?: keyof BrokerageAccountIndeterminate;
  message?: string;
}

const MAP: Record<keyof BrokerageAccountIndeterminate, DocumentType> = {
  IDENTITY_VERIFICATION: "identity_verification",
  TAX_IDENTIFICATION: "tax_id_verification",
  ADDRESS_VERIFICATION: "address_verification",
  DATE_OF_BIRTH: "date_of_birth_verification",
  DATE_OF_BIRTH_INTERNATIONAL: "date_of_birth_verification",
  PEP: "account_approval_letter",
  FAMILY_MEMBER_PEP: "account_approval_letter",
  CONTROL_PERSON: "account_approval_letter",
  AFFILIATED: "account_approval_letter",
  OTHER: "identity_verification",
  COUNTRY_NOT_SUPPORTED: "identity_verification",
  INVALID_IDENTITY_PASSPORT: "identity_verification",
  WATCHLIST_HIT: "identity_verification",
  W8BEN_CORRECTION: "w8ben",
  SELFIE_VERIFICATION: "identity_verification",
  OTHER_PARTNER: "identity_verification",
};

export const UploadDocumentInput: React.FC<Props> = ({
  indeterminateKey,
  message,
}) => {
  const { t } = useTranslation();

  const [document, setDocument] = useState<BrokerageAccountDocument>({
    mime_type: "image/jpeg",
    document_type:
      (indeterminateKey && MAP[indeterminateKey]) || "identity_verification",
  });
  const { upload, uploading, DocumentTypes } = useUploadDocuments();

  if (uploading === "finished") {
    return (
      <Layouts.Grid>
        {message ? <Labels.B1>{message}</Labels.B1> : null}
        <Layouts.Row>
          <Labels.B1>{t("documents_uploaded")}</Labels.B1>
          <CheckIcon />
        </Layouts.Row>
      </Layouts.Grid>
    );
  }

  return (
    <div className="mt-8">
      <Layouts.Grid>
        {message ? <Labels.B1>{message}</Labels.B1> : null}
        <Inputs.File
          label="documents.options.file"
          onAttach={(_, base64, mimeType) => {
            setDocument({ ...document, content: base64, mime_type: mimeType });
          }}
        />
        <DropdownSelect
          className="rounded-xl border border-sb-gray-100 py-4"
          inputClassName="px-4"
          selected={document.document_type}
          onSelect={(type) => {
            setDocument({
              ...document,
              document_type: type.value as DocumentType,
            });
          }}
          options={DocumentTypes}
        />
        <Button
          className="w-fit"
          label="forms.kyc.submit"
          loading={uploading === "processing"}
          onClick={() => upload(document)}
        />
      </Layouts.Grid>
    </div>
  );
};
