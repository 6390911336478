import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { PortfolioPerformanceChart } from "shared/models/portfolio/PortfolioModel";

import { fetch } from "shared/store/backtesting_portfolio_charts/BacktestingPortfolioChartsThunks";

interface BacktestingPortfolioChartsState {
  cache: { [id: string]: { [period: string]: PortfolioPerformanceChart } };
}

const initialState: BacktestingPortfolioChartsState = {
  cache: {},
};

const BacktestingPortfolioChartsSlice = createSlice({
  name: "[BACKTESTING_PORTFOLIOS_CHARTS]",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetch.fulfilled, (state, { payload }) => {
      const { id, period, data } = payload;
      if (!state.cache[id]) state.cache[id] = {};
      state.cache[id][period] = data;
    });
  },
});

export const selectBacktestingPortfolioChart = (id?: string) =>
  createSelector(
    (state: RootState) => state,
    (state) => {
      return id ? state.backtestingPortfolioCharts.cache[id] : undefined;
    },
  );

const backtestingPortfolioChartsActions = {
  ...BacktestingPortfolioChartsSlice.actions,
};

export default BacktestingPortfolioChartsSlice.reducer;
