import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  url: string;
  label?: string;
  text?: string;
  className?: string;
}

export const TermsLink: React.FC<Props> = ({ className, label, url, text }) => {
  const { t } = useTranslation();
  return (
    <a
      href={url}
      className={`block text-base underline ${className}`}
      target="_blank"
      rel="noreferrer"
    >
      {label ? t(label) : null}
      {text}
    </a>
  );
};
