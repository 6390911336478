import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AvatarImage } from "shared/components/common/AvatarImage";
import { Labels } from "shared/components/hoc/Labels";
import { ArrowUp } from "shared/icons/ArrowUp";
import { Portfolio } from "shared/models/portfolio/PortfolioModel";
import { CurrencyFormat, pctFormat } from "shared/utils/currency";

type Props = {
  portfolio: Portfolio;
  clientId?: string;
};

export const PortfolioSubscription: React.FC<Props> = ({
  portfolio,
  clientId,
}) => {
  const { t } = useTranslation();

  if (!portfolio?.subscription) {
    return null;
  }

  return (
    <div>
      <Link
        to={`${clientId ? "/clients/" + clientId : ""}/portfolios/${
          portfolio.id
        }`}
      >
        <div className="flex cursor-pointer justify-between gap-8 rounded-3xl border border-sb-gray-200 p-4 duration-150 hover:bg-sb-gray-100">
          <div className="flex w-full justify-between gap-4">
            <div>
              <div className="flex items-center gap-4">
                <AvatarImage
                  imageSrc={portfolio?.cover?.uri}
                  width={40}
                  height={40}
                  className="shrink-0"
                />
                <div>
                  <Labels.H3>{portfolio.title}</Labels.H3>
                  <div className="-mt-2 flex items-center gap-1 text-sb-gray-500">
                    <div
                      className={
                        "flex items-center gap-1 " +
                        (portfolio.subscription.total_return >= 0
                          ? "text-sb-green-700"
                          : "text-sb-red-700")
                      }
                    >
                      <ArrowUp
                        className={
                          "aspect-square w-4 " +
                          (portfolio.subscription.total_return < 0
                            ? " rotate-180"
                            : "")
                        }
                      />
                      <div className="font-medium">
                        {CurrencyFormat.format(
                          portfolio.subscription.total_return,
                        )}{" "}
                        (
                        {pctFormat.format(
                          portfolio.subscription.total_return_pct,
                        )}
                        )
                      </div>
                    </div>
                    {t("positions.totalReturn")}
                  </div>
                </div>
              </div>
              <div className="mt-4">{portfolio.description}</div>
            </div>
            <div>
              <div className="flex items-center gap-2">
                <div className="text-lg text-sb-gray-500">
                  {t("clientPortfolio.invested")}
                </div>
                <div className="text-xl font-semibold">
                  {CurrencyFormat.format(portfolio.subscription.equity)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
