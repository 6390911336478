import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./styles.css";

type Props = {
  onChange?: (checked: boolean) => void;
  name: string;
};

export const YesNoSwitch: React.FC<Props> = ({ onChange, name }) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (onChange) {
      onChange(checked);
    }
  }, [checked, onChange]);

  return (
    <>
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={() => {}}
        className="invisible absolute left-0 top-0"
      />
      <div className="yesno-switch-wrapper flex justify-stretch">
        <div
          className={"yesno-switch " + (checked ? "active" : "inactive")}
          onClick={() => setChecked(true)}
        >
          {t("feeTemplateForm.yes")}
        </div>
        <div
          className={"yesno-switch " + (checked ? "inactive" : "active")}
          onClick={() => setChecked(false)}
        >
          {t("feeTemplateForm.no")}
        </div>
      </div>
    </>
  );
};
