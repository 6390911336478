import { createSelector, createSlice } from "@reduxjs/toolkit";
import { OnboardingPart } from "pages/fa/onboarding/OnboardingFlow";
import { Onboarding } from "shared/models/onboarding/OnboardingModel";
import { getKYCRequirements } from "shared/utils/kyc_utils";
import { RootState } from "../store";
import { fetchAvailableCountries } from "./OnboardingThunks";

enum OnboardingStatusEnum {
  NotStarted = "NOT_STARTED",
  Started = "STARTED",
  Finished = "FINISHED",
}

interface IOnboardingState {
  status: OnboardingStatusEnum;
  data: Onboarding;
  availableCountries: string[];
  filledSteps: (keyof Onboarding)[];
  activeOnboardingPart?: OnboardingPart;
  phoneNumber?: string;
  advisorRegistrationNumber: string;
}

const initialState: IOnboardingState = {
  status: OnboardingStatusEnum.NotStarted,
  data: {},
  availableCountries: [],
  filledSteps: [],
  activeOnboardingPart: "tax_country",
  advisorRegistrationNumber: "",
} as IOnboardingState;

const OnboardingSlice = createSlice({
  name: "[ONBOARDING]",
  initialState,
  reducers: {
    reset: (state) => {
      state.status = OnboardingStatusEnum.NotStarted;
      state.data = {};
      state.filledSteps = [];
      return state;
    },
    clearOnboarding: () => initialState,
    updateStatus: (
      state,
      { payload: status }: { payload: OnboardingStatusEnum },
    ) => {
      state.status = status;
      return state;
    },
    update: (state, { payload: updates }: { payload: Onboarding }) => {
      return {
        ...state,
        data: {
          ...state.data,
          ...updates,
        },
      };
    },
    updateFilledSteps: (
      state,
      { payload: filledStep }: { payload: keyof Onboarding },
    ) => {
      state.filledSteps = [...state.filledSteps, filledStep];
      return state;
    },
    updateOnboardingPart: (state, { payload }: { payload: OnboardingPart }) => {
      state.activeOnboardingPart = payload;
      return state;
    },
    updatePhoneNumber: (state, { payload }: { payload: string }) => {
      state.phoneNumber = payload;
      return state;
    },
    updateAdvisorRegistrationNumber: (
      state,
      { payload }: { payload: string },
    ) => {
      state.advisorRegistrationNumber = payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAvailableCountries.fulfilled, (state, action) => {
      state.availableCountries = action.payload;
    });
  },
});

export const selectOnboarding = (state: RootState) => state.onboarding.data;

export const selectPhoneNumber = (state: RootState) =>
  state.onboarding.phoneNumber;

export const selectAdvisorRegistrationNumber = (state: RootState) =>
  state.onboarding.advisorRegistrationNumber;

export const selectActiveOnboardingPart = (state: RootState) =>
  state.onboarding.activeOnboardingPart;

export const selectAvailableCountries = (state: RootState) =>
  state.onboarding.availableCountries;
const selectOnboardingRequirements = createSelector(
  selectOnboarding,
  (data) => getKYCRequirements(data),
);

export const OnboardingActions = { ...OnboardingSlice.actions };

export default OnboardingSlice.reducer;
