import { PropsWithChildren } from "react";

type Props = PropsWithChildren & {
  className?: string;
};

export const PageWidthConstraint: React.FC<Props> = ({
  className,
  children,
}) => {
  return (
    <div className={"relative flex justify-center " + className}>
      <div className={"relative w-full max-w-[1800px] bg-transparent px-8"}>
        {children ? children : ""}
      </div>
    </div>
  );
};
