import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Skeleton } from "shared/components/common/Skeleton";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import { selectMacroeconomicConditions } from "shared/store/macroeconomic_conditions/MacroeconomicConditionsReducer";
import { MacroeconomicConditionsThunks } from "shared/store/macroeconomic_conditions/MacroeconomicConditionsThunks";
import { Loading } from "shared/types/enums";
import ReactMarkdown from "react-markdown";
import { formatDate } from "shared/utils/date";

export const MacroeconomicConditions: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { conditions, loading } = useSelector(selectMacroeconomicConditions());

  useEffect(() => {
    if (!conditions) {
      dispatch(MacroeconomicConditionsThunks.fetchMacroeconomicConditions());
    }
  }, [conditions, dispatch]);

  return (
    <Panel>
      <Labels.H3 className="mb-4">{t("macroeconomic.title")}</Labels.H3>
      {loading === Loading.InProgress && (
        <div>
          <Skeleton className="h-[24px] w-44 bg-sb-gray-200" />
          <Skeleton className="mt-2 h-[16px] w-20 bg-sb-gray-200" />
        </div>
      )}
      {loading === Loading.Finished && conditions ? (
        <div className="mt-8">
          <div className="text-xl font-semibold text-sb-green-700">
            {t("macroeconomic.dateUpdate", {
              date: formatDate(conditions.date),
            })}
          </div>
          <div className="mt-4 space-y-2">
            {conditions.macro_summary?.map((summary, idx) => {
              return (
                <div
                  key={"mesummary_" + idx}
                  className="rounded-lg bg-sb-gray-100 px-4 py-2"
                >
                  <span className="mr-2 font-semibold">{summary.title}</span>
                  {summary.content}
                </div>
              );
            })}
          </div>
          <div className="mt-8">
            <Labels.H3>{t("macroeconomic.insights")}</Labels.H3>
            <ReactMarkdown className="reactMarkdown hyphens-auto">
              {conditions.insights}
            </ReactMarkdown>
          </div>
        </div>
      ) : null}
      {loading === Loading.Finished && !conditions ? (
        <Labels.G1>{t("macroeconomic.noConditions")}</Labels.G1>
      ) : null}
    </Panel>
  );
};
