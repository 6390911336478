import { DateTime, DurationLike } from "luxon";
import { useEffect, useState } from "react";
import { DateRange } from "react-aria";
import { useTranslation } from "react-i18next";
import { AxisDomain } from "recharts/types/util/types";
import { Chart } from "shared/components/common/chart/Chart";
import { Panel } from "shared/components/hoc/Panels";
import { ChartPeriod } from "shared/types/Aum";
import { ISOTimestamp } from "shared/utils/types";

import { parseDate } from "@internationalized/date";

import { ChartPeriodSelector } from "./ChartPeriodSelector";

type Props = {
  data: Record<string, any>[] | null | undefined;
  xKey: string;
  yKey: string;
  pctKey?: string;
  onPeriodChange: (
    startDate: ISOTimestamp | null,
    endDate: ISOTimestamp | null,
  ) => void;
  domain?: AxisDomain;
  xFormatter?: (value: any) => string;
  yFormatter?: (value: any) => string;
  yValueFormatter?: (value: any) => string;
  zoomable?: boolean;
};

export const ValueChart: React.FC<Props> = ({
  onPeriodChange,
  data,
  xKey,
  yKey,
  pctKey,
  domain,
  xFormatter,
  yFormatter,
  yValueFormatter,
  zoomable,
}) => {
  const { t } = useTranslation();

  const [selectedPeriod, setSelectedPeriod] = useState<ChartPeriod>("1m");
  const [customRange, setCustomRange] = useState<DateRange>();

  useEffect(() => {
    let endDate = DateTime.utc().toFormat("yyyy-MM-dd");
    let startDate = null;
    if (selectedPeriod && !["all", "custom"].includes(selectedPeriod)) {
      let delta: DurationLike = { days: 1 };
      if (selectedPeriod === "1w") {
        delta = { days: 7 };
      } else if (selectedPeriod === "1m") {
        delta = { months: 1 };
      } else if (selectedPeriod === "2m") {
        delta = { months: 2 };
      } else if (selectedPeriod === "3m") {
        delta = { months: 3 };
      } else if (selectedPeriod === "1y") {
        delta = { years: 1 };
      } else if (selectedPeriod === "2y") {
        delta = { years: 2 };
      } else if (selectedPeriod === "ytd") {
        delta = {
          days: Math.abs(DateTime.utc().startOf("year").diffNow("days").days),
        };
      }
      startDate = DateTime.utc().minus(delta).toFormat("yyyy-MM-dd");
    } else if (selectedPeriod === "custom" && customRange) {
      startDate = DateTime.fromISO(customRange.start.toString()).toFormat(
        "yyyy-MM-dd",
      );
      endDate = DateTime.fromISO(customRange.end.toString()).toFormat(
        "yyyy-MM-dd",
      );
    }
    onPeriodChange(startDate, endDate);
  }, [selectedPeriod, customRange, onPeriodChange]);

  const periods: { id: ChartPeriod; text: string }[] = [
    // {
    //   id: "today",
    //   text: t("aggregatedAum.period.today"),
    // },
    {
      id: "1w",
      text: t("aggregatedAum.period.oneWeek"),
    },
    {
      id: "1m",
      text: t("aggregatedAum.period.oneMonth"),
    },
    {
      id: "2m",
      text: t("aggregatedAum.period.twoMonths"),
    },
    {
      id: "3m",
      text: t("aggregatedAum.period.threeMonths"),
    },
    {
      id: "1y",
      text: t("aggregatedAum.period.oneYear"),
    },
    {
      id: "2y",
      text: t("aggregatedAum.period.twoYears"),
    },
    {
      id: "ytd",
      text: t("aggregatedAum.period.ytd"),
    },
    {
      id: "all",
      text: t("aggregatedAum.period.all"),
    },
  ];

  useEffect(() => {
    if (customRange) {
      setSelectedPeriod("custom");
    }
  }, [customRange]);

  useEffect(() => {
    if (selectedPeriod !== "custom") {
      setCustomRange(undefined);
    }
  }, [selectedPeriod]);

  return (
    <Panel>
      <div className="w-full select-none py-4">
        <Chart
          data={data}
          xKey={xKey}
          yKey={yKey}
          pctKey={pctKey}
          height={400}
          yFormatter={yFormatter}
          xFormatter={xFormatter}
          yValueFormatter={yValueFormatter}
          domain={domain}
          onZoom={
            zoomable
              ? (left, right) => {
                  if (left && right) {
                    const dates = [left, right];

                    //sort dates
                    const sortedDates = dates
                      .map((date) => ({
                        formatDate: date,
                        millisDate: DateTime.fromFormat(
                          date,
                          "yyyy-MM-dd",
                        ).toMillis(),
                      }))
                      .sort((a, b) => a.millisDate - b.millisDate);

                    const [start, end] = sortedDates;

                    setSelectedPeriod("custom");
                    setCustomRange({
                      start: parseDate(start.formatDate),
                      end: parseDate(end.formatDate),
                    });
                  }
                }
              : undefined
          }
        />
      </div>
      <div className="text-base">
        <div className="mt-8 flex items-center gap-2 text-base">
          <ChartPeriodSelector
            periods={periods}
            selectedPeriod={selectedPeriod}
            onChange={setSelectedPeriod}
            renderCustomRangePeriod={true}
            customRange={customRange}
            onChangeCustomRange={setCustomRange}
          />
        </div>
      </div>
    </Panel>
  );
};
