import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import OrderRow from "./OrderRow";
import { selectClientOrders } from "shared/store/client_details/ClientDetailsReducer";
import { ClientDetailsThunks } from "shared/store/client_details/ClientDetailsThunks";
import { useAccount } from "shared/hooks/useAccount";
import { useNavigate } from "react-router-dom";

type Props = {
  clientId?: string;
};

export const ClientOrders: React.FC<Props> = ({ clientId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orders } = useSelector(selectClientOrders(clientId || ""));
  const { account } = useAccount();
  const [limit, setLimit] = useState(10);

  const setupInterval = useCallback(() => {
    let refreshTimeout: NodeJS.Timeout | null = null;

    const refresh = async () => {
      if (clientId) {
        dispatch(ClientDetailsThunks.fetchOrders({ clientId, limit }));
      }
      refreshTimeout = setTimeout(refresh, 5000);
    };

    refresh();

    return () => {
      if (refreshTimeout) {
        clearTimeout(refreshTimeout);
      }
    };
  }, [clientId, dispatch, limit, account]);

  useEffect(() => {
    const clearInterval = setupInterval();
    return () => clearInterval();
  }, [setupInterval]);

  return (
    <Panel>
      <div className="flex justify-between gap-4">
        <Labels.H3>{t("orders.title")}</Labels.H3>
        <Button
          label="orders.buy"
          onClick={() => navigate(`/trade?clientId=${clientId}`)}
        />
      </div>
      {orders?.length === 0 ? (
        <Labels.G1 className="mt-4">{t("orders.noOrders")}</Labels.G1>
      ) : (
        <div className="mt-8">
          {orders?.map((item, idx) => {
            return (
              <div key={"position_" + idx}>
                <OrderRow order={item} clientId={clientId} />
              </div>
            );
          })}
        </div>
      )}
      {limit <= orders?.length && (
        <div
          className="mt-8 cursor-pointer text-base text-sb-gray-500 underline"
          onClick={() => setLimit(limit + 10)}
        >
          {t("global.seeMore")}
        </div>
      )}
    </Panel>
  );
};
