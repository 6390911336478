import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Skeleton } from "shared/components/common/Skeleton";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import { useAccount } from "shared/hooks/useAccount";
import { useDispatch } from "shared/hooks/useDispatch";
import { fetchPendingTasks } from "shared/store/account/AccountThunks";
import { Loading } from "shared/types/enums";
import { ClientPendingTasks } from "./ClientPendingTasks";

type Props = {};

export const PendingTasks: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pendingTasks, pendingTasksLoading } = useAccount();

  useEffect(() => {
    dispatch(fetchPendingTasks());
  }, [dispatch]);

  return (
    <Panel>
      <Labels.H3>{t("pendingTasks.title")}</Labels.H3>
      <div className="space-y-2">
        <div className="text-sm">
          {pendingTasksLoading === Loading.InProgress ? (
            <Skeleton className="my-1 h-10 w-full bg-sb-gray-100" />
          ) : (
            pendingTasks.map((item, idx) => {
              return (
                <ClientPendingTasks key={"pending_task_" + idx} task={item} />
              );
            })
          )}
        </div>
        {pendingTasksLoading === Loading.Finished && !pendingTasks.length ? (
          <Labels.G1>{t("pendingTasks.noTasks")}</Labels.G1>
        ) : null}
      </div>
    </Panel>
  );
};
