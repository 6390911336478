import { useTranslation } from "react-i18next";
import { Modal } from "shared/components/common/Modal";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  symbol: string;
  loading: boolean;
  onClosePosition: () => void;
};

export const ClosePositionModal: React.FC<Props> = ({
  open,
  setOpen,
  symbol,
  loading,
  onClosePosition,
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} setOpen={setOpen}>
      <Labels.H3>{t("global.closeSymbol", { symbol })}</Labels.H3>
      {t("trade.doYouWantToCloseAll")}
      <div className="mt-8 flex w-full gap-4">
        <Button
          disabled={loading}
          label="global.cancel"
          btnStyle="blank"
          className="w-full"
          onClick={() => setOpen(false)}
        />
        <Button
          loading={loading}
          label="global.closeAll"
          className="w-full"
          onClick={onClosePosition}
        />
      </div>
    </Modal>
  );
};
