import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DropdownSelect from "shared/components/controls/DropdownSelect";
import { LEGAL_AGE, MAX_AGE } from "shared/utils/common";
import { isValidDate, validateAge } from "shared/utils/validation";

interface PickerElementProps {
  value?: number;
  onSelect: (value: number | string) => void;
  displayText: string;
  options: PickerModalOption<number>[];
  className?: string;
}

export interface PickerModalOption<T> {
  value: T;
  label: string;
  desc?: string;
  selected?: boolean;
}

const PickerElement: React.FC<PickerElementProps> = ({
  value,
  onSelect,
  displayText,
  options,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <DropdownSelect
      selected={value || ""}
      className={`mt-2 max-h-[50px] ${className}`}
      title={t(displayText)}
      options={options}
      onSelect={(o) => onSelect(o.value)}
    />
  );
};

export enum DateStates {
  AgeUnderMin,
  InvalidDate,
  ValidDate,
  Empty,
}

interface Props {
  initialDate?: Date;
  usaDateOrder: boolean;
  maxAge: number;
  minAge: number;
  inputClassName?: string;
  onDateSelect: (newDate: Date | undefined, dateState: DateStates) => void;
}

export const DatePicker: React.FC<Props> = ({
  initialDate,
  maxAge,
  minAge,
  usaDateOrder,
  inputClassName,
  onDateSelect,
}) => {
  const now = DateTime.now();
  const { t } = useTranslation();
  const [day, setDay] = useState<number | string | undefined>();
  const [month, setMonth] = useState<number | string | undefined>();
  const [year, setYear] = useState<number | string | undefined>();

  useEffect(() => {
    if (initialDate) {
      setDay(initialDate.getDate());
      setMonth(initialDate.getMonth());
      setYear(initialDate.getFullYear());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelect = (
    dayValue: number | string | undefined,
    monthValue: number | string | undefined,
    yearValue: number | string | undefined,
  ) => {
    setDay(dayValue);
    setMonth(monthValue);
    setYear(yearValue);
    if (!dayValue || monthValue === undefined || !yearValue)
      return onDateSelect(undefined, DateStates.Empty);

    if (!isValidDate(+yearValue, +monthValue + 1, +dayValue)) {
      return onDateSelect(undefined, DateStates.InvalidDate);
    }

    const date = new Date(+yearValue, +monthValue, +dayValue);

    if (!validateAge(date, LEGAL_AGE, MAX_AGE))
      return onDateSelect(undefined, DateStates.AgeUnderMin);

    onDateSelect(date, DateStates.ValidDate);
  };

  const dayOptions: PickerModalOption<number>[] = Array.from(
    { length: 31 },
    (v, k) => ({
      value: k + 1,
      label: `${k + 1}`,
    }),
  );

  const monthOptions: PickerModalOption<number>[] = [
    { value: 0, label: "kyc.months.january" },
    { value: 1, label: "kyc.months.february" },
    { value: 2, label: "kyc.months.march" },
    { value: 3, label: "kyc.months.april" },
    { value: 4, label: "kyc.months.may" },
    { value: 5, label: "kyc.months.june" },
    { value: 6, label: "kyc.months.july" },
    { value: 7, label: "kyc.months.august" },
    { value: 8, label: "kyc.months.september" },
    { value: 9, label: "kyc.months.october" },
    { value: 10, label: "kyc.months.november" },
    { value: 11, label: "kyc.months.december" },
  ].map((month) => ({ ...month, label: t(month.label) }));

  const yearOptions: PickerModalOption<number>[] = Array.from(
    { length: maxAge - minAge },
    (y, k) => ({
      value: now.year - k,
      label: `${now.year - k}`,
    }),
  );

  const DatePickerOrder = usaDateOrder ? (
    <div className="flex w-full flex-row">
      <PickerElement
        onSelect={(value) => onSelect(day, value, year)}
        value={month ? +month : 0}
        displayText="kyc.datetime.month"
        options={monthOptions}
        className={`w-full ${inputClassName}`}
      />
      <PickerElement
        onSelect={(value) => onSelect(value ? +value : 0, month, year)}
        value={day ? +day : 0}
        displayText="kyc.datetime.day"
        options={dayOptions}
        className={`mx-2 w-full ${inputClassName}`}
      />
    </div>
  ) : (
    <div className="flex w-full flex-grow">
      <PickerElement
        onSelect={(value) => onSelect(value ? +value : 0, month, year)}
        value={day ? +day : 0}
        displayText="kyc.datetime.day"
        options={dayOptions}
        className={`w-full ${inputClassName}`}
      />
      <PickerElement
        onSelect={(value) => onSelect(day, value ? +value : 0, year)}
        value={month ? +month : 0}
        displayText="kyc.datetime.month"
        options={monthOptions}
        className={`mx-2 w-full ${inputClassName}`}
      />
    </div>
  );

  return (
    <div className="flex flex-row">
      {DatePickerOrder}
      <PickerElement
        onSelect={(value) => onSelect(day, month, value ? +value : 0)}
        value={year ? +year : 0}
        displayText="kyc.datetime.year"
        options={yearOptions}
        className={`w-fit ${inputClassName}`}
      />
    </div>
  );
};
