import { createAsyncThunk } from "@reduxjs/toolkit";
import { ContactsService } from "shared/services/contacts/ContactsService";

export const fetchContacts = createAsyncThunk(
  "[CONTACTS] Fetch contacts",
  async () => {
    const contacts = await ContactsService.getAll();
    return contacts;
  },
);

export const fetchContactById = createAsyncThunk(
  "[CONTACTS] Fetch contact by id",
  async (contactId: string) => {
    const contact = await ContactsService.get(contactId);
    return contact;
  },
);

export const ContactsThunks = {
  fetchContacts,
  fetchContactById,
};
