import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "shared/components/controls/Button";
import { PlusIcon } from "shared/icons/PlusIcon";

type Props = {
  skip?: string[];
  onAssetClassClicked?: (assetClass: string) => void;
};

export const AddAssetClassButton: React.FC<Props> = ({
  skip,
  onAssetClassClicked,
}) => {
  const { t } = useTranslation();

  const assetClasses = [
    "fixed_income",
    "growth",
    "cash_equivalents",
    "commodities",
    "dividend_value",
    "real_estate",
    "products",
  ];

  const filteredAssetClasses = assetClasses.filter((item) => {
    return !skip?.includes(item);
  });

  if (filteredAssetClasses.length === 0) {
    return null;
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button as="div">
          <Button
            btnStyle="blank"
            label="portfolioCard.assetClass"
            leftIcon={<PlusIcon className="aspect-square w-4" />}
            className="text-sm"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-100 absolute right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="p-2">
            {filteredAssetClasses.map((item, idx) => {
              return (
                <Menu.Item key={"asset_class_" + idx}>
                  <div
                    className="cursor-pointer rounded-lg p-4 duration-150 hover:bg-sb-gray-100"
                    onClick={() => onAssetClassClicked?.(item)}
                  >
                    {t("portfolioCard.assetClasses." + item)}
                  </div>
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
