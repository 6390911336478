import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface RealtimeSubscriptionsState {
  subscriptions: { [symbol: string]: number };
}

const initialState: RealtimeSubscriptionsState = {
  subscriptions: {},
};

const RealtimeSubscriptionsSlice = createSlice({
  name: "[REALTIME_SUBSCRIPTION]",
  initialState,
  reducers: {
    reset(state) {
      state.subscriptions = {};
      return state;
    },
    subscribe(
      state,
      { payload: { symbols } }: { payload: { symbols: string[] } },
    ): RealtimeSubscriptionsState {
      const subscriptions = symbols.reduce(
        (acc, value) => {
          acc[value] = Date.now();
          return acc;
        },
        { ...state.subscriptions },
      );
      return { subscriptions };
    },
    unsubscribe(
      state,
      { payload: { symbols } }: { payload: { symbols: string[] } },
    ): RealtimeSubscriptionsState {
      const subscriptions = symbols.reduce(
        (acc, value) => {
          delete acc[value];
          return acc;
        },
        { ...state.subscriptions },
      );
      return { subscriptions };
    },
  },
});

export const selectRealtimeSubscriptions = (state: RootState) =>
  state.realtimeSubscriptions;

export const RealtimeSubscriptionActions = {
  ...RealtimeSubscriptionsSlice.actions,
};

export default RealtimeSubscriptionsSlice.reducer;
