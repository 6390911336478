import { useTranslation } from "react-i18next";
import { ValueSelector } from "./ValueSelector";

type Props = {
  onChange?: (values: string[]) => void;
};

export const PortfolioGoal: React.FC<Props> = ({ onChange }) => {
  const { t } = useTranslation();

  const preDefinedGoals = [
    "retirement",
    "passiveIncome",
    "capitalGrowth",
    "protectionAgainstInflation",
    "education",
  ];

  return (
    <ValueSelector
      title={t("proposals.portfolioGoal")}
      translationKey="portfolioGoal_answers"
      preDefinedValues={preDefinedGoals}
      onChange={onChange}
      custom
    />
  );
};
