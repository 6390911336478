import RestService from "shared/api/RestService";
import {
  OnFidoTokenModel,
  OnFidoOutcomeModel,
} from "shared/models/onboarding/OnfidoModel";
import { FaApiRoutes } from "shared/utils/routes";

class OnFidoService {
  async getUsersToken(): Promise<OnFidoTokenModel> {
    const url = FaApiRoutes.Alpaca.Onfido.Sdk.Tokens;
    return RestService.get(url, { platform: "web", referrer: "*" });
  }

  async patchOutcome(onFidoOutcome: OnFidoOutcomeModel) {
    const url = FaApiRoutes.Alpaca.Onfido.Sdk.Path;
    return RestService.patch(url, onFidoOutcome);
  }
}
const onfidoService = new OnFidoService();
export default onfidoService;
