import { createAsyncThunk } from "@reduxjs/toolkit";
import { BrokerageAccountDocumentUpload } from "shared/models/brokerage_account/BrokerageAccountModel";
import { OnboardingAddress } from "shared/models/onboarding/OnboardingModel";
import { BrokerageAccountService } from "shared/services/brokerage_account/BrokerageAccountService";

const uploadDocuments = createAsyncThunk(
  "[BROKERAGE_ACCOUNT] Brokerage Account Upload Documents",
  async (documents: BrokerageAccountDocumentUpload[]) => {
    await BrokerageAccountService.uploadDocuments(documents);
    return documents;
  },
);

const updateAddress = createAsyncThunk(
  "[BROKERAGE_ACCOUNT] Brokerage Account Update Address",
  async (updatedAddress: OnboardingAddress) =>
    await BrokerageAccountService.updateAddress(updatedAddress),
);

export const BrokerageAccountThunks = {
  fetch,
  uploadDocuments,
  updateAddress,
};
