import { createAsyncThunk } from "@reduxjs/toolkit";
import { Proposal } from "shared/models/proposals/ProposalsModel";
import { ProposalsService } from "shared/services/proposals/ProposalsService";

const fetchMany = createAsyncThunk(
  "[PROPOSALS] Fetch proposals",
  async (): Promise<Proposal[]> => ProposalsService.getMany(),
);

export const fetchOne = createAsyncThunk(
  "[PROPOSALS] Fetch proposals by id",
  async (id: string): Promise<Proposal | undefined> =>
    ProposalsService.getOne(id),
);

export const ProposalsThunks = {
  fetchMany,
  fetchOne,
};
