import { AIAssistantMessageContent } from "shared/models/assistant/AIAssistantModel";
import { TextAssistantResponse } from "./responses/TextAssistantResponse";
import { mapResponseContents } from "./utils";

type Props = {
  content: AIAssistantMessageContent[];
  skipFeedback?: boolean;
  messageId?: string;
  sessionId?: string;
};

export const AssistantResponse: React.FC<Props> = ({
  content,
  skipFeedback,
  sessionId,
  messageId,
}) => {
  const mappedItems = mapResponseContents(content);

  let components = [];
  let i = 0;
  for (let items of mappedItems) {
    components.push(
      <TextAssistantResponse
        key={"text_response_" + i}
        text={items.map((i) => i.text?.value).join("\n")}
        skipFeedback={skipFeedback}
        messageId={messageId}
        sessionId={sessionId}
      />,
    );
    i++;
  }
  return <div>{components}</div>;
};
