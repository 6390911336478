type Props = {
  className?: string;
};

export const XIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.66934 11.1188L0.881836 10.3313L5.21309 6.00001L0.881836 1.66876L1.66934 0.881256L6.00059 5.21251L10.3318 0.881256L11.1193 1.66876L6.78809 6.00001L11.1193 10.3313L10.3318 11.1188L6.00059 6.78751L1.66934 11.1188Z"
        fill="currentColor"
      />
    </svg>
  );
};
