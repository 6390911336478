import RestService from "shared/api/RestService";
import { AumChart } from "shared/models/account/AumChart";
import { AumChartParams } from "shared/models/aum/AumChartParams";
import {
  Client,
  ClientRecentActivities,
} from "shared/models/client/ClientModel";
import {
  Portfolio,
  PortfolioResults,
} from "shared/models/portfolio/PortfolioModel";
import { WireDepositAccountModel } from "shared/models/wire_deposit/WireDepositModel";
import { FaApiRoutes } from "shared/utils/routes";

export const ClientsService = {
  async getById(clientId: string): Promise<Client | undefined> {
    return RestService.get(FaApiRoutes.Advisor.Clients.Details(clientId).Path);
  },

  async getMany(): Promise<Client[]> {
    return RestService.get(FaApiRoutes.Advisor.Clients.Path);
  },

  async getPortfolios(clientId: string): Promise<Portfolio[]> {
    return RestService.get(
      FaApiRoutes.Advisor.Clients.Details(clientId).Portfolios.Path,
    );
  },

  async getPortfolioById(
    clientId: string,
    portfolioId: string,
  ): Promise<Portfolio> {
    return RestService.get(
      FaApiRoutes.Advisor.Clients.Details(clientId).Portfolios.Details(
        portfolioId,
      ).Path,
    );
  },

  async getAdvisorClientAum(
    clientId: string,
    params?: AumChartParams,
  ): Promise<AumChart> {
    return RestService.get(
      FaApiRoutes.Advisor.Clients.Details(clientId).AumChart,
      params,
    );
  },

  async getRecentActivities(
    clientId: string,
    params?: { type: string },
  ): Promise<ClientRecentActivities> {
    return RestService.get(
      FaApiRoutes.Advisor.Clients.Details(clientId).Activities,
      params,
    );
  },

  async depositToPortfolio(
    clientId: string,
    portfolioId: string,
    amount: number,
  ) {
    return RestService.post(
      FaApiRoutes.Advisor.Clients.Details(clientId).Portfolios.Details(
        portfolioId,
      ).Deposits,
      { amount },
    );
  },

  async withdrawFromPortfolio(
    clientId: string,
    portfolioId: string,
    amount: number,
  ) {
    return RestService.post(
      FaApiRoutes.Advisor.Clients.Details(clientId).Portfolios.Details(
        portfolioId,
      ).Withdrawals,
      { amount },
    );
  },

  async getPortfolioResults(
    clientId: string,
    portfolioId: string,
    params?: { startDate?: string | null; endDate?: string | null },
  ): Promise<PortfolioResults> {
    return RestService.get(
      FaApiRoutes.Advisor.Clients.Details(clientId).Portfolios.Details(
        portfolioId,
      ).Results,
      params,
    );
  },

  async getWireAccounts(clientId: string): Promise<WireDepositAccountModel[]> {
    return RestService.get(
      FaApiRoutes.Advisor.Clients.Details(clientId).Alpaca.Funding.Accounts,
    );
  },
};
