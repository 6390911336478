import { useTranslation } from "react-i18next";
import { Panel } from "shared/components/hoc/Panels";
import { useAccount } from "shared/hooks/useAccount";

export const ActionRequiredSubmittedView: React.FC = () => {
  const { t } = useTranslation();
  const { account } = useAccount();

  const { identity, contact } =
    account?.client?.summary?.alpaca_details?.brokerage_account || {};

  return (
    <Panel>
      <div className="space-y-4">
        <div>
          <div className="font-semibold">{t("settings.traderApp.status")}</div>
          <div className="text-lg">{t("settings.traderApp.submitted")}</div>
        </div>
        <div>
          <div className="font-semibold">{t("settings.traderApp.name")}</div>
          <div className="text-lg">{`${identity?.given_name} ${identity?.family_name}`}</div>
        </div>
        <div>
          <div className="font-semibold">{t("settings.traderApp.email")}</div>
          <div className="text-lg">{contact?.email_address}</div>
        </div>
        <div className="italic">{t("forms.kyc.verifying")}</div>
      </div>
    </Panel>
  );
};
