import { useTranslation } from "react-i18next";
import { InvoicingAppliedType } from "../types";

export const useInvoicing = () => {
  const { t } = useTranslation();

  const appliedChoices: { label: string; value: InvoicingAppliedType }[] = [
    {
      label: t("feeTemplateForm.invoicing.monthly"),
      value: "monthly",
    },
    {
      label: t("feeTemplateForm.invoicing.quarterly"),
      value: "quarterly",
    },
  ];

  return { appliedChoices };
};
