import { useFlatFee } from "modules/fee_template_form/hooks/useFlatFee";
import { useTranslation } from "react-i18next";
import { InfoSection } from "../InfoSection";

export const FlatFee: React.FC = () => {
  const { t } = useTranslation();
  const { appliedChoices } = useFlatFee();

  return (
    <>
      <div>
        <InfoSection text={t("feeTemplateForm.flatFee.infoText")} />
        <div className="fee-template-form-section">
          <div className="fee-template-form">
            <div>{t("feeTemplateForm.applied")}</div>
            <select name="flatFee_applied">
              {appliedChoices.map((item, idx) => (
                <option key={idx} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            <label>{t("feeTemplateForm.amount")}</label>
            <input type="number" name="flatFee_amount" required />
          </div>
        </div>
      </div>
    </>
  );
};
