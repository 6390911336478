import { t } from "i18next";
import { useState } from "react";
import { DataTable } from "shared/components/common/datatable/DataTable";
import ChevronRight from "shared/icons/ChevronRight";
import { SquareWarningIcon } from "shared/icons/SquareWarningIcon";
import { PendingTasks } from "shared/models/pending_tasks/PendingTasksModel";

type Props = {
  task: PendingTasks;
};

export const ClientPendingTasks: React.FC<Props> = ({ task }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <div
        className="flex cursor-pointer items-center rounded-lg bg-sb-gray-100 px-4 py-2"
        onClick={() => setExpanded((prev) => !prev)}
      >
        <div className="flex flex-1 items-center">
          <div className="mr-4">
            <div className="text-lg font-medium">
              {task.user_id}
            </div>
          </div>
          <div className="mr-4 h-[40px] w-[1px] bg-black"></div>
          <div>
            <div className="text-base font-medium">{task.client_summary.client_name}</div>
            <div className="text-base text-sb-gray-500">
              {task.client_summary.email}
            </div>
            <div className="text-base text-sb-gray-500">
              {task.client_summary.phone}
            </div>
          </div>
        </div>

        <ChevronRight
          className={(expanded ? "rotate-90" : "") + " h-5 w-5 duration-150"}
        />
      </div>
      {expanded && task.ibrk_pending_tasks && (
        <div className="pl-4">
          <DataTable
            data={task.ibrk_pending_tasks.pendingTasks}
            columns={[
              "formNumber",
              "formName",
              "action",
              "isRequiredForApproval",
              "isRequiredForTrading",
            ]}
            headerLabels={{
              formNumber: t("pendingTasks.headers.formNumber"),
              formName: t("pendingTasks.headers.formName"),
              action: t("pendingTasks.headers.action"),
              isRequiredForApproval: t(
                "pendingTasks.headers.isRequiredForApproval",
              ),
              isRequiredForTrading: t(
                "pendingTasks.headers.isRequiredForTrading",
              ),
            }}
            cellFormatter={(key: string, value: string) => {
              if (
                [
                  "isRequiredForApproval",
                  "isRequiredForTrading",
                ].includes(key)
              ) {
                return (
                  <div className="flex justify-center">
                    {value ? (
                      <SquareWarningIcon className="h-6 w-6 text-yellow-400" />
                    ) : (
                      "-"
                    )}
                  </div>
                );
              }
              return <div>{value}</div>;
            }}
          />
        </div>
      )}
      {expanded && task.alpaca_pending_tasks && (
        <div className="pl-4">
          <DataTable
            data={task.alpaca_pending_tasks.pending_tasks}
            columns={[
              "pending_issue",
            ]}
            headerLabels={{
              pending_issue: t("pendingTasks.headers.action")
            }}
            cellFormatter={(key: string, value: string) => {
              return <div>{value}</div>;
            }}
          />
        </div>
      )}
    </>
  );
};
