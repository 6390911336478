import { Labels } from "shared/components/hoc/Labels";

export interface RadioOption<T> {
  value: T;
  label: string;
}

interface Props<T> {
  options: RadioOption<T>[];
  value: T;
  onChange(value: T): void;
}

export function RadioAnswers<T>({ options, value, onChange }: Props<T>) {
  const activeClasses = "bg-sb-green-100 !border-sb-green-700";
  return (
    <div className="space-y-3">
      {options.map((option, idx) => (
        <div
          onClick={() => onChange(option.value)}
          className={`cursor-pointer rounded-lg border border-black p-3 ${
            option.value === value ? activeClasses : undefined
          }`}
          key={idx}
        >
          <Labels.B1>{option.label}</Labels.B1>
        </div>
      ))}
    </div>
  );
}
