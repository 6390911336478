import RestService from "shared/api/RestService";
import {
  EditPortfolioAllocation,
  NewPortfolio,
  Portfolio,
  PortfolioPerformanceChart,
  PortfolioPerformanceChartParams,
  PortfolioProjection,
  PortfolioResults,
  PortfolioSimulationParams,
} from "shared/models/portfolio/PortfolioModel";
import {
  FaApiRoutes,
  PORTFOLIO_BACKTESTING_CHART_V6,
  PORTFOLIO_PROJECTION_V1,
} from "shared/utils/routes";

const MAX_PORTFOLIO_CHART_POINTS = 100;

export const PortfoliosService = {
  async getMany(): Promise<Portfolio[]> {
    return RestService.get<Portfolio[]>(FaApiRoutes.Advisor.Portfolios.Path);
  },

  async getOne(id: string): Promise<Portfolio> {
    return RestService.get<Portfolio>(
      FaApiRoutes.Advisor.Portfolios.Details(id).Path,
    );
  },

  async getResults(
    id: string,
    params?: { startDate?: string | null; endDate?: string | null },
  ): Promise<PortfolioResults> {
    return RestService.get<PortfolioResults>(
      FaApiRoutes.Advisor.Portfolios.Details(id).Results,
      params,
    );
  },

  async post(portfolio: NewPortfolio): Promise<void> {
    return RestService.post<void>(
      FaApiRoutes.Advisor.Portfolios.Path,
      portfolio,
    );
  },

  async editAllocations(
    portfolioId: string,
    allocations: { allocations: EditPortfolioAllocation[] },
  ): Promise<void> {
    return RestService.post<void>(
      FaApiRoutes.Advisor.Portfolios.Details(portfolioId).Composition,
      allocations,
    );
  },

  async editTexts(
    portfolioId: string,
    payload: { title?: string; symbol?: string; description?: string },
  ): Promise<void> {
    return RestService.post<void>(
      FaApiRoutes.Advisor.Portfolios.Details(portfolioId).Texts,
      payload,
    );
  },

  async getPortfolioProjection(
    params: PortfolioSimulationParams,
  ): Promise<[PortfolioProjection]> {
    return RestService.post<[PortfolioProjection]>(
      PORTFOLIO_PROJECTION_V1,
      params,
    );
  },

  async getPortfolioBacktestingChart(
    portfolioId: string,
    params?: PortfolioPerformanceChartParams,
  ) {
    return RestService.get<PortfolioPerformanceChart>(
      PORTFOLIO_BACKTESTING_CHART_V6(portfolioId),
      {
        ...params,
        max_points: MAX_PORTFOLIO_CHART_POINTS,
      },
    );
  },
};
