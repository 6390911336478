import { parseDate } from "@internationalized/date";
import { t } from "i18next";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { DateRange } from "react-aria";
import { DataTable } from "shared/components/common/datatable/DataTable";
import { DateRangeSelector } from "shared/components/common/DateRangeSelector";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import { FinancialAdvisorFeeScheduleDaily } from "shared/models/fees/FeesModel";
import { FeesService } from "shared/services/fees/FeesService";
import { pctFormatUnsigned } from "shared/utils/currency";
import { formatDateTime } from "shared/utils/date";

type Props = {
  clientId?: string;
};

export const ClientFeeScheduleDaily: React.FC<Props> = ({ clientId }) => {
  const [scheduleLoading, setScheduleLoading] = useState(false);
  const [schedule, setSchedule] = useState<FinancialAdvisorFeeScheduleDaily[]>(
    [],
  );
  const [dateRange, setDateRange] = useState<DateRange>({
    start: parseDate(DateTime.local().toFormat("yyyy-MM-dd")),
    end: parseDate(DateTime.local().plus({ months: 3 }).toFormat("yyyy-MM-dd")),
  });

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (clientId) {
      setScheduleLoading(true);
      FeesService.getDailySchedule(
        clientId,
        dateRange.start.toString(),
        dateRange.end.toString(),
      )
        .then(setSchedule)
        .finally(() => setScheduleLoading(false));
    }
  }, [clientId, dateRange.end, dateRange.start]);

  const filteredSchedule = expanded ? schedule : schedule.slice(0, 10);

  return (
    <Panel>
      <div className="flex items-center justify-between gap-4">
        <Labels.H3>{t("fees.feeScheduleDaily")}</Labels.H3>
        <div>
          <DateRangeSelector
            dateRange={dateRange}
            setDateRange={setDateRange}
            placeholder={t("fees.headers.feeDate")}
          />
        </div>
      </div>
      <div className="mt-4">
        {!schedule.length && !scheduleLoading ? (
          <Labels.G1>{t("fees.noSchedule")}</Labels.G1>
        ) : (
          <DataTable
            data={filteredSchedule}
            loading={scheduleLoading}
            columns={[
              "effective_from",
              "fee_date",
              "fee_frequency",
              "annual_rate",
            ]}
            headerLabels={{
              created_at: t("fees.headers.createdAt"),
              effective_from: t("fees.headers.effectiveFrom"),
              fee_frequency: t("fees.headers.feeFrequency"),
              annual_rate: t("fees.headers.annualRate"),
              fee_date: t("fees.headers.feeDate"),
            }}
            cellFormatter={(
              key: string,
              value: any,
              entry: Record<string, any>,
            ) => {
              if (key === "created_at") {
                return formatDateTime(value);
              }
              if (key === "annual_rate") {
                return pctFormatUnsigned.format(
                  entry["configuration"]?.["annual_rate"] || 0,
                );
              }
              if (key === "fee_frequency") {
                return t(`fees.frequency.${value}`);
              }
              return value;
            }}
            defaultSortSettings={{
              direction: "desc",
              by: "created_at",
            }}
          />
        )}
      </div>
      {filteredSchedule.length < schedule.length || expanded ? (
        <div
          className="mt-4 w-full cursor-pointer text-center underline"
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? t("global.hide") : t("global.seeMore")}
        </div>
      ) : null}
    </Panel>
  );
};
