import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import { Combobox, Transition } from "@headlessui/react";
import CheckIcon from "shared/icons/CheckIcon";
import ChevronRight from "shared/icons/ChevronRight";

export type DropdownOption = {
  value: string | number;
  label: string;
  icon?: React.ReactElement;
};

type Props = {
  title?: string;
  selected: string | number;
  options: DropdownOption[];
  onSelect: (o: DropdownOption) => void;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
};

export default function DropdownSelect({
  title,
  options,
  onSelect,
  selected,
  placeholder,
  className,
  inputClassName,
}: Props) {
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const selectedOption = options.find((option) => option.value === selected);

  const filteredOptions =
    query === ""
      ? options
      : options.filter(
          (o) =>
            `${o.value}`
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(query.toLowerCase().replace(/\s+/g, "")) ||
            t(o.label)
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(query.toLowerCase().replace(/\s+/g, "")),
        );

  return (
    <div className={className + " rounded-lg"}>
      <Combobox value={selectedOption} onChange={onSelect}>
        <div className="relative">
          <div className="relative w-full cursor-default overflow-hidden bg-transparent text-left">
            {title && (
              <div className="w-full pt-1 text-sm text-sb-gray-600">
                {title}
              </div>
            )}
            <Combobox.Input
              className={`w-full border-none bg-transparent text-base leading-5 text-black outline-none focus:ring-0 ${inputClassName}`}
              placeholder={placeholder ? placeholder : t("kyc.select")}
              displayValue={(o: DropdownOption) => t(o.label)}
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-3 flex items-center pr-2">
              <ChevronRight className="h-5 w-5 rotate-90" />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute z-50 mt-4 max-h-60 w-full overflow-auto rounded-md bg-transparent bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {filteredOptions.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredOptions.map((o, i) => (
                  <Combobox.Option
                    key={i}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-2 pl-10 text-base ${
                        active ? " bg-sb-gray-100" : "text-gray-900"
                      }`
                    }
                    value={o}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {t(o.label)}
                        </span>
                        {!selected && o.icon ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                            {o.icon}
                          </span>
                        ) : null}
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "bg-sb-gray-100" : ""
                            }`}
                          >
                            <CheckIcon className="h-3 w-3" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}
