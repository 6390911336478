type Props = {
  className?: string;
};

export const MessageWidget: React.FC<Props> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 182 213"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M127.802 18.7354H57.0151C46.5557 18.7354 38.0767 27.2189 38.0767 37.6838V193.562C38.0767 204.027 46.5557 212.511 57.0151 212.511H127.802C138.261 212.511 146.74 204.027 146.74 193.562V37.6838C146.74 27.2189 138.261 18.7354 127.802 18.7354Z"
        fill="white"
      />
      <path
        d="M73.0759 23.8474H111.741C111.555 25.3799 111.456 26.8468 111.183 28.226C110.778 30.3278 109.312 31.3239 106.686 31.3239H79.9467C79.0605 31.3239 78.1634 31.3239 77.2881 31.3239C76.7829 31.3239 76.2826 31.2241 75.816 31.0304C75.3494 30.8366 74.9255 30.5526 74.5688 30.1947C74.2121 29.8367 73.9294 29.4119 73.7371 28.9444C73.5447 28.477 73.4465 27.9762 73.4479 27.4707C73.4479 26.2994 73.2291 25.1391 73.0759 23.8474Z"
        fill="#111112"
      />
      <path
        d="M18.3978 33.5958L13.6555 28.851C12.0233 27.218 9.37708 27.218 7.74494 28.851L3.0026 33.5958C1.37046 35.2288 1.37046 37.8765 3.0026 39.5095L7.74494 44.2543C9.37708 45.8873 12.0233 45.8873 13.6555 44.2543L18.3978 39.5095C20.0299 37.8765 20.0299 35.2288 18.3978 33.5958Z"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M31.705 4.82007L29.5543 2.66825C28.4648 1.57816 26.6983 1.57816 25.6088 2.66825L23.4581 4.82007C22.3686 5.91017 22.3686 7.67756 23.4581 8.76766L25.6088 10.9195C26.6983 12.0096 28.4648 12.0096 29.5543 10.9195L31.705 8.76766C32.7945 7.67756 32.7945 5.91017 31.705 4.82007Z"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M92.2332 144.796H122.178"
        stroke="#111112"
        strokeWidth="3"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M82.5615 153.345H122.178"
        stroke="#111112"
        strokeWidth="3.45"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M82.5615 161.894H122.178"
        stroke="#111112"
        strokeWidth="3.45"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M66.7195 183.678C66.3694 183.678 66.0193 183.743 65.6254 183.743C56.3586 183.743 47.0918 183.831 37.8359 183.743C33.0296 183.669 28.3002 182.525 23.9905 180.395C19.6808 178.265 15.8988 175.202 12.9189 171.428C9.93903 167.654 7.83583 163.264 6.76183 158.577C5.68783 153.889 5.66996 149.021 6.70951 144.325C10.134 128.737 25.2431 117.736 40.9978 119.356C57.5293 121.064 70.1658 134.681 70.3299 150.937C70.4717 158.43 67.991 165.736 63.3169 171.593C60.8334 174.789 60.9865 177.318 63.8202 180.175C64.9143 181.269 66.0084 182.156 67.1024 183.141L66.7195 183.678Z"
        fill="#00FD86"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M147.932 78.5581C148.399 78.6348 148.877 78.6096 149.334 78.4841C149.79 78.3587 150.214 78.136 150.576 77.8314C150.938 77.5267 151.23 77.1473 151.432 76.7191C151.634 76.291 151.741 75.8242 151.746 75.3507C151.751 74.8773 151.653 74.4085 151.46 73.9763C151.267 73.5441 150.982 73.1589 150.626 72.847C150.27 72.5351 149.851 72.3038 149.398 72.1692C148.944 72.0345 148.467 71.9996 147.998 72.0668C147.222 72.1782 146.511 72.5639 145.995 73.154C145.478 73.7442 145.19 74.4999 145.182 75.2843C145.174 76.0687 145.447 76.8301 145.952 77.4306C146.456 78.0311 147.159 78.4311 147.932 78.5581Z"
        fill="white"
      />
      <path
        d="M119.158 106.023C120.252 105.038 121.346 104.096 122.441 103.057C125.274 100.199 125.427 97.6708 122.944 94.4744C118.27 88.6182 115.789 81.3113 115.931 73.8183C116.117 57.5627 128.71 43.989 145.241 42.2376C160.974 40.6175 176.105 51.6516 179.573 67.2066C180.612 71.9095 180.591 76.785 179.511 81.4785C178.43 86.1721 176.318 90.5657 173.327 94.34C170.337 98.1143 166.543 101.174 162.222 103.297C157.901 105.421 153.162 106.554 148.348 106.614C139.092 106.735 129.826 106.614 120.559 106.614C120.209 106.614 119.859 106.614 119.465 106.548L119.158 106.023Z"
        fill="#00FD86"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M94.0056 69.4287H64.0608"
        stroke="#111112"
        strokeWidth="3"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M103.677 77.967H64.0608"
        stroke="#111112"
        strokeWidth="3.45"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M103.677 86.5164H64.0608"
        stroke="#111112"
        strokeWidth="3.45"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M161.236 77.6277C161.705 77.6957 162.182 77.6617 162.636 77.5278C163.09 77.3939 163.51 77.1635 163.866 76.8521C164.223 76.5408 164.508 76.1561 164.702 75.7242C164.896 75.2923 164.994 74.8236 164.99 74.3501C164.986 73.8767 164.88 73.4097 164.679 72.9811C164.478 72.5526 164.186 72.1727 163.824 71.8675C163.463 71.5622 163.039 71.3388 162.583 71.2126C162.127 71.0865 161.649 71.0604 161.182 71.1364C160.408 71.2622 159.705 71.661 159.199 72.2607C158.694 72.8603 158.42 73.6211 158.426 74.4055C158.433 75.1899 158.72 75.946 159.235 76.537C159.751 77.128 160.461 77.5149 161.236 77.6277Z"
        fill="white"
      />
      <path
        d="M148.009 77.6277C148.478 77.6949 148.955 77.66 149.409 77.5253C149.862 77.3907 150.281 77.1595 150.637 76.8475C150.993 76.5356 151.278 76.1504 151.471 75.7182C151.664 75.286 151.762 74.8172 151.757 74.3437C151.752 73.8703 151.645 73.4035 151.443 72.9754C151.241 72.5472 150.949 72.1678 150.587 71.8631C150.225 71.5585 149.801 71.3358 149.345 71.2104C148.888 71.0849 148.41 71.0597 147.943 71.1364C147.17 71.2634 146.467 71.6634 145.963 72.2639C145.458 72.8644 145.185 73.6258 145.193 74.4102C145.201 75.1946 145.489 75.9503 146.006 76.5405C146.522 77.1306 147.233 77.5163 148.009 77.6277Z"
        fill="white"
      />
      <path
        d="M134.716 77.6277C135.184 77.6999 135.662 77.6699 136.117 77.54C136.572 77.41 136.994 77.1831 137.353 76.8748C137.712 76.5665 138.001 76.1841 138.198 75.7538C138.396 75.3236 138.498 74.8556 138.498 74.3821C138.498 73.9086 138.396 73.4406 138.198 73.0104C138.001 72.5801 137.712 72.1977 137.353 71.8894C136.994 71.5811 136.572 71.3542 136.117 71.2242C135.662 71.0943 135.184 71.0643 134.716 71.1365C133.941 71.2559 133.235 71.6488 132.725 72.2441C132.214 72.8394 131.934 73.5978 131.934 74.3821C131.934 75.1664 132.214 75.9248 132.725 76.5201C133.235 77.1154 133.941 77.5083 134.716 77.6277Z"
        fill="white"
      />
      <path
        d="M53.5138 149.995V150.564C53.4382 150.831 53.2718 151.064 53.0434 151.221C52.7619 151.408 52.4294 151.504 52.0916 151.495H38.3281C38.3281 151.495 38.2296 151.495 38.2077 151.495C38.1859 151.495 38.2077 151.572 38.2077 151.604C38.3719 151.823 38.536 152.031 38.6891 152.25C40.1712 154.388 41.0241 156.898 41.1508 159.497C41.1763 160.136 41.158 160.776 41.0961 161.412C40.9985 162.393 40.8005 163.362 40.5053 164.302C40.1933 165.246 39.7864 166.156 39.2909 167.017C39.0727 167.484 38.6796 167.845 38.1968 168.024H37.6279C37.2114 167.878 36.8361 167.634 36.5338 167.313C32.1575 162.89 27.7447 158.475 23.2955 154.067C22.9494 153.756 22.7118 153.341 22.6172 152.885V152.502C22.6518 152.287 22.7446 152.086 22.8853 151.92C23.0261 151.754 23.2096 151.63 23.4159 151.561C23.6563 151.466 23.9124 151.418 24.1708 151.418H37.8248C37.8248 151.418 37.9561 151.418 37.978 151.418C37.9999 151.418 37.978 151.32 37.8905 151.276C37.245 150.529 36.6938 149.706 36.2494 148.824C35.9259 148.173 35.6587 147.495 35.4507 146.799C35.138 145.786 34.9542 144.738 34.9036 143.679C34.849 142.969 34.849 142.255 34.9036 141.545C35.0009 140.659 35.1879 139.787 35.4616 138.939C35.7998 137.92 36.2397 136.937 36.7745 136.006C36.9228 135.707 37.1271 135.439 37.3763 135.217C37.5705 135.065 37.8 134.963 38.0436 134.922H38.2625C38.6895 134.994 39.0773 135.215 39.3565 135.546L52.8246 149.01C53.1417 149.268 53.3805 149.609 53.5138 149.995Z"
        fill="white"
      />
    </svg>
  );
};
