import { createSelector, createSlice } from "@reduxjs/toolkit";
import { ClientRecentActivities } from "shared/models/client/ClientModel";
import { FinancialAdvisorFee } from "shared/models/fees/FeesModel";
import { Portfolio } from "shared/models/portfolio/PortfolioModel";
import { TradingOrder } from "shared/models/trading/TradingOrderModel";
import { TradingPosition } from "shared/models/trading/TradingPositionmodel";
import {
  WireDepositAccountModel,
  WireDepositCurrencies,
} from "shared/models/wire_deposit/WireDepositModel";
import { RootState } from "shared/store/store";
import { Loading } from "shared/types/enums";
import { ClientDetailsThunks } from "./ClientDetailsThunks";

type ClientDetails = {
  positions: TradingPosition[];
  orders: TradingOrder[];
  portfolios: Portfolio[];
  recentActivities: ClientRecentActivities;
  wireAccounts: {
    accounts: WireDepositAccountModel[];
    selectedCurrency?: WireDepositCurrencies;
  };
  openFees: FinancialAdvisorFee[];
  feeSchedule: FinancialAdvisorFee[];

  positionsLoading: Loading;
  ordersLoading: Loading;
  portfoliosLoading: Loading;
  recentActivitiesLoading: Loading;
  wireAccountsLoading: Loading;
  feesLoading: Loading;
  feeScheduleLoading: Loading;
};

interface ClientDetailsState {
  [clientId: string]: ClientDetails;
}

const initialState: ClientDetails = {
  positions: [],
  orders: [],
  portfolios: [],
  recentActivities: [],
  wireAccounts: {
    accounts: [],
    selectedCurrency: undefined,
  },
  openFees: [],
  feeSchedule: [],

  positionsLoading: Loading.Idle,
  ordersLoading: Loading.Idle,
  portfoliosLoading: Loading.Idle,
  recentActivitiesLoading: Loading.Idle,
  wireAccountsLoading: Loading.Idle,
  feesLoading: Loading.Idle,
  feeScheduleLoading: Loading.Idle,
};

const clientDetailsSlice = createSlice({
  name: "[CLIENT_DETAILS]",
  initialState: {} as ClientDetailsState,
  reducers: {},
  extraReducers: (builder) => {
    /* POSITIONS */
    builder.addCase(
      ClientDetailsThunks.fetchPositions.pending,
      (state, { meta }) => {
        if (!state[meta.arg.clientId]) {
          state[meta.arg.clientId] = initialState;
        }
        state[meta.arg.clientId] = {
          ...state[meta.arg.clientId],
          positionsLoading: Loading.InProgress,
        };
      },
    );
    builder.addCase(
      ClientDetailsThunks.fetchPositions.fulfilled,
      (state, action) => {
        if (!state[action.payload.clientId]) {
          state[action.payload.clientId] = initialState;
        }
        state[action.payload.clientId] = {
          ...state[action.payload.clientId],
          positions: action.payload.positions,
          positionsLoading: Loading.Finished,
        };
      },
    );
    /* ACTIVITIES */
    builder.addCase(
      ClientDetailsThunks.fetchRecentActivities.pending,
      (state, { meta }) => {
        if (!state[meta.arg.clientId]) {
          state[meta.arg.clientId] = initialState;
        }
        state[meta.arg.clientId] = {
          ...state[meta.arg.clientId],
          recentActivitiesLoading: Loading.InProgress,
        };
      },
    );
    builder.addCase(
      ClientDetailsThunks.fetchRecentActivities.fulfilled,
      (state, action) => {
        if (!state[action.payload.clientId]) {
          state[action.payload.clientId] = initialState;
        }
        state[action.payload.clientId] = {
          ...state[action.payload.clientId],
          recentActivities: action.payload.recentActivities,
          recentActivitiesLoading: Loading.Finished,
        };
      },
    );
    /* ORDERS */
    builder.addCase(
      ClientDetailsThunks.fetchOrders.pending,
      (state, { meta }) => {
        if (!state[meta.arg.clientId]) {
          state[meta.arg.clientId] = initialState;
        }
        state[meta.arg.clientId] = {
          ...state[meta.arg.clientId],
          ordersLoading: Loading.InProgress,
        };
      },
    );
    builder.addCase(
      ClientDetailsThunks.fetchOrders.fulfilled,
      (state, action) => {
        if (!state[action.payload.clientId]) {
          state[action.payload.clientId] = initialState;
        }
        state[action.payload.clientId] = {
          ...state[action.payload.clientId],
          orders: action.payload.orders,
          ordersLoading: Loading.Finished,
        };
      },
    );
    /* PORTFOLIOS */
    builder.addCase(
      ClientDetailsThunks.fetchPortfolios.pending,
      (state, { meta }) => {
        if (!state[meta.arg.clientId]) {
          state[meta.arg.clientId] = initialState;
        }
        state[meta.arg.clientId] = {
          ...state[meta.arg.clientId],
          portfoliosLoading: Loading.InProgress,
        };
      },
    );
    builder.addCase(
      ClientDetailsThunks.fetchPortfolios.fulfilled,
      (state, action) => {
        if (!state[action.payload.clientId]) {
          state[action.payload.clientId] = initialState;
        }
        state[action.payload.clientId] = {
          ...state[action.payload.clientId],
          portfolios: action.payload.portfolios,
          portfoliosLoading: Loading.Finished,
        };
      },
    );
    /* CLIENT PORTFOLIO */
    builder.addCase(
      ClientDetailsThunks.fetchClientPortfolio.pending,
      (state, { meta }) => {
        if (!state[meta.arg.clientId]) {
          state[meta.arg.clientId] = initialState;
        }
        state[meta.arg.clientId] = {
          ...state[meta.arg.clientId],
          portfoliosLoading: Loading.InProgress,
        };
      },
    );
    builder.addCase(
      ClientDetailsThunks.fetchClientPortfolio.fulfilled,
      (state, action) => {
        if (!state[action.payload.clientId]) {
          state[action.payload.clientId] = initialState;
        }
        state[action.payload.clientId] = {
          ...state[action.payload.clientId],
          portfolios: [
            ...state[action.payload.clientId].portfolios.filter(
              (p) => p.id !== action.payload.portfolio.id,
            ),
            action.payload.portfolio,
          ],
          portfoliosLoading: Loading.Finished,
        };
      },
    );
    /* WIRE ACCOUNTS */
    builder.addCase(
      ClientDetailsThunks.fetchWireAccounts.pending,
      (state, { meta }) => {
        if (!state[meta.arg.clientId]) {
          state[meta.arg.clientId] = initialState;
        }
        state[meta.arg.clientId] = {
          ...state[meta.arg.clientId],
          wireAccountsLoading: Loading.InProgress,
        };
      },
    );
    builder.addCase(
      ClientDetailsThunks.fetchWireAccounts.fulfilled,
      (state, action) => {
        if (!state[action.payload.clientId]) {
          state[action.payload.clientId] = initialState;
        }
        const accounts = action.payload.wireAccounts;
        let selectedCurrency =
          state[action.payload.clientId].wireAccounts.selectedCurrency;
        if (!selectedCurrency) {
          // auto-select default currency
          const currencies: WireDepositCurrencies[] = [
            "GBP",
            "CHF",
            "EUR",
            "USD",
          ];
          const available = currencies.filter((c) =>
            accounts.find((a) => a.currency === c),
          );
          selectedCurrency = available[0];
        }
        state[action.payload.clientId] = {
          ...state[action.payload.clientId],
          wireAccounts: {
            accounts: action.payload.wireAccounts,
            selectedCurrency,
          },
          wireAccountsLoading: Loading.Finished,
        };
      },
    );
    /* OPEN FEES */
    builder.addCase(
      ClientDetailsThunks.fetchOpenFees.pending,
      (state, { meta }) => {
        if (!state[meta.arg.clientId]) {
          state[meta.arg.clientId] = initialState;
        }
        state[meta.arg.clientId] = {
          ...state[meta.arg.clientId],
          feesLoading: Loading.InProgress,
        };
      },
    );
    builder.addCase(
      ClientDetailsThunks.fetchOpenFees.fulfilled,
      (state, action) => {
        if (!state[action.payload.clientId]) {
          state[action.payload.clientId] = initialState;
        }
        state[action.payload.clientId] = {
          ...state[action.payload.clientId],
          openFees: action.payload.fees,
          feesLoading: Loading.Finished,
        };
      },
    );
    /* FEE SCHEDULE */
    builder.addCase(
      ClientDetailsThunks.fetchFeeSchedule.pending,
      (state, { meta }) => {
        if (!state[meta.arg.clientId]) {
          state[meta.arg.clientId] = initialState;
        }
        state[meta.arg.clientId] = {
          ...state[meta.arg.clientId],
          feeScheduleLoading: Loading.InProgress,
        };
      },
    );
    builder.addCase(
      ClientDetailsThunks.fetchFeeSchedule.fulfilled,
      (state, action) => {
        if (!state[action.payload.clientId]) {
          state[action.payload.clientId] = initialState;
        }
        state[action.payload.clientId] = {
          ...state[action.payload.clientId],
          feeSchedule: action.payload.fees,
          feeScheduleLoading: Loading.Finished,
        };
      },
    );
  },
});

export const selectClientPositions = (clientId: string) =>
  createSelector(
    (state: RootState) => state,
    (state) => {
      return {
        positions: clientId
          ? state.clientDetails[clientId]?.positions
          : ([] as TradingPosition[]),
        loading: clientId
          ? state.clientDetails[clientId]?.positionsLoading
          : Loading.Idle,
      };
    },
  );

export const selectClientOrders = (clientId: string) =>
  createSelector(
    (state: RootState) => state,
    (state) => {
      return {
        orders: clientId ? state.clientDetails[clientId]?.orders : [],
        loading: clientId
          ? state.clientDetails[clientId]?.ordersLoading
          : Loading.Idle,
      };
    },
  );

export const selectClientRecentActivities = (clientId?: string) =>
  createSelector(
    (state: RootState) => state,
    (state) => {
      return {
        activities: clientId
          ? state.clientDetails[clientId]?.recentActivities
          : [],
        loading: clientId
          ? state.clientDetails[clientId]?.recentActivitiesLoading
          : Loading.Idle,
      };
    },
  );

export const selectClientPortfolios = (clientId: string) =>
  createSelector(
    (state: RootState) => state,
    (state) => {
      return {
        portfolios: clientId ? state.clientDetails[clientId]?.portfolios : [],
        loading: clientId
          ? state.clientDetails[clientId]?.portfoliosLoading
          : Loading.Idle,
      };
    },
  );

export const selectPortfolioByClientIdAndPortfolioId = (
  clientId: string | undefined,
  portfolioId: string | undefined,
) =>
  createSelector(
    (state: RootState) => state,
    (state) => {
      return {
        portfolio:
          clientId && portfolioId
            ? state.clientDetails[clientId]?.portfolios.find(
                (portfolio) => portfolio.id === portfolioId,
              )
            : undefined,
        loading:
          clientId && portfolioId
            ? state.clientDetails[clientId]?.portfoliosLoading
            : Loading.Idle,
      };
    },
  );

export const selectClientWireAccounts = (clientId?: string) =>
  createSelector(
    (state: RootState) => state,
    (state) => {
      return {
        ...(clientId
          ? state.clientDetails[clientId]?.wireAccounts ||
            initialState.wireAccounts
          : initialState.wireAccounts),
        loading: clientId
          ? state.clientDetails[clientId]?.wireAccountsLoading || Loading.Idle
          : Loading.Idle,
      };
    },
  );

export const selectClientCurrencyCloudAccount = (
  currency?: WireDepositCurrencies,
  clientId?: string,
) =>
  createSelector(
    (state: RootState) => state,
    (state) => {
      return clientId
        ? state.clientDetails[clientId]?.wireAccounts?.accounts.find(
            (a) => a.currency === currency,
          )
        : undefined;
    },
  );

export const selectClientOpenFees = (clientId?: string) =>
  createSelector(
    (state: RootState) => state,
    (state) => {
      return {
        fees: clientId ? state.clientDetails[clientId]?.openFees : [],
        loading: clientId
          ? state.clientDetails[clientId]?.feesLoading
          : Loading.Idle,
      };
    },
  );

export const selectClientFeeSchedule = (clientId?: string) =>
  createSelector(
    (state: RootState) => state,
    (state) => {
      return {
        fees: clientId ? state.clientDetails[clientId]?.feeSchedule : [],
        loading: clientId
          ? state.clientDetails[clientId]?.feeScheduleLoading
          : Loading.Idle,
      };
    },
  );
const ClientDetailsActions = { ...clientDetailsSlice.actions };

export default clientDetailsSlice.reducer;
