import { useTranslation } from "react-i18next";
import { TradingPosition } from "shared/models/trading/TradingPositionmodel";
import { CurrencyFormat } from "shared/utils/currency";

type Props = {
  latestPrice: number;
  position?: TradingPosition;
};

export const PositionValues: React.FC<Props> = ({ latestPrice, position }) => {
  const { t } = useTranslation();

  if (!position) {
    return null;
  }

  return (
    <div className="text-lg">
      <div>
        {t("trade.available")}:
        <span className="ml-2 font-semibold">{position.qty}</span>
      </div>
      <div>
        {t("trade.currentValue")}:
        <span className="ml-2 font-semibold">
          {CurrencyFormat.format(position.qty * latestPrice)}
        </span>
      </div>
    </div>
  );
};
