import { SerializedError, createSlice, createSelector } from "@reduxjs/toolkit";
import { MarketInfo } from "shared/models/market/MarketModel";
import { RootState } from "../store";
import { fetchMarketInfo } from "./MarketInfoThunks";

interface MarketInfoState {
  marketInfo: MarketInfo | null | undefined;
  error?: SerializedError;
}

const initialState: MarketInfoState = {
  marketInfo: undefined,
};

const marketInfoSlice = createSlice({
  name: "[MARKET_INFO]",
  initialState,
  reducers: {
    reset(state) {
      state.marketInfo = initialState.marketInfo;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMarketInfo.rejected, (state, action) => {
      state.error = action.error;
      return state;
    });
    builder.addCase(fetchMarketInfo.fulfilled, (state, action) => {
      state.marketInfo = action.payload;
      delete state.error;
      return state;
    });
  },
});

export const selectMarketInfo = () =>
  createSelector(
    (state: RootState) => state.marketInfo,
    ({ marketInfo, error }) => ({
      marketInfo: marketInfo,
      error,
    }),
  );

const MarketInfoActions = { ...marketInfoSlice.actions };

export default marketInfoSlice.reducer;
