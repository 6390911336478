import { Response } from "modules/chat/Response";

type TextResponseProps = {
  text?: string;
  skipFeedback?: boolean;
  messageId?: string;
  sessionId?: string;
};

export const TextAssistantResponse: React.FC<TextResponseProps> = ({
  text,
  skipFeedback,
  messageId,
  sessionId,
}) => {
  return text ? (
    <Response
      text={text}
      sbIcon
      skipFeedback={skipFeedback}
      sessionId={sessionId}
      messageId={messageId}
    />
  ) : null;
};
