type Props = {
  name: string;
  value: string;
};

export const Stat: React.FC<Props> = ({ name, value }) => {
  return (
    <div className="flex w-full items-center justify-between rounded-xl px-4 py-2">
      <div className="">{name}</div>
      <div className="font-bold text-sb-gray-600">{value}</div>
    </div>
  );
};
