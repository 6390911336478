import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ArrowsUpDown } from "shared/icons/ArrowsUpDown";
import { Panel } from "shared/components/hoc/Panels";
import { RecentPortfolioActivities } from "./RecentPortfolioActivities";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import { selectClientRecentActivities } from "shared/store/client_details/ClientDetailsReducer";
import { ClientDetailsThunks } from "shared/store/client_details/ClientDetailsThunks";
import { Skeleton } from "shared/components/common/Skeleton";
import { Loading } from "shared/types/enums";
import { Labels } from "shared/components/hoc/Labels";

type Props = {
  clientId?: string;
  portfolioId?: string;
  ungroup?: boolean;
};

export const RecentActivities: React.FC<Props> = ({
  clientId,
  portfolioId,
  ungroup,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { activities, loading } = useSelector(
    selectClientRecentActivities(clientId),
  );

  useEffect(() => {
    if (clientId) {
      dispatch(ClientDetailsThunks.fetchRecentActivities({ clientId }));
    }
  }, [clientId, dispatch]);

  return (
    <Panel className="w-full">
      <div className="mb-4 flex items-center gap-2 text-lg font-semibold">
        <ArrowsUpDown className="h-5 w-5" />
        {t("recentActivities.title")}
      </div>
      {loading === Loading.InProgress && (
        <>
          <Skeleton className="mb-2 h-[10px] w-[80px] bg-sb-gray-200" />
          <Skeleton className="mb-2 h-[10px] w-[80px] bg-sb-gray-200" />
        </>
      )}
      {activities?.map((item, idx) => {
        return (
          <RecentPortfolioActivities
            title={item.title}
            symbol={item.symbol}
            activities={item.activities}
            key={idx}
            ungroup={ungroup}
          />
        );
      })}
      {loading === Loading.Finished && !activities?.length && (
        <Labels.G1>{t("recentActivities.noRecentActivities")}</Labels.G1>
      )}
    </Panel>
  );
};
