type Props = {
  className?: string;
};

export const StreetbeatIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.76562 2.91591C4.76562 4.10086 5.17782 5.18049 5.87341 5.99679H10.7425C11.3093 5.99679 11.4381 5.49648 11.0774 5.15416L6.31137 0.282679C6.02798 -0.033309 5.69307 0.0193557 5.48697 0.388008C5.04901 1.15165 4.76562 1.99428 4.76562 2.91591Z"
        fill="url(#paint0_linear_974_5335)"
      />
      <path
        d="M6.83877 9.09323C6.83916 7.96395 6.47323 6.8692 5.80286 5.99414H1.14126C0.598636 5.99414 0.475313 6.49315 0.795953 6.86084L5.3589 11.7196C5.65487 12.0085 5.97551 11.9822 6.17283 11.6145C6.60746 10.8549 6.83781 9.98274 6.83877 9.09323Z"
        fill="url(#paint1_linear_974_5335)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_974_5335"
          x1="9.41831"
          y1="7.59107"
          x2="11.2492"
          y2="6.13954"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.321474" stopColor="#01CD80" />
          <stop offset="0.817721" stopColor="#01CD52" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_974_5335"
          x1="5.85832"
          y1="9.04129"
          x2="2.25439"
          y2="7.09837"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.153302" stopColor="#37D7D7" />
          <stop offset="1" stopColor="#67E1E0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
