import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "shared/utils/firebase";

export const EmailValidityChecker: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const isStaging = user.email?.endsWith("+staging@streetbeat.com");
        const isDamian = user.email === "damian.scavo@gmail.com";
        if (!isDamian && !isStaging && !user.emailVerified) {
          navigate("/verify-email");
        }
      }
    });

    return () => unsubscribe();
  }, []);

  return <></>;
};
