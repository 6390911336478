type Props = {
  className?: string;
};

export const EvivaLogoBlack: React.FC<Props> = ({ className }) => {
  return (
    <>
      <svg
        viewBox="0 0 129 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M41.4745 21.2388C39.9927 21.2388 38.713 20.9308 37.6353 20.315C36.564 19.6928 35.7397 18.814 35.1624 17.6785C34.5851 16.5367 34.2964 15.1928 34.2964 13.6469C34.2964 12.1265 34.5851 10.7923 35.1624 9.64402C35.7461 8.48936 36.5608 7.59129 37.6064 6.94981C38.652 6.30192 39.8805 5.97797 41.2917 5.97797C42.2026 5.97797 43.0622 6.12551 43.8705 6.42059C44.6851 6.70926 45.4036 7.15829 46.0258 7.7677C46.6545 8.3771 47.1484 9.15329 47.5076 10.0963C47.8669 11.0328 48.0465 12.149 48.0465 13.4448V14.5128H35.9322V12.165H44.7076C44.7012 11.4979 44.5568 10.9045 44.2746 10.3849C43.9923 9.85892 43.5978 9.44516 43.0911 9.14367C42.5907 8.84217 42.007 8.69143 41.3398 8.69143C40.6278 8.69143 40.0023 8.86462 39.4635 9.21102C38.9247 9.55101 38.5045 10 38.203 10.5581C37.9079 11.1098 37.7572 11.716 37.7507 12.3767V14.4262C37.7507 15.2858 37.9079 16.0235 38.2222 16.6393C38.5366 17.2488 38.976 17.717 39.5405 18.0442C40.105 18.3649 40.7657 18.5253 41.5226 18.5253C42.0294 18.5253 42.4881 18.4547 42.8986 18.3136C43.3092 18.1661 43.6652 17.9512 43.9667 17.6689C44.2682 17.3867 44.4959 17.0371 44.6499 16.6201L47.9022 16.9857C47.6969 17.8453 47.3056 18.5959 46.7282 19.2373C46.1573 19.8724 45.426 20.3663 44.5344 20.7192C43.6427 21.0656 42.6228 21.2388 41.4745 21.2388Z"
          fill="#111112"
        />
        <path
          d="M63.7715 6.17041L58.5082 20.9501H54.6593L49.396 6.17041H53.1102L56.5068 17.1493H56.6607L60.067 6.17041H63.7715Z"
          fill="#111112"
        />
        <path
          d="M66.1819 20.9501V6.17041H69.6651V20.9501H66.1819ZM67.9331 4.07278C67.3815 4.07278 66.9068 3.88996 66.509 3.52431C66.1113 3.15226 65.9125 2.70643 65.9125 2.18683C65.9125 1.66082 66.1113 1.21499 66.509 0.849346C66.9068 0.477288 67.3815 0.29126 67.9331 0.29126C68.4912 0.29126 68.9659 0.477288 69.3572 0.849346C69.7549 1.21499 69.9538 1.66082 69.9538 2.18683C69.9538 2.70643 69.7549 3.15226 69.3572 3.52431C68.9659 3.88996 68.4912 4.07278 67.9331 4.07278Z"
          fill="#111112"
        />
        <path
          d="M86.4486 6.17041L81.1853 20.9501H77.3364L72.0731 6.17041H75.7872L79.1839 17.1493H79.3378L82.7441 6.17041H86.4486Z"
          fill="#111112"
        />
        <path
          d="M92.6886 21.2484C91.752 21.2484 90.9085 21.0816 90.158 20.748C89.4138 20.408 88.8237 19.9077 88.3875 19.247C87.9577 18.5862 87.7428 17.7716 87.7428 16.8029C87.7428 15.969 87.8967 15.2794 88.2047 14.7342C88.5126 14.1889 88.9327 13.7527 89.4652 13.4255C89.9976 13.0984 90.5974 12.8514 91.2645 12.6846C91.9381 12.5114 92.6341 12.3863 93.3525 12.3094C94.2185 12.2196 94.9209 12.1394 95.4598 12.0688C95.9986 11.9918 96.3899 11.8764 96.6337 11.7224C96.8839 11.562 97.009 11.3151 97.009 10.9815V10.9238C97.009 10.1989 96.7941 9.63761 96.3643 9.23989C95.9345 8.84217 95.3155 8.64332 94.5072 8.64332C93.654 8.64332 92.9773 8.82934 92.4769 9.2014C91.983 9.57346 91.6494 10.0129 91.4762 10.5196L88.2239 10.0578C88.4805 9.15971 88.9039 8.40917 89.494 7.80618C90.0842 7.19678 90.8059 6.74133 91.659 6.43984C92.5122 6.13193 93.4552 5.97797 94.4879 5.97797C95.2 5.97797 95.9088 6.06136 96.6144 6.22815C97.3201 6.39493 97.9648 6.67077 98.5485 7.05565C99.1322 7.43413 99.6005 7.95052 99.9533 8.60483C100.313 9.25914 100.492 10.077 100.492 11.0585V20.9501H97.1437V18.9198H97.0282C96.8165 19.3304 96.5182 19.7152 96.1333 20.0745C95.7549 20.4273 95.277 20.7127 94.6996 20.9308C94.1287 21.1425 93.4584 21.2484 92.6886 21.2484ZM93.5931 18.6889C94.2923 18.6889 94.8985 18.551 95.4117 18.2751C95.9249 17.9929 96.3194 17.6208 96.5952 17.1589C96.8775 16.6971 97.0186 16.1935 97.0186 15.6483V13.9066C96.9095 13.9965 96.7235 14.0798 96.4605 14.1568C96.2039 14.2338 95.9152 14.3012 95.5945 14.3589C95.2738 14.4166 94.9562 14.4679 94.6419 14.5128C94.3276 14.5577 94.0549 14.5962 93.824 14.6283C93.3044 14.6989 92.8393 14.8143 92.4288 14.9747C92.0182 15.1351 91.6943 15.3596 91.457 15.6483C91.2196 15.9305 91.1009 16.2962 91.1009 16.7452C91.1009 17.3867 91.3351 17.871 91.8034 18.1981C92.2716 18.5253 92.8682 18.6889 93.5931 18.6889Z"
          fill="#111112"
        />
        <path
          d="M105.832 21.0944C105.454 21.0944 105.127 20.9597 104.851 20.6903C104.582 20.4145 104.447 20.0873 104.447 19.7088C104.447 19.3239 104.582 18.9968 104.851 18.7274C105.127 18.4579 105.454 18.3232 105.832 18.3232C106.217 18.3232 106.545 18.4579 106.814 18.7274C107.083 18.9968 107.218 19.3239 107.218 19.7088C107.218 19.959 107.154 20.1899 107.026 20.4016C106.904 20.6133 106.737 20.7833 106.525 20.9116C106.32 21.0335 106.089 21.0944 105.832 21.0944Z"
          fill="#111112"
        />
        <path
          d="M115.871 21.2869C114.979 21.2869 114.165 21.1137 113.427 20.7673C112.689 20.4145 112.102 19.9077 111.666 19.247C111.23 18.5798 111.012 17.7716 111.012 16.8222C111.012 16.0909 111.15 15.4751 111.425 14.9747C111.701 14.4744 112.093 14.0638 112.599 13.7431C113.106 13.4223 113.706 13.1689 114.399 12.9829C115.091 12.7969 115.855 12.6526 116.689 12.5499C117.516 12.4473 118.215 12.3575 118.786 12.2805C119.364 12.2035 119.803 12.0816 120.105 11.9149C120.406 11.7481 120.557 11.4787 120.557 11.1066V10.7602C120.557 9.75307 120.255 8.96085 119.652 8.38352C119.056 7.79977 118.196 7.5079 117.074 7.5079C116.009 7.5079 115.14 7.74204 114.466 8.21032C113.799 8.6786 113.331 9.23027 113.061 9.86533L111.435 9.27838C111.769 8.47012 112.23 7.82543 112.821 7.34432C113.411 6.8568 114.071 6.50719 114.803 6.2955C115.534 6.0774 116.275 5.96835 117.025 5.96835C117.59 5.96835 118.177 6.04212 118.786 6.18966C119.402 6.3372 119.973 6.59379 120.499 6.95943C121.025 7.31866 121.452 7.82222 121.779 8.47012C122.106 9.11159 122.27 9.92627 122.27 10.9142V20.9501H120.557V18.6119H120.451C120.246 19.0481 119.941 19.4715 119.537 19.882C119.133 20.2926 118.626 20.6293 118.017 20.8924C117.407 21.1554 116.692 21.2869 115.871 21.2869ZM116.102 19.7184C117.013 19.7184 117.802 19.5164 118.469 19.1122C119.136 18.7081 119.649 18.1725 120.008 17.5053C120.374 16.8318 120.557 16.0909 120.557 15.2826V13.1465C120.429 13.2684 120.214 13.3774 119.912 13.4737C119.617 13.5699 119.274 13.6565 118.883 13.7334C118.498 13.804 118.113 13.865 117.728 13.9163C117.343 13.9676 116.997 14.0125 116.689 14.051C115.855 14.1536 115.143 14.314 114.553 14.5321C113.962 14.7502 113.51 15.0517 113.196 15.4366C112.882 15.815 112.724 16.3026 112.724 16.8991C112.724 17.7972 113.045 18.4932 113.687 18.9872C114.328 19.4747 115.133 19.7184 116.102 19.7184Z"
          fill="#111112"
        />
        <path
          d="M126.845 20.9501V6.17041H128.567V20.9501H126.845ZM127.721 3.63016C127.368 3.63016 127.066 3.51148 126.816 3.27414C126.566 3.03038 126.441 2.7385 126.441 2.39852C126.441 2.05853 126.566 1.76987 126.816 1.53252C127.066 1.28876 127.368 1.16688 127.721 1.16688C128.073 1.16688 128.375 1.28876 128.625 1.53252C128.875 1.76987 129 2.05853 129 2.39852C129 2.7385 128.875 3.03038 128.625 3.27414C128.375 3.51148 128.073 3.63016 127.721 3.63016Z"
          fill="#111112"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.7838 0C5.7235 0 0 5.7235 0 12.7838C0 19.8441 5.7235 25.5676 12.7838 25.5676C19.8441 25.5676 25.5676 19.8441 25.5676 12.7838C25.5676 5.7235 19.8441 0 12.7838 0ZM12.7912 12.7726C13.7737 13.9549 14.3101 15.4339 14.3095 16.9596C14.3081 18.1614 13.9705 19.3397 13.3334 20.366C13.0442 20.8628 12.5743 20.8982 12.1405 20.5079L5.45258 13.9436C4.98262 13.4468 5.16337 12.7726 5.95869 12.7726H12.7912ZM12.7912 12.7726H19.6237C20.419 12.7726 20.5997 12.0984 20.0936 11.6372L13.4057 5.0728C13.0081 4.647 12.5381 4.71797 12.2489 5.21473C11.6343 6.24374 11.2367 7.3792 11.2367 8.6211C11.2367 10.2178 11.8151 11.6726 12.7912 12.7726Z"
          fill="#111112"
        />
      </svg>
    </>
  );
};
