import { useTranslation } from "react-i18next";
import CurrencyInput from "react-currency-input-field";
import { Number, convertCurrencyWithDecimalCheck } from "shared/utils/converts";
import { Panel } from "shared/components/hoc/Panels";
import { FeeTemplatesIcon } from "shared/icons/FeeTemplatesIcon";
import { PropsWithChildren } from "react";

type Props = PropsWithChildren & {
  readOnlyValue?: Number;
  value?: string;
  onChange?: (value?: string, name?: string) => void;
  defaultValue?: string;
};

export const InitialInvestment: React.FC<Props> = ({
  readOnlyValue,
  value,
  onChange,
  children,
  defaultValue,
}) => {
  const { t } = useTranslation();

  return (
    <Panel>
      <div className="relative">
        {children}
        <div className="flex items-center gap-4">
          <div className="grid aspect-square h-10 shrink-0 place-items-center rounded-full bg-sb-gray-200">
            <FeeTemplatesIcon className="aspect-square h-6 text-sb-gray-500" />
          </div>
          <div className="text-xl font-medium">
            {t("proposals.initialInvestment")}
          </div>
        </div>
        <div className="ml-14 flex items-center gap-4">
          {readOnlyValue ? (
            <div className="text-2xl">
              {convertCurrencyWithDecimalCheck(readOnlyValue)}
            </div>
          ) : (
            <>
              <div className="text-2xl">$</div>
              <CurrencyInput
                allowNegativeValue={false}
                className="w-full min-w-0 text-3xl caret-sb-green-600 outline-none"
                placeholder="0"
                value={value}
                defaultValue={defaultValue}
                decimalsLimit={2}
                decimalScale={2}
                onValueChange={onChange}
              />
            </>
          )}
        </div>
      </div>
    </Panel>
  );
};
