import { FeeTemplateView } from "modules/fee_template_view/FeeTemplateView";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BackButton } from "shared/components/common/BackButton";
import { BasePage } from "shared/components/common/BasePage";
import { Spinner } from "shared/components/common/Spinner";
import { Button } from "shared/components/controls/Button";
import { Layouts } from "shared/components/hoc/Layouts";
import { FeeTemplatesService } from "shared/services/fee_templates/FeeTemplatesService";
import { FeeTemplate } from "shared/types/FeeTemplateType";

export const ViewFeeTemplatePage: React.FC = () => {
  const { t } = useTranslation();
  const { templateId } = useParams();
  const navigate = useNavigate();

  const [template, setTemplate] = useState<FeeTemplate>();
  const [loading, setLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);

  if (!templateId) {
    navigate("/");
  }

  useEffect(() => {
    if (templateId) {
      setLoading(true);
      FeeTemplatesService.getById(templateId)
        .then((t) => {
          if (!t) {
            navigate("/");
          }
          setTemplate(t);
        })
        .catch(() => navigate("/"))
        .finally(() => setLoading(false));
    }
  }, [templateId, navigate]);

  const onRemove = () => {
    if (templateId) {
      setRemoveLoading(true);
      FeeTemplatesService.remove(templateId)
        .then(() => {
          toast.success(t("feeTemplates.templateRemoved"));
          navigate("/fee-templates");
        })
        .finally(() => setRemoveLoading(false));
    }
  };

  return (
    <BasePage>
      <div>
        {loading ? (
          <div className="flex w-full justify-center">
            <Spinner className="h-8 w-8" />
          </div>
        ) : (
          <Layouts.Spaced>
            <BackButton />
            {template ? <FeeTemplateView template={template} /> : null}
            <div className="float-right mt-10">
              <Button
                label="Remove"
                onClick={onRemove}
                loading={removeLoading}
              />
            </div>
          </Layouts.Spaced>
        )}
      </div>
    </BasePage>
  );
};
