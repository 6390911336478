import React from "react";

type Props = {
  className?: string;
  fill?: string;
};

export const CalendarIcon: React.FC<Props> = ({ className, fill }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
    >
      <path d="M17 13.6667H14V17.1667H17V13.6667Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 4.33333H17V2H15V4.33333H9V2H7V4.33333H5C3.34315 4.33333 2 5.90034 2 7.83333V19.5C2 21.433 3.34315 23 5 23H19C20.6569 23 22 21.433 22 19.5V7.83333C22 5.90034 20.6569 4.33333 19 4.33333ZM7 6.66667H5C4.44772 6.66667 4 7.189 4 7.83333V19.5C4 20.1443 4.44772 20.6667 5 20.6667H19C19.5523 20.6667 20 20.1443 20 19.5V7.83333C20 7.189 19.5523 6.66667 19 6.66667H17V9H15V6.66667H9V9H7V6.66667Z"
      />
    </svg>
  );
};
