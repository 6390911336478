type Props = {
  className?: string;
};

export const TradeIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.9181 5.3335C16.1903 6.37879 18.0334 8.24932 19.8765 10.1199L21.2381 8.57254C19.5257 6.81886 17.8133 5.06526 16.3692 3.89269C14.9251 2.72012 13.7489 2.12874 12.6383 2.01868C11.5277 1.90862 10.4823 2.27996 9.23072 3.26338C7.97915 4.24681 6.44974 5.91368 4.99179 7.50918L4.95997 4.92075H3V10.9039H9.12753V8.84078H6.50736L6.50945 8.83866C7.65723 7.67027 8.80489 6.50201 9.76024 5.67039C10.7162 4.83827 11.4795 4.34312 12.2119 4.20557C12.9443 4.06801 13.6459 4.28822 14.9181 5.3335Z"
        fill="currentColor"
      ></path>
      <path
        d="M10.0675 18.6665C8.79535 17.6212 6.95223 15.7507 5.10919 13.8801L3.74755 15.4275C5.45991 17.1811 7.17235 18.9347 8.61647 20.1073C10.0606 21.2799 11.2367 21.8713 12.3473 21.9813C13.458 22.0914 14.5033 21.72 15.7549 20.7366C17.0065 19.7532 18.5359 18.0863 19.9938 16.4908L20.0257 19.0793H21.9856V13.0961H15.8581V15.1592H18.4783L18.4762 15.1613C17.3284 16.3297 16.1808 17.498 15.2254 18.3296C14.2695 19.1617 13.5062 19.6569 12.7738 19.7944C12.0413 19.932 11.3397 19.7118 10.0675 18.6665Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
