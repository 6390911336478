import { toast } from "react-toastify";
import AuthService from "shared/services/auth/AuthService";

export default function useAuth() {
  const signInWithGoogle = async () => {
    try {
      await AuthService.signInWithGoogle();
    } catch (error: any) {
      toast.error(`${error?.message}`);
    }
  };

  const signInWithApple = async () => {
    try {
      await AuthService.signInWithApple();
    } catch (error: any) {
      toast.error(`${error?.message}`);
    }
  };

  return {
    signInWithGoogle,
    signInWithApple,
  };
}
