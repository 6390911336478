import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Panel } from "shared/components/hoc/Panels";
import { useDispatch } from "shared/hooks/useDispatch";
import { Proposal } from "shared/models/proposals/ProposalsModel";
import { ProposalsService } from "shared/services/proposals/ProposalsService";
import { ProposalsThunks } from "shared/store/proposals/ProposalsThunks";
import { EditMenu } from "./ProposalEditMenu";

type Props = {
  proposal: Proposal;
};

export const ProposalReadme: React.FC<Props> = ({ proposal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [editing, setEditing] = useState(false);
  const readmeRef = useRef<HTMLTextAreaElement>(null);
  const [loading, setLoading] = useState(false);

  const onSaveClicked = () => {
    if (readmeRef.current) {
      setLoading(true);
      ProposalsService.patchProposal(proposal.proposal_id, {
        readme: readmeRef.current.value,
      })
        .then(async () => {
          await dispatch(ProposalsThunks.fetchOne(proposal.proposal_id));
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    setEditing(false);
  };

  return (
    <Panel>
      <div className="relative">
        {loading && (
          <div className="absolute bottom-0 left-0 right-0 top-0 z-50 bg-white opacity-50"></div>
        )}
        {proposal.status === "draft" && (
          <EditMenu
            editing={editing}
            setEditing={setEditing}
            onSaveClicked={onSaveClicked}
          />
        )}
        <div className="mb-6 text-lg font-semibold">
          {t("global.description")}
        </div>
        {editing ? (
          <div>
            <textarea
              ref={readmeRef}
              defaultValue={proposal.readme}
              className="w-full rounded-xl border border-sb-gray-200 p-4"
            ></textarea>
          </div>
        ) : (
          <div className="relative text-base">{proposal.readme}</div>
        )}
      </div>
    </Panel>
  );
};
