import { useRef, useState, KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";
import { PlusCircle } from "shared/icons/PlusCircle";
import { ValueToggle } from "./ValueToggle";

type Props = {
  preDefinedValues?: string[];
  title: string;
  translationKey: string;
  custom?: boolean;
  onChange?: (values: string[]) => void;
};

export const ValueSelector: React.FC<Props> = ({
  title,
  preDefinedValues,
  translationKey,
  custom,
  onChange,
}) => {
  const { t } = useTranslation();
  const [values, setValues] = useState<string[]>([]);
  const [customValues, setCustomValues] = useState<string[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const addValue = (val: string) => {
    const newValues = [...values, val];
    setValues(newValues);
    onChange?.([...newValues, ...customValues]);
  };

  const removeValue = (val: string) => {
    const newValues = values.filter((item: string) => item !== val);
    setValues(newValues);
    onChange?.([...newValues, ...customValues]);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (inputRef.current && inputRef.current.value) {
        const newValues = [...customValues, inputRef.current.value];
        setCustomValues(newValues);
        onChange?.([...values, ...newValues]);
        inputRef.current.value = "";
      }
    }
  };

  const addCustomValue = () => {
    if (inputRef.current && inputRef.current.value) {
      const newValues = [...customValues, inputRef.current.value];
      setCustomValues(newValues);
      onChange?.([...values, ...newValues]);
      inputRef.current.value = "";
    }
  };

  const removeCustomValue = (val: string) => {
    const newValues = customValues.filter((item: string) => item !== val);
    setCustomValues(newValues);
    onChange?.([...values, ...newValues]);
  };

  return (
    <div className="pt-10">
      <div className="mb-8 text-xl font-medium text-sb-gray-600">{title}</div>
      {custom && (
        <div className="relative mb-4">
          <input
            ref={inputRef}
            onKeyDown={handleKeyDown}
            type="text"
            className="w-full rounded-full border border-sb-gray-300 px-4 py-1.5"
          />
          <div
            className="absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer"
            onClick={() => addCustomValue()}
          >
            <PlusCircle className="h-7 w-7 text-sb-green-600" />
          </div>
        </div>
      )}
      <div className="flex flex-wrap items-center gap-4">
        {custom
          ? customValues.map((item, idx) => {
              return (
                <ValueToggle
                  checked={true}
                  key={"user_goal_" + idx}
                  text={item}
                  onChange={() => {
                    removeCustomValue(item);
                  }}
                />
              );
            })
          : null}
        {preDefinedValues
          ? preDefinedValues.map((item, idx) => {
              return (
                <ValueToggle
                  checked={values.includes(item)}
                  key={"goal_" + idx}
                  text={t(`proposals.${translationKey}.${item}`)}
                  onChange={(checked) => {
                    if (checked) {
                      addValue(item);
                    } else {
                      removeValue(item);
                    }
                  }}
                />
              );
            })
          : null}
      </div>
    </div>
  );
};
