import { Popover, Transition } from "@headlessui/react";
import { Fragment, PropsWithChildren } from "react";

type AlignType = "left" | "middle" | "right";

type Props = {
  button: JSX.Element;
  align?: AlignType;
};

const alignClass: Record<AlignType, string> = {
  left: "-translate-x-full",
  middle: "-translate-x-1/2",
  right: "",
};

export default function CustomPopover(props: PropsWithChildren<Props>) {
  return (
    <Popover className="relative">
      <Popover.Button className="outline-none">{props.button}</Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          className={
            "absolute z-10 mt-1 overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 " +
            (!props.align ? "-translate-x-full" : alignClass[props.align])
          }
        >
          {props.children}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
