import "react-toastify/dist/ReactToastify.css";
import "./App.css";

import { User } from "firebase/auth";
import { WelcomePage } from "pages/welcome/WelcomePage";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Location,
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { IntercomProvider } from "react-use-intercom";
import ScrollToTop from "shared/components/common/ScrollToTop";
import { Spinner } from "shared/components/common/Spinner";
import { useDispatch } from "shared/hooks/useDispatch";
import { EvivaLogoContent } from "shared/icons/EvivaLogo";
import { AssetInfoFetcher } from "shared/listeners/AssetInfoFetcher";
import { RealtimeTickerSubscriber } from "shared/listeners/RealtimeTickersSubscriber";
import {
  DecisionType,
  Role,
  RouteComponent,
  RouteConfig,
} from "shared/router/Route";
import { routeConfig } from "shared/router/routeConfig";
import { AppActions } from "shared/store/app/AppReducer";
import { INTERCOM_APP_ID } from "shared/utils/consts";
import { auth } from "shared/utils/firebase";
import IntercomListener from "shared/listeners/IntercomListener";
import { RecentSearchFetcher } from "shared/listeners/RecentSearchFetcher";
import { EmailValidityChecker } from "shared/listeners/EmailValidityChecker";
import { AiChatBubble } from "shared/components/common/AiChatBubble";
import { IdleListener } from "shared/listeners/IdleListener";

const withAccessControl =
  (
    Component: RouteComponent,
    acl: RouteConfig["acl"],
    location: Location<any>,
    redirectUrl: string | null,
  ) =>
  (currentUserRole: Role) => {
    const decision = acl[currentUserRole];
    const ComponentToRender =
      typeof Component === "function" ? Component : Component[currentUserRole];
    if (
      ComponentToRender &&
      (!decision || decision.type === DecisionType.ALLOW)
    ) {
      return <ComponentToRender />;
    }

    if (
      currentUserRole === Role.GUEST &&
      decision.type === DecisionType.REDIRECT
    ) {
      return <WelcomePage />;
    }

    if (
      currentUserRole !== Role.GUEST &&
      decision.type === DecisionType.REDIRECT &&
      redirectUrl
    ) {
      return <Navigate to={redirectUrl} />;
    }

    return <Navigate to={decision.meta || "/"} />;
  };

type RouteWrapperProps = {
  routeConfig: RouteConfig[];
  currentUserRole: Role;
};

const RouteWrapper: React.FC<RouteWrapperProps> = ({
  routeConfig,
  currentUserRole,
}) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirectUrl");

  console.log("NODE ENV", process.env.NODE_ENV);

  return (
    <Routes>
      {routeConfig.map(({ path, component, acl }) => (
        <Route
          key={path}
          path={path}
          element={withAccessControl(
            component,
            acl,
            location,
            redirectUrl,
          )(currentUserRole)}
        />
      ))}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

function App() {
  const [currentUserRole, setCurrentUserRole] = useState<Role>();
  return (
    <AppRoleProvider userRoleCallback={setCurrentUserRole}>
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          className="custom-toast"
        />
        {currentUserRole && (
          <Router>
            <IntercomListener />
            <EmailValidityChecker />
            <AssetInfoFetcher />
            <RecentSearchFetcher />
            <RealtimeTickerSubscriber />
            <ScrollToTop />
            <AiChatBubble />
            <IdleListener />
            <RouteWrapper
              routeConfig={routeConfig}
              currentUserRole={currentUserRole}
            />
          </Router>
        )}
      </IntercomProvider>
    </AppRoleProvider>
  );
}

interface AppRoleProviderProps {
  userRoleCallback(role: Role): void;
}

const AppRoleProvider: React.FC<PropsWithChildren<AppRoleProviderProps>> = ({
  children,
  userRoleCallback,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleRoleSelection = useCallback(
    async (user: User | null) => {
      setLoading(true);

      if (user) {
        userRoleCallback(Role.FA);
      } else {
        userRoleCallback(Role.GUEST);
        dispatch(AppActions.clearStoreRequest());
      }
      setLoading(false);
    },
    [dispatch, userRoleCallback],
  );

  useEffect(() => {
    const authSubscription = auth.onAuthStateChanged(async (user) => {
      await handleRoleSelection(user);
    });

    return authSubscription;
  }, [handleRoleSelection]);

  if (loading)
    return (
      <div className="flex h-screen w-full flex-col items-center justify-center">
        <EvivaLogoContent className="mb-6 w-44 shrink-0" />
        <Spinner className="h-16 w-16" />
      </div>
    );

  return <>{children}</>;
};

export default App;
