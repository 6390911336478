import {
  CreateTradingOrder,
  Side,
} from "shared/models/trading/TradingOrderModel";
import { convertCurrency, convertToNumber } from "./converts";
import { AmountType, PositionSide } from "./types";

const FractionableFormat = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 3,
});

export const getAmountType = (order: CreateTradingOrder): AmountType => {
  return order.qty !== undefined ? "qty" : "cash";
};

export const getFormattedAmountValue = (
  order: CreateTradingOrder,
  defaultValue: number = 0,
): string => {
  const type = getAmountType(order);

  if (type === "cash") {
    return convertCurrency(order.notional, defaultValue);
  }

  return getApproximateQty(order.qty, defaultValue);
};

export const checkIfOrderReducingPosition = (
  orderSide: Side,
  positionSide: PositionSide,
): boolean => {
  return (
    (positionSide === "long" && orderSide === "sell") ||
    (positionSide === "short" && orderSide === "buy")
  );
};

const getApproximateQty = (
  qty: string | number | undefined,
  defaultValue: number = 0,
) => {
  const _qty = convertToNumber(qty, defaultValue);
  const approximateQty = FractionableFormat.format(_qty);

  return _qty === parseFloat(approximateQty)
    ? approximateQty
    : `~${approximateQty}`;
};
