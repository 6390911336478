import { createAsyncThunk } from "@reduxjs/toolkit";
import { TradingOrder } from "shared/models/trading/TradingOrderModel";
import {
  OrdersService,
  RecentOrdersParams,
} from "shared/services/orders/OrdersService";

export const fetchRecentOrders = createAsyncThunk(
  "[RECENT_ORDERS] Fetch recent orders",
  async (params?: RecentOrdersParams): Promise<TradingOrder[]> => {
    return OrdersService.fetchRecent(params);
  },
);

export const RecentOrdersThunks = {
  fetchRecentOrders,
};
