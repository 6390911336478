import { useAccount } from "shared/hooks/useAccount";
import { BrokerageAccountIndeterminate } from "shared/models/brokerage_account/BrokerageAccountEventModel";
import { UploadDocumentInput } from "../components/UploadDocumentInput";
import { UpdateBirthdayForm } from "./UpdateBirthdayForm";

interface DateOfBirthMessageProps {
  indeterminateKey: keyof BrokerageAccountIndeterminate;
}

export const DateOfBirthMessage: React.FC<DateOfBirthMessageProps> = ({
  indeterminateKey,
}) => {
  const { account } = useAccount();
  const { identity } =
    account?.client?.summary?.alpaca_details?.brokerage_account || {};

  if (!identity) {
    return null;
  }

  return identity.country_of_tax_residence === "USA" ? (
    <UploadDocumentInput indeterminateKey={indeterminateKey} />
  ) : (
    <UpdateBirthdayForm />
  );
};
