import { createAsyncThunk } from "@reduxjs/toolkit";
import { CustomerAgreement } from "shared/models/account/AccountModel";
import { AccountService } from "shared/services/account/AccountService";

export const fetch = createAsyncThunk(
  "[ACCOUNT] Fetch account/me",
  async () => {
    const account = await AccountService.getMe();
    return account;
  },
);

export const fetchPendingTasks = createAsyncThunk(
  "[ACCOUNT] Fetch pending tasks",
  async () => {
    const tasks = await AccountService.getPendingTasks();
    return tasks;
  },
);

const postCustomAgreements = createAsyncThunk(
  "[ACCOUNT] Post referral code",
  async (custom_agreements: CustomerAgreement[]) => {
    await AccountService.postCustomAgreements(custom_agreements);
  },
);

export const AccountThunks = {
  fetch,
  fetchPendingTasks,
  postCustomAgreements,
};
