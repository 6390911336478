import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "shared/components/controls/Button";
import { FilledAddIcon } from "shared/icons/FilledAddIcon";

interface Props {
  className?: string;
}

export const AddContactButton: React.FC<Props> = ({ className }) => {
  const navigate = useNavigate();

  const navigateToAddClientForm = () => {
    navigate("/contacts/add-contact");
  };

  return (
    <Button
      leftIcon={<FilledAddIcon className="fill-sb-green-800" />}
      label="global.addContact"
      onClick={navigateToAddClientForm}
      className={className}
    />
  );
};
