import { DateTime } from "luxon";
import RestService from "shared/api/RestService";
import {
  BrokerageAccount,
  BrokerageAccountDocumentUpload,
} from "shared/models/brokerage_account/BrokerageAccountModel";
import {
  Onboarding,
  OnboardingAddress,
} from "shared/models/onboarding/OnboardingModel";
import { getKYCRequirements } from "shared/utils/kyc_utils";
import { FaApiRoutes } from "shared/utils/routes";
import KYCValidation from "./BrokerageAccountValidation";

const createRequest = (
  KYCData: Onboarding,
  signature: string,
  phone_data?: string,
): BrokerageAccount => {
  const ipAddress = "[filled_by_backend]";
  const nowDate = DateTime.now().toISO();

  const requirements = getKYCRequirements(KYCData);

  const requestPayload: BrokerageAccount = {
    contact: KYCValidation.validateContact(KYCData),
    identity: KYCValidation.validateIdentity(KYCData),
    disclosures: KYCValidation.validateDisclosures(KYCData),
    agreements: [
      {
        agreement: "margin_agreement",
        signed_at: nowDate,
        ip_address: ipAddress,
      },
      {
        agreement: "account_agreement",
        signed_at: nowDate,
        ip_address: ipAddress,
      },
      {
        agreement: "customer_agreement",
        signed_at: nowDate,
        ip_address: ipAddress,
      },
    ],
    documents: KYCValidation.validateDocuments(KYCData, requirements),
    phone_data,
  };

  // Create w8ben from validated payload if required
  if (requirements.w8ben) {
    const w8ben = KYCValidation.validateW8BEN(
      requestPayload.identity,
      requestPayload.contact,
      signature,
    );
    requestPayload.documents.push(w8ben);
  }

  return requestPayload;
};

export const BrokerageAccountService = {
  async get() {
    await RestService.get(FaApiRoutes.Alpaca.Account);
  },

  async post(KYCData: Onboarding, signature: string, sensor_data?: string) {
    const requestPayload = createRequest(KYCData, signature, sensor_data);
    await RestService.post(FaApiRoutes.Alpaca.Create, {
      alpaca_account: requestPayload,
    });
  },

  async uploadDocuments(
    documentPayload: BrokerageAccountDocumentUpload[],
  ): Promise<void> {
    await RestService.post(
      FaApiRoutes.Alpaca.Documents.Upload,
      documentPayload,
    );
  },

  async updateAddress(updatedAddress: OnboardingAddress): Promise<void> {
    await RestService.put(FaApiRoutes.Alpaca.Account, updatedAddress);
  },

  async putForClient(
    clientId: string,
    KYCData: Onboarding,
    signature: string,
  ): Promise<void> {
    const requestPayload = createRequest(KYCData, signature);
    await RestService.put(
      FaApiRoutes.Advisor.Clients.Details(clientId).Alpaca.Account,
      requestPayload,
    );
  },

  async uploadDocumentsForClient(
    clientId: string,
    documentPayload: BrokerageAccountDocumentUpload[],
  ): Promise<void> {
    await RestService.post(
      FaApiRoutes.Advisor.Clients.Details(clientId).Documents.Upload,
      documentPayload,
    );
  },

  async updateAddressForClient(
    clientId: string,
    updatedAddress: OnboardingAddress,
  ): Promise<void> {
    await RestService.put(
      FaApiRoutes.Advisor.Clients.Details(clientId).Alpaca.Account,
      updatedAddress,
    );
  },
};
