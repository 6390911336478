import React from "react";
import { BackButton } from "shared/components/common/BackButton";
import { Button } from "shared/components/controls/Button";

interface Props {
  onNext?: () => void;
  onPrev?: () => void;
  nextLabel?: string;
  nextDisabled: boolean;
  loading?: boolean;
}

export const OnboardingFooter: React.FC<Props> = ({
  onNext,
  onPrev,
  nextDisabled,
  loading,
  nextLabel = "global.continue",
}) => {
  return (
    <div className="flex w-full flex-row-reverse justify-between">
      {onNext ? (
        <Button
          btnStyle="black"
          label={nextLabel}
          loading={loading}
          onClick={() => onNext()}
          disabled={nextDisabled}
        />
      ) : null}
      {onPrev ? <BackButton onClick={() => onPrev()} /> : null}
    </div>
  );
};
