import React from "react";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Layouts } from "./Layouts";
import "./styles.css";

type Props = PropsWithChildren & {
  className?: string;
  onClick?: () => void;
};

export const Panel: React.FC<Props> = ({ children, className, onClick }) => {
  return (
    <div className={`rounded-card ${className}`} onClick={() => onClick?.()}>
      {children}
    </div>
  );
};

interface ValueRowProps {
  t: string;
  className?: string;
  icon?: React.ReactElement;
  dim?: boolean;
  bold?: boolean;
  value?: React.ReactElement | string;
}

const ValueRow: React.FC<ValueRowProps> = ({
  t,
  value,
  className,
  icon,
}) => {
  const { t: i18nt } = useTranslation();
  let valueElement: React.ReactElement | undefined | string;

  if (React.isValidElement(value)) {
    valueElement = value;
  } else {
    valueElement = (
      <div className="text-overflow: text-ellipsis font-semibold">{value}</div>
    );
  }
  return (
    <Layouts.Row className={className}>
      {icon}
      <div className="font-semibold">{i18nt(t)}</div>
      {valueElement}
    </Layouts.Row>
  );
};
