import { useState } from "react";
import { PlusIcon } from "shared/icons/PlusIcon";
import { AddPortfolioModal } from "./AddPortfolioModal";
import { ClientPortfolioSubscriptions } from "./ClientPortfolioSubscriptions";

type Props = {
  clientId?: string;
};

export const ClientPortfolios: React.FC<Props> = ({ clientId }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="relative">
      {clientId && (
        <>
          <AddPortfolioModal
            clientId={clientId}
            open={showModal}
            setOpen={setShowModal}
          />
          <div
            className="absolute right-4 top-4 cursor-pointer"
            onClick={() => setShowModal(true)}
          >
            <PlusIcon className="aspect-square w-6 text-sb-green-700" />
          </div>
        </>
      )}
      <ClientPortfolioSubscriptions clientId={clientId} />
    </div>
  );
};
