import { WatchlistPanel } from "modules/watchlist/WatchlistPanel";
import { CreateWatchlistModal } from "pages/assets/CreateWatchlistModal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BasePage } from "shared/components/common/BasePage";
import { Skeleton } from "shared/components/common/Skeleton";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";
import { Layouts } from "shared/components/hoc/Layouts";
import { Panel } from "shared/components/hoc/Panels";
import useAssets from "shared/hooks/useAssets";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import { PlusIcon } from "shared/icons/PlusIcon";
import { selectWatchlists } from "shared/store/watchlist/WatchlistReducer";
import { WatchlistThunks } from "shared/store/watchlist/WatchlistThunks";
import { Loading } from "shared/types/enums";

export const WatchlistPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { watchlists, loading: watchlistLoading } = useSelector(
    selectWatchlists(),
  );
  const { loading: assetsLoading } = useAssets();
  const [showCreateWatchlistModal, setShowCreateWatchlistModal] =
    useState(false);

  const loading =
    watchlistLoading === Loading.InProgress ||
    assetsLoading === Loading.InProgress;

  useEffect(() => {
    dispatch(WatchlistThunks.fetchWatchlists());
  }, []);

  return (
    <BasePage>
      <CreateWatchlistModal
        open={showCreateWatchlistModal}
        setOpen={setShowCreateWatchlistModal}
      />
      <div className="flex w-full items-center justify-between gap-4">
        <Labels.H1 className="mb-8">{t("navigation.watchlist")}</Labels.H1>
        <Button
          label="watchlist.create"
          onClick={() => setShowCreateWatchlistModal(true)}
          leftIcon={<PlusIcon className="aspect-square w-5" />}
        />
      </div>
      <Layouts.Spaced>
        {loading ? (
          <Panel className="space-y-2">
            <Skeleton className="h-12 w-32 bg-sb-gray-200" />
            <Skeleton className="h-4 w-20 bg-sb-gray-200" />
            <Skeleton className="h-4 w-16 bg-sb-gray-200" />
          </Panel>
        ) : (
          watchlists.map((watchlist) => {
            return <WatchlistPanel key={watchlist.id} watchlist={watchlist} />;
          })
        )}
      </Layouts.Spaced>
    </BasePage>
  );
};
