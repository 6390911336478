import { useTranslation } from "react-i18next";
import { ValueSelector } from "./ValueSelector";

type Props = {
  onChange?: (values: string[]) => void;
};

export const InvestmentTypes: React.FC<Props> = ({ onChange }) => {
  const { t } = useTranslation();

  const preDefinedTypes = [
    "technology",
    "finance",
    "artificialIntelligence",
    "consumerStaple",
    "industrial",
    "manufacturing",
    "transportation",
    "services",
  ];

  return (
    <ValueSelector
      title={t("proposals.investmentType")}
      translationKey="investmentType_answers"
      preDefinedValues={preDefinedTypes}
      onChange={onChange}
      custom
    />
  );
};
