import { createSlice } from "@reduxjs/toolkit";
import { AssetStats, AssetEarnings } from "shared/models/asset/AssetModel";
import { RootState } from "../../store";
import { fetchAssetStats, fetchSupportLevelPrices } from "./AssetStatsThunks";

interface AssetStatsState {
  cache: {
    [symbol: string]: {
      stats?: AssetStats;
      earnings?: AssetEarnings[];
    };
  };
  supportLevelsPrices?: number[];
}

const initialState: AssetStatsState = {
  cache: {},
};

const AssetStatsSlice = createSlice({
  name: "[ASSET_STATS]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAssetStats.fulfilled, (state, action) => {
      state.cache[action.payload.symbol] = {
        ...state.cache[action.payload.symbol],
        stats: action.payload.stats,
        earnings: action.payload.earnings,
      };
    });
    builder.addCase(fetchSupportLevelPrices.fulfilled, (state, action) => {
      state.supportLevelsPrices = action.payload;
    });
  },
});

export const selectAssetStats = (symbol: string) => (state: RootState) =>
  state.asset.stats.cache[symbol] || {};
export const selectSupportLevelPrices = (state: RootState) =>
  state.asset.stats.supportLevelsPrices;
const AssetStatsActions = { ...AssetStatsSlice.actions };

export default AssetStatsSlice.reducer;
