import { useChartColors } from "modules/portfolio_composition/hooks/useChartColors";
import { useTranslation } from "react-i18next";
import { Cell, Pie, PieChart } from "recharts";
import { PortfolioCompositionActivity } from "shared/models/portfolio/PortfolioModel";

type Props = {
  composition: PortfolioCompositionActivity;
};

const MAX_COLORS = 5;

export default function CompositionChart({ composition }: Props) {
  const { t } = useTranslation();
  const { getInnerColor } = useChartColors();

  const getConvertedPieChartPoints = (d: PortfolioCompositionActivity) => {
    if (d) {
      const assets = d.assets.map(
        ({ symbol, weight }: Record<string, string>) => {
          return {
            symbol: symbol,
            weight: parseFloat(weight),
          };
        },
      );
      let result: any = [];
      if (assets) {
        const sortedAssets = assets.sort((a, b) => b.weight - a.weight);
        result = sortedAssets.slice(0, MAX_COLORS);
        const othersWeight = sortedAssets
          .slice(MAX_COLORS, assets.length)
          .map((el) => el.weight)
          .reduce((partialSum, a) => partialSum + a, 0);
        if (othersWeight > 0) {
          result.push({
            weight: othersWeight,
            symbol: t("latestUpdates.other").toUpperCase(),
          });
        }
      }
      return result;
    }
    return [];
  };

  const chartData = getConvertedPieChartPoints(composition);

  return (
    <div className="flex w-full flex-wrap items-center justify-center gap-4">
      <PieChart width={170} height={170}>
        <Pie
          data={getConvertedPieChartPoints(composition)}
          dataKey="weight"
          nameKey="symbol"
          cx="50%"
          cy="50%"
          innerRadius={"60%"}
          outerRadius={"100%"}
        >
          {chartData.map((_: any, index: number) => {
            return <Cell key={"cell-" + index} fill={getInnerColor(index)} />;
          })}
        </Pie>
      </PieChart>
      <div className="gap-x-2 space-y-2">
        {getConvertedPieChartPoints(composition).map(
          (value: any, index: number) => {
            return (
              <div
                key={"legend_entry_" + index}
                className="flex items-center gap-2"
              >
                <div
                  className={`min-h-[4px] min-w-[4px] rounded-full md:min-h-[6px] md:min-w-[6px]`}
                  style={{ backgroundColor: getInnerColor(index) }}
                ></div>
                <div className="flex items-baseline gap-1">
                  <span className="text-sm font-semibold md:text-base">
                    {value.symbol}
                  </span>{" "}
                  <span className="text-[10px] font-light md:text-base">
                    {(value.weight * 100).toFixed(0)}%
                  </span>
                </div>
              </div>
            );
          },
        )}
      </div>
    </div>
  );
}
