import { useTranslation } from "react-i18next";
import { AvatarImage } from "shared/components/common/AvatarImage";
import { Spinner } from "shared/components/common/Spinner";
import { Button } from "shared/components/controls/Button";
import { usePortfolios } from "shared/hooks/usePortfolios";
import { Loading } from "shared/types/enums";

interface PortfolioListProps {
  portfolioId?: string;
  onCreateNewPortfolioHandler?: () => void;
  onPortfolioIdChange(id?: string): void;
}

export const PortfolioList: React.FC<PortfolioListProps> = ({
  portfolioId,
  onCreateNewPortfolioHandler,
  onPortfolioIdChange,
}) => {
  const { t } = useTranslation();
  const { loading, portfolios } = usePortfolios();

  if (loading === Loading.InProgress) {
    return <Spinner className="h-8 w-8" />;
  }

  if (!portfolios.length) {
    return (
      <div>
        <div className="mb-4">{t("proposals.noPortfoliosYet")}</div>
        <Button
          label="portfolios.create"
          onClick={onCreateNewPortfolioHandler}
        />
      </div>
    );
  }

  const activePortfolioClasses = "border-sb-green-500 bg-sb-green-100";
  return (
    <div className="flex max-w-[500px] flex-col">
      {portfolios.map((portfolio) => (
        <div
          key={portfolio.id}
          onClick={() => onPortfolioIdChange(portfolio.id)}
          className={`mb-3 flex cursor-pointer flex-row items-center justify-start rounded-xl border border-sb-gray-200 p-2 pr-4 ${
            portfolio.id === portfolioId && activePortfolioClasses
          }`}
        >
          <AvatarImage
            className="mr-3"
            imageSrc={portfolio?.cover?.uri}
            width={32}
            height={32}
          />
          <div>{portfolio.title}</div>
        </div>
      ))}
    </div>
  );
};
