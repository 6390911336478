import { createAsyncThunk } from "@reduxjs/toolkit";
import { AssetsService } from "shared/services/assets/AssetsService";

export const fetchInfoBySymbol = createAsyncThunk(
  "[ASSET_INFO] Fetch asset info",
  async (symbol: string) => {
    const info = await AssetsService.getInfo(symbol);
    return { symbol, info };
  },
);

export const AssetInfoThunks = {
  fetchInfoBySymbol,
};
