import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Spinner } from "shared/components/common/Spinner";
import { Labels } from "shared/components/hoc/Labels";
import { useDispatch } from "shared/hooks/useDispatch";
import useRealtimeTicker from "shared/hooks/useRealtimeTicker";
import { AssetWrapper } from "shared/models/asset/AssetModel";
import { Client } from "shared/models/client/ClientModel";
import {
  CreateTradingOrder,
  TradingOrderType,
} from "shared/models/trading/TradingOrderModel";
import { TradingPosition } from "shared/models/trading/TradingPositionmodel";
import { OrdersService } from "shared/services/orders/OrdersService";
import { PositionsService } from "shared/services/positions/PositionsService";
import { ClientDetailsThunks } from "shared/store/client_details/ClientDetailsThunks";
import {
  InitializeOrderPayload,
  CreateOrderActions,
} from "shared/store/create_order/CreateOrderReducer";
import { convertToNumber } from "shared/utils/converts";
import { EstimatedValue } from "../EstimatedValue";
import useCreateOrder from "../hooks/useCreateOrder";
import { useCreateOrderRequirements } from "../hooks/useCreateOrderRequirements";
import {
  useCreateOrderValidation,
  CreateOrderReviewInfo,
  CreateOrderValidator,
} from "../hooks/useCreateOrderValidation";
import OrderTypeSelect from "../OrderTypeSelect";
import { PositionValues } from "../PositionValues";
import { ReviewOrderModal } from "../ReviewOrderModal";
import { ClosePositionModal } from "./ClosePositionModal";
import LimitOrder from "./LimitOrder";
import MarketOrder from "./MarketOrder";
import StopOrder from "./StopOrder";
import TrailingStopOrder from "./TrailingStopOrder";

type Props = {
  asset: AssetWrapper;
  position: TradingPosition | undefined;
  onSuccess?: (o?: CreateTradingOrder) => void;
  client?: Client;
  compact?: boolean;
};

export default function SellAsset({
  asset,
  position,
  client,
  onSuccess,
  compact,
}: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { latestPrice } = useRealtimeTicker(asset.symbol);
  const order = useCreateOrder();
  const requirements = useCreateOrderRequirements({
    side: "sell",
    type: order.type,
    position,
    asset,
  });
  const clientId = client?.user_id;
  const validation = useCreateOrderValidation({ asset, position, clientId });

  const [loading, setLoading] = useState(false);

  const [warningsValue, setWarningsValue] = useState<string[]>();
  const [reviewed, setReviewed] = useState(false);
  const [orderValid, setOrderValid] = useState(false);
  const [reviewedOrder, setReviewedOrder] = useState<CreateOrderReviewInfo>();
  const [open, setOpen] = useState(false);
  const [estimatedValue, setEstimatedValue] = useState<number>();
  const [estimatedShares, setEstimatedShares] = useState<number>();
  const [closeAllModal, setCloseAllModal] = useState(false);
  const [closePositionLoading, setClosePositionLoading] = useState(false);

  const setType = (type: TradingOrderType) => {
    const newOrder: CreateTradingOrder = {
      ...order,
      type,
    };
    dispatch(CreateOrderActions.update({ ...newOrder }));
  };

  const orderTypeSelect = (
    <div>
      <div>{t("trade.orderType")}</div>
      <OrderTypeSelect
        type={order.type}
        onChange={setType}
        side="sell"
        className="rounded-xl border border-sb-gray-200 px-3 py-2 !font-normal !text-black"
      />
    </div>
  );

  useEffect(() => {
    if (reviewed && reviewedOrder) {
      setOpen(true);
    }
  }, [reviewed, reviewedOrder, order]);

  const typeComponents: Record<TradingOrderType, JSX.Element> = {
    limit: (
      <LimitOrder asset={asset} onEstimatedValueChange={setEstimatedValue}>
        {orderTypeSelect}
      </LimitOrder>
    ),
    stop: (
      <StopOrder asset={asset} onEstimatedValueChange={setEstimatedValue}>
        {orderTypeSelect}
      </StopOrder>
    ),
    trailing_stop: (
      <TrailingStopOrder
        asset={asset}
        onEstimatedValueChange={setEstimatedValue}
      >
        {orderTypeSelect}
      </TrailingStopOrder>
    ),
    stop_limit: (
      <LimitOrder asset={asset} onEstimatedValueChange={setEstimatedValue}>
        {orderTypeSelect}
      </LimitOrder>
    ),
    market: (
      <MarketOrder
        asset={asset}
        onEstimatedValueChange={setEstimatedValue}
        onEstimatedSharesChange={setEstimatedShares}
      >
        {orderTypeSelect}
      </MarketOrder>
    ),
  };

  const amountType =
    order.qty !== undefined
      ? t("trade.summary.amountType.qty")
      : t("trade.summary.amountType.cash");

  useEffect(() => {
    const assetClass = "us_equity";
    const newOrder: InitializeOrderPayload = {
      assetClass: assetClass,
      symbol: asset.symbol,
      side: "sell",
      type: order.type,
      allowNotional: requirements.allowNotional,
    };
    dispatch(CreateOrderActions.initialize(newOrder));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset]);

  useEffect(() => {
    setReviewed(false);
  }, [order]);

  const validateOrder = () => {
    if (order.type === "limit") {
      const tradingAtPrice = convertToNumber(order?.limit_price, 0);
      reviewOrder({ tradingAtPrice, latestPrice }, [
        validation.limitPriceValidation,
        validation.remainingFractionValidation,
      ]);
    } else if (order.type === "market") {
      const tradingAtPrice = latestPrice;
      reviewOrder({ tradingAtPrice, latestPrice });
    } else if (order.type === "stop") {
      const tradingAtPrice = convertToNumber(order?.stop_price, 0);
      reviewOrder({ tradingAtPrice, latestPrice }, [
        validation.stopPriceValidation,
        validation.remainingFractionValidation,
      ]);
    } else if (order.type === "trailing_stop") {
      const tradingAtPrice = convertToNumber(order?.trail_price, 0);
      const tradingAtPercentage = convertToNumber(order?.trail_percent, 0);
      reviewOrder({ tradingAtPrice, tradingAtPercentage, latestPrice }, [
        validation.trailingStopPercentValidation,
        validation.remainingFractionValidation,
      ]);
    }
  };

  const reviewOrder = (
    reviewInfo: CreateOrderReviewInfo,
    validators: CreateOrderValidator[] = [],
  ) => {
    const [isValid, warnings] = validation.validateOrder(reviewInfo, [
      validation.minimumAmountValidation,
      validation.reducingPositionValidation,
      validation.buyingPowerValidation,
      validation.takeProfitValidation,
      validation.stopLossValidation,
      ...validators,
    ]);

    setOrderValid(isValid);
    if (isValid) {
      setReviewed(true);
      setReviewedOrder(reviewInfo);
    }
    setWarningsValue(warnings);
  };

  const makeOrder = () => {
    if (order && orderValid && clientId) {
      setLoading(true);
      OrdersService.create([order], clientId)
        .then(() => {
          if (clientId) {
            dispatch(ClientDetailsThunks.fetchOrders({ clientId, limit: 10 }));
            dispatch(ClientDetailsThunks.fetchPositions({ clientId }));
          }
          onSuccess?.(order);
          setReviewed(false);
          setReviewedOrder(undefined);
        })
        .finally(() => setLoading(false));
    }
  };

  const closeAll = () => {
    if (client) {
      setClosePositionLoading(true);
      PositionsService.closePosition(asset.symbol, client.user_id)
        .then(() => {
          toast.success(t("trade.positionClosed"));
          dispatch(
            ClientDetailsThunks.fetchPositions({
              clientId: client.user_id,
            }),
          );
        })
        .finally(() => {
          setClosePositionLoading(false);
          setCloseAllModal(false);
        });
    }
  };

  return (
    <div>
      <ClosePositionModal
        open={closeAllModal}
        setOpen={setCloseAllModal}
        loading={closePositionLoading}
        onClosePosition={closeAll}
        symbol={asset.symbol}
      />
      {position ? (
        <>
          {!compact && (
            <div
              className="flex cursor-pointer justify-end font-semibold text-sb-green-light"
              onClick={() => setCloseAllModal(true)}
            >
              {t("global.closeAll")}
            </div>
          )}
          {typeComponents[order.type]}
          <div
            className={
              (compact ? "mt-12 flex w-full justify-between gap-4" : "w-full") +
              " !mt-12"
            }
          >
            {compact && (
              <PositionValues latestPrice={latestPrice} position={position} />
            )}
            <div className="flex justify-end gap-4">
              <EstimatedValue
                estimatedValue={estimatedValue}
                estimatedShares={estimatedShares}
                qtyType={order.qty ? "Shares" : "USD"}
              />
              <div
                className={"green-btn h-fit text-center"}
                onClick={() => {
                  if (!loading) {
                    validateOrder();
                  }
                }}
              >
                {loading ? (
                  <div className="flex justify-center">
                    <Spinner className="h-4 w-4 fill-white text-sb-gray-500" />
                  </div>
                ) : (
                  <>{t("global.review")}</>
                )}
              </div>
            </div>
          </div>
          {reviewed && reviewedOrder && (
            <ReviewOrderModal
              open={open}
              setOpen={setOpen}
              order={order}
              reviewedOrder={reviewedOrder}
              amountType={amountType}
              warningsValue={warningsValue}
              setReviewed={setReviewed}
              makeOrder={makeOrder}
            />
          )}
          {warningsValue && warningsValue.length > 0 ? (
            <div className="my-4 space-y-2 text-base">
              <div className="font-roobert text-sm font-semibold">
                {t("trade.warnings_title").toUpperCase()}
              </div>
              {warningsValue?.map((item, idx) => {
                return <div key={"warning_" + idx}>{item}</div>;
              })}
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <Labels.G1>No position</Labels.G1>
      )}
    </div>
  );
}
