import { ManualTrading } from "modules/client_portfolios/ManualTrading";
import { PortfolioSubscription } from "modules/client_portfolios/PortfolioSubscription";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Labels } from "shared/components/hoc/Labels";
import { Layouts } from "shared/components/hoc/Layouts";
import { Panel } from "shared/components/hoc/Panels";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import { selectClientPortfolios } from "shared/store/client_details/ClientDetailsReducer";
import { ClientDetailsThunks } from "shared/store/client_details/ClientDetailsThunks";
import { selectPortfolios } from "shared/store/portfolios/PortfoliosReducer";
import { Loading } from "shared/types/enums";

type Props = {
  clientId?: string;
};

export const ClientPortfolioSubscriptions: React.FC<Props> = ({ clientId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { portfolios, loading } = useSelector(
    selectClientPortfolios(clientId || ""),
  );

  useEffect(() => {
    if (clientId) {
      dispatch(ClientDetailsThunks.fetchPortfolios({ clientId }));
    }
  }, [dispatch, clientId]);

  return (
    <Panel>
      <Labels.H3>{t("clientDetails.portfolios")}</Labels.H3>
      <Layouts.Spaced className="mt-8">
        {portfolios?.map((item, idx) => {
          return (
            <PortfolioSubscription
              key={idx}
              portfolio={item}
              clientId={clientId}
            />
          );
        })}
        {clientId && <ManualTrading clientId={clientId} />}
        {loading === Loading.Finished && !portfolios.length ? (
          <Labels.G1>{t("portfolios.noPortfolios")}</Labels.G1>
        ) : null}
      </Layouts.Spaced>
    </Panel>
  );
};
