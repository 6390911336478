import { createSlice, createSelector } from "@reduxjs/toolkit";
import { FeePayoutTerm } from "shared/models/fees/FeesModel";
import { Loading } from "shared/types/enums";
import { RootState } from "../store";
import { fetchPayoutTerms } from "./FeePayoutTermThunks";

interface FeePayoutTermsState {
  payoutTerms: FeePayoutTerm | undefined;
  loading: Loading;
}

const initialState: FeePayoutTermsState = {
  payoutTerms: undefined,
  loading: Loading.Idle,
};

const feePayoutTermsSlice = createSlice({
  name: "[PAYOUT_TERMS]",
  initialState,
  reducers: {
    reset(state) {
      state.payoutTerms = initialState.payoutTerms;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPayoutTerms.pending, (state) => {
      state.loading = Loading.InProgress;
      return state;
    });
    builder.addCase(fetchPayoutTerms.rejected, (state) => {
      state.loading = Loading.InProgress;
      return state;
    });
    builder.addCase(fetchPayoutTerms.fulfilled, (state, action) => {
      state.payoutTerms = action.payload;
      return state;
    });
  },
});

export const selectPayoutTerms = () =>
  createSelector(
    (state: RootState) => state.payoutTerms,
    ({ payoutTerms, loading }) => ({
      payoutTerms: payoutTerms,
      loading: loading,
    }),
  );

const FeePayoutTermsActions = { ...feePayoutTermsSlice.actions };

export default feePayoutTermsSlice.reducer;
