import { GlobalSearch } from "modules/global_search/GlobalSearch";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";
import { Layouts } from "shared/components/hoc/Layouts";
import { MagnifyingGlassIcon } from "shared/icons/MagnifyingGlassIcon";
import { AssetWrapper } from "shared/models/asset/AssetModel";
import {
  EditPortfolioAllocation,
  Portfolio,
  PortfolioAssetAllocation,
} from "shared/models/portfolio/PortfolioModel";
import { PortfoliosService } from "shared/services/portfolios/PortfoliosService";
import { Allocation } from "./Allocation";
import { AllocationChart } from "./AllocationChart";

type Props = {
  portfolio: Portfolio;
};

export const EditAllocations: React.FC<Props> = ({ portfolio }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [saveLoading, setSaveLoading] = useState(false);
  const [allocations, setAllocations] = useState<PortfolioAssetAllocation[]>(
    [],
  );

  const setAllocation = (symbol: string, allocation: number) => {
    setAllocations(
      allocations.map((item) => {
        if (item.symbol === symbol) {
          return { ...item, weight: "" + allocation };
        }
        return item;
      }),
    );
  };

  const addStock = (asset: AssetWrapper, allocation: number) => {
    let a = [...allocations];
    const symbolOrConid = asset.alpaca_details
      ? asset.alpaca_details.symbol
      : asset.ibrk_details?.conid;
    const exists = a.find((a) => a.symbol === "" + symbolOrConid);
    if (!exists) {
      a.push({
        symbol: "" + symbolOrConid,
        weight: "" + allocation,
        name: asset.name,
        ticker: asset.symbol,
      });
    }
    setAllocations(a);
  };

  const removeStock = (symbol: string) => {
    setAllocations(
      allocations.filter((item) => {
        return item.symbol !== symbol;
      }),
    );
  };

  const saveAllocations = () => {
    const data: EditPortfolioAllocation[] = allocations.map((item) => {
      return {
        contract_id: item.symbol,
        weight: item.weight,
      };
    });
    setSaveLoading(true);
    PortfoliosService.editAllocations(portfolio.id, { allocations: data })
      .then(() => {
        toast.success(t("portfolios.edit.success"));
        navigate(`/portfolios/${portfolio.id}`);
      })
      .finally(() => setSaveLoading(false));
  };

  useEffect(() => {
    if (portfolio.allocations && !allocations.length) {
      setAllocations(portfolio.allocations);
    }
  }, [portfolio, allocations]);

  const totalAllocated = Math.trunc(
    allocations
      .map((item) => parseFloat(item.weight))
      .reduce((a, b) => a + b, 0) * 100 || 0,
  );

  return (
    <div>
      <div className="my-8 flex justify-center">
        <AllocationChart data={allocations} />
      </div>
      <Labels.H2>
        {t("portfolios.form.totalAllocated")}{" "}
        <span
          className={
            totalAllocated === 100 ? "text-sb-green-700" : "text-sb-red-700"
          }
        >
          {totalAllocated}%
        </span>
      </Labels.H2>
      <div className="mt-10">
        <div className="relative">
          <div className="absolute left-4 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="h-5 w-5 text-sb-gray-600" />
          </div>
          <GlobalSearch onClicked={(asset) => addStock(asset, 0)} />
        </div>
      </div>
      <Layouts.Spaced className="my-4">
        {allocations?.map((item, idx) => {
          return (
            <Allocation
              key={"allocation_" + idx}
              item={item}
              portfolioItem={portfolio.allocations?.find(
                (a) => a.symbol === item.symbol,
              )}
              onAllocationChange={setAllocation}
              onRemove={removeStock}
            />
          );
        })}
      </Layouts.Spaced>
      <div className="my-8">
        <Button
          label={"portfolios.form.save"}
          disabled={totalAllocated !== 100}
          loading={saveLoading}
          onClick={saveAllocations}
        />
      </div>
    </div>
  );
};
