import React, { useState } from "react";
import { Button } from "shared/components/controls/Button";
import { useDispatch } from "shared/hooks/useDispatch";
import { FilledAddIcon } from "shared/icons/FilledAddIcon";
import { PortfolioAssistantService } from "shared/services/portfolio_assistant/PortfolioAssistantService";
import { AppActions } from "shared/store/app/AppReducer";

interface Props {
  className?: string;
}

export const CreatePortfolioButton: React.FC<Props> = ({ className }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const createNewSession = () => {
    setLoading(true);
    PortfolioAssistantService.createSession()
      .then((s) => {
        dispatch(AppActions.setAssistantActiveSession(s));
        dispatch(AppActions.openAiAssistant());
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Button
      leftIcon={<FilledAddIcon className="fill-sb-green-800" />}
      label="portfolios.create"
      onClick={createNewSession}
      className={className}
      loading={loading}
    />
  );
};
