import { DateTime } from "luxon";
import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Labels } from "shared/components/hoc/Labels";
import { Layouts } from "shared/components/hoc/Layouts";
import { Panel } from "shared/components/hoc/Panels";
import { useAccount } from "shared/hooks/useAccount";
import { BrokerageAccountIndeterminate } from "shared/models/brokerage_account/BrokerageAccountEventModel";
import { UploadDocumentInput } from "../components/UploadDocumentInput";
import { AddressVerificationMessage } from "../messages/AddressVerificationMessage";
import { DateOfBirthMessage } from "../messages/DateOfBirthMessage";
import { W8BENMessage } from "../messages/W8BenMessage";

type Props = {
  className?: string;
};

export const RequiredInformation: React.FC<Props> = ({ className }) => {
  const { account } = useAccount();
  const { custom_message, last_event, updated_at } =
    account?.client?.summary?.alpaca_details?.brokerage_account || {};
  const message =
    custom_message || last_event?.kyc_results?.additional_information;

  if (
    !last_event ||
    !last_event.kyc_results ||
    !last_event.kyc_results.indeterminate
  ) {
    if (!custom_message) return null;

    return (
      <DocumentsRequiredPanel date={updated_at}>
        <Labels.B1>{custom_message}</Labels.B1>
      </DocumentsRequiredPanel>
    );
  }

  const {
    kyc_results: { indeterminate },
    at,
  } = last_event;

  const indeterminateList = createIndeterminateList(indeterminate);

  if (!indeterminateList.length) return null;

  const containsWatchlistHit = indeterminateList.includes("WATCHLIST_HIT");

  const filteredIndeterminateList = containsWatchlistHit
    ? // only show watchlist_hit, because other actions are not relevant
      indeterminateList.filter((item) => item !== "WATCHLIST_HIT")
    : indeterminateList.filter((item) => {
        // remove undefined OTHER action required
        if (item === "OTHER" && !message) return false;
        return true;
      });

  return (
    <DocumentsRequiredPanel date={at}>
      {filteredIndeterminateList.map((key) => (
        <DocumentsRequiredMessage
          key={key}
          indeterminateKey={key}
          message={message}
        />
      ))}
    </DocumentsRequiredPanel>
  );
};

interface DocumentsRequiredPanelProps extends PropsWithChildren {
  date?: string;
}

const DocumentsRequiredPanel: React.FC<DocumentsRequiredPanelProps> = ({
  date,
  children,
}) => {
  return (
    <Panel>
      <Layouts.Grid>
        {date ? (
          <Labels.B1>
            {DateTime.fromISO(date).toFormat("MMM dd, hh:mm")}
          </Labels.B1>
        ) : null}
        {children}
      </Layouts.Grid>
    </Panel>
  );
};

export const OtherIndeterminateActions: React.FC = () => {
  const { t } = useTranslation();
  const { account } = useAccount();
  const { last_event } =
    account?.client?.summary?.alpaca_details?.brokerage_account || {};

  if (!last_event) return null;
  const indeterminate = last_event.kyc_results?.indeterminate;

  const indeterminateList = createIndeterminateList(indeterminate);

  const otherKey: (keyof BrokerageAccountIndeterminate)[] = ["OTHER"];
  const otherAndWatchListHitKeys: (keyof BrokerageAccountIndeterminate)[] = [
    "WATCHLIST_HIT",
    "OTHER",
    "W8BEN_CORRECTION",
  ];

  const onlyOtherRequired = indeterminateList.every((i) =>
    otherKey.includes(i),
  );
  const otherAndWatchListHitRequired = indeterminateList.every((i) =>
    otherAndWatchListHitKeys.includes(i),
  );
  const showOtherIndeterminateActions =
    onlyOtherRequired || otherAndWatchListHitRequired;

  if (!showOtherIndeterminateActions) return null;

  return (
    <Layouts.Grid>
      <UploadDocumentInput message={t("documents.uploadDocument")} />
    </Layouts.Grid>
  );
};
interface DocumentsRequiredMessageProps {
  indeterminateKey: keyof BrokerageAccountIndeterminate;
  message?: string;
}

const DocumentsRequiredMessage = ({
  indeterminateKey,
  message,
}: DocumentsRequiredMessageProps) => {
  const { t } = useTranslation();

  switch (indeterminateKey) {
    case "ADDRESS_VERIFICATION":
      return <AddressVerificationMessage />;
    case "DATE_OF_BIRTH":
      return <DateOfBirthMessage indeterminateKey={indeterminateKey} />;
    case "WATCHLIST_HIT":
      return <Labels.B1>{t(`errors.kyc.${indeterminateKey}`)}</Labels.B1>;
    case "OTHER":
      // here for non US update your whole W8BEN form
      return (
        <UploadDocumentInput
          indeterminateKey={indeterminateKey}
          message={message}
        />
      );
    case "W8BEN_CORRECTION":
      return <W8BENMessage />;
    case "OTHER_PARTNER":
      return <Labels.B1>{t(`errors.kyc.${indeterminateKey}`)}</Labels.B1>;
    case "SELFIE_VERIFICATION":
      return <UploadDocumentInput indeterminateKey="SELFIE_VERIFICATION" />;
    default:
      return <UploadDocumentInput indeterminateKey={indeterminateKey} />;
  }
};

const createIndeterminateList = (
  indeterminate: BrokerageAccountIndeterminate | undefined,
) => {
  if (!indeterminate) return [];

  const keys: (keyof BrokerageAccountIndeterminate)[] = [
    "IDENTITY_VERIFICATION",
    "TAX_IDENTIFICATION",
    "ADDRESS_VERIFICATION",
    "DATE_OF_BIRTH",
    "PEP",
    "FAMILY_MEMBER_PEP",
    "CONTROL_PERSON",
    "AFFILIATED",
    "COUNTRY_NOT_SUPPORTED",
    "INVALID_IDENTITY_PASSPORT",
    "WATCHLIST_HIT",
    "SELFIE_VERIFICATION",
    "W8BEN_CORRECTION",
    "OTHER_PARTNER",
    "OTHER",
  ];

  return keys.filter((k) => !!indeterminate[k]);
};
