import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { BackButton } from "shared/components/common/BackButton";
import { Button } from "shared/components/controls/Button";
import AuthService from "shared/services/auth/AuthService";
import { ForgotPassword } from "./ForgotPassword";

type Props = {
  onBackClicked: () => void;
};

export const EmailSignInForm: React.FC<Props> = ({ onBackClicked }) => {
  const { t } = useTranslation();
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [forgotPassword, setForgotPassword] = useState(false);

  const signInHandler = async (e: any) => {
    e.preventDefault();
    const email = emailRef.current?.value;
    const password = passwordRef.current?.value;

    if (!email || !password) {
      return;
    }

    setLoading(true);
    try {
      await AuthService.signInWithEmailAndPassword(
        email.trim(),
        password.trim(),
      );
    } catch (error: any) {
      toast.error(`${error?.message}`);
    } finally {
      setLoading(false);
    }
  };

  if (forgotPassword) {
    return <ForgotPassword onBackClicked={() => setForgotPassword(false)} />;
  }

  return (
    <div>
      <form onSubmit={signInHandler} className="space-y-4">
        <input
          ref={emailRef}
          type="text"
          className="w-full rounded-lg bg-sb-gray-100 p-4 text-lg"
          placeholder={t("auth.emailAddress")}
        ></input>
        <input
          ref={passwordRef}
          type="password"
          className="w-full rounded-lg bg-sb-gray-100 p-4 text-lg"
          placeholder={t("auth.password")}
        ></input>
        <div className="flex items-center justify-between">
          <BackButton onClick={onBackClicked} />
          <Button
            btnStyle="black"
            loading={loading}
            className="w-fit"
            label="auth.signIn"
            type="submit"
          />
        </div>
        <div>
          <div
            className="mt-8 cursor-pointer text-center text-base underline"
            onClick={() => setForgotPassword(true)}
          >
            {t("auth.forgotPassword")}
          </div>
        </div>
      </form>
    </div>
  );
};
