import { HTMLInputTypeAttribute, LegacyRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AttachmentIcon } from "shared/icons/AttachmentIcon";
import { CloseIcon } from "shared/icons/CloseIcon";
import { AllowedFileMimeType, AllowedFileMimeTypes } from "shared/utils/types";
import { Layouts } from "./Layouts";

type TextProps = {
  label?: string;
  placeholder?: string;
  reference?: LegacyRef<HTMLInputElement> | null;
  className?: string;
  type?: HTMLInputTypeAttribute;
  value?: string;
  autoCapitalize?: string | undefined;
  error?: string;
  inputMode?:
    | "none"
    | "search"
    | "text"
    | "tel"
    | "email"
    | "url"
    | "numeric"
    | "decimal";
  onChange?(value: string): void;
  onSubmit?(): void;
  inputClassName?: string;
};

const Text: React.FC<TextProps> = ({
  label,
  placeholder,
  value,
  reference,
  type,
  inputMode,
  autoCapitalize,
  className = "",
  inputClassName = "",
  error,
  onChange,
  onSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={["relative", className].join(" ")}>
        <input
          type={type ? type : "text"}
          inputMode={inputMode ? inputMode : "text"}
          id={`input_id_${label}`}
          className={`peer block w-full appearance-none rounded-xl border-0 bg-sb-gray-100 px-4 pb-2.5 pt-5 text-base text-gray-900 focus:border-sb-green-700 focus:outline-none focus:ring-0 ${inputClassName}`}
          placeholder={placeholder ? t(placeholder) : ""}
          ref={reference}
          autoCapitalize={autoCapitalize}
          value={value}
          onChange={onChange ? (e) => onChange(e.target.value) : undefined}
          onSubmit={onSubmit}
        />
        <label
          htmlFor={`input_id_${label}`}
          className="absolute left-2.5 top-4  z-10 origin-[0] -translate-y-4 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-sb-green-700"
        >
          {label ? t(label) : null}
        </label>
      </div>
      {error ? <div>{error}</div> : null}
    </>
  );
};

type CheckboxProps = {
  label: string;
  reference?: LegacyRef<HTMLInputElement> | null;
  className?: string;
  checked?: boolean;
  onChange?(value: boolean): void;
};

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  reference,
  checked,
  className = "",
  onChange,
}) => {
  const { t } = useTranslation();
  return (
    <div className={["relative", className].join(" ")}>
      <input
        type="checkbox"
        id={`input_checkbox_${label}`}
        ref={reference}
        value={checked ? "true" : "false"}
        checked={checked}
        onChange={onChange ? () => onChange(!checked) : undefined}
      />
      <label
        htmlFor={`input_checkbox_${label}`}
        className="pl-2 text-base text-gray-900"
      >
        {t(label)}
      </label>
    </div>
  );
};

type FileProps = {
  label: string;
  placeholder?: string;
  reference?: LegacyRef<HTMLInputElement> | null;
  className?: string;
  value?: string;
  autoCapitalize?: string | undefined;
  error?: string;
  onAttach(file: File, base64: string, mimeType: AllowedFileMimeType): void;
};

const File: React.FC<FileProps> = ({
  label,
  placeholder,
  value,
  reference,
  autoCapitalize,
  className = "",
  error,
  onAttach,
}) => {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File | undefined>();

  const handleAttachment = (file: File) => {
    const mimeType = AllowedFileMimeTypes.find((v) => v === file.type);
    if (!mimeType) {
      // TODO: error t('info.unsupported_heic_type')
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target?.result;
      let content;
      if (typeof result === "string") {
        const base64String = result.split(",")[1];
        content = base64String;
      } else if (result instanceof ArrayBuffer) {
        const base64String = new TextDecoder("utf-8").decode(result);
        content = base64String;
      }
      if (content) {
        onAttach(file, content, mimeType);
        setSelectedFile(file);
      } else {
        // TODO: handle error
      }
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className={className}>
      <Layouts.Row className="items-center">
        <div className="peer block w-full appearance-none rounded-xl border border-sb-gray-100 p-4 text-base text-gray-900 focus:border-sb-green-700 focus:outline-none focus:ring-0">
          <input
            type="file"
            id={`input_id_${label}`}
            className="absolute h-full w-full opacity-0"
            placeholder={placeholder ? t(placeholder) : undefined}
            ref={reference}
            autoCapitalize={autoCapitalize}
            onChange={(e) => {
              const files = e.target?.files;
              if (!files) return;
              const file = files[0];
              if (file) handleAttachment(file);
            }}
          />
          <label htmlFor={`input_id_${label}`} className="m-0">
            {selectedFile ? selectedFile.name : t(label)}
          </label>
        </div>
        {selectedFile ? (
          <div onClick={() => setSelectedFile(undefined)}>
            <CloseIcon className="h-5 w-5" />
          </div>
        ) : (
          <AttachmentIcon />
        )}
      </Layouts.Row>
      {error && <p className="ml-3 text-sm text-sb-red-600">{error}</p>}
    </div>
  );
};

export const Inputs = {
  Text,
  Checkbox,
  File,
};
