import { ref, onValue } from "firebase/database";
import { useCallback, useEffect } from "react";
import { RealTimeTickerLatestClose } from "shared/models/asset/AssetModel";
import { RealtimePricesActions } from "shared/store/realtime_prices/RealtimePricesReducer";
import { selectRealtimeSubscriptions } from "shared/store/realtime_prices/RealtimeSubscriptionsReducer";
import { RealtimeSubscriptionsThunks } from "shared/store/realtime_prices/RealtimeSubscriptionsThunks";
import { auth, database } from "shared/utils/firebase";
import { useDispatch, useSelector } from "./useDispatch";

const cache: {
  changed: boolean;
  tickers: { [key: string]: RealTimeTickerLatestClose };
} = {
  changed: false,
  tickers: {},
};

export default async function useRefreshRealtimeTickers() {
  const dispatch = useDispatch();
  const { subscriptions } = useSelector(selectRealtimeSubscriptions);
  const symbols = Object.keys(subscriptions);
  const hash = JSON.stringify(symbols);

  const setupInterval = useCallback(() => {
    let refreshTimeout: NodeJS.Timeout | null = null;

    const refresh = async () => {
      if (symbols.length > 0) {
        dispatch(RealtimeSubscriptionsThunks.subscribe(symbols));
      }
      refreshTimeout = setTimeout(refresh, 50000);
    };

    refresh();

    return () => {
      if (refreshTimeout) {
        clearTimeout(refreshTimeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  useEffect(() => {
    const clearInterval = setupInterval();
    return () => clearInterval();
  }, [setupInterval]);

  const setupManyInterval = useCallback(() => {
    let refreshTimeout: NodeJS.Timeout | null = null;

    const refresh = async () => {
      if (cache.changed) {
        dispatch(RealtimePricesActions.updateMany(cache.tickers));
        cache.tickers = {};
        cache.changed = false;
      }
      refreshTimeout = setTimeout(refresh, 5000);
    };

    refresh();

    return () => {
      if (refreshTimeout) {
        clearTimeout(refreshTimeout);
      }
    };
  }, [dispatch]);

  // This can be used to group updates and avoid too many dispatches
  useEffect(() => {
    const clearInterval = setupManyInterval();
    return () => clearInterval();
  }, [dispatch, setupManyInterval]);

  const getEnv = () => {
    if (auth.currentUser && auth.currentUser.email) {
      if (auth.currentUser.email.endsWith("+staging@streetbeat.com"))
        return "staging";
      if (auth.currentUser.email.endsWith("+test@streetbeat.com"))
        return "staging";
    }
    return "production";
  };

  useEffect(() => {
    const callbacks = symbols.map((symbol) => {
      const securedSymbol = symbol.replace(".", "_");
      const env = getEnv();
      const subscriptionPath = env === "staging" ? "stocks_staging" : "stocks";
      const symbolRef = ref(database(), `${subscriptionPath}/${securedSymbol}`);
      const callback = onValue(symbolRef, (snapshot) => {
        if (snapshot.key && snapshot.exists()) {
          const symbolSnapshot = snapshot.key;
          const ticker = snapshot.val();
          const cachedTicker = cache.tickers[symbolSnapshot] || {
            latestPrice: 0,
            previousClose: 0,
          };
          if (ticker.latestPrice) cachedTicker.latestPrice = ticker.latestPrice;
          if (ticker.previousClose)
            cachedTicker.previousClose = ticker.previousClose;
          cache.changed = true;
          cache.tickers[symbolSnapshot] = cachedTicker;
        }
      });
      return {
        callback: callback,
      };
    });

    // Stop listening for updates when no longer required
    return () => {
      callbacks.forEach((item) => {
        item.callback();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash, auth.currentUser]);
}
