import { GlobalSearch } from "modules/global_search/GlobalSearch";
import { useTranslation } from "react-i18next";
import { Modal } from "shared/components/common/Modal";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";
import { useDispatch } from "shared/hooks/useDispatch";
import CheckIcon from "shared/icons/CheckIcon";
import { PlusIcon } from "shared/icons/PlusIcon";
import { Watchlist } from "shared/models/watchlist/WatchlistModel";
import { WatchlistThunks } from "shared/store/watchlist/WatchlistThunks";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  watchlist: Watchlist;
};

export const AddSymbolToWatchlistModal: React.FC<Props> = ({
  open,
  setOpen,
  watchlist,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onWatchlistSave = async (symbol: string) => {
    const isIn = watchlist.assets.includes(symbol);
    const name = watchlist.name;
    if (!name) {
      return;
    }
    if (isIn) {
      dispatch(
        WatchlistThunks.removeSymbol({
          id: watchlist.id,
          symbol,
        }),
      );
    } else {
      dispatch(
        WatchlistThunks.addSymbol({
          id: watchlist.id,
          watchlistData: { name, symbol },
        }),
      );
    }
  };

  const getRightComponent = (symbol: string) => {
    const isIn = watchlist.assets.includes(symbol);
    return (
      <div
        className="cursor-pointer px-2 text-sb-green-600"
        onClick={() => onWatchlistSave(symbol)}
      >
        {isIn ? (
          <CheckIcon className="aspect-square w-5" strokeWidth={1.5} />
        ) : (
          <PlusIcon className="aspect-square w-5" />
        )}
      </div>
    );
  };

  const getRowClassName = (symbol: string) => {
    const isIn = watchlist.assets.includes(symbol);
    return isIn ? "bg-sb-green-100" : "";
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <Labels.H3>{t("watchlist.addProduct")}</Labels.H3>
      <GlobalSearch
        wFull
        assetListRightComponent={getRightComponent}
        assetListRowClass={getRowClassName}
        disableClick
      />
      <Button
        className="mt-4 w-full"
        label="global.done"
        onClick={() => setOpen(false)}
      />
    </Modal>
  );
};
