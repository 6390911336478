import { Fragment } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  request: Record<string, any>;
};

export const NetLiquidation: React.FC<Props> = ({ request }) => {
  const { t } = useTranslation();

  let liquidationRanges = [];
  for (
    let i = 0;
    request[`netLiquidation_navRange_${i}_min`] !== undefined;
    i++
  ) {
    const min = request[`netLiquidation_navRange_${i}_min`];
    const max =
      request[`netLiquidation_navRange_${i}_max`] ||
      t("feeTemplateForm.netLiquidation.maximum");
    liquidationRanges.push(
      <Fragment key={"nl_" + i}>
        <div className="text-lg font-medium">
          {t("feeTemplateForm.navRange")} {min} - {max}
        </div>
        {request[`netLiquidation_feePct_${i}`] ? (
          <div>{request[`netLiquidation_feePct_${i}`]}%</div>
        ) : (
          <div>-</div>
        )}
      </Fragment>,
    );
  }

  return (
    <>
      <div className="col-span-2 mt-8 border-b pb-2 text-xl font-semibold">
        {t("feeTemplateForm.netLiquidation.title")}
      </div>
      <div className="text-lg font-medium">
        {t("feeTemplateForm.netLiquidation.type")}
      </div>
      <div>
        {t(`feeTemplateForm.netLiquidation.${request["netLiquidation_type"]}`)}
      </div>
      <div className="text-lg font-medium">{t("feeTemplateForm.applied")}</div>
      <div>
        {t(
          `feeTemplateForm.netLiquidation.${request["netLiquidation_applied"]}`,
        )}
      </div>
      {liquidationRanges}
    </>
  );
};
