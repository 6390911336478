import { useTranslation } from "react-i18next";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import { RecentUploadedDocuments } from "./RecentUploadedDocuments";
import {
  OtherIndeterminateActions,
  RequiredInformation,
} from "./RequiredInformation";

export const ActionRequiredReviewPendingView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Panel>
      <Labels.H3>{t(`trader.action.REVIEW_PENDING`)}</Labels.H3>
      <RequiredInformation />
      <RecentUploadedDocuments />
      <OtherIndeterminateActions />
    </Panel>
  );
};
