import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import { RootState } from "../store";
import { Loading } from "shared/types/enums";
import { ProposalsThunks, fetchOne } from "./ProposalsThunks";
import { Proposal } from "shared/models/proposals/ProposalsModel";

const proposalsAdapter = createEntityAdapter<Proposal>({
  selectId: (proposal) => proposal.proposal_id,
});

const { selectAll, selectById } = proposalsAdapter.getSelectors(
  (state: RootState) => state.proposals,
);

interface PortfoliosState {
  loading: Loading;
  fetched: boolean;
}

const initialState = proposalsAdapter.getInitialState<PortfoliosState>({
  loading: Loading.Idle,
  fetched: false,
});

const ProposalsSlice = createSlice({
  name: "[PROPOSALS]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // FETCH MANY
    builder.addCase(ProposalsThunks.fetchMany.pending, (state) => {
      state.loading = Loading.InProgress;
    });
    builder.addCase(ProposalsThunks.fetchMany.rejected, (state) => {
      state.loading = Loading.Finished;
      state.fetched = true;
    });
    builder.addCase(
      ProposalsThunks.fetchMany.fulfilled,
      (state, action: { payload: Proposal[] }) => {
        state.loading = Loading.Finished;
        proposalsAdapter.setAll(state, action.payload);
        state.fetched = true;
      },
    );
    // FETCH ONE
    builder.addCase(ProposalsThunks.fetchOne.pending, (state) => {
      state.loading = Loading.InProgress;
    });
    builder.addCase(fetchOne.rejected, (state) => {
      state.loading = Loading.Finished;
      state.fetched = true;
    });
    builder.addCase(
      fetchOne.fulfilled,
      (state, action: { payload?: Proposal }) => {
        state.loading = Loading.Finished;
        if (action.payload) {
          proposalsAdapter.upsertOne(state, action.payload);
        }
      },
    );
  },
});

export const selectProposalById = (id?: string) =>
  createSelector(
    (state: RootState) => state,
    (state) => (id ? selectById(state, id) : undefined),
  );

export const selectProposals = createSelector(
  (state: RootState) => state.proposals,
  selectAll,
  (state, proposals) => ({
    loading: state.loading,
    fetched: state.fetched,
    proposals: proposals,
  }),
);

const Proposals = { ...ProposalsSlice.actions };

export default ProposalsSlice.reducer;
