import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Loading } from "shared/types/enums";
import {
  TopClientPerformers,
  TopPortfolioPerformers,
} from "shared/models/top_performances/TopPerformancesModel";
import { TopPerformancesThunks } from "./TopPerformancesThunks";

interface TopPerformancesState {
  clients: TopClientPerformers;
  portfolios: TopPortfolioPerformers;
  clientsLoading: Loading;
  portfoliosLoading: Loading;
}

const initialState: TopPerformancesState = {
  clients: [],
  portfolios: [],
  clientsLoading: Loading.Idle,
  portfoliosLoading: Loading.Idle,
};

const TopPerformancesSlices = createSlice({
  name: "[TOP_PERFORMANCES]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(TopPerformancesThunks.fetchTopClients.rejected, (state) => {
      state.clientsLoading = Loading.Finished;
      return state;
    });
    builder.addCase(TopPerformancesThunks.fetchTopClients.pending, (state) => {
      state.clientsLoading = Loading.InProgress;
      return state;
    });
    builder.addCase(
      TopPerformancesThunks.fetchTopClients.fulfilled,
      (state, action) => {
        state.clients = action.payload;
        state.clientsLoading = Loading.Finished;
        return state;
      },
    );
    builder.addCase(
      TopPerformancesThunks.fetchTopPortfolios.rejected,
      (state) => {
        state.portfoliosLoading = Loading.Finished;
        return state;
      },
    );
    builder.addCase(
      TopPerformancesThunks.fetchTopPortfolios.pending,
      (state) => {
        state.portfoliosLoading = Loading.InProgress;
        return state;
      },
    );
    builder.addCase(
      TopPerformancesThunks.fetchTopPortfolios.fulfilled,
      (state, action) => {
        state.portfolios = action.payload;
        state.portfoliosLoading = Loading.Finished;
        return state;
      },
    );
  },
});

export const selectTopClients = () =>
  createSelector(
    (state: RootState) => state.topPerformances,
    ({ clients, clientsLoading }) => ({
      clients,
      loading: clientsLoading,
    }),
  );

export const selectTopPortfolios = () =>
  createSelector(
    (state: RootState) => state.topPerformances,
    ({ portfolios, portfoliosLoading }) => ({
      portfolios,
      loading: portfoliosLoading,
    }),
  );

const TopPerformancesActions = { ...TopPerformancesSlices.actions };

export default TopPerformancesSlices.reducer;
