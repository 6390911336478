import RestService from "shared/api/RestService";
import { FaApiRoutes } from "shared/utils/routes";

class EmailVerificationService {
  async sendEmailVerification(url: string) {
    const payload = { url };
    return RestService.post(FaApiRoutes.EmailVerification.Send, payload);
  }
}

const emailVerificationService = new EmailVerificationService();

export default emailVerificationService;
