import { FeeTemplatesList } from "modules/fee_templates_list/FeeTemplatesList";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BasePage } from "shared/components/common/BasePage";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";
import { FilledAddIcon } from "shared/icons/FilledAddIcon";

export const FeeTemplatesPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <BasePage>
      <div className="flex items-center justify-between">
        <div>
          <Labels.H1>{t("feeTemplates.title")}</Labels.H1>
        </div>
        <Button
          label="Add Fee Template"
          leftIcon={<FilledAddIcon className="fill-sb-green-800" />}
          onClick={() => {
            navigate("new");
          }}
        />
      </div>
      <div className="mt-10">
        <FeeTemplatesList />
      </div>
    </BasePage>
  );
};
