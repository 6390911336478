import { parseDate } from "@internationalized/date";
import { DateTime } from "luxon";
import { FeesAccruedByDayChart } from "modules/fees_accrued_chart/FeesAccruedByDayChart";
import { PayoutsChart } from "modules/payouts_chart/PayoutsChart";
import { useEffect, useState } from "react";
import { DateRange } from "react-aria";
import { useTranslation } from "react-i18next";
import { DateRangeSelector } from "shared/components/common/DateRangeSelector";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import { FinancialAdvisorFeeStats } from "shared/models/fees/FeesModel";
import { FeesService } from "shared/services/fees/FeesService";
import { CurrencyFormat } from "shared/utils/currency";

type StatsType = "accrued" | "paid";

export const FeeStats: React.FC = () => {
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState<DateRange>({
    start: parseDate(
      DateTime.local().minus({ months: 3 }).toFormat("yyyy-MM-dd"),
    ),
    end: parseDate(DateTime.local().toFormat("yyyy-MM-dd")),
  });
  const [selectedStat, setSelectedStat] = useState<StatsType>("accrued");
  const [stats, setStats] = useState<FinancialAdvisorFeeStats>();

  useEffect(() => {
    FeesService.getStats(
      dateRange.start.toString(),
      dateRange.end.toString(),
    ).then(setStats);
  }, [dateRange.end, dateRange.start]);

  const components: Record<StatsType, JSX.Element> = {
    accrued: (
      <FeesAccruedByDayChart
        start={dateRange.start.toString()}
        end={dateRange.end.toString()}
      />
    ),
    paid: (
      <PayoutsChart
        updatedAtFrom={dateRange.start.toString()}
        updatedAtTo={dateRange.end.toString()}
        height={400}
      />
    ),
  };

  return (
    <Panel>
      <div className="flex justify-between gap-4">
        <Labels.H3>{t("feeStats.title")}</Labels.H3>
        <DateRangeSelector
          dateRange={dateRange}
          setDateRange={setDateRange}
          placeholder={t("feeStats.selectDateRange")}
        />
      </div>

      <div className="mt-4 flex items-center justify-center gap-8">
        <StatsCard
          title={t("feeStats.totalFeesAccrued")}
          selected={selectedStat === "accrued"}
          onClick={() => setSelectedStat("accrued")}
          value={CurrencyFormat.format(stats?.accrued_total || "0")}
        />
        <StatsCard
          title={t("feeStats.totalFeesPaid")}
          selected={selectedStat === "paid"}
          onClick={() => setSelectedStat("paid")}
          value={CurrencyFormat.format(stats?.paid_successful || "0")}
        />
      </div>
      <div className="mt-4">{components[selectedStat]}</div>
    </Panel>
  );
};

type StatsProps = {
  title: string;
  value: string;
  selected: boolean;
  onClick: () => void;
};

const StatsCard: React.FC<StatsProps> = ({
  title,
  value,
  selected,
  onClick,
}) => {
  return (
    <div
      className={
        "min-w-[150px] cursor-pointer rounded-xl border p-4 duration-150 " +
        (selected
          ? "border-sb-green-600 bg-sb-green-300"
          : "border-sb-gray-200 hover:bg-sb-green-100 ")
      }
      onClick={onClick}
    >
      <div className="text-center">{title}</div>
      <div className="text-center text-2xl font-medium">{value}</div>
    </div>
  );
};
