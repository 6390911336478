type Props = {
  className?: string;
};

export const StreetbeatHollowIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5449 0.700518C13.1414 0.257352 12.5552 -0.0788981 11.8645 0.0155673C11.1822 0.108873 10.7045 0.581892 10.4208 1.08849C9.5472 2.6184 8.95795 4.35004 8.95795 6.27005C8.95795 7.97872 9.37463 9.58957 10.1228 10.9856H3.0044C2.17038 10.9856 1.39338 11.387 1.10389 12.1996C0.836849 12.9493 1.1068 13.7282 1.59629 14.2688L1.60869 14.2826L10.4478 23.3488L10.4636 23.3637C10.8777 23.753 11.4524 24.0633 12.1167 23.9893C12.7994 23.9132 13.2899 23.4649 13.5865 22.9396C14.5139 21.3739 15.0042 19.5796 15.0062 17.7512C15.0068 16.0806 14.6032 14.4443 13.8425 12.9856H21.0074C21.8328 12.9856 22.6227 12.5896 22.9056 11.7675C23.1686 11.0031 22.8622 10.2274 22.3314 9.71283L13.5449 0.700518ZM11.506 12.9856C12.4781 14.366 13.0067 16.0321 13.0063 17.7489C13.0046 19.2254 12.6076 20.6714 11.8606 21.9291L11.8598 21.9303C11.8556 21.9267 11.8513 21.9228 11.8468 21.9188L3.13748 12.9856H11.506ZM12.4869 10.9856C11.5264 9.70167 10.958 8.06638 10.958 6.27005C10.958 4.7888 11.4054 3.40964 12.1352 2.11961L20.7791 10.9856H12.4869Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
