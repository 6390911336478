type Props = {
  className?: string;
};

export const VerifyEmailImage: React.FC<Props> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 193 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M192.273 119.406V33.1071C192.273 25.0082 185.702 18.4457 177.603 18.4561L57.6731 18.6087C49.5784 18.619 43.0259 25.1917 43.0408 33.2864L43.1986 119.433C43.2135 127.515 49.7684 134.058 57.8496 134.058H177.621C185.713 134.058 192.273 127.499 192.273 119.406Z"
        fill="#ADFFC9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M192.273 47.1343L118.845 78.0516L43.0664 47.1343C43.0602 43.0944 43.054 39.187 43.0523 36.7408C43.0505 34.2947 43.0533 33.4412 43.4824 31.1895C43.9112 28.9375 44.7661 25.2863 47.0582 22.8917C49.3501 20.4967 53.0785 19.3584 55.325 18.8075C57.5714 18.2565 58.3353 18.2935 78.3182 18.3354C98.3008 18.3773 137.502 18.424 157.84 18.4669C178.178 18.5095 179.653 18.5482 181.925 19.2736C184.197 19.999 187.267 21.4114 189.12 23.6956C190.974 25.9795 191.612 29.1352 191.929 31.1923C192.247 33.2491 192.243 34.2074 192.247 36.6813C192.25 39.1552 192.261 43.1449 192.273 47.1343Z"
        fill="#00CC4F"
      />
      <circle cx="67.569" cy="111.521" r="5.29985" fill="#111112" />
      <circle cx="89.0618" cy="111.521" r="5.29985" fill="#111112" />
      <circle cx="110.555" cy="111.521" r="5.29985" fill="#111112" />
      <path
        d="M170.319 104.39L177.494 111.564L170.319 118.738"
        stroke="#111112"
        strokeWidth="2"
      />
      <path
        d="M10.8656 4.24264C13.2087 1.89949 17.0077 1.89949 19.3509 4.24264L22.6623 7.55412L26.4394 11.3312V11.3312C28.5254 13.4172 28.5254 16.7993 26.4394 18.8853V18.8853L22.6623 22.6624L19.3509 25.9738C17.0077 28.317 13.2087 28.317 10.8656 25.9738L7.55411 22.6624L3.77705 18.8853V18.8853C1.69104 16.7993 1.69104 13.4172 3.77705 11.3312V11.3312L7.55411 7.55412L10.8656 4.24264Z"
        stroke="#111112"
        strokeWidth="2"
      />
    </svg>
  );
};
