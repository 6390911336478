import { useTranslation } from "react-i18next";
import { ValueSelector } from "./ValueSelector";

type Props = {
  onChange?: (values: string[]) => void;
};

export const Strategy: React.FC<Props> = ({ onChange }) => {
  const { t } = useTranslation();

  const preDefinedStrategies = [
    "marketGrowth",
    "yieldGeneration",
    "dividendGeneration",
    "capitalPreservation",
  ];

  return (
    <ValueSelector
      title={t("proposals.strategy")}
      translationKey="strategy_answers"
      preDefinedValues={preDefinedStrategies}
      onChange={onChange}
      custom
    />
  );
};
