import { createAsyncThunk } from "@reduxjs/toolkit";
import { AumChartParams } from "shared/models/aum/AumChartParams";
import { AccountService } from "shared/services/account/AccountService";

export const fetch = createAsyncThunk(
  "[AGGREGATED_AUM] Fetch aggregated AUM chart",
  async (params: AumChartParams | undefined) => {
    const aumChart = await AccountService.getAumChart(params);
    return aumChart;
  },
);

export const AggregatedAumThunks = {
  fetch,
};
