import CustomPopover from "shared/components/common/CustomPopover";
import i18n from "shared/i18n/i18n";
import ChevronRight from "shared/icons/ChevronRight";
import {
  Side,
  TradingOrderType,
} from "shared/models/trading/TradingOrderModel";
import OrderTypeDropdownItem from "./OrderTypeDropdownItem";
import { Popover } from "@headlessui/react";

interface DropdownItem {
  value: TradingOrderType;
  label: string;
}

const dropdownItems: DropdownItem[] = [
  {
    value: "limit",
    label: i18n.t("trade.orderTypes.options.limit.label"),
  },
  {
    value: "market",
    label: i18n.t("trade.orderTypes.options.market.label"),
  },
  {
    value: "stop",
    label: i18n.t("trade.orderTypes.options.stop.label"),
  },
  {
    value: "trailing_stop",
    label: i18n.t("trade.orderTypes.options.trailing_stop.label"),
  },
];

type Props = {
  type: TradingOrderType;
  side: Side;
  onChange: (t: TradingOrderType) => void;
  className?: string;
};

export default function OrderTypeSelect({
  type,
  onChange,
  side,
  className,
}: Props) {
  return (
    <CustomPopover
      align="middle"
      button={
        <div
          className={
            "flex items-center gap-2 font-semibold text-sb-green-light " +
            className
          }
        >
          {dropdownItems.filter((el) => el.value === type)[0].label}
          <div>
            <ChevronRight className="h-4 w-4 rotate-90 text-sb-green-light" />
          </div>
        </div>
      }
    >
      {dropdownItems.map((item, idx) => {
        return (
          <Popover.Button
            key={"dropdown_item_" + idx}
            className="z-100 w-[300px] p-1.5 text-left"
          >
            <OrderTypeDropdownItem
              type={item.value}
              onChange={onChange}
              side={side}
            />
          </Popover.Button>
        );
      })}
    </CustomPopover>
  );
}
