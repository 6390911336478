import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";
import { Checkbox } from "../../../../shared/components/controls/Checkbox";
import { YesNoSwitch } from "../YesNoSwitch";
import { PreviousLoss } from "./PreviousLoss";

export const HighWaterMark = () => {
  const { t } = useTranslation();
  const [perpetual, setPerpetual] = useState(false);
  const [previousLossesCnt, setPreviousLossesCnt] = useState(1);
  const [showPreviousLosses, setShowPreviousLosses] = useState(false);

  return (
    <>
      {!perpetual ? (
        <>
          <label>{t("feeTemplateForm.pnlPct.lookBackYears")}</label>
          <div className="w-full">
            <input
              type="number"
              name="pnlPct_lookBackYears"
              min={0}
              className="w-full"
            />
          </div>
        </>
      ) : (
        ""
      )}
      <div></div>
      <div>
        <Checkbox
          label={t("feeTemplateForm.pnlPct.perpetualLookBack")}
          name="pnlPct_perpetualLookBack"
          onChange={setPerpetual}
        />
        <Labels.G1 className="text-sm">
          {t("feeTemplateForm.pnlPct.perpetualLookBackDesc")}
        </Labels.G1>
      </div>
      <label>{t("feeTemplateForm.pnlPct.prorate")}</label>
      <YesNoSwitch name="pnlPct_prorate" />
      <label>{t("feeTemplateForm.pnlPct.previousLoses")}</label>
      <YesNoSwitch
        name="pnlPct_previousLoses"
        onChange={setShowPreviousLosses}
      />
      {showPreviousLosses && (
        <>
          {Array.from({ length: previousLossesCnt }, (_, idx) => idx).map(
            (_, idx) => {
              return <PreviousLoss key={"pl_" + idx} index={idx} />;
            },
          )}
          <div className="col-span-2 flex items-center justify-center gap-4 pb-4">
            {previousLossesCnt > 1 && (
              <Button
                label={t("feeTemplateForm.remove")}
                onClick={() => {
                  if (previousLossesCnt > 1) {
                    setPreviousLossesCnt((prev) => prev - 1);
                  }
                }}
              />
            )}
            <Button
              label={t("feeTemplateForm.add")}
              onClick={() => {
                setPreviousLossesCnt((prev) => prev + 1);
              }}
            />
          </div>
        </>
      )}
    </>
  );
};
