import { createSelector, createSlice } from "@reduxjs/toolkit";
import { fetchSessions } from "./PortfolioAssistantThunks";
import { Loading } from "shared/types/enums";
import { RootState } from "../store";
import { AIAssistantSession } from "shared/models/assistant/AIAssistantModel";

interface PortfolioAssistantState {
  sessions: AIAssistantSession[];
  loading: Loading;
}

const initialState: PortfolioAssistantState = {
  sessions: [],
  loading: Loading.Idle,
};

const portfolioAssistantSlice = createSlice({
  name: "[PORTFOLIO_ASSISTANT]",
  initialState,
  reducers: {
    reset(state) {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSessions.pending, (state) => {
      state.loading = Loading.InProgress;
      return state;
    });
    builder.addCase(fetchSessions.fulfilled, (state, action) => {
      state.sessions = action.payload;
      state.loading = Loading.Finished;
      return state;
    });
  },
});

export const selectPortfolioAssistantSessions = () =>
  createSelector(
    (state: RootState) => state.portfolioAssistant,
    ({ sessions, loading }) => ({
      sessions: sessions,
      loading: loading,
    }),
  );

export default portfolioAssistantSlice.reducer;
