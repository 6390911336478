type Props = {
  className?: string;
};

export const CheckBadge: React.FC<Props> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M17.068 8.50244L15.9913 7.25161C15.7855 7.01411 15.6192 6.57078 15.6192 6.25411V4.90828C15.6192 4.06911 14.9305 3.38036 14.0913 3.38036H12.7455C12.4367 3.38036 11.9855 3.21411 11.748 3.00828L10.4971 1.93161C9.95087 1.46453 9.05629 1.46453 8.50212 1.93161L7.25921 3.01619C7.02171 3.21411 6.57046 3.38036 6.26171 3.38036H4.89212C4.05296 3.38036 3.36421 4.06911 3.36421 4.90828V6.26203C3.36421 6.57078 3.19796 7.01411 3.00004 7.25161L1.93129 8.51036C1.47212 9.05661 1.47212 9.94328 1.93129 10.4895L3.00004 11.7483C3.19796 11.9858 3.36421 12.4291 3.36421 12.7379V14.0916C3.36421 14.9308 4.05296 15.6195 4.89212 15.6195H6.26171C6.57046 15.6195 7.02171 15.7858 7.25921 15.9916L8.51004 17.0683C9.05629 17.5354 9.95087 17.5354 10.505 17.0683L11.7559 15.9916C11.9934 15.7858 12.4367 15.6195 12.7534 15.6195H14.0992C14.9384 15.6195 15.6271 14.9308 15.6271 14.0916V12.7458C15.6271 12.437 15.7934 11.9858 15.9992 11.7483L17.0759 10.4974C17.535 9.95119 17.535 9.04869 17.068 8.50244ZM12.793 8.00369L8.96921 11.8274C8.85837 11.9383 8.70796 12.0016 8.54962 12.0016C8.39129 12.0016 8.24087 11.9383 8.13004 11.8274L6.21421 9.91161C5.98462 9.68203 5.98462 9.30203 6.21421 9.07244C6.44379 8.84286 6.82379 8.84286 7.05337 9.07244L8.54962 10.5687L11.9538 7.16453C12.1834 6.93494 12.5634 6.93494 12.793 7.16453C13.0225 7.39411 13.0225 7.77411 12.793 8.00369Z"
        fill="currentColor"
      />
    </svg>
  );
};
