type Props = {
  title: string;
  value?: string;
};

export const SimpleRow: React.FC<Props> = ({ title, value }) => {
  if (!value) {
    return null;
  }

  return (
    <div>
      <div className="font-semibold">{title}</div>
      <div className="text-xl font-light">{value}</div>
    </div>
  );
};
