import RestService from "shared/api/RestService";
import { RecurringInvestmentFrequency } from "shared/models/client_proposal/ClientProposalModel";
import {
  MetaPortfolio,
  Portfolio,
} from "shared/models/portfolio/PortfolioModel";
import {
  Proposal,
  ProposalQuestionaire,
} from "shared/models/proposals/ProposalsModel";
import { FaApiRoutes } from "shared/utils/routes";

export type ProposalPayload = {
  client_id: string;
  strategy_id?: string;
  questionnaire?: ProposalQuestionaire;
  initial_deposit: string;
  recurring_deposit: string;
  recurring_deposit_frequency: string;
  readme: string;
};

type ProposalPortfolioPatchPayload = {
  meta_portfolio?: MetaPortfolio[];
  strategy_title?: string;
  strategy_symbol?: string;
};

export const ProposalsService = {
  async createProposal(payload: ProposalPayload): Promise<void> {
    await RestService.post<string>(FaApiRoutes.Advisor.Proposals.Path, payload);
  },

  async sendProposal(proposalId: string, message?: string): Promise<void> {
    await RestService.post<void>(
      FaApiRoutes.Advisor.Proposals.Details(proposalId).Send,
      { message },
    );
  },

  async cancelProposal(id: string): Promise<void> {
    return RestService.post<void>(
      FaApiRoutes.Advisor.Proposals.Details(id).Cancel,
    );
  },

  async deleteProposal(id: string): Promise<void> {
    return RestService.post<void>(
      FaApiRoutes.Advisor.Proposals.Details(id).Delete,
    );
  },

  async autoApproveProposal(id: string): Promise<void> {
    return RestService.post<void>(
      FaApiRoutes.Advisor.Proposals.Details(id).Approve,
    );
  },

  async patchProposal(
    proposalId: string,
    payload: {
      strategy_id?: string;
      readme?: string;
      initial_deposit?: number;
      recurring_deposit?: number;
      recurring_deposit_frequency?: RecurringInvestmentFrequency;
    },
  ): Promise<void> {
    return RestService.patch<void>(
      FaApiRoutes.Advisor.Proposals.Details(proposalId).Path,
      payload,
    );
  },

  async getPortfolios(proposalId: string) {
    return RestService.get<Portfolio[]>(
      FaApiRoutes.Advisor.Proposals.Details(proposalId).Portfolios,
    );
  },

  async patchPortfolios(
    proposalId: string,
    payload: ProposalPortfolioPatchPayload,
  ): Promise<void> {
    return RestService.patch<void>(
      FaApiRoutes.Advisor.Proposals.Details(proposalId).Portfolios,
      payload,
    );
  },

  async getMany(): Promise<Proposal[]> {
    return RestService.get<Proposal[]>(FaApiRoutes.Advisor.Proposals.Path);
  },

  async getOne(id: string): Promise<Proposal | undefined> {
    return RestService.get<Proposal>(
      FaApiRoutes.Advisor.Proposals.Details(id).Path,
    );
  },
};
