type Props = {
  title: string;
  values: string[];
};

export const ListRow: React.FC<Props> = ({ title, values }) => {
  return (
    <div>
      <div className="font-semibold">{title}</div>
      <div className="flex flex-wrap items-center gap-4 text-xl">
        {values.map((item, idx) => {
          return (
            <div
              key={"list_item_" + idx + "_" + item}
              className="mt-1 rounded-full border border-sb-gray-300 bg-sb-gray-100 px-4"
            >
              {item}
            </div>
          );
        })}
      </div>
    </div>
  );
};
