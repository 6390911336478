import { t } from "i18next";
import { usePnlPct } from "modules/fee_template_form/hooks/usePnlPct";
import {
  PnlPctTypeType,
  financialInstruments,
} from "modules/fee_template_form/types";
import { useState } from "react";

export const PerformanceThreshold: React.FC = () => {
  const { typeChoices } = usePnlPct();
  const [performanceThresholdType, setPerformanceThresholdType] =
    useState<PnlPctTypeType>(typeChoices[0].value);

  return (
    <>
      <div>{t("feeTemplateForm.pnlPct.type")}</div>
      <select
        name="pnlPct_threshold_type"
        onChange={(e) =>
          setPerformanceThresholdType(e.target.value as PnlPctTypeType)
        }
      >
        {typeChoices.map((item, idx) => (
          <option key={"tc_" + idx} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
      {performanceThresholdType === "benchmark" && (
        <>
          <label>{t("feeTemplateForm.pnlPct.benchmark")}</label>
          <select name="pnlPct_benchmark" required>
            {financialInstruments.map((item, idx) => (
              <option key={"fi_" + idx} value={item}>
                {item}
              </option>
            ))}
          </select>
        </>
      )}
      {performanceThresholdType === "fixed" && (
        <>
          <label>{t("feeTemplateForm.pnlPct.thresholdPct")}</label>
          <input type="number" name="pnlPct_thresholdPct" required />
        </>
      )}
    </>
  );
};
