import { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Modal } from "shared/components/common/Modal";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";
import { useAccount } from "shared/hooks/useAccount";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import { ClientsService } from "shared/services/clients/ClientsService";
import { AccountThunks } from "shared/store/account/AccountThunks";
import { selectClientById } from "shared/store/clients/ClientsReducer";
import { ClientsThunks } from "shared/store/clients/ClientsThunks";
import { ClientDetailsThunks } from "shared/store/client_details/ClientDetailsThunks";
import { CurrencyFormat } from "shared/utils/currency";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  clientId?: string;
  portfolioId: string;
};
export const PortfolioDepositModal: React.FC<Props> = ({
  open,
  setOpen,
  clientId,
  portfolioId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [value, setValue] = useState<string>();
  const { account } = useAccount();
  const client = useSelector(selectClientById(clientId));

  const clientTradingAccount = client?.trading;
  const tradingAccount = clientId
    ? clientTradingAccount
    : account?.client.trading;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (clientId) {
      dispatch(ClientsThunks.fetchClientById(clientId));
    } else {
      dispatch(AccountThunks.fetch());
    }
  }, [dispatch, clientId]);

  const depositSuccess = () => {
    if (clientId) {
      dispatch(
        ClientDetailsThunks.fetchClientPortfolio({ clientId, portfolioId }),
      );
      dispatch(ClientDetailsThunks.fetchRecentActivities({ clientId }));
      dispatch(ClientsThunks.fetchClientById(clientId));
    }
    setLoading(false);
    setOpen(false);
    toast.success(t("faPortfolio.depositSuccess"));
  };

  const onDeposit = () => {
    if (clientId && value) {
      setLoading(true);
      ClientsService.depositToPortfolio(
        clientId,
        portfolioId,
        parseFloat(value),
      ).then(depositSuccess);
    }
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <Labels.H3>{t("clientCash.deposit")}</Labels.H3>
      {tradingAccount?.buying_power && (
        <div className="text-base text-sb-gray-600">
          {t("trade.buyingPower", {
            value: CurrencyFormat.format(tradingAccount?.buying_power),
          })}
        </div>
      )}
      <div className="mt-4">
        <CurrencyInput
          prefix="$"
          className="w-full min-w-0 text-3xl caret-sb-green-600 outline-none"
          placeholder="0"
          decimalsLimit={2}
          decimalScale={2}
          onValueChange={(value) => setValue(value)}
        />
      </div>
      <div className="mt-4 flex items-center justify-center gap-4">
        <Button
          loading={loading}
          label="clientCash.deposit"
          disabled={
            !value ||
            parseFloat(tradingAccount?.buying_power || "0") < parseFloat(value)
          }
          onClick={onDeposit}
        />
        <Button
          disabled={loading}
          btnStyle="blank"
          label="global.cancel"
          onClick={() => setOpen(false)}
        />
      </div>
    </Modal>
  );
};
