import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AumChartFrequency } from "shared/models/aum/AumChartParams";

type PerfMeasure = "mwr" | "twr";

export type ManagedCapitalSettings = {
  perfMeasure: PerfMeasure;
  frequency: AumChartFrequency;
};

export const DefaultManagedCapitalSettings: ManagedCapitalSettings = {
  perfMeasure: "twr",
  frequency: "daily",
};

type Props = {
  onSettingsChange: (settings: ManagedCapitalSettings) => void;
};

export const ManagedCapital: React.FC<Props> = ({ onSettingsChange }) => {
  const { t } = useTranslation();

  const [frequency, setFrequency] = useState<AumChartFrequency>(
    DefaultManagedCapitalSettings.frequency,
  );
  const [perfMeasure, setPerfMeasure] = useState<PerfMeasure>(
    DefaultManagedCapitalSettings.perfMeasure,
  );

  useEffect(() => {
    onSettingsChange({
      perfMeasure,
      frequency,
    });
  }, [frequency, onSettingsChange, perfMeasure]);

  return (
    <div className="flex justify-between gap-10">
      <div className="flex gap-10">
        <div>
          <div>{t("managedCapital.perfMeasure.title")}</div>
          <div className="mt-4 flex gap-2">
            <div
              className={
                "rounded-selector " +
                (perfMeasure === "mwr" ? "active" : "inactive")
              }
              onClick={() => setPerfMeasure("mwr")}
            >
              {t("managedCapital.perfMeasure.mwr")}
            </div>
            <div
              className={
                "rounded-selector " +
                (perfMeasure === "twr" ? "active" : "inactive")
              }
              onClick={() => setPerfMeasure("twr")}
            >
              {t("managedCapital.perfMeasure.twr")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
