import { useTranslation } from "react-i18next";
import { Labels } from "shared/components/hoc/Labels";
import { Layouts } from "shared/components/hoc/Layouts";
import { Panel } from "shared/components/hoc/Panels";
import {
  OtherIndeterminateActions,
  RequiredInformation,
} from "./RequiredInformation";

export const ActionRequiredDefaultView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Panel>
      <Layouts.Grid>
        <Labels.H1 className="font-semibold">
          {t("trader.action.action_required.title")}
        </Labels.H1>
        <Labels.H2>{t("trader.action.action_required.description")}</Labels.H2>
        <RequiredInformation />
        <OtherIndeterminateActions />
      </Layouts.Grid>
    </Panel>
  );
};
