import { useTranslation } from "react-i18next";
import { Labels } from "shared/components/hoc/Labels";
import { Layouts } from "shared/components/hoc/Layouts";
import { BrokerageAccountIndeterminate } from "shared/models/brokerage_account/BrokerageAccountEventModel";
import { UpdateW8BenForm } from "../components/UpdateW8BenForm";

export const W8BENMessage: React.FC = () => {
  const { t } = useTranslation();

  const indeterminateKey: keyof BrokerageAccountIndeterminate =
    "W8BEN_CORRECTION";

  return (
    <Layouts.Grid>
      <Labels.B1>{t(`errors.kyc.${indeterminateKey}`)}</Labels.B1>
      <UpdateW8BenForm />
    </Layouts.Grid>
  );
};
