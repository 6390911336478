import { Menu, Transition } from "@headlessui/react";
import { Fragment, PropsWithChildren } from "react";

type Props = PropsWithChildren & {
  button: JSX.Element;
  position?: string;
};

export const OptionsMenu: React.FC<Props> = ({
  button,
  children,
  position,
}) => {
  const positionClass = position || "right-0";
  return (
    <Menu as="div" className="relative inline-block w-full text-left">
      <Menu.Button as="div">{button}</Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`${positionClass} z-100 absolute z-50 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <div className="px-1 py-1">{children}</div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
