import { Trans, useTranslation } from "react-i18next";
import GoogleIcon from "shared/icons/GoogleIcon";
import AppleIcon from "shared/icons/AppleIcon";
import { EnvelopeIcon } from "shared/icons/EnvelopeIcon";
import { WelcomeContainer } from "./WelcomeContainer";
import { useState } from "react";
import { EmailSignInForm } from "./EmailSignInForm";
import useAuth from "shared/hooks/useAuth";
import { Link } from "react-router-dom";

type Props = {
  redirectUrl?: string;
};

export const WelcomePage: React.FC<Props> = ({ redirectUrl }) => {
  const { t } = useTranslation();
  const [emailLogin, setEmailLogin] = useState(false);
  const { signInWithGoogle, signInWithApple } = useAuth();

  return (
    <WelcomeContainer>
      <div className="absolute right-4 top-4">
        <Trans i18nKey="welcome.signUpLink">
          <span className="mr-1 font-semibold"></span>
          <Link to="/sign-up" className="font-semibold text-sb-green-600">
            Login
          </Link>
        </Trans>
      </div>
      {!emailLogin ? (
        <div className="space-y-4">
          <SignUpButton
            icon={<GoogleIcon />}
            label={t("auth.signInWithGoogle")}
            onClick={signInWithGoogle}
          />
          <SignUpButton
            icon={<AppleIcon />}
            label={t("auth.signInWithApple")}
            onClick={signInWithApple}
          />
          <SignUpButton
            icon={<EnvelopeIcon className="aspect-square w-4" />}
            label={t("auth.signInWithEmail")}
            onClick={() => setEmailLogin(true)}
          />
        </div>
      ) : (
        <EmailSignInForm onBackClicked={() => setEmailLogin(false)} />
      )}
    </WelcomeContainer>
  );
};

type SignUpProps = {
  onClick: () => void;
  icon: JSX.Element;
  label: string;
};

export const SignUpButton: React.FC<SignUpProps> = ({
  onClick,
  icon,
  label,
}) => {
  return (
    <div
      className="grid cursor-pointer place-items-center rounded-full border border-sb-gray-200 px-28 py-4 duration-150 hover:bg-sb-gray-100"
      onClick={onClick}
    >
      <div className="flex gap-4">
        {icon}
        {label}
      </div>
    </div>
  );
};
