import { PayoutStatusPicker } from "modules/payout_status_picker/PayoutStatusPicker";
import { useEffect, useState } from "react";
import { DateRange } from "react-aria";
import { useTranslation } from "react-i18next";
import { BasePage } from "shared/components/common/BasePage";
import { DataTable } from "shared/components/common/datatable/DataTable";
import { DateRangeSelector } from "shared/components/common/DateRangeSelector";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import { Payout, PayoutStatus } from "shared/models/fees/FeesModel";
import { FeesService, PayoutQuery } from "shared/services/fees/FeesService";
import { Loading } from "shared/types/enums";
import { CurrencyFormat } from "shared/utils/currency";
import { formatDateTime } from "shared/utils/date";
import { PayoutTerms } from "./PayoutTerms";

export const PayoutsPage: React.FC = () => {
  const { t } = useTranslation();
  const [payouts, setPayouts] = useState<Payout[]>([]);

  const [payoutStatuses, setPayoutStatuses] = useState<PayoutStatus[]>([]);
  const [updatedDate, setUpdatedDate] = useState<DateRange>();
  const [amountFrom, setAmountFrom] = useState<string>();
  const [amountTo, setAmountTo] = useState<string>();

  const [loading, setLoading] = useState<Loading>(Loading.Idle);

  const search = () => {
    setLoading(Loading.InProgress);
    const newQuery: PayoutQuery = {
      statuses: payoutStatuses,
      updated_date_from: updatedDate?.start.toString(),
      updated_date_to: updatedDate?.end.toString(),
      amount_from: amountFrom ? parseFloat(amountFrom) : undefined,
      amount_to: amountTo ? parseFloat(amountTo) : undefined,
    };

    FeesService.getPayouts(newQuery)
      .then(setPayouts)
      .finally(() => setLoading(Loading.Finished));
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BasePage>
      <Labels.H1>{t("payouts.title")}</Labels.H1>
      <PayoutTerms />
      <Panel className="mt-8 space-y-4">
        <Labels.H3>{t("payouts.filters")}</Labels.H3>
        <div>
          <span className="font-medium">{t("payouts.payoutsStatuses")}</span>
          <PayoutStatusPicker
            payoutStatuses={payoutStatuses}
            setPayoutStatuses={setPayoutStatuses}
          />
        </div>
        <div className="flex gap-4">
          <div>
            <span className="font-medium">{t("fees.updatedDate")}</span>
            <DateRangeSelector
              dateRange={updatedDate}
              setDateRange={setUpdatedDate}
              placeholder={t("fees.selectDateRange")}
            />
          </div>
        </div>
        <div className="flex items-center gap-4">
          <div>
            <span className="font-medium">{t("fees.amountFrom")}</span>
            <input
              type="number"
              className="w-full rounded-lg border border-sb-gray-200 px-2 py-2 text-right outline-none"
              value={amountFrom}
              onChange={(e) => setAmountFrom(e.target.value)}
            />
          </div>
          <div className="pt-4">-</div>
          <div>
            <span className="font-medium">{t("fees.amountTo")}</span>
            <input
              type="number"
              className={
                "w-full rounded-lg border px-2 py-2 text-right outline-none " +
                (parseFloat(amountTo || "0") < parseFloat(amountFrom || "0")
                  ? "border-sb-red-700"
                  : "border-sb-gray-200")
              }
              value={amountTo}
              onChange={(e) => setAmountTo(e.target.value)}
            />
          </div>
        </div>
        <div>
          <Button
            label="global.search"
            onClick={search}
            disabled={loading === Loading.InProgress}
          />
        </div>
      </Panel>
      <Panel className="mt-4">
        <Labels.H3>{t("fees.results")}</Labels.H3>
        {loading === Loading.Finished && !payouts?.length ? (
          <Labels.G1>{t("fees.noResults")}</Labels.G1>
        ) : null}
        {loading === Loading.InProgress ||
        (loading === Loading.Finished && payouts?.length) ? (
          <DataTable
            data={payouts}
            loading={loading === Loading.InProgress}
            columns={[
              "created_at",
              "payout_amount",
              "payout_status",
              "updated_at",
            ]}
            headerLabels={{
              created_at: t("fees.headers.createdAt"),
              payout_status: t("fees.headers.status"),
              updated_at: t("fees.headers.updatedAt"),
              payout_amount: t("payouts.payoutAmount"),
            }}
            cellFormatter={(key: string, value: any) => {
              if (["created_at", "updated_at"].includes(key)) {
                return value ? formatDateTime(value) : "";
              }
              if (key === "payout_status") {
                return t(`payoutsChart.status.${value}`);
              }
              if (key === "payout_amount") {
                return CurrencyFormat.format(value);
              }
              return value;
            }}
            defaultSortSettings={{
              direction: "desc",
              by: "created_at",
            }}
          />
        ) : null}
      </Panel>
    </BasePage>
  );
};
