type Props = {
  className?: string;
};

export default function StopOrder({ className }: Props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.63482 14.0559C4.31885 13.6956 3.98702 13.3173 3.63778 12.9209C3.32742 12.5687 2.7903 12.5348 2.43809 12.8451C2.08587 13.1555 2.05194 13.6926 2.36229 14.0448C2.699 14.4269 3.02281 14.7963 3.33454 15.1519C4.90115 16.9391 6.16282 18.3783 7.22367 19.3364C8.45473 20.4483 9.76423 21.2389 11.1859 20.6548C11.8353 20.3881 12.3915 19.871 12.9034 19.215C13.4201 18.5528 13.9428 17.6816 14.5037 16.6131C15.5326 14.6529 16.7513 11.9076 18.3435 8.32097C18.485 8.00237 18.6294 7.67713 18.7768 7.34522C18.9674 6.91621 18.7741 6.41392 18.3451 6.22332C17.9161 6.03273 17.4138 6.226 17.2232 6.65501C17.0794 6.97876 16.9387 7.29562 16.8011 7.6057C15.1953 11.2225 13.9999 13.9151 12.9984 15.8229C12.4555 16.8573 11.9878 17.625 11.5631 18.1692C11.1337 18.7196 10.7979 18.9764 10.5399 19.0824C10.1471 19.2437 9.58163 19.1753 8.3631 18.0748C7.38279 17.1895 6.20438 15.8457 4.63482 14.0559Z"
        fill="currentColor"
      />
      <path
        d="M0.999634 6H21.9996"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="2 3"
      />
      <path d="M19.5 6.5C19.5 5.67157 18.8284 5 18 5C17.1716 5 16.5 5.67157 16.5 6.5C16.5 7.32843 17.1716 8 18 8C18.8284 8 19.5 7.32843 19.5 6.5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 7.5C17.4477 7.5 17 7.05228 17 6.5C17 5.94772 17.4477 5.5 18 5.5C18.5523 5.5 19 5.94772 19 6.5C19 7.05228 18.5523 7.5 18 7.5ZM16 6.5C16 7.60457 16.8954 8.5 18 8.5C19.1046 8.5 20 7.60457 20 6.5C20 5.39543 19.1046 4.5 18 4.5C16.8954 4.5 16 5.39543 16 6.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
