import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "shared/components/controls/Button";
import { Panel } from "shared/components/hoc/Panels";
import CheckIcon from "shared/icons/CheckIcon";

interface Props {
  link?: string;
  addNewContactHandler: () => void;
}

export const ContactAdded: React.FC<Props> = ({ addNewContactHandler }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Panel>
      <div className="mb-6 flex flex-row items-center">
        <CheckIcon className="h-10 w-10 text-sb-green-600" />
        <div className="ml-3 flex-1">
          <h1 className="mb-1 text-xl font-medium">
            {t("addContactForm.contactAdded")}
          </h1>
        </div>
      </div>
      <div className="flex gap-4">
        <Button
          onClick={addNewContactHandler}
          className="mt-4"
          label="addContactForm.addNewClient"
        />
        <Button
          onClick={() => navigate("/contacts")}
          className="mt-4"
          label="addContactForm.backToList"
        />
      </div>
    </Panel>
  );
};
