import { t } from "i18next";
import { GlobalSearch } from "modules/global_search/GlobalSearch";
import { useEffect, useState } from "react";
import { MagnifyingGlassIcon } from "shared/icons/MagnifyingGlassIcon";
import { AssetWrapper } from "shared/models/asset/AssetModel";
import { Asset } from "./Asset";

export type Allocation = {
  allocation: number;
  asset: AssetWrapper;
};

type Props = {
  onAllocationChange: (allocation: Allocation[]) => void;
  onValidate: (correct: boolean) => void;
};

export const AllocateStocks: React.FC<Props> = ({
  onAllocationChange,
  onValidate,
}) => {
  const [allocations, setAllocations] = useState<Allocation[]>([]);

  useEffect(() => {
    onAllocationChange(allocations);
    const isValid =
      allocations.map((item) => item.allocation).reduce((a, b) => a + b, 0) ===
      100;
    onValidate(isValid);
  }, [allocations, onAllocationChange, onValidate]);

  const addStock = (asset: AssetWrapper) => {
    let a = [...allocations];
    const symbolOrConid = asset.alpaca_details ? asset.alpaca_details.symbol : asset.ibrk_details?.conid;
    const exists = a.find((a) => {
      const sOrc = a.asset.alpaca_details ? a.asset.alpaca_details.symbol : a.asset.ibrk_details?.conid;
      return symbolOrConid === sOrc;
    });
    if (!exists) {
      a.push({ allocation: 0, asset });
    }
    setAllocations(a);
  };

  const removeStock = (asset: AssetWrapper) => {
    const symbolOrConid = asset.alpaca_details ? asset.alpaca_details.symbol : asset.ibrk_details?.conid;
    setAllocations(
      [...allocations].filter((a) => {
        const sOrc = a.asset.alpaca_details ? a.asset.alpaca_details.symbol : a.asset.ibrk_details?.conid;
        return symbolOrConid !== sOrc;
      }),
    );
  };

  const setStockAllocation = (asset: AssetWrapper, allocation: number) => {
    let a = [...allocations];
    const symbolOrConid = asset.alpaca_details ? asset.alpaca_details.symbol : asset.ibrk_details?.conid;

    a = a.map((a) => {
      const sOrc = a.asset.alpaca_details ? a.asset.alpaca_details.symbol : a.asset.ibrk_details?.conid;
      if (symbolOrConid === sOrc) {
        a.allocation = allocation;
      }
      return a;
    });
    setAllocations(a);
  };

  const totalAllocated =
    allocations
      .filter((a) => a)
      .map((a) => a.allocation)
      .reduce((a, b) => a + b, 0) || 0;

  return (
    <div>
      <div className="text-xl font-semibold">
        {t("portfolios.form.totalAllocated")}:{" "}
        <span
          className={
            totalAllocated !== 100 ? "text-sb-red-700" : "text-sb-green-700"
          }
        >
          {totalAllocated}%
        </span>
      </div>
      <div className="mt-10">
        <div className="relative">
          <div className="absolute left-4 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="h-5 w-5 text-sb-gray-600" />
          </div>
          <GlobalSearch onClicked={addStock} />
        </div>
      </div>
      <div className="mt-8 grid grid-cols-2 gap-4">
        {allocations
          .filter((a) => a)
          .map((allocation, idx) => (
            <Asset
              key={"asset_" + idx}
              asset={allocation.asset}
              onRemove={() => removeStock(allocation.asset)}
              onAllocationChange={(value) =>
                setStockAllocation(allocation.asset, value)
              }
            />
          ))}
      </div>
    </div>
  );
};
