import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { Modal } from "shared/components/common/Modal";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";
import AuthService from "shared/services/auth/AuthService";
import { auth } from "shared/utils/firebase";

const timeout = 30 * 60_000;
const promptBeforeIdle = 60_000;

export const IdleListener: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  const onIdle = async () => {
    await auth.authStateReady();
    if (auth.currentUser) {
      AuthService.signOut();
      setOpen(false);
    }
  };

  const onActive = () => {
    setOpen(false);
  };

  const onPrompt = async () => {
    await auth.authStateReady();
    if (auth.currentUser) {
      setOpen(true);
    }
  };

  const { activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  const handleStillHere = () => {
    activate();
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <Labels.H3 className="text-center">Are you still here?</Labels.H3>
      <div className="flex justify-center">
        <Button
          className="outline-none"
          label="Yes"
          onClick={handleStillHere}
        />
      </div>
    </Modal>
  );
};
