import { ClientDetailsPage } from "pages/fa/clients/client_details/ClientDetailsPage";
import { ClientListPage } from "pages/fa/clients/ClientListPage";
import { DashboardPage } from "pages/fa/dashboard/DashboardPage";
import { AddFeeTemplatePage } from "pages/fa/fee_templates/add_fee_template/AddFeeTemplatePage";
import { FeeTemplatesPage } from "pages/fa/fee_templates/FeeTemplatesPage";
import { ViewFeeTemplatePage } from "pages/fa/fee_templates/view_fee_template/ViewFeeTemplatePage";
import { CreatePortfolioPage } from "pages/fa/portfolios/create_portfolio/CreatePortfolioPage";
import { PortfoliosPage } from "pages/fa/portfolios/PortfoliosPage";
import { CreateProposalPage } from "pages/fa/proposals/create_proposal/CreateProposalPage";
import { WelcomePage } from "pages/welcome/WelcomePage";
import { DecisionType, Role, RouteConfig } from "./Route";
import { AssetPage } from "pages/assets/AssetPage";
import { ProposalsPage } from "pages/fa/proposals/ProposalsPage";
import { UpdateProposalPage } from "pages/fa/proposals/update_proposal/UpdateProposalPage";
import { EditPortfolioPage } from "pages/fa/portoflio/edit/EditPortfolioPage";
import { FaPortfolioPage } from "pages/fa/portoflio/FaPortfolioPage";
import { FeesPage } from "pages/fa/fees/FeesPage";
import { PayoutsPage } from "pages/fa/payouts/PayoutsPage";
import { TradePage } from "pages/fa/trade/TradePage";
import { SignUpPage } from "pages/welcome/signup/SignUpPage";
import { ClientPortfolioPage } from "pages/fa/clients/portfolio/ClientPortfolioPage";
import { OnboardingFlow } from "pages/fa/onboarding/OnboardingFlow";
import { ActionRequiredPage } from "pages/fa/onboarding/action_required/ActionRequiredPage";
import { OnfidoPage } from "pages/fa/onboarding/onfido/OnfidoPage";
import { VerifyEmail } from "pages/fa/dashboard/cta/VerifyEmail";
import { WatchlistPage } from "pages/fa/watchlist/WatchlistPage";
import { ContactListPage } from "pages/fa/contacts/ContactListPage";
import { AddContactPage } from "pages/fa/contacts/AddContactPage";
import { ContactDetailsPage } from "pages/fa/contacts/ContactDetailsPage";
import { PlaidConnect } from "pages/fa/plaid/PlaidConnect";

export const routeConfig: RouteConfig[] = [
  {
    path: "/",
    component: SignUpPage,
    acl: {
      [Role.GUEST]: {
        type: DecisionType.ALLOW,
      },
      [Role.FA]: {
        type: DecisionType.REDIRECT,
        meta: "/dashboard",
      },
    },
  },
  {
    path: "/login",
    component: WelcomePage,
    acl: {
      [Role.GUEST]: {
        type: DecisionType.ALLOW,
      },
      [Role.FA]: {
        type: DecisionType.REDIRECT,
        meta: "/dashboard",
      },
    },
  },
  {
    path: "/dashboard",
    component: {
      [Role.FA]: DashboardPage,
      [Role.GUEST]: null,
    },
    acl: {
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
      [Role.FA]: {
        type: DecisionType.ALLOW,
      },
    },
  },
  {
    path: "/dashboard/assets/:assetId",
    component: AssetPage,
    acl: {
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
        meta: "/",
      },
      [Role.FA]: {
        type: DecisionType.ALLOW,
      },
    },
  },
  {
    path: "/fees",
    component: FeesPage,
    acl: {
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
      },
      [Role.FA]: {
        type: DecisionType.ALLOW,
      },
    },
  },
  {
    path: "/payouts",
    component: PayoutsPage,
    acl: {
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
      },
      [Role.FA]: {
        type: DecisionType.ALLOW,
      },
    },
  },
  {
    path: "/clients",
    component: ClientListPage,
    acl: {
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
      },
      [Role.FA]: {
        type: DecisionType.ALLOW,
      },
    },
  },
  {
    path: "/contacts",
    component: ContactListPage,
    acl: {
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
      },
      [Role.FA]: {
        type: DecisionType.ALLOW,
      },
    },
  },
  {
    path: "/contacts/:contactId",
    component: ContactDetailsPage,
    acl: {
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
      },
      [Role.FA]: {
        type: DecisionType.ALLOW,
      },
    },
  },
  {
    path: "/clients/:clientId",
    component: ClientDetailsPage,
    acl: {
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
      },
      [Role.FA]: {
        type: DecisionType.ALLOW,
      },
    },
  },
  {
    path: "/contacts/add-contact",
    component: AddContactPage,
    acl: {
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
      },
      [Role.FA]: {
        type: DecisionType.ALLOW,
      },
    },
  },
  {
    path: "/fee-templates",
    component: FeeTemplatesPage,
    acl: {
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
      },
      [Role.FA]: {
        type: DecisionType.ALLOW,
      },
    },
  },
  {
    path: "/fee-templates/:templateId",
    component: ViewFeeTemplatePage,
    acl: {
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
      },
      [Role.FA]: {
        type: DecisionType.ALLOW,
      },
    },
  },
  {
    path: "/fee-templates/new",
    component: AddFeeTemplatePage,
    acl: {
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
      },
      [Role.FA]: {
        type: DecisionType.ALLOW,
      },
    },
  },
  {
    path: "/portfolios",
    component: PortfoliosPage,
    acl: {
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
      },
      [Role.FA]: {
        type: DecisionType.ALLOW,
      },
    },
  },
  {
    path: "/clients/:clientId/portfolios/:portfolioId",
    component: {
      [Role.FA]: ClientPortfolioPage,
      [Role.GUEST]: null,
    },
    acl: {
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
      },
      [Role.FA]: {
        type: DecisionType.ALLOW,
      },
    },
  },
  {
    path: "/portfolios/:portfolioId",
    component: {
      [Role.FA]: FaPortfolioPage,
      [Role.GUEST]: null,
    },
    acl: {
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
      },
      [Role.FA]: {
        type: DecisionType.ALLOW,
      },
    },
  },
  {
    path: "/portfolios/:portfolioId/edit",
    component: {
      [Role.FA]: EditPortfolioPage,
      [Role.GUEST]: null,
    },
    acl: {
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
      },
      [Role.FA]: {
        type: DecisionType.ALLOW,
      },
    },
  },
  {
    path: "/portfolios/create",
    component: CreatePortfolioPage,
    acl: {
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
      },
      [Role.FA]: {
        type: DecisionType.ALLOW,
      },
    },
  },
  {
    path: "/proposals",
    component: {
      [Role.FA]: ProposalsPage,
      [Role.GUEST]: ProposalsPage,
    },
    acl: {
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
      },
      [Role.FA]: {
        type: DecisionType.ALLOW,
      },
    },
  },
  {
    path: "/watchlists",
    component: {
      [Role.FA]: WatchlistPage,
      [Role.GUEST]: null,
    },
    acl: {
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
      },
      [Role.FA]: {
        type: DecisionType.ALLOW,
      },
    },
  },
  {
    path: "/trade",
    component: {
      [Role.FA]: TradePage,
      [Role.GUEST]: TradePage,
    },
    acl: {
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
      },
      [Role.FA]: {
        type: DecisionType.ALLOW,
      },
    },
  },
  {
    path: "/verify-email",
    component: {
      [Role.FA]: VerifyEmail,
      [Role.GUEST]: null,
    },
    acl: {
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
      },
      [Role.FA]: {
        type: DecisionType.ALLOW,
      },
    },
  },
  {
    path: "/onboarding",
    component: {
      [Role.FA]: OnboardingFlow,
      [Role.GUEST]: null,
    },
    acl: {
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
      },
      [Role.FA]: {
        type: DecisionType.ALLOW,
      },
    },
  },
  {
    path: "/onboarding/action_required",
    component: {
      [Role.FA]: ActionRequiredPage,
      [Role.GUEST]: null,
    },
    acl: {
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
      },
      [Role.FA]: {
        type: DecisionType.ALLOW,
      },
    },
  },
  {
    path: "/onboarding/onfido",
    component: {
      [Role.FA]: OnfidoPage,
      [Role.GUEST]: null,
    },
    acl: {
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
      },
      [Role.FA]: {
        type: DecisionType.ALLOW,
      },
    },
  },
  {
    path: "/proposals/:proposalId",
    component: {
      [Role.FA]: UpdateProposalPage,
      [Role.GUEST]: null,
    },
    acl: {
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
      },
      [Role.FA]: {
        type: DecisionType.ALLOW,
      },
    },
  },
  {
    path: "/reports",
    component: {
      [Role.FA]: null,
      [Role.GUEST]: null,
    },
    acl: {
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
      },
      [Role.FA]: {
        type: DecisionType.ALLOW,
      },
    },
  },
  {
    path: "/clients/:clientId/proposals/create",
    component: CreateProposalPage,
    acl: {
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
      },
      [Role.FA]: {
        type: DecisionType.ALLOW,
      },
    },
  },
  {
    path: "/plaid-connect",
    component: PlaidConnect,
    acl: {
      [Role.GUEST]: {
        type: DecisionType.REDIRECT,
      },
      [Role.FA]: {
        type: DecisionType.ALLOW,
      },
    },
  },
];
