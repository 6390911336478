import { t } from "i18next";
import { useEffect, useState } from "react";
import { Layouts } from "shared/components/hoc/Layouts";
import { MetaPortfolio } from "shared/models/portfolio/PortfolioModel";
import { AddAssetClassButton } from "./AddAssetClassButton";
import { CompositionChart } from "./CompositionChart";
import { useChartColors } from "./hooks/useChartColors";
import { MetaPortfolioRow } from "./MetaPortfolioRow";

type Props = {
  metaPortfolio: MetaPortfolio[];
  editing?: boolean;
  onChange?: (metaPortfolio: MetaPortfolio[]) => void;
  totalInvalid?: () => boolean;
  wrapped?: boolean;
};

export const MetaPortfolioChart: React.FC<Props> = ({
  metaPortfolio,
  editing,
  onChange,
  totalInvalid,
  wrapped,
}) => {
  const [changedMetaPortfolio, setChangedMetaPortfolio] =
    useState(metaPortfolio);
  const { innerColors } = useChartColors(changedMetaPortfolio);

  useEffect(() => {
    onChange?.(changedMetaPortfolio);
  }, [changedMetaPortfolio, onChange]);

  useEffect(() => {
    if (!editing) {
      setChangedMetaPortfolio(metaPortfolio);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing]);

  const onMetaPortfolioRowChanged = (meta: MetaPortfolio) => {
    let newMetaPortfolio = [...changedMetaPortfolio];
    for (let idx in newMetaPortfolio) {
      const row = newMetaPortfolio[idx];
      if (row.asset_class === meta.asset_class) {
        newMetaPortfolio[idx] = meta;
      }
    }
    setChangedMetaPortfolio(newMetaPortfolio);
  };

  const onAssetClassRemoved = (assetClass: string) => {
    const filtered = changedMetaPortfolio.filter(
      (row) => row.asset_class !== assetClass,
    );
    setChangedMetaPortfolio(filtered);
  };

  const onAssetClassAdded = (assetClass: string) => {
    setChangedMetaPortfolio([
      ...changedMetaPortfolio,
      { asset_class: assetClass, weight: 0, allocations: [] },
    ]);
  };

  return (
    <>
      {metaPortfolio && (
        <div className="relative mt-8 flex justify-between gap-4">
          <div className={wrapped ? "flex w-full justify-center" : ""}>
            <CompositionChart metaPortfolio={changedMetaPortfolio} />
          </div>
          <Layouts.Spaced className="flex-grow text-base">
            <div className="flex-grow space-y-4 text-base">
              <div className="grid grid-cols-2 gap-4 px-4">
                <div className="flex-1">{t("portfolioCard.assetClass")}</div>
                <div className="flex-1 text-right">
                  {t("portfolioCard.ptf")}
                </div>
              </div>
              {(editing
                ? changedMetaPortfolio
                : metaPortfolio.filter((p) => p.weight > 0)
              )?.map((portfolio, idx) => {
                return (
                  <MetaPortfolioRow
                    key={
                      "meta_portfolio_row_" + portfolio.asset_class + "_" + idx
                    }
                    metaPortfolio={portfolio}
                    color={innerColors[idx]}
                    editing={editing}
                    onChange={onMetaPortfolioRowChanged}
                    onRemove={onAssetClassRemoved}
                    totalInvalid={totalInvalid}
                  />
                );
              })}
              {editing && (
                <>
                  <hr className="my-4" />
                  <AddAssetClassButton
                    skip={changedMetaPortfolio.map((p) => p.asset_class)}
                    onAssetClassClicked={onAssetClassAdded}
                  />
                </>
              )}
            </div>
          </Layouts.Spaced>
        </div>
      )}
    </>
  );
};
