import React from "react";

type Props = {
  className?: string;
};

export const CloseIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M2.22529 14.8251L1.17529 13.775L6.95029 8.00005L1.17529 2.22505L2.22529 1.17505L8.00029 6.95005L13.7753 1.17505L14.8253 2.22505L9.05029 8.00005L14.8253 13.775L13.7753 14.8251L8.00029 9.05005L2.22529 14.8251Z"
        fill="black"
        stroke="black"
      />
    </svg>
  );
};
