import { Contact } from "modules/brokerage_account/Contact";
import { Employment } from "modules/brokerage_account/Employment";
import { Identity } from "modules/brokerage_account/Identity";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Layouts } from "shared/components/hoc/Layouts";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import { selectClientById } from "shared/store/clients/ClientsReducer";
import { ClientsThunks } from "shared/store/clients/ClientsThunks";

type Props = {
  clientId?: string;
};

export const ClientBrokerageAccount: React.FC<Props> = ({ clientId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const client = useSelector(selectClientById(clientId));

  const brokerageAccount = client?.summary.alpaca_details?.brokerage_account;
  const identity = brokerageAccount?.identity;
  const contact = brokerageAccount?.contact;

  useEffect(() => {
    if (!client && clientId) {
      dispatch(ClientsThunks.fetchClientById(clientId));
    }
  }, [client, clientId, dispatch]);

  if (client && !client.summary.account_name) {
    return (
      <div className="text-xl font-semibold">
        {t("clientDetails.noAccount")}
      </div>
    );
  }

  return (
    <Layouts.Spaced>
      <Identity identity={identity} />
      <Contact contact={contact} />
      <Employment brokerageAccount={brokerageAccount} />
    </Layouts.Spaced>
  );
};
