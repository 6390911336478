import { colorsConfig } from "colors.config";
import { useEffect } from "react";
import {
  ResponsiveContainer,
  ScatterChart,
  XAxis,
  YAxis,
  ZAxis,
  Scatter,
  Cell,
} from "recharts";
import useAssetsExtras from "shared/hooks/useAssetsExtras";
import { useDispatch } from "shared/hooks/useDispatch";
import { AssetStatsThunks } from "shared/store/assets/asset_stats/AssetStatsThunks";

type Props = {
  assetId?: string;
};

export const EarningsChart: React.FC<Props> = ({ assetId }) => {
  const { earnings } = useAssetsExtras(assetId ? assetId : "");
  const dispatch = useDispatch();

  useEffect(() => {
    if (assetId) {
      dispatch(AssetStatsThunks.fetchAssetStats(assetId));
    }
  }, [assetId, dispatch]);

  let estimationData = [];
  if (earnings) {
    let i = 0;
    for (let e of earnings) {
      estimationData.push({
        x: i,
        y: e.wallstreet_eps_estimate,
      });
      i = i + 1;
    }
  }

  let actualData = [];
  if (earnings && estimationData.length > 1) {
    let i = 0;
    for (let e of earnings) {
      if (e) {
        actualData.push({
          x: i,
          y: e.eps,
          color:
            e.eps > estimationData[i].y
              ? colorsConfig["sb-green"][700]
              : colorsConfig["sb-red"][700],
        });
      }
      i = i + 1;
    }
  }

  const customXTick = (props: any) => {
    const { x, y, payload } = props;
    if (earnings) {
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
            Q{earnings[payload.value].fiscal_quarter}
          </text>
          <text x={0} y={20} dy={16} textAnchor="middle" fill="#666">
            FY{("" + earnings[payload.value].fiscal_year).slice(-2)}
          </text>
        </g>
      );
    }
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666">
          N/A
        </text>
      </g>
    );
  };

  return (
    <>
      {earnings && estimationData.length > 0 && actualData.length > 0 && (
        <ResponsiveContainer width="100%" height={400}>
          <ScatterChart
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <XAxis
              type="number"
              dataKey="x"
              axisLine={false}
              tickLine={false}
              ticks={[0, 1, 2, 3, 4]}
              domain={[-0.5, 4]}
              tick={customXTick}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              type="number"
              dataKey="y"
              domain={[
                (dataMin: number) =>
                  dataMin >= 0 ? dataMin * 0.9 : dataMin * 1.1,
                (dataMax: number) =>
                  dataMax >= 0 ? dataMax * 1.1 : dataMax * 0.9,
              ]}
              tickFormatter={(tick: string) => parseFloat(tick).toFixed(2)}
            />
            <ZAxis range={[600, 600]} />
            <Scatter data={estimationData} fill={"#00CC4F50"} />
            <Scatter data={actualData} fill={colorsConfig["sb-red"][700]} />
            <Scatter data={actualData}>
              {actualData.map((item, index) => (
                <Cell key={`cell-${index}`} fill={item.color} />
              ))}
            </Scatter>
          </ScatterChart>
        </ResponsiveContainer>
      )}
    </>
  );
};
