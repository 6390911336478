import { DateTime } from "luxon";
import React from "react";
import { useTranslation } from "react-i18next";
import { Labels } from "shared/components/hoc/Labels";
import { Layouts } from "shared/components/hoc/Layouts";
import { DatePicker } from "../../components/KycDatePicker";

interface Props {
  value: string;
  onChange: (value: string) => void;
}

export const BirthdayInput: React.FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation();

  const previewDate = (date: string) =>
    DateTime.fromISO(date).isValid && value !== null
      ? DateTime.fromISO(date).toFormat("MMMM dd, yyyy")
      : "";

  return (
    <Layouts.Grid>
      <Labels.H2>{t("settings.updateApplication.dateOfBirthLabel")}</Labels.H2>
      <DatePicker
        usaDateOrder={true}
        minAge={18}
        maxAge={150}
        onDateSelect={(newDate: Date | undefined) => {
          onChange(newDate?.toISOString() || "");
        }}
        initialDate={new Date(value)}
      />

      <Labels.B1 className="font-semibold">
        {value !== null ? previewDate(value) : ""}
      </Labels.B1>
    </Layouts.Grid>
  );
};
