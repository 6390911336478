import { Specifics } from "modules/proposals/Specifics";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProposalQuestionaire } from "shared/models/proposals/ProposalsModel";

type Props = {
  onQuestionaireChanged: (questionaire: ProposalQuestionaire) => void;
};

export const AIPortfolioCreator: React.FC<Props> = ({
  onQuestionaireChanged,
}) => {
  const { t } = useTranslation();
  const [otherDetails, setOtherDetails] = useState("");
  const [specifics, setSpecifics] = useState<Record<string, string[]>>({});

  const answersToGptPrompt = (): ProposalQuestionaire => {
    let result = [];
    for (let question of Object.keys(specifics)) {
      const goal = t(`proposals.${question}`);
      const answer = Object.values(specifics[question])
        .map((el) => t(`proposals.${question}_answers.${el}`) || el)
        .join(", ");
      result.push({
        question: goal,
        answer,
      });
    }
    return result;
  };

  useEffect(() => {
    let gptAnswers = [...answersToGptPrompt()];
    if (otherDetails) {
      gptAnswers.push({
        question: "Other",
        answer: otherDetails,
      });
    }

    onQuestionaireChanged?.(gptAnswers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specifics, otherDetails]);

  return (
    <div>
      <Specifics onChange={setSpecifics}>
        <div className="mt-12">
          <div className="mb-8 mt-12 text-xl font-medium text-sb-gray-600">
            {t("proposals.other")}
          </div>
          <div className="text-sb-gray-400">
            {t("proposals.aiSpecificsPlaceholder")}
          </div>
          <div className="relative mt-8">
            <textarea
              className="min-h-32 h-32 w-full overflow-hidden rounded-xl border border-sb-gray-300 px-4 py-2"
              onChange={(e) => setOtherDetails(e.target.value)}
            />
          </div>
        </div>
      </Specifics>
    </div>
  );
};
