import { Popover } from "@headlessui/react";
import { PropsWithChildren, useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CustomPopover from "shared/components/common/CustomPopover";
import { Layouts } from "shared/components/hoc/Layouts";
import ChevronRight from "shared/icons/ChevronRight";
import { AssetWrapper } from "shared/models/asset/AssetModel";
import { CreateTradingOrder } from "shared/models/trading/TradingOrderModel";
import { CreateOrderActions } from "shared/store/create_order/CreateOrderReducer";
import { usdIntlConfig } from "shared/utils/currency";
import useProfitLossPrices from "../hooks/useProfitLossPrices";

type Props = {
  asset: AssetWrapper;
  notionalEnabled?: boolean;
  fractional?: boolean;
};

export default function GenericBuyOrder({
  asset,
  notionalEnabled,
  fractional,
  children,
}: PropsWithChildren<Props>) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { stopLoss, takeProfit } = useProfitLossPrices(asset.symbol);

  const [qty, setQty] = useState<string | undefined>("0");
  const [notional, setNotional] = useState<string | undefined>("0");
  const [qtyType, setQtyType] = useState<"Shares" | "USD">("Shares");

  const [takeProfitEnabled, setTakeProfitEnabled] = useState(false);
  const [stopLossEnabled, setStopLossEnabled] = useState(false);
  const [takeProfitValue, setTakeProfitValue] = useState<string | undefined>(
    takeProfit?.toString(),
  );
  const [stopLossValue, setStopLossValue] = useState<string | undefined>(
    stopLoss?.toString(),
  );

  useEffect(() => {
    if (stopLoss && !stopLossValue) {
      setStopLossValue(stopLoss.toString());
    }
    if (takeProfit && !takeProfitValue) {
      setTakeProfitValue(takeProfit.toString());
    }
  }, [stopLoss, takeProfit, stopLossValue, takeProfitValue]);

  useEffect(() => {
    const order: CreateTradingOrder = {
      qty: qty && qtyType === "Shares" ? qty.toString() : undefined,
      notional:
        notionalEnabled && notional && qtyType === "USD" ? notional : undefined,
      side: "buy",
      stop_loss: stopLossEnabled ? { stop_price: stopLossValue } : undefined,
      symbol: asset.symbol,
      take_profit: takeProfitEnabled
        ? { limit_price: takeProfitValue }
        : undefined,
      time_in_force: "gtc",
      type: "limit",
    };
    dispatch(CreateOrderActions.update({ ...order }));
  }, [
    dispatch,
    takeProfitEnabled,
    takeProfitValue,
    stopLossEnabled,
    stopLossValue,
    qty,
    asset.symbol,
    notional,
    qtyType,
    notionalEnabled,
  ]);

  return (
    <Layouts.Spaced>
      <div className="flex flex-wrap gap-8">
        <div>
          <div>{t("trade.quantity")}</div>
          <div className="flex items-center gap-2">
            <div className="rounded-xl border border-sb-gray-200 px-2 py-1 pr-2 font-medium">
              {qtyType === "USD" ? (
                <CurrencyInput
                  prefix="$"
                  value={notional}
                  intlConfig={usdIntlConfig}
                  decimalsLimit={2}
                  decimalScale={2}
                  onValueChange={(value) => {
                    setNotional(value);
                    setQty("0");
                  }}
                  className="py-1 text-right outline-none"
                />
              ) : (
                <CurrencyInput
                  prefix={undefined}
                  value={qty}
                  intlConfig={undefined}
                  decimalsLimit={6}
                  onValueChange={(value) => {
                    if (!fractional) {
                      setQty(parseFloat(value || "0").toFixed(0));
                    } else {
                      setQty(value);
                    }
                    setNotional(undefined);
                  }}
                  className="py-1 text-right outline-none"
                />
              )}
            </div>
            {notionalEnabled ? (
              <CustomPopover
                align="middle"
                button={
                  <div className="flex cursor-pointer items-center gap-2 text-sb-gray-500">
                    {qtyType}
                    <div>
                      <ChevronRight className="h-4 w-4 rotate-90 text-sb-green-light" />
                    </div>
                  </div>
                }
              >
                <div className="p-1.5">
                  <Popover.Button
                    className="cursor-pointer rounded-lg p-1.5 font-semibold duration-150 hover:bg-sb-gray-100"
                    onClick={() => setQtyType("Shares")}
                  >
                    {t("trade.amountType.qty")}
                  </Popover.Button>
                  <Popover.Button
                    className="cursor-pointer rounded-lg p-1.5 font-semibold duration-150 hover:bg-sb-gray-100"
                    onClick={() => setQtyType("USD")}
                  >
                    {t("trade.amountType.cash")}
                  </Popover.Button>
                </div>
              </CustomPopover>
            ) : (
              "Shares"
            )}
          </div>
        </div>
        {children}
        <div>
          <div className="flex items-end gap-2">
            <input
              type="checkbox"
              checked={takeProfitEnabled}
              onChange={(e) => setTakeProfitEnabled(e.target.checked)}
              className="mb-1 accent-sb-green-light"
            />
            <div>{t("trade.takeProfit.label")}</div>
          </div>
          <div className="text-base">
            <div className="flex justify-between">
              <div className="rounded-xl border border-sb-gray-200 px-2 py-1 font-semibold">
                <CurrencyInput
                  prefix="$"
                  value={takeProfitValue}
                  intlConfig={usdIntlConfig}
                  decimalsLimit={2}
                  decimalScale={2}
                  onValueChange={(value) => setTakeProfitValue(value)}
                  className="py-1 text-right outline-none disabled:cursor-auto disabled:bg-transparent disabled:text-sb-gray-500"
                  disabled={!takeProfitEnabled || !qty || qty === "0"}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="flex items-end gap-2">
            <input
              type="checkbox"
              checked={stopLossEnabled}
              onChange={(e) => setStopLossEnabled(e.target.checked)}
              className="mb-1 accent-sb-green-light"
            />
            <div>{t("trade.stopLoss.label")}</div>
          </div>
          <div className="text-base">
            <div className="rounded-xl border border-sb-gray-200 px-2 py-1 font-semibold">
              <CurrencyInput
                prefix="$"
                value={stopLossValue}
                intlConfig={usdIntlConfig}
                decimalsLimit={2}
                decimalScale={2}
                onValueChange={(value) => setStopLossValue(value)}
                className="py-1 text-right outline-none disabled:cursor-auto disabled:bg-transparent disabled:text-sb-gray-500"
                disabled={!stopLossEnabled || !qty || qty === "0"}
              />
            </div>
          </div>
        </div>
      </div>
    </Layouts.Spaced>
  );
}
