import { get, ref, set } from "firebase/database";
import { AppActions } from "shared/store/app/AppReducer";
import { auth, database } from "shared/utils/firebase";
import { useDispatch } from "./useDispatch";

type RecentSearch = {
  symbol: string;
  name: string;
};

export type TimestampedRecentSearch = RecentSearch & {
  timestamp: number;
};

export const useRecentSearch = () => {
  const RECENT_SEARCH_LIMIT = 5;
  const RECENT_SEARCH_KEY = "eviva/recentSearch";
  const dispatch = useDispatch();

  const getPath = async () => {
    await auth.authStateReady();
    const userId = auth.currentUser?.uid;
    if (!userId) {
      return null;
    }
    const path = `${RECENT_SEARCH_KEY}/${userId}`;
    return path;
  };

  const getRecentSearches = async (): Promise<TimestampedRecentSearch[]> => {
    const path = await getPath();
    if (!path) {
      return [];
    }
    const nodeRef = ref(database(), path);
    const snapshot = await get(nodeRef);
    return Object.values(snapshot.val() || {});
  };

  const saveRecentSearch = async (search: RecentSearch) => {
    const path = await getPath();
    if (!path) {
      return;
    }
    const nodeRef = ref(database(), path);
    const timestamp = new Date().getTime();

    const recentSearches = await getRecentSearches();
    const filteredRecentSearches = recentSearches.filter(
      (s) => s.symbol !== search.symbol,
    );
    filteredRecentSearches.push({ ...search, timestamp });

    if (filteredRecentSearches.length > RECENT_SEARCH_LIMIT) {
      filteredRecentSearches.shift();
    }
    set(nodeRef, filteredRecentSearches);
    dispatch(AppActions.setSearchHistory(filteredRecentSearches));
  };

  return {
    getRecentSearches,
    saveRecentSearch,
  };
};
