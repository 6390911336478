import { DashboardIcon } from "shared/icons/DashboardIcon";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { EvivaLogo } from "shared/icons/EvivaLogo";
import { Person } from "shared/icons/Person";
import { PortfoliosIcon } from "shared/icons/PortfoliosIcon";
import { ProposalsIcon } from "shared/icons/ProposalsIcon";
import { Routes } from "shared/router/Route";
import { DollarIcon } from "shared/icons/DollarIcon";
import { BanknotesIcon } from "shared/icons/BanknotesIcon";
import { EvivaLogoSmall } from "shared/icons/EvivaLogoSmall";
import { TradeIcon } from "shared/icons/TradeIcon";
import { DocumentMagnifyingGlass } from "shared/icons/DocumentMagnifyingGlass";
import { PersonPlus } from "shared/icons/PersonPlus";
import { PersonGroup } from "shared/icons/PersonGroup";

type NavigationItem = { href: Routes; text: string; icon: JSX.Element };

export const NavigationBar: React.FC = () => {
  const { t } = useTranslation();

  const menuItems: NavigationItem[] = [
    {
      href: "/dashboard",
      icon: <DashboardIcon className="h-5 w-5 shrink-0" />,
      text: t("navigation.dashboard"),
    },
    {
      href: "/contacts",
      icon: <PersonPlus className="h-5 w-5 shrink-0" />,
      text: t("navigation.contacts"),
    },
    {
      href: "/clients",
      icon: <PersonGroup className="h-5 w-5 shrink-0" />,
      text: t("navigation.clients"),
    },
    {
      href: "/portfolios",
      icon: <PortfoliosIcon className="h-5 w-5 shrink-0" />,
      text: t("navigation.portfolios"),
    },
    {
      href: "/proposals",
      icon: <ProposalsIcon className="h-5 w-5 shrink-0" />,
      text: t("navigation.proposals"),
    },
    {
      href: "/fees",
      icon: <DollarIcon className="h-5 w-5 shrink-0" />,
      text: t("navigation.fees"),
    },
    {
      href: "/payouts",
      icon: <BanknotesIcon className="h-5 w-5 shrink-0" />,
      text: t("navigation.payouts"),
    },
    {
      href: "/trade",
      icon: <TradeIcon className="h-5 w-5 shrink-0" />,
      text: t("navigation.trade"),
    },
    {
      href: "/watchlists",
      icon: <DocumentMagnifyingGlass className="h-5 w-5 shrink-0" />,
      text: t("navigation.watchlist"),
    },
  ];

  return (
    <div className="flex flex-col items-center">
      <EvivaLogo
        className=".5 float-left mb-[55px] hidden w-44 shrink-0 xl:block"
        href="/dashboard"
      />
      <EvivaLogoSmall
        className=".5 float-left mb-[55px] block aspect-square w-10 shrink-0 xl:hidden"
        href="/dashboard"
      />
      <div className="flex h-full flex-col justify-between">
        <div className="flex flex-col gap-1.5">
          {menuItems.map((item, idx) => {
            return (
              <NavLink key={"nav_item_" + idx} to={item.href}>
                {({ isActive }) => <MenuItem item={item} isActive={isActive} />}
              </NavLink>
            );
          })}
        </div>
      </div>
    </div>
  );
};

type MenuItemProps = {
  item: NavigationItem;
  isActive: boolean;
};

const MenuItem: React.FC<MenuItemProps> = ({ item, isActive }) => {
  return (
    <div
      className={
        "flex items-center gap-4 rounded-xl px-2 py-2 duration-150 hover:bg-sb-gray-800 hover:text-white xl:px-4 " +
        (isActive ? "bg-black text-white" : "")
      }
    >
      <div className={isActive ? "text-sb-green-700" : ""}>{item.icon}</div>
      <span className="hidden xl:block">{item.text}</span>
    </div>
  );
};
