import React, { ForwardedRef, InputHTMLAttributes } from "react";

import "react-phone-input-2/lib/style.css";
import "./phone_input_styles.css";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  defaultCountry?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  ref?: ForwardedRef<HTMLInputElement>;
}

export const PhoneNumberInput: React.FC<Props> = React.forwardRef(
  (props, ref) => {
    const { t } = useTranslation();
    const { defaultCountry, ...restProps } = props;

    return (
      <PhoneInput
        enableSearch={true}
        country={defaultCountry || "it"}
        inputProps={{
          name: "phone_number",
          ref: ref ? ref : undefined,
          ...restProps,
        }}
        placeholder={t("global.form.phoneNumber")}
        searchPlaceholder={t("global.search")}
      />
    );
  },
);
