import { colorsConfig } from "colors.config";
import {
  calculateYToYAxisValue,
  calculateYAxisValueToY,
} from "./assetChartUtils";

export const CustomCursor = (props: any) => {
  const { width, left, points, hoverY, payload, height } = props;
  const lineColor = colorsConfig["black"];
  const latestPrice =
    payload?.[0]?.payload?.latestPrice ||
    calculateYToYAxisValue(props.chartData, hoverY - 10, height);
  const fixedY =
    calculateYAxisValueToY(props.chartData, latestPrice, height) + 10;
  const strokeColor = payload?.[0].stroke || lineColor;
  const date = payload?.[0]?.payload?.date;
  const parsedDate = date ? props.xFormatter(date, true) : "N/A";

  return (
    <g>
      {hoverY !== undefined && (
        <>
          <line
            fill={lineColor}
            stroke={lineColor}
            x1={left}
            y1={fixedY}
            x2={width + left}
            y2={fixedY}
            strokeDasharray={"3 3"}
            strokeWidth={1}
          />
          <line
            fill={lineColor}
            stroke={lineColor}
            x1={points[0].x}
            y1={points[0].y}
            x2={points[1].x}
            y2={points[1].y}
            strokeDasharray={"3 3"}
            strokeWidth={1}
          />
          {payload?.[0]?.payload?.latestPrice && (
            <circle
              cx={points[0].x}
              cy={fixedY}
              r={4}
              fill={strokeColor}
              stroke="#FFF"
              strokeWidth={2}
            />
          )}
          <rect
            fill={lineColor}
            stroke={lineColor}
            width="66"
            height="17"
            x={width + left}
            y={fixedY - 8}
          />
          <text
            x={width + left + 8}
            y={fixedY + 4}
            fill="#FFF"
            fontWeight="normal"
          >
            {latestPrice ? props.yFormatter(latestPrice) : null}
          </text>
          <rect
            fill={lineColor}
            stroke={lineColor}
            width="100"
            height="17"
            x={points[1].x - 50}
            y={points[1].y}
          />
          <text
            x={points[1].x}
            y={points[1].y + 12}
            fill="#FFF"
            textAnchor="middle"
            fontWeight="normal"
          >
            {parsedDate}
          </text>
        </>
      )}
    </g>
  );
};
