type Props = {
  className?: string;
};

export const DashboardIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9203 1.45078C9.9099 1.34192 9.16602 2.17472 9.16602 3.06797V9.16642C9.16602 10.0869 9.91221 10.8331 10.8327 10.8331H16.9311C17.8244 10.8331 18.6572 10.0892 18.5483 9.07875C18.4607 8.26569 18.2574 7.46769 17.9433 6.70927C17.5112 5.66606 16.8778 4.71817 16.0794 3.91973C15.2809 3.1213 14.333 2.48794 13.2898 2.05583C12.5314 1.74168 11.7334 1.53837 10.9203 1.45078ZM10.8327 9.16642V3.11823C11.4567 3.1938 12.0689 3.35407 12.652 3.59561C13.493 3.94397 14.2572 4.45456 14.9009 5.09824C15.5445 5.74191 16.0551 6.50607 16.4035 7.34707C16.645 7.93021 16.8053 8.54238 16.8809 9.16642L10.8327 9.16642Z"
        fill="currentColor"
      />
      <path
        d="M9.24967 16.6664C11.9073 16.6664 14.1574 14.9135 14.9036 12.5002H16.6301C15.8395 15.8442 12.8362 18.3331 9.24967 18.3331C5.06134 18.3331 1.66602 14.9378 1.66602 10.7494C1.66602 7.16287 4.15499 4.15959 7.49911 3.36906V5.09547C5.08563 5.84168 3.33267 8.09174 3.33267 10.7494C3.33267 14.0173 5.9818 16.6664 9.24967 16.6664Z"
        fill="currentColor"
      />
    </svg>
  );
};
