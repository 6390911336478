import { useTranslation } from "react-i18next";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import { BrokerageAccountContact } from "shared/models/brokerage_account/BrokerageAccountModel";
import { SimpleRow } from "./rows/SimpleRow";

type Props = {
  contact?: BrokerageAccountContact;
};

export const Contact: React.FC<Props> = ({ contact }) => {
  const { t } = useTranslation();

  return (
    <Panel>
      <Labels.H3 className="mb-4">{t("brokerageAccount.contact")}</Labels.H3>
      {contact && (
        <div className="space-y-8">
          <SimpleRow
            title={t("brokerageAccount.fields.emailAddress")}
            value={contact?.email_address}
          />
          <SimpleRow
            title={t("brokerageAccount.fields.address")}
            value={`${contact.local_street_address || ""} ${
              contact.postal_code
            } ${contact.city} ${contact.state || ""}`.trim()}
          />
          <SimpleRow
            title={t("brokerageAccount.fields.phoneNumber")}
            value={contact?.phone_number}
          />
        </div>
      )}
    </Panel>
  );
};
