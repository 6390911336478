import { createAsyncThunk } from "@reduxjs/toolkit";
import { ClientsService } from "shared/services/clients/ClientsService";

export const fetchClients = createAsyncThunk(
  "[CLIENTS] Fetch clients",
  async () => {
    const clients = await ClientsService.getMany();
    return clients;
  },
);

export const fetchClientById = createAsyncThunk(
  "[CLIENTS] Fetch client by id",
  async (clientId: string) => {
    const client = await ClientsService.getById(clientId);
    return client;
  },
);

export const ClientsThunks = {
  fetchClients,
  fetchClientById,
};
