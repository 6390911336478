import { Tab } from "@headlessui/react";
import { ClientCash } from "modules/client_cash/ClientCash";
import { ClientHeader } from "modules/client_header/ClientHeader";
import { RecentActivities } from "modules/recent_activities/RecentActivities";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { BasePage, RightBar } from "shared/components/common/BasePage";
import { Button } from "shared/components/controls/Button";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import { ClientDashboard } from "./tabs/ClientDashboard";
import { ClientHoldings } from "./tabs/ClientHoldings";
import "./styles.css";
import { selectClientById } from "shared/store/clients/ClientsReducer";
import { ClientsThunks } from "shared/store/clients/ClientsThunks";
import { ClientBrokerageAccount } from "./tabs/ClientBrokerageAccount";
import { ClientTradingAccount } from "./tabs/ClientTradingAccount";
import { Layouts } from "shared/components/hoc/Layouts";
import { SelectProposalModal } from "modules/proposals/SelectProposalModal";
import { objectToQueryString } from "shared/utils/utils";
import { ClientDepositInformation } from "./tabs/ClientDepositInformation";
import { ClientFees } from "./tabs/ClientFees";
import { ClientFeeSchedules } from "./tabs/ClientFeeSchedules";

export const ClientDetailsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clientId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const client = useSelector(selectClientById(clientId));
  const [showSelectProposalModal, setShowSelectProposalModal] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (clientId) {
      dispatch(ClientsThunks.fetchClientById(clientId));
    }
  }, [clientId, dispatch]);

  useEffect(() => {
    if (searchParams.get("tab")) {
      setSelectedIndex(parseInt(searchParams.get("tab") || "0"));
    }
  }, [searchParams]);

  const tabChanged = (index: number) => {
    setSearchParams({ tab: index.toString() });
    setSelectedIndex(index);
  };

  return (
    <BasePage>
      <RightBar>
        <div className="flex flex-col items-center space-y-8">
          <Layouts.Spaced>
            <Button
              label="clientDetails.newProposal"
              onClick={() => navigate("proposals/create")}
              className="w-full"
            />
            <Button
              label="clientDetails.assignExistingProposal"
              onClick={() => setShowSelectProposalModal(true)}
              className="w-full"
            />
            <Button
              label="trade.buyStock"
              onClick={() => {
                navigate(`/trade?clientId=${clientId}`);
              }}
              className="w-full"
            />
          </Layouts.Spaced>
          <ClientCash clientId={clientId} />
          <RecentActivities clientId={clientId} />
        </div>
      </RightBar>

      <SelectProposalModal
        open={showSelectProposalModal}
        setOpen={setShowSelectProposalModal}
        onClick={(proposal) => {
          const params = {
            p: proposal.strategy_type,
            i: proposal.initial_deposit,
            ra: proposal.recurring_deposit,
            rf: proposal.recurring_deposit_frequency,
            d: proposal.readme,
          };
          navigate({
            pathname: `proposals/create`,
            search: objectToQueryString(params),
          });
        }}
      />
      <ClientHeader client={client} backButton />
      <Layouts.Spaced className="min-w-[760px]">
        <Tab.Group
          defaultIndex={0}
          selectedIndex={selectedIndex}
          onChange={tabChanged}
        >
          <Tab.List className="box-border border-b-[3px] border-sb-gray-200 font-medium">
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={(selected ? "active" : "") + " tab"}>
                  {t("clientDetails.tabs.dashboard")}
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={(selected ? "active" : "") + " tab"}>
                  {t("clientDetails.tabs.holdings")}
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={(selected ? "active" : "") + " tab"}>
                  {t("clientDetails.tabs.brokerageAccount")}
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={(selected ? "active" : "") + " tab"}>
                  {t("clientDetails.tabs.tradingAccount")}
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={(selected ? "active" : "") + " tab"}>
                  {t("clientDetails.tabs.depositInformation")}
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={(selected ? "active" : "") + " tab"}>
                  {t("clientDetails.tabs.fees")}
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={(selected ? "active" : "") + " tab"}>
                  {t("clientDetails.tabs.feeSchedules")}
                </button>
              )}
            </Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              {clientId && <ClientDashboard clientId={clientId} />}
            </Tab.Panel>
            <Tab.Panel>
              <ClientHoldings clientId={clientId} />
            </Tab.Panel>
            <Tab.Panel>
              <ClientBrokerageAccount clientId={clientId} />
            </Tab.Panel>
            <Tab.Panel>
              <ClientTradingAccount clientId={clientId} />
            </Tab.Panel>
            <Tab.Panel>
              <ClientDepositInformation clientId={clientId} />
            </Tab.Panel>
            <Tab.Panel>
              <ClientFees clientId={clientId} />
            </Tab.Panel>
            <Tab.Panel>
              <ClientFeeSchedules clientId={clientId} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </Layouts.Spaced>
    </BasePage>
  );
};
