import React, { useState } from "react";
import { Controller } from "react-hook-form";

import { DatePicker, DateStates } from "./KycDatePicker";
import { useTranslation } from "react-i18next";
import { KycControllerProps } from "./KycForm";
import { MAX_AGE, LEGAL_AGE } from "shared/utils/common";

interface Props extends KycControllerProps {
  usDateOrder?: boolean;
}

export const KycDateOfBirthInput: React.FC<Props> = ({
  name,
  control,
  rules,
  error,
  disabled,
  defaultValue,
  usDateOrder,
}) => {
  const { t } = useTranslation();
  const [customError, setCustomError] = useState<string>("");

  return (
    <div className="relative mb-4 min-w-[250px]">
      <Controller
        name={name}
        control={control}
        rules={rules}
        disabled={disabled}
        defaultValue={defaultValue}
        render={({ field }) => (
          <>
            <DatePicker
              maxAge={MAX_AGE}
              minAge={LEGAL_AGE}
              usaDateOrder={!!usDateOrder}
              onDateSelect={(
                newDate: Date | undefined,
                dateState: DateStates,
              ) => {
                if (dateState === DateStates.AgeUnderMin) {
                  setCustomError("kyc.error.underage");
                  return;
                }
                if (dateState === DateStates.InvalidDate) {
                  return;
                }
                if (newDate) {
                  setCustomError("");
                  field.onChange(newDate.toISOString());
                }
              }}
              initialDate={field.value ? new Date(field.value) : undefined}
            />
            {error && (
              <p className="ml-3 mt-4 text-sm text-sb-red-600">
                {t(customError) || error.message}
              </p>
            )}
          </>
        )}
      />
    </div>
  );
};
