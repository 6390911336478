import { PortfolioList } from "pages/fa/proposals/components/PortfolioList";
import { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Modal, ModalScroll } from "shared/components/common/Modal";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import { ClientsService } from "shared/services/clients/ClientsService";
import { selectClientById } from "shared/store/clients/ClientsReducer";
import { ClientDetailsThunks } from "shared/store/client_details/ClientDetailsThunks";
import { CurrencyFormat } from "shared/utils/currency";

type Props = {
  clientId: string;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const AddPortfolioModal: React.FC<Props> = ({
  open,
  setOpen,
  clientId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const client = useSelector(selectClientById(clientId));
  const [amount, setAmount] = useState<string>();
  const [portfolioId, setPortfolioId] = useState<string>();
  const [loading, setLoading] = useState(false);

  const buying_power = parseFloat(client?.trading?.buying_power || "0");

  const onAddClicked = () => {
    if (clientId && portfolioId && amount) {
      setLoading(true);
      ClientsService.depositToPortfolio(clientId, portfolioId, +amount)
        .then(() => {
          toast.success(t("clientPortfolio.portfolioAddedSuccess"));
          dispatch(ClientDetailsThunks.fetchPortfolios({ clientId }));
        })
        .finally(() => {
          setLoading(false);
          setOpen(false);
        });
    }
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <Labels.H3>{t("clientPortfolio.investInPortfolio")}</Labels.H3>
      <div className="mt-8">
        <div>{t("clientCash.buying_power")}</div>
        <div className="text-lg font-medium">
          {CurrencyFormat.format(buying_power)}
        </div>
      </div>
      <div className="mt-8">
        <div className="text-xl font-medium">{t("proposals.investment")}</div>
        <div className="flex items-center gap-4">
          <div className="text-2xl">$</div>
          <CurrencyInput
            className="w-full min-w-0 text-3xl caret-sb-green-600 outline-none"
            placeholder="0"
            decimalsLimit={2}
            decimalScale={2}
            onValueChange={(value) => setAmount(value)}
          />
        </div>
      </div>
      <ModalScroll>
        <PortfolioList
          portfolioId={portfolioId}
          onPortfolioIdChange={setPortfolioId}
        />
      </ModalScroll>
      <div className="mt-8 flex items-center justify-center gap-5">
        <Button
          label="clientPortfolio.invest"
          onClick={onAddClicked}
          disabled={!amount || !portfolioId}
          loading={loading}
        />
        <Button
          btnStyle="blank"
          label="clientPortfolio.cancel"
          onClick={() => setOpen(false)}
          disabled={loading}
        />
      </div>
    </Modal>
  );
};
