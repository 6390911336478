import { useChartColors } from "modules/portfolio_composition/hooks/useChartColors";
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from "recharts";
import { PortfolioAssetAllocation } from "shared/models/portfolio/PortfolioModel";

type Props = {
  data: PortfolioAssetAllocation[];
};

export const AllocationChart: React.FC<Props> = ({ data }) => {
  const { getInnerColor } = useChartColors();

  const chartData: { name: string; value: number }[] = data.map((item) => {
    return {
      name: item.ticker,
      value: parseFloat(item.weight) * 100,
    };
  });

  const tooltipContent = (tooltipProps: any) => {
    const value =
      tooltipProps.payload && tooltipProps.payload.length > 0
        ? tooltipProps.payload[0]["payload"]["value"]
        : "";
    const name =
      tooltipProps.payload && tooltipProps.payload.length > 0
        ? tooltipProps.payload[0]["payload"]["name"]
        : "";
    return (
      <div className="rounded-sm bg-sb-gray-200 px-2.5 py-1.5 text-base font-medium">
        <span className="font-semibold">{name}</span> {value}%
      </div>
    );
  };

  return (
    <div className="flex items-center gap-4">
      <div className="aspect-square h-[200px] max-h-[300px] w-[350px] min-w-[300px]">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={chartData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={65}
              outerRadius={95}
              isAnimationActive={false}
            >
              {data.map((_, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={getInnerColor(index)}
                  stroke={getInnerColor(index)}
                />
              ))}
            </Pie>
            <Tooltip content={tooltipContent} />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div>
        {data.map((item, idx) => {
          return (
            <div key={"legend_" + idx} className="flex items-center gap-2">
              <div
                className="h-2 w-2 rounded-full"
                style={{ background: getInnerColor(idx) }}
              />
              <div className="text-lg font-semibold">{item.ticker}</div>
              <div>{Math.trunc(parseFloat(item.weight) * 100)}%</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
