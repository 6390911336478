import RestService from "shared/api/RestService";
import {
  AddContactModel,
  Contact,
} from "shared/models/contact/AddContactModel";
import { KycDraft, Onboarding } from "shared/models/onboarding/OnboardingModel";
import { FaApiRoutes } from "shared/utils/routes";
import { clientAccountValidation } from "./clientAccountValidation";

export const ContactsService = {
  async getAll(): Promise<Contact[]> {
    const response = await RestService.get<{ contacts: Contact[] }>(
      FaApiRoutes.Advisor.Contacts.Path,
    );
    return response.contacts;
  },

  async get(contactId: string): Promise<Contact> {
    const response = await RestService.get<Contact>(
      FaApiRoutes.Advisor.Contacts.Details(contactId).Path,
    );
    return response;
  },

  async remove(contactId: string) {
    return RestService.delete(
      FaApiRoutes.Advisor.Contacts.Details(contactId).Path,
    );
  },

  async create(contact: AddContactModel): Promise<{ contact_id: string }> {
    return RestService.post(FaApiRoutes.Advisor.Contacts.Path, { ...contact });
  },

  async update(contactId: string, contact: AddContactModel) {
    return RestService.patch(
      FaApiRoutes.Advisor.Contacts.Details(contactId).Path,
      { ...contact },
    );
  },

  async uploadKyc(contactId: string, KYCData: Onboarding) {
    const requestPayload = {
      contact: clientAccountValidation.validateContact(KYCData),
      identity: clientAccountValidation.validateIdentity(KYCData),
    };
    return RestService.post(
      FaApiRoutes.Advisor.Contacts.Details(contactId).KycDraft.Alpaca,
      requestPayload,
    );
  },

  async getKyc(contactId: string): Promise<KycDraft> {
    return RestService.get(
      FaApiRoutes.Advisor.Contacts.Details(contactId).KycDraft.Alpaca,
    );
  },

  async invite(contactId: string) {
    return RestService.post(
      FaApiRoutes.Advisor.Contacts.Details(contactId).Invite,
    );
  },
};
