import { colorsConfig } from "colors.config";
import { MetaPortfolio } from "shared/models/portfolio/PortfolioModel";
import { convertToNumber } from "shared/utils/converts";
import Values from "values.js";

export const useChartColors = (metaPortfolio?: MetaPortfolio[]) => {
  const INNER_COLORS = [
    colorsConfig["sb-violet"][600],
    colorsConfig["sb-cyan"][900],
    colorsConfig["sb-green"][900],
    colorsConfig["sb-pink"][700],
    colorsConfig["olive"][700],
    colorsConfig["sb-blue"][700],
    colorsConfig["sb-red"][700],
  ];

  const getInnerColor = (dataIndex: number) => {
    return INNER_COLORS[dataIndex % INNER_COLORS.length];
  };

  const getOuterColor = (
    innerColor: string,
    dataIndex: number,
    maxIndex: number,
  ) => {
    const valueColor = new Values(innerColor);
    const palette = valueColor.tints(100 / (maxIndex + 1));
    return palette[dataIndex % (palette.length - 1)].hexString();
  };

  if (!metaPortfolio) {
    return {
      innerColors: [],
      outerColors: [],
      innerData: [],
      outerData: [],
      getOuterColor,
      getInnerColor,
    };
  }

  let innerData = [];
  let outerData = [];
  let outerColors: string[] = [];
  let innerColors: string[] = [];
  for (let index = 0; index < metaPortfolio.length; index++) {
    const meta = metaPortfolio[index];
    const innerColor = getInnerColor(index);
    innerColors.push(innerColor);
    innerData.push({ name: meta.asset_class, value: meta.weight });
    for (let j = 0; j < meta.allocations.length; j++) {
      const allocation = meta.allocations[j];
      outerData.push({
        name: allocation.ticker,
        value:
          convertToNumber(allocation?.weight, 0) *
          convertToNumber(+meta?.weight, 0),
      });
      const maxIndex = meta.allocations.length;
      outerColors.push(getOuterColor(innerColor, j, maxIndex));
    }
  }

  return {
    innerColors,
    outerColors,
    innerData,
    outerData,
    getOuterColor,
    getInnerColor,
  };
};
