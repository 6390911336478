type Props = {
  className?: string;
};

export const Triangle: React.FC<Props> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 8 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.99975 4.09207L0.714257 4.08694C0.450263 4.08653 0.318333 3.75998 0.505006 3.56901L3.78393 0.214597C3.89975 0.0961139 4.08753 0.0961136 4.20334 0.214597L7.4923 3.57928C7.67928 3.77056 7.54658 4.09762 7.28215 4.0972L3.99975 4.09207Z"
        fill="currentColor"
      />
    </svg>
  );
};
