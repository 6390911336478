type Props = {
  className?: string;
};

export const AgreementCheck: React.FC<Props> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 300 300"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="93.8379"
        y="52.1738"
        width="130.223"
        height="163.877"
        rx="8"
        fill="#F5F5F6"
      />
      <rect
        x="111.382"
        y="69.7178"
        width="40.9692"
        height="7.31592"
        rx="2"
        fill="#34363A"
      />
      <rect
        x="111.382"
        y="84.3535"
        width="40.9692"
        height="7.31592"
        rx="2"
        fill="#34363A"
      />
      <rect
        x="111.382"
        y="110.703"
        width="87.7911"
        height="7.31592"
        rx="2"
        fill="#34363A"
      />
      <rect
        x="111.382"
        y="126.794"
        width="87.7911"
        height="7.31592"
        rx="2"
        fill="#34363A"
      />
      <rect
        x="111.382"
        y="142.897"
        width="87.7911"
        height="7.31592"
        rx="2"
        fill="#34363A"
      />
      <rect
        x="111.382"
        y="158.987"
        width="64.3801"
        height="7.31592"
        rx="2"
        fill="#34363A"
      />
      <rect
        x="111.382"
        y="175.076"
        width="24.8741"
        height="7.31592"
        rx="2"
        fill="#34363A"
      />
      <ellipse
        cx="158.592"
        cy="243.176"
        rx="71.327"
        ry="3.96261"
        fill="#34363A"
      />
      <path
        opacity="0.5"
        d="M136.748 96.71C135.026 107.891 124.566 115.56 113.384 113.838C102.203 112.116 94.5342 101.655 96.2562 90.474C97.9783 79.2925 108.439 71.624 119.62 73.3461C130.802 75.0681 138.47 85.5285 136.748 96.71Z"
        fill="#80D4FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M112.293 121.06C127.468 123.397 141.665 112.99 144.002 97.8153C146.339 82.6404 135.932 68.4442 120.757 66.1072C105.582 63.7701 91.3855 74.1772 89.0485 89.3521C87.9111 96.7372 89.7921 103.89 93.7721 109.558C93.3586 109.95 92.8943 110.395 92.3719 110.9C87.9455 115.177 79.3498 123.719 74.929 128.345C70.5082 132.972 70.2627 133.682 70.292 134.486C70.3213 135.29 70.6253 136.188 71.13 136.776C71.6348 137.364 72.3403 137.642 73.1665 137.634C73.9928 137.625 74.9404 137.328 80.2449 133.425C85.5495 129.522 95.211 122.011 99.9094 118.236C100.508 117.754 101.026 117.334 101.472 116.965C104.655 119.016 108.31 120.447 112.293 121.06ZM136.771 96.7017C135.049 107.883 124.589 115.552 113.407 113.83C102.226 112.108 94.5571 101.647 96.2792 90.4657C98.0012 79.2842 108.462 71.6158 119.643 73.3378C130.825 75.0599 138.493 85.5202 136.771 96.7017Z"
        fill="#9B9FA7"
      />
    </svg>
  );
};
