import { DateTime } from "luxon";
import { Onboarding } from "shared/models/onboarding/OnboardingModel";
import { DATE_FORMAT } from "shared/utils/consts";
import { formatStreetAddress } from "shared/utils/kyc_utils";

const validateContact = (input: Onboarding) => {
  const street_address = formatStreetAddress(
    input.street || "",
    input.addressUnit,
  );
  const city = input.city;
  const state = input.state;
  // Alpaca requires alpha-numeric postal codes
  const postal_code = (input.postalCode || "").replace(/[^0-9a-z]/gi, "");
  const phone_number = input.phone_number;

  return {
    street_address,
    city,
    state,
    postal_code,
    phone_number,
  };
};

const validateIdentity = (input: Onboarding) => {
  const date_of_birth = input.birthday
    ? DateTime.fromISO(input.birthday).toFormat(DATE_FORMAT)
    : undefined;

  const country_of_tax_residence = input.taxCountry;
  const tax_id_type = input.taxIdType || "NOT_SPECIFIED";
  const tax_id =
    input.taxIdNotLegallyRequired === true
      ? "Not legally required"
      : input.taxId;

  return {
    given_name: input.firstName,
    family_name: input.lastName,
    ...(input.middleName && { middle_name: input.middleName }),
    date_of_birth,
    tax_id,
    tax_id_type,
    country_of_citizenship: input.nationality,
    country_of_tax_residence,
  };
};

export const clientAccountValidation = {
  validateContact,
  validateIdentity,
};
