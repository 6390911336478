import { t } from "i18next";
import { Labels } from "shared/components/hoc/Labels";
import { AgreementCheck } from "shared/icons/AgreementCheck";
import { EvivaLogo } from "shared/icons/EvivaLogo";

export const TermsAndConditionsColumn: React.FC = () => {
  return (
    <div>
      <div className="rounded-3xl bg-black p-10">
        <EvivaLogo className="mb-20 w-[150px] text-white" />
        <Labels.H1 className="mb-4 text-4xl text-white">
          {t("webOnboarding.reviewConditions")}
        </Labels.H1>
        <Labels.B1 className="text-white">
          {t("webOnboarding.pleaseRead")}
        </Labels.B1>
        <AgreementCheck className="h-[250px] w-[250px]" />
      </div>
    </div>
  );
};
