import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PortfolioProjection } from "shared/models/portfolio/PortfolioModel";
import {
  Bar,
  BarChart,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { CurrencyFormat } from "shared/utils/currency";
import { FrequencyType } from "shared/types/RecurringInvestmentModel";
import { convertCurrency } from "shared/utils/converts";
import { BarChartIcon } from "shared/icons/BarChartIcon";
import { Panel } from "shared/components/hoc/Panels";
import { PortfoliosService } from "shared/services/portfolios/PortfoliosService";

const x_axis: string[] = [
  "portfolioProjectionChart.graphPeriods.now",
  "portfolioProjectionChart.graphPeriods.5Y",
  "portfolioProjectionChart.graphPeriods.10Y",
  "portfolioProjectionChart.graphPeriods.15Y",
  "portfolioProjectionChart.graphPeriods.20Y",
];

interface Props {
  portfolioId?: string;
  frequency?: FrequencyType;
  recurringAmount?: string;
  duration: number;
  initialAmount?: string;
  height?: number;
}

export const PortfolioProjectionChart: React.FC<Props> = ({
  portfolioId,
  frequency,
  recurringAmount,
  duration,
  initialAmount,
  height = 200,
}) => {
  const [projection, setProjection] = useState<PortfolioProjection | null>(
    null,
  );

  const future_value = projection?.future_value;
  const invested_amount = projection?.invested_amount;

  const { t } = useTranslation();

  const data = future_value?.map((fv, idx) => ({
    name: t(x_axis[idx]),
    compounded: Math.round(fv.value),
    invested: Math.round(invested_amount ? invested_amount[idx].value : 0),
  }));

  const finalFutureValue = data ? data[data.length - 1]?.compounded : 0;

  const fetchAndUpdateProjection = useCallback(async () => {
    try {
      const simulationResponse = await PortfoliosService.getPortfolioProjection(
        {
          amount: `${recurringAmount || 0}`,
          strategy_id: portfolioId,
          frequency: frequency || "month",
          duration: duration || 240,
          initial_amount: +(initialAmount || 0),
        },
      );

      setProjection(simulationResponse[0]);
    } catch {
      setProjection(null);
    }
  }, [duration, frequency, initialAmount, portfolioId, recurringAmount]);

  useEffect(() => {
    fetchAndUpdateProjection();
  }, [fetchAndUpdateProjection]);

  const labelFormatter = (
    props: any,
    color: string,
    position: "top" | "bottom",
  ) => {
    const dy = position === "top" ? -10 : props.viewBox.height + 20;

    return (
      <g>
        <text
          x={props.viewBox.x}
          y={props.viewBox.y}
          fill={color}
          dy={dy}
          dx={props.width / 2}
          fontSize={12}
          textAnchor="middle"
        >
          {CurrencyFormat.format(props.value)}
        </text>
      </g>
    );
  };

  const placeholderStyle = { height: `${height}px` };

  return (
    <Panel>
      <div className="mb-2 text-lg font-semibold">
        {t("portfolioProjectionChart.title")}
      </div>
      <div className="mb-4 text-2xl font-semibold">
        {convertCurrency(finalFutureValue, 0, "low")}
      </div>
      {data?.length ? (
        <div className="relative w-full" style={{ height: height || 300 }}>
          <div className="absolute bottom-0 left-0 right-0 top-0">
            <ResponsiveContainer width={"100%"} height={height} minWidth={10}>
              <BarChart data={data}>
                <Bar
                  dataKey="invested"
                  stackId="a"
                  fill="#00CCCB"
                  radius={[0, 0, 5, 5]}
                >
                  <LabelList
                    dataKey="invested"
                    content={(props) =>
                      labelFormatter(props, "#00CCCB", "bottom")
                    }
                  />
                </Bar>
                <Bar
                  dataKey="compounded"
                  stackId="a"
                  fill="#01FD87"
                  radius={[5, 5, 0, 0]}
                >
                  <LabelList
                    dataKey="compounded"
                    content={(props) => labelFormatter(props, "#00AD3A", "top")}
                  />
                </Bar>
                <XAxis
                  tickMargin={5}
                  dataKey="name"
                  tickLine={false}
                  stroke="#000001"
                  fontSize={12}
                />

                <YAxis
                  padding={{ top: 20, bottom: 35 }}
                  type="number"
                  domain={["auto", "auto"]}
                  allowDataOverflow={true}
                  hide
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <div
          className="grid w-full animate-pulse place-items-center rounded-xl bg-sb-gray-100"
          style={placeholderStyle}
        >
          <BarChartIcon className="h-24 w-24 fill-sb-gray-300 stroke-sb-gray-300" />
        </div>
      )}
      <div className="mt-4 flex w-full items-center justify-between text-sm">
        <div className="flex items-center gap-2">
          <div className="h-3 w-3 bg-[#01FD87]"></div>
          {t("portfolioProjectionChart.compoundedReturn")}
        </div>
        <div className="flex items-center gap-2">
          <div className="h-3 w-3 bg-[#00CCCB]"></div>
          {t("portfolioProjectionChart.amountInvested")}
        </div>
        <div className="cursor-pointer underline">
          <a
            href="https://www.streetbeat.com/en/disclosure-library"
            target="_blank"
            rel="noreferrer"
          >
            {t("global.disclaimers")}
          </a>
        </div>
      </div>
    </Panel>
  );
};
