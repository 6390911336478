import RestService from "shared/api/RestService";
import {
  CreateTradingOrder,
  TradingOrder,
} from "shared/models/trading/TradingOrderModel";
import { FaApiRoutes } from "shared/utils/routes";
import { ISODate } from "shared/utils/types";

export type RecentOrdersParams = {
  client_ids?: string[];
  submitted_from?: ISODate;
  submitted_to?: ISODate;
};

export const OrdersService = {
  async fetch(clientId: string, params?: string): Promise<TradingOrder[]> {
    return await RestService.get<TradingOrder[]>(
      FaApiRoutes.Advisor.Clients.Details(clientId).Orders.Path + params,
    );
  },

  async cancel(orderId: string, clientId: string): Promise<void> {
    await RestService.delete(
      FaApiRoutes.Advisor.Clients.Details(clientId).Orders.Details(orderId)
        .Path,
    );
  },

  async create(orders: CreateTradingOrder[], clientId: string): Promise<void> {
    return await RestService.post(
      FaApiRoutes.Advisor.Clients.Details(clientId).Orders.Path,
      orders,
    );
  },

  async fetchOne(
    symbol: string,
    clientId: string,
  ): Promise<TradingOrder | undefined> {
    return await RestService.get<TradingOrder>(
      FaApiRoutes.Advisor.Clients.Details(clientId).Orders.Details(symbol).Path,
    );
  },

  async fetchRecent(params?: RecentOrdersParams): Promise<TradingOrder[]> {
    return RestService.get<TradingOrder[]>(
      FaApiRoutes.Advisor.Clients.Orders.Search,
      { ...params, client_ids: params?.client_ids?.join(",") },
    );
  },
};
