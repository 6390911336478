import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import useAuth from "shared/hooks/useAuth";
import AppleIcon from "shared/icons/AppleIcon";
import { EnvelopeIcon } from "shared/icons/EnvelopeIcon";
import GoogleIcon from "shared/icons/GoogleIcon";
import { FaOnboardingService } from "shared/services/fa_onboarding/FaOnboardingService";
import { WelcomeContainer } from "../WelcomeContainer";
import { SignUpButton } from "../WelcomePage";
import { EmailSignUpForm } from "./EmailSignUpForm";

const DEFAULT_SIGNUP_CODE = "no_code";

export const SignUpPage: React.FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [emailSignUp, setEmailSignUp] = useState(false);
  const { signInWithGoogle, signInWithApple } = useAuth();

  const code = searchParams.get("code") || DEFAULT_SIGNUP_CODE;

  const googleSignIn = async () => {
    await signInWithGoogle();
    await FaOnboardingService.postInvitationCode(code);
  };

  const appleSignIn = async () => {
    await signInWithApple();
    await FaOnboardingService.postInvitationCode(code);
  };

  return (
    <WelcomeContainer>
      {!emailSignUp ? (
        <div className="space-y-4">
          <SignUpButton
            icon={<GoogleIcon />}
            label={t("auth.signUpWithGoogle")}
            onClick={googleSignIn}
          />
          <SignUpButton
            icon={<AppleIcon />}
            label={t("auth.signUpWithApple")}
            onClick={appleSignIn}
          />
          <SignUpButton
            icon={<EnvelopeIcon className="aspect-square w-4" />}
            label={t("auth.signUpWithEmail")}
            onClick={() => setEmailSignUp(true)}
          />
          <div className="flex justify-center pt-10">
            <Trans i18nKey="welcome.alreadyHaveAnAccount">
              <span className="mr-1 font-semibold"></span>
              <Link
                to="/login"
                className="mr-1 font-semibold text-sb-green-600"
              >
                Login
              </Link>
            </Trans>
          </div>
        </div>
      ) : (
        <EmailSignUpForm
          onBackClicked={() => setEmailSignUp(false)}
          code={code || ""}
        />
      )}
    </WelcomeContainer>
  );
};
