import { AssetChart } from "modules/asset_chart/AssetChart";
import { AssetEarnings } from "modules/asset_earnings/AssetEarnings";
import { AssetInfo } from "modules/asset_info/AssetInfo";
import { AssetNews } from "modules/asset_news/AssetNews";
import { AssetStats } from "modules/asset_stats/AssetStats";
import { AssetTrade } from "modules/trade/AssetTrade";
import { useParams, useNavigate } from "react-router-dom";
import { BackButton } from "shared/components/common/BackButton";
import { BasePage, RightBar } from "shared/components/common/BasePage";
import { Skeleton } from "shared/components/common/Skeleton";
import { Labels } from "shared/components/hoc/Labels";
import useAssetsExtras from "shared/hooks/useAssetsExtras";
import { AddToWatchlistButton } from "./AddToWatchlistButton";
import { useDispatch } from "shared/hooks/useDispatch";
import { useEffect } from "react";
import { WatchlistThunks } from "shared/store/watchlist/WatchlistThunks";

export const AssetPage: React.FC = () => {
  const { assetId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { info, hasAnyInfo } = useAssetsExtras(assetId ? assetId : "");

  if (!assetId) {
    navigate("/");
  }

  useEffect(() => {
    dispatch(WatchlistThunks.fetchWatchlists());
  }, []);

  return (
    <BasePage globalSearch>
      {hasAnyInfo ? (
        <RightBar>
          <div>
            <AssetInfo assetId={assetId} />
          </div>
        </RightBar>
      ) : null}
      {assetId && (
        <div>
          {info ? (
            <div className="flex items-center justify-between gap-4">
              <Labels.H1>{info.securityName}</Labels.H1>
              <AddToWatchlistButton symbol={assetId} />
            </div>
          ) : (
            <Skeleton className="my-1 h-10 w-40 bg-sb-gray-300" />
          )}
          {info ? (
            <div className="mb-4 text-sb-gray-500">{info.symbol}</div>
          ) : (
            <Skeleton className="my-1 h-6 w-8 bg-sb-gray-200" />
          )}
          <BackButton noText />
          {assetId && (
            <div className="mt-10 space-y-8">
              <AssetChart assetId={assetId} height={300} />
              <AssetTrade assetId={assetId} />
              <AssetStats assetId={assetId} />
              <AssetEarnings assetId={assetId} />
              <AssetNews assetId={assetId} />
            </div>
          )}
        </div>
      )}
    </BasePage>
  );
};
