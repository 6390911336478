import { useEffect } from "react";
import { useDispatch } from "shared/hooks/useDispatch";
import { useRecentSearch } from "shared/hooks/useRecentSearch";
import { AppActions } from "shared/store/app/AppReducer";
import { auth } from "shared/utils/firebase";

export const RecentSearchFetcher: React.FC = () => {
  const dispatch = useDispatch();
  const { getRecentSearches } = useRecentSearch();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        getRecentSearches().then((result) => {
          dispatch(AppActions.setSearchHistory(result));
        });
      }
    });
    return () => unsubscribe();
  }, []);

  return <></>;
};
