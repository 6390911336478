import { LastTrading } from "modules/trading_account/LastTrading";
import { Trading } from "modules/trading_account/Trading";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Layouts } from "shared/components/hoc/Layouts";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import { selectClientById } from "shared/store/clients/ClientsReducer";
import { ClientsThunks } from "shared/store/clients/ClientsThunks";

type Props = {
  clientId?: string;
};

export const ClientTradingAccount: React.FC<Props> = ({ clientId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const client = useSelector(selectClientById(clientId));

  const tradingAccount = client?.trading?.alpaca_details?.trading_account;

  useEffect(() => {
    if (clientId) {
      dispatch(ClientsThunks.fetchClientById(clientId));
    }
  }, [dispatch, clientId]);

  if (client && !client.summary.account_name) {
    return (
      <div className="text-xl font-semibold">
        {t("clientDetails.noAccount")}
      </div>
    );
  }
  return (
    <Layouts.Spaced>
      {tradingAccount && (
        <>
          <Trading tradingAccount={tradingAccount} />
          <LastTrading tradingAccount={tradingAccount} />
        </>
      )}
    </Layouts.Spaced>
  );
};
