import { AnswerItem } from "./AnswerItem";

interface RadioOption<T> {
  value: T;
  label: string;
}

interface Props<T> {
  options: RadioOption<T>[];
  value: T[];
  onChange(value: T): void;
}

export function CheckboxAnswers<T>({ options, value, onChange }: Props<T>) {
  return (
    <div className="space-y-3">
      {options.map((option, idx) => (
        <AnswerItem
          key={idx}
          value={option.value}
          label={option.label}
          isActive={!!value.find((v) => option.value === v)}
          onChange={onChange}
        />
      ))}
    </div>
  );
}
