import { useSelector } from "shared/hooks/useDispatch";
import { CreateTradingOrder } from "shared/models/trading/TradingOrderModel";
import { selectCreateOrder } from "shared/store/create_order/CreateOrderReducer";

export default function useCreateOrder(): CreateTradingOrder {
  const {
    order = {
      symbol: "",
      assetClass: "us_equity",
      side: "buy",
      type: "market",
      limit_price: "0",
      allowNotional: true,
      time_in_force: "day",
      qty: "0",
      notional: "0",
      stop_loss: { stop_price: "0", limit_price: "0" },
      take_profit: { limit_price: "0" },
      stop_price: "0",
      trail_percent: "0",
      trail_price: "0",
    },
  } = useSelector(selectCreateOrder);

  return order;
}
