import { t } from "i18next";
import { SimpleRow } from "modules/brokerage_account/rows/SimpleRow";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import { AlpacaTradingAccount } from "shared/models/brokerage/TradingAccountModel";
import { CurrencyFormat } from "shared/utils/currency";
import { formatDate } from "shared/utils/date";

type Props = {
  tradingAccount: AlpacaTradingAccount;
};

export const Trading: React.FC<Props> = ({ tradingAccount }) => {
  return (
    <Panel>
      <Labels.H3 className="mb-4">{t("tradingAccount.account")}</Labels.H3>
      {tradingAccount && (
        <div className="grid grid-cols-3 gap-8">
          <div className="col-span-3">
            <SimpleRow
              title={t("tradingAccount.createdAt")}
              value={formatDate(tradingAccount.created_at)}
            />
          </div>
          <SimpleRow
            title={t("tradingAccount.equity")}
            value={CurrencyFormat.format(tradingAccount.equity)}
          />
          <div className="col-span-2">
            <SimpleRow
              title={t("tradingAccount.portfolioValue")}
              value={CurrencyFormat.format(tradingAccount.portfolio_value)}
            />
          </div>
          <SimpleRow
            title={t("tradingAccount.buyingPower")}
            value={CurrencyFormat.format(tradingAccount.buying_power)}
          />
          <div className="col-span-2">
            <SimpleRow
              title={t("tradingAccount.effectiveBuyingPower")}
              value={CurrencyFormat.format(
                tradingAccount.effective_buying_power,
              )}
            />
          </div>
          <SimpleRow
            title={t("tradingAccount.cash")}
            value={CurrencyFormat.format(tradingAccount.cash)}
          />
          <SimpleRow
            title={t("tradingAccount.cashTransferable")}
            value={CurrencyFormat.format(tradingAccount.cash_transferable)}
          />
          <SimpleRow
            title={t("tradingAccount.cashWithdrawable")}
            value={CurrencyFormat.format(tradingAccount.cash_withdrawable)}
          />
          <div className="col-span-3">
            <SimpleRow
              title={t("tradingAccount.longMarketValue")}
              value={CurrencyFormat.format(tradingAccount.long_market_value)}
            />
          </div>
          <SimpleRow
            title={t("tradingAccount.currency")}
            value={tradingAccount.currency}
          />
        </div>
      )}
    </Panel>
  );
};
