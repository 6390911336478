import RestService from "shared/api/RestService";
import { News } from "shared/models/news/NewsModel";
import { NEWS_V2 } from "shared/utils/routes";

export const NewsService = {
  async fetch(tickers: string): Promise<News[]> {
    const news = await RestService.get<{ news: News[] }>(NEWS_V2, { tickers });
    return news.news.map((item) => ({
      ...item,
      id: `${item.id}_${item.created_at}`,
    }));
  },
};
