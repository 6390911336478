import { useTranslation } from "react-i18next";
import { Client } from "shared/models/client/ClientModel";
import { CurrencyFormat } from "shared/utils/currency";

type Props = {
  client?: Client;
};

export const ClientBuyingPower: React.FC<Props> = ({ client }) => {
  const { t } = useTranslation();

  if (!client) {
    return null;
  }

  return (
    <div className="text-lg">
      <div>
        {t("tradingAccount.buyingPower")}:
        <span className="ml-2 font-semibold">
          {CurrencyFormat.format(client?.trading?.buying_power || 0)}
        </span>
      </div>
      <div>
        {t("tradingAccount.cash")}:
        <span className="ml-2 font-semibold">
          {CurrencyFormat.format(client?.trading?.cash || 0)}
        </span>
      </div>
    </div>
  );
};
