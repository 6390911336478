type Props = {
  className?: string;
};

export const PaperAirplane: React.FC<Props> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.2571 5.83616L9.37733 11.2674C9.33274 11.3298 9.30538 11.4029 9.298 11.4793C9.29062 11.5557 9.30349 11.6327 9.3353 11.7025L12.0308 17.6085C12.2731 18.1573 13.0637 18.1209 13.2528 17.551L18.8157 0.862316C18.8541 0.746877 18.8595 0.623042 18.8315 0.504664C18.8035 0.386286 18.7431 0.278035 18.6571 0.192019C18.5711 0.106003 18.4629 0.0456144 18.3445 0.0176096C18.2261 -0.0103952 18.1023 -0.00491181 17.9868 0.0334456L1.29816 5.59634C0.728893 5.78609 0.692425 6.57664 1.24068 6.81832L7.14723 9.51323C7.21707 9.54504 7.29405 9.55791 7.37043 9.55053C7.44682 9.54315 7.51992 9.51579 7.58237 9.4712L13.0136 5.59139C13.0473 5.56698 13.0886 5.55538 13.13 5.55867C13.1715 5.56196 13.2104 5.57992 13.2398 5.60933C13.2692 5.63874 13.2872 5.67767 13.2905 5.71913C13.2938 5.76059 13.2815 5.80248 13.2571 5.83616Z"
        fill="currentColor"
      />
    </svg>
  );
};
