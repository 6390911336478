type Props = {
  className?: string;
};

export const CheckCircleOutlined: React.FC<Props> = ({ className }) => {
  return (
    <>
      <svg
        viewBox="0 0 105 104"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M52.5002 17.334C33.3542 17.334 17.8335 32.8547 17.8335 52.0006C17.8335 71.1466 33.3542 86.6673 52.5002 86.6673C71.6461 86.6673 87.1668 71.1466 87.1668 52.0006C87.1668 32.8547 71.6461 17.334 52.5002 17.334ZM52.5002 24.0437C67.9509 24.0437 80.4571 36.5476 80.4571 52.0006C80.4571 67.4514 67.9532 79.9576 52.5002 79.9576C37.0495 79.9576 24.5432 67.4537 24.5432 52.0006C24.5432 36.5499 37.0471 24.0437 52.5002 24.0437ZM72.0986 42.253L68.9484 39.0774C68.296 38.4197 67.2339 38.4154 66.5762 39.0679L46.8173 58.668L38.4593 50.2422C37.8069 49.5845 36.7449 49.5801 36.0872 50.2325L32.9114 53.3827C32.2537 54.0351 32.2494 55.0972 32.9019 55.755L45.5917 68.5475C46.2441 69.2052 47.3062 69.2096 47.9639 68.5571L72.0892 44.6253C72.7467 43.9728 72.7509 42.9107 72.0986 42.253Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};
