import { createSelector, createSlice } from "@reduxjs/toolkit";
import { TimestampedRecentSearch } from "shared/hooks/useRecentSearch";
import { ChatMessageNotification } from "shared/types/Chat";
import { RootState } from "../store";
import { AIAssistantSession } from "shared/models/assistant/AIAssistantModel";

interface AppState {
  chatsOpened: string[];
  unseenMessages: ChatMessageNotification[];
  advisorId: string;
  searchHistory: TimestampedRecentSearch[];
  assistantOpen: boolean;
  assistantActiveSession: AIAssistantSession | undefined;
}

const initialState: AppState = {
  chatsOpened: [],
  unseenMessages: [],
  advisorId: "",
  searchHistory: [],
  assistantOpen: false,
  assistantActiveSession: undefined,
};

const AppStateSlice = createSlice({
  name: "[APP]",
  initialState,
  reducers: {
    clearStoreRequest: () => {
      // Note that this should be left intentionally empty.
      // Clearing redux state and AsyncStorage happens in store.ts
    },
    toggleChat: (state, action) => {
      const payload = action.payload;
      if (state.chatsOpened.includes(payload)) {
        state.chatsOpened = state.chatsOpened.filter((id) => id !== payload);
      } else {
        state.chatsOpened.push(payload);
      }
    },
    openChat: (state, action) => {
      const payload = action.payload;
      if (!state.chatsOpened.includes(payload)) {
        state.chatsOpened.push(payload);
      }
    },
    openAiAssistant: (state) => {
      state.assistantOpen = true;
    },
    toggleAiAssistant: (state) => {
      state.assistantOpen = !state.assistantOpen;
    },
    setAssistantActiveSession: (state, action) => {
      state.assistantActiveSession = action.payload;
    },
    setUnseenMessages: (state, action) => {
      state.unseenMessages = action.payload;
    },
    setAdvisorId: (state, action) => {
      state.advisorId = action.payload;
    },
    setSearchHistory: (state, action) => {
      state.searchHistory = action.payload;
    },
  },
});

export const selectSearchHistory = () =>
  createSelector(
    (state: RootState) => state,
    (state) => state.app.searchHistory,
  );

export const selectAssistantOpened = () =>
  createSelector(
    (state: RootState) => state,
    (state) => state.app.assistantOpen,
  );

export const selectAssistantActiveSession = () =>
  createSelector(
    (state: RootState) => state,
    (state) => state.app.assistantActiveSession,
  );

export const AppActions = { ...AppStateSlice.actions };

export default AppStateSlice.reducer;
