import { PropsWithChildren } from "react";
import { Spinner } from "./Spinner";

type BoxType = "info" | "success" | "warning" | "error";

type Props = PropsWithChildren & {
  type: BoxType;
  title: string;
  description?: string;
  buttonText?: string;
  buttonLoading?: boolean;
  onClick?: () => void;
};

export const InfoBox: React.FC<Props> = ({
  type,
  title,
  description,
  children,
  buttonText,
  buttonLoading,
  onClick,
}) => {
  const colorClasses: Record<BoxType, string> = {
    info: "bg-sb-blue-100 border-sb-blue-700 text-sb-blue-900",
    success: "bg-sb-green-100 border-sb-green-700 text-sb-green-900",
    warning: "bg-orange-100 border-orange-300 text-orange-900",
    error: "bg-red-100 border-red-700 text-sb-red-900",
  };

  return (
    <div className={colorClasses[type] + " rounded-3xl  p-4"}>
      <div className="flex w-full flex-wrap items-center gap-4">
        <div className="flex-grow">
          <div className="mb-2 text-xl font-semibold">{title}</div>
          {description && <div className="text-lg">{description}</div>}
        </div>
        {buttonText && (
          <button className="orange-btn text-lg" onClick={() => onClick?.()}>
            {buttonLoading ? (
              <Spinner className="absolute bottom-0 left-0 right-0 top-0 m-auto h-[20px] w-[20px] text-white" />
            ) : (
              buttonText
            )}
          </button>
        )}
      </div>
      {children}
    </div>
  );
};
