import { t } from "i18next";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import { BrokerageAccountIdentity } from "shared/models/brokerage_account/BrokerageAccountModel";
import { CompactFormat } from "shared/utils/utils";
import { ListRow } from "./rows/ListRow";
import { SimpleRow } from "./rows/SimpleRow";

type Props = {
  identity?: BrokerageAccountIdentity;
};

const getClientName = (identity: BrokerageAccountIdentity) => {
  return `${identity.given_name} ${identity.middle_name || ""} ${
    identity.family_name
  }`;
};

export const Identity: React.FC<Props> = ({ identity }) => {
  return (
    <Panel>
      <Labels.H3 className="mb-8">{t("brokerageAccount.identity")}</Labels.H3>
      {identity && (
        <div className="grid grid-cols-3 gap-y-8">
          <SimpleRow
            title={t("brokerageAccount.fields.name")}
            value={getClientName(identity)}
          />
          <div className="col-span-2">
            <SimpleRow
              title={t("brokerageAccount.fields.dob")}
              value={identity.date_of_birth}
            />
          </div>
          {identity.annual_income_min && identity.annual_income_max ? (
            <SimpleRow
              title={t("brokerageAccount.fields.annualIncome")}
              value={`${CompactFormat.format(
                parseInt("" + identity.annual_income_min),
              )} - ${CompactFormat.format(
                parseInt("" + identity.annual_income_max),
              )}`}
            />
          ) : null}
          <div className="col-span-2">
            {identity.liquid_net_worth_min && identity.liquid_net_worth_max ? (
              <SimpleRow
                title={t("brokerageAccount.fields.liquidNetWorth")}
                value={`${CompactFormat.format(
                  parseInt("" + identity.liquid_net_worth_min),
                )} - ${CompactFormat.format(
                  parseInt("" + identity.liquid_net_worth_max),
                )}`}
              />
            ) : null}
          </div>
          {identity.funding_source && (
            <div className="col-span-3">
              <ListRow
                title={t("brokerageAccount.fields.fundingSources")}
                values={identity.funding_source?.map((s) =>
                  t(`kyc.fundingSource.options.${s}`),
                )}
              />
            </div>
          )}
          <SimpleRow
            title={t("brokerageAccount.fields.countryOfCitizenship")}
            value={
              identity.country_of_citizenship || t("brokerageAccount.unknown")
            }
          />
          <SimpleRow
            title={t("brokerageAccount.fields.countryOfTaxResidence")}
            value={identity.country_of_tax_residence}
          />
          <SimpleRow
            title={t("brokerageAccount.fields.taxIdType")}
            value={identity.tax_id_type || t("brokerageAccount.unknown")}
          />
        </div>
      )}
    </Panel>
  );
};
