import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DropdownSelect, {
  DropdownOption,
} from "shared/components/controls/DropdownSelect";
import { KycControllerProps } from "./KycForm";

interface Props extends KycControllerProps {
  label: string;
  options: DropdownOption[];
}

export const KycDropdown: React.FC<Props> = ({
  name,
  control,
  rules,
  error,
  label,
  defaultValue,
  options,
}) => {
  const { t } = useTranslation();

  return (
    <div className="relative mb-4 min-w-[250px]">
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field }) => (
          <>
            <DropdownSelect
              selected={field.value || ""}
              className="peer block w-full appearance-none rounded-xl border-0 bg-sb-gray-100 px-2.5 py-1 text-base text-gray-900 focus:border-sb-green-700 focus:outline-none focus:ring-0"
              title={t(label)}
              options={options}
              onSelect={(o) => field.onChange(o.value)}
            />
            {error && (
              <p className="ml-3 text-sm text-sb-red-600">{error.message}</p>
            )}
          </>
        )}
      />
    </div>
  );
};
