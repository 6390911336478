import React from "react";
import { DateRange } from "react-aria";
import { useTranslation } from "react-i18next";
import { CalendarIcon } from "shared/icons/Calendar";
import { formatDate } from "shared/utils/date";

import { CustomDateRangePicker } from "../daterangepicker/CustomDateRangePicker";

interface Props<T> {
  periods: { id: T; text: string }[];
  selectedPeriod: T;
  renderCustomRangePeriod?: boolean;
  customRange?: DateRange;
  onChangeCustomRange?: (value?: DateRange) => void;
  onChange(period: T): void;
}

export function ChartPeriodSelector<T>({
  periods,
  selectedPeriod,
  renderCustomRangePeriod = false,
  customRange,
  onChangeCustomRange,
  onChange,
}: Props<T>) {
  const { t } = useTranslation();
  return (
    <div className="flex items-center gap-4">
      <div className="text-base">{t("aggregatedAum.timePeriod")}</div>
      <div className="flex flex-wrap items-center gap-2">
        {periods.map((item, idx) => {
          return (
            <div
              key={"period_" + idx}
              className={
                "rounded-selector cursor-pointer whitespace-nowrap hover:border-sb-green-700 hover:bg-sb-green-400 " +
                (selectedPeriod === item.id
                  ? "border-sb-green-700 bg-sb-green-500"
                  : "border-sb-gray-200 bg-sb-gray-100")
              }
              onClick={() => onChange(item.id)}
            >
              {item.text}
            </div>
          );
        })}

        {renderCustomRangePeriod ? (
          <CustomDateRangePicker
            value={customRange}
            onChange={onChangeCustomRange}
          >
            <div className="rounded-selector flex items-center gap-1 bg-black text-white duration-150 hover:bg-sb-gray-600">
              {customRange
                ? `${formatDate(customRange.start.toString())} - ${formatDate(
                    customRange.end.toString(),
                  )}`
                : t("aggregatedAum.period.custom")}
              <CalendarIcon className="h-4 w-4 fill-sb-green-500" />
            </div>
          </CustomDateRangePicker>
        ) : null}
      </div>
    </div>
  );
}

// export const ChartPeriodSelector: React.FC<Props> = ({
//   periods,
//   selectedPeriod,
//   onChange,
// }) => {

// };
