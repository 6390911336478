import { useTranslation } from "react-i18next";
import { ValueSelector } from "./ValueSelector";

type Props = {
  onChange?: (values: string[]) => void;
};

export const AssetClass: React.FC<Props> = ({ onChange }) => {
  const { t } = useTranslation();

  const preDefinedGoals = [
    "fixed_income",
    "growth",
    "cash_equivalents",
    "commodities",
    "dividend_value",
    "real_estate",
    "sector_specific",
  ];

  return (
    <ValueSelector
      title={t("proposals.assetClass")}
      translationKey="assetClass_answers"
      preDefinedValues={preDefinedGoals}
      onChange={onChange}
    />
  );
};
