type Props = {
  className?: string;
};

export const CheckCircle: React.FC<Props> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11 6C11 8.76143 8.76143 11 6 11C3.23857 11 1 8.76143 1 6C1 3.23857 3.23857 1 6 1C8.76143 1 11 3.23857 11 6ZM5.42165 8.64746L9.13133 4.93778C9.2573 4.81181 9.2573 4.60756 9.13133 4.48159L8.67514 4.0254C8.54917 3.89942 8.34492 3.89942 8.21893 4.0254L5.19355 7.05077L3.78107 5.63829C3.6551 5.51232 3.45085 5.51232 3.32486 5.63829L2.86867 6.09448C2.7427 6.22044 2.7427 6.4247 2.86867 6.55066L4.96544 8.64744C5.09143 8.77343 5.29567 8.77343 5.42165 8.64746Z"
        fill="currentColor"
      />
    </svg>
  );
};
