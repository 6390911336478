import React, { useState } from "react";

import { AvailableTaxTypes } from "shared/types/Countries";
import { LetUsKnowYouColumn } from "./components/LetUsKnowYouColumn";
import { OnboardingFooter } from "./components/OnboardingFooter";
import { OnboardingPartProps } from "./OnboardingFlow";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import {
  OnboardingActions,
  selectOnboarding,
} from "shared/store/onboarding/OnboardingReducer";
import { OnboardingContainer } from "shared/components/common/OnboardingContainer";
import { Labels } from "shared/components/hoc/Labels";
import { Inputs } from "shared/components/hoc/Inputs";
import LockerIcon from "shared/icons/LockerIcon";
import { ISOCountryCode, TaxType } from "shared/types/Countries";

type TaxIdKeys =
  | "sub_title"
  | "item1"
  | "item2"
  | "item3"
  | "item1.title"
  | "item2.title"
  | "item3.title"
  | "item1.sub_title"
  | "item2.sub_title"
  | "item3.sub_title";

export const TaxId: React.FC<OnboardingPartProps> = ({
  onNext,
  onPrevious,
}) => {
  const dispatch = useDispatch();
  const { taxCountry, taxId, nationality } = useSelector(selectOnboarding);
  const { t: nativeTranslation } = useTranslation();
  const [formattedValue, setFormattedValue] = useState("");

  const isPayingTaxesInUSA = taxCountry === "USA";
  const nonUsTaxNumberName = nativeTranslation(
    getTaxTypeByCode(taxCountry)
      ? getTaxTypeByCode(taxCountry)[0]?.t
      : "tax_codes.NOT_SPECIFIED",
  );
  const generateTextBasedOnKey = (key: TaxIdKeys): any =>
    isPayingTaxesInUSA
      ? `forms.kyc.screen_titles.tax_id_info.us.${key}`
      : `forms.kyc.screen_titles.tax_id_info.non_us.${key}`;

  const onChangeTaxId = (taxIdValue: string) => {
    dispatch(OnboardingActions.update({ taxId: taxIdValue }));
  };

  const t = (key: TaxIdKeys) =>
    nativeTranslation(generateTextBasedOnKey(key), {
      taxNumberName: nonUsTaxNumberName,
    });

  const taxTypeInfo = getTaxTypeByCode(taxCountry)[0];

  const format = (v: string) => {
    v = v.replace(/-/g, "");
    if (v.length <= 3) {
      return v;
    }
    if (v.length > 3 && v.length <= 5) {
      return `${v.slice(0, 3)}-${v.slice(3)}`;
    }
    return `${v.slice(0, 3)}-${v.slice(3, 5)}-${v.slice(5)}`;
  };

  const nonUSNationality = nationality !== "USA";

  const nextStep =
    nonUSNationality && isPayingTaxesInUSA
      ? "upload_visa"
      : "employment_status";

  return (
    <OnboardingContainer
      leftColumnContent={<LetUsKnowYouColumn />}
      rightColumnContent={
        <div className="mb-14 mt-20 flex flex-col">
          <div className="flex-1">
            <Labels.H2 className="mb-2">
              {nativeTranslation("forms.kyc.screen_titles.tax_id_info.title")}
            </Labels.H2>
            <Labels.B1 className="mb-6">{t("sub_title")}</Labels.B1>
            <div className="mb-8">
              {isPayingTaxesInUSA ? (
                <Inputs.Text
                  label={`kyc.${taxTypeInfo.t}`}
                  className="mb-2"
                  autoCapitalize="none"
                  value={formattedValue}
                  inputMode="numeric"
                  onChange={(value) => {
                    const unmaskedValue = value.replace(/-/g, "");
                    onChangeTaxId(unmaskedValue);
                    setFormattedValue(format(unmaskedValue));
                  }}
                />
              ) : (
                <Inputs.Text
                  onChange={onChangeTaxId}
                  value={taxId}
                  label={`kyc.${taxTypeInfo.t}`}
                  className="mb-2"
                  inputMode="numeric"
                  autoCapitalize="none"
                />
              )}
            </div>
            <Item title={t("item1.title")} description={t("item1.sub_title")} />
            <Item title={t("item2.title")} description={t("item2.sub_title")} />
            <Item title={t("item3.title")} description={t("item3.sub_title")} />
          </div>
          <OnboardingFooter
            onNext={() => onNext && onNext(nextStep)}
            onPrev={() => onPrevious && onPrevious()}
            nextDisabled={!taxId}
          />
        </div>
      }
    />
  );
};

interface ItemProps {
  title: string;
  description: string;
}

const Item: React.FC<ItemProps> = ({ title, description }) => {
  return (
    <div className="mb-4 flex">
      <LockerIcon className="h-5 w-5" />
      <div className="ml-4 flex-1">
        <Labels.B1>{title}</Labels.B1>
        <Labels.B1>{description}</Labels.B1>
      </div>
    </div>
  );
};

const getTaxTypeByCode = (
  code: ISOCountryCode | undefined,
): TaxType[] => {
  if (!code) return [{ t: "tax_codes.NOT_SPECIFIED", code: "NOT_SPECIFIED" }];
  const result = AvailableTaxTypes.filter((c) => c.countryCode === code);
  return result.length > 0
    ? result
    : [{ t: "tax_codes.NOT_SPECIFIED", code: "NOT_SPECIFIED" }];
};
