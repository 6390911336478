import { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  className?: string;
  Header?: React.ReactElement;
  Footer?: React.ReactElement;
}>;

export const PageContainer: React.FC<Props> = ({
  className,
  children,
  Header,
  Footer,
}) => {
  return (
    <div className={className + " min-w-[1280px]"}>
      {Header ? <div className="mb-8">{Header}</div> : null}
      {children}
      {Footer ? <div>{Footer}</div> : null}
    </div>
  );
};
