import { Skeleton } from "shared/components/common/Skeleton";
import { Panel } from "shared/components/hoc/Panels";

export const PortfolioCardSkel: React.FC = () => {
  return (
    <Panel>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div className="h-[40px] w-[40px] animate-pulse rounded-full bg-sb-gray-200" />
          <div>
            <Skeleton className="my-1 h-8 w-40 bg-sb-gray-200" />
            <Skeleton className="my-1 h-4 w-20 bg-sb-gray-200" />
          </div>
        </div>
      </div>
    </Panel>
  );
};
