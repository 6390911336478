import { createAsyncThunk } from "@reduxjs/toolkit";
import { TopPerformancesService } from "shared/services/top_performances/TopPerformancesService";

const fetchTopClients = createAsyncThunk(
  "[TOP_PERFORMANCES] Fetch top clients",
  async () => {
    const clients = await TopPerformancesService.fetchTopClients();
    return clients;
  },
);

const fetchTopPortfolios = createAsyncThunk(
  "[TOP_PERFORMANCES] Fetch top portfolios",
  async () => {
    const portfolios = await TopPerformancesService.fetchTopPortfolios();
    return portfolios;
  },
);

export const TopPerformancesThunks = {
  fetchTopPortfolios,
  fetchTopClients,
};
