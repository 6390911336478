import { useTranslation } from "react-i18next";
import { ValueSelector } from "./ValueSelector";

type Props = {
  onChange?: (values: string[]) => void;
};

export const Geography: React.FC<Props> = ({ onChange }) => {
  const { t } = useTranslation();

  const preDefinedGeographies = [
    "europe",
    "usa",
    "north_america",
    "asia",
    "emerging_market",
  ];

  return (
    <ValueSelector
      title={t("proposals.geography")}
      translationKey="geography_answers"
      preDefinedValues={preDefinedGeographies}
      onChange={onChange}
      custom
    />
  );
};
