import { AssetWrapper } from "shared/models/asset/AssetModel";
import {
  Side,
  TradingOrderType,
} from "shared/models/trading/TradingOrderModel";
import { TradingPosition } from "shared/models/trading/TradingPositionmodel";

interface CreateOrderRequirements {
  allowNotional: boolean;
}

interface Props {
  side: Side;
  type: TradingOrderType;
  asset?: AssetWrapper;
  position?: TradingPosition;
}

export const useCreateOrderRequirements = ({
  side,
  type,
  position,
  asset,
}: Props): CreateOrderRequirements => {
  const checkAllowNotional = () => {
    if (type !== "market") return false;

    // It's market order only conditions:
    if (position) return true; // closing / reducing position
    if (side === "sell") return false; // not allowed when shorting
    if (!asset?.alpaca_details?.fractionable) return false; // stock is not fractionable

    return true;
  };

  const allowNotional = checkAllowNotional();

  return { allowNotional };
};
