import { t } from "i18next";
import { CurrencyFormat } from "shared/utils/currency";

type Props = {
  qtyType: "Shares" | "USD";
  estimatedValue?: number;
  estimatedShares?: number;
};

export const EstimatedValue: React.FC<Props> = ({
  qtyType,
  estimatedShares,
  estimatedValue,
}) => {
  return (
    <div>
      {qtyType === "Shares" ? (
        <div className="flex items-baseline gap-4">
          <div>{t("trade.estPrice")}</div>
          <div className="text-2xl font-medium">
            {CurrencyFormat.format(estimatedValue || 0)}
          </div>
        </div>
      ) : (
        <div className="flex items-baseline gap-4">
          <div>{t("trade.estShares")}</div>
          <div className="text-2xl font-medium">
            {estimatedShares?.toFixed(2)}
          </div>
        </div>
      )}
    </div>
  );
};
