type SummaryRowProps = {
  title: string;
  value: string;
};

export const OrderSummaryRow: React.FC<SummaryRowProps> = ({
  title,
  value,
}) => {
  return (
    <div className="flex justify-between gap-2 pt-4 text-lg">
      <div>{title}</div>
      <div className="text-sb-gray-500">{value}</div>
    </div>
  );
};
