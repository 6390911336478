import { TaxIdType } from "./TaxIdType";

export interface TaxType {
  t: string;
  code: TaxIdType;
  countryCode?: ISOCountryCode;
}

export const AvailableTaxTypes: TaxType[] = [
  { code: "NOT_SPECIFIED", t: "tax_codes.NOT_SPECIFIED" },
  { code: "USA_SSN", t: "tax_codes.USA_SSN", countryCode: "USA" },
  { code: "ARG_AR_CUIT", t: "tax_codes.ARG_AR_CUIT", countryCode: "ARG" },
  { code: "AUS_TFN", t: "tax_codes.AUS_TFN", countryCode: "AUS" },
  { code: "AUS_ABN", t: "tax_codes.AUS_ABN", countryCode: "AUS" },
  { code: "BOL_NIT", t: "tax_codes.BOL_NIT", countryCode: "BOL" },
  { code: "BRA_CPF", t: "tax_codes.BRA_CPF", countryCode: "BRA" },
  { code: "CHL_RUT", t: "tax_codes.CHL_RUT", countryCode: "CHL" },
  { code: "COL_NIT", t: "tax_codes.COL_NIT", countryCode: "COL" },
  { code: "CRI_NITE", t: "tax_codes.CRI_NITE", countryCode: "CRI" },
  { code: "DEU_TAX_ID", t: "tax_codes.DEU_TAX_ID", countryCode: "DEU" },
  { code: "DOM_RNC", t: "tax_codes.DOM_RNC", countryCode: "DOM" },
  { code: "ECU_RUC", t: "tax_codes.ECU_RUC", countryCode: "ECU" },
  { code: "ESP_TAX_ID", t: "tax_codes.ESP_TAX_ID", countryCode: "ESP" },
  { code: "FRA_SPI", t: "tax_codes.FRA_SPI", countryCode: "FRA" },
  { code: "GBR_UTR", t: "tax_codes.GBR_UTR", countryCode: "GBR" },
  { code: "GBR_NINO", t: "tax_codes.GBR_NINO", countryCode: "GBR" },
  { code: "GTM_NIT", t: "tax_codes.GTM_NIT", countryCode: "GTM" },
  { code: "HND_RTN", t: "tax_codes.HND_RTN", countryCode: "HND" },
  { code: "HUN_TIN", t: "tax_codes.HUN_TIN", countryCode: "HUN" },
  { code: "IND_PAN", t: "tax_codes.IND_PAN", countryCode: "IND" },
  { code: "ISR_TAX_ID", t: "tax_codes.ISR_TAX_ID", countryCode: "ISR" },
  { code: "ITA_TAX_ID", t: "tax_codes.ITA_TAX_ID", countryCode: "ITA" },
  { code: "JPN_TAX_ID", t: "tax_codes.JPN_TAX_ID", countryCode: "JPN" },
  { code: "MEX_RFC", t: "tax_codes.MEX_RFC", countryCode: "MEX" },
  { code: "NIC_RUC", t: "tax_codes.NIC_RUC", countryCode: "NIC" },
  { code: "NLD_TIN", t: "tax_codes.NLD_TIN", countryCode: "NLD" },
  { code: "PAN_RUC", t: "tax_codes.PAN_RUC", countryCode: "PAN" },
  { code: "POL_TAX_ID", t: "tax_codes.POL_TAX_ID", countryCode: "POL" },
  { code: "PRT_TAX_ID", t: "tax_codes.PRT_TAX_ID", countryCode: "PRT" },
  { code: "PER_RUC", t: "tax_codes.PER_RUC", countryCode: "PER" },
  { code: "PRY_RUC", t: "tax_codes.PRY_RUC", countryCode: "PRY" },
  { code: "SGP_NRIC", t: "tax_codes.SGP_NRIC", countryCode: "SGP" },
  { code: "SGP_FIN", t: "tax_codes.SGP_FIN", countryCode: "SGP" },
  { code: "SGP_ASGD", t: "tax_codes.SGP_ASGD", countryCode: "SGP" },
  { code: "SGP_ITR", t: "tax_codes.SGP_ITR", countryCode: "SGP" },
  { code: "SLV_NIT", t: "tax_codes.SLV_NIT", countryCode: "SLV" },
  { code: "SWE_TAX_ID", t: "tax_codes.SWE_TAX_ID", countryCode: "SWE" },
  { code: "URY_RUT", t: "tax_codes.URY_RUT", countryCode: "URY" },
  { code: "VEN_RIF", t: "tax_codes.VEN_RIF", countryCode: "VEN" },
];
const getTaxTypeByCode = (
  code: ISOCountryCode | undefined,
): TaxType[] => {
  if (!code) return [{ t: "tax_codes.NOT_SPECIFIED", code: "NOT_SPECIFIED" }];
  const result = AvailableTaxTypes.filter((c) => c.countryCode === code);
  return result.length > 0
    ? result
    : [{ t: "tax_codes.NOT_SPECIFIED", code: "NOT_SPECIFIED" }];
};

const notLegallyRequiredCountries: ISOCountryCode[] = [
  "AUS",
  "BMU",
  "VGB",
  "JPN",
];

type CountriesType = { t: string; code: ISOCountryCode };
export const AllCountries: CountriesType[] = [
  { code: "USA", t: "countries.USA" },
  { code: "AFG", t: "countries.AFG" },
  { code: "ALB", t: "countries.ALB" },
  { code: "DZA", t: "countries.DZA" },
  { code: "ASM", t: "countries.ASM" },
  { code: "AND", t: "countries.AND" },
  { code: "AGO", t: "countries.AGO" },
  { code: "AIA", t: "countries.AIA" },
  { code: "ATG", t: "countries.ATG" },
  { code: "ARG", t: "countries.ARG" },
  { code: "ARM", t: "countries.ARM" },
  { code: "ABW", t: "countries.ABW" },
  { code: "AUS", t: "countries.AUS" },
  { code: "AUT", t: "countries.AUT" },
  { code: "AZE", t: "countries.AZE" },
  { code: "BHS", t: "countries.BHS" },
  { code: "BHR", t: "countries.BHR" },
  { code: "BGD", t: "countries.BGD" },
  { code: "BRB", t: "countries.BRB" },
  { code: "BLR", t: "countries.BLR" },
  { code: "BEL", t: "countries.BEL" },
  { code: "BLZ", t: "countries.BLZ" },
  { code: "BEN", t: "countries.BEN" },
  { code: "BMU", t: "countries.BMU" },
  { code: "BTN", t: "countries.BTN" },
  { code: "BOL", t: "countries.BOL" },
  { code: "BIH", t: "countries.BIH" },
  { code: "BWA", t: "countries.BWA" },
  { code: "BRA", t: "countries.BRA" },
  { code: "VGB", t: "countries.VGB" },
  { code: "BRN", t: "countries.BRN" },
  { code: "BGR", t: "countries.BGR" },
  { code: "BFA", t: "countries.BFA" },
  { code: "BDI", t: "countries.BDI" },
  { code: "KHM", t: "countries.KHM" },
  { code: "CMR", t: "countries.CMR" },
  { code: "CAN", t: "countries.CAN" },
  { code: "CPV", t: "countries.CPV" },
  { code: "CAF", t: "countries.CAF" },
  { code: "TCD", t: "countries.TCD" },
  { code: "CHL", t: "countries.CHL" },
  { code: "CHN", t: "countries.CHN" },
  { code: "HKG", t: "countries.HKG" },
  { code: "MAC", t: "countries.MAC" },
  { code: "COL", t: "countries.COL" },
  { code: "COM", t: "countries.COM" },
  { code: "COG", t: "countries.COG" },
  { code: "CRI", t: "countries.CRI" },
  { code: "CIV", t: "countries.CIV" },
  { code: "HRV", t: "countries.HRV" },
  { code: "CUB", t: "countries.CUB" },
  { code: "CYP", t: "countries.CYP" },
  { code: "CZE", t: "countries.CZE" },
  { code: "DNK", t: "countries.DNK" },
  { code: "DJI", t: "countries.DJI" },
  { code: "DMA", t: "countries.DMA" },
  { code: "DOM", t: "countries.DOM" },
  { code: "ECU", t: "countries.ECU" },
  { code: "EGY", t: "countries.EGY" },
  { code: "SLV", t: "countries.SLV" },
  { code: "GNQ", t: "countries.GNQ" },
  { code: "ERI", t: "countries.ERI" },
  { code: "EST", t: "countries.EST" },
  { code: "ETH", t: "countries.ETH" },
  { code: "FRO", t: "countries.FRO" },
  { code: "FJI", t: "countries.FJI" },
  { code: "FIN", t: "countries.FIN" },
  { code: "FRA", t: "countries.FRA" },
  { code: "GUF", t: "countries.GUF" },
  { code: "PYF", t: "countries.PYF" },
  { code: "GAB", t: "countries.GAB" },
  { code: "GMB", t: "countries.GMB" },
  { code: "GEO", t: "countries.GEO" },
  { code: "DEU", t: "countries.DEU" },
  { code: "GHA", t: "countries.GHA" },
  { code: "GRC", t: "countries.GRC" },
  { code: "GRL", t: "countries.GRL" },
  { code: "GRD", t: "countries.GRD" },
  { code: "GLP", t: "countries.GLP" },
  { code: "GUM", t: "countries.GUM" },
  { code: "GTM", t: "countries.GTM" },
  { code: "GNB", t: "countries.GNB" },
  { code: "HTI", t: "countries.HTI" },
  { code: "HND", t: "countries.HND" },
  { code: "HUN", t: "countries.HUN" },
  { code: "ISL", t: "countries.ISL" },
  { code: "IDN", t: "countries.IDN" },
  { code: "IRQ", t: "countries.IRQ" },
  { code: "IRL", t: "countries.IRL" },
  { code: "ITA", t: "countries.ITA" },
  { code: "ISR", t: "countries.ISR" },
  { code: "IND", t: "countries.IND" },
  { code: "JPN", t: "countries.JPN" },
  { code: "JOR", t: "countries.JOR" },
  { code: "KAZ", t: "countries.KAZ" },
  { code: "KEN", t: "countries.KEN" },
  { code: "KIR", t: "countries.KIR" },
  { code: "PRK", t: "countries.PRK" },
  { code: "KOR", t: "countries.KOR" },
  { code: "KWT", t: "countries.KWT" },
  { code: "KGZ", t: "countries.KGZ" },
  { code: "LAO", t: "countries.LAO" },
  { code: "LVA", t: "countries.LVA" },
  { code: "LBN", t: "countries.LBN" },
  { code: "LSO", t: "countries.LSO" },
  { code: "LBR", t: "countries.LBR" },
  { code: "LBY", t: "countries.LBY" },
  { code: "LIE", t: "countries.LIE" },
  { code: "LTU", t: "countries.LTU" },
  { code: "LUX", t: "countries.LUX" },
  { code: "MDG", t: "countries.MDG" },
  { code: "MWI", t: "countries.MWI" },
  { code: "MYS", t: "countries.MYS" },
  { code: "MDV", t: "countries.MDV" },
  { code: "MLI", t: "countries.MLI" },
  { code: "MLT", t: "countries.MLT" },
  { code: "MHL", t: "countries.MHL" },
  { code: "MTQ", t: "countries.MTQ" },
  { code: "MRT", t: "countries.MRT" },
  { code: "MUS", t: "countries.MUS" },
  { code: "MEX", t: "countries.MEX" },
  { code: "FSM", t: "countries.FSM" },
  { code: "MDA", t: "countries.MDA" },
  { code: "MCO", t: "countries.MCO" },
  { code: "MNG", t: "countries.MNG" },
  { code: "MNE", t: "countries.MNE" },
  { code: "MSR", t: "countries.MSR" },
  { code: "MAR", t: "countries.MAR" },
  { code: "MOZ", t: "countries.MOZ" },
  { code: "MMR", t: "countries.MMR" },
  { code: "NAM", t: "countries.NAM" },
  { code: "NRU", t: "countries.NRU" },
  { code: "NPL", t: "countries.NPL" },
  { code: "NLD", t: "countries.NLD" },
  { code: "ANT", t: "countries.ANT" },
  { code: "NCL", t: "countries.NCL" },
  { code: "NZL", t: "countries.NZL" },
  { code: "NIC", t: "countries.NIC" },
  { code: "NER", t: "countries.NER" },
  { code: "NGA", t: "countries.NGA" },
  { code: "MNP", t: "countries.MNP" },
  { code: "NOR", t: "countries.NOR" },
  { code: "OMN", t: "countries.OMN" },
  { code: "PAK", t: "countries.PAK" },
  { code: "PLW", t: "countries.PLW" },
  { code: "PSE", t: "countries.PSE" },
  { code: "PAN", t: "countries.PAN" },
  { code: "PNG", t: "countries.PNG" },
  { code: "PRY", t: "countries.PRY" },
  { code: "PER", t: "countries.PER" },
  { code: "PHL", t: "countries.PHL" },
  { code: "PCN", t: "countries.PCN" },
  { code: "POL", t: "countries.POL" },
  { code: "PRT", t: "countries.PRT" },
  { code: "PRI", t: "countries.PRI" },
  { code: "QAT", t: "countries.QAT" },
  { code: "REU", t: "countries.REU" },
  { code: "ROU", t: "countries.ROU" },
  { code: "RUS", t: "countries.RUS" },
  { code: "RWA", t: "countries.RWA" },
  { code: "KNA", t: "countries.KNA" },
  { code: "LCA", t: "countries.LCA" },
  { code: "VCT", t: "countries.VCT" },
  { code: "WSM", t: "countries.WSM" },
  { code: "SMR", t: "countries.SMR" },
  { code: "STP", t: "countries.STP" },
  { code: "SAU", t: "countries.SAU" },
  { code: "SEN", t: "countries.SEN" },
  { code: "SRB", t: "countries.SRB" },
  { code: "SYC", t: "countries.SYC" },
  { code: "SLE", t: "countries.SLE" },
  { code: "SGP", t: "countries.SGP" },
  { code: "SVK", t: "countries.SVK" },
  { code: "SVN", t: "countries.SVN" },
  { code: "SLB", t: "countries.SLB" },
  { code: "SOM", t: "countries.SOM" },
  { code: "ZAF", t: "countries.ZAF" },
  { code: "ESP", t: "countries.ESP" },
  { code: "LKA", t: "countries.LKA" },
  { code: "SDN", t: "countries.SDN" },
  { code: "SUR", t: "countries.SUR" },
  { code: "SWZ", t: "countries.SWZ" },
  { code: "SWE", t: "countries.SWE" },
  { code: "CHE", t: "countries.CHE" },
  { code: "SYR", t: "countries.SYR" },
  { code: "TJK", t: "countries.TJK" },
  { code: "TWN", t: "countries.TWN" },
  { code: "TZA", t: "countries.TZA" },
  { code: "THA", t: "countries.THA" },
  { code: "TLS", t: "countries.TLS" },
  { code: "TGO", t: "countries.TGO" },
  { code: "TON", t: "countries.TON" },
  { code: "TTO", t: "countries.TTO" },
  { code: "TUN", t: "countries.TUN" },
  { code: "TUR", t: "countries.TUR" },
  { code: "TKM", t: "countries.TKM" },
  { code: "TUV", t: "countries.TUV" },
  { code: "UGA", t: "countries.UGA" },
  { code: "UKR", t: "countries.UKR" },
  { code: "ARE", t: "countries.ARE" },
  { code: "GBR", t: "countries.GBR" },
  { code: "URY", t: "countries.URY" },
  { code: "UZB", t: "countries.UZB" },
  { code: "VUT", t: "countries.VUT" },
  { code: "VAT", t: "countries.VAT" },
  { code: "VEN", t: "countries.VEN" },
  { code: "VNM", t: "countries.VNM" },
  { code: "VIR", t: "countries.VIR" },
  { code: "YEM", t: "countries.YEM" },
  { code: "ZMB", t: "countries.ZMB" },
  { code: "ZWE", t: "countries.ZWE" },
];

export type ISOCountryCode =
  | "AFG"
  | "ALB"
  | "DZA"
  | "ASM"
  | "AND"
  | "AGO"
  | "AIA"
  | "ATG"
  | "ARG"
  | "ARM"
  | "ABW"
  | "AUS"
  | "AUT"
  | "AZE"
  | "BHS"
  | "BHR"
  | "BGD"
  | "BRB"
  | "BLR"
  | "BEL"
  | "BLZ"
  | "BEN"
  | "BMU"
  | "BTN"
  | "BOL"
  | "BIH"
  | "BWA"
  | "BRA"
  | "VGB"
  | "BRN"
  | "BGR"
  | "BFA"
  | "BDI"
  | "KHM"
  | "CMR"
  | "CAN"
  | "CPV"
  | "CAF"
  | "TCD"
  | "CHL"
  | "CHN"
  | "HKG"
  | "MAC"
  | "COL"
  | "COM"
  | "COG"
  | "CRI"
  | "CIV"
  | "HRV"
  | "CUB"
  | "CYP"
  | "CZE"
  | "DNK"
  | "DJI"
  | "DMA"
  | "DOM"
  | "ECU"
  | "EGY"
  | "SLV"
  | "GNQ"
  | "ERI"
  | "EST"
  | "ETH"
  | "FRO"
  | "FJI"
  | "FIN"
  | "FRA"
  | "GUF"
  | "PYF"
  | "GAB"
  | "GMB"
  | "GEO"
  | "DEU"
  | "GHA"
  | "GRC"
  | "GRL"
  | "GRD"
  | "GLP"
  | "GUM"
  | "GTM"
  | "GNB"
  | "HTI"
  | "HND"
  | "HUN"
  | "ISL"
  | "IDN"
  | "IND"
  | "IRQ"
  | "IRL"
  | "ISR"
  | "ITA"
  | "JPN"
  | "JOR"
  | "KAZ"
  | "KEN"
  | "KIR"
  | "PRK"
  | "KOR"
  | "KWT"
  | "KGZ"
  | "LAO"
  | "LVA"
  | "LBN"
  | "LSO"
  | "LBR"
  | "LBY"
  | "LIE"
  | "LTU"
  | "LUX"
  | "MDG"
  | "MWI"
  | "MYS"
  | "MDV"
  | "MLI"
  | "MLT"
  | "MHL"
  | "MTQ"
  | "MRT"
  | "MUS"
  | "MEX"
  | "FSM"
  | "MDA"
  | "MCO"
  | "MNG"
  | "MNE"
  | "MSR"
  | "MAR"
  | "MOZ"
  | "MMR"
  | "NAM"
  | "NRU"
  | "NPL"
  | "NLD"
  | "ANT"
  | "NCL"
  | "NZL"
  | "NIC"
  | "NER"
  | "NGA"
  | "MNP"
  | "NOR"
  | "OMN"
  | "PAK"
  | "PLW"
  | "PSE"
  | "PAN"
  | "PNG"
  | "PRY"
  | "PER"
  | "PHL"
  | "PCN"
  | "POL"
  | "PRT"
  | "PRI"
  | "QAT"
  | "REU"
  | "ROU"
  | "RUS"
  | "RWA"
  | "KNA"
  | "LCA"
  | "VCT"
  | "WSM"
  | "SMR"
  | "STP"
  | "SAU"
  | "SEN"
  | "SRB"
  | "SYC"
  | "SLE"
  | "SGP"
  | "SVK"
  | "SVN"
  | "SLB"
  | "SOM"
  | "ZAF"
  | "ESP"
  | "LKA"
  | "SDN"
  | "SUR"
  | "SWZ"
  | "SWE"
  | "CHE"
  | "SYR"
  | "TJK"
  | "TZA"
  | "THA"
  | "TLS"
  | "TGO"
  | "TON"
  | "TTO"
  | "TUN"
  | "TUR"
  | "TKM"
  | "TUV"
  | "TWN"
  | "UGA"
  | "UKR"
  | "ARE"
  | "GBR"
  | "USA"
  | "URY"
  | "UZB"
  | "VUT"
  | "VEN"
  | "VAT"
  | "VNM"
  | "VIR"
  | "YEM"
  | "ZMB"
  | "ZWE";

export const CountryNames: Record<ISOCountryCode, string> = {
  ABW: "Aruba",
  AFG: "Afghanistan",
  AGO: "Angola",
  AIA: "Anguilla",
  ALB: "Albania",
  AND: "Andorra",
  ANT: "Netherlands Antilles",
  ARE: "United Arab Emirates",
  ARG: "Argentina",
  ARM: "Armenia",
  ASM: "American Samoa",
  ATG: "Antigua and Barbuda",
  AUS: "Australia",
  AUT: "Austria",
  AZE: "Azerbaijan",
  BDI: "Burundi",
  BEL: "Belgium",
  BEN: "Benin",
  BFA: "Burkina Faso",
  BGD: "Bangladesh",
  BGR: "Bulgaria",
  BHR: "Bahrain",
  BHS: "Bahamas",
  BIH: "Bosnia and Herzegovina",
  BLR: "Belarus",
  BLZ: "Belize",
  BMU: "Bermuda",
  BOL: "Bolivia",
  BRA: "Brazil",
  BRB: "Barbados",
  BRN: "Brunei Darussalam",
  BTN: "Bhutan",
  BWA: "Botswana",
  CAF: "Central African Republic",
  CAN: "Canada",
  CHE: "Switzerland",
  CHL: "Chile",
  CHN: "China",
  CIV: "Côte d'Ivoire",
  CMR: "Cameroon",
  COG: "Congo",
  COL: "Colombia",
  COM: "Comoros",
  CPV: "Cape Verde",
  CRI: "Costa Rica",
  CUB: "Cuba",
  CYP: "Cyprus",
  CZE: "Czech Republic",
  DEU: "Germany",
  DJI: "Djibouti",
  DMA: "Dominica",
  DNK: "Denmark",
  DOM: "Dominican Republic",
  DZA: "Algeria",
  ECU: "Ecuador",
  EGY: "Egypt",
  ERI: "Eritrea",
  ESP: "Spain",
  EST: "Estonia",
  ETH: "Ethiopia",
  FIN: "Finland",
  FJI: "Fiji",
  FRA: "France",
  FRO: "Faroe Islands",
  FSM: "Micronesia, Federated States of",
  GAB: "Gabon",
  GBR: "United Kingdom",
  GEO: "Georgia",
  GHA: "Ghana",
  GLP: "Guadeloupe",
  GMB: "Gambia",
  GNB: "Guinea-Bissau",
  GNQ: "Equatorial Guinea",
  GRC: "Greece",
  GRD: "Grenada",
  GRL: "Greenland",
  GTM: "Guatemala",
  GUF: "French Guiana",
  GUM: "Guam",
  HKG: "Hong Kong",
  HND: "Honduras",
  HRV: "Croatia",
  HTI: "Haiti",
  HUN: "Hungary",
  IDN: "Indonesia",
  IND: "India",
  IRL: "Ireland",
  IRQ: "Iraq",
  ISL: "Iceland",
  ISR: "Israel",
  ITA: "Italy",
  JOR: "Jordan",
  JPN: "Japan",
  KAZ: "Kazakhstan",
  KEN: "Kenya",
  KGZ: "Kyrgyzstan",
  KHM: "Cambodia",
  KIR: "Kiribati",
  KNA: "Saint Kitts and Nevis",
  KOR: "Korea",
  KWT: "Kuwait",
  LAO: "Lao PDR",
  LBN: "Lebanon",
  LBR: "Liberia",
  LBY: "Libya",
  LCA: "Saint Lucia",
  LIE: "Liechtenstein",
  LKA: "Sri Lanka",
  LSO: "Lesotho",
  LTU: "Lithuania",
  LUX: "Luxembourg",
  LVA: "Latvia",
  MAC: "Macao",
  MAR: "Morocco",
  MCO: "Monaco",
  MDA: "Moldova",
  MDG: "Madagascar",
  MDV: "Maldives",
  MEX: "Mexico",
  MHL: "Marshall Islands",
  MLI: "Mali",
  MLT: "Malta",
  MMR: "Myanmar",
  MNE: "Montenegro",
  MNG: "Mongolia",
  MNP: "Northern Mariana Islands",
  MOZ: "Mozambique",
  MRT: "Mauritania",
  MSR: "Montserrat",
  MTQ: "Martinique",
  MUS: "Mauritius",
  MWI: "Malawi",
  MYS: "Malaysia",
  NAM: "Namibia",
  NCL: "New Caledonia",
  NER: "Niger",
  NGA: "Nigeria",
  NIC: "Nicaragua",
  NLD: "Netherlands",
  NOR: "Norway",
  NPL: "Nepal",
  NRU: "Nauru",
  NZL: "New Zealand",
  OMN: "Oman",
  PAK: "Pakistan",
  PAN: "Panama",
  PCN: "Pitcairn",
  PER: "Peru",
  PHL: "Philippines",
  PLW: "Palau",
  PNG: "Papua New Guinea",
  POL: "Poland",
  PRI: "Puerto Rico",
  PRK: "Korea",
  PRT: "Portugal",
  PRY: "Paraguay",
  PSE: "Palestinian Territory",
  PYF: "French Polynesia",
  QAT: "Qatar",
  REU: "Réunion",
  ROU: "Romania",
  RUS: "Russian Federation",
  RWA: "Rwanda",
  SAU: "Saudi Arabia",
  SDN: "Sudan",
  SEN: "Senegal",
  SGP: "Singapore",
  SLB: "Solomon Islands",
  SLE: "Sierra Leone",
  SLV: "El Salvador",
  SMR: "San Marino",
  SOM: "Somalia",
  SRB: "Serbia",
  STP: "Sao Tome and Principe",
  SUR: "Suriname",
  SVK: "Slovakia",
  SVN: "Slovenia",
  SWE: "Sweden",
  SWZ: "Swaziland",
  SYC: "Seychelles",
  SYR: "Syrian Arab Republic",
  TCD: "Chad",
  TGO: "Togo",
  THA: "Thailand",
  TJK: "Tajikistan",
  TKM: "Turkmenistan",
  TLS: "Timor-Leste",
  TON: "Tonga",
  TTO: "Trinidad and Tobago",
  TUN: "Tunisia",
  TUR: "Turkey",
  TUV: "Tuvalu",
  TWN: "Taiwan",
  TZA: "Tanzania",
  UGA: "Uganda",
  UKR: "Ukraine",
  URY: "Uruguay",
  USA: "United States of America",
  UZB: "Uzbekistan",
  VAT: "Vatican",
  VCT: "Saint Vincent and Grenadines",
  VEN: "Venezuela",
  VGB: "British Virgin Islands",
  VIR: "Virgin Islands, US",
  VNM: "Vietnam",
  VUT: "Vanuatu",
  WSM: "Samoa",
  YEM: "Yemen",
  ZAF: "South Africa",
  ZMB: "Zambia",
  ZWE: "Zimbabwe",
};
