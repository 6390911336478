export const BmoLogo: React.FC = () => {
  return (
    <>
      <svg
        width="156"
        height="27"
        viewBox="0 0 156 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_2322_9984"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="156"
          height="27"
        >
          <path
            d="M0.292969 26.4458H155.705V0.031826H0.292969V26.4458Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_2322_9984)">
          <path
            d="M7.11377 16.3062C7.11377 17.7943 6.61306 18.5063 4.87078 18.5063H3.78538V14.3382H4.9422C6.52516 14.3382 7.11377 14.8955 7.11377 16.3062ZM9.4478 16.461C9.4478 14.7282 8.03043 14.1053 6.30934 14.0662V14.0014C7.77223 13.8857 8.97456 13.2722 8.97456 11.7981C8.97456 10.1615 7.61448 9.85513 6.0943 9.85513C4.2649 9.85513 2.11766 9.90515 0.292969 9.85513V10.3131C0.681451 10.2873 1.61538 10.3389 1.61538 10.8212V18.0147C1.61538 18.4805 0.653197 18.5587 0.292969 18.5587V19.0034C2.06037 18.9503 4.15032 19.0034 5.92086 19.0034C7.78478 19.0034 9.4478 18.3625 9.4478 16.461ZM6.74334 12.0724C6.74334 13.2198 6.23792 13.8591 4.91316 13.8591H3.78538V10.3389C3.929 10.3256 4.40931 10.3131 4.55293 10.3131H4.9422C6.29443 10.3131 6.74334 10.9126 6.74334 12.0724Z"
            fill="white"
          />
          <path
            d="M21.2439 18.9889C20.4253 18.9889 19.6067 18.9772 18.789 19.003V18.545C19.1625 18.545 20.1938 18.52 20.1938 18.0135V11.3123H20.1655L16.4055 19.1069H16.0178L12.359 11.3123H12.3308V18.0135C12.3308 18.534 13.4075 18.545 13.7944 18.545L13.8093 19.003C12.7891 18.9616 11.3945 18.9366 10.3883 19.003V18.545C10.7776 18.545 11.7107 18.5075 11.7107 18.0135V10.8317C11.7107 10.351 10.7486 10.3127 10.3883 10.3127L10.375 9.85548C11.5389 9.9055 12.7891 9.9055 13.9522 9.85548L17.0812 16.3894L20.266 9.86876C21.3883 9.91722 22.5945 9.9055 23.7145 9.85548V10.3127C23.3409 10.3127 22.3654 10.3393 22.3654 10.8317V18.0135C22.3654 18.52 23.3268 18.545 23.7145 18.545V19.003C22.8951 18.9772 22.0632 18.9889 21.2439 18.9889Z"
            fill="white"
          />
          <path
            d="M34.7676 14.4122C34.7676 11.3899 32.9131 9.69544 29.569 9.69544C26.2241 9.69544 24.3555 11.3766 24.3555 14.4122C24.3555 17.4486 26.2084 19.1431 29.569 19.1431C32.9264 19.1431 34.7676 17.4486 34.7676 14.4122ZM32.3386 14.4122C32.3386 16.1989 32.1651 18.6858 29.569 18.6858C26.9712 18.6858 26.7845 16.1989 26.7845 14.4122C26.7845 12.6287 26.9712 10.1527 29.569 10.1527C32.151 10.1527 32.3386 12.6404 32.3386 14.4122Z"
            fill="white"
          />
          <path
            d="M53.6485 0.0323143C46.3277 0.0323143 40.3867 5.94724 40.3867 13.2401C40.3867 20.5345 46.3277 26.4463 53.6485 26.4463C60.9763 26.4463 66.9142 20.5345 66.9142 13.2401C66.9142 5.94724 60.9763 0.0323143 53.6485 0.0323143Z"
            fill="#ED1D24"
          />
          <path
            d="M53.2477 9.20877L50.945 6.21926C50.8516 6.09656 50.7033 6.01684 50.5361 6.01684C50.3745 6.01684 50.2301 6.09187 50.1359 6.2091L47.3945 9.64958V15.0409L50.1202 11.5621C50.2167 11.4394 50.3674 11.3604 50.5361 11.3604C50.7072 11.3604 50.8595 11.4417 50.9568 11.5668L53.2704 14.5797C53.3575 14.6954 53.4964 14.7697 53.6526 14.7697C53.8088 14.7697 53.9477 14.6954 54.0356 14.5797L56.3492 11.5668C56.4466 11.4417 56.598 11.3604 56.7691 11.3604C56.9386 11.3604 57.0885 11.4394 57.1851 11.5621L59.9107 15.0409V9.64958L57.1702 6.2091C57.076 6.09187 56.9316 6.01684 56.7691 6.01684C56.6019 6.01684 56.4544 6.09656 56.3602 6.21926L54.0576 9.20877C53.9642 9.32913 53.8174 9.40651 53.6526 9.40651C53.4878 9.40651 53.3411 9.32913 53.2477 9.20877ZM47.3945 15.0409H59.9107V18.9956H47.3945V15.0409Z"
            fill="white"
          />
          <path
            d="M78.961 19.0083V18.5706C79.3275 18.5706 80.3234 18.5706 80.3234 18.0798V14.4041H75.8099V18.0798C75.8099 18.5706 76.8067 18.5706 77.1724 18.5706V19.0083C75.722 18.9552 74.0363 18.9552 72.5859 19.0083V18.5706C72.9517 18.5706 73.9484 18.5706 73.9484 18.0798V10.583C73.9484 10.0921 72.9367 10.0921 72.5859 10.0921V9.65368C74.0221 9.70682 75.737 9.70682 77.1724 9.65368V10.0921C76.8067 10.0921 75.8099 10.0921 75.8099 10.583V13.9265H80.3234V10.583C80.3234 10.1046 79.3126 10.0921 78.961 10.0921V9.65368C80.3972 9.70682 82.112 9.70682 83.5482 9.65368V10.0921C83.1817 10.0921 82.1701 10.0921 82.1701 10.583V18.0798C82.1701 18.5706 83.1817 18.5706 83.5482 18.5706V19.0083C82.0971 18.9552 80.4121 18.9552 78.961 19.0083Z"
            fill="white"
          />
          <path
            d="M88.6602 15.4923C87.2679 15.611 86.1394 15.7041 86.1394 17.2703C86.1394 17.9065 86.3591 18.5435 87.1942 18.5435C88.3525 18.5435 88.6602 17.5884 88.6602 16.7123V15.4923ZM89.7448 19.0343C89.2025 19.0343 88.6743 18.9554 88.6743 18.3583C88.03 18.9155 87.3849 19.1406 86.4902 19.1406C85.0697 19.1406 84.3516 18.4904 84.3516 17.2039C84.3516 15.0139 87.0333 15.1609 88.6602 15.0538V14.0456C88.6602 13.3289 88.2937 12.931 87.4728 12.931C86.5349 12.931 86.2571 13.3554 86.2571 14.1386C86.2571 14.4832 85.9636 14.5497 85.6559 14.5497C85.1866 14.563 84.8491 14.5364 84.8491 14.0057C84.8491 12.7583 86.301 12.4934 87.3998 12.4934C89.1138 12.4934 90.3452 12.7185 90.3452 14.5098V17.5486C90.3452 18.4505 90.4331 18.5701 91.4455 18.5841V19.0078C90.8726 19.0078 90.3012 19.0343 89.7448 19.0343Z"
            fill="white"
          />
          <path
            d="M97.101 14.5502C95.8115 14.5502 96.8365 13.1434 95.9575 13.1434C95.0055 13.1434 94.7708 14.762 94.7708 15.3857V18.0798C94.7708 18.5839 95.6208 18.5839 96.0022 18.5839V19.0083C94.6531 18.9692 93.2609 18.9552 91.9126 19.0083V18.5839C92.2791 18.5839 93.0709 18.5573 93.0709 18.0798V13.5545C93.0709 13.0777 92.294 13.0512 91.9126 13.0371L91.8984 12.6393C92.8363 12.6393 93.789 12.6526 94.7269 12.6127V13.6217L94.7559 13.6076C95.1075 12.984 95.7087 12.4931 96.5147 12.4931C97.3654 12.4931 97.8779 12.9582 97.8779 13.728C97.8779 14.2321 97.7312 14.5502 97.101 14.5502Z"
            fill="white"
          />
          <path
            d="M103.945 14.5502C102.655 14.5502 103.68 13.1434 102.801 13.1434C101.849 13.1434 101.615 14.762 101.615 15.3857V18.0798C101.615 18.5839 102.465 18.5839 102.846 18.5839V19.0083C101.497 18.9692 100.105 18.9552 98.7563 19.0083V18.5839C99.1228 18.5839 99.9147 18.5573 99.9147 18.0798V13.5545C99.9147 13.0777 99.1377 13.0512 98.7563 13.0371L98.7422 12.6393C99.68 12.6393 100.633 12.6526 101.571 12.6127V13.6217L101.6 13.6076C101.951 12.984 102.552 12.4931 103.358 12.4931C104.209 12.4931 104.722 12.9582 104.722 13.728C104.722 14.2321 104.575 14.5502 103.945 14.5502Z"
            fill="white"
          />
          <path
            d="M107.578 11.3919C106.977 11.3919 106.611 10.9675 106.611 10.4501C106.611 9.9327 106.992 9.52081 107.578 9.52081C108.164 9.52081 108.546 9.94599 108.546 10.4501C108.546 10.9675 108.164 11.3919 107.578 11.3919ZM105.57 19.0083V18.5839C105.937 18.5839 106.728 18.544 106.728 18.0798V13.5279C106.728 13.0902 105.907 13.0777 105.57 13.0637V12.6393C106.509 12.6393 107.49 12.6791 108.429 12.626V18.0798C108.429 18.544 109.235 18.5839 109.6 18.5839V19.0083C108.282 18.9552 106.89 18.9552 105.57 19.0083Z"
            fill="white"
          />
          <path
            d="M113.032 19.1406C112.226 19.1406 111.391 19.0085 110.629 18.7694V16.7928H111.288C111.317 17.8143 111.787 18.7295 113.062 18.7295C113.808 18.7295 114.6 18.4114 114.6 17.6416C114.6 16.0753 110.746 16.8061 110.746 14.5231C110.746 13.0639 111.976 12.4934 113.428 12.4934C114.146 12.4934 114.835 12.6254 115.509 12.8513V14.5231H114.908C114.805 13.5946 114.453 12.931 113.311 12.931C112.607 12.931 112.109 13.196 112.109 13.8736C112.109 15.4258 115.978 14.4973 115.978 17.071C115.978 18.5701 114.453 19.1406 113.032 19.1406Z"
            fill="white"
          />
          <path
            d="M124.928 14.2316H123.669V18.5311H124.606C126.277 18.5311 127.126 17.8534 127.126 16.3145C127.126 14.8811 126.555 14.2316 124.928 14.2316ZM124.826 10.1042C124.445 10.1042 124.048 10.1042 123.669 10.1448V13.7533H124.884C126.218 13.7533 126.672 13.103 126.672 11.9494C126.672 10.7153 126.247 10.1042 124.826 10.1042ZM125.471 19.0078C123.8 19.0078 122.115 18.9547 120.444 19.0078V18.5701C120.78 18.5701 121.806 18.5311 121.806 18.0793V10.5825C121.806 10.1182 120.795 10.0776 120.444 10.0917L120.43 9.65397C122.173 9.70634 123.932 9.65397 125.676 9.65397C127.331 9.65397 128.622 10.0385 128.622 11.7501C128.622 13.1429 127.596 13.7533 126.189 13.926V13.9792C127.933 14.0855 129.135 14.7092 129.135 16.4208C129.135 18.3575 127.331 19.0078 125.471 19.0078Z"
            fill="white"
          />
          <path
            d="M134.969 15.4923C133.577 15.611 132.448 15.7041 132.448 17.2703C132.448 17.9065 132.668 18.5435 133.504 18.5435C134.661 18.5435 134.969 17.5884 134.969 16.7123V15.4923ZM136.053 19.0343C135.512 19.0343 134.984 18.9554 134.984 18.3583C134.339 18.9155 133.693 19.1406 132.8 19.1406C131.378 19.1406 130.66 18.4904 130.66 17.2039C130.66 15.0139 133.343 15.1609 134.969 15.0538V14.0456C134.969 13.3289 134.602 12.931 133.782 12.931C132.844 12.931 132.566 13.3554 132.566 14.1386C132.566 14.4832 132.273 14.5497 131.965 14.5497C131.495 14.563 131.159 14.5364 131.159 14.0057C131.159 12.7583 132.61 12.4934 133.708 12.4934C135.423 12.4934 136.655 12.7185 136.655 14.5098V17.5486C136.655 18.4505 136.742 18.5701 137.754 18.5841V19.0078C137.182 19.0078 136.61 19.0343 136.053 19.0343Z"
            fill="white"
          />
          <path
            d="M142.999 19.0083V18.5972C143.365 18.5839 144.068 18.544 144.068 18.0798V14.6698C144.068 13.8476 143.907 13.1567 142.837 13.1567C141.606 13.1567 141.166 14.1125 141.166 15.0809V18.0798C141.166 18.5042 141.899 18.5839 142.237 18.5839L142.251 18.9958C140.976 18.9419 139.583 18.9552 138.309 19.0083V18.5972C138.69 18.5839 139.466 18.5706 139.466 18.0798V13.5678C139.466 13.0902 138.69 13.0637 138.309 13.0512V12.6393C139.246 12.6393 140.184 12.6526 141.123 12.6127V13.5545H141.152C141.826 12.8253 142.499 12.4931 143.57 12.4931C145.168 12.4931 145.768 13.1965 145.768 14.5893V18.0798C145.768 18.544 146.545 18.5839 146.912 18.5839V19.0083C145.636 18.9692 144.274 18.9552 142.999 19.0083Z"
            fill="white"
          />
          <path
            d="M152.2 18.9953V18.5842C152.317 18.5842 152.728 18.5967 152.684 18.4115C152.64 18.2255 152.332 17.9597 152.17 17.7479C151.761 17.2438 151.364 16.7397 150.94 16.2489C150.925 16.2348 150.749 15.9698 150.749 15.9698L150.163 16.4872V18.1192C150.163 18.6499 150.911 18.5842 151.306 18.5842V19.0078C150.002 18.9554 148.624 18.9554 147.32 19.0078V18.5842C147.671 18.5842 148.462 18.5311 148.462 18.0793V10.2378C148.462 9.76026 147.715 9.73369 147.334 9.71962L147.32 9.30852C148.257 9.30852 149.225 9.33509 150.163 9.28194L150.148 15.8768C150.148 15.8768 152.493 13.3954 152.493 13.157C152.493 13.0507 151.951 13.0241 151.849 13.0241V12.6396H154.751V13.0108H154.574C154.252 13.0108 153.593 13.0241 153.358 13.2234C152.772 13.7135 151.805 14.8686 151.805 14.8686L154.486 18.0527C154.779 18.3974 155.248 18.5842 155.704 18.5967V19.0078C154.545 18.9422 153.343 18.9953 152.2 18.9953Z"
            fill="white"
          />
          <path
            d="M68.247 7.05128C67.5101 7.05128 66.9128 6.44478 66.9128 5.69916C66.9128 4.95198 67.5101 4.34782 68.247 4.34782C68.9839 4.34782 69.582 4.95198 69.582 5.69916C69.582 6.44478 68.9839 7.05128 68.247 7.05128ZM68.247 4.12898C67.3766 4.12898 66.6719 4.83162 66.6719 5.69916C66.6719 6.56514 67.3766 7.26855 68.247 7.26855C69.1166 7.26855 69.8221 6.56514 69.8221 5.69916C69.8221 4.83162 69.1166 4.12898 68.247 4.12898Z"
            fill="white"
          />
          <path
            d="M67.8987 5.61092V4.98645H68.2777C68.5987 4.98645 68.7447 5.0818 68.7447 5.29986C68.7447 5.51792 68.5987 5.61092 68.2777 5.61092H67.8987ZM68.9786 5.30689C68.9786 4.95753 68.7745 4.79809 68.3805 4.79809H67.6797V6.59961H67.8987V5.80084H68.2777L68.7878 6.59961H69.0515L68.5108 5.80084C68.8012 5.75473 68.9786 5.55778 68.9786 5.30689Z"
            fill="white"
          />
        </g>
      </svg>
    </>
  );
};
