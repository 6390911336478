import { createSlice } from "@reduxjs/toolkit";
import {
  AssetChartDataPoint,
  AssetChartQueryParams,
} from "shared/models/asset/AssetModel";
import { RootState } from "shared/store/store";
import { ChartRange } from "shared/utils/types";
import { fetchChartBySymbol } from "./AssetChartThunks";

interface ChartCache {
  [symbol: string]: {
    [key in ChartRange]?: AssetChartDataPoint[];
  };
}

interface AssetChartState {
  params: AssetChartQueryParams;
  cache: ChartCache;
  selectedIndex?: number;
}

const initialState: AssetChartState = {
  params: { range: "date" },
  cache: {},
};

const assetChartSlice = createSlice({
  name: "[ASSET_CHART]",
  initialState,
  reducers: {
    setParams(state, { payload }: { payload: AssetChartQueryParams }) {
      state.params = payload;
    },
    setSelectedIndex(
      state,
      { payload: { index } }: { payload: { index?: number } },
    ) {
      state.selectedIndex = index;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchChartBySymbol.fulfilled, (state, action) => {
      if (!state.cache[action.payload.symbol])
        state.cache[action.payload.symbol] = {};
      state.cache[action.payload.symbol][action.payload.params.range] =
        action.payload.data;
    });
  },
});

const selectAssetChart = (state: RootState) => state.asset.chart;

export const selectAssetChartData =
  (symbol: string, range: ChartRange) => (state: RootState) =>
    state.asset.chart.cache[symbol]
      ? state.asset.chart.cache[symbol][range] || undefined
      : undefined || undefined;

const AssetChartActions = { ...assetChartSlice.actions };

export default assetChartSlice.reducer;
