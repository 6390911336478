import { useTranslation } from "react-i18next";
import { FeeStrategyType } from "../types";

export const useFeeStrategies = (): {
  label: string;
  value: FeeStrategyType;
}[] => {
  const { t } = useTranslation();

  return [
    {
      label: t("feeTemplateForm.feeStrategy_1"),
      value: "feeStrategy_1",
    },
    {
      label: t("feeTemplateForm.feeStrategy_2"),
      value: "feeStrategy_2",
    },
    {
      label: t("feeTemplateForm.feeStrategy_3"),
      value: "feeStrategy_3",
    },
  ];
};
