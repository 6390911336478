type Props = {
  className?: string;
};

export const MagnifyingGlassIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.82296 1.76477C4.41179 1.76477 1.64648 4.53007 1.64648 7.94124C1.64648 11.3524 4.41179 14.1177 7.82296 14.1177C9.23163 14.1177 10.5302 13.6461 11.5693 12.8522L16.7113 17.9942L17.8759 16.8296L12.7339 11.6876C13.5278 10.6485 13.9994 9.34992 13.9994 7.94124C13.9994 4.53007 11.2341 1.76477 7.82296 1.76477ZM3.29354 7.94124C3.29354 5.43972 5.32143 3.41183 7.82296 3.41183C10.3245 3.41183 12.3524 5.43972 12.3524 7.94124C12.3524 10.4428 10.3245 12.4707 7.82296 12.4707C5.32143 12.4707 3.29354 10.4428 3.29354 7.94124Z"
        fill="currentColor"
      />
    </svg>
  );
};
