import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import { ArrowUp } from "shared/icons/ArrowUp";
import { ImpactDataType, News } from "shared/models/news/NewsModel";
import { NewsService } from "shared/services/news/NewsService";
import {
  selectPortfolios,
  selectPortfolioById,
} from "shared/store/portfolios/PortfoliosReducer";
import { PortfoliosThunks } from "shared/store/portfolios/PortfoliosThunks";
import { formatDateTime } from "shared/utils/date";

type Props = {
  portfolioId?: string;
};

export const PortfolioNews: React.FC<Props> = ({ portfolioId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { portfolios: allPortfolios } = useSelector(selectPortfolios);
  const portfolio = useSelector(selectPortfolioById(portfolioId));
  const [news, setNews] = useState<News[]>([]);

  useEffect(() => {
    if (!portfolio) {
      dispatch(PortfoliosThunks.fetchMany());
    }
  }, [dispatch, portfolio]);

  useEffect(() => {
    const portfolios = portfolio ? [portfolio] : allPortfolios;
    if (portfolios) {
      const symbols = portfolios
        .map((p) => p.allocations)
        .flat()
        .filter((a) => a)
        .map((a) => a?.symbol);
      NewsService.fetch(symbols.join(",")).then(setNews);
    }
  }, [allPortfolios, portfolio]);

  return (
    <Panel>
      <Labels.H3>{t("news.portfolioNews")}</Labels.H3>
      {news?.length ? (
        <div className="mt-10 grid w-full grid-cols-5 gap-x-8 gap-y-4">
          <div>
            <div className="text-lg font-semibold">{t("news.categories")}</div>
          </div>
          <div className="col-span-2">
            <div className="text-lg font-semibold">{t("news.summary")}</div>
          </div>
          <div className="text-center">
            <div className="text-lg font-semibold">{t("news.impacts")}</div>
          </div>
          <div className="text-center">
            <div className="text-lg font-semibold">{t("news.date")}</div>
          </div>
          {news &&
            news.map((item, idx) => {
              return (
                <Fragment key={"news_row_" + idx}>
                  <div>
                    <Categories categories={item.categories} />
                  </div>
                  <div className="col-span-2">{item.summary}</div>
                  <div className="flex justify-center">
                    <Impacts articleId={item.id} impacts={item.impact} />
                  </div>
                  <div className="text-center">
                    {formatDateTime(item.created_at)}
                  </div>
                  <div className="col-span-5 mb-10 mt-4 border-b border-sb-gray-200"></div>
                </Fragment>
              );
            })}
        </div>
      ) : (
        <Labels.G1>{t("news.noNews")}</Labels.G1>
      )}
    </Panel>
  );
};

type ImpactsProps = {
  articleId: string;
  impacts: ImpactDataType[];
};

const Impacts: React.FC<ImpactsProps> = ({ impacts, articleId }) => {
  return (
    <div className="space-y-2">
      {impacts.map((item, idx) => {
        const bgClass =
          item.score > 0
            ? "bg-sb-green-100 text-sb-green-700 border-sb-green-700"
            : item.score < 0
            ? "bg-sb-red-100 text-sb-red-700 border-sb-red-700"
            : "bg-sb-cyan-100 text-sb-cyan-700 border-sb-cyan-700";
        const rotateClass =
          item.score > 0
            ? "rotate-0"
            : item.score < 0
            ? "rotate-180"
            : "rotate-90";
        return (
          <Link
            to={`/dashboard/assets/${item.symbol}`}
            key={articleId + "_impact_" + idx}
          >
            <div
              className={
                bgClass + " flex items-center gap-2 rounded-lg border px-2 py-1"
              }
            >
              <ArrowUp className={"aspect-square w-4 " + rotateClass} />
              {item.symbol}
            </div>
          </Link>
        );
      })}
    </div>
  );
};

type CategoriesProps = {
  categories: string[];
};

const Categories: React.FC<CategoriesProps> = ({ categories }) => {
  return (
    <div className="flex flex-wrap items-center gap-2">
      {categories.map((category, idx) => {
        return (
          <div
            key={"caegory_" + idx}
            className="w-fit rounded-full border border-sb-gray-500 bg-sb-gray-100 px-2 py-1 text-sm font-medium"
          >
            {category}
          </div>
        );
      })}
    </div>
  );
};
