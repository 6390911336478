import { CompanyLogo } from "shared/components/common/CompanyLogo";
import { AssetWrapper } from "shared/models/asset/AssetModel";

type Props = {
  asset: AssetWrapper;
  rightComponent?: JSX.Element;
  disableClick?: boolean;
  rowClassName?: string;
};

export const Asset: React.FC<Props> = ({
  asset,
  rightComponent,
  disableClick,
  rowClassName,
}) => {
  const exchange = asset.alpaca_details
    ? asset.alpaca_details.exchange
    : asset.ibrk_details?.exchange;
  const industry = asset.alpaca_details ? null : asset.ibrk_details?.industry;
  const conid = asset.alpaca_details ? null : asset.ibrk_details?.conid;

  return (
    <div
      className={
        "flex w-full items-center justify-between gap-2 " + rowClassName
      }
    >
      <div
        className={
          (disableClick ? "" : "cursor-pointer hover:bg-sb-gray-200") +
          " w-full rounded-xl p-3 duration-150"
        }
      >
        <div className="flex items-center gap-2">
          <CompanyLogo
            className="h-8 w-8 shrink-0 rounded-full border border-sb-gray-500"
            symbol={asset.symbol}
          />
          <div>
            <div className="flex items-center gap-4">
              <div className="text font-semibold">
                {asset.symbol} ({exchange})
              </div>
              {industry && (
                <div className="h-filt rounded-full bg-sb-blue-200 px-2 text-sm text-sb-blue-700">
                  {industry}
                </div>
              )}
            </div>
            <div className="text-sm text-sb-gray-500">{asset.name}</div>
            {conid && <div className="text-sm text-sb-gray-500">{conid}</div>}
          </div>
        </div>
      </div>
      {rightComponent}
    </div>
  );
};
