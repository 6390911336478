import { createSlice } from "@reduxjs/toolkit";
import { AssetInfo } from "shared/models/asset/AssetModel";
import { RootState } from "shared/store/store";
import { fetchInfoBySymbol } from "./AssetInfoThunks";

interface AssetInfoState {
  cache: {
    [symbol: string]: {
      info?: AssetInfo;
    };
  };
  selectedIndex?: number;
}

const initialState: AssetInfoState = {
  cache: {},
};

const assetInfoSlice = createSlice({
  name: "[ASSET_INFO]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInfoBySymbol.fulfilled, (state, action) => {
      state.cache[action.payload.symbol] = {
        ...state.cache[action.payload.symbol],
        info: action.payload.info,
      };
    });
  },
});

export const selectAssetInfo = (symbol: string) => (state: RootState) =>
  state.asset.info.cache[symbol] || {};

const AssetInfoActions = { ...assetInfoSlice.actions };

export default assetInfoSlice.reducer;
