import { Menu } from "@headlessui/react";
import { t } from "i18next";
import { CompactAssetInfo } from "modules/asset_info/CompactAssetInfo";
import { PropsWithChildren, useState } from "react";
import { OptionsMenu } from "shared/components/controls/OptionsMenu";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import useAssets from "shared/hooks/useAssets";
import { EditIcon } from "shared/icons/EditIcon";
import { OptionsIcon } from "shared/icons/OptionsIcon";
import { PlusIcon } from "shared/icons/PlusIcon";
import { TrashIcon } from "shared/icons/TrashIcon";
import { Watchlist } from "shared/models/watchlist/WatchlistModel";
import { AddSymbolToWatchlistModal } from "./AddSymbolToWatchlistModal";
import { DeleteWatchlistModal } from "./DeleteWatchlistModal";
import { useDispatch } from "shared/hooks/useDispatch";
import { WatchlistThunks } from "shared/store/watchlist/WatchlistThunks";
import { Button } from "shared/components/controls/Button";
import { RenameWatchlistModal } from "./RenameWatchlistModal";

type Props = {
  watchlist: Watchlist;
};

export const WatchlistPanel: React.FC<Props> = ({ watchlist }) => {
  const dispatch = useDispatch();
  const { assets_map } = useAssets();
  const [showAddMoreModal, setShowAddMoreModal] = useState(false);
  const [showDeleteWatchlistModal, setShowDeleteWatchlistModal] =
    useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showRenameWatchlistModal, setShowRenameWatchlistModal] =
    useState(false);

  const removeSymbol = (symbol: string) => {
    dispatch(WatchlistThunks.removeSymbol({ id: watchlist.id, symbol }));
  };

  return (
    <Panel>
      <AddSymbolToWatchlistModal
        watchlist={watchlist}
        open={showAddMoreModal}
        setOpen={setShowAddMoreModal}
      />
      <DeleteWatchlistModal
        open={showDeleteWatchlistModal}
        setOpen={setShowDeleteWatchlistModal}
        watchlist={watchlist}
      />
      <RenameWatchlistModal
        open={showRenameWatchlistModal}
        setOpen={setShowRenameWatchlistModal}
        watchlist={watchlist}
      />
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-baseline gap-4">
          <Labels.H2>{watchlist.name}</Labels.H2>
          {isEditing && (
            <div
              className="cursor-pointer"
              onClick={() => setShowRenameWatchlistModal(true)}
            >
              <EditIcon className="aspect-square w-6" />
            </div>
          )}
        </div>
        <div className="flex items-center gap-2">
          {isEditing && (
            <Button
              label="watchlist.finishEditing"
              onClick={() => setIsEditing(false)}
              className="!px-2 !py-1 text-sm"
              btnStyle="black"
            />
          )}
          <div>
            <OptionsMenu
              button={
                <div className="cursor-pointer">
                  <OptionsIcon className="aspect-square w-6" />
                </div>
              }
            >
              <div className="w-56 select-none space-y-2 p-2 font-semibold">
                <Menu.Item>
                  <MenuItem onClick={() => setShowAddMoreModal(true)}>
                    <PlusIcon className="aspect-square w-5" />
                    {t("watchlist.addMore")}
                  </MenuItem>
                </Menu.Item>
                <Menu.Item>
                  <MenuItem onClick={() => setIsEditing(true)}>
                    <EditIcon className="aspect-square w-5" />
                    {t("watchlist.edit")}
                  </MenuItem>
                </Menu.Item>
                <Menu.Item>
                  <MenuItem onClick={() => setShowDeleteWatchlistModal(true)}>
                    <TrashIcon className="aspect-square w-5" />
                    {t("watchlist.delete")}
                  </MenuItem>
                </Menu.Item>
              </div>
            </OptionsMenu>
          </div>
        </div>
      </div>
      <div className="mt-4 space-y-8">
        {!watchlist.assets.length && (
          <Labels.G1>{t("watchlist.noAssets")}</Labels.G1>
        )}
        {watchlist.assets.map((assetId) => {
          const asset = assets_map[assetId];
          if (!asset) {
            return null;
          }
          return (
            <div key={`${assetId}-watchlist`} className="flex w-full items-center gap-2">
              {isEditing && (
                <div
                  className="mr-4 cursor-pointer"
                  onClick={() => removeSymbol(assetId)}
                >
                  <TrashIcon className="aspect-square w-5" />
                </div>
              )}
              <div className="w-full">
                <CompactAssetInfo
                  asset={asset}
                  key={assetId}
                  companyClassName="w-[250px]"
                  bidAskClassname="w-[300px]"
                />
              </div>
            </div>
          );
        })}
      </div>
    </Panel>
  );
};

type MenuItemProps = PropsWithChildren & {
  onClick?: () => void;
};

const MenuItem: React.FC<MenuItemProps> = ({ onClick, children }) => {
  return (
    <div
      className="flex cursor-pointer items-center gap-2 rounded-lg p-2 duration-150 hover:bg-sb-gray-100"
      onClick={onClick}
    >
      {children}
    </div>
  );
};
