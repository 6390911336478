import { useNavigate, useParams } from "react-router-dom";
import { BasePage } from "shared/components/common/BasePage";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import { useEffect, useState } from "react";
import { ContactsThunks } from "shared/store/contacts/ContactsThunks";
import { selectContactById } from "shared/store/contacts/ContactsReducer";
import { Labels } from "shared/components/hoc/Labels";
import { AtSymbol } from "shared/icons/AtSymbol";
import { PhoneIcon } from "shared/icons/PhoneIcon";
import { t } from "i18next";
import { toast } from "react-toastify";
import { ContactsService } from "shared/services/contacts/ContactsService";
import { Modal } from "shared/components/common/Modal";
import { Button } from "shared/components/controls/Button";
import { TrashIcon } from "shared/icons/TrashIcon";
import { EditIcon } from "shared/icons/EditIcon";
import CheckIcon from "shared/icons/CheckIcon";
import { PaperAirplane } from "modules/proposals/svg/PaperAirplane";
import { KycHeader } from "modules/client_kyc/components/KycHeader";
import { KycForm } from "modules/client_kyc/components/KycForm";
import { Panel } from "shared/components/hoc/Panels";
import { Layouts } from "shared/components/hoc/Layouts";
import { KycDraft, Onboarding } from "shared/models/onboarding/OnboardingModel";

export const ContactDetailsPage: React.FC = () => {
  const { contactId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contact = useSelector(selectContactById(contactId));
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [inviteLoading, setInviteLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [initialData, setInitialData] = useState<KycDraft>();

  useEffect(() => {
    dispatch(ContactsThunks.fetchContacts());
  }, []);

  useEffect(() => {
    if (contactId) {
      ContactsService.getKyc(contactId).then((data) => {
        if (data) {
          setInitialData(data);
        }
      });
    }
  }, [contactId]);

  const handleKycSubmit = async (kycDraft: Onboarding) => {
    if (kycDraft && contactId) {
      await ContactsService.uploadKyc(contactId, kycDraft);
      toast.success(t("kyc.updateSuccess"));
    }
  };

  const handleRemoveContact = () => {
    if (contact) {
      setDeleteLoading(true);
      setShowDeleteModal(false);
      ContactsService.remove(contact.contact_id)
        .then(() => {
          dispatch(ContactsThunks.fetchContacts());
          toast.success(t("contacts.removedSuccess"));
          navigate("/contacts");
        })
        .finally(() => setDeleteLoading(false));
    }
  };

  const handleEditContact = (email?: string, phone?: string) => {
    if (contact) {
      setInviteLoading(true);
      const newContact = { ...contact };
      if (email) {
        newContact.email = email;
      }
      if (phone) {
        newContact.phone = phone;
      }
      ContactsService.update(contact.contact_id, newContact)
        .then(() => {
          dispatch(ContactsThunks.fetchContacts());
          toast.success(t("contacts.updatedSuccess"));
        })
        .finally(() => setInviteLoading(false));
    }
  };

  const handleInviteContact = () => {
    if (contact) {
      setInviteLoading(true);
      ContactsService.invite(contact.contact_id)
        .then(() => {
          dispatch(ContactsThunks.fetchContacts());
          toast.success(t("contacts.invitedSuccess"));
          navigate("/contacts");
        })
        .finally(() => setInviteLoading(false));
    }
  };

  return (
    <BasePage>
      <Modal open={showDeleteModal} setOpen={setShowDeleteModal}>
        <Labels.H3 className="mb-4 text-center">
          {t("contacts.remove")}
        </Labels.H3>
        <div className="flex w-full items-center justify-center gap-4">
          <Button
            label="global.cancel"
            btnStyle="blank"
            onClick={() => setShowDeleteModal(false)}
          />
          <Button label="watchlist.delete" onClick={handleRemoveContact} />
        </div>
      </Modal>
      {contact && (
        <>
          <Layouts.Spaced>
            <Panel>
              <Layouts.Spaced>
                <Labels.H1>{contact.name}</Labels.H1>
                <ValueRow
                  icon={<AtSymbol className="aspect-square w-5" />}
                  text={contact.email}
                  onEdited={(value) => handleEditContact(value, undefined)}
                />
                <ValueRow
                  icon={<PhoneIcon className="aspect-square w-5" />}
                  text={contact.phone}
                  onEdited={(value) => handleEditContact(undefined, value)}
                />
                <div className="flex items-center gap-4">
                  {contact?.status === "no_invitation" && (
                    <Button
                      label="contacts.sendInvite"
                      onClick={handleInviteContact}
                      leftIcon={<PaperAirplane className="aspect-square w-4" />}
                      loading={inviteLoading}
                      disabled={deleteLoading}
                    />
                  )}
                  {contact?.status !== "accepted" && (
                    <Button
                      btnStyle="blank"
                      label="watchlist.delete"
                      onClick={() => setShowDeleteModal(true)}
                      leftIcon={<TrashIcon className="aspect-square w-4" />}
                      loading={deleteLoading}
                      disabled={inviteLoading}
                    />
                  )}
                </div>
              </Layouts.Spaced>
            </Panel>
            {initialData && (
              <Panel>
                <KycHeader
                  title={t("kyc.editTitle")}
                  subtitle={t("kyc.editSubtitle")}
                />
                <KycForm
                  onSubmit={handleKycSubmit}
                  initialValues={initialData}
                />
              </Panel>
            )}
          </Layouts.Spaced>
        </>
      )}
    </BasePage>
  );
};

type ValueRowProps = {
  icon: JSX.Element;
  text: string;
  onEdited?: (value: string) => void;
};

const ValueRow: React.FC<ValueRowProps> = ({ icon, text, onEdited }) => {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(text);

  return (
    <div className="flex items-center gap-2 ">
      {icon}
      {!editing ? (
        <>
          <div className="text-xl">{text}</div>
          <div
            className="cursor-pointer"
            onClick={() => {
              setEditing(true);
              setValue(text);
            }}
          >
            <EditIcon className="aspect-square w-5 duration-150 hover:text-sb-gray-500" />
          </div>
        </>
      ) : (
        <>
          <input
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className="rounded-lg border border-sb-gray-200 p-2"
          />
          <div
            className="cursor-pointer"
            onClick={() => {
              setEditing(false);
              onEdited?.(value);
            }}
          >
            <CheckIcon className="aspect-square w-5 text-sb-green-700" />
          </div>
        </>
      )}
    </div>
  );
};
