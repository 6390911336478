import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Spinner } from "shared/components/common/Spinner";
import { KycDraft, Onboarding } from "shared/models/onboarding/OnboardingModel";
import { KycFormSegment } from "./KycFormSegment";
import { KycDropdown } from "./KycDropdown";
import { USA, countryOptions } from "../utils/consts";
import { KycTextInput } from "./KycTextInput";
import { KycDateOfBirthInput } from "./KycDateOfBirthInput";

interface Props {
  disabled?: boolean;
  onSubmit: (KYCData: Onboarding) => void;
  initialValues?: KycDraft;
}

interface OnboardingForm extends Onboarding {
  signature?: string;
  agreementTerms?: boolean;
}

export interface KycControllerProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  control?: any;
  rules?: Object;
  error?: any;
  disabled?: boolean;
}

export const KycForm: React.FC<Props> = ({
  disabled,
  onSubmit,
  initialValues,
}) => {
  const { t } = useTranslation();

  const [submitting, setSubmitting] = useState(false);

  const { handleSubmit, control, formState, watch } = useForm<OnboardingForm>();

  const { errors } = formState;

  const taxCountry = watch("taxCountry");
  const isPayingTaxesInUsa =
    initialValues?.identity?.country_of_tax_residence === "USA" ||
    taxCountry === "USA";

  const submitWrapper = async (data: OnboardingForm) => {
    setSubmitting(true);
    try {
      onSubmit(data);
    } catch (error) {
      throw error;
    } finally {
      setSubmitting(false);
    }
  };

  const steps = [
    <KycFormSegment title="kyc.countryOfLiving" key="kyc_segment_1">
      <KycDropdown
        defaultValue={initialValues?.identity?.country_of_tax_residence}
        options={countryOptions}
        label="kyc.taxCountry"
        name="taxCountry"
        control={control}
        error={errors.taxCountry}
      />
    </KycFormSegment>,
    <KycFormSegment
      className="mr-10"
      title="kyc.personalInformation"
      key="kyc_segment_2"
    >
      <KycTextInput
        defaultValue={initialValues?.identity?.given_name}
        label={t("kyc.firstName")}
        name="firstName"
        control={control}
        error={errors.firstName}
      />
      <KycTextInput
        defaultValue={initialValues?.identity?.middle_name}
        label={t("kyc.middleName")}
        name="middleName"
        control={control}
      />
      <KycTextInput
        defaultValue={initialValues?.identity?.family_name}
        label={t("kyc.lastName")}
        name="lastName"
        control={control}
        error={errors.lastName}
      />
    </KycFormSegment>,
    <KycFormSegment title="kyc.citizenship" key="kyc_segment_3">
      <KycDropdown
        defaultValue={initialValues?.identity?.country_of_citizenship}
        options={countryOptions}
        label="kyc.nationality"
        name="nationality"
        control={control}
        error={errors.nationality}
      />
    </KycFormSegment>,
    <KycFormSegment title="kyc.birthday" className="mb-12" key="kyc_segment_4">
      <KycDateOfBirthInput
        defaultValue={initialValues?.identity?.date_of_birth}
        name="birthday"
        control={control}
        error={errors.birthday}
      />
    </KycFormSegment>,
    <KycFormSegment title="kyc.addressInformation" key="kyc_segment_6">
      <div className="flex w-full flex-row justify-between">
        <div className="mr-4 flex-grow">
          <KycTextInput
            defaultValue={initialValues?.contact?.street_address?.[0]}
            label={t("kyc.address.street")}
            name="street"
            control={control}
            error={errors.street}
          />
          <KycTextInput
            defaultValue={initialValues?.contact?.street_address?.[1]}
            label={t("kyc.address.addressUnit")}
            name="addressUnit"
            control={control}
            error={errors.addressUnit}
          />
          <KycTextInput
            defaultValue={initialValues?.contact?.city}
            label={t("kyc.address.city")}
            name="city"
            control={control}
            error={errors.city}
          />
        </div>
        <div className="flex-grow">
          {isPayingTaxesInUsa ? (
            <KycDropdown
              defaultValue={initialValues?.contact?.state}
              options={USA.States}
              label={t("kyc.address.state")}
              name="state"
              control={control}
              error={errors.state}
            />
          ) : (
            <KycTextInput
              defaultValue={initialValues?.contact?.state}
              label={t("kyc.address.state")}
              name="state"
              control={control}
              error={errors.state}
            />
          )}
          <KycTextInput
            defaultValue={initialValues?.contact?.postal_code}
            label={t("kyc.address.postalCode")}
            name="postalCode"
            control={control}
            error={errors.postalCode}
          />
        </div>
      </div>
    </KycFormSegment>,
  ];

  return (
    <form
      className={`relative ${disabled ? "opacity-20" : ""}`}
      onSubmit={handleSubmit(submitWrapper)}
    >
      {disabled ? (
        <div className="absolute left-0 top-0 z-10 h-full w-full" />
      ) : null}
      <div className="flex flex-row justify-between"></div>
      {steps}
      <button type="submit" className="green-btn w-100 mt-6 text-center">
        {submitting ? <Spinner className="h-8 w-8" /> : t("kyc.attachKycDraft")}
      </button>
    </form>
  );
};
