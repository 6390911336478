import { Fragment } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  request: Record<string, any>;
};

export const PnlPct: React.FC<Props> = ({ request }) => {
  const { t } = useTranslation();

  let previousLoses = [];

  for (let i = 0; request[`pnlPct_prev_loss_amount_${i}`] !== undefined; i++) {
    previousLoses.push(
      <Fragment key={"pl_" + i}>
        <div className="text-lg font-medium">
          {t("feeTemplateForm.pnlPct.previousLoss")} -{" "}
          {request[`pnlPct_prev_loss_year_${i}`]}
        </div>
        <div>{request[`pnlPct_prev_loss_amount_${i}`]}</div>
      </Fragment>,
    );
  }

  return (
    <>
      <div className="col-span-2 mt-8 border-b pb-2 text-xl font-semibold">
        {t("feeTemplateForm.pnlPct.title")}
      </div>
      <div className="text-lg font-medium">{t("feeTemplateForm.applied")}</div>
      <div>{t(`feeTemplateForm.pnlPct.${request["pnlPct_applied"]}`)}</div>
      <div className="text-lg font-medium">
        {t("feeTemplateForm.percentage")}
      </div>
      <div>{request["pnlPct_percentage"]}%</div>
      <div className="text-lg font-medium">
        {t("feeTemplateForm.pnlPct.performanceThreshold")}
      </div>
      <div>
        {request["pnlPct_performanceThreshold"]
          ? t("feeTemplateForm.yes")
          : t("feeTemplateForm.no")}
      </div>
      {request["pnlPct_threshold_type"] && (
        <>
          <div className="text-lg font-medium">
            {t("feeTemplateForm.pnlPct.type")}
          </div>
          <div>
            {t(`feeTemplateForm.pnlPct.${request["pnlPct_threshold_type"]}`)}
          </div>
        </>
      )}
      {request["pnlPct_benchmark"] && (
        <>
          <div className="text-lg font-medium">
            {t("feeTemplateForm.pnlPct.benchmark")}
          </div>
          <div>{request["pnlPct_benchmark"]}</div>
        </>
      )}
      {request["pnlPct_highWaterMark"] && (
        <>
          <div className="text-lg font-medium">
            {t("feeTemplateForm.pnlPct.highWaterMark")}
          </div>
          <div>
            {request["pnlPct_highWaterMark"]
              ? t("feeTemplateForm.yes")
              : t("feeTemplateForm.no")}
          </div>
        </>
      )}
      {request["pnlPct_perpetualLookBack"] && (
        <>
          <div className="text-lg font-medium">
            {t("feeTemplateForm.pnlPct.perpetualLookBack")}
          </div>
          <div>
            {request["pnlPct_perpetualLookBack"]
              ? t("feeTemplateForm.yes")
              : t("feeTemplateForm.no")}
          </div>
        </>
      )}
      {request["pnlPct_lookBackYears"] && (
        <>
          <div className="text-lg font-medium">
            {t("feeTemplateForm.pnlPct.lookBackYears")}
          </div>
          <div>{request["pnlPct_lookBackYears"]}</div>
        </>
      )}
      {request["pnlPct_prorate"] && (
        <>
          <div className="text-lg font-medium">
            {t("feeTemplateForm.pnlPct.prorate")}
          </div>
          <div>
            {request["pnlPct_prorate"]
              ? t("feeTemplateForm.yes")
              : t("feeTemplateForm.no")}
          </div>
        </>
      )}
      {request["pnlPct_previousLoses"] && (
        <>
          <div className="text-lg font-medium">
            {t("feeTemplateForm.pnlPct.previousLoses")}
          </div>
          <div>
            {request["pnlPct_previousLoses"]
              ? t("feeTemplateForm.yes")
              : t("feeTemplateForm.no")}
          </div>
        </>
      )}
      {previousLoses}
    </>
  );
};
