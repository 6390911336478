import { createAsyncThunk } from "@reduxjs/toolkit";
import { FeesService } from "shared/services/fees/FeesService";

export const fetchPayoutTerms = createAsyncThunk(
  "[PAYOUT_TERMS] Fetch payout terms",
  async () => {
    const payoutTerms = await FeesService.getPayoutTerms();
    return payoutTerms;
  },
);

export const FeePayoutTermsThunks = {
  fetchPayoutTerms,
};
