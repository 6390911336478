import { t } from "i18next";
import { useEffect } from "react";
import { Labels } from "shared/components/hoc/Labels";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import { selectPayoutTerms } from "shared/store/fee_payout_term/FeePayoutTermsReducer";
import { FeePayoutTermsThunks } from "shared/store/fee_payout_term/FeePayoutTermThunks";
import { pctFormatUnsigned } from "shared/utils/currency";

export const PayoutTerms: React.FC = () => {
  const dispatch = useDispatch();
  const { payoutTerms } = useSelector(selectPayoutTerms());

  useEffect(() => {
    dispatch(FeePayoutTermsThunks.fetchPayoutTerms());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (!payoutTerms) {
    return null;
  }

  const sb_share = payoutTerms.streetbeat_share

  return (
    <>
      <Labels.H3 className="mt-8">{t("payouts.payoutTerms")}</Labels.H3>
      <div>
        <div>
          <span className="mr-2 font-medium">{t("payouts.effectiveFrom")}:</span>
          {payoutTerms?.effective_from}
        </div>
        <div>
          <span className="mr-2 font-medium">
            {t("payouts.streetbeatShare")}:
          </span>
          {pctFormatUnsigned.format(sb_share)}
        </div>
      </div>
    </>
  );
};
