import React from "react";

type Props = {
  className?: string;
};

export const ArrowBackIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={`h-4 w-4 fill-black ${className}`}
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.79845 10.619L6.26994 11.1612C6.04615 11.3908 5.68429 11.3908 5.46289 11.1612L0.834831 6.41532C0.611046 6.18572 0.611046 5.81445 0.834831 5.58729L5.46289 0.838949C5.68667 0.609348 6.04853 0.609348 6.26994 0.838949L6.79845 1.3812C7.02462 1.61324 7.01985 1.99184 6.78893 2.219L3.9202 5.02306H10.7623C11.0789 5.02306 11.3337 5.28441 11.3337 5.60927V6.39089C11.3337 6.71575 11.0789 6.97711 10.7623 6.97711H3.9202L6.78893 9.78117C7.02223 10.0083 7.027 10.3869 6.79845 10.619Z" />
    </svg>
  );
};
