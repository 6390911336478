import { PropsWithChildren } from "react";

type Props = PropsWithChildren & {
  open: boolean;
  height: number;
  width: number;
  className?: string;
};

export const FloatingWindow: React.FC<Props> = ({
  open,
  height,
  width,
  children,
  className,
}) => {
  return (
    <div
      style={{
        height: height,
        top: -height - 10,
        width: width,
        left: -width + 40,
      }}
      className={
        (open ? "visible" : "hidden") +
        " absolute overflow-hidden rounded-xl bg-white drop-shadow-md transition duration-300"
      }
    >
      <div className={"h-full w-full overflow-auto " + className}>
        {children}
      </div>
    </div>
  );
};
