import { useTranslation } from "react-i18next";

type Props = {
  request: Record<string, any>;
};

export const Invoicing: React.FC<Props> = ({ request }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="col-span-2 mt-8 border-b pb-2 text-xl font-semibold">
        {t("feeTemplateForm.invoicing.title")}
      </div>
      <div className="text-lg font-medium">{t("feeTemplateForm.applied")}</div>
      <div>{t(`feeTemplateForm.pnlPct.${request["invoicing_applied"]}`)}</div>
      <div className="text-lg font-medium">
        {t("feeTemplateForm.invoicing.maxFee")}
      </div>
      <div>{request["invoicing_maxFee"]}</div>
    </>
  );
};
