import { useEffect, useState } from "react";
import { PlaidLinkOnSuccessMetadata, usePlaidLink } from "react-plaid-link";
import { BasePage } from "shared/components/common/BasePage";
import { Spinner } from "shared/components/common/Spinner";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";
import { Layouts } from "shared/components/hoc/Layouts";
import { Panel } from "shared/components/hoc/Panels";
import { TrashIcon } from "shared/icons/TrashIcon";
import { PlaidLinkToken } from "shared/models/plaid/PlaidModel";
import { PlaidService } from "shared/services/plaid/PlaidService";

export const PlaidConnect: React.FC = () => {
  const [linkToken, setLinkToken] = useState<PlaidLinkToken>();

  useEffect(() => {
    PlaidService.fetchInvestmentsToken().then(setLinkToken);
  }, []);

  return (
    <BasePage>
      {!linkToken && (
        <div className="flex justify-center py-10">
          <Spinner className="size-8" />
        </div>
      )}
      {linkToken && <PlaidConnectButton token={linkToken.link_token} />}
    </BasePage>
  );
};

type PlaidConnectButtonProps = {
  token: string;
};

const PlaidConnectButton: React.FC<PlaidConnectButtonProps> = ({ token }) => {
  const [accounts, setAccounts] = useState<PlaidLinkOnSuccessMetadata[]>([]);
  const { open, ready } = usePlaidLink({
    token: token,
    onSuccess: (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
      const selectedAccount = metadata.accounts[0];
      if (!selectedAccount) throw new Error("No account selected");
      PlaidService.addAccessToken(public_token, metadata).then(refresh);
    },
  });

  const refresh = () => {
    setAccounts([]);
    PlaidService.getAccounts().then(setAccounts);
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleRemoveAccounts = (institutionId: string) => {
    PlaidService.deleteAccount(institutionId).then(refresh);
  };

  return (
    <>
      <Button onClick={() => open()} disabled={!ready} label="Connect plaid" />
      <Layouts.Spaced className="mt-12">
        {accounts.map((item, idx) => {
          return (
            <Panel key={"panel_ac_" + idx}>
              <div className="flex items-center justify-between">
                <div>
                  <Labels.H2>{item.institution?.name}</Labels.H2>
                  {item.accounts.map((account, accIdx) => {
                    return (
                      <div key={"ac_" + accIdx + "_in_" + idx}>
                        {account.name}
                      </div>
                    );
                  })}
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    item.institution &&
                    handleRemoveAccounts(item.institution.institution_id)
                  }
                >
                  <TrashIcon className="size-6 duration-150 hover:text-sb-gray-500" />
                </div>
              </div>
            </Panel>
          );
        })}
      </Layouts.Spaced>
    </>
  );
};
