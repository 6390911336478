import { MultiClientPicker } from "modules/client_picker/MultiClientPicker";
import { FeesAccruedChart } from "modules/fees_accrued_chart/FeesAccruedChart";
import { FeeStatusPicker } from "modules/fee_status_picker/FeeStatusPicker";
import { useEffect, useState } from "react";
import { DateRange } from "react-aria";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BasePage } from "shared/components/common/BasePage";
import { DataTable } from "shared/components/common/datatable/DataTable";
import { Button } from "shared/components/controls/Button";
import { Labels } from "shared/components/hoc/Labels";
import { Layouts } from "shared/components/hoc/Layouts";
import { Panel } from "shared/components/hoc/Panels";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import {
  FinancialAdvisorFee,
  FinancialAdvisorFeeStatus,
} from "shared/models/fees/FeesModel";
import { FeeQuery, FeesService } from "shared/services/fees/FeesService";
import { selectClients } from "shared/store/clients/ClientsReducer";
import { ClientsThunks } from "shared/store/clients/ClientsThunks";
import { Loading } from "shared/types/enums";
import { formatDateTime } from "shared/utils/date";
import { DateRangeSelector } from "../../../shared/components/common/DateRangeSelector";
import { CurrencyFormat } from "shared/utils/currency";

export const FeesPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clients } = useSelector(selectClients);

  const [results, setResults] = useState<FinancialAdvisorFee[]>([]);

  const [clientIds, setClientIds] = useState<string[]>([]);
  const [scheduledDateRange, setScheduledDateRange] = useState<DateRange>();
  const [collectedDate, setCollectedDate] = useState<DateRange>();
  const [payoutDate, setPayoutDate] = useState<DateRange>();
  const [updatedDate, setUpdatedDate] = useState<DateRange>();
  const [feeStatuses, setFeeStatuses] = useState<FinancialAdvisorFeeStatus[]>(
    [],
  );
  const [amountFrom, setAmountFrom] = useState<string>();
  const [amountTo, setAmountTo] = useState<string>();

  const [loading, setLoading] = useState<Loading>(Loading.Idle);

  const search = () => {
    setLoading(Loading.InProgress);
    const newQuery: FeeQuery = {
      scheduled_charge_date_from: scheduledDateRange?.start.toString(),
      scheduled_charge_date_to: scheduledDateRange?.end.toString(),
      collected_date_from: collectedDate?.start.toString(),
      collected_date_to: collectedDate?.end.toString(),
      payout_date_from: payoutDate?.start.toString(),
      payout_date_to: payoutDate?.end.toString(),
      updated_date_from: updatedDate?.start.toString(),
      updated_date_to: updatedDate?.end.toString(),
      statuses: feeStatuses,
      client_ids: clientIds,
      amount_from: amountFrom ? parseFloat(amountFrom) : undefined,
      amount_to: amountTo ? parseFloat(amountTo) : undefined,
    };
    FeesService.getFees(newQuery)
      .then(setResults)
      .finally(() => setLoading(Loading.Finished));
  };

  useEffect(() => {
    search();
    dispatch(ClientsThunks.fetchClients());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BasePage>
      <Labels.H1>{t("navigation.fees")}</Labels.H1>
      <Layouts.Spaced className="mt-8">
        <FeesAccruedChart />
        <Panel className="space-y-4">
          <Labels.H3>{t("fees.filters")}</Labels.H3>
          <div>
            <span className="font-medium">{t("fees.clients")}</span>
            <MultiClientPicker onChange={setClientIds} />
          </div>
          <div>
            <span className="font-medium">{t("fees.feeStatuses")}</span>
            <FeeStatusPicker
              feeStatuses={feeStatuses}
              setFeeStatuses={setFeeStatuses}
            />
          </div>
          <div className="flex gap-4">
            <div>
              <span className="font-medium">{t("fees.scheduledDate")}</span>
              <DateRangeSelector
                dateRange={scheduledDateRange}
                setDateRange={setScheduledDateRange}
                placeholder={t("fees.selectDateRange")}
              />
            </div>
            <div>
              <span className="font-medium">{t("fees.collectedDate")}</span>
              <DateRangeSelector
                dateRange={collectedDate}
                setDateRange={setCollectedDate}
                placeholder={t("fees.selectDateRange")}
              />
            </div>
            <div>
              <span className="font-medium">{t("fees.payoutDate")}</span>
              <DateRangeSelector
                dateRange={payoutDate}
                setDateRange={setPayoutDate}
                placeholder={t("fees.selectDateRange")}
              />
            </div>
            <div>
              <span className="font-medium">{t("fees.updatedDate")}</span>
              <DateRangeSelector
                dateRange={updatedDate}
                setDateRange={setUpdatedDate}
                placeholder={t("fees.selectDateRange")}
              />
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div>
              <span className="font-medium">{t("fees.amountFrom")}</span>
              <input
                type="number"
                className="w-full rounded-lg border border-sb-gray-200 px-2 py-2 text-right outline-none"
                value={amountFrom}
                onChange={(e) => setAmountFrom(e.target.value)}
              />
            </div>
            <div className="pt-4">-</div>
            <div>
              <span className="font-medium">{t("fees.amountTo")}</span>
              <input
                type="number"
                className={
                  "w-full rounded-lg border px-2 py-2 text-right outline-none " +
                  (parseFloat(amountTo || "0") < parseFloat(amountFrom || "0")
                    ? "border-sb-red-700"
                    : "border-sb-gray-200")
                }
                value={amountTo}
                onChange={(e) => setAmountTo(e.target.value)}
              />
            </div>
          </div>
          <div>
            <Button
              label="global.search"
              onClick={search}
              disabled={loading === Loading.InProgress}
            />
          </div>
        </Panel>
        <Panel>
          <Labels.H3>{t("fees.results")}</Labels.H3>
          {loading === Loading.Finished && !results?.length ? (
            <Labels.G1>{t("fees.noResults")}</Labels.G1>
          ) : null}
          {loading === Loading.InProgress ||
          (loading === Loading.Finished && results?.length) ? (
            <DataTable
              data={results}
              loading={loading === Loading.InProgress}
              columns={[
                "user_id",
                "amount",
                "scheduled_charge_date",
                "status",
                "collected_at",
                "paid_at",
                "updated_at",
              ]}
              headerLabels={{
                user_id: t("fees.headers.client"),
                amount: t("fees.headers.amount"),
                created_at: t("fees.headers.createdAt"),
                collected_at: t("fees.headers.collectedAt"),
                paid_at: t("fees.headers.paidAt"),
                scheduled_charge_date: t("fees.headers.scheduledChargeDate"),
                status: t("fees.headers.status"),
                updated_at: t("fees.headers.updatedAt"),
              }}
              sortableValue={(key: string, entry: any) => {
                if (key === "status") {
                  const status = entry.status;
                  if (status === "calculation") return 0;
                  if (status === "confirmed") return 1;
                  if (status === "collected") return 2;
                  if (status === "paid") return 3;
                  return -1;
                }

                if (key === "amount") {
                  const amount = entry?.amount || "0";
                  return +amount;
                }

                return undefined;
              }}
              cellFormatter={(key: string, value: any) => {
                if (
                  [
                    "created_at",
                    "collected_at",
                    "paid_at",
                    "updated_at",
                  ].includes(key)
                ) {
                  return value ? formatDateTime(value) : "";
                }
                if (key === "status") {
                  return t(`fees.statuses.${value}`);
                }
                if (key === "user_id") {
                  return (
                    clients.find((c) => c.user_id === value)?.summary
                      .client_name || t("global.unknown")
                  );
                }
                if (key === "amount") {
                  return <div>{CurrencyFormat.format(value)}</div>;
                }
                return value;
              }}
              defaultSortSettings={{
                direction: "desc",
                by: "created_at",
              }}
              onRowClicked={(row) => {
                navigate(`/clients/${row.user_id}?tab=5`);
              }}
            />
          ) : null}
        </Panel>
      </Layouts.Spaced>
    </BasePage>
  );
};
