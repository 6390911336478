import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "../../../shared/components/controls/Checkbox";
import { FlatFee } from "./fees/FlatFeeForm";
import { InvoicingForm } from "./fees/InvoicingForm";
import { NetLiquidationForm } from "./fees/NetLiquidationForm";
import { PnlPctForm } from "./fees/PnlPctForm";

export const FeeStrategyDetails: React.FC = () => {
  const { t } = useTranslation();

  const [netLiquidation, setNetLiquidation] = useState(false);
  const [flatFee, setFlatFee] = useState(false);
  const [pnlPctFee, setPnlPctFee] = useState(false);
  const [invoicing, setInvoicing] = useState(false);

  return (
    <div>
      <Checkbox
        label={t("feeTemplateForm.netLiquidation.title")}
        onChange={setNetLiquidation}
        name="netLiquidation"
      />
      <hr className="mb-4 border-sb-gray-300" />
      {netLiquidation && (
        <div className="my-8">
          <NetLiquidationForm />
        </div>
      )}
      <Checkbox
        label={t("feeTemplateForm.flatFee.title")}
        onChange={setFlatFee}
        name="flatFee"
      />
      <hr className="mb-4 border-sb-gray-300" />
      {flatFee && (
        <div className="my-8">
          <FlatFee />
        </div>
      )}
      <Checkbox
        label={t("feeTemplateForm.pnlPct.title")}
        onChange={setPnlPctFee}
        name="pnlPctFee"
      />
      <hr className="mb-4 border-sb-gray-300" />
      {pnlPctFee && (
        <div className="my-8">
          <PnlPctForm />
        </div>
      )}
      <Checkbox
        label={t("feeTemplateForm.invoicing.title")}
        onChange={setInvoicing}
        name="invoicing"
      />
      <hr className="mb-4 border-sb-gray-300" />
      {invoicing && (
        <div className="my-8">
          <InvoicingForm />
        </div>
      )}
    </div>
  );
};
