import { useTranslation } from "react-i18next";

type Props = {
  index: number;
};

const START_YEAR = 2008;

export const PreviousLoss: React.FC<Props> = ({ index }) => {
  const { t } = useTranslation();

  const yearsArray = Array.from(
    { length: new Date().getFullYear() - START_YEAR - 1 },
    (_, index) => START_YEAR + index,
  );

  return (
    <div className="col-span-2 border-b border-b-sb-gray-400 pb-4">
      <div className="text-lg font-semibold">
        {t("feeTemplateForm.pnlPct.previousLoss")}
      </div>
      <div className="grid grid-cols-2 gap-y-4">
        <label>{t("feeTemplateForm.amount")}</label>
        <input
          type="number"
          name={"pnlPct_prev_loss_amount_" + index}
          required
        />
        <label>{t("feeTemplateForm.pnlPct.year")}</label>
        <select name={"pnlPct_prev_loss_year_" + index}>
          {yearsArray.map((item, idx) => (
            <option key={"year_" + idx} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
