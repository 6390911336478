import { DateTime } from "luxon";

import { DATE_FORMAT } from "./consts";

const leftZero = (val: number): string => {
  if (val > 9) {
    return `${val}`;
  }

  return `0${val}`;
};

export const isValidDate = (
  year: number,
  month: number,
  day: number,
): boolean => {
  // Adjustment made due to Hermes activation breaking date validation.
  const date = `${year}-${leftZero(month)}-${leftZero(day)}`;
  const parsedDate = DateTime.fromFormat(date, DATE_FORMAT);
  const formattedDate = parsedDate.toFormat(DATE_FORMAT);
  return date === formattedDate;
};

export const validateAge = (
  value: Date | undefined,
  minAge: number,
  maxAge: number,
) => {
  if (!value) return false;
  const now = DateTime.now();
  const date = DateTime.fromJSDate(value);
  if (date > now) return false;
  const age = now.diff(date, "years").years;
  return age >= minAge && age <= maxAge;
};
