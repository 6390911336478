import { Person } from "shared/icons/Person";
import { StreetbeatIcon } from "shared/icons/StreetbeatIcon";
import "./styles.css";

type Props = {
  text: string;
  sbIcon?: boolean;
};

export const Message: React.FC<Props> = ({ text, sbIcon }) => {
  return (
    <div className="flex flex-row-reverse items-end gap-2">
      <div className="grid aspect-square h-6 place-items-center rounded-full bg-sb-gray-200">
        {sbIcon ? (
          <StreetbeatIcon className="aspect-square h-4" />
        ) : (
          <Person className="aspect-square h-4 text-sb-gray-500" />
        )}
      </div>
      <div className="chat-message-bubble right bg-black text-white">
        {text}
      </div>
    </div>
  );
};
