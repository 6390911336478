const CurrencyFormat = {
  format: (amount: any, currency?: string | undefined) => {
    const floatAmount: number =
      typeof amount == "string" ? parseFloat(amount) : amount;
    if (!currency || currency === "USD") {
      const c = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return c.format(floatAmount);
    }
    const c = new Intl.NumberFormat("pl-PL", {
      style: "currency",
      currency: currency,
    });
    return c.format(floatAmount);
  },
};

export const roundCurrency = (num: number, precision: number = 100) =>
  Math.floor((num + Number.EPSILON) * precision) / precision;

export const usdIntlConfig = {
  locale: "en-US",
  currency: "USD",
};

export const pctFormat = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  signDisplay: "always", // 'always' to display + for positive numbers
});

export const pctFormatUnsigned = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const pctFormatInt = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const pctFormatMaxTwo = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export { CurrencyFormat };
