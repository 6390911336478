import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import { ClientDetailsThunks } from "shared/store/client_details/ClientDetailsThunks";
import { ClientPortfolios } from "modules/client_portfolios/ClientPortfolios";
import { ClientProposals } from "modules/client_proposals/ClientProposals";
import { TotalInvesting } from "modules/total_investing/TotalInvesting";
import { selectClientById } from "shared/store/clients/ClientsReducer";
import { LatestUpdates } from "modules/latest_updates/LatestUpdates";
import { Layouts } from "shared/components/hoc/Layouts";

type Props = {
  clientId: string;
};

export const ClientDashboard: React.FC<Props> = ({ clientId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const client = useSelector(selectClientById(clientId));

  useEffect(() => {
    dispatch(ClientDetailsThunks.fetchPortfolios({ clientId }));
  }, [dispatch, clientId]);

  if (client && !client.summary.account_name) {
    return (
      <div className="text-xl font-semibold">
        {t("clientDetails.noAccount")}
      </div>
    );
  }

  return (
    <div>
      {clientId && client ? (
        <Layouts.Spaced>
          <TotalInvesting clientId={clientId} />
          <ClientPortfolios clientId={clientId} />
          <ClientProposals clientId={clientId} />
          <LatestUpdates clientId={clientId} />
        </Layouts.Spaced>
      ) : (
        ""
      )}
    </div>
  );
};
