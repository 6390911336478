import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "shared/components/common/Spinner";
import { useRecentSearch } from "shared/hooks/useRecentSearch";
import { MagnifyingGlassIcon } from "shared/icons/MagnifyingGlassIcon";
import { XMarkIcon } from "shared/icons/XMarkIcon";
import { AssetWrapper } from "shared/models/asset/AssetModel";
import { AssetsService } from "shared/services/assets/AssetsService";
import { Asset } from "./Asset";

type Props = {
  value?: AssetWrapper;
  onClicked?: (asset: AssetWrapper) => void;
  onClear?: () => void;
  wFull?: boolean;
  hidePlaceholder?: boolean;
  assetListRightComponent?: (symbol: string) => JSX.Element;
  assetListRowClass?: (symbol: string) => string;
  disableClick?: boolean;
};

export const GlobalSearch: React.FC<Props> = ({
  onClicked,
  onClear,
  wFull,
  hidePlaceholder,
  value,
  assetListRightComponent,
  disableClick,
  assetListRowClass,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [assets, setAssets] = useState<AssetWrapper[]>([]);
  const wrapperRef = useRef(null);
  const [opened, setOpened] = useState(false);

  const { saveRecentSearch } = useRecentSearch();

  const [searching, setSearching] = useState(false);

  useEffect(() => {
    if (value?.symbol) {
      setSearchTerm(value.symbol);
    }
  }, [value]);

  useEffect(() => {
    if (!searchTerm) {
      return;
    }
    if (document.activeElement !== inputRef.current) {
      return;
    }
    setAssets([]);
    setSearching(true);
    const delaySearch = setTimeout(() => {
      AssetsService.search(searchTerm)
        .then(setAssets)
        .finally(() => setSearching(false));
    }, 1000);

    return () => clearTimeout(delaySearch);
  }, [searchTerm]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        wrapperRef &&
        wrapperRef.current &&
        !(wrapperRef.current as any).contains(event.target)
      ) {
        setOpened(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleOnClick = (item: AssetWrapper) => {
    onClicked?.(item);
    setOpened(false);
    setSearchTerm(item.symbol);
    saveRecentSearch({ symbol: item.symbol, name: item.name });
  };

  return (
    <div
      className={(wFull ? "w-full" : "w-fit") + " relative"}
      ref={wrapperRef}
    >
      <div className="absolute left-4 top-1/2 -translate-y-1/2">
        {searchTerm ? (
          <div
            className="cursor-pointer"
            onClick={() => {
              setSearchTerm("");
              setOpened(false);
              setAssets([]);
              onClear?.();
            }}
          >
            <XMarkIcon className="h-5 w-5 text-sb-gray-600" strokeWidth={1} />
          </div>
        ) : (
          <MagnifyingGlassIcon className="h-5 w-5 text-sb-gray-600" />
        )}
      </div>
      {searching && (
        <div className="absolute right-4 top-1/2 -translate-y-1/2">
          <Spinner className="h-5 w-5" />
        </div>
      )}
      <input
        ref={inputRef}
        type="text"
        className={
          (wFull ? "w-full" : "w-[500px]") +
          " rounded-full border border-sb-gray-400 px-4 py-2 pl-10 outline-sb-green-600"
        }
        placeholder={hidePlaceholder ? "" : t("globalSearch.placeholder")}
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
        onFocus={() => setOpened(true)}
      />
      {opened && assets.length ? (
        <div className="absolute left-0 top-[100%] z-50 max-h-[50vh] min-w-[400px] overflow-y-auto rounded-xl bg-white drop-shadow-lg">
          {assets.map((item, idx) => {
            return (
              <div
                key={idx}
                onClick={() => !disableClick && handleOnClick(item)}
              >
                <Asset
                  asset={item}
                  rightComponent={assetListRightComponent?.(item.symbol)}
                  disableClick={disableClick}
                  rowClassName={assetListRowClass?.(item.symbol)}
                />
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};
