import { useEffect } from "react";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import { Portfolio } from "shared/models/portfolio/PortfolioModel";

import { selectPortfolios } from "shared/store/portfolios/PortfoliosReducer";
import { PortfoliosThunks } from "shared/store/portfolios/PortfoliosThunks";
import { Loading } from "shared/types/enums";

interface PortfoliosHookData {
  portfolios: Portfolio[];
  loading: Loading;
  fetched: boolean;
}

export function usePortfolios(): PortfoliosHookData {
  const dispatch = useDispatch();
  const { loading, fetched, portfolios } = useSelector(selectPortfolios);

  useEffect(() => {
    dispatch(PortfoliosThunks.fetchMany());
  }, [dispatch]);

  return {
    loading,
    fetched,
    portfolios,
  };
}
