type Props = {
  className?: string;
};

export const TypingAnimation: React.FC<Props> = ({ className }) => {
  return (
    <svg height="20" width="40" className="rounded-full bg-sb-gray-100">
      <circle className="dot" cx="5" cy="10" r="3" style={{ fill: "grey" }} />
      <circle className="dot" cx="20" cy="10" r="3" style={{ fill: "grey" }} />
      <circle className="dot" cx="35" cy="10" r="3" style={{ fill: "grey" }} />
    </svg>
  );
};
