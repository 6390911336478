import { PositionsService } from "shared/services/positions/PositionsService";
import { useEffect, useState } from "react";
import { Modal } from "shared/components/common/Modal";
import { TradingPosition } from "shared/models/trading/TradingPositionmodel";
import { Labels } from "shared/components/hoc/Labels";
import useAssets from "shared/hooks/useAssets";
import { useTranslation } from "react-i18next";
import { CurrencyFormat } from "shared/utils/currency";
import { Skeleton } from "shared/components/common/Skeleton";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  symbol: string;
  clientId?: string;
};

export const PositionModal: React.FC<Props> = ({
  open,
  setOpen,
  clientId,
  symbol,
}) => {
  const { t } = useTranslation();
  const [position, setPosition] = useState<TradingPosition>();
  const { assets_map } = useAssets();

  useEffect(() => {
    if (clientId) {
      PositionsService.fetchOne(symbol, clientId).then(setPosition);
    }
  }, [clientId, symbol]);

  return (
    <Modal open={open} setOpen={setOpen}>
      {!position && (
        <div>
          <Skeleton className="h-10 w-20 bg-sb-gray-200" />
          <Skeleton className="mt-2 h-5 w-40 bg-sb-gray-200" />
          <Skeleton className="mt-12 h-5 w-32 bg-sb-gray-200" />
          <Skeleton className="mt-2 h-5 w-32 bg-sb-gray-200" />
          <Skeleton className="mt-2 h-5 w-32 bg-sb-gray-200" />
        </div>
      )}
      {position && <Labels.H1>{position.symbol}</Labels.H1>}
      {position && assets_map ? (
        <>
          <div className="text-base text-sb-gray-500">
            {assets_map[position?.symbol].name}
          </div>
          <div className="mt-8 space-y-2">
            <PositionRow
              title={t("positions.avg_entry_price")}
              value={CurrencyFormat.format(position.avg_entry_price)}
            />
            <PositionRow
              title={t("positions.change_today")}
              value={(position.change_today * 100).toFixed(2)}
            />
            <PositionRow
              title={t("positions.cost_basis")}
              value={CurrencyFormat.format(position.cost_basis)}
            />
            <PositionRow
              title={t("positions.current_price")}
              value={CurrencyFormat.format(position.current_price)}
            />
            <PositionRow
              title={t("positions.exchange")}
              value={t(position.exchange)}
            />
            <PositionRow
              title={t("positions.lastday_price")}
              value={CurrencyFormat.format(position.lastday_price)}
            />
            <PositionRow
              title={t("positions.market_value")}
              value={CurrencyFormat.format(position.market_value)}
            />
            <PositionRow title={t("positions.qty")} value={position.qty + ""} />
            <PositionRow
              title={t("positions.unrealized_pl")}
              value={`${CurrencyFormat.format(position.unrealized_pl)} (${(
                position.unrealized_plpc * 100
              ).toFixed(2)}%)`}
            />
            <PositionRow
              title={t("positions.unrealized_intraday_pl")}
              value={`${CurrencyFormat.format(
                position.unrealized_intraday_pl,
              )} (${(position.unrealized_intraday_pl * 100).toFixed(2)}%)`}
            />
          </div>
        </>
      ) : null}
    </Modal>
  );
};

type PositionRowProps = {
  title: string;
  value: string;
};

const PositionRow: React.FC<PositionRowProps> = ({ title, value }) => {
  return (
    <div className="flex items-center justify-between">
      <div className="font-semibold">{title}</div>
      <div>{value}</div>
    </div>
  );
};
