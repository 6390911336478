import { SimpleRow } from "modules/brokerage_account/rows/SimpleRow";
import { useTranslation } from "react-i18next";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import { BrokerageAccount } from "shared/models/brokerage_account/BrokerageAccountModel";

type Props = {
  brokerageAccount?: BrokerageAccount;
};

export const Employment: React.FC<Props> = ({ brokerageAccount }) => {
  const { t } = useTranslation();

  return (
    <Panel>
      <Labels.H3 className="mb-4">{t("brokerageAccount.employment")}</Labels.H3>
      {brokerageAccount && (
        <div className="grid grid-cols-2 gap-y-8">
          <SimpleRow
            title={t("brokerageAccount.fields.employmentStatus")}
            value={
              brokerageAccount?.disclosures.employment_status ||
              t("brokerageAccount.unknown")
            }
          />
          <SimpleRow
            title={t("brokerageAccount.fields.employmentPosition")}
            value={
              brokerageAccount?.disclosures.employment_position ||
              t("brokerageAccount.unknown")
            }
          />
          <SimpleRow
            title={t("brokerageAccount.fields.employerName")}
            value={
              brokerageAccount?.disclosures.employer_name ||
              t("brokerageAccount.unknown")
            }
          />
          <SimpleRow
            title={t("brokerageAccount.fields.employerAddress")}
            value={
              brokerageAccount?.disclosures.employer_address ||
              t("brokerageAccount.unknown")
            }
          />
        </div>
      )}
    </Panel>
  );
};
