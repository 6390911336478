import { useTranslation } from "react-i18next";
import { KycStatusSummary } from "shared/models/client/ClientModel";
import "./styles.css";

type Props = {
  status: KycStatusSummary;
};

export const KycBadge: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <div className={"kyc " + status}>
      {t(`clients.kyc.${status}`)}
    </div>
  );
};
