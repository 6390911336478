import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Chart } from "shared/components/common/chart/Chart";
import { Labels } from "shared/components/hoc/Labels";
import { FeeAccruedChartByDayPoint } from "shared/models/fees/FeesModel";
import { FeesService } from "shared/services/fees/FeesService";
import { CurrencyFormat } from "shared/utils/currency";
import { ISOTimestamp } from "shared/utils/types";

type Props = {
  start: ISOTimestamp;
  end: ISOTimestamp;
};

export const FeesAccruedByDayChart: React.FC<Props> = ({ start, end }) => {
  const { t } = useTranslation();
  const [data, setData] = useState<FeeAccruedChartByDayPoint[]>();

  useEffect(() => {
    FeesService.getAccruedChartByDay({ start, end }).then(setData);
  }, [end, start]);

  return (
    <div className="w-full select-none py-4 pr-4">
      {data && !data.length ? (
        <Labels.G1>{t("fees.noFeesAccrued")}</Labels.G1>
      ) : (
        <Chart
          data={data}
          xKey={"date"}
          yKey={"amount"}
          height={400}
          yFormatter={(x) => CurrencyFormat.format(x)}
          domain={["0", "dataMax"]}
        />
      )}
    </div>
  );
};
