type Props = {
  className?: string;
};

export default function MarketOrder({ className }: Props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M20.1951 7.23956L19.2313 4.7176C16.9244 12.0562 10.7739 16.4357 2.75 16.4357C2.33579 16.4357 2 16.1 2 15.6857C2 15.2715 2.33579 14.9357 2.75 14.9357C10.1273 14.9357 15.6722 10.9761 17.7863 4.31213L15.3991 5.60586C15.035 5.80323 14.5798 5.668 14.3824 5.30383C14.185 4.93966 14.3203 4.48445 14.6844 4.28708L18.6921 2.11512C19.1969 1.84153 19.8272 2.07486 20.0322 2.61123L21.5962 6.70411C21.7441 7.09104 21.5503 7.52457 21.1634 7.67242C20.7764 7.82028 20.3429 7.62648 20.1951 7.23956Z"
        fill="currentColor"
      />
      <path
        d="M19.75 10.672C19.75 10.4511 19.9291 10.272 20.15 10.272H21.35C21.5709 10.272 21.75 10.4511 21.75 10.672V22.2858C21.75 22.5067 21.5709 22.6858 21.35 22.6858H20.15C19.9291 22.6858 19.75 22.5067 19.75 22.2858V10.672Z"
        fill="currentColor"
      />
      <path
        d="M16.15 13.6859C15.9291 13.6859 15.75 13.8649 15.75 14.0859V22.2859C15.75 22.5068 15.9291 22.6859 16.15 22.6859H17.35C17.5709 22.6859 17.75 22.5068 17.75 22.2859V14.0859C17.75 13.8649 17.5709 13.6859 17.35 13.6859H16.15Z"
        fill="currentColor"
      />
      <path
        d="M11.75 17.1088C11.75 16.8879 11.9291 16.7088 12.15 16.7088H13.35C13.5709 16.7088 13.75 16.8879 13.75 17.1088V22.2858C13.75 22.5067 13.5709 22.6858 13.35 22.6858H12.15C11.9291 22.6858 11.75 22.5067 11.75 22.2858V17.1088Z"
        fill="currentColor"
      />
      <path
        d="M8.15 18.0881C7.92909 18.0881 7.75 18.2672 7.75 18.4881V22.2858C7.75 22.5068 7.92909 22.6858 8.15 22.6858H9.35C9.57091 22.6858 9.75 22.5068 9.75 22.2858V18.4881C9.75 18.2672 9.57091 18.0881 9.35 18.0881H8.15Z"
        fill="currentColor"
      />
      <path
        d="M4.15 19.0076C3.92909 19.0076 3.75 19.1867 3.75 19.4076V22.2858C3.75 22.5067 3.92909 22.6858 4.15 22.6858H5.35C5.57091 22.6858 5.75 22.5067 5.75 22.2858V19.4076C5.75 19.1867 5.57091 19.0076 5.35 19.0076H4.15Z"
        fill="currentColor"
      />
    </svg>
  );
}
