import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BasePage } from "shared/components/common/BasePage";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import { selectProposals } from "shared/store/proposals/ProposalsReducer";
import { ProposalsThunks } from "shared/store/proposals/ProposalsThunks";
import { ProposalsTable } from "./ProposalsTable";
import { ClientsThunks } from "shared/store/clients/ClientsThunks";

export const ProposalsPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { proposals, loading } = useSelector(selectProposals);

  useEffect(() => {
    dispatch(ProposalsThunks.fetchMany());
    dispatch(ClientsThunks.fetchClients());
  }, [dispatch]);

  const setupInterval = useCallback(() => {
    let fetchProposalsTimeout: NodeJS.Timeout | null = null;

    const fetchProposals = async () => {
      const newProposals = await dispatch(ProposalsThunks.fetchMany()).unwrap();
      const isAnyNewBusy = newProposals.find((el) => !el.strategy_type);
      if (isAnyNewBusy) {
        fetchProposalsTimeout = setTimeout(fetchProposals, 5000);
      }
    };

    fetchProposals();

    return () => {
      if (fetchProposalsTimeout) {
        clearTimeout(fetchProposalsTimeout);
      }
    };
  }, [dispatch]);

  const isAnyBusy = proposals.find((el) => !el.strategy_type) !== undefined;
  useEffect(() => {
    let clearInterval: () => void = () => {};
    if (isAnyBusy) {
      clearInterval = setupInterval();
    }

    return () => clearInterval();
  }, [isAnyBusy, dispatch, setupInterval]);

  return (
    <BasePage>
      <Labels.H1>{t("proposals.title")}</Labels.H1>
      <Panel className="mt-8">
        <ProposalsTable
          key={"proposals_" + proposals.length}
          proposals={proposals}
          loading={loading}
        />
      </Panel>
    </BasePage>
  );
};
