import {
  AllocateStocks,
  Allocation,
} from "modules/allocate_stocks/AllocateStocks";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Button } from "shared/components/controls/Button";
import { NewPortfolio } from "shared/models/portfolio/PortfolioModel";
import { PortfoliosService } from "shared/services/portfolios/PortfoliosService";
import { TradingInvestorType } from "shared/utils/types";

interface Props {
  onPortfolioCreated(): void;
}

export const CreatePortfolioForm: React.FC<Props> = ({
  onPortfolioCreated,
}) => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [submitValid, setSubmitValid] = useState(false);
  const [allocations, setAllocations] = useState<Allocation[]>([]);

  const riskLevelOptions: { value: TradingInvestorType; label: string }[] = [
    {
      value: "aggressive",
      label: t("clientDetails.riskProfile.aggressive"),
    },
    {
      value: "moderate",
      label: t("clientDetails.riskProfile.moderate"),
    },
    {
      value: "conservative",
      label: t("clientDetails.riskProfile.conservative"),
    },
  ];

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setSubmitting(true);
    const form = event.target;
    const formData = new FormData(form);
    const mappedAllocations = allocations.map((a) => ({
      weight: a.allocation / 100,
      symbol: a.asset.symbol,
      contract_id: a.asset.ibrk_details?.conid,
    }));
    let jsonData = {
      ...Object.fromEntries(formData.entries()),
      allocations: mappedAllocations,
    };

    PortfoliosService.post(jsonData as NewPortfolio)
      .then(() => {
        toast.success(t("portfolios.created"));
        onPortfolioCreated();
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="space-y-6">
          <div className="create-portfolio-form">
            <label>{t("portfolios.form.symbol")}</label>
            <input name="symbol" type="text" className="w-[600px]" />
          </div>
          <div className="create-portfolio-form">
            <label>{t("portfolios.form.title")}</label>
            <input
              name="title"
              minLength={10}
              type="text"
              className="w-[600px]"
              required
            />
          </div>
          <div className="create-portfolio-form">
            <label>{t("portfolios.form.description")}</label>
            <input
              name="description"
              minLength={10}
              type="text"
              className="w-[600px]"
              required
            />
          </div>
          <div className="create-portfolio-form">
            <label>{t("portfolios.form.risk_level")}</label>
            <select
              required
              name="risk_level"
              className="box-border h-11 cursor-pointer rounded-xl border border-sb-gray-200 bg-white px-4 py-1 duration-150 hover:border-sb-gray-500"
            >
              {riskLevelOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="create-portfolio-form">
            <label>{t("portfolios.form.goal")}</label>
            <input
              name="goal"
              type="text"
              className="w-[600px]"
              minLength={10}
              required
            />
          </div>
        </div>
        <div className="mt-8">
          <AllocateStocks
            onAllocationChange={setAllocations}
            onValidate={setSubmitValid}
          />
        </div>
        <div className="mt-10">
          <Button
            label="portfolios.create"
            onClick={() => {}}
            type="submit"
            loading={submitting}
            disabled={!submitValid}
          />
        </div>
      </form>
    </div>
  );
};
