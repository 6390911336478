import { DropdownOption } from "shared/components/controls/DropdownSelect";
import { AllCountries, CountryNames } from "shared/types/Countries";

export const USA = {
  States: [
    { label: "kyc.us_states.AL", value: "AL" },
    { label: "kyc.us_states.AK", value: "AK" },
    { label: "kyc.us_states.AS", value: "AS" },
    { label: "kyc.us_states.AZ", value: "AZ" },
    { label: "kyc.us_states.AR", value: "AR" },
    { label: "kyc.us_states.CA", value: "CA" },
    { label: "kyc.us_states.CO", value: "CO" },
    { label: "kyc.us_states.CT", value: "CT" },
    { label: "kyc.us_states.DE", value: "DE" },
    { label: "kyc.us_states.DC", value: "DC" },
    { label: "kyc.us_states.FM", value: "FM" },
    { label: "kyc.us_states.FL", value: "FL" },
    { label: "kyc.us_states.GA", value: "GA" },
    { label: "kyc.us_states.GU", value: "GU" },
    { label: "kyc.us_states.HI", value: "HI" },
    { label: "kyc.us_states.ID", value: "ID" },
    { label: "kyc.us_states.IL", value: "IL" },
    { label: "kyc.us_states.IN", value: "IN" },
    { label: "kyc.us_states.IA", value: "IA" },
    { label: "kyc.us_states.KS", value: "KS" },
    { label: "kyc.us_states.KY", value: "KY" },
    { label: "kyc.us_states.LA", value: "LA" },
    { label: "kyc.us_states.ME", value: "ME" },
    { label: "kyc.us_states.MH", value: "MH" },
    { label: "kyc.us_states.MD", value: "MD" },
    { label: "kyc.us_states.MA", value: "MA" },
    { label: "kyc.us_states.MI", value: "MI" },
    { label: "kyc.us_states.MN", value: "MN" },
    { label: "kyc.us_states.MS", value: "MS" },
    { label: "kyc.us_states.MO", value: "MO" },
    { label: "kyc.us_states.MT", value: "MT" },
    { label: "kyc.us_states.NE", value: "NE" },
    { label: "kyc.us_states.NV", value: "NV" },
    { label: "kyc.us_states.NH", value: "NH" },
    { label: "kyc.us_states.NJ", value: "NJ" },
    { label: "kyc.us_states.NM", value: "NM" },
    { label: "kyc.us_states.NY", value: "NY" },
    { label: "kyc.us_states.NC", value: "NC" },
    { label: "kyc.us_states.ND", value: "ND" },
    { label: "kyc.us_states.MP", value: "MP" },
    { label: "kyc.us_states.OH", value: "OH" },
    { label: "kyc.us_states.OK", value: "OK" },
    { label: "kyc.us_states.OR", value: "OR" },
    { label: "kyc.us_states.PW", value: "PW" },
    { label: "kyc.us_states.PA", value: "PA" },
    { label: "kyc.us_states.PR", value: "PR" },
    { label: "kyc.us_states.RI", value: "RI" },
    { label: "kyc.us_states.SC", value: "SC" },
    { label: "kyc.us_states.SD", value: "SD" },
    { label: "kyc.us_states.TN", value: "TN" },
    { label: "kyc.us_states.TX", value: "TX" },
    { label: "kyc.us_states.UT", value: "UT" },
    { label: "kyc.us_states.VT", value: "VT" },
    { label: "kyc.us_states.VI", value: "VI" },
    { label: "kyc.us_states.VA", value: "VA" },
    { label: "kyc.us_states.WA", value: "WA" },
    { label: "kyc.us_states.WV", value: "WV" },
    { label: "kyc.us_states.WI", value: "WI" },
    { label: "kyc.us_states.WY", value: "WY" },
  ],
};

export const countryOptions: DropdownOption[] = AllCountries.map(
  (c) =>
    ({
      value: c.code,
      label: CountryNames[c.code],
    }) as DropdownOption,
);
