import { FeesAccruedChart } from "modules/fees_accrued_chart/FeesAccruedChart";
import { ClientOpenFees } from "modules/client_fees/ClientOpenFees";
import { Layouts } from "shared/components/hoc/Layouts";

type Props = {
  clientId?: string;
};

export const ClientFees: React.FC<Props> = ({ clientId }) => {
  return (
    <Layouts.Spaced>
      <FeesAccruedChart clientId={clientId} />
      <ClientOpenFees clientId={clientId} />
    </Layouts.Spaced>
  );
};
