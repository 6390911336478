import { createSlice } from "@reduxjs/toolkit";
import { MacroeconomicConditions } from "shared/models/tools/MacroeconomicConditionsModel";
import { RootState } from "shared/store/store";
import { Loading } from "shared/types/enums";
import { fetchMacroeconomicConditions } from "./MacroeconomicConditionsThunks";

interface MacroeconomicConditionsReducer {
  conditions: MacroeconomicConditions | null | undefined;
  loading: Loading;
}

const initialState: MacroeconomicConditionsReducer = {
  conditions: undefined,
  loading: Loading.Idle,
};

const macroeconomicConditionsSlice = createSlice({
  name: "[MACROECONOMIN_CONDITIONS]",
  initialState,
  reducers: {
    reset(state) {
      state.conditions = initialState.conditions;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMacroeconomicConditions.pending, (state) => {
      state.loading = Loading.InProgress;
    });
    builder.addCase(fetchMacroeconomicConditions.rejected, (state) => {
      state.loading = Loading.Finished;
      return state;
    });
    builder.addCase(fetchMacroeconomicConditions.fulfilled, (state, action) => {
      state.loading = Loading.Finished;
      state.conditions = action.payload;
      return state;
    });
  },
});

export const selectMacroeconomicConditions = () => (state: RootState) =>
  state.macroeconomicConditions;

const MacroeconomicCOnditionsActions = {
  ...macroeconomicConditionsSlice.actions,
};

export default macroeconomicConditionsSlice.reducer;
