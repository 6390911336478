type Props = {
  className?: string;
};

export default function LimitOrder({ className }: Props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.5 19H22.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="2 3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.33477 10.9441C4.0188 11.3044 3.68698 11.6827 3.33773 12.0791C3.02737 12.4313 2.49025 12.4652 2.13804 12.1549C1.78582 11.8445 1.75189 11.3074 2.06225 10.9552C2.39895 10.5731 2.72276 10.2037 3.03449 9.84807C4.6011 8.06093 5.86277 6.62165 6.92362 5.66355C8.15468 4.55173 9.46418 3.76108 10.8859 4.34517C11.5352 4.61193 12.0915 5.12896 12.6034 5.78501C13.12 6.44717 13.6427 7.3184 14.2036 8.38693C15.2325 10.3471 16.4513 13.0924 18.0435 16.679C18.1849 16.9976 18.3293 17.3229 18.4768 17.6548C18.6674 18.0838 18.4741 18.5861 18.0451 18.7767C17.6161 18.9673 17.1138 18.774 16.9232 18.345C16.7794 18.0212 16.6387 17.7043 16.501 17.3943C14.8952 13.7774 13.6999 11.0849 12.6984 9.17705C12.1554 8.14271 11.6877 7.37501 11.2631 6.83077C10.8337 6.28043 10.4978 6.0236 10.2399 5.91763C9.84709 5.75626 9.28158 5.82467 8.06305 6.92518C7.08274 7.81053 5.90433 9.15429 4.33477 10.9441Z"
        fill="currentColor"
      />
      <path d="M19.5 18.5C19.5 19.3284 18.8284 20 18 20C17.1716 20 16.5 19.3284 16.5 18.5C16.5 17.6716 17.1716 17 18 17C18.8284 17 19.5 17.6716 19.5 18.5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 17.5C17.4477 17.5 17 17.9477 17 18.5C17 19.0523 17.4477 19.5 18 19.5C18.5523 19.5 19 19.0523 19 18.5C19 17.9477 18.5523 17.5 18 17.5ZM16 18.5C16 17.3954 16.8954 16.5 18 16.5C19.1046 16.5 20 17.3954 20 18.5C20 19.6046 19.1046 20.5 18 20.5C16.8954 20.5 16 19.6046 16 18.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
