import { DateTime } from "luxon";
import RestService from "shared/api/RestService";
import {
  BrokerageAccount,
  BrokerageAccountDocumentUpload,
} from "shared/models/brokerage_account/BrokerageAccountModel";
import { FaOnboarding } from "shared/models/kyc/FaOnboarding";
import {
  Onboarding,
  OnboardingAddress,
} from "shared/models/onboarding/OnboardingModel";
import { auth } from "shared/utils/firebase";
import { getKYCRequirements } from "shared/utils/kyc_utils";
import { FaApiRoutes } from "shared/utils/routes";
import { faAccountValidation } from "../brokerage_account/BrokerageAccountValidation";

type FaBrokerageAccount = BrokerageAccount & {
  license_number?: string;
};

const createRequest = (
  KYCData: Onboarding,
  signature: string,
  phone_data?: string,
): FaBrokerageAccount => {
  const ipAddress = "[filled_by_backend]";
  const nowDate = DateTime.now().toISO();

  const requirements = getKYCRequirements(KYCData);

  const requestPayload: FaBrokerageAccount = {
    contact: faAccountValidation.validateContact(KYCData),
    identity: faAccountValidation.validateFaIdentity(KYCData),
    disclosures: faAccountValidation.validateDisclosures(KYCData),
    agreements: [
      {
        agreement: "margin_agreement",
        signed_at: nowDate,
        ip_address: ipAddress,
      },
      {
        agreement: "account_agreement",
        signed_at: nowDate,
        ip_address: ipAddress,
      },
      {
        agreement: "customer_agreement",
        signed_at: nowDate,
        ip_address: ipAddress,
      },
    ],
    documents: faAccountValidation.validateDocuments(KYCData, requirements),
    phone_data,
    license_number: KYCData.license_number,
  };

  // Create w8ben from validated payload if required
  if (requirements.w8ben) {
    const w8ben = faAccountValidation.validateW8BEN(
      requestPayload.identity,
      requestPayload.contact,
      signature,
    );
    requestPayload.documents.push(w8ben);
  }

  requestPayload.contact["email_address"] =
    auth.currentUser?.email || undefined;

  return requestPayload;
};

export const FaOnboardingService = {
  async postInvitationCode(code: string): Promise<void> {
    await auth.authStateReady();
    return RestService.post(FaApiRoutes.Advisor.Onboarding.Code, { code });
  },

  async post(data: Onboarding, signature: string): Promise<void> {
    const requestPayload = createRequest(data, signature);
    return RestService.post(
      FaApiRoutes.Advisor.Onboarding.Account,
      requestPayload,
    );
  },

  async updateAddress(updatedAddress: OnboardingAddress): Promise<void> {
    await RestService.put(
      FaApiRoutes.Advisor.Onboarding.Account,
      updatedAddress,
    );
  },

  async get(): Promise<FaOnboarding> {
    return RestService.get(FaApiRoutes.Advisor.Onboarding.Account);
  },

  async postRegistrationNumber(registration_number: string): Promise<void> {
    await RestService.post(FaApiRoutes.Advisor.Onboarding.RegistrationNumber, {
      registration_number,
    });
  },

  async uploadDocuments(
    documentPayload: BrokerageAccountDocumentUpload[],
  ): Promise<void> {
    await RestService.post(
      FaApiRoutes.Advisor.Onboarding.Document,
      documentPayload,
    );
  },
};
