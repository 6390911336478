import axios, {
  AxiosError,
  AxiosInstance,
  AxiosProgressEvent,
  AxiosResponse,
} from "axios";
import qs from "qs";
import { toast } from "react-toastify";
import { auth, environment } from "shared/utils/firebase";
import { Environment } from "shared/utils/types";

interface RestResponse<T> {
  resource?: T;
  error?: any;
}

const STAGING_API_URL = "https://api.staging.streetbeat.com";
const PROD_API_URL = "https://api.streetbeat.com";

export class ApiResponseError extends Error {
  readonly data?: any;
  readonly status: number;
  readonly cause: Error;
  readonly request: any;

  constructor(error: AxiosError, status: number, data?: any) {
    const msg =
      data && data.error && data.error.error_message
        ? data.error.error_message
        : data.error.message
        ? data.error.message
        : error.message;
    super(msg);
    this.name = "ApiResponseError";
    this.cause = error;
    this.status = status;
    this.data = data;
    this.request = error?.request;
  }
}

class RestService {
  private ENVIRONMENT_APIS: { [key in Environment]: AxiosInstance } = {
    production: this.createAPIInstance("production"),
    staging: this.createAPIInstance("staging"),
  };

  private createAPIInstance(environment: Environment) {
    const instance = axios.create({
      baseURL: environment === "staging" ? STAGING_API_URL : PROD_API_URL,
    });
    this.addRequestInterceptor(instance);
    this.addResponseInterceptor(instance);
    return instance;
  }

  private async addRequestInterceptor(instance: AxiosInstance) {
    instance.interceptors.request.use(
      async (config) => {
        // Inject standard headers
        config.headers.Accept = "application/json";
        config.headers["Content-Type"] = "application/json";
        config.headers["AppVersionEpoch"] = "99999999999";

        // Inject firebase token
        try {
          await auth.authStateReady();
          const token = await auth.currentUser?.getIdToken();
          if (token) {
            config.headers.Firebase = token;
            config.headers.Authorization = `Bearer ${token}`;
          }
        } catch (error) {
          console.warn("[RestService] request", JSON.stringify(error));
        }
        return config;
      },
      (error) => Promise.reject(error),
    );
  }

  private addResponseInterceptor(instance: AxiosInstance) {
    instance.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        if (!error.response) {
          console.warn("[RestService] response", JSON.stringify(error));
          const apiError = new ApiResponseError(error, 0, null);
          if (![401, 404].includes(error.request.status)) {
            toast.error(apiError.message);
          }
          return Promise.reject(apiError);
        }

        const { status, data } = error.response;
        const apiError = new ApiResponseError(error, status, data);
        if (![401, 404].includes(error.request.status)) {
          toast.error(apiError.message);
        }
        return Promise.reject(apiError);
      },
    );
  }

  async api(): Promise<AxiosInstance> {
    const env = await environment();
    if (!auth.currentUser) return this.ENVIRONMENT_APIS["production"];
    if (!auth.currentUser.email) return this.ENVIRONMENT_APIS["production"];

    return this.ENVIRONMENT_APIS[env];
  }

  async post<T>(url: string, payload?: any, params?: any): Promise<T> {
    const awaitedApi = await this.api();
    const { data } = await awaitedApi.post<RestResponse<T>>(
      url,
      payload,
      params,
    );
    return data?.resource || (data as T);
  }

  async get<T>(url: string, params?: {}): Promise<T> {
    const awaitedApi = await this.api();
    const { data } = await awaitedApi.get<RestResponse<T>>(url, {
      params,
      paramsSerializer: (serializedParams) =>
        qs.stringify(serializedParams, { arrayFormat: "repeat" }),
    });
    return data.resource || (data as T);
  }

  async put<T>(url: string, payload?: any, params?: any): Promise<T> {
    const awaitedApi = await this.api();
    const { data } = await awaitedApi.put<RestResponse<T>>(url, payload, {
      params,
    });
    return data?.resource || (data as T);
  }

  async patch<T>(url: string, payload?: any, params?: any): Promise<T> {
    const awaitedApi = await this.api();
    const { data } = await awaitedApi.patch<RestResponse<T>>(
      url,
      payload,
      params,
    );
    return data?.resource || (data as T);
  }

  async delete<T>(url: string, params?: any): Promise<T> {
    const awaitedApi = await this.api();
    const { data } = await awaitedApi.delete<RestResponse<T>>(url, { params });
    return data?.resource || (data as T);
  }

  async download(url: string): Promise<AxiosResponse<any, any>> {
    const awaitedApi = await this.api();
    const response = await awaitedApi.get<any>(url, { responseType: "blob" });
    return response;
  }

  async postSse(
    url: string,
    payload?: any,
    onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void,
  ) {
    const awaitedApi = await this.api();
    const response = await awaitedApi.post(url, payload, {
      onDownloadProgress,
      responseType: "stream",
    });
    return response;
  }
}

const restService = new RestService();
export default restService;
