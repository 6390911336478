const BASE_PATH_V2 = "/fa/api/v2";
const BASE_PATH_V1 = "/api/v1";

const ADVISOR_PATH = `${BASE_PATH_V2}/advisor`;
const ADVISOR_CONTACTS_PATH = `${ADVISOR_PATH}/contacts`;
const ADVISOR_WATCHLISTS_PATH = `${ADVISOR_PATH}/watchlists`;
const ADVISOR_ONBOARDING_PATH = `${BASE_PATH_V2}/advisor/onboarding`;
const ADVISOR_FEES_PATH = `${ADVISOR_PATH}/fees`;
const ADVISOR_CLIENTS_PATH = `${ADVISOR_PATH}/clients`;
const ADVISOR_PROPOSALS_PATH = `${ADVISOR_PATH}/proposals`;
const ADVISOR_PORTFOLIOS_PATH = `${ADVISOR_PATH}/portfolios`;
const ADVISOR_FEE_TEMPLATES_PATH = `${ADVISOR_PATH}/ibrk/fee-templates`;
const ALPACA_BASE_PATH = `${BASE_PATH_V2}/alpaca`;
const USERS_PATH = `${BASE_PATH_V2}/users`;

const PORTFOLIO_ASSISTANT_PATH = `${BASE_PATH_V1}/portfolio-assistant`;

export const FaApiRoutes = {
  Alpaca: {
    Countries: `${ALPACA_BASE_PATH}/countries`,
    Create: `${ALPACA_BASE_PATH}/create`,
    Account: `${ALPACA_BASE_PATH}/account`,
    Documents: {
      Upload: `${ALPACA_BASE_PATH}/documents/upload`,
    },
    Onfido: {
      Sdk: {
        Path: `${ALPACA_BASE_PATH}/onfido/sdk`,
        Tokens: `${ALPACA_BASE_PATH}/onfido/sdk/tokens`,
      },
    },
  },
  Users: {
    Me: {
      Path: `${USERS_PATH}/me`,
      CustomAgreements: `${USERS_PATH}/me/custom_agreements`,
    },
    PhoneStartVerify: `${USERS_PATH}/phone_start_verify`,
    PhoneCheckVerify: `${USERS_PATH}/phone_check_verify`,
  },
  Assets: {
    Path: `${BASE_PATH_V2}/assets`,
    Search: `${BASE_PATH_V2}/assets/search`,
    Details: (symbol: string) => {
      return {
        Path: `${BASE_PATH_V2}/assets/${symbol}`,
      };
    },
  },
  Tools: {
    TextAssistant: `${BASE_PATH_V2}/tools/text-assistant`,
    MacroeconomicConditions: `${BASE_PATH_V2}/tools/macroeconomic_conditions`,
  },
  Advisor: {
    PortfolioPerformances: `${ADVISOR_PATH}/portfolio-performances`,
    ClientPerformances: `${ADVISOR_PATH}/client-performances`,
    Contacts: {
      Path: `${ADVISOR_CONTACTS_PATH}`,
      Details: (contactId: string) => {
        return {
          Path: `${ADVISOR_CONTACTS_PATH}/${contactId}`,
          Invite: `${ADVISOR_CONTACTS_PATH}/${contactId}/invite`,
          KycDraft: {
            Alpaca: `${ADVISOR_CONTACTS_PATH}/${contactId}/kyc-draft/alpaca`,
          },
        };
      },
    },
    Clients: {
      Path: `${ADVISOR_CLIENTS_PATH}`,
      Alpaca: {
        Account: `${ADVISOR_CLIENTS_PATH}/alpaca/account`,
      },
      Details: (clientId: string) => {
        return {
          Path: `${ADVISOR_CLIENTS_PATH}/${clientId}`,
          Activities: `${ADVISOR_CLIENTS_PATH}/${clientId}/activities`,
          Alpaca: {
            Funding: {
              Accounts: `${ADVISOR_CLIENTS_PATH}/${clientId}/alpaca/funding/accounts`,
            },
            Account: `${ADVISOR_CLIENTS_PATH}/${clientId}/alpaca/account`,
          },
          AumChart: `${ADVISOR_CLIENTS_PATH}/${clientId}/aum-chart`,
          Documents: {
            Upload: `${ADVISOR_CLIENTS_PATH}/${clientId}/documents/upload`,
          },
          Onfido: {
            Sdk: {
              Tokens: `${ADVISOR_CLIENTS_PATH}/${clientId}/onfido/sdk/tokens`,
              Path: `${ADVISOR_CLIENTS_PATH}/${clientId}/onfido/sdk`,
            },
          },
          Orders: {
            Path: `${ADVISOR_CLIENTS_PATH}/${clientId}/orders`,
            Details: (orderId: string) => {
              return {
                Path: `${ADVISOR_CLIENTS_PATH}/${clientId}/orders/${orderId}`,
              };
            },
          },
          Positions: {
            Path: `${ADVISOR_CLIENTS_PATH}/${clientId}/positions`,
            Details: (symbol: string) => {
              return {
                Path: `${ADVISOR_CLIENTS_PATH}/${clientId}/positions/${symbol}`,
              };
            },
          },
          Portfolios: {
            Path: `${ADVISOR_CLIENTS_PATH}/${clientId}/portfolios`,
            Details: (portfolioId: string) => {
              return {
                Path: `${ADVISOR_CLIENTS_PATH}/${clientId}/portfolios/${portfolioId}`,
                Deposits: `${ADVISOR_CLIENTS_PATH}/${clientId}/portfolios/${portfolioId}/deposits`,
                Withdrawals: `${ADVISOR_CLIENTS_PATH}/${clientId}/portfolios/${portfolioId}/withdrawals`,
                Results: `${ADVISOR_CLIENTS_PATH}/${clientId}/portfolios/${portfolioId}/results`,
              };
            },
          },
          SendInvitation: `${ADVISOR_CLIENTS_PATH}/${clientId}/send-invitation`,
        };
      },
      Orders: {
        Search: `${ADVISOR_CLIENTS_PATH}/orders/search`,
      },
    },
    PendingTasks: `${ADVISOR_PATH}/pending-tasks`,
    AumChart: `${ADVISOR_PATH}/aum-chart`,
    Onboarding: {
      Code: `${ADVISOR_ONBOARDING_PATH}/code`,
      Account: `${ADVISOR_ONBOARDING_PATH}/account`,
      Document: `${ADVISOR_ONBOARDING_PATH}/document`,
      RegistrationNumber: `${ADVISOR_ONBOARDING_PATH}/registration_number`,
    },
    Fees: {
      Accrued: {
        ByDay: `${ADVISOR_FEES_PATH}/accrued/by-day`,
        Chart: `${ADVISOR_FEES_PATH}/accrued/chart`,
      },
      Details: (clientId: string) => {
        return {
          Path: `${ADVISOR_FEES_PATH}/${clientId}`,
          Schedule: `${ADVISOR_FEES_PATH}/${clientId}/schedule`,
          ScheduleDaily: `${ADVISOR_FEES_PATH}/${clientId}/schedule_daily`,
        };
      },
      Stats: `${ADVISOR_FEES_PATH}/stats`,
      Search: `${ADVISOR_FEES_PATH}/search`,
      PayoutsByDay: `${ADVISOR_FEES_PATH}/payouts/by-day`,
      PayoutTerms: `${ADVISOR_FEES_PATH}/payout_terms`,
      Payouts: {
        Search: `${ADVISOR_FEES_PATH}/payouts/search`,
      },
    },
    FeeTemplates: {
      Path: `${ADVISOR_FEE_TEMPLATES_PATH}`,
      Details: (templateId: string) =>
        `${ADVISOR_FEE_TEMPLATES_PATH}/${templateId}`,
    },
    Proposals: {
      Path: `${ADVISOR_PROPOSALS_PATH}`,
      Details: (proposalId: string) => {
        return {
          Path: `${ADVISOR_PROPOSALS_PATH}/${proposalId}`,
          Send: `${ADVISOR_PROPOSALS_PATH}/${proposalId}/send`,
          Cancel: `${ADVISOR_PROPOSALS_PATH}/${proposalId}/cancel`,
          Delete: `${ADVISOR_PROPOSALS_PATH}/${proposalId}/delete`,
          Approve: `${ADVISOR_PROPOSALS_PATH}/${proposalId}/approve`,
          Assistant: {
            Path: `${ADVISOR_PROPOSALS_PATH}/${proposalId}/assistant`,
          },
          Portfolios: `${ADVISOR_PROPOSALS_PATH}/${proposalId}/portfolios`,
        };
      },
    },
    Portfolios: {
      Path: `${ADVISOR_PORTFOLIOS_PATH}`,
      Details: (portfolioId: string) => {
        return {
          Path: `${ADVISOR_PORTFOLIOS_PATH}/${portfolioId}`,
          Composition: `${ADVISOR_PORTFOLIOS_PATH}/${portfolioId}/composition`,
          Texts: `${ADVISOR_PORTFOLIOS_PATH}/${portfolioId}/texts`,
          Results: `${ADVISOR_PORTFOLIOS_PATH}/${portfolioId}/results`,
        };
      },
    },
    Watchlists: {
      Path: `${ADVISOR_WATCHLISTS_PATH}`,
      Details: (watchlistId: string) => {
        return {
          Path: `${ADVISOR_WATCHLISTS_PATH}/${watchlistId}`,
          Symbol: (symbol: string) =>
            `${ADVISOR_WATCHLISTS_PATH}/${watchlistId}/${symbol}`,
        };
      },
    },
  },
  PortfolioAssistant: {
    Sessions: {
      Path: `${PORTFOLIO_ASSISTANT_PATH}/sessions`,
      Details: (sessionId: string) => {
        return {
          Path: `${PORTFOLIO_ASSISTANT_PATH}/sessions/${sessionId}`,
          Portfolios: `${PORTFOLIO_ASSISTANT_PATH}/sessions/${sessionId}/portfolios`,
          Feedbacks: `${PORTFOLIO_ASSISTANT_PATH}/sessions/${sessionId}/feedbacks`,
        };
      },
    },
  },
  EmailVerification: {
    Send: `${BASE_PATH_V1}/accounts/send-verification-email`,
  },
  ResetPassword: `${BASE_PATH_V1}/accounts/send-forgot-password-email`,
};

// TODO move those endpoint in fastapi to FA namespace
export const CLOCK_V1 = "/api/v1/broker/clock"; // TODO
export const TICKERS_SUBSCRIBE_V1 = "/api/v1/tickers/subscribe"; // TODO
export const COMPANIES_CHART_V2 = (symbol: string) =>
  `/api/v2/companies/${symbol}/chart`; // TODO
export const COMPANIES_STATS_V2 = (symbol: string) =>
  `/api/v2/companies/${symbol}/stats`; // TODO
export const COMPANIES_EARNINGS = (symbol: string) =>
  `/api/v2/companies/${symbol}/earnings`; // TODO
export const COMPANIES_PRICE_LEVELS_V2 = (symbol: string) =>
  `/api/v2/companies/${symbol}/price-levels`; // TODO
export const NEWS_V2 = "/api/v2/news"; // TODO
export const PORTFOLIO_BACKTESTING_CHART_V6 = (portfolioId: string) =>
  `/api/v6/strategies/${portfolioId}/charts`; // TODO
export const PORTFOLIO_PROJECTION_V1 =
  "/api/v1/recurring_investment/simulation"; // TODO
export const INTERCOM_VERIFICATION_V1 =
  "/api/v1/accounts/intercom_verification_token/web";

export const PORTFOLIO_ASSISTANT_SESSIONS = `v1/portfolio-assistant/sessions`;
export const PLAID_INVESTMENTS_TOKEN =
  "api/v1/plaid/link_token?product=investments";
export const PLAID_ADD_ACCESS_TOKEN =
  "api/v1/plaid/investments/add_access_token";
export const PLAID_INVESTMENTS_ACCOUNTS = "api/v1/plaid/investments/accounts";
