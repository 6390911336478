import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DataTable } from "shared/components/common/datatable/DataTable";
import { Labels } from "shared/components/hoc/Labels";
import { Panel } from "shared/components/hoc/Panels";
import { useDispatch, useSelector } from "shared/hooks/useDispatch";
import { selectClientFeeSchedule } from "shared/store/client_details/ClientDetailsReducer";
import { ClientDetailsThunks } from "shared/store/client_details/ClientDetailsThunks";
import { Loading } from "shared/types/enums";
import { pctFormatUnsigned } from "shared/utils/currency";
import { formatDateTime } from "shared/utils/date";

type Props = {
  clientId?: string;
};

export const ClientFeeSchedule: React.FC<Props> = ({ clientId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { fees: feeSchedule, loading: scheduleLoading } = useSelector(
    selectClientFeeSchedule(clientId),
  );

  useEffect(() => {
    if (clientId) {
      dispatch(ClientDetailsThunks.fetchFeeSchedule({ clientId }));
    }
  }, [dispatch, clientId]);

  return (
    <Panel>
      <Labels.H3>{t("fees.feeSchedule")}</Labels.H3>
      <div className="mt-4">
        {!feeSchedule.length && scheduleLoading === Loading.Finished ? (
          <Labels.G1>{t("fees.noSchedule")}</Labels.G1>
        ) : (
          <DataTable
            data={feeSchedule}
            loading={scheduleLoading === Loading.InProgress}
            columns={[
              "created_at",
              "effective_from",
              "fee_frequency",
              "annual_rate",
            ]}
            headerLabels={{
              created_at: t("fees.headers.createdAt"),
              effective_from: t("fees.headers.effectiveFrom"),
              fee_frequency: t("fees.headers.feeFrequency"),
              annual_rate: t("fees.headers.annualRate"),
            }}
            cellFormatter={(
              key: string,
              value: any,
              entry: Record<string, any>,
            ) => {
              if (key === "created_at") {
                return formatDateTime(value);
              }
              if (key === "annual_rate") {
                return pctFormatUnsigned.format(
                  entry["configuration"]?.["annual_rate"] || 0,
                );
              }
              if (key === "fee_frequency") {
                return t(`fees.frequency.${value}`);
              }
              return value;
            }}
            defaultSortSettings={{
              direction: "desc",
              by: "created_at",
            }}
          />
        )}
      </div>
    </Panel>
  );
};
